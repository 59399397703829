// SelectionContext.js
import React, { createContext, useState } from 'react';

export const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectedActivity, setSelectedActivity] = useState('');
  const [expertName, setExpertName] = useState('');

  return (
    <SelectionContext.Provider value={{ selectedActivity, setSelectedActivity, expertName, setExpertName}}>
      {children}
    </SelectionContext.Provider>
  );
};