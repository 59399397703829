import { RxCross2 } from 'react-icons/rx';
import classes from "./EmailProfileCards.module.css"

export const EmailProfileCards = (props) => {
    return (
        <div className={classes.profileCard}>
            {props.userImage && <div className={classes.imageSection}><img src={props.userImage} className={classes.studentImage} /></div>}
            <p >{props.userName ? props.userName : props.userEmail}</p>
            <RxCross2 onClick={() => props.removeRecipient(props.userEmail)} />
        </div>
    )
}