import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from './ExpertListingPageCards.module.css';
import { Tooltip } from 'react-tooltip';
import Auth from "../../utils/Auth";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiFillStar, AiOutlineCheckCircle, AiOutlineExclamationCircle, AiOutlineStar } from "react-icons/ai";
import { FaAward } from "react-icons/fa";
import { CgPin } from "react-icons/cg";
import { LoginModal } from "../modal/LoginModal";
import { IoMdPaperPlane } from "react-icons/io";
import { ErrorMessages } from "../../constants/ErrorMessages";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { BiLoaderAlt } from "react-icons/bi";

export const ExpertListingPageCards = (props) => {
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.expertsPageCardsCount);
    const [cardDisplayedCount, setCardDisplayedCount] = useState(AppConstants.cardCounts.expertsPageCardsCount);
    const [expertFollowDetails, setExpertFollowDetails] = useState([]);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalAlertText, setModalAlertText] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [loginExpertFollowDetails, setLoginExpertFollowDetails] = useState({});
    const [sessionExpired, setSessionExpired] = useState(AppConstants.falseText);
    const [loggedInAgencyType, setLoggedInAgencyType] = useState(AppConstants.nullText);

    useEffect(() => {
        if (props.expertsOfCategory?.length > 0) {
            setExpertFollowDetails(props.expertsOfCategory?.map(creator => (
                {
                    uniqueId: creator.creatorUniqueId,
                    followStatus: creator.loggedInUserFollowCreatorStatus === AppConstants.trueTextString ? AppConstants.trueText : AppConstants.falseText
                }
            )));
        }
    }, [props.expertsOfCategory]);

    useEffect(() => {
        const fetchLoggedInAgencyType = async () => {
            const url = AppConstants.getLoggedInAgencyTypeAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setLoggedInAgencyType(response.agencyType);
            }
        }

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleAgency) {
            fetchLoggedInAgencyType();
        } else {
            setLoggedInAgencyType(AppConstants.emptyString);
        }
    }, []);

    const getExpertSkillNames = (skillDetails) => {
        let skillNames = AppConstants.emptyString;
        skillDetails?.forEach((skill, index) => {
            if (index > 0) {
                skillNames = `${skillNames}, ${skill.skillName}`;
            } else {
                skillNames = skill.skillName;
            }
        });
        return skillNames;
    };

    const getDisplayImageForSkill = (skillDetails) => {
        //return defaultImage;
        let skillId = skillDetails[0]?.skillId;
        return props.skillsData.filter(item => item.skillId === skillId)[0]?.skillthumbnailImage;
    }

    const handleModalClose = () => {
        if (loginExpertFollowDetails.idx !== null || loginExpertFollowDetails.idx !== undefined) {
            setLoginExpertFollowDetails({});
            if (!sessionExpired) {
                window.location.reload();
            }
        }
        setShowModal(AppConstants.falseText);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    const followUser = (creator, idx) => {
        setLoginExpertFollowDetails({ creator, idx });
        setShowLoginModal(AppConstants.trueText);
    }

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) {
                updateFollowStatus(loginExpertFollowDetails.creator, loginExpertFollowDetails.idx, AppConstants.followUnfollowStatus.follow);
            } else {
                const userDetails = Auth.getLoggedInUserDetails();
                if (Auth.getUserRole() === AppConstants.userRoleAgency && loginExpertFollowDetails.creatorRole !== AppConstants.userRoleAgency) {
                    setModalAlertText(ErrorMessages.agencyFollowOtherAgencyErrors);
                } else {
                    setModalAlertText(`Hi ${userDetails.firstName}, ${Auth.getUserRole() === AppConstants.userRoleExpert ? ErrorMessages.expertFollowErrorMessage : ErrorMessages.adminFollowErrorMessage}`);
                }
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
            }
        }
        setShowLoginModal(AppConstants.falseText);
    };

    const updateFollowStatus = async (creator, idx, userFollowParam) => {
        let status = userFollowParam ? userFollowParam : expertFollowDetails[idx]?.followStatus ? AppConstants.followUnfollowStatus.unfollow : AppConstants.followUnfollowStatus.follow;
        const url = `${AppConstants.followUnfollowUserAPI}${creator.creatorUniqueId}&status=${status}`;
        const response = await AuthorizedDataService.postRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString,
        );

        if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`${ErrorMessages.youHaveAlreadyFollowedText} ${creator.creatorName}`) : setModalAlertText(`${ErrorMessages.youHaveAlreadyUnfollowedText}  ${creator.creatorName}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode400) {
            const body = await response.json();
            if (body === -22) {
                // Agency cannot follow Agency (School, Skill Org, Housing Society)
                setModalAlertText(ErrorMessages.agencyFollowAgencyError);
            } else if (body === -21) {
                // academy cannot follow other agency
                setModalAlertText(ErrorMessages.academyFollowAgencyError);
            }
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
            setExpertFollowDetails(expertFollowDetails.map(creatorDetails => (
                {
                    uniqueId: creatorDetails.uniqueId,
                    followStatus: creatorDetails.uniqueId === creator.creatorUniqueId ? (status === AppConstants.followUnfollowStatus.unfollow) ? AppConstants.falseText : AppConstants.trueText : creatorDetails.followStatus
                }
            )));
            if (loginExpertFollowDetails.idx >= 0) {
                window.location.reload();
            }
        } else if (response?.status === AppConstants.httpResponseCodes.responseCode403) {
            setModalAlertText(ErrorMessages.sessionExpiredFollowError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setSessionExpired(AppConstants.trueText);
        } else {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`${ErrorMessages.followingErrorMessage}  ${creator.creatorName}. ${ErrorMessages.pleaseTryAgainText}`) : setModalAlertText(`${ErrorMessages.unfollowingErrorMessage}  ${creator.creatorName}. ${ErrorMessages.pleaseTryAgainText}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        }
    };

    const sortCreators = (a, b) => {
        if (a.isCreatorPartOfQrencia === "Y" && b.isCreatorPartOfQrencia === "N") {
            return -1;
        } else if (a.isCreatorPartOfQrencia === "N" && b.isCreatorPartOfQrencia === "Y") {
            return 1;
        } else {
            return a.creatorName.localeCompare(b.creatorName);
        }
    };

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + cardDisplayedCount);
    }, [props.expertsOfCategory]);

    return (
        <>
            <Row xs={1} sm={2} md={3} lg={4} xl={4} className={classes.expertSection}>
                {props.expertsOfCategory?.sort(sortCreators).slice(0, cardCount).map((creator, idx) => (
                    <Col ref={idx === cardCount - 1 ? lastElementRef : null}>
                        <Card key={creator.creatorUniqueId} className={classes.expertCard} onClick={() => handleCardClick(`${process.env.REACT_APP_FRONT_END_URL}${creator.creatorRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${creator.creatorMicrositeSlug}`)}>
                            <Card.Img src={creator.isCreatorPartOfQrencia === AppConstants.yText ? creator.creatorImagePath : getDisplayImageForSkill(creator.skillDetails)} className={classes.expertImage} />
                            {/* {creator.isCreatorPartOfQrencia === AppConstants.nText && <div className={classes.leadImageText}><span className={creator.creatorRole === AppConstants.userRoleExpert ? classes.spanTextExpert : classes.spanTextAgency}>{creator.skillDetails[0].skillName} by {creator.creatorName}</span></div>} */}
                            <Card.Body className={`${classes.expertCardBody} ${creator.creatorRole === AppConstants.userRoleExpert ? classes.expertCardBorder : classes.academyCardBorder}`}>
                                <h1 className={`${classes.expertTitle}`}>
                                    <Link className={`${classes.linkedText}  ${classes.singleLine}`} data-tooltip-id={`${creator.creatorUniqueId}-creatorName`}>{creator.creatorName}</Link>
                                    <Tooltip className={classes.tooltipMessage} id={`${creator.creatorUniqueId}-creatorName`}><span>{creator.creatorName}</span></Tooltip>
                                </h1>
                                <div data-tooltip-id={creator.creatorUniqueId} data-tooltip-place="top" className={`${classes.expertSkills} ${classes.singleLine}`}><IoMdPaperPlane /> {getExpertSkillNames(creator.skillDetails)}</div>
                                <div className={classes.expertCity}><CgPin />&nbsp;{creator.creatorCity}</div>
                                <Tooltip className={classes.tooltipMessage} id={creator.creatorUniqueId}><span>{getExpertSkillNames(creator.skillDetails)}</span></Tooltip>
                                <div className={classes.followSection}>
                                    <div className={classes.starSection}>
                                        {creator?.creatorRating && AppConstants.rateNudge5starArray.map((value) => (
                                            <span
                                                key={value}
                                                className={classes.ratingStars}>
                                                {value <= (creator?.creatorRating?.substr(0, 1) || 0) ? <AiFillStar /> : <AiOutlineStar />}
                                            </span>
                                        ))}
                                    </div>
                                    {creator.topCreatorFlag === AppConstants.yText && <div className={classes.topExpertFlag}>
                                        <FaAward />
                                        <p>{creator.creatorRole === AppConstants.userRoleExpert ? "Top Expert" : "Top Agency"}</p>
                                    </div>}
                                    {!Auth.isLogin() && creator.isCreatorPartOfQrencia === AppConstants.yText && <Button className={`${classes.followUnfollowButton} ${classes.follow}`} onClick={e => { stopPropagation(e); followUser(creator, idx) }}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button>}
                                    {(Auth.isLogin() && creator.isCreatorPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && ((loggedInAgencyType === AppConstants.agencyTypes.academy && creator.creatorRole !== AppConstants.userRoleAgency) || (loggedInAgencyType !== AppConstants.agencyTypes.academy && (creator.creatorRole !== AppConstants.userRoleAgency || creator.agencyType === AppConstants.agencyTypes.academy))))) && expertFollowDetails[idx]?.followStatus) ? <Button data-tooltip-id={`${creator.creatorUniqueId}-unfollow`} className={`${classes.followUnfollowButton} ${classes.following}`} onClick={e => { stopPropagation(e); updateFollowStatus(creator, idx) }}>{AppConstants.followUnfollowStatus.following} </Button> : null}
                                    {(Auth.isLogin() && creator.isCreatorPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && ((loggedInAgencyType === AppConstants.agencyTypes.academy && creator.creatorRole !== AppConstants.userRoleAgency) || (loggedInAgencyType !== AppConstants.agencyTypes.academy && (creator.creatorRole !== AppConstants.userRoleAgency || creator.agencyType === AppConstants.agencyTypes.academy))))) && !expertFollowDetails[idx]?.followStatus) ? <Button className={`${classes.followUnfollowButton} ${classes.follow}`} onClick={e => { stopPropagation(e); updateFollowStatus(creator, idx) }}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button> : null}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                {loading && cardCount<props.expertsOfCategory?.length && (
                <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                )}
            </Row>
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>
            {showLoginModal && <LoginModal signupMessage={AppConstants.signupFollowMessage} loginMessage={AppConstants.loginFollowMessage} onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
        </>
    );
}