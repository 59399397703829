import { useEffect, useState } from 'react';
import classes from "./ExpertBulkMessagingPage.module.css";
import { ComposeMailForm } from "../../component/expertBulkMessaging/ComposeMailForm/ComposeMailForm"
import { SuggestedSearchComponent } from '../../component/expertBulkMessaging/SuggestedSearchComponent/SuggestedSearchComponent';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AppConstants } from '../../constants/AppConstants';

export const ExpertBulkMessagingPage = () => {
    const [selectedCompetition, setSelectedCompetition] = useState({});
    const [participantsDetails, setParticipantsDetails] = useState([]);
    const [composeMail, setComposeMail] = useState(false);
    useEffect(() => {
        setComposeMail(false);
    }, [selectedCompetition])

    const changeSelectedCompetition = (competition) => {
        setSelectedCompetition(competition);
    };

    const showComposeMailSection = async () => {
        if (selectedCompetition.uniqueId) {
            const url = `${AppConstants.fetchStudentsOfCompetitionAPI}${selectedCompetition.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setParticipantsDetails(response);
                setComposeMail(true);
            } else {
                setComposeMail(false);
                setParticipantsDetails([]);
            }
        }
    };

    return (
        <>
            <div className="expertContentSection">
                <h3 className={classes.heading}>Send Email</h3>
                <SuggestedSearchComponent changeSelectedCompetition={changeSelectedCompetition} showComposeMailSection={showComposeMailSection} />
                {composeMail && <ComposeMailForm participantsDetails={participantsDetails} competitionDetails={selectedCompetition} />}
            </div >
        </>
    )
}
