import { useState } from "react";
import parse from 'html-react-parser';
import { Row, Col, Button } from "react-bootstrap";
import classes from './DiscoverPassionContentSection.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { VideoPopup } from '../popup/VideoPopup';
import Auth from "../../utils/Auth";

export const DiscoverPassionContentSection = (props) => {
    const [discoverPassionData, setDiscoverPassionData] = useState(props.pointers);
    const [activeTab, setActiveTab] = useState(props.pointers?.filter(item => item.active === AppConstants.trueText)[0]);
    const [isClicked, setIsClicked] = useState(false);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const aboutQrenciaIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.brainBoosterIconImage}`;
    const competitionsIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.competitionsIconImage}`;
    const coursesIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.courseIconImage}`;
    const raiseNudgeIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.feedbackFromExpertIconImage}`;
    const workshopsIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.workshopIconImage}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;

    //push icons fetched from s3 to discoverPassionData
    const iconArray = [
        { "title": "About Qrencia", "iconUrl": aboutQrenciaIcon },
        { "title": "Competitions", "iconUrl": competitionsIcon },
        { "title": "Courses", "iconUrl": coursesIcon },
        { "title": "Nudges", "iconUrl": raiseNudgeIcon },
        { "title": "Workshops", "iconUrl": workshopsIcon }
    ];

    //adding icon url
    const mappedData = iconArray.map((iconObj) => {
        const matchingObject = discoverPassionData?.find((dataObj) => dataObj.title === iconObj.title);
        return {
            title: iconObj.title,
            iconUrl: iconObj.iconUrl,
            ...matchingObject,
        };
    });

    //set content of selected tab
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    function OpenVideoPopup() {
        setIsClicked(true);
    }
    function closeDiscoverPassionPopup() {
        setIsClicked(false);
    };

    const getRedirectLink = () => {
        if (activeTab.title.toUpperCase() === AppConstants.nudgesText.toUpperCase()) {
            return AppConstants.nudgesPageRoute
        } else {
            if (Auth.isLogin()) {
                if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                    if (activeTab.title.toUpperCase() === AppConstants.competitionsText.toUpperCase()) {
                        return AppConstants.studentCompetitionsPath;
                    } else if (activeTab.title.toUpperCase() === AppConstants.coursesText.toUpperCase()) {
                        return AppConstants.studentCoursesPath;
                    } else if (activeTab.title.toUpperCase() === AppConstants.workshopsText.toUpperCase()) {
                        return AppConstants.studentWorkshopsPath;
                    } else {
                        return AppConstants.studentHomePath;
                    }
                } else if (Auth.getUserRole() === AppConstants.userRoleExpert) {
                    return AppConstants.expertHomePath;
                } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
                    return AppConstants.agencyHomePath;
                } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
                    return AppConstants.adminNumberTrackingPath;
                } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
                    return AppConstants.salesNumberTrackingPath;
                } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                    return AppConstants.marketingBulkSignupPath;
                } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                    return AppConstants.partnerSuccessRecommendCreatorsPath;
                } else {
                    return AppConstants.signup;
                }
            } else {
                return AppConstants.signup;
            }
        }
    }

    return (
        <>
            <div className={classes.contentMainContainer}>
                <div className={classes.contentTabContainer}>
                    {mappedData?.filter(item => item.active === AppConstants.trueText).map((tab) => (
                        <div key={tab.id}
                            className={activeTab.id === tab.id ? `${classes.tabItem} ${classes.tabItemActive}` : `${classes.tabItem}`}
                            onClick={() => handleTabClick(tab)}>
                            <img
                                className={classes.tabIconImage}
                                src={tab.iconUrl}
                                alt="Passion List Icons"
                            />
                            <p className={classes.tabTitleText}>{tab.title}</p>
                        </div>
                    ))}
                </div>
                <div className={classes.yourPassionSectionContainer}>
                    <div className={classes.contentSection}>
                        <h1 className={classes.contentTitle}>{parse(activeTab.subTitle)}</h1>
                        <div className={classes.contentDescription}>{parse(activeTab.description)}</div>
                        {activeTab.buttonText && <div>
                            {Auth.isLogin() ? <Button target={AppConstants.openInNewTab} href={getRedirectLink()} className={classes.contentButton}>{activeTab.buttonText.split(AppConstants.discoverYourPassionButtonTextDelim)[1]}</Button> : <Button target={AppConstants.openInNewTab} href={getRedirectLink()} className={classes.contentButton}>{activeTab.buttonText.split(AppConstants.discoverYourPassionButtonTextDelim)[0]}</Button>}
                        </div>}
                    </div>
                    <div className={classes.mediaSection}>
                        {activeTab.videoPath === null && <div className={classes.mediaSectionImageContainer}>
                            <img
                                className={classes.contentMediaFile}
                                src={activeTab.imagePath}
                                alt="Passion List Icons"
                            />
                        </div>}
                        {activeTab.videoPath !== null && <div>
                            <div className={classes.videoPopupSection}>
                                <div className={classes.positionRelative}>
                                    <div className={classes.thumbnailImage}>
                                        <img onClick={OpenVideoPopup}
                                            className={classes.videoThumbnailImage}
                                            src={activeTab.imagePath}
                                            alt="Video Thumbnail"
                                        />
                                        <img onClick={OpenVideoPopup}
                                            className={classes.videoPlayIcon}
                                            src={videoPlayIconUrl}
                                            alt="Video Play Button"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {isClicked && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeDiscoverPassionPopup} videoUrl={activeTab.videoPath} />}
        </>
    );
}