import { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import classes from "./ExpertPublishUnpublishCourse.module.css";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { AppConstants } from "../../constants/AppConstants";
import { PublishedUnpublishedWorkshopCards } from "../../component/cards/publishedUnpublishedWorkshopCards/PublishedUnpublishedWorkshopCards.js";
import { EditWorkshopComponent } from "../../component/editWorkshopComponent/EditWorkshopComponent.js";

export const PublishUnpublishWorkshops = () => {
    const [publishedWorkshops, setPublishedWorkshops] = useState([]);
    const [unpublishedWorkshops, setUnpublishedWorkshops] = useState([]);
    const [publishedWorkshopSearchText, setPublishedWorkshopSearchText] = useState(AppConstants.emptyString);
    const [unpublishedWorkshopSearchText, setUnpublishedWorkshopSearchText] = useState(AppConstants.emptyString);
    const [filteredPublishedWorkshops, setFilteredPublishedWorkshops] = useState([]);
    const [filteredUnpublishedWorkshops, setFilteredUnpublishedWorkshops] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState({});
    const [editWorkshopOpen, setEditWorkshopOpen] = useState(false);
    const [refreshWorkshopsFlag, setRefreshWorkshopsFlag] = useState(false);

    useEffect(() => {
        const fetchPublishedWorkshopList = async () => {
            const url = AppConstants.fetchWorkshopsByStatusAPI + AppConstants.workshopPublishedStatus.published;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setPublishedWorkshops(response);
                setFilteredPublishedWorkshops(response);
            } else {
                setPublishedWorkshops([]);
                setFilteredPublishedWorkshops([]);
            }
        };

        const fetchUnpublishedWorkshopList = async () => {
            const url = AppConstants.fetchWorkshopsByStatusAPI + encodeURIComponent(AppConstants.workshopPublishedStatus.unpublished);
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setUnpublishedWorkshops(response);
                setFilteredUnpublishedWorkshops(response);
            } else {
                setUnpublishedWorkshops([]);
                setFilteredUnpublishedWorkshops([]);
            }
        };

        fetchPublishedWorkshopList();
        fetchUnpublishedWorkshopList();
    }, [refreshWorkshopsFlag]);

    const refreshWorkshopsData = () => {
        setRefreshWorkshopsFlag(!refreshWorkshopsFlag);
        setPublishedWorkshopSearchText(AppConstants.emptyString);
        setFilteredPublishedWorkshops(publishedWorkshops);
        setUnpublishedWorkshopSearchText(AppConstants.emptyString);
        setFilteredUnpublishedWorkshops(unpublishedWorkshops);
    }

    const searchPublishedWorkshops = (e) => {
        setPublishedWorkshopSearchText(e.target.value);
        let filteredWorkshops = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? filteredPublishedWorkshops.filter((item) => ((item.workshopTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.workshopId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : publishedWorkshops;
        setFilteredPublishedWorkshops(filteredWorkshops);
    };

    const searchUnpublishedWorkshops = (e) => {
        setUnpublishedWorkshopSearchText(e.target.value);
        let filteredWorkshops = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? filteredUnpublishedWorkshops.filter((item) => ((item.workshopTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.workshopId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : unpublishedWorkshops;
        setFilteredUnpublishedWorkshops(filteredWorkshops);
    };

    const resetPublishedWorkshopFilter = () => {
        setPublishedWorkshopSearchText(AppConstants.emptyString);
        setFilteredPublishedWorkshops(publishedWorkshops);
    };

    const resetUnpublishedWorkshopFilter = () => {
        setUnpublishedWorkshopSearchText(AppConstants.emptyString);
        setFilteredUnpublishedWorkshops(unpublishedWorkshops);
    };

    const onClickEdit = (workshop) => {
        if (workshop.workshopStatus === AppConstants.workshopStatus.past) return;
        setEditWorkshopOpen(true);
        setSelectedWorkshop(workshop);
    }

    const onHideEdit = () => {
        setSelectedWorkshop({});
        setEditWorkshopOpen(false);
        refreshWorkshopsData();
    }

    return (
        <>
            {!editWorkshopOpen && <div>
                <h2 className={classes.heading}>Unpublished Workshops</h2>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={unpublishedWorkshopSearchText} onChange={(e) => { searchUnpublishedWorkshops(e) }} placeholder="Search workshops" />
                    {unpublishedWorkshopSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetUnpublishedWorkshopFilter() }} />}
                </div>
                {filteredUnpublishedWorkshops.length !== 0 && <div>
                    <PublishedUnpublishedWorkshopCards
                        workshopData={filteredUnpublishedWorkshops.length === 0 ? unpublishedWorkshops : filteredUnpublishedWorkshops}
                        workshopType={AppConstants.workshopPublishedStatus.unpublished}
                        refreshWorkshopsData={refreshWorkshopsData}
                        onClickEdit={onClickEdit}
                    />
                </div>}
                {filteredUnpublishedWorkshops.length === 0 && <div>
                    <p className="noDataAvailableError">{ErrorMessages.noUnpublishedWorkshopsFallbackMessage}</p>
                </div>}
            </div>}
            {!editWorkshopOpen && <div className={classes.publishedCardsSection}>
                <h2 className={classes.heading}>Published Workshops</h2>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={publishedWorkshopSearchText} onChange={(e) => { searchPublishedWorkshops(e) }} placeholder="Search workshops" />
                    {publishedWorkshopSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetPublishedWorkshopFilter() }} />}
                </div>
                {filteredPublishedWorkshops.length !== 0 && <div>
                    <PublishedUnpublishedWorkshopCards
                        workshopData={filteredPublishedWorkshops.length === 0 ? publishedWorkshops : filteredPublishedWorkshops}
                        workshopType={AppConstants.workshopPublishedStatus.published}
                        refreshWorkshopsData={refreshWorkshopsData}
                        onClickEdit={onClickEdit}
                    />
                </div>}
                {filteredPublishedWorkshops.length === 0 && <div>
                    <p className="noDataAvailableError">{ErrorMessages.noPublishedWorkshopsFallbackMessage}</p>
                </div>}
            </div>}
            {editWorkshopOpen && selectedWorkshop.uniqueId && <div className={classes.showEditSection}>
                <EditWorkshopComponent selectedWorkshop={selectedWorkshop.uniqueId} onHideEdit={onHideEdit} />
            </div>}
        </>
    )
}