import { useState } from 'react';
import { Button, Modal, Row } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorSuccessAlertMessage } from "../../component/errorSuccessMessage/ErrorSuccessAlertMessage";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from "../../constants/ErrorMessages";
import { BiLoaderAlt } from "react-icons/bi";
import { FileUploadInput } from '../../component/fileUploadInput/FileUploadInput';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import modalClass from "../../component/cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { HiOutlineDownload } from 'react-icons/hi';
import classes from "./BulkUploadSheetComponent.module.css";

export const BulkUploadSheetComponent = (props) => {
    const { downloadTemplateAPI, uploadSheetAPI, downloadedSheetName, multipartFileName, heading, uploadFileLabel, successMessage } = props;
    const [templateSpinner, setTemplateSpinner] = useState(AppConstants.falseText);
    const [downloadTemplateError, setDownloadTemplateError] = useState(AppConstants.emptyString);
    const [studentSheet, setParticipantSheet] = useState(AppConstants.emptyString);
    const [uploadSheetSpinner, setUploadSheetSpinner] = useState(AppConstants.falseText);
    const [fileError, setFileError] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const fileUploadFieldId = "studentSheetInput";

    const onCloseModal = () => {
        setShowModal(false);
    };

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setParticipantSheet(AppConstants.emptyString);
    }

    const downloadTemplate = async () => {
        try {
            setTemplateSpinner(AppConstants.trueText);
            const url = downloadTemplateAPI;
            const response = await FileOperationsUtil.downloadFile(
                url,
                downloadedSheetName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok) {
                    setTemplateSpinner(AppConstants.falseText);
                } else {
                    setTemplateSpinner(AppConstants.falseText);
                    setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
                    let timer = setTimeout(() => {
                        setDownloadTemplateError(AppConstants.falseText);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setTemplateSpinner(AppConstants.falseText);
                setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
                let timer = setTimeout(() => {
                    setDownloadTemplateError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setTemplateSpinner(AppConstants.falseText);
            setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
            let timer = setTimeout(() => {
                setDownloadTemplateError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const uploadSheet = async () => {
        try {
            if (AppConstants.excelSheetType !== studentSheet.type) {
                setFileError(ErrorMessages.bulkEnrolSheetFileTypeError);
                let timer = setTimeout(() => {
                    setFileError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else {
                setUploadSheetSpinner(AppConstants.trueText);
                let formData = new FormData();

                formData.append(multipartFileName, studentSheet);

                let url = uploadSheetAPI;
                let response = await AuthorizedDataService.postMultipartRequest(
                    url,
                    formData,
                    AppConstants.emptyString,
                    AppConstants.emptyString);

                if (response.ok) {
                    let message = successMessage;
                    let body;
                    if (!successMessage) {
                        body = await response.json();
                        message = body.message;
                    }
                    removeFile(fileUploadFieldId);
                    setUploadSheetSpinner(AppConstants.falseText);
                    setModalMessage(message);
                    setModalTypeError(AppConstants.falseText);
                    setShowModal(AppConstants.trueText);
                } else {
                    const body = await response.json();
                    removeFile(fileUploadFieldId);
                    setUploadSheetSpinner(AppConstants.falseText);
                    setModalMessage(body.message)
                    setModalTypeError(AppConstants.trueText);
                    setShowModal(AppConstants.trueText);
                }
            }
        } catch (error) {
            removeFile(fileUploadFieldId);
            setUploadSheetSpinner(AppConstants.falseText);
            setModalMessage(ErrorMessages.bulkEnrolUploadSheetError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        }
    };
    return (
        <div className="adminContentSection">
            <h3 className={classes.heading}>{heading}</h3>
            <div className={classes.bulkEnrolmentSheetContainer}>
                <Button className={classes.templateButton} onClick={downloadTemplate}>Template {templateSpinner ? <BiLoaderAlt className="spinner" /> : <HiOutlineDownload />} </Button>
                <Row>
                    <div className={classes.uploadFileSection}>
                        <div>
                            <FileUploadInput id={fileUploadFieldId} name={fileUploadFieldId} accept={AppConstants.excelSheetType} label={uploadFileLabel} handleFileChange={event => setParticipantSheet(event.target.files[AppConstants.zeroIndex])} inputFile={studentSheet} />
                            {studentSheet && studentSheet.size > 0 && <p onClick={() => { removeFile(fileUploadFieldId) }} className='removeFileText'>Remove file</p>}
                        </div>
                        <Button onClick={uploadSheet} disabled={uploadSheetSpinner || !studentSheet?.size} className={classes.buttons}>Upload {uploadSheetSpinner && <BiLoaderAlt className="spinner" />}</Button>
                    </div>
                </Row>

                {fileError !== AppConstants.emptyString && <ErrorSuccessAlertMessage message={fileError} varient={AppConstants.alertVarient[1]} />}
                {downloadTemplateError.length > 0 && <ErrorSuccessAlertMessage message={downloadTemplateError} varient={AppConstants.alertVarient[1]} />}

                <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}