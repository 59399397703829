import React from "react";
import { useState } from "react";
import parse from 'html-react-parser';
import { AppConstants } from "../../../constants/AppConstants";
import classes from './RefundPolicyPageBannerSection.module.css';

export const RefundPolicyPageBannerSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const sectionBackgroundImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.qrenciaSkillsBackgroundImagePath}`;
    const [bgImage, setBgImage] = useState(sectionBackgroundImageUrl);

    return (
        <>
        <div className={classes.refundPolicyMainContainer} style={{ backgroundImage: `url(${bgImage})`}}>     
           <div className={classes.refundPolicySubContainer}>
              <div className={classes.refundPolicyHeading}>
               <h1 className={classes.refundPolicyHeadingTitle}>{props.refundPolicyData[0].title}</h1></div>
              <div className={classes.refundPolicyDate}>{props.refundPolicyData[0].subTitle}</div>
              <div className={classes.refundPolicyDescription}>{parse(props.refundPolicyData[0].description)}</div>
           </div>
        </div>
        </>
    )
}