import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import Auth from "../../utils/Auth";

export const EnrolInWorkshop = {

    enrolInSelectedWorkshop(workshopId, cost) {
        return this.enrol(workshopId, cost);
    },

    async enrol({ uniqueId, cost }) {
        try {
            if (!(Auth.isLogin())) {
                return { message: ErrorMessages.pleaseLogInToEnrolError };
            } else {
                if (Auth.getUserRole() !== AppConstants.userRoleStudent) {
                    return { message: ErrorMessages.onlyStudentEnrolWorkshopError };
                } else {
                    const url = `${AppConstants.enrolInWorkshopAPI}${uniqueId}`;
                    const response = await AuthorizedDataService.postRequestWithResponseCode(
                        url,
                        AppConstants.emptyString,
                        AppConstants.emptyString
                    );

                    if (response) {
                        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                            return { message: ErrorMessages.sessionExpiredWorkshopEnrolmentError };
                        } else {
                            const data = await response.json();
                            return { message: this.getEnrolmentStatus(data, cost) };
                        }
                    } else {
                        return { message: this.getEnrolmentStatus(AppConstants.nullText, cost) };
                    }
                }
            }
        } catch {
            if (cost === 0 || cost === AppConstants.nullText || cost === AppConstants.freeText) {
                return { message: ErrorMessages.enrollmentFailError };
            } else {
                return { message: ErrorMessages.paymentDeductedEnrolmentError };
            }
        }
    },

    getEnrolmentStatus(data, cost) {
        if (data === 1) {
            return SuccessMessage.studentEnrollmentSuccess;
        } else if (data === -111) {
            return ErrorMessages.alreadyEnroledInWorkshopError;
        } else if (data === -113) {
            return ErrorMessages.workshopRegistrationEndedError;
        } else if (data === -121) {
            return ErrorMessages.unpublishedWorkshopEnrolmentError;
        } else if (data === -143) {
            return ErrorMessages.reRequestForWorkshopCODError;
        } else {
            if (cost === 0 || cost === AppConstants.nullText || cost === undefined || cost === AppConstants.emptyString || cost === AppConstants.freeText) {
                return ErrorMessages.enrollmentFailError;
            } else {
                return ErrorMessages.paymentDeductedEnrolmentError;
            }
        }
    }
}