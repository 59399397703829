import React, { useState, useEffect, useRef } from "react";
import formClass from "../../../../common/FormStyling.module.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css'
import validator from "validator";
import classes from '../createObrForms/AdminGetObrForEmailForm.module.css';
import { AppConstants } from "../../../../constants/AppConstants";
import { ErrorMessages } from '../../../../constants/ErrorMessages';
import { FormValidationUtils } from "../../../../utils/FormValidationUtils";
import { Typeahead } from 'react-bootstrap-typeahead';
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { BiLoaderAlt } from "react-icons/bi";
import { SuccessMessage } from "../../../../constants/SuccessMessage";
import "../createObrForms/AdminSubmitObrForm.css";
import Auth from "../../../../utils/Auth";
import { DateTime } from "luxon";
import { DateTimeUtil } from "../../../../utils/DateTimeUtil";

export const GenerateLeadForm = (props) => {
    const { selectedLead, updateFlag, isOnboarded } = props;
    const initialValues = {
        firstName: AppConstants.emptyString,
        lastName: AppConstants.emptyString,
        email: AppConstants.emptyString,
        phoneNumber: AppConstants.emptyString,
        dob: AppConstants.emptyString,
        countryId: AppConstants.emptyString,
        country: AppConstants.emptyString,
        state: AppConstants.emptyString,
        city: AppConstants.emptyString,
        selectedSkills: AppConstants.emptyString,
        instituteName: AppConstants.emptyString,
        websiteLink: AppConstants.emptyString,
        source: Auth.getLoggedInName(),
        leadType: AppConstants.leadTypes.expert,
        leadUniqueId: AppConstants.emptyString,
        rating: AppConstants.creatorRating.fiveStar,
        listingEnabled: AppConstants.trueText,
        topCreatorFlag: AppConstants.falseText,
        estimatedOnboardingDate: AppConstants.emptyString,
        isExistingBussinessOnQ: AppConstants.emptyString,
        currentStatus: AppConstants.currentLeadStatusOptions[0],
        remarks: AppConstants.emptyString,
        physicalBranding: AppConstants.falseText
    };

    const skillsRef = useRef(null);
    const countryRef = useRef(null);
    const [submitSpinner, setSubmitSpinner] = useState(AppConstants.falseText);
    const [formHasError, setFormHasError] = useState(AppConstants.falseText);
    const [submitFormMessage, setSubmitFormMessage] = useState(AppConstants.emptyString);
    const [skillDetails, setSkillDetails] = useState([]);
    const [alertVarient, setAlertVarient] = useState(AppConstants.emptyString);
    const [cityList, setCityList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
    const [selectedCity, setSelectedCity] = useState(AppConstants.emptyString);
    const [selectedState, setSelectedState] = useState(AppConstants.emptyString);
    const [agencyType, setAgencyType] = useState((updateFlag && selectedLead.leadType !== AppConstants.userRoleExpert) ? selectedLead.leadType : AppConstants.emptyString);
    const [userRole, setUserRole] = useState((updateFlag && selectedLead.leadType !== AppConstants.userRoleExpert) ? AppConstants.userRoleAgency : AppConstants.userRoleExpert);
    const [formLabels, setFormLabels] = useState((updateFlag && selectedLead.leadType !== AppConstants.userRoleExpert) ? AppConstants.generateLeadsForAgencyLabel : AppConstants.generateLeadsForExpertLabel);
    const [formValues, setFormValues] = useState(initialValues);
    const [defaultCountry, setDefaultCountry] = useState([]);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const index = 0;

    useEffect(() => {
        if (props?.countryList?.length > 0) {
            setDefaultCountry(props.countryList?.filter(country => country.id === 1))
        }
    }, [props.countryList]);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });


    //fetch All skills supported by platform
    useEffect(() => {
        setSkillDetails(props.skillDetails);
        if (updateFlag) {
            let estimatedOnboardingDate = selectedLead.estimatedOnboardingDate ? DateTime.fromISO(`${selectedLead.estimatedOnboardingDate?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat) : AppConstants.emptyString;
            setFormValues({
                ...selectedLead, estimatedOnboardingDate,
                currentStatus: selectedLead.currentStatus || AppConstants.currentLeadStatusOptions[0],
                topCreatorFlag: selectedLead.topCreatorFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText,
                listingEnabled: selectedLead.listingEnabled === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText,
                rating: isOnboarded ? selectedLead.leadType === AppConstants.leadTypes.expert ? selectedLead.expertRating : selectedLead.agencyRating : selectedLead.rating,
                physicalBranding: selectedLead.physicalBranding === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText
            });
        }
    }, [selectedLead, updateFlag, props.skillDetails, timeZone]);

    //initialise form
    useEffect(() => {
        async function fetchCities(state) {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[index]?.iso2}${AppConstants.getCityUri}`, config);
            const responseData = await response.json();
            if (responseData.length !== index) {
                setCityList(responseData);
                if (selectedLead.expertCity !== AppConstants.nullText) {
                    setSelectedCity(responseData.filter(item => item.name === selectedLead.city));
                }
            }
            return null;
        };

        if (selectedLead && selectedLead.countryId && selectedLead.countryId !== index) {
            setSelectedCountry(props.countryList.filter(item => item.id === selectedLead.countryId));
        }
        if (selectedLead && selectedLead.state !== AppConstants.nullText) {
            const state = props.stateList.filter(item => item.name === selectedLead.state);
            setSelectedState(state);
            if ((selectedLead.countryId == AppConstants.countryIdIndia && state[index] !== undefined && state[index]?.name !== AppConstants.emptyString)) {
                fetchCities(state)
            }
        }

        if (props.countryList?.length && !isOnboarded && !updateFlag) {
            handleCountryChange(props.countryList?.filter(country => country.id === 1));
        }

    }, [props.countryList, props.stateList]);

    async function fetchCitiesOnStateChange(country, state) {
        if ((country[index]?.country === AppConstants.countryIndia && state[index]?.name !== AppConstants.emptyString)) {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[index]?.iso2}${AppConstants.getCityUri}`, config);
            const responseData = await response.json();
            if (responseData.length !== index) {
                setCityList(responseData);
            } else {
                setCityList([]);
            }
            return null;
        }
    }

    const validatePhoneNumber = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    // method to get form errors
    function getFormErrors() {
        let isSubmit = AppConstants.trueText;

        if (userRole === AppConstants.userRoleAgency && !AppConstants.obrAgencyTypes.includes(agencyType)) {
            setSubmitFormMessage(ErrorMessages.agencyObrMissingAgencyType);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }

        if (userRole === AppConstants.userRoleAgency && (formValues.instituteName === AppConstants.nullText || formValues.instituteName === AppConstants.emptyString || formValues.instituteName === undefined)) {
            setSubmitFormMessage(ErrorMessages.agencyObrMissingName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.userRoleAgency && formValues.instituteName?.trim().length === 0) {
            setSubmitFormMessage(ErrorMessages.agencyObrInValidName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.userRoleAgency && formValues.instituteName?.includes(`"`)) {
            setSubmitFormMessage(ErrorMessages.agencyNameDoubleQuotesError);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.userRoleAgency && (FormValidationUtils.isLengthOfStringShort(formValues.instituteName?.trim(), 2))) {
            setSubmitFormMessage(ErrorMessages.agencyObrfirstNameMinLength);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.firstName === AppConstants.nullText || formValues.firstName === AppConstants.emptyString || formValues.firstName === undefined) {
            setSubmitFormMessage(ErrorMessages.expertObrMissingFirstName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.firstName?.trim().length === 0) {
            setSubmitFormMessage(ErrorMessages.expertObrInValidFirstName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if ((FormValidationUtils.isLengthOfStringShort(formValues.firstName?.trim(), 2))) {
            setSubmitFormMessage(ErrorMessages.expertObrfirstNameMinLength);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (!(FormValidationUtils.isValidString(formValues.firstName))) {
            setSubmitFormMessage(ErrorMessages.expertObrIncorrectFirstName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }

        if ((formValues.lastName === AppConstants.emptyString || formValues.lastName === AppConstants.nullText || formValues.lastName === undefined)) {
            setSubmitFormMessage(ErrorMessages.expertObrMissingLastName);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.lastName?.trim().length === 0) {
            setSubmitFormMessage(ErrorMessages.expertObrInValidLastName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (!(FormValidationUtils.isValidString(formValues.lastName))) {
            setSubmitFormMessage(ErrorMessages.expertObrIncorrectLastName);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if ((FormValidationUtils.isLengthOfStringShort(formValues.lastName?.trim(), 2))) {
            setSubmitFormMessage(ErrorMessages.expertObrLasttNameMinLength);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (!formValues.email) {
            setSubmitFormMessage("Error! Missing Email Id");
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (!(FormValidationUtils.isValidEmail(formValues.email?.trim()))) {
            setSubmitFormMessage("Error! Invalid Email Id");
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }

        if (userRole === AppConstants.userRoleExpert && formValues.dob && !(FormValidationUtils.isValidDateOfBirth(formValues.dob))) {
            setSubmitFormMessage(ErrorMessages.expertObrIncorrectDob);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }

        if (formValues.countryId === index || formValues.countryId === AppConstants.emptyString || formValues.countryId === AppConstants.nullText || formValues.countryId === undefined) {
            setSubmitFormMessage(ErrorMessages.obrCountryMissingError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if ((formValues.country === AppConstants.countryIndia) && (formValues.state === AppConstants.nullText || formValues.state === AppConstants.emptyString || formValues.state === undefined)) {
            setSubmitFormMessage(ErrorMessages.obrMissingStateError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if ((formValues.country !== AppConstants.countryIndia) && (formValues.state ? formValues.state.trim().length === 0 : false)) {
            setSubmitFormMessage(ErrorMessages.createObrInvalidStateError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.city === AppConstants.nullText || formValues.city === AppConstants.emptyString || formValues.city === undefined || formValues.city?.trim()?.length === 0) {
            setSubmitFormMessage(ErrorMessages.obrMissingCityError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.phoneNumber === AppConstants.nullText || formValues.phoneNumber === undefined || formValues.phoneNumber === AppConstants.emptyString) {
            setSubmitFormMessage(ErrorMessages.obrMissingPhoneNumber);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.country === AppConstants.countryIndia && !(FormValidationUtils.isValidIndiaMobileNumber(formValues.phoneNumber))) {
            setSubmitFormMessage(ErrorMessages.createOBRInvalidMobileNumberError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.country !== AppConstants.countryIndia && !(FormValidationUtils.isValidInternationalMobileNumber(formValues.phoneNumber))) {
            setSubmitFormMessage(ErrorMessages.createOBRInvalidMobileNumberError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.expert && formValues.instituteName && formValues.instituteName?.trim().length === 0) {
            setSubmitFormMessage(ErrorMessages.agencyObrInValidName);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.expert && formValues.instituteName && formValues.instituteName?.includes(`"`)) {
            setSubmitFormMessage(ErrorMessages.agencyNameDoubleQuotesError);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (userRole === AppConstants.expert && formValues.instituteName && (FormValidationUtils.isLengthOfStringShort(formValues.instituteName?.trim(), 2))) {
            setSubmitFormMessage(ErrorMessages.agencyObrfirstNameMinLength);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (formValues.websiteLink && !validator.isURL(formValues.websiteLink?.trim())) {
            setSubmitFormMessage(ErrorMessages.enterValidWebsiteLinkError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }
        if (((userRole === AppConstants.userRoleAgency && agencyType === AppConstants.agencyTypes.academy) || userRole === AppConstants.userRoleExpert) && (formValues.selectedSkills === AppConstants.nullText || formValues.selectedSkills?.length === index)) {
            setSubmitFormMessage(ErrorMessages.obrMissingSkills);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        };

        if (!isOnboarded && updateFlag && formValues.estimatedOnboardingDate && DateTimeUtil.createDateTimeObject(formValues.estimatedOnboardingDate, "00:00")?.valueOf() < DateTime.now().startOf("day").valueOf()) {
            setSubmitFormMessage(ErrorMessages.demoDatePastDateError);
            setFormHasError(AppConstants.trueText);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
            return isSubmit;
        }

        return isSubmit;
    }

    //handle country change
    function handleCountryChange(value) {
        if (value?.length !== index) {
            setSelectedCountry(value);
            setSelectedState([]);
            setSelectedCity([]);
            setFormValues({
                ...formValues,
                countryId: value[index].id,
                country: value[index].country,
                city: AppConstants.emptyString,
                state: AppConstants.emptyString
            });
        }
        else {
            setSelectedCountry([]);
            setSelectedState([]);
            setSelectedCity([]);
            setFormValues({
                ...formValues,
                countryId: AppConstants.emptyString,
                country: AppConstants.emptyString,
                city: AppConstants.emptyString,
                state: AppConstants.emptyString
            });
        }
    }

    //handle city change

    function handleCityChange(value) {
        if (value.length !== index) {
            setSelectedCity(value);
            setFormValues({
                ...formValues,
                city: value[index].name
            });
        }
        else {
            setSelectedCity([]);
            setFormValues({
                ...formValues,
                city: AppConstants.emptyString
            });
        }
    }

    //handle state change
    function handleStateChange(value) {
        if (value.length !== index) {
            setSelectedState(value);
            setSelectedCity([]);
            fetchCitiesOnStateChange(selectedCountry, value);
            setFormValues({
                ...formValues,
                state: value[index].name,
                city: AppConstants.emptyString
            });
        }
        else {
            setSelectedState([]);
            setSelectedCity([]);
            setFormValues({
                ...formValues,
                state: AppConstants.emptyString,
                city: AppConstants.emptyString
            });
        }
    };

    //Change of Input email handler
    function handleInputChange(e) {
        setFormHasError(AppConstants.falseText);
        setSubmitSpinner(AppConstants.falseText);
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    //Method to submit data on backend
    async function submitLeadFormData(e) {
        try {
            e?.preventDefault();
            setSubmitSpinner(AppConstants.trueText);
            setFormHasError(AppConstants.falseText);

            if (getFormErrors()) {
                if (userRole !== AppConstants.userRoleExpert && formValues.state === AppConstants.emptyString) {
                    formValues.state = null;
                }
                let trimmedFormValues = {
                    ...formValues,
                    remarks: formValues.remarks ? formValues.remarks.trim() : AppConstants.nullText,
                    physicalBranding: formValues.physicalBranding ? AppConstants.yText : AppConstants.nText,
                    state: formValues.state ? formValues.state : AppConstants.nullText,
                    dob: formValues.dob || AppConstants.nullText,
                    leadType: userRole === AppConstants.userRoleExpert ? AppConstants.userRoleExpert : agencyType,
                    leadUniqueId: updateFlag ? selectedLead.leadUniqueId : AppConstants.nullText,
                    selectedSkills: ((userRole === AppConstants.userRoleAgency && agencyType === AppConstants.agencyTypes.academy) || userRole === AppConstants.userRoleExpert) ? formValues.selectedSkills : AppConstants.nullText,
                    listingEnabled: formValues.listingEnabled ? AppConstants.yText : AppConstants.nText,
                    topCreatorFlag: formValues.topCreatorFlag ? AppConstants.yText : AppConstants.nText,
                    isExistingBussinessOnQ: formValues.isExistingBussinessOnQ ? formValues.isExistingBussinessOnQ : AppConstants.nullText,
                    stringEstimatedOnboardingDate: formValues.estimatedOnboardingDate ? DateTimeUtil.createDateTimeObject(formValues.estimatedOnboardingDate, "00:00")?.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(DateTimeUtil.getPreferredTimeZoneForUser())})\'`) : AppConstants.nullText,
                    locale: DateTimeUtil.getPreferredTimeZoneForUser()
                };

                const submitLeadURL = AppConstants.captureQrenciaLeadsAPI;
                trimmedFormValues.firstName = trimmedFormValues?.firstName?.trim();
                trimmedFormValues.instituteName = trimmedFormValues.instituteName ? trimmedFormValues?.instituteName?.trim() : AppConstants.nullText;
                trimmedFormValues.lastName = trimmedFormValues?.lastName?.trim();
                trimmedFormValues.websiteLink = trimmedFormValues.websiteLink ? trimmedFormValues.websiteLink?.trim() : AppConstants.nullText;
                trimmedFormValues.email = trimmedFormValues.email?.trim();

                const data = trimmedFormValues;
                const response = await AuthorizedDataService.postRequestWithResponseCode(
                    submitLeadURL,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response && (response?.status === AppConstants.httpResponseCodes.responseCode200 || response?.status === AppConstants.httpResponseCodes.responseCode201)) {
                    setSubmitSpinner(AppConstants.falseText);
                    if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                        isOnboarded ? setSubmitFormMessage("Details updated successfully!") : setSubmitFormMessage("Lead details updated successfully!");
                    } else if (response.status === AppConstants.httpResponseCodes.responseCode201) {
                        setSubmitFormMessage(SuccessMessage.leadCreatedSuccessfully);
                        props.refreshNonOnboardedLeads();
                    }
                    if (!updateFlag && !isOnboarded) {
                        setFormValues({
                            ...initialValues,
                            countryId: defaultCountry[0].id,
                            country: defaultCountry[0].country,
                            city: AppConstants.emptyString,
                            state: AppConstants.emptyString
                        });
                        setSelectedCountry(defaultCountry)
                        setSelectedState([]);
                        setSelectedCity([]);
                        setAgencyType(AppConstants.emptyString);
                        skillsRef.current?.clear();
                    }
                    if (updateFlag && !isOnboarded) {
                        props.refreshNonOnboardedLeads();
                    }

                    if (updateFlag && isOnboarded) {
                        props.refreshOnboardedLeads();
                    }
                    setAlertVarient(AppConstants.alertVarient[index]);
                    setFormHasError(AppConstants.trueText);
                    const timer = setTimeout(() => {
                        setFormHasError(AppConstants.falseText);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
                    setSubmitFormMessage(ErrorMessages.leadEmailAlreadyExistsError);
                    setSubmitSpinner(AppConstants.falseText);
                    setFormHasError(AppConstants.trueText);
                    setAlertVarient(AppConstants.alertVarient[1]);
                    setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
                } else {
                    setSubmitFormMessage(ErrorMessages.obrSubmitError);
                    setSubmitSpinner(AppConstants.falseText);
                    setFormHasError(AppConstants.trueText);
                    setAlertVarient(AppConstants.alertVarient[1]);
                    setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
                }
            } else {
                setSubmitSpinner(AppConstants.falseText);
                setFormHasError(AppConstants.trueText);
                setAlertVarient(AppConstants.alertVarient[1]);
                setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            }
        } catch (error) {
            setSubmitFormMessage(ErrorMessages.somethingWentWrongPleaseTryAgainError);
            setSubmitSpinner(AppConstants.falseText);
            setFormHasError(AppConstants.trueText);
            setAlertVarient(AppConstants.alertVarient[1]);
            setTimeout(() => setFormHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
        }
    };

    //handle skill change
    function handleSkillChange(value) {
        setFormValues({
            ...formValues,
            selectedSkills: value.map(item => ({
                skillId: item.skillId,
                skillName: item.skill,
                skillIntegerId: item.skillUniqueId
            }))
        });
    };

    function handleAgencyTypeChange(value) {
        setFormValues({
            ...formValues,
            skill: AppConstants.emptyString
        })
        setAgencyType(value);
    };

    const changeUserRole = (e) => {
        setUserRole(e.target.value);
        setAgencyType(AppConstants.emptyString);
        setFormValues({
            ...initialValues,
            countryId: defaultCountry[0].id,
            country: defaultCountry[0].country,
            city: AppConstants.emptyString,
            state: AppConstants.emptyString
        });
        setSelectedState([]);
        setSelectedCity([]);
        e.target.value === AppConstants.userRoleExpert ? setFormLabels(AppConstants.generateLeadsForExpertLabel) : setFormLabels(AppConstants.generateLeadsForAgencyLabel);
    };

    const hideEditForm = () => {
        props.closeEditModal();
    };

    return (
        <>
            <div className={`${!updateFlag && classes.createObrFormContainer} ${!updateFlag && classes.marginTop1em}`}>
                <form id="submitLeadDetails" >
                    {<Row className={`${classes.adminSubmitObrFormRow}`}>
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>User Role<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name="userRole"
                                value={userRole}
                                onChange={changeUserRole}
                                disabled={updateFlag}
                            >
                                {AppConstants.obrUserRoles?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        {userRole === AppConstants.userRoleAgency && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>Agency Type<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name="agencyType"
                                value={agencyType}
                                onChange={e => handleAgencyTypeChange(e.target?.value)}
                                required
                                disabled={isOnboarded}
                            ><option value={AppConstants.emptyString} defaultValue>Select Agency Type</option>
                                {AppConstants.obrAgencyTypes?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>}
                        {userRole === AppConstants.userRoleAgency && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.instituteName.label}{userRole === AppConstants.userRoleAgency && <span className="redAstrisk">*</span>}</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="text"
                                    onChange={e => handleInputChange(e)}
                                    value={formValues?.instituteName}
                                    name={formLabels.instituteName.name}
                                    disabled={isOnboarded || selectedLead?.obrState === AppConstants.expertObrStatus.statusInReview}
                                    required minLength={3} maxLength={65}
                                />
                            </div>
                        </Col>}
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.firstName.label}<span className="redAstrisk">*</span></Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="text"
                                    onChange={e => handleInputChange(e)}
                                    value={formValues?.firstName}
                                    name={formLabels.firstName.name}
                                    disabled={isOnboarded}
                                    required minLength={3} maxLength={40}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.lastName.label}<span className="redAstrisk">*</span></Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="text"
                                    onChange={e => handleInputChange(e)}
                                    name={formLabels.lastName.name}
                                    value={formValues?.lastName}
                                    disabled={isOnboarded}
                                    required minLength={3} maxLength={40}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.email.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="email"
                                onChange={e => handleInputChange(e)}
                                name={formLabels.email.name}
                                value={formValues?.email}
                                maxLength={50}
                                required
                                disabled={updateFlag}
                                readOnly={updateFlag}
                            >
                            </Form.Control>
                        </Col>
                        {userRole === AppConstants.userRoleExpert && <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.dob.label}</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="date"
                                    onChange={e => handleInputChange(e)}
                                    name={formLabels.dob.name}
                                    value={formValues?.dob}
                                    disabled={isOnboarded}
                                    required
                                />
                            </div>
                        </Col>}
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.country.label}<span className="redAstrisk">*</span></Form.Label>
                            <Typeahead
                                className={`${classes.expertObrEmailContainer} adminCreateObrTypeahead`}
                                name={formLabels.country.name}
                                id={formLabels.country.name}
                                labelKey={formLabels.country.name}
                                selected={selectedCountry}
                                disabled={isOnboarded}
                                onChange={e => handleCountryChange(e)}
                                options={props.countryList}
                                required
                                ref={countryRef}
                            />
                        </Col>
                        {(formValues?.country !== AppConstants.nullText && formValues?.country !== AppConstants.emptyString && formValues.country !== undefined) && <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.state.label}{(formValues.country !== `${AppConstants.nullText}` && formValues.country !== `${AppConstants.emptyString}` && formValues.country !== undefined && formValues.country === AppConstants.countryIndia) && <span className="redAstrisk">*</span>}</Form.Label>
                            {(formValues?.country !== AppConstants.nullText && formValues?.country !== AppConstants.emptyString && formValues.country !== undefined && formValues.country === AppConstants.countryIndia) && <Typeahead
                                className={`${classes.expertObrEmailContainer} adminCreateObrTypeahead`}
                                name={formLabels.state.name}
                                id={formLabels.state.name}
                                labelKey="name"
                                onChange={e => handleStateChange(e)}
                                selected={selectedState}
                                disabled={isOnboarded}
                                options={props.stateList}
                                required
                            />}
                            {(formValues.country !== AppConstants.nullText && formValues.country !== AppConstants.emptyString && formValues.country !== undefined && formValues.country !== AppConstants.countryIndia)
                                && <div className={classes.expertObrEmailContainer}>
                                    <input
                                        type="text"
                                        onChange={e => handleInputChange(e)}
                                        name={formLabels.state.name}
                                        value={formValues.state}
                                        disabled={isOnboarded}
                                    />
                                </div>}
                        </Col>}
                        {(formValues.country !== AppConstants.nullText && formValues.country !== AppConstants.emptyString && formValues.country !== undefined) && <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.city.label}<span className="redAstrisk">*</span></Form.Label>
                            {(formValues.country !== AppConstants.nullText && formValues.country !== AppConstants.emptyString && formValues.country !== undefined && formValues.country === AppConstants.countryIndia) && <Typeahead
                                className={`${classes.expertObrEmailContainer} adminCreateObrTypeahead`}
                                name={formLabels.city.name}
                                id={formLabels.city.name}
                                labelKey="name"
                                selected={selectedCity}
                                onChange={e => handleCityChange(e)}
                                options={cityList}
                                disabled={isOnboarded}
                                required
                            />}
                            {(formValues.country !== AppConstants.nullText && formValues.country !== AppConstants.emptyString && formValues.country !== undefined && formValues.country !== AppConstants.countryIndia)
                                && <div className={classes.expertObrEmailContainer}>
                                    <input
                                        type="text"
                                        onChange={e => handleInputChange(e)}
                                        name={formLabels.city.name}
                                        value={formValues.city}
                                        disabled={isOnboarded}
                                    />
                                </div>}
                        </Col>}
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.phoneNumber.label}<span className="redAstrisk">*</span></Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="number"
                                    onChange={e => handleInputChange(e)}
                                    name={formLabels.phoneNumber.name}
                                    onKeyDown={validatePhoneNumber}
                                    disabled={isOnboarded}
                                    value={formValues.phoneNumber !== index ? formValues.phoneNumber : AppConstants.emptyString}
                                    required
                                />
                            </div>
                        </Col>
                        {userRole === AppConstants.userRoleExpert && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.instituteName.label}</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="text"
                                    onChange={e => handleInputChange(e)}
                                    value={formValues?.instituteName}
                                    name={formLabels.instituteName.name}
                                    required minLength={3} maxLength={65}
                                    disabled={isOnboarded}
                                />
                            </div>
                        </Col>}
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.websiteLink.label}</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="url"
                                    onChange={e => handleInputChange(e)}
                                    name={formLabels.websiteLink.name}
                                    value={formValues?.websiteLink}
                                    required
                                    disabled={isOnboarded}
                                />
                            </div>
                        </Col>
                        {!isOnboarded && ((userRole === AppConstants.userRoleExpert) || (userRole === AppConstants.userRoleAgency && agencyType === AppConstants.agencyTypes.academy)) && <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                            <Form.Label className={`${formClass.formLabel}`}>{formLabels.selectedSkills.label}<span className="redAstrisk">*</span></Form.Label>
                            <Typeahead
                                className={`${classes.expertObrEmailContainer} adminCreateObrTypeahead adminCreateObrTypeaheadMultiple`}
                                name={formLabels.selectedSkills.name}
                                id={formLabels.selectedSkills.name}
                                labelKey={AppConstants.expertCreateObrForm.selectedSkills.name}
                                multiple
                                onChange={handleSkillChange}
                                options={skillDetails}
                                readOnly={isOnboarded}
                                ref={skillsRef}
                            />
                            {(formValues?.selectedSkills !== AppConstants.nullText && formValues?.selectedSkills?.length > index) &&
                                <p className={classes.expertObrSelectedSkillsList}>{formValues?.selectedSkills?.map(item => item.skillName).join(", ")}</p>
                            }
                        </Col>}
                        {!isOnboarded && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>{formLabels.currentStatus.label}</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.currentStatus.name}
                                value={formValues.currentStatus}
                                disabled={isOnboarded}
                                onChange={(e) => setFormValues({ ...formValues, currentStatus: e.target.value })}
                            >
                                {Object.values(AppConstants.currentLeadStatusOptions)?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>}
                        <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>{formLabels.rating.label}</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.rating.name}
                                value={formValues.rating}
                                onChange={(e) => setFormValues({ ...formValues, rating: e.target.value })}
                            >
                                {Object.values(AppConstants.creatorRating)?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        {!isOnboarded && updateFlag && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>{formLabels.estimatedOnboardingDate.label}</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <input
                                    type="date"
                                    onChange={e => handleInputChange(e)}
                                    name={formLabels.estimatedOnboardingDate.name}
                                    value={formValues?.estimatedOnboardingDate}
                                    required
                                />
                            </div>
                        </Col>}
                        {updateFlag && isOnboarded && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <Form.Label className={formClass.formLabel}>Existing Business</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.isExistingBussinessOnQ.name}
                                value={formValues.isExistingBussinessOnQ}
                                onChange={(e) => setFormValues({ ...formValues, isExistingBussinessOnQ: e.target.value })}
                            ><option value={AppConstants.emptyString} defaultValue></option>
                                {Object.values(AppConstants.existingBusinessOptions)?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>}
                        {updateFlag && isOnboarded && <Col xs={12} sm={6} md={6} lg={6} xl={4} className="mb-10">
                            <div className={classes.switchContainer}>
                                <div className={classes.switchText}>Physical Branding Installed<span> <input type="checkbox" checked={formValues.physicalBranding} onChange={() => setFormValues({ ...formValues, physicalBranding: !formValues.physicalBranding })} /></span></div>
                            </div>
                        </Col>}
                        {!isOnboarded && updateFlag && <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-10">
                            <Form.Label className={formClass.formLabel}>Remarks</Form.Label>
                            <div className={classes.expertObrEmailContainer}>
                                <textarea className={classes.remarksText} value={formValues.remarks} onChange={(e) => setFormValues({ ...formValues, remarks: e.target.value })} placeholder="Enter your remarks" />
                            </div>
                        </Col>}
                        {((updateFlag && !isOnboarded) || !updateFlag) && <Col xs={12} sm={6} md={6} lg={6} xl={4} >
                            <div className={classes.switchContainer}>
                                <div className={classes.switchText}>{formLabels.topCreatorFlag.label} <span> <input type="checkbox" checked={formValues.topCreatorFlag} onChange={() => setFormValues({ ...formValues, topCreatorFlag: !formValues.topCreatorFlag })} /></span></div>
                            </div>
                        </Col>}
                        {((updateFlag && !isOnboarded) || !updateFlag) && <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4} >
                            <div className={classes.switchContainer}>
                                <div className={classes.switchText}>Enable Listing Page <span> <input type="checkbox" checked={formValues.listingEnabled} onChange={() => setFormValues({ ...formValues, listingEnabled: !formValues.listingEnabled })} /></span></div>
                            </div>
                        </Col>}
                    </Row>}
                    {!updateFlag && <Row className={classes.adminSubmitObrFormRow}>
                        <Col xs={6} sm={6} md={6} lg={6} xl={4} className="mt-2">
                            <Button className={classes.expertObrcreateButton} onClick={e => submitLeadFormData(e)} disabled={submitSpinner}>{AppConstants.expertCreateObrButtons.submitObrBtn.label}{submitSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </Col>
                    </Row>}
                    {updateFlag && <Row className={classes.editButtonRow}>
                        <div>
                            <Button className={classes.editScreenButton} onClick={e => submitLeadFormData(e)} disabled={submitSpinner}>Save {submitSpinner && <BiLoaderAlt className="spinner" />}</Button>
                            <Button className={classes.editScreenButton} onClick={hideEditForm} disabled={submitSpinner}>Close</Button>
                        </div>
                    </Row>}
                    {formHasError && <ErrorSuccessAlertMessage message={submitFormMessage} varient={alertVarient} />}
                </form>
            </div>
        </>
    );
}