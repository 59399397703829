import { useState, useEffect } from 'react';
import { StudentSchoolRecentlyPublishedCompetitions } from '../../component/cards/StudentMySchoolCompetitions/StudentSchoolRecentlyPublishedCompetitions';
import classes from "./StudentMySchoolPage.module.css"
import { AppConstants } from "../../constants/AppConstants";
import { StudentSkillCategoryTabs } from '../../component/lists/StudentSkillCategoryTabs';
import { DataService } from '../../services/DataService';

export const StudentMySchoolPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const skillsData = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (skillsData.length > AppConstants.zeroIndex) {
                var data = new Map();
                for (let obj of skillsData) {
                    data.set(obj.categoryId, obj.category);
                }
                let skillCategoryArray = [...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId);

                setCategoryList([AppConstants.AllText, ...skillCategoryArray]);
            }
        }

        fetchSkills();
    }, []);

    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    }

    return (
        <div className="studentContentSection">
            {categoryList.length > AppConstants.zeroIndex && <div className={classes.section}>
                <div className={classes.filterSection}>
                    <StudentSkillCategoryTabs categoryList={categoryList} onSelection={categorySelectionHandler} />
                </div>
                <h3 className={classes.sectionHeading}>Upcoming Competitions</h3>
                <StudentSchoolRecentlyPublishedCompetitions selectedCategory={selectedCategory} categoryList={categoryList} />
            </div>}
        </div>
    )
}
