import React, { useState } from 'react';
import rightSectionStyles from './MicrositePageRightSection.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { TfiLinkedin, TfiFacebook } from 'react-icons/tfi';
import { FaGlobeAsia, FaYoutube } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';

export const MicrositePageRightSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const videoMessageThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.expertMicroSiteS3ImagesPath}/${AppConstants.expertVideoMessageThumbnailPath}`;
    const [uploadedVideoMessageThumbnailImage, setUploadedVideoMessageThumbnailImage] = useState(props.micrositeData.videoMessageThumbnailPath);
    const [micrositeDetails, setMicrositeDetails] = useState(props.micrositeData);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(true);

    const playVideo = () => {
        setIsVideoPlaying(true);
        setShowPlayIcon(false);
    };

    const handleClose = () => {
        setIsVideoPlaying(false);
        setShowPlayIcon(true);
    };

    return (
        <>
            {micrositeDetails && <div className={rightSectionStyles.showOnLargeScreen}>
                <div className={rightSectionStyles.aboutExpertContainer}>
                    {micrositeDetails?.skillDetails?.length !== 0 && <p className={rightSectionStyles.aboutExpertHeading}>{AppConstants.expertMicroSiteStaticText.expertSkillsText}</p>}
                    {micrositeDetails?.skillDetails?.length !== 0 && <div className={`${rightSectionStyles.skillsContainer} mb-3`}>
                        {micrositeDetails?.skillDetails?.map((skill, index) => (
                            <p key={index} className={rightSectionStyles.skillsText}>
                                {skill.skillName}
                            </p>))}
                    </div>}
                    {(micrositeDetails?.facebookLink ||
                        micrositeDetails?.linkedinLink ||
                        micrositeDetails?.websiteLink ||
                        micrositeDetails?.youtubeLink ||
                        micrositeDetails?.instagramLink) && <p className={rightSectionStyles.aboutExpertHeading}>{AppConstants.expertMicroSiteStaticText.socialLinksText}</p>}
                    {(micrositeDetails?.facebookLink ||
                        micrositeDetails?.linkedinLink ||
                        micrositeDetails?.websiteLink ||
                        micrositeDetails?.youtubeLink ||
                        micrositeDetails?.instagramLink) &&
                        <div className={rightSectionStyles.skillsContainer}>
                            {micrositeDetails?.facebookLink && <a href={micrositeDetails?.facebookLink} target={AppConstants.openInNewTab}><TfiFacebook /></a>}
                            {micrositeDetails?.linkedinLink && <a href={micrositeDetails?.linkedinLink} target={AppConstants.openInNewTab}><TfiLinkedin /></a>}
                            {micrositeDetails?.websiteLink && <a href={micrositeDetails?.websiteLink} target={AppConstants.openInNewTab}><FaGlobeAsia /></a>}
                            {micrositeDetails?.youtubeLink && <a href={micrositeDetails?.youtubeLink} target={AppConstants.openInNewTab}><FaYoutube /></a>}
                            {micrositeDetails?.instagramLink && <a href={micrositeDetails?.instagramLink} target={AppConstants.openInNewTab}><AiFillInstagram /></a>}
                        </div>}
                </div>
                {micrositeDetails.videoMessagePath !== null && <div className={rightSectionStyles.videoReviewSection}>
                    <p className={`${rightSectionStyles.aboutExpertHeading} mb-2`}>Video Message</p>
                    <div className={rightSectionStyles.promotionVideoSection}>
                        {isVideoPlaying ? (<div>
                            <video controls autoPlay className={rightSectionStyles.videoContent}>
                                <source src={micrositeDetails.videoMessagePath} type="video/mp4" />
                            </video>
                            <IoMdClose className={rightSectionStyles.closeButton} onClick={handleClose} />
                        </div>
                        ) : <img src={uploadedVideoMessageThumbnailImage || videoMessageThumbnail} className={rightSectionStyles.videoContent} alt="Video Message Thumbnail" />}
                        {showPlayIcon && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={rightSectionStyles.videoIcon} />}
                        <div>

                        </div>
                    </div>
                </div>}
                {micrositeDetails.promotionImageOnePath !== null && <div className={`${rightSectionStyles.advertismentSection} mt-3`}>
                    <div className={rightSectionStyles.promotionVideoSection}>
                        <img src={micrositeDetails.promotionImageOnePath} className={rightSectionStyles.advertismentImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>}
        </>
    );
}