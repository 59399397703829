import moment from "moment";
import { DateTime } from 'luxon';
import 'moment-timezone';
import Auth from "./Auth";
import { AppConstants } from "../constants/AppConstants";

export const DateTimeUtil = {


  // Method to convert date to UTC Date
  convertToUTC(date = new Date()) {

    var utc_date = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());

    // return new Date(utc_date).toUTCString();
    return new Date(utc_date).toISOString();
  },
  utcToLocal(utcDateTimeString) {
    //const systemDate = new Date();
    const utcDate = new Date(utcDateTimeString);

    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

    return localDate
  },
  getPreferredTimeZoneForUser() {
    let defaultTimeZone = "UTC";
    let preferredTimeZone = "";
    if (Auth.isLogin()) {
      preferredTimeZone = Auth.getLoggedInUserDetails().preferredTimezone ? Auth.getLoggedInUserDetails().preferredTimezone : defaultTimeZone;
    } else {
      const userLocale = encodeURIComponent(navigator.language || navigator.userLanguage);
      preferredTimeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone || defaultTimeZone;
    }

    return preferredTimeZone;
  },
  utcToPreferredTimezone(utcDateTimeString) {
    let defaultTimeZone = "UTC";
    let preferredTimeZone = "";
    const utcDate = new Date(utcDateTimeString.replace(' ', 'T') + 'Z');

    if (Auth.isLogin()) {
      preferredTimeZone = Auth.getLoggedInUserDetails().preferredTimezone ? Auth.getLoggedInUserDetails().preferredTimezone : defaultTimeZone;
    } else {
      const userLocale = encodeURIComponent(navigator.language || navigator.userLanguage);
      preferredTimeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone || defaultTimeZone;
    }

    const localDate = new Date(moment(utcDate).tz(preferredTimeZone));

    return localDate;
  },
  utcToSelectedTimeZone(utcDateTimeString, selectedTimeZone) {
    const utcDate = new Date(utcDateTimeString.replace(' ', 'T') + 'Z');

    const localDate = new Date(moment(utcDate).tz(selectedTimeZone));

    return localDate;
  },
  getZoneNameForPreferredTimeZone(preferredTimeZone) {
    return moment.tz(preferredTimeZone).zoneAbbr();
  },
  getFullZoneName(timeZone) {
    const dateTime = DateTime.now().setZone(timeZone);
    const fullName = dateTime.offsetNameLong;
    return fullName;
  },
  getTimeZoneName(dateString) {
    const date = new Date(dateString);
    const timeZoneName = date.toLocaleString('en-US', { timeZoneName: 'short' });
    return timeZoneName;
  },
  fetchTimeZoneForLocale() {
    const userLocale = encodeURIComponent(navigator.language || navigator.userLanguage);
    const timeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone;
    return timeZone;
  },
  fetchCurrentTimestamp() {
    return Math.floor(Date.now() / 1000);
  },
  getDateTimeForCourses(dateString) {
    if (!dateString) {
      return
    }
    let timeArr = dateString?.split(",");
    let date = moment(timeArr[1]?.trim()).format("Do MMM, YYYY");
    return `${date} at ${timeArr[0]}`
  },
  getLocalTimeValue(timeString) {
    let date = new Date;
    let timeArr = timeString.split(":");
    date.setHours(timeArr[0]);
    date.setMinutes(timeArr[1]);
    let newDate = moment(date).format("yyyy-MM-DD HH:mm:ss").replace(" ", "T") + "Z";
    return moment(newDate).format("yyyy-MM-DD HH:mm:ss")
  },
  getConvertedJsDateInPreferredTimeZone(date, timezone) {
    const dateTime = DateTime.fromJSDate(date).setZone(timezone);
    const zonename = DateTime.now().setZone(timezone).offsetNameLong;
    return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${zonename})\'`);
  },
  convertUtcTimeToLocalTime(utcTime) {
    if (!utcTime) return AppConstants.emptyString;
    const utcDateTime = DateTime.fromFormat(utcTime, 'HH:mm:ss', { zone: 'utc' });
    const localDateTime = utcDateTime.setZone(this.getPreferredTimeZoneForUser());
    return localDateTime.toFormat("h:mm a");
  },

  createDateTimeObject(date, time) {
    if (!date || !time) {
      return null;
    }
    const dateArr = date.split(AppConstants.hyphen);
    const timeArr = time.split(AppConstants.colon);
    const dateTime = DateTime.fromObject({
      year: dateArr[0],
      month: dateArr[1],
      day: dateArr[2],
      hour: timeArr[0],
      minute: timeArr[1],
      second: 0
    }, { zone: this.getPreferredTimeZoneForUser() }
    );

    return dateTime;
  },
  formatDateTime(dateTime, timeZone) {
    return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${this.getFullZoneName(timeZone)})\'`);
  }
}