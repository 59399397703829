import React, { useState, useEffect } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import classes from './StudentProfile.module.scss';
import { Card, Toast, ToastContainer } from "react-bootstrap"
import { ProfileSkillCategoryTab } from '../studentProfilePage/ProfileSkillCategoryTab'
import { FaTimes } from 'react-icons/fa';
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";


export const AdvanceProfilingStep1 = ({ nextStep, setUserInterestSkills, selectSkillErrorMessage }) => {
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [filteredList, setFilteredList] = useState([]);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [initialRender, setInitialRender] = useState(false)
    const [categoryList, setCategoryList] = useState([]);
    const [notInterestedSkills, setNotInterestedSkills] = useState([]);
    const [qrenciaCategoryWiseSkillsDetails, setQrenciaCategoryWiseSkillsDetails] = useState([]);
    const [showToast, setShowToast] = useState(AppConstants.falseText);
    //fetch all skills
    useEffect(() => {
        getFilteredList()
    }, [selectedCategory, initialRender])

    function getFilteredList() {
        if (selectedCategory === AppConstants.emptyString || selectedCategory === undefined) {
            setSelectedCategory(categoryList[0]);
        }
        let filteredSkillsArr = [];
        if (selectedCategory === AppConstants.emptyString) {
            filteredSkillsArr = notInterestedSkills?.filter(item => item.category === categoryList[0]);
        }
        else {
            filteredSkillsArr = notInterestedSkills?.filter(item => item.category === selectedCategory);
        }
        setFilteredList(filteredSkillsArr);
    }

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            var data = new Map();
            for (let obj of response) {
                data.set(obj.categoryId, obj.category);
            }
            setCategoryList([...data.values()]);
            setSelectedCategory(categoryList[0]);
            setQrenciaCategoryWiseSkillsDetails(response);
            setNotInterestedSkills(response);
            setInitialRender(true)
        }

        const fetchData = async () => {
            await fetchSkills();
        };

        fetchData();

    }, []);


    //handel change in selected skills array

    async function addToCheckedCategories(skill) {
        const skillObject = {
            'skillName': skill.skill,
            "skillId": skill.skillId,
            "skillUniqueId": skill.skillUniqueId,
            "competitionUniqueId": null,
            "expertUniqueId": null,
            "skillCategoryName": skill.category,
            "skillCategorySlug": skill.categorySlug,
            "skillEmojiUnicode": skill.skillEmojiUnicode
        };
        let alreadyExists = checkedCategories?.filter((item) => item.skillId === skill.skillId);
        if (alreadyExists.length !== 0) {
            setCheckedCategories(checkedCategories.filter(item => item.skillId !== skill.skillId));
            setUserInterestSkills(checkedCategories.filter(item => item.skillId !== skill.skillId));
        } else {
            let updatedSkills = checkedCategories
            updatedSkills.push(skillObject);
            setCheckedCategories([...updatedSkills]);
            setUserInterestSkills([...updatedSkills])
            return
        }
    };

    //function call on selecting category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    }

    return (
        <div className={classes.completeProfileContainer}>
            <div label="Interests">
                <p className={classes.tabContentHeading}>Choose your interested skills</p>
                {selectSkillErrorMessage &&
                                            <div className={` ${classes.errorSuccessContainer}`}>
                                                {<div className={classes.errorSuccessMessage}>
                                                    <ErrorSuccessAlertMessage message={selectSkillErrorMessage} varient={AppConstants.alertVarient[1]} />
                                                </div>}
                                            </div>
                                        }
                <Card className={`${classes.cardContainer}  ${classes.interestCard}`}>
                    <ProfileSkillCategoryTab categoryList={categoryList} onSelection={categorySelectionHandler} />
                    <div className={classes.skillContainerBox}>
                        <div className={classes.skillContainer}>
                            {filteredList?.map((item, idx) => (
                                <div
                                    className={`${classes.interestitem} ${checkedCategories.some(skill => skill.skillId === item.skillId) ? classes.selected : ''}`}
                                    onClick={() => addToCheckedCategories(item)}
                                    key={idx}
                                >
                                    <span>{item.skillEmojiUnicode ? String.fromCodePoint(parseInt(item.skillEmojiUnicode?.replace('U+', ''), 16)) : String.fromCodePoint('0x1f603')}</span> {item.skill}
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
                {checkedCategories.length > 0 && <>  <p className={classes.tabContentHeading}>My selected skills</p>

                    <div className={classes.selectedSkillSection}>
                        {checkedCategories.map((item, index) => (
                            <div className={`${classes.interestitem} ${classes.selected} ${classes.selectedSkillPill}`} key={index} >
                                <span>{item.skillEmojiUnicode ? String.fromCodePoint(parseInt(item.skillEmojiUnicode?.replace('U+', ''), 16)) : String.fromCodePoint('0x1f603')}</span> {item.skillName} <FaTimes className={classes.closeIcon} onClick={() => addToCheckedCategories(item)} />
                            </div>
                        ))}
                    </div>
                </>}
            </div>
        </div>
    );

}