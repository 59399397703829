import { AppConstants } from '../../constants/AppConstants';
import { Col, Row } from 'react-bootstrap';
import classes from './MicrositeReviewComponent.module.css';
import { useState } from 'react';
import { ReviewCardsComponent } from '../cards/reviewCardsComponent/ReviewCardsComponent';

export const MicrositeReviewComponent = (props) => {
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.micrositeReviewCardCount);
    return (
        <div className={classes.micrositeReviewSection}>
            {props.micrositeReviews.slice(0, cardCount).map((review, index) => {
                return <div>
                    <ReviewCardsComponent review={review} />
                    {(index + 1 < cardCount) && (index + 1 < props.micrositeReviews.length) && <hr className={classes.horizontalBreak} />}
                </div>
            })}
            {cardCount < props.micrositeReviews.length && <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className="p-2">
                    {cardCount < props.micrositeReviews.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.micrositeReviewCardCount)}>{AppConstants.moreReviewsText}</button>}
                </Col>
            </Row>}
        </div>
    )
}