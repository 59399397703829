import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { AppConstants } from '../../constants/AppConstants';
import classes from "./PostStartEnrolmentModal.module.css";
import { BiLoaderAlt } from 'react-icons/bi';

export const CompetitionSolutionConfirmationModal = ({ updateConfirmationStatus, learningActivityDetails, uploadChallenegeSpinner, uploadedSolutionErrorMessage, uploadedSolutionErrorVarient }) => {
    return (
        <Modal show={true} onHide={() => updateConfirmationStatus(AppConstants.falseText)} backdrop="static" keyboard={false} centered>
            <Modal.Body className={modalClass.modalBody}>
                {(!uploadChallenegeSpinner && !uploadedSolutionErrorMessage) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                {uploadedSolutionErrorMessage && <div className={uploadedSolutionErrorVarient === AppConstants.alertVarient[0] ? modalClass.modalSuccessIcon : modalClass.modalErrorIcon}>{uploadedSolutionErrorVarient === AppConstants.alertVarient[0] ? <AiOutlineCheckCircle /> : <AiOutlineExclamationCircle />}</div>}
                {uploadChallenegeSpinner && <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /></div>}
                <div className={modalClass.modalAlertText}>
                    <p><b>{learningActivityDetails.id} | {learningActivityDetails.title}</b></p>
                    {(!uploadChallenegeSpinner && !uploadedSolutionErrorMessage) && <p>Are you sure you want to submit? Please ensure all necessary files have been selected.</p>}
                    {(uploadChallenegeSpinner && !uploadedSolutionErrorMessage) && <p>Submitting your solution. Please wait!</p>}
                    {uploadedSolutionErrorMessage && <p>{uploadedSolutionErrorMessage}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer className={modalClass.modalFooter}>
                {(!uploadedSolutionErrorMessage && !uploadChallenegeSpinner) && <Button className={classes.modalNoButton} onClick={() => updateConfirmationStatus(AppConstants.falseText)}>No</Button>}
                {(!uploadedSolutionErrorMessage && !uploadChallenegeSpinner) && <Button className={classes.modalYesButton} onClick={() => updateConfirmationStatus(AppConstants.trueText)}>Submit</Button>}
                {(uploadedSolutionErrorMessage) && <Button className={classes.modalCloseButton} onClick={() => updateConfirmationStatus(AppConstants.falseText)}>Close</Button>}
            </Modal.Footer>
        </Modal>
    );
};
