import parse from 'html-react-parser';
import { AppConstants } from "../constants/AppConstants";

export const StringUtils = {
    decodeBase64EncodedString(encodedString) {
        try {
            return JSON.parse(window.atob(encodedString));
        } catch {
            return AppConstants.nullText;
        }
    },
    encodeObjectToBase64String(data) {
        try {
            return window.btoa(JSON.stringify(data));
        } catch {
            return AppConstants.emptyString;
        }
    },
    getParsedMessage(message, obj) {
        let replacedStr = message;
        for (const [key, value] of Object.entries(obj)) {
            replacedStr = replacedStr?.replace(`{{${key}}}`, `${value}`);
        };

        return parse(replacedStr);
    },
    isValidURL(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    },

    /**
     * 
     * @param {*} path the API endpoint  (relative path)
     * @param {*} params object having key value pairs of parameters to be appended in url
     * @returns the updated url with appended parameters
     */
    createURLWithParams(path, params = {}) {
        let paramLength = 0;

        for (const [key, value] of Object.entries(params)) {
            if (value === AppConstants.emptyString) continue;
            path = `${path}${paramLength === 0 ? "?" : "&"}${key}=${value}`;
            paramLength++;
        }

        return path;
    }
};