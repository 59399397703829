import React, { useEffect, useState } from "react";
import { useLoaderData,useLocation,useNavigate } from "react-router-dom";
import { LandingPageTopNavigationForListingPages } from '../../layout/ExtendedPagesLayout/LandingPageTopNavigationForListingPages';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import { SkillCategoryTabs } from "../../component/lists/SkillsCategoryTabs";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";
import { CourseListingPageCards } from "../../component/cards/courseListingCards/CourseListingPageCards";
import classes from "../../component/expertListingPage/ExpertListingPage.module.css";
import { Button } from "react-bootstrap";
import { CoursesListingPageBanner } from "../../component/cards/courseListingCards/CourseListingPageBanner";
import { DataService } from "../../services/DataService";
import sharePanelClasses from "../../component/individualCoursePage/IndividualCoursePageLayout.module.css";

export const CourseListingPage = () => {
    const location = useLocation();
    const currentPath = location.pathname.replace(/^\/+/, '').toLowerCase();
    const [learningActivities, setLearningActivities] = useState([]);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const [landingPageData, skillsData, courseData, bannerSectionData] = useLoaderData();
    const [filterText, setFilterText] = useState(AppConstants.emptyString);
    const [courseOfCategory, setCourseOfCategory] = useState(courseData);
    const [filteredList, setFilteredList] = useState(courseData);
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.AllText);
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}`;

    useEffect(() => {
        async function getLaData() {
            const url = `${AppConstants.getPageContentAPI}/${AppConstants.laDropdownValues}`
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setLearningActivities(response);
            if(response==null || response?.length===0){
                navigate(errorPageUrl);
            }
            else{
                const competitionsEnabled = response.find(item => item.subTitle.replace(/^\/+/, '').toLowerCase() === currentPath.toLowerCase());
                if (competitionsEnabled === undefined) {
                    navigate(errorPageUrl);
                }
            }
        }
        getLaData();
    }, []);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, []);

    // function to filter the experts based on selected category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);

        if (option) {
            if (option === AppConstants.AllText) {

                setCourseOfCategory(courseData);
                setFilteredList(courseData)
            } else {
                const courseArray = courseData.filter(course =>
                    course.categoryName === option)

                setFilteredList(courseArray);
                setCourseOfCategory(courseArray);
            }
        } else {

            setFilteredList(courseData)
            setCourseOfCategory(courseData);
        }
    }

    const handleResetFields = () => {
        setFilterText(AppConstants.emptyString);
        if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
            setFilteredList(courseData.filter(expert =>
                expert.skillDetails?.some(skill => skill.skillCategoryName?.toLowerCase() === selectedCategory?.toLowerCase())
            ));
        }
        if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
            setFilteredList(courseData);
        }
    }

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(e.target.value);

        let filteredArray = courseOfCategory;

        if (searchText !== AppConstants.emptyString) {
            filteredArray = courseOfCategory?.filter((item) => {
                const isExpertMatch = item.expertName?.toLowerCase()?.includes(searchText);
                const isAgencyMatch = item.agencyName?.toLowerCase()?.includes(searchText);
                const isSkillMatch = item.skillSlug?.toLowerCase()?.includes(searchText);
                const isTitleMatch = item.courseTitle?.toLowerCase()?.includes(searchText);
                const isFreeMatch = searchText === AppConstants.freeText && (item.cost === 0 || item.cost === null);
                const isTypeMatch = item.modeOfDelivery?.toLowerCase().includes(searchText)

                return isAgencyMatch || isExpertMatch || isSkillMatch || isTitleMatch || isFreeMatch || isTypeMatch;
            });
        }

        setFilteredList(filteredArray);
    };


    return (
        <div>
            <LandingPageTopNavigationForListingPages landingPageData={landingPageData}  learningActivities={learningActivities}/>
            <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={sharePanelClasses} linkToShare={linkToShare} />
            </div>
            <CoursesListingPageBanner bannerImage={bannerSectionData} />
            <div className={classes.stickyHeader}>
                <SkillCategoryTabs categoryList={skillsData} onSelection={categorySelectionHandler} />
                <div className={classes.containerSearchSkill}>
                    <div className={classes.containerSearchItem}><input
                        type={AppConstants.fieldTypeText}
                        value={filterText}
                        onChange={handleSearchInputChange}
                        placeholder={AppConstants.courseSearchPlaceholder}
                    />
                    </div>
                    <Button onClick={handleResetFields} className={classes.resetButton}>Reset</Button>
                </div>
            </div>
            {filteredList?.length !== 0 ?
                <CourseListingPageCards minimumReviewCount={minimumReviewCount} coursesOfCategory={filteredList} skillsData={skillsData} />
                : courseData.length === 0 ?
                    (<div className={classes.noDataText}><h5 className={classes.headingBlue}>{AppConstants.newCoursesHeading}</h5><p>{AppConstants.newCourseText} <a className={classes.exploreLink} href="/competitions">Explore Competitions</a></p><p className={classes.noDataSubText}>{AppConstants.newCoursesSubText}</p></div>) : <div className={classes.noDataError}><p className={classes.errorText}>No Courses Found</p></div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </div>
    );
}