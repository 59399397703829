import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import classes from '../../common/CertificateVerificationPage.module.css';
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";

export const CertificateVerificationPage = () => {
    const { eventType, certificateNumber } = useParams();
    const [footerData, setFooterData] = useState();
    const [certificateData, setCertificateData] = useState(AppConstants.emptyString);
    const [pageLoader, setPageLoader] = useState(AppConstants.trueText);
    const navigate = useNavigate();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    const bannerDesktop = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.certificateVerificationWebBanner}`;
    const bannerMobile = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.certificateVerificationMobileBanner}`;
    const bannerTablet = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.certificateVerificationTabletBanner}`;
    const verifyGif = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.greenVerificationGif}`;

    useEffect(() => {
        fetchFooterData();
        async function fetchFooterData() {
            const url = AppConstants.landingPageDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setFooterData(response);
        };

        async function checkCertificateValidity() {
            if (!AppConstants.certificateVerificationEventTypes.includes(eventType)) {
                navigate(AppConstants.asterisk);
            } else {
                const url = `${AppConstants.verifyCertificateAPI}${AppConstants.forwardSlash}${eventType}${AppConstants.forwardSlash}${certificateNumber}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    setPageLoader(AppConstants.falseText);
                    setCertificateData(response);
                } else {
                    navigate(AppConstants.asterisk);
                    setPageLoader(AppConstants.falseText);
                }
            }
        }
        checkCertificateValidity();
    }, []);
    console.log(certificateData)
    return (
        <>
            {!pageLoader && <>
                <LoginPageTopNavigation />
                <div className={classes.contentSection}>
                    <img src={bannerDesktop} className={classes.backgroundImage} alt="banner"></img>
                    <img src={bannerTablet} className={classes.backgroundImageTablet} alt="banner"></img>
                    <img src={bannerMobile} className={classes.backgroundImageMobile} alt="banner"></img>
                    {(certificateData?.teamEnrolmentEnabledFlag === AppConstants.nText || certificateData?.teamEnrolmentEnabledFlag == null) && <div className={classes.textSection}>
                        <h1 className={classes.heading}>Certificate Verification</h1>
                        <p className={classes.descriptionText}>This certificate was issued to <span className={classes.certificateBoldText}>{certificateData.participantName}</span></p>
                        {eventType === AppConstants.competitionText && <p className={classes.descriptionText}>for participating in competition <span className={classes.certificateBoldText}>{certificateData.eventName}</span> under skill category <span className={classes.certificateBoldText}>{certificateData.skillName}</span></p>}
                        {eventType === AppConstants.courseText && <p className={classes.descriptionText}>for completing the course <span className={classes.certificateBoldText}>{certificateData.eventName}</span> under skill category <span className={classes.certificateBoldText}>{certificateData.skillName}</span></p>}
                        {eventType === AppConstants.workshopText && <p className={classes.descriptionText}>for participating in workshop <span className={classes.certificateBoldText}>{certificateData.eventName}</span> under skill category <span className={classes.certificateBoldText}>{certificateData.skillName}</span></p>}
                        {(eventType === AppConstants.workshopText || eventType === AppConstants.courseText) && (certificateData.userRole === AppConstants.userRoleAgency ? <p className={classes.descriptionText}>conducted by <span className={classes.certificateBoldText}>{certificateData.organizedBy}</span></p> : certificateData.organizationName ? <p className={classes.descriptionText}>conducted by <span className={classes.certificateBoldText}>{certificateData.organizationName}</span></p> : <p className={classes.descriptionText}>conducted by <span className={classes.certificateBoldText}>{certificateData.expertName}</span></p>)}
                        {(eventType === AppConstants.competitionText) && (certificateData.userRole === AppConstants.userRoleAgency ? <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.organizedBy}</span></p> : certificateData.organizationName ? <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.organizationName}</span></p> : <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.expertName}</span></p>)}
                        {certificateData.eventStartDate === certificateData.eventEndDate ? <p className={classes.descriptionText}>on {certificateData.eventStartDate}</p> : <p className={classes.descriptionText}>from {certificateData.eventStartDate} to {certificateData.eventEndDate}</p>}
                        <p className={classes.verifiedByText}><img className={classes.verifyCheckImg} src={verifyGif} alt="verify gif" /> Verified by Qrencia</p>
                    </div>}
                    {certificateData?.teamEnrolmentEnabledFlag === AppConstants.yText && eventType === AppConstants.competitionText && <div className={classes.textSection}>
                        <h1 className={classes.heading}>Certificate Verification</h1>
                        <p className={classes.descriptionText}>This certificate was issued to <span className={classes.certificateBoldText}>{certificateData.entityType === AppConstants.markingTypes.team.value ? certificateData.teamName : certificateData.participantName}</span></p>
                        {certificateData.entityType !== AppConstants.markingTypes.team.value && <p className={classes.descriptionText}>as a part of <span className={classes.certificateBoldText}>{certificateData.teamName}</span> from <span className={classes.certificateBoldText}>{certificateData.instituteName}</span></p>}
                        {certificateData.entityType === AppConstants.markingTypes.team.value && <p className={classes.descriptionText}>from <span className={classes.certificateBoldText}>{certificateData.instituteName}</span></p>}
                        <p className={classes.descriptionText}>for participating in competition <span className={classes.certificateBoldText}>{certificateData.eventName}</span> under skill category <span className={classes.certificateBoldText}>{certificateData.skillName}</span></p>
                        {(certificateData.userRole === AppConstants.userRoleAgency ? <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.organizedBy}</span></p> : certificateData.organizationName ? <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.organizationName}</span></p> : <p className={classes.descriptionText}>organized by <span className={classes.certificateBoldText}>{certificateData.expertName}</span></p>)}
                        {certificateData.eventStartDate === certificateData.eventEndDate ? <p className={classes.descriptionText}>on {certificateData.eventStartDate}</p> : <p className={classes.descriptionText}>from {certificateData.eventStartDate} to {certificateData.eventEndDate}</p>}
                        <p className={classes.verifiedByText}><img className={classes.verifyCheckImg} src={verifyGif} alt="verify gif" /> Verified by Qrencia</p>
                    </div>}
                </div>
                {footerData && <LandingPageFooter landingPageData={footerData} />}
            </>}
            {pageLoader &&
                <div className={classes.preLoaderContainer}>
                    <div className={classes.preLoaderDiv}>
                        <div className={classes.loader}></div>
                        <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                        <p className={classes.loaderText}>Verifying Certificate</p>
                    </div>
                </div>}
        </>
    )

}