import { AppConstants } from "../../../constants/AppConstants";
import classes from "./ExpressionOfInterestCarouselSection.module.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./eoiMobileCarousel.css"
import { Card } from "react-bootstrap";
export const ExpresionOfInterestCarouselSection = ({ eoiImageData }) => {

    const responsiveSlider = {
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1
        }
    };

    return (
        <>
            {eoiImageData?.bannerImage?.active && <div className={classes.expertImageContainer}>
                <img src={eoiImageData.bannerImage.imagePath} alt="EOIImage" />
            </div>}

            <div className={classes.expertCarouselContainer}>
                <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={AppConstants.trueText}
                    className="expertCarouselContainerImage">
                    {eoiImageData.slides?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <Card key={slide.id}>
                            <Card.Img src={slide.imagePath} />
                        </Card>
                    ))}
                </Carousel>
            </div>
        </>
    )
}