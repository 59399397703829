import { Button, Modal, ModalBody } from "react-bootstrap"
import Auth from "../../utils/Auth"
import { AppConstants } from "../../constants/AppConstants"
import classes from "./SubmitReviewModal.module.css";
import { AiFillStar, AiOutlineCheckCircle, AiOutlineExclamationCircle, AiOutlineStar } from "react-icons/ai";
import { useEffect, useState } from "react";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { BiLoaderAlt } from "react-icons/bi";
import modalClass from "../cards/CompetitionsCards.module.css";
import { SuccessMessage } from "../../constants/SuccessMessage";

export const SubmitReviewModal = (props) => {
    const [rating, setRating] = useState(AppConstants.nullText);
    const [submitReviewErrorMessage, setSubmitReviewErrorMessage] = useState(AppConstants.emptyString);
    const [inputErrorMessage, setInputErrorMessage] = useState(AppConstants.emptyString);
    const [ratingErrorMessage, setRatingErrorMessage] = useState(AppConstants.emptyString);
    const [reviewSpinner, setReviewSpinner] = useState(AppConstants.falseText);
    const [studentReview, setStudentReview] = useState(AppConstants.emptyString);
    const [profanityError, setProfanityError] = useState(AppConstants.emptyString);
    const [profanityDetails, setProfanityDetails] = useState({});
    const [reviewSubmitFlag, setReviewSubmitFlag] = useState(AppConstants.falseText);

    useEffect(() => {
        const fetchProfanityDetails = async () => {
            const url = AppConstants.getProfanityApiDetailsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setProfanityDetails(response);
            }
        }

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            fetchProfanityDetails();
        }
    }, []);
    const checkReviewValidity = async () => {
        try {
            let isvalid = AppConstants.trueText;

            if (rating === AppConstants.nullText || rating === AppConstants.emptyString) {
                setRatingErrorMessage(ErrorMessages.enterRatingErrorMessage);
                setTimeout(() => {
                    setRatingErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                isvalid = AppConstants.falseText;
            }

            if (studentReview?.trim() === AppConstants.emptyString || studentReview === AppConstants.nullText) {
                setInputErrorMessage(ErrorMessages.enterReviewErrorMessage);
                setTimeout(() => {
                    setInputErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                isvalid = AppConstants.falseText;
            }

            if (!isvalid) return AppConstants.falseText;
            setReviewSpinner(AppConstants.trueText);

            const url = `${profanityDetails.endpoint}?text=${studentReview.trim()}`;
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': profanityDetails.key,
                    'X-RapidAPI-Host': profanityDetails.host
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.has_profanity) {
                setProfanityError(ErrorMessages.reviewProfanityError);
                setTimeout(() => {
                    setProfanityError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return AppConstants.falseText;
            } else {
                return AppConstants.trueText;
            }
        } catch (error) {
            return AppConstants.falseText;
        }
    }

    const postReviewAndRating = async () => {
        const valid = await checkReviewValidity();
        if (valid) {
            setProfanityError(AppConstants.emptyString);
            setRatingErrorMessage(AppConstants.emptyString);
            setInputErrorMessage(AppConstants.emptyString);

            const url = AppConstants.submitReviewAPI;
            const data = {
                rating,
                review: studentReview,
                reviewedToUserId: props.micrositeUserId
            };
            setReviewSpinner(AppConstants.trueText);
            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                data,
                AppConstants.emptyString
            );

            if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
                setReviewSpinner(AppConstants.falseText);
                setSubmitReviewErrorMessage(AppConstants.emptyString)
                setRating(AppConstants.nullText)
                setStudentReview(AppConstants.emptyString);
                setReviewSubmitFlag(AppConstants.trueText);
                props.fetchReviewForUser();
            } else if (response && response === AppConstants.httpResponseCodes.responseCode409) {
                setSubmitReviewErrorMessage(ErrorMessages.submitReviewErrorMessage);
                setReviewSpinner(AppConstants.falseText);
                const timer = setTimeout(() => {
                    setSubmitReviewErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => clearTimeout(timer);
            } else {
                setSubmitReviewErrorMessage(ErrorMessages.submitReviewErrorMessage);
                setReviewSpinner(AppConstants.falseText);
                const timer = setTimeout(() => {
                    setSubmitReviewErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => clearTimeout(timer);
            }
        } else {
            setReviewSpinner(AppConstants.falseText);
        }
    };

    return (
        <Modal show={true} size="medium" backdrop="static" keyboard={false} centered onHide={props.closeReviewModal}>
            <div className={classes.postReviewContainer}>
                {!reviewSubmitFlag && <Modal.Body >
                    <p className={classes.ratingTitle}>Writing review for {props.micrositeUserName}</p>
                    <div className={classes.profileImageSection}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl ? <div className={classes.reviewInitials}><img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.reviewInitials}>{Auth.getLoggedInUserDetails()?.nameInitials}</div>}
                        <div>
                            <p className={classes.reviewerName}>{Auth.getLoggedInName()}</p>
                            <p className={classes.description}>Posting publicly across Qrencia</p>
                        </div>
                    </div>
                    <div className={classes.captureRatingStarContainer}>
                        {AppConstants.rateNudge5starArray.map((value, index) => (
                            <span
                                key={value}
                                className={classes.ratingStars}
                                onClick={() => { setRating(value) }}>
                                {value <= rating ? <AiFillStar /> : <AiOutlineStar />}
                            </span>
                        ))}
                    </div>
                    {ratingErrorMessage && <p className={classes.formErrorMessage}><AiOutlineExclamationCircle /> {ratingErrorMessage}</p>}
                    <div className={classes.reviewInput}>
                        <textarea maxLength={AppConstants.micrositeReviewTextLimit} className={classes.inputTextBox} value={studentReview} onChange={(e) => setStudentReview(e.target.value)} placeholder={`Share details of your own experience about ${props.micrositeUserName}`} />
                    </div>
                    {inputErrorMessage && <p className={classes.formErrorMessage}><AiOutlineExclamationCircle /> {inputErrorMessage}</p>}

                    {submitReviewErrorMessage && <ErrorSuccessAlertMessage message={submitReviewErrorMessage} varient={AppConstants.alertVarient[1]} />}
                    {profanityError && <ErrorSuccessAlertMessage message={profanityError} varient={AppConstants.alertVarient[1]} />}
                </Modal.Body>}
                {reviewSubmitFlag && <ModalBody>
                    <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>
                    <div className={modalClass.modalAlertText}>{SuccessMessage.reviewSharedSuccessfullyMessage}</div>
                </ModalBody>}
                <Modal.Footer className={classes.modalFooter}>
                    {!reviewSubmitFlag && <div className={classes.buttonSection}>
                        <Button className={classes.closeButton} onClick={props.closeReviewModal}>Cancel</Button>
                        <Button disabled={!(rating) || !studentReview?.trim().length} className={classes.postButton} onClick={postReviewAndRating}>Submit {reviewSpinner && <BiLoaderAlt className="spinner" />}</Button>
                    </div>}
                    {reviewSubmitFlag && <div className={classes.doneSection}>
                        <Button className={classes.doneButton} onClick={props.closeReviewModal}>Close</Button>
                    </div>}
                </Modal.Footer>
            </div>
        </Modal>
    )
}