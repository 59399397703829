import React, { useEffect, useState } from 'react';
import styles from "./StudentTopScoreSection.module.css";
import { AppConstants } from '../../constants/AppConstants';

export const StudentTopScoreSection = (props) => {
    const [topScoringCreteriaInOrder, setTopScoringCreteriaInOrder] = useState([]);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const maleFemaleCharacterBgImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.maleFemaleCharacterBgImage}`;

    useEffect(() => {
        const data = props.topScoringCreteria.sort((a, b) => b.marksPercentage - a.marksPercentage);
        setTopScoringCreteriaInOrder(data);
    }, [props.topScoringCreteria]);

    return (
        <>
            <div className={styles.topScoreSectionContainer}>
                <div className={styles.topHeadingContainer}>
                    <h1 className={styles.topHeading}>Your <strong>top scoring</strong> areas in the competition</h1>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.leftImagesContainer}>
                        <img className={styles.leftCharacterImage} src={maleFemaleCharacterBgImage} alt="Male Female Character" />
                    </div>
                    <div className={styles.rightContentContainer}>
                        {topScoringCreteriaInOrder[2]?.criteriaName && <div className={`${styles.scoreCard} ${styles.lowScoreCard}`}>
                            <h1 className={styles.scoreHeading}>{topScoringCreteriaInOrder[2]?.criteriaName}</h1>
                            <p className={styles.scoreSubHeading}>You scored {topScoringCreteriaInOrder[2]?.marksScored}/{topScoringCreteriaInOrder[2]?.totalMarks}</p>
                        </div>}
                        {topScoringCreteriaInOrder[0]?.criteriaName && <div className={`${styles.scoreCard} ${styles.highScoreCard}`}>
                            <h1 className={styles.scoreHeading}>{topScoringCreteriaInOrder[0]?.criteriaName}</h1>
                            <p className={styles.scoreSubHeading}>You scored {topScoringCreteriaInOrder[0]?.marksScored}/{topScoringCreteriaInOrder[0]?.totalMarks}</p>
                        </div>}
                        {topScoringCreteriaInOrder[1]?.criteriaName && <div className={`${styles.scoreCard} ${styles.midScoreCard}`}>
                            <h1 className={styles.scoreHeading}>{topScoringCreteriaInOrder[1]?.criteriaName}</h1>
                            <p className={styles.scoreSubHeading}>You scored {topScoringCreteriaInOrder[1]?.marksScored}/{topScoringCreteriaInOrder[1]?.totalMarks}</p>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}

