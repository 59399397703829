import React, { useState,useEffect } from "react";
import { DataService } from "../../services/DataService";
import { useLoaderData,useLocation,useNavigate } from "react-router-dom";
import { LandingPageTopNavigationForListingPages } from '../../layout/ExtendedPagesLayout/LandingPageTopNavigationForListingPages';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import { SkillCategoryTabs } from "../../component/lists/SkillsCategoryTabs";
import { WorkshopListingPageCards } from "../../component/cards/workshopListingCard/WorkshopListingCards";
import classes from "../../component/expertListingPage/ExpertListingPage.module.css";
import { Button } from "react-bootstrap";
import { WorkshopsListingPageBanner } from "../../component/cards/workshopListingCard/WorkshopListingBanner";
import sharePanelClasses from "../../component/individualCoursePage/IndividualCoursePageLayout.module.css";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";

export const WorkshopListingPage = () => {
    const location = useLocation();
    const currentPath = location.pathname.replace(/^\/+/, '').toLowerCase();
    const [learningActivities, setLearningActivities] = useState([]);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const [landingPageData, skillsData, workshopData, bannerSectionData] = useLoaderData();
    const [filterText, setFilterText] = useState(AppConstants.emptyString);
    const [workshopOfCategory, setWorkshopsOfCategory] = useState(workshopData);
    const [filteredList, setFilteredList] = useState(workshopData);
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.AllText);
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}`;
    useEffect(() => {
        async function getLaData() {
            const url = `${AppConstants.getPageContentAPI}/${AppConstants.laDropdownValues}`
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setLearningActivities(response);
            if(response==null || response?.length===0){
                navigate(errorPageUrl);
            }
            else{
                const competitionsEnabled = response.find(item => item.subTitle.replace(/^\/+/, '').toLowerCase() === currentPath.toLowerCase());
                if (competitionsEnabled === undefined) {
                    navigate(errorPageUrl);
                }
            }
        }
        getLaData();
    }, []);
    // function to filter the experts based on selected category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);
        if (option) {
            if (option === AppConstants.AllText) {
                setWorkshopsOfCategory(workshopData);
                setFilteredList(workshopData)
            } else {
                const workshopArray = workshopData.filter(workshop =>
                    workshop.categoryName === option)
                setFilteredList(workshopArray);
                setWorkshopsOfCategory(workshopArray);
            }
        } else {
            setFilteredList(workshopData)
            setWorkshopsOfCategory(workshopData);
        }
    }

    const handleResetFields = () => {
        setFilterText(AppConstants.emptyString);
        if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
            setFilteredList(workshopData.filter(expert =>
                expert.categoryName.toLowerCase() === selectedCategory?.toLowerCase())
            )
        }
        if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
            setFilteredList(workshopData);
        }
    }

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(e.target.value);

        let filteredArray = workshopOfCategory;

        if (searchText !== AppConstants.emptyString) {
            filteredArray = workshopOfCategory?.filter((item) => {
                const isExpertMatch = item.expertName?.toLowerCase()?.includes(searchText);
                const isAgencyMatch = item.agencyName?.toLowerCase()?.includes(searchText);
                const isSkillMatch = item.skillSlug?.toLowerCase()?.includes(searchText);
                const isTitleMatch = item.workshopTitle?.toLowerCase()?.includes(searchText);
                const isFreeMatch = searchText === AppConstants.freeText && (item.workshopCost === 0 || item.workshopCost === null);
                const isTypeMatch = item.workshopModeOfDelivery?.toLowerCase().includes(searchText)

                return isExpertMatch || isSkillMatch || isTitleMatch || isFreeMatch || isTypeMatch || isAgencyMatch;
            });
        }

        setFilteredList(filteredArray);
    };

    return (
        <div>
            <LandingPageTopNavigationForListingPages landingPageData={landingPageData}  learningActivities={learningActivities}/>
            <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={sharePanelClasses} linkToShare={linkToShare} />
            </div>
            <WorkshopsListingPageBanner bannerImage={bannerSectionData} />
            <div className={classes.stickyHeader}>
                <SkillCategoryTabs categoryList={skillsData} onSelection={categorySelectionHandler} />
                <div className={classes.containerSearchSkill}>
                    <div className={classes.containerSearchItem}><input
                        type={AppConstants.fieldTypeText}
                        value={filterText}
                        onChange={handleSearchInputChange}
                        placeholder={AppConstants.workshopSerachPlaceholder}
                    />
                    </div>
                    <Button onClick={handleResetFields} className={classes.resetButton}>Reset</Button>
                </div>
            </div>
            {filteredList?.length !== 0 ?
                <WorkshopListingPageCards workshopsOfCategory={filteredList} skillsData={skillsData} />
                : workshopData.length === 0 ?
                    (<div className={classes.noDataText}><h5 className={classes.headingBlue}>{AppConstants.newWorkshopsHeading}</h5><p>{AppConstants.newWorkshopText} <a className={classes.exploreLink} href="/competitions">Explore Competitions</a></p><p className={classes.noDataSubText}>{AppConstants.newWorkshopsSubText}</p></div>) : <div className={classes.noDataError}><p className={classes.errorText}>No Workshops Found</p></div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </div>

    );
}