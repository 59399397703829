import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import classes from "./ViewJudgeCard.module.css"
import { FaShare } from "react-icons/fa";
import { AiFillDelete } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import { BiLoaderAlt } from 'react-icons/bi'
import { TiTickOutline } from "react-icons/ti"
import { BiError } from "react-icons/bi"
import { AppConstants } from '../../../constants/AppConstants';

export const ViewJudgeCard = ({ judgeDetails, removeJudge, sendLinkToJudge, showSendMailButton = false, showDeleteButton = false, competitionStatus = AppConstants.emptyString }) => {

    const { judgeName, judgeEmail, } = judgeDetails;

    const [showModal, setShowModal] = useState(false);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [sendMailSpinner, setSendMailSpinner] = useState(false);
    const [showSuccessFeedbackIcon, setShowSuccessFeedbackIcon] = useState(false);
    const [showErrorFeedbackIcon, setShowErrorFeedbackIcon] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState(AppConstants.emptyString);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSuccessFeedbackIcon(false);
            setShowErrorFeedbackIcon(false);
            setShowFeedbackMessage(false);
        }, AppConstants.judgesLinkCopiedInterval);

        return () => clearTimeout(timer);
    }, [showSuccessFeedbackIcon, showErrorFeedbackIcon]);

    const sendLink = async () => {
        setSendMailSpinner(true);
        let response = await sendLinkToJudge(judgeDetails);
        if (response) {
            setSendMailSpinner(false)
            setShowSuccessFeedbackIcon(true);
            setShowErrorFeedbackIcon(false);
        } else {
            setSendMailSpinner(false)
            setShowSuccessFeedbackIcon(false);
            setShowErrorFeedbackIcon(true);
        }
    }

    const confirmJudgeRemoval = () => {
        if (competitionStatus === "Completed") {
            return null;
        } else {
            removeJudge(judgeDetails)
        }

        setShowModal(false);
    };

    return (
        <div className={classes.cardContainer}>
            {showFeedbackMessage && <div className={classes.copyMessage}>{feedbackMessage}</div>}
            <div className={classes.cardRow}>
                <div className={classes.nameText}>
                    {judgeName}
                </div>
                <div className={classes.iconContainer}>
                    {showDeleteButton && <AiFillDelete onClick={confirmJudgeRemoval} />}
                    {/* {showSendMailButton && (sendMailSpinner ? <BiLoaderAlt className="spinner" /> : showSuccessFeedbackIcon ? <TiTickOutline /> : showErrorFeedbackIcon ? <BiError /> : <FaShare onClick={() => sendLink()} data-tooltip-place="top" data-tooltip-id="sendLink" />)}
                    <Tooltip className={classes.tooltipMessage} id="sendLink"><span className={classes.tooltipMessage}>Send Judging Link</span></Tooltip> */}
                </div>
            </div>
            <div className={`${classes.cardRow} ${classes.bottomRow}`}>
                <div className={classes.mailText}>
                    {judgeEmail}
                </div>
            </div>
            <Modal show={showModal} animation centered onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Judge</Modal.Title>
                </Modal.Header>
                <Modal.Body>{`Are you sure you want to remove ${judgeEmail} as Judge?`}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={confirmJudgeRemoval}>
                        Yes, Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}