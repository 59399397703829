import { useState, useEffect, useRef, useContext } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Button, Form } from 'react-bootstrap'
import formClass from "../../common/FormStyling.module.css";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./ExpressionOfInterestForm.module.css";
import { BiLoaderAlt } from "react-icons/bi";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Typeahead } from 'react-bootstrap-typeahead';
import { DataService } from "../../services/DataService";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SelectionContext } from '../../context/SelectionContext';

export const ExpressionOfInterestForm = () => {
    const formLabels = AppConstants.eoiPageFormLabels;
    const history = useNavigate();
    const [exploreSkills, setExploreSkills] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [submitButtonSpinner, setSubmitButtonSpinner] = useState(AppConstants.falseText);
    const [alertVarient, setAlertVarient] = useState(AppConstants.emptyString);
    const skillsRef = useRef(null);
    const countryRef = useRef(null);
    const cityRef = useRef(null);
    const { expertName, setExpertName } = useContext(SelectionContext);

    let initialValues = {
        firstName: AppConstants.emptyString,
        lastName: AppConstants.emptyString,
        email: AppConstants.emptyString,
        mobile: AppConstants.emptyString,
        dob: AppConstants.emptyString,
        country: AppConstants.emptyString,
        state: AppConstants.emptyString,
        city: AppConstants.emptyString,
        skill: AppConstants.emptyString,
        instituteName: AppConstants.emptyString,
        websiteLink: AppConstants.emptyString
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-93Z4E9VW4Q';
        script.async = true;

        const script2 = document.createElement('script');
        // Set the content of the script to the Google Analytics code
        script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-93Z4E9VW4Q');`;

        // Append the script to the head of the document
        document.head.appendChild(script);
        document.head.appendChild(script2);

        return () => {
            // Cleanup if necessary
            document.head.removeChild(script);
            document.head.removeChild(script2);
        };
    }, []);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().trim().required(ErrorMessages.eoiMissingFirstNameError),
        lastName: Yup.string().trim().required(ErrorMessages.eoiMissingLastNameError),
        dob: Yup.date().required(ErrorMessages.eoiMissingDobError).max(new Date(new Date().setDate(new Date().getDate() - 1)), ErrorMessages.eoiInvalidDobError),
        email: Yup.string().email(ErrorMessages.eoiInvalidEmailError).required(ErrorMessages.eoiInvalidEmailError).matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, ErrorMessages.eoiInvalidEmailError),
        mobile: Yup.string().required(ErrorMessages.eoiMissingMobileError).when(['country'], (country, schema) => {
            const selectedCountry = values.country[AppConstants.zeroIndex]?.country;
            if (selectedCountry === AppConstants.countryIndia) {
                return schema.matches(/^\d{10}$/, 'Please enter a valid 10 digit phone number');
            }
            return schema.matches(/^\d{6,16}$/, 'Please enter a valid 6 to 16 digit phone number');
        }),
        instituteName: Yup.string().test("Empty_Spaces_Test", "Please enter a valid institute name", function (value = "") {
            return (value.length > 0 && value.trim().length === 0) ? AppConstants.falseText : AppConstants.trueText;
        }).notRequired(),
        websiteLink: Yup.string().test("url_validation_website_link", "Please enter a valid website link",
            function (value = "") { return value === AppConstants.emptyString ? AppConstants.trueText : validator.isURL(value) }).notRequired(),
    });

    const { handleSubmit, values, touched, errors, setFieldValue, handleBlur, handleChange } = useFormik({
        initialValues,
        validationSchema,
        handleSubmit: () => { }
    });

    //fetch All skills supported by platform
    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setExploreSkills(response);
        }
        async function fetchCountries() {
            const url = AppConstants.fetchCountriesAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setCountryList(response);
            return null;
        };
        async function fetchStates() {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(AppConstants.getStatesApi, config);
            const responseData = await response.json();
            setStateList(responseData);
            return null;
        };

        fetchStates()
        fetchCountries();
        fetchSkills();
    }, []);

    useEffect(() => {
        async function fetchCities() {

            if ((values.country[AppConstants.zeroIndex]?.country === AppConstants.countryIndia && values.state !== AppConstants.emptyString && values.state[AppConstants.zeroIndex] !== undefined && values.state[AppConstants.zeroIndex]?.name !== undefined)) {
                var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
                const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${values.state[AppConstants.zeroIndex]?.iso2}${AppConstants.getCityUri}`, config);
                const responseData = await response.json();
                if (responseData.length !== 0) {

                    setCityList(responseData);
                }
                return null;
            }
        };
        if ((values.country[AppConstants.zeroIndex]?.country === AppConstants.countryIndia && values.state[AppConstants.zeroIndex] !== undefined && values.state[AppConstants.zeroIndex]?.name !== AppConstants.emptyString)) {
            fetchCities();
        }
    }, [values.state]);


    const handleSkillChange = (value) => {
        setFieldValue(formLabels.skill.name, value)
    };

    const validMoblieNumber = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString, AppConstants.dot];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const handleCountryChange = (value) => {
        if (value.length === 0) {
            setFieldValue(formLabels.country.name, AppConstants.emptyString);
        } else {
            setFieldValue(formLabels.country.name, value);
        }
        setFieldValue(formLabels.state.name, AppConstants.emptyString);
        setFieldValue(formLabels.city.name, AppConstants.emptyString);
        setCityList([]);
    }

    const handleStateChange = (value) => {
        setFieldValue(formLabels.state.name, value);
        setFieldValue(formLabels.city.name, []);
        setCityList([]);
        if (cityRef.current) {
            cityRef.current.clear();
        }
    }
    const handleCityChange = (value) => {
        setFieldValue(formLabels.city.name, value)
    };

    const validateForm = () => {
        try {
            let errorValues = Object.values(errors);

            if (errorValues.length !== AppConstants.zeroIndex) return errorValues;
            if (errorValues.length === AppConstants.zeroIndex && values.firstName.trim() === AppConstants.emptyString) return [ErrorMessages.allFieldsAreRequiredText];

            if (values.country[AppConstants.zeroIndex]?.id === undefined) {
                return [ErrorMessages.eoiMissingCountryError];
            } else if (values.country[AppConstants.zeroIndex].country === AppConstants.countryIndia && (values.state[AppConstants.zeroIndex]?.name === undefined || values.state[AppConstants.zeroIndex]?.name === null || values.state[AppConstants.zeroIndex]?.name === AppConstants.emptyString)) {
                return [ErrorMessages.eoiMissingStateError];
            } else if (values.country[AppConstants.zeroIndex].country === AppConstants.countryIndia && (values.city[AppConstants.zeroIndex]?.name === undefined || values.city[AppConstants.zeroIndex]?.name === null || values.city[AppConstants.zeroIndex]?.name === AppConstants.emptyString)) {
                return [ErrorMessages.eoiMissingCityError];
            } else if (values.country[AppConstants.zeroIndex].country !== AppConstants.countryIndia && values.city?.trim() === AppConstants.emptyString) {
                return [ErrorMessages.eoiMissingCityError];
            } else if (values.skill.length === AppConstants.zeroIndex) {
                return [ErrorMessages.eoiMissingSkillError];
            }

            return [];
        } catch (error) {
            return [ErrorMessages.tryAgainErrorText]
        }
    };

    const resetFormDetails = () => {
        let fieldNames = Object.keys(formLabels);
        fieldNames.forEach((name) => {
            setFieldValue(name, AppConstants.emptyString);
        });
        skillsRef.current.clear();
        countryRef.current.clear();
    };

    const submitFormDetails = async () => {
        try {
            const errorValues = validateForm();
            setExpertName(values.firstName + " " + values.lastName);
            if (errorValues.length === 0) {
                setSubmitButtonSpinner(AppConstants.trueText)
                let data = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    dob: values.dob,
                    email: values.email,
                    phoneNumber: values.mobile,
                    country: values.country[AppConstants.zeroIndex]?.country,
                    state: values.country[AppConstants.zeroIndex]?.country === AppConstants.countryIndia ? values.state[AppConstants.zeroIndex]?.name : values.state ? values.state : AppConstants.nullText,
                    city: values.country[AppConstants.zeroIndex]?.country === AppConstants.countryIndia ? values.city[AppConstants.zeroIndex]?.name : values.city,
                    selectedSkills: values.skill?.map(item => ({
                        skillId: item.skillId,
                        skillName: item.skill,
                        skillIntegerId: item.skillUniqueId
                    })),
                    instituteName: (values.instituteName?.trim() === AppConstants.emptyString) ? AppConstants.nullText : values.instituteName?.trim(),
                    websiteLink: values.websiteLink?.trim() === AppConstants.emptyString ? AppConstants.nullText : values.websiteLink,
                    source: AppConstants.sourceTypeEOI,
                    leadType: AppConstants.leadTypes.expert,
                    listingEnabled: AppConstants.nText,
                    countryId: values.country[AppConstants.zeroIndex]?.id,
                    countryMobileNumberCode: values.country[AppConstants.zeroIndex].countryCallingCode,
                    rating: AppConstants.creatorRating.fiveStar,
                    topCreatorFlag: AppConstants.nText
                };

                let url = AppConstants.captureQrenciaLeadsAPI;
                let response = await DataService.postWithResponseCode(
                    url,
                    data,
                    AppConstants.emptyString
                );

                if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                    setSubmitButtonSpinner(AppConstants.falseText);
                    window.gtag_report_conversion();
                    history(AppConstants.welcomeExpertPageRoute);
                } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                    setSubmitButtonSpinner(AppConstants.falseText);
                    setAlertVarient(AppConstants.alertVarient[1]);
                    setMessage(ErrorMessages.leadEmailAlreadyExistsError);
                    let timer = setTimeout(() => {
                        setMessage(AppConstants.falseText);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else {
                    setSubmitButtonSpinner(AppConstants.falseText);
                    setAlertVarient(AppConstants.alertVarient[1]);
                    setMessage(ErrorMessages.eoiFormSubmitError);
                    let timer = setTimeout(() => {
                        setMessage(AppConstants.falseText);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setMessage(errorValues[AppConstants.zeroIndex]);
                setAlertVarient(AppConstants.alertVarient[1]);
                setSubmitButtonSpinner(AppConstants.falseText);
                let timer = setTimeout(() => {
                    setMessage(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setSubmitButtonSpinner(AppConstants.falseText);
            let timer = setTimeout(() => {
                setMessage(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    return (
        <div className={classes.container}>
            <p className={classes.formHeading}>Ready to Join the Global Community of Experts?</p>
            <div className={`row ${classes.formLayout}`}>
                <p className={classes.formSubHeading}> Simply complete the form below and our team will contact you promptly!</p>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.firstName.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.firstName.name}
                            maxLength={50}
                            placeholder="First Name"
                            isInvalid={touched.firstName && errors.firstName}
                            value={values.firstName} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.firstName} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.lastName.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.lastName.name}
                            maxLength={50}
                            placeholder="Last Name"
                            isInvalid={touched.lastName && errors.lastName}
                            value={values.lastName} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.lastName} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.email.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.email.name}
                            maxLength={50}
                            placeholder="Email Address"
                            isInvalid={touched.email && errors.email}
                            value={values.email} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.email} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.dob.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.dob.name}
                            type="date"
                            max={new Date().toISOString().split('T')[AppConstants.zeroIndex]}
                            isInvalid={touched.dob && errors.dob}
                            value={values.dob} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.dob} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Label className={`${formClass.formLabel}`}>{formLabels.country.label}<span className="redAstrisk">*</span></Form.Label>
                    <Typeahead
                        className={`${classes.typeahead} adminCreateObrTypeahead adminCreateObrTypeaheadMultiple`}
                        name={formLabels.country.name}
                        id={formLabels.country.name}
                        placeholder="Country Name"
                        labelKey={formLabels.country.name}
                        onChange={handleCountryChange}
                        options={countryList}
                        ref={countryRef}
                    />
                </div>
                {values.country !== AppConstants.emptyString && values.country !== AppConstants.nullText && values.country?.length > 0 && values.country[AppConstants.zeroIndex]?.country !== AppConstants.countryIndia && <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2" controlId="basicDetailsDesc">
                        <Form.Label className={formClass.formLabel}>{formLabels.state.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.state.name}
                            maxLength={50}
                            placeholder="State Name"
                            isInvalid={touched.state && errors.state}
                            value={values.state} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.state} </Form.Control.Feedback>
                    </Form.Group>
                </div>}
                {(values.country !== AppConstants.emptyString && values.country[AppConstants.zeroIndex] !== undefined && values.country?.length > 0 && values.country[AppConstants.zeroIndex]?.country === AppConstants.countryIndia) && <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Label className={`${formClass.formLabel}`}>{formLabels.state.label}<span className="redAstrisk">*</span></Form.Label>
                    <Typeahead
                        className={`${classes.typeahead} adminCreateObrTypeahead adminCreateObrTypeaheadMultiple`}
                        name={formLabels.state.name}
                        id={formLabels.state.name}
                        placeholder="State Name"
                        labelKey="name"
                        onChange={handleStateChange}
                        options={stateList}
                    />
                </div>}
                {values.country !== AppConstants.emptyString && values.country !== AppConstants.nullText && values.country?.length > 0 && values.country[AppConstants.zeroIndex]?.country !== AppConstants.countryIndia && <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2" controlId="basicDetailsDesc">
                        <Form.Label className={formClass.formLabel}>{formLabels.city.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.city.name}
                            maxLength={50}
                            placeholder="City Name"
                            isInvalid={touched.city && errors.city}
                            value={values.city} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.city} </Form.Control.Feedback>
                    </Form.Group>
                </div>}
                {values.country !== AppConstants.emptyString && values.country !== AppConstants.nullText && values.country?.length > 0 && values.country[AppConstants.zeroIndex]?.country == AppConstants.countryIndia && <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Label className={`${formClass.formLabel}`}>{formLabels.city.label}<span className="redAstrisk">*</span></Form.Label>
                    <Typeahead
                        className={`${classes.typeahead} adminCreateObrTypeahead adminCreateObrTypeaheadMultiple`}
                        name={formLabels.city.name}
                        id={formLabels.city.name}
                        placeholder="City Name"
                        labelKey="name"
                        onChange={handleCityChange}
                        options={cityList}
                        ref={cityRef}
                    />
                </div>}
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.mobile.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.mobile.name}
                            type="number"
                            onKeyDown={validMoblieNumber}
                            placeholder="Contact Number"
                            isInvalid={touched.mobile && errors.mobile}
                            value={values.mobile} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.mobile} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.instituteName.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.instituteName.name}
                            placeholder="Institute/Academy Name"
                            isInvalid={touched.instituteName && errors.instituteName}
                            value={values.instituteName} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.instituteName} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.websiteLink.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.websiteLink.name}
                            placeholder="Website Link"
                            isInvalid={touched.websiteLink && errors.websiteLink}
                            value={values.websiteLink} onBlur={handleBlur}
                            onChange={handleChange} />
                        <Form.Control.Feedback className="errorText" type="invalid"> {errors.websiteLink} </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <Form.Label className={`${formClass.formLabel}`}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                    <Typeahead
                        className={`${classes.typeahead} adminCreateObrTypeahead adminCreateObrTypeaheadMultiple`}
                        name={formLabels.skill.name}
                        id={formLabels.skill.name}
                        labelKey={formLabels.skill.name}
                        multiple
                        onChange={handleSkillChange}
                        options={exploreSkills}
                        ref={skillsRef}
                    />
                    {(values.skill !== AppConstants.nullText && values.skill.length > AppConstants.zeroIndex) &&
                        <p className={classes.selectedSkillsList}>{values.skill.map(item => item.skill).join(", ")}</p>
                    }
                </div>
                <div className={classes.buttonSection}>
                    <Button onClick={submitFormDetails} disabled={submitButtonSpinner}>Submit {submitButtonSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </div>
                {message.length > 0 && <ErrorSuccessAlertMessage message={message} varient={alertVarient} />}
            </div>

        </div>
    );
}
