import React, { useEffect, useState } from 'react';
import styles from "./StudentCompetitionSnapshotSection.module.css";
import { AppConstants } from '../../constants/AppConstants';
import { MathUtil } from '../../utils/MathUtil';

export const StudentCompetitionSnapshotSection = (props) => {
    const [competitionSnapshot, setCompetitionSnapshot] = useState(props.competitionSnapshot);

    useEffect(() => {
        setCompetitionSnapshot(props.competitionSnapshot);
    }, [props.competitionSnapshot])

    return (
        <>
            <div className={styles.competitionSectionContainer}>
                <div className={styles.topHeadingContainer}>
                    <h1 className={styles.headingText}>{AppConstants.performanceReportCompetitionSectionText.headingPartOne} <strong>{AppConstants.performanceReportCompetitionSectionText.headingPartTwo}</strong></h1>
                    <p className={styles.subHeadingText}>{AppConstants.performanceReportCompetitionSectionText.subHeading}</p>
                </div>
                <div className={styles.InfoBoxesContainer}>
                    <div className={`${styles.InfoBoxesCard} ${styles.borderTopPurple}`}>
                        <p className={styles.InfoBoxesNumber}>{MathUtil.formatNumberToLocaleString(competitionSnapshot.totalCompetitors)}</p>
                        <p className={styles.InfoBoxesText}>{AppConstants.performanceReportCompetitionSectionText.competitiorsText}</p>
                    </div>
                    <div className={`${styles.InfoBoxesCard} ${styles.borderTopGreen}`}>
                        <p className={styles.InfoBoxesNumber}>{competitionSnapshot.averageScore}</p>
                        <p className={styles.InfoBoxesText}>{AppConstants.performanceReportCompetitionSectionText.averageScoreText}</p>
                    </div>
                    <div className={`${styles.InfoBoxesCard} ${styles.borderTopSkyBlue}`}>
                        <p className={styles.InfoBoxesNumber}>{competitionSnapshot.highestScore}</p>
                        <p className={styles.InfoBoxesText}>{AppConstants.performanceReportCompetitionSectionText.higestScoreText}</p>
                    </div>
                    <div className={`${styles.InfoBoxesCard} ${styles.borderTopRed}`}>
                        <p className={styles.InfoBoxesNumber}>{competitionSnapshot.lowestScore}</p>
                        <p className={styles.InfoBoxesText}>{AppConstants.performanceReportCompetitionSectionText.lowestScoreText}</p>
                    </div>
                    <div className={`${styles.InfoBoxesCardYourScore} ${styles.borderTopYellow}`}>
                        <p className={styles.InfoBoxesNumber}>{competitionSnapshot.myScore}</p>
                        <p className={styles.InfoBoxesText}>{AppConstants.performanceReportCompetitionSectionText.yourScoreText}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

