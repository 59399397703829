export const FormValidationUtils = {

    isValidString(formValues) {
        const regex = /[^a-zA-Z\s]/g;
        return !regex.test(formValues.trim());
    },
    isValidNumber(formValues) {
        try {
            const number = parseFloat(formValues);
            return !isNaN(number);
        } catch (error) {
            return false;
        }
    },
    isLengthOfStringShort(formValue, maxLength) {
        if (formValue) {
            return formValue?.length <= maxLength;
        } else {
            return true;
        }
    },
    isLengthOfStringLarge(formValue, minLength) {
        if (formValue) {
            return formValue?.length >= minLength;
        } else {
            return false;
        }
    },
    isValidIndiaMobileNumber(number) {
        const pattern = /^[6-9]{1}\d{9}$/;
        return pattern.test(number);
    },
    isValidInternationalMobileNumber(number) {
        const pattern = /^[1-9]{1}\d{5,15}$/
        return pattern.test(number);
    },
    isValidDateOfBirth(dateString) {
        if (dateString) {
            const formValueDate = new Date(dateString);
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return !(formValueDate >= yesterday);
        } else {
            return false
        }
    },
    isValidEmail(stringFormValue) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        return emailRegex.test(stringFormValue)
    },
    isEmpty(stringFormValue) {
        return stringFormValue === "" || stringFormValue.trim() === "";
    },
    /**
     * 
     * @param {*} event -  keyboard event object
     * @param {[]} excludedParams - An optional array of additional characters or keywords to exclude (default is an empty array)
     */
    preventNumberWithSpecialCharacters(event, excludeParams = []) {
        let excludedKeywords = ["e", "E", ...excludeParams];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }
};