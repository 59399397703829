import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import Auth from "../../utils/Auth";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { StringUtils } from "../../utils/StringUtils";

export const EnrolInCompetition = {

    enrolInSelectedCompetition(competitionId, cost) {
        return this.enrol(competitionId, cost);
    },

    async enrol(competitionDetails) {
        try {
            //Check if user is logged in
            if (!(Auth.isLogin())) {
                return { message: ErrorMessages.enrollmentUserNotLoggedInError };
            } else {
                if (Auth.getUserRole() !== AppConstants.userRoleStudent) {
                    return { message: ErrorMessages.enrollmentUserNotStudentError };
                } else {
                    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
                    const url = `${AppConstants.enrolInCompetitionApi}${competitionDetails.uniqueId}&locale=${encodeURIComponent(timeZone)}`
                    const response = await AuthorizedDataService.postRequestWithResponseCode(url, null, AppConstants.emptyString, AppConstants.emptyString);

                    if (response) {
                        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                            return { message: ErrorMessages.sessionExpiredCompetitionEnrolmentError };
                        } else {
                            const data = await response.json();
                            return { message: this.getEnrolmentStatus(data, competitionDetails.cost) };
                        }
                    } else {
                        return { message: this.getEnrolmentStatus(AppConstants.nullText, competitionDetails.cost) }
                    }
                }
            }
        } catch (error) {
            if (competitionDetails.cost === 0 || competitionDetails.cost === null || competitionDetails.cost === undefined || competitionDetails.cost === AppConstants.freeText) {
                return { message: ErrorMessages.enrollmentFailError }
            } else {
                return { message: ErrorMessages.paymentDeductedEnrolmentError }
            }
        }
    },

    async enrolInTeamCompetitions(competitionDetails) {
        try {
            if (!(Auth.isLogin())) {
                return { message: ErrorMessages.enrollmentUserNotLoggedInError };
            } else {
                if (Auth.getUserRole() !== AppConstants.userRoleTeam) {
                    return { message: ErrorMessages.onlyTeamEnrolmentsAllowedError };
                } else {
                    const url = AppConstants.enrolTeamInCompetitionAPI;
                    const response = await AuthorizedDataService.postRequestWithResponseCode(url, competitionDetails, AppConstants.emptyString, AppConstants.emptyString);

                    if (response) {
                        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                            return { message: ErrorMessages.sessionExpiredCompetitionEnrolmentError };
                        } else {
                            const data = await response.json();
                            return { message: this.getEnrolmentStatusForTeamCompetitions(data, competitionDetails.cost) };
                        }
                    } else {
                        return { message: this.getEnrolmentStatusForTeamCompetitions(AppConstants.nullText, competitionDetails.cost) }
                    }
                }
            }
        } catch (error) {
            if (competitionDetails.cost === 0 || competitionDetails.cost === null || competitionDetails.cost === undefined || competitionDetails.cost === AppConstants.freeText) {
                return { message: ErrorMessages.enrollmentFailError }
            } else {
                return { message: ErrorMessages.paymentDeductedEnrolmentError }
            }
        }
    },

    getEnrolmentStatus(data, cost) {
        if (data === 1) {
            return SuccessMessage.studentEnrollmentSuccess;
        } else if (data === -113) {
            return ErrorMessages.enrollmentFailCompetitionStartedError;
        } else if (data === -115) {
            return ErrorMessages.enrollmentFailRegistrationClosedError;
        } else if (data === -117) {
            return ErrorMessages.enrollmentFailStudentAlreadyEnrolledError;
        } else if (data === -119) {
            return ErrorMessages.enrollmentFailRegistrationFullError;
        } else if (data === -121) {
            return ErrorMessages.competitionAgeLimitEnrolError
        } else if (data === -123) {
            return ErrorMessages.competitionNotEligibleErrorMessage;
        } else if (data === -125) {
            return ErrorMessages.competitionNotAssociatedToStudentErrorMessage;
        } else if (data === -143) {
            return ErrorMessages.reRequestForCompetitionCODError;
        } else {
            if (cost === 0 || cost === AppConstants.emptyString || cost === null || cost === undefined || cost === AppConstants.freeText) {
                return ErrorMessages.enrollmentFailError;
            } else {
                return ErrorMessages.paymentDeductedEnrolmentError;
            }
        }
    },

    getEnrolmentStatusForTeamCompetitions(data, cost) {
        if (data.statusCode === 1) {
            return SuccessMessage.teamEnrollmentSuccess;
        } else if (data.statusCode === -115) {
            return ErrorMessages.enrollmentFailRegistrationClosedError;
        } else if (data.statusCode === -119) {
            return ErrorMessages.teamCompetitionsSeatsFullError;
        } else if (data.statusCode === -120) {
            return ErrorMessages.teamLimitExceedeError;
        } else if (data.statusCode === -129) {
            return StringUtils.getParsedMessage(ErrorMessages.teamAlreadyEnrolledError, { teamNames: data.alreadyEnrolledTeams.join(', ') });
        } else if (data.statusCode === -130) {
            return StringUtils.getParsedMessage(parseInt(data.message) < 1 ? ErrorMessages.enrollmentFailRegistrationFullError : parseInt(data.message) === 1 ? ErrorMessages.oneRemainingTeamLimitError : ErrorMessages.remainingTeamLimitError, { remainingTeams: data.message });
        } else if (data.statusCode === -143) {
            return ErrorMessages.reRequestForCompetitionCODError;
        } else if (data.statusCode === -151) {
            return StringUtils.getParsedMessage(ErrorMessages.teamCompetitionAgeLimitError, { userNames: data.participantNames.join(', ') })
        } else if (data.statusCode === -161) {
            return StringUtils.getParsedMessage(ErrorMessages.qrenciaUserAgeCriteriErrorTeamCompetitions, { qrenciaIds: data.participantNames.join(', ') })
        } else if (data.statusCode === -171) {
            return StringUtils.getParsedMessage(ErrorMessages.invalidQrenciaIdForTeamEnrolmentsError, { qrenciaIds: data.invalidUserQrenciaIDs.join(', ') })
        } else if (data.statusCode === -181) {
            return StringUtils.getParsedMessage(ErrorMessages.alreadyEnrolledQrenciaIdErrors, { qrenciaIds: data.invalidUserQrenciaIDs.join(', ') })
        } else if (data.statusCode === -142) {
            return ErrorMessages.enrollmentRistrictedForTeam;
        }
        else {
            if (cost === 0 || cost === AppConstants.emptyString || cost === null || cost === undefined || cost === AppConstants.freeText) {
                return ErrorMessages.enrollmentFailError;
            } else {
                return ErrorMessages.paymentDeductedEnrolmentError;
            }
        }
    }
}