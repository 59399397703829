import React, { useState, useEffect } from 'react';
import expertJudgingScreenBanner from "../individualCompetition/IndividualCompetitionBanner.module.css"
import { AppConstants } from '../../constants/AppConstants';

export const ExpertJudgingScreenBanner = (props) => {
    const [mobileImage, setMobileImage] = useState(null);
    const [tabletImage, setTabletImage] = useState(null);
    const [webImage, setWebImage] = useState(null);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultWebBanner}`;
    const defaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultTabletBanner}`;
    const defaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultMobileBanner}`;

    useEffect(() => {
        setWebImage(props.competitionDetail.promotionImageWeb === null ? defaultWebBanner : props.competitionDetail.promotionImageWeb);
        setTabletImage(props.competitionDetail.promotionImageTab === null ? defaultTabletBanner : props.competitionDetail.promotionImageTab);
        setMobileImage(props.competitionDetail.promotionImageMobile === null ? defaultMobileBanner : props.competitionDetail.promotionImageMobile);
    }, [props.competitionData]);

    return (
        <>
            <div className={expertJudgingScreenBanner.competitionMobileImgContainer}>
                {!props.competitionDetail.competitionDefaultMobileBanner && <div className={expertJudgingScreenBanner.competitionBannerTitleContainer}><p className={expertJudgingScreenBanner.competitionBannerTitle}>{props?.competitionDetail?.competitionTitle}</p></div>}
                <img src={mobileImage} alt={AppConstants.competitionBannerText} className={expertJudgingScreenBanner.competitionBannerImage} />
            </div>
            <div className={expertJudgingScreenBanner.competitionTabletImgContainer}>
                {!props.competitionDetail.promotionImageTab && <div className={expertJudgingScreenBanner.competitionBannerTitleContainer}><p className={expertJudgingScreenBanner.competitionBannerTitle}>{props?.competitionDetail?.competitionTitle}</p></div>}
                <img src={tabletImage} alt={AppConstants.competitionBannerText} className={expertJudgingScreenBanner.competitionBannerImage} />
            </div>
            <div className={expertJudgingScreenBanner.competitionBannerImgContainer}>
                {!props.competitionDetail.promotionImageWeb && <div className={expertJudgingScreenBanner.competitionBannerTitleContainer}><p className={expertJudgingScreenBanner.competitionBannerTitle}>{props?.competitionDetail?.competitionTitle}</p></div>}
                <img src={webImage} alt={AppConstants.competitionBannerText} className={expertJudgingScreenBanner.competitionWebBannerImage} />
            </div>
        </>
    );
}
