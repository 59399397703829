import { useState, useEffect } from "react";
import { Form, Button, Modal } from 'react-bootstrap'
import ReactQuill, { Quill } from 'react-quill';
import parse from 'html-react-parser';
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { AppConstants } from "../../../constants/AppConstants";
import classes from "../../editCourseComponent/editFrequentlyAskedQuestions/EditFrequentlyAskedQuestions.module.css";
import formClass from "../../../common/FormStyling.module.css"
import "./AddFaqTextEditor.css";

export const AddFrequentlyAskedQuestions = (props) => {
    let stages;
    if (props.activityType === AppConstants.courseText) {
        stages = AppConstants.createCoursesActions;
    } else if (props.activityType === AppConstants.workshopText) {
        stages = AppConstants.createWorkshopActions;
    }
    const [addQuestionFlag, setAddQuestionFlag] = useState(props.faqCollection?.length > 0 ? false : true);
    const [question, setQuestion] = useState(AppConstants.emptyString);
    const [answer, setAnswer] = useState(AppConstants.emptyEditorString);
    const [faqsList, setFaqslist] = useState([]);
    const [editIndex, setEditIndex] = useState(AppConstants.emptyString);
    const [editedQuestion, setEditedQuestion] = useState(AppConstants.emptyString);
    const [editedAnswer, setEditedAnswer] = useState(AppConstants.emptyEditorString);
    const [showModal, setShowModal] = useState(false);

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.http}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }

    useEffect(() => {
        const changeParentState = () => {
            props.changeFaqData(faqsList);
        }
        changeParentState();
    }, [faqsList]);

    useEffect(() => {
        setAddQuestionFlag(props.faqCollection?.length > 0 ? false : true);
        if (props.faqCollection) {
            setFaqslist(props.faqCollection?.map(faq => ({
                answer: faq.answer,
                question: faq.question
            })));
        } else {
            setFaqslist([]);
        }
    }, [props.faqCollection]);

    const addCurrentQuestion = () => {
        setFaqslist(questionList => [...questionList, {
            question,
            answer
        }]);

        setQuestion(AppConstants.emptyString);
        setAnswer(AppConstants.emptyEditorString);
        setAddQuestionFlag(false);
    };

    const deleteFaq = (index) => {
        if (faqsList.length === 1) {
            setAddQuestionFlag(true)
        }
        setFaqslist(faqs => faqs.filter((faq, i) => i !== index));
    }

    const addFaqDisabled = (questionText, answerText) => {
        return (answerText.replace(/<(.|\n)*?>/g, '').trim().length === 0 || questionText.trim().length === 0)
    };

    const discardFAQ = () => {
        if (faqsList.length > 0) {
            setAddQuestionFlag(false)
        }
        setAnswer(AppConstants.emptyEditorString);
        setQuestion(AppConstants.emptyString);
    }

    const moveToNextSection = () => {
        props.saveCurrentStep({ step: stages.indexOf(props.stage) });
    };

    const editSelectedFAQ = (faq, index) => {
        setEditIndex(index);
        setEditedAnswer(faq.answer);
        setEditedQuestion(faq.question);
        setShowModal(true)
    };

    const saveEditedQuestion = () => {
        let arr = [];
        faqsList.forEach((faq, index) => {
            let data = { ...faq };
            if (index === editIndex) {
                data.question = editedQuestion;
                data.answer = editedAnswer
            };
            arr.push(data);
        });

        setFaqslist(arr);
        setEditedQuestion(AppConstants.emptyString);
        setEditedAnswer(AppConstants.emptyEditorString);
        setEditIndex(AppConstants.emptyString);
        setShowModal(false);
    };

    const discardEditChanges = () => {
        setEditedQuestion(AppConstants.emptyString);
        setEditedAnswer(AppConstants.emptyEditorString);
        setEditIndex(AppConstants.emptyString);
        setShowModal(false);
    };

    const closeModal = () => {
        setShowModal(false);
        setEditedQuestion(AppConstants.emptyString);
        setEditedAnswer(AppConstants.emptyEditorString);
        setEditIndex(AppConstants.emptyString);
    }

    return (
        <div className={`${classes.faqContainer} frequentlyAskedQuestions`}>
            {
                faqsList?.length > 0 && faqsList.map((faq, i) => <div className={classes.addedFaqs} key={i}>
                    <div className={classes.displayedFaqs}>
                        <div className={classes.questionContainer}>
                            <div className={classes.questions}>
                                <div className={classes.questionText}>Q{i + 1} : {faq.question}</div>
                            </div>
                            <div className={classes.iconContainer}>
                                <AiOutlineEdit onClick={() => editSelectedFAQ(faq, i)} className={classes.editIcon} /><br />
                                <AiFillDelete onClick={() => deleteFaq(i)} className={classes.editIcon} />
                            </div>
                        </div>
                        <div className={`${classes.answers} quillListIndent`}>{parse(faq.answer)}</div>
                    </div>
                </div>)
            }
            {faqsList?.length > 0 && !addQuestionFlag && <div onClick={() => setAddQuestionFlag(true)} className={classes.addNewQuestionText}>Add new question</div>}
            {addQuestionFlag && <div className={classes.addQuestionsContainer}>
                <Form.Label className={formClass.formLabel}>Question</Form.Label>
                <Form.Control type="text" className={formClass.formInput} value={question} onChange={(e) => setQuestion(e.target.value)} />
                <Form.Group className="mb-2 mt-2">
                    <Form.Label className={formClass.formLabel}>Answer</Form.Label>
                    <ReactQuill preserveWhitespace={AppConstants.trueText} formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules} className={classes.textEditor} theme="snow" placeholder="Enter answer" value={answer} onChange={setAnswer} />
                </Form.Group>
                {addQuestionFlag && (faqsList?.length > 0 && question.length === 0 && answer === AppConstants.emptyEditorString) && <Button className={classes.addBtn} onClick={discardFAQ}>Close</Button>}
                {addQuestionFlag && (question.length > 0 || answer !== AppConstants.emptyEditorString) && <Button className={classes.addBtn} onClick={discardFAQ}>Discard Changes</Button>}
                <Button className={classes.addBtn} disabled={addFaqDisabled(question, answer)} onClick={addCurrentQuestion}>Add</Button>
            </div>}

            <Modal size="lg" show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={classes.modalBody}>
                    <div className={classes.addQuestionsContainer}>
                        <Form.Label className={formClass.formLabel}>Question</Form.Label>
                        <Form.Control type="text" className={formClass.formInput} value={editedQuestion} onChange={(e) => setEditedQuestion(e.target.value)} />
                        <Form.Group className="mb-2 mt-2">
                            <Form.Label className={formClass.formLabel}>Answer</Form.Label>
                            <ReactQuill formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules} className={classes.textEditor} theme="snow" placeholder="Enter answer" value={editedAnswer} preserveWhitespace={AppConstants.trueText} onChange={setEditedAnswer} />
                        </Form.Group>
                        {(editedQuestion.length > 0 || editedAnswer !== AppConstants.emptyEditorString) && <Button className={classes.addBtn} onClick={discardEditChanges}>Cancel</Button>}
                        <Button className={classes.addBtn} disabled={addFaqDisabled(editedQuestion, editedAnswer)} onClick={saveEditedQuestion}>Save</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className={classes.nextBtn}>
                <Button onClick={moveToNextSection}>Next</Button>
            </div>
        </div>
    )
}