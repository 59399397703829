import { MarkAttendanceRightWidget } from '../../component/widgets/MarkAttendanceRightWidget';
import classes from './AgencyRightWidgetSpace.module.css';

export const AgencyRightWidgetSpace = () => {
    return (
        <>
            <div className={classes.widgetSection}>
                <MarkAttendanceRightWidget />
                {/* <AgencyTodaysActivitiesWidget /> */}
            </div>
        </>
    )
}