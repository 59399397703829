import { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { AgGridReact } from 'ag-grid-react';
import { Button, Row, Col, Card, Badge } from 'react-bootstrap';
import { AiOutlineUser } from 'react-icons/ai';
import { BsAward } from 'react-icons/bs'
import { BiCalendarCheck } from "react-icons/bi";
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from "./ShowSchoolTeachersList.module.css";
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import "../expertManageCompetitionComponent/dataGrid.css"
import { DateTimeUtil } from '../../utils/DateTimeUtil';

export const ShowSchoolTeachersList = () => {
    const [expertList, setExpertList] = useState([]);
    const [cardCount, setCardCount] = useState(6);
    const associationStatus = AppConstants.agencyExpertInvitationStatus;
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchExpertList = async () => {
            const url = AppConstants.getTeachersMappingDetailsWithSchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setExpertList(response);
            } else {
                setExpertList([]);
            }
        };

        fetchExpertList();
    }, []);

    function getCssClassForEventType(param) {
        const defaultClassName = `${classes.eventTypeField}`;
        let additionalClassName = AppConstants.emptyString;
        if (param.invitationStatus === associationStatus.accepted) {
            additionalClassName = `${classes.textGreen}`;
        } else if (param.invitationStatus === associationStatus.rejected) {
            additionalClassName = `${classes.textRed}`;
        } else {
            additionalClassName = `${classes.textBlack}`;
        }

        return (defaultClassName + AppConstants.space + additionalClassName)
    };


    const getValidTextClass = ({ value }) => {
        if (value == AppConstants.expertObrStatus.statusAccepted) {
            return `${classes.textGreen}`;
        } else if (value == AppConstants.expertObrStatus.statusRejected) {
            return `${classes.textRed}`;
        }
        else if (value === associationStatus.sent) {
            return `${classes.textBlack}`;
        }
    };

    function getSKillValueString(selectedSkills) {
        let value = AppConstants.emptyString;
        selectedSkills?.forEach((skill, i) => {
            if (i > AppConstants.zeroIndex) {
                value = `${value}, ${skill.skillName}`;
            } else {
                value = skill.skillName;
            }
        });
        return value;
    };

    const columnDefs = [
        {
            headerName: "Qrencia Id",
            field: "expertQrenciaId",
            tooltipField: "expertQrenciaId",
            filter: 'agTextColumnFilter'
        }, {
            headerName: "Name",
            field: "expertName",
            tooltipField: "expertName",
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Skill",
            field: "expertSkills",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: (params) => { return getSKillValueString(params.data.expertSkills) },
            valueGetter: (params) => { return getSKillValueString(params.data.expertSkills) }
        },
        {
            headerName: "Status",
            field: "invitationStatus",
            tooltipField: "invitationStatus",
            filter: 'agTextColumnFilter',
            cellClass: getValidTextClass
        },
        {
            headerName: "Link Sent On",
            field: "invitationRequestSentOn",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.invitationRequestSentOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.invitationRequestSentOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`;
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "Status Updated On",
            field: "invitationRequestStatusUpdatedOn",
            tooltipValueGetter: ({ data }) => {
            if(data.invitationRequestStatusUpdatedOn!=null){
                return `${moment.utc(data.invitationRequestStatusUpdatedOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`;
            
            }
            else{
                return "N/A"
            }
        },
           valueGetter: (params) => {
            if(params.data.invitationRequestStatusUpdatedOn!=null){
                return `${moment.utc(params.data.invitationRequestStatusUpdatedOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${params.data.preferredTimezone})` : AppConstants.emptyString}`;
            }
            else{
                return "N/A"
            }
                },
            filter: 'agTextColumnFilter'
        }
    ];

    return (
        <div className={classes.container}>
            <div className={classes.showGrid}>
                <Row>
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            className="expertObrDetailsGrid"
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={5}
                            rowData={expertList}
                            enableBrowserTooltips={true}
                            domLayout='autoHeight'
                            suppressRowHoverHighlight={true}
                            defaultColDef={{
                                filter: true,
                                sortable: true,
                                flex: 1,
                                resizable: true,
                                suppressMenu: true,
                                suppressHorizontalScroll: true,
                                floatingFilterComponentParams: { suppressFilterButton: true },
                                floatingFilter: true,
                            }}
                            enableFilter={true}
                            enableSorting={true}
                            enableSearching={true}
                            headerClass={classes.obrGridHeader}
                            rowClass={classes.obrGridTable}
                        />
                    </div>
                </Row>
            </div>
            <div className={classes.showCards}>
                <Row xs={1} sm={1} md={1} lg={1} xl={1}>
                    {expertList.length === AppConstants.zeroIndex ? (<div className={classes.noDataContainer}>
                        <p className="text-center w-100 expertNoParticipantsErroMessage">No Data Available to display at the moment</p>
                    </div>) : (expertList.slice(AppConstants.zeroIndex, cardCount).map(item => (
                        <Col className={classes.learningActivityCardsColumn} key={item.expertObrUniqueId}>
                            <Card key={item.id} className={classes.learningActivityCards}>
                                <Card.Body>
                                    <div className={classes.obrCardsContainer}>
                                        <Badge className={getCssClassForEventType(item)}>{item.invitationStatus}</Badge>
                                    </div>
                                    <div className={classes.cardsContainer}>
                                        <Card.Text><AiOutlineUser /></Card.Text>
                                        <Card.Text>{item.expertQrenciaId} | {item.expertName}</Card.Text>
                                    </div>
                                    <div className={classes.cardsContainer}>
                                        <Card.Text><BsAward /></Card.Text>
                                        <Card.Text>{getSKillValueString(item.expertSkills)}</Card.Text>
                                    </div>
                                    <div className={classes.cardsContainer}>
                                        <Card.Text><BiCalendarCheck /></Card.Text>
                                        <Card.Text>Sent on: {moment.utc(item.invitationRequestSentOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)} {timeZone === AppConstants.utcTimezone && `(${item.preferredTimezone})`}</Card.Text>
                                    </div>
                                    <div className={classes.cardsContainer}>
                                        <Card.Text><BiCalendarCheck /></Card.Text>
                                        {item.invitationRequestStatusUpdatedOn && <Card.Text>Updated on: {moment.utc(item.invitationRequestStatusUpdatedOn).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)} {timeZone === AppConstants.utcTimezone && `(${item.preferredTimezone})`}</Card.Text>}
                                        {!item.invitationRequestStatusUpdatedOn && <Card.Text>Updated on: {AppConstants.notAvailableText}</Card.Text>}
                                    </div>
                                </Card.Body>
                            </Card></Col>)
                    ))
                    }
                </Row>
                {expertList.length !== AppConstants.zeroIndex && cardCount < expertList.length && <Row className='mt-2 mb-4'>
                    <Col className={classes.loadMoreContainer}>
                        <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + 6)}>{AppConstants.expertCreateObrButtons.loadMoreBtn.label}</Button>
                    </Col>
                </Row>}
            </div>
        </div>
    )
}