import { useEffect, useState } from "react"
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { QrenciaLoader } from "../../component/loader/QrenciaLoader";
import { DataService } from "../../services/DataService";
import { AppConstants } from "../../constants/AppConstants";
import { ScoreboardTable } from "../../component/competitionScoreboardComponent/ScoreboardTable";
import classes from "../../component/competitionScoreboardComponent/CompetitionScoreboard.module.css";
import judgingScreenClasses from "../../component/competitionJudgingComponents/ExpertMarkParticipantsScreen.module.css"
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import { IndividualCompetitionBanner } from "../../component/individualCompetition/IndividualCompetitionBanner";
import Moment from "react-moment";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { ChampionshipTable } from "../../component/competitionScoreboardComponent/ChampionshipTable";

export const CompetitionScoreboard = () => {
    const navigate = useNavigate();
    const landingPageData = useLoaderData();
    const { competitionSlug } = useParams();
    const [scoreboardData, setScoreboardData] = useState({});
    const [showSpinner, setShowSpinner] = useState(AppConstants.trueText);
    const [bannerDetails, setBannerDetails] = useState({});
    const [minimumSignupAge, setMinimumSignupAge] = useState(AppConstants.emptyString);
    // const [championshipDetails, setChampionshipDetails] = useState([]);

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const trophyIcon = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.scoreboardTrophyIconImage}`;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;

    useEffect(() => {
        // const fetchChampionshipDetails = async () => {
        //     const url = `${AppConstants.fetchGymnasticsChampionshipAPI}/${competitionSlug}`;
        //     const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
        //     if (response) {
        //         setChampionshipDetails(response);
        //     } else {
        //         setChampionshipDetails([]);
        //     }
        // };

        const fetchScoreboardData = async () => {
            const url = `${AppConstants.scoreboardAPI}/${competitionSlug}`;
            const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
            if (response) {
                setScoreboardData(response);
                setBannerDetails({
                    competitionTitle: response.title,
                    bannerImageDesktop: response.webBannerImagePath,
                    bannerImageTablet: response.tabletBannerImagePath,
                    bannerImageMobile: response.mobileBannerImagePath
                });

                setShowSpinner(AppConstants.falseText);

                // if (response.teamEnrolmentFlag === AppConstants.yText) {
                //     fetchChampionshipDetails();
                // }
            } else {
                setShowSpinner(AppConstants.falseText);
                navigate(AppConstants.asterisk);
            }
        }

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumSignupAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };

        if (competitionSlug) {
            fetchConfigurableKeys();
            fetchScoreboardData();
        } else {
            navigate(AppConstants.asterisk);
        }
    }, [competitionSlug, navigate]);

    const getAgeGroups = () => {
        if (scoreboardData.minAge !== null && scoreboardData.maxAge !== null) {
            return `Age ${scoreboardData.minAge}-${scoreboardData.maxAge}`;
        } else if (scoreboardData.minAge === null && scoreboardData.maxAge !== null) {
            return `Age ${minimumSignupAge}-${scoreboardData.maxAge}`;
        } else if (scoreboardData.minAge !== null && scoreboardData.maxAge === null) {
            return `Age ${scoreboardData.minAge}+`;
        } else {
            return "All Age groups";
        }
    };

    return (
        <div className={classes.scoreboardSection}>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            {
                showSpinner ? <QrenciaLoader loaderText={AppConstants.emptyString} /> :
                    <>
                        <IndividualCompetitionBanner competitionData={bannerDetails} />
                        <div className={judgingScreenClasses.thumbnailSection}>
                            <div className={judgingScreenClasses.thumbnailContainer}>
                                <img className={judgingScreenClasses.thumbnail} src={scoreboardData.thumbnailImagePath !== null ? scoreboardData.thumbnailImagePath : defaultThumbnail} alt="Competition Thumbnail"></img>
                            </div>
                            <div className={judgingScreenClasses.thumbnailDetailsSection}>
                                <div className={judgingScreenClasses.competitionTitle}>{scoreboardData.title}</div>
                                <div className={judgingScreenClasses.competitionDescription}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{scoreboardData.utcStartDate?.replace(' ', 'T') + 'Z'}</Moment> <p className={judgingScreenClasses.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{scoreboardData.utcStartDate?.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> <p className={judgingScreenClasses.hyphenText}>{AppConstants.hyphen}</p> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{scoreboardData.utcEndDate?.replace(' ', 'T') + 'Z'}</Moment> <p className={judgingScreenClasses.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{scoreboardData.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment></div>
                                <div className={judgingScreenClasses.competitionDescription}>Age Criteria: {getAgeGroups()}</div>
                            </div>
                        </div>
                        <div className={classes.tableContent}>
                            <div className={classes.trophySection}>
                                <img className={classes.trophyIcon} alt="trophy_icon" src={trophyIcon} />
                            </div>
                            <div className={classes.scoroardHeadingSection}>
                                <span className={classes.scoreboardHeading}>Scoreboard</span>
                            </div>
                            <ScoreboardTable scoreboardData={scoreboardData} />
                        </div>
                        {/* {championshipDetails.length > 0 && <div className={classes.tableContent}>
                            <div className={classes.scoroardHeadingSection}>
                                <span className={classes.scoreboardHeading}>Team Championship</span>
                            </div>
                            <ChampionshipTable championshipDetails={championshipDetails} />
                        </div>} */}
                        <p className={classes.scoreboardFooterMessage}>{AppConstants.scoreboardPageFooterMessage}</p>
                    </>
            }
        </div>
    )
}