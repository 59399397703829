import React, { useState, useEffect } from 'react';
import classes from './IndividualCompetitionBanner.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const IndividualCompetitionBanner = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultWebBanner}`;
    const defaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultTabletBanner}`;
    const defaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultMobileBanner}`;
    const [mobileImage, setMobileImage] = useState(null);
    const [tabletImage, setTabletImage] = useState(null);
    const [webImage, setWebImage] = useState(null);

    useEffect(() => {
        setWebImage(props.competitionData.bannerImageDesktop ? props.competitionData.bannerImageDesktop : defaultWebBanner);
        setTabletImage(props.competitionData.bannerImageTablet ? props.competitionData.bannerImageTablet : defaultTabletBanner);
        setMobileImage(props.competitionData.bannerImageMobile ? props.competitionData.bannerImageMobile : defaultMobileBanner);
    }, [props.competitionData]);

    return (
        <>
            <div className={classes.competitionMobileImgContainer}>
                {!props.competitionData.bannerImageMobile && <div className={classes.competitionBannerTitleContainer}><p className={classes.competitionBannerTitle}>{props?.competitionData?.competitionTitle}</p></div>}
                <img src={mobileImage} alt={AppConstants.competitionBannerText} className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionTabletImgContainer}>
                {!props.competitionData.bannerImageTablet && <div className={classes.competitionBannerTitleContainer}><p className={classes.competitionBannerTitle}>{props?.competitionData?.competitionTitle}</p></div>}
                <img src={tabletImage} alt={AppConstants.competitionBannerText} className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionBannerImgContainer}>
                {!props.competitionData.bannerImageDesktop && <div className={classes.competitionBannerTitleContainer}><p className={classes.competitionBannerTitle}>{props?.competitionData?.competitionTitle}</p></div>}
                <img src={webImage} alt={AppConstants.competitionBannerText} className={classes.competitionWebBannerImage} />
            </div>
        </>
    );
}