import React from "react";
import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AllSkills } from "../../component/extendedPageComponents/skills/AllSkills";

import {AppConstants} from "../../constants/AppConstants";
import { SeoSchemaConstants } from "../../constants/SeoSchemaConstants";
export const SkillsPage = () => {
  const [landingPageData, skillsData, bannerSectionData] = useLoaderData();
  const seoTitle = SeoSchemaConstants.allSkillsPageSeoTitle
  const seoDescription = SeoSchemaConstants.allSkillsSeoDescription
  const seoImage = { ...bannerSectionData.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.mobileText)) };
  const seoKeywords = SeoSchemaConstants.allSkillsSeoKeywords;
  return (
    <div>
  
      <LandingPageTopNavigation landingPageData={landingPageData} />
      <AllSkills skillsData={skillsData} bannerImage={bannerSectionData} />
      <LandingPageFooter landingPageData={landingPageData} />
    </div>

  );
}

