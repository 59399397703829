import { useState, useEffect } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { DataService } from "../../services/DataService";
import classes from "./ExpertUpdateInvitationStatus.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import Auth from "../../utils/Auth";
import { AcceptAgencyInvitationLinkModal } from "../modal/AcceptAgencyInvitationLinkModal";
import { Row, Col, Button } from 'react-bootstrap';
import { BiLoaderAlt } from "react-icons/bi";
import { MdOpenInNew } from 'react-icons/md'
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { SuccessMessage } from "../../constants/SuccessMessage";


export const ExpertUpdateInvitationStatus = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [footerData, setFooterData] = useState(AppConstants.emptyString);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const { agencyUniqueId, expertUniqueId } = useParams();
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [userStatusUpdated, setUserStatusUpdate] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [approveSpinner, setApproveSpinner] = useState(AppConstants.falseText);
    const [rejectSpinner, setRejectSpinner] = useState(AppConstants.falseText);
    const [status, setStatus] = useState(AppConstants.emptyString);
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.blueTriangleImagePath}`;
    const [pageLoader, setPageLoader] = useState(AppConstants.trueText);
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    const associationStatus = AppConstants.agencyExpertInvitationStatus;
    const navigate = useNavigate();


    useEffect(() => {
        fetchFooterData();
        async function fetchFooterData() {
            const url = AppConstants.landingPageDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setFooterData(response);
        };

        async function getAgencyDetails() {
            const url = `${AppConstants.fetchExpertMappingPageAgencyDetailsAPI}${agencyUniqueId}&teacherUniqueId=${expertUniqueId}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setAgencyDetails(response);
                setPageLoader(AppConstants.falseText);
            } else {
                navigate(AppConstants.asterisk);
                setPageLoader(AppConstants.falseText);
            }
        };

        getAgencyDetails();
    }, []);

    const updateInvitaionStatus = async (status) => {
        try {
            setStatus(status);

            if (!Auth.isLogin()) {
                setModalTypeError(AppConstants.trueText);
                setMessage(ErrorMessages.loginToContinueText);
                handleModalShow();
            } else if (Auth.getLoggedInUserDetails().userRole !== AppConstants.userRoleExpert) {
                setModalTypeError(AppConstants.trueText);
                setMessage(ErrorMessages.onlyExpertApproveInvitationStatusMessage);
                handleModalShow();
            } else {
                if (status === associationStatus.accepted) {
                    setApproveSpinner(AppConstants.trueText);
                } else {
                    setRejectSpinner(AppConstants.trueText);
                }

                const apiParams = AppConstants.updateExpertSchoolMappingAPIParams;
                const url = `${AppConstants.updateExpertSchoolMappingStatusAPI}?${apiParams.schoolUniqueId}=${agencyUniqueId}&${apiParams.teacherUniqueId}=${expertUniqueId}&${apiParams.status}=${status}`;
                const data = {
                    expertQrenciaId: Auth.getLoggedInUserDetails().userId,
                    agencyUniqueId,
                    status
                };

                const response = await AuthorizedDataService.putRequestWithResponseCode(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                    status === associationStatus.accepted ? setMessage(SuccessMessage.expertAcceptedInvitationRequestMessage) : setMessage(SuccessMessage.expertRejectedInvitationRequestMessage);
                    setModalTypeError(AppConstants.falseText);
                    setUserStatusUpdate(status);
                    handleModalShow();
                    setRejectSpinner(AppConstants.falseText);
                    setApproveSpinner(AppConstants.falseText);
                    setStatus(AppConstants.emptyString);
                } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                    setModalTypeError(AppConstants.trueText);
                    status === associationStatus.accepted ? setMessage(ErrorMessages.expertAlreadyAssociatedWithTheSchool) : setMessage(ErrorMessages.expertAlreadyAssociatedWithTheSchool);
                    handleModalShow();
                    setRejectSpinner(AppConstants.falseText);
                    setApproveSpinner(AppConstants.falseText);
                    setStatus(AppConstants.emptyString);
                } else {
                    setModalTypeError(AppConstants.trueText);
                    status === associationStatus.accepted ? setMessage(ErrorMessages.expertAcceptInvitationRequestFailed) : setMessage(ErrorMessages.expertRejectInvitationRequestFailed);
                    handleModalShow();
                    setRejectSpinner(AppConstants.falseText);
                    setApproveSpinner(AppConstants.falseText);
                    setStatus(AppConstants.emptyString);
                }
            }
        } catch (error) {
            setModalTypeError(AppConstants.trueText);
            status === associationStatus.accepted ? setMessage(ErrorMessages.expertAcceptInvitationRequestFailed) : setMessage(ErrorMessages.expertRejectInvitationRequestFailed);
            handleModalShow();
            setRejectSpinner(AppConstants.falseText);
            setApproveSpinner(AppConstants.falseText);
            setStatus(AppConstants.emptyString);
        }
    };


    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    }

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    }

    return (
        <>
            {!pageLoader && <>
                <LandingPageTopNavigation />
                <div className={classes.updateInvitationPage}>
                    <div className={classes.headingSection}>
                        <img
                            className={classes.blueTriangleImage}
                            src={blueTriangleImageUrl}
                            alt="Blue Triangle"
                        />
                        <h2 className={classes.heading}>Association Request from <span>{agencyDetails.schoolName}</span></h2>
                    </div>
                    <div className={classes.detailsContainer}>

                        {<Row className="mt-2">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={classes.columnContainer}>
                                    <h5 className={classes.detailsHeading}>School Details</h5>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>Name</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolName}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>Email</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolEmail}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>Contact Number</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolCountryMobileCode}-{agencyDetails.schoolContactNumber}</p>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>State</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolState ? agencyDetails.schoolState : AppConstants.notAvailableText}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>City</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolCity ? agencyDetails.schoolCity : AppConstants.notAvailableText}</p>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>School Years of experience</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolYearsOfExperience ? agencyDetails.schoolYearsOfExperience : AppConstants.notAvailableText}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>Branding Page</p>
                                    <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={`${AppConstants.forwardSlash}${AppConstants.agency}${AppConstants.forwardSlash}${agencyDetails.schoolUserSlug}`}>Click Here <MdOpenInNew /></a>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>Geo Location</p>
                                    <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={agencyDetails.schoolGeoLocation}>Click Here <MdOpenInNew /></a>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={classes.columnContainer}>
                                    <p className={classes.expertDetailsLabel}>About {agencyDetails.schoolName}</p>
                                    <p className={classes.expertDetailsText}>{agencyDetails.schoolDescription !== null ? agencyDetails.schoolDescription : AppConstants.schoolDescription}</p>
                                </div>
                            </Col>
                            {(agencyDetails.teacherSchoolMappingStatus === associationStatus.sent && !userStatusUpdated) && agencyDetails.teacherAssociatedSchoolName === AppConstants.nullText && <div className={`mt-2 ${classes.buttonDiv}`}>
                                <Button disabled={approveSpinner || rejectSpinner} onClick={() => updateInvitaionStatus(associationStatus.accepted)} className={classes.selectButton}>Accept {approveSpinner && <BiLoaderAlt className='spinner' />}</Button>
                                <Button disabled={approveSpinner || rejectSpinner} onClick={() => updateInvitaionStatus(associationStatus.rejected)} className={classes.selectButton}>Reject {rejectSpinner && <BiLoaderAlt className='spinner' />}</Button>
                            </div>}
                            {agencyDetails.teacherSchoolMappingStatus !== associationStatus.sent && <div className={`mt-2 ${classes.warningDiv}`}>
                                {agencyDetails.teacherSchoolMappingStatus === associationStatus.accepted && <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You have accepted the invitation from {agencyDetails.schoolName}</p></div>}
                                {agencyDetails.teacherSchoolMappingStatus === associationStatus.rejected && <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You have rejected the invitation from {agencyDetails.schoolName}</p></div>}
                            </div>}
                            {userStatusUpdated && <div className={`mt-2 ${classes.warningDiv}`}>
                                {userStatusUpdated === associationStatus.accepted && <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You have accepted the invitation from {agencyDetails.schoolName}</p></div>}
                                {userStatusUpdated === associationStatus.rejected && <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You have rejected the invitation from {agencyDetails.schoolName}</p></div>}
                            </div>}
                            {agencyDetails.teacherSchoolMappingStatus === associationStatus.sent && agencyDetails.teacherAssociatedSchoolName !== AppConstants.nullText && <div className={`mt-2 ${classes.warningDiv}`}>
                                {<div className={classes.warningMessage}><p><AiOutlineExclamationCircle />This invitation is no longer valid as the expert has already accepted the invitation for {agencyDetails.teacherAssociatedSchoolName}</p></div>}
                            </div>}
                        </Row>}
                    </div>
                    {(message !== AppConstants.emptyString) && showModal && <AcceptAgencyInvitationLinkModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} status={status} updateInvitaionStatus={updateInvitaionStatus}></AcceptAgencyInvitationLinkModal>}
                </div>
                {footerData && <LandingPageFooter landingPageData={footerData} />}
            </>}

            {pageLoader &&
                <div className={classes.preLoaderContainer}>
                    <div className={classes.preLoaderDiv}>
                        <div className={classes.loader}></div>
                        <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                        <p className={classes.loaderText}>Fetching School Details..</p>
                    </div>
                </div>}
        </>
    );
}