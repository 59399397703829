import { useEffect, useState, useRef } from "react";

export function useOnlineStatus() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const isOnlineRef = useRef(isOnline);

    const updateNetworkStatus = () => {
        setIsOnline(navigator.onLine);
        isOnlineRef.current = navigator.onLine;
    };

    useEffect(() => {
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);

    return isOnlineRef;
}
