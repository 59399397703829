import { Link, useLocation } from 'react-router-dom';
import { AppConstants } from '../constants/AppConstants';
import React from "react";
export const Breadcrumbs = (props) => {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className={props.class}>
      {pathnames.length > 0 && (
        <div>
          <Link style={{ textDecoration: "underline", textUnderlineOffset: "0.4em", marginRight: "0.3em" }} to={AppConstants.forwardSlash}>{AppConstants.breadcrumbHome}</Link>
          {pathnames.map((pathname, index) => {
            const routeTo = `${pathnames.slice(0, index + 1).join(AppConstants.forwardSlash)}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <span style={{ fontWeight: "600", marginLeft: "0.3em" }} key={routeTo}> {AppConstants.greaterThanSign} {pathname}
              </span>
            ) : (
              <span key={routeTo}>
                {AppConstants.greaterThanSign} <Link style={{ textDecoration: "underline", textUnderlineOffset: "0.4em", marginLeft: "0.3em", marginRight: "0.3em" }} to={`${AppConstants.forwardSlash}${routeTo}`} state={props.data}>{pathname}</Link>
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};