import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import styles from "./AdvertismentSection.module.css";
import { AppConstants } from '../../constants/AppConstants';

export const AdvertismentSectionOne = (props) => {
    const [advertismentDetails, setAdvertismentDetails] = useState(props.adDetails);

    useEffect(() => {
        setAdvertismentDetails(props.adDetails);
    }, [props.adDetails])

    return (
        <>
            <div className={styles.advertismentMainContainer}>
                <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-5 col-lg-4'>
                        <div className={styles.broughtToYouByContainer}>
                            <p className={styles.broughtToYouByText}>{AppConstants.performanceReportAdvertismentStaticText}</p>
                            <p className={styles.sponserText}>{advertismentDetails?.sponsorName}</p>
                            <p className={styles.adTitleText}>{advertismentDetails?.title}</p>
                            <p className={styles.adSubTitleText}>{advertismentDetails?.description}</p>
                        </div>
                    </div>
                    {advertismentDetails?.dedicatedPageFlag === AppConstants.yText ?
                        <div className='col-xs-12 col-sm-12 col-md-7 col-lg-8'>
                            <a href={advertismentDetails?.imageUrl} target={AppConstants.openInNewTab} className={`${styles.advertismentImageContainer} ${styles.dedicatedAdvertismentContainer}`}>
                                <img className={`${styles.advertismentDedicatedImage}`} src={advertismentDetails?.advertisementImageUrl} alt="Advertisment" />
                            </a>
                        </div> :
                        <div className='col-xs-12 col-sm-12 col-md-7 col-lg-8'>
                            <a href={advertismentDetails?.imageUrl} target={AppConstants.openInNewTab} className={styles.advertismentImageContainer}>
                                <img className={styles.advertismentImage} src={advertismentDetails?.advertisementImageUrl} alt="Advertisment" />
                            </a>
                        </div>}
                </div>
            </div>
        </>
    );
}

