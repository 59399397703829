import { AppConstants } from "../../../constants/AppConstants"
import reviewCardsClass from "./CourseReviewCards.module.css"
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";

export const CourseReviewCards = ({ review, individualSkillsFlag }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    return <div className={reviewCardsClass.reviewContainer}>
        <div className={reviewCardsClass.profileImageSection}>
            {review.userImagePath ? <div className={reviewCardsClass.reviewInitials}><img src={review.userImagePath} className={reviewCardsClass.profilePicture} alt="user_profile" /></div> : <div className={reviewCardsClass.reviewInitials}>{review.nameInitials}</div>}
            <div>
                <p className={reviewCardsClass.reviewerName}>{review.studentName}</p>
                {individualSkillsFlag && <p className={reviewCardsClass.courseTitle}>Review for {review.learningActivityTitle} by {review.creatorName} | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{review.commentRaisedOn?.replace(' ', 'T') + 'Z'}</Moment>  {timeZone === AppConstants.utcTimezone && `(${review.preferredTimezone})`}</p>}
                {!individualSkillsFlag && <p className={reviewCardsClass.courseTitle}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{review.commentRaisedOn?.replace(' ', 'T') + 'Z'}</Moment>  {timeZone === AppConstants.utcTimezone && `(${review.preferredTimezone})`}</p>}
            </div>

        </div>

        <p className={reviewCardsClass.reviewComment}>{review.reviewComment}</p>
    </div>
}