import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import Auth from "../../utils/Auth";
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const EnrolInCourse = {

    enrolInSelectedCourse(courseDetails) {
        return this.enrol(courseDetails);
    },

    async enrol(courseDetails) {
        try {
            if (!(Auth.isLogin())) {
                return { message: ErrorMessages.pleaseLogInToEnrolError };
            } else {
                if (Auth.getUserRole() !== AppConstants.userRoleStudent) {
                    return { message: ErrorMessages.onlyStudentEnrolCourseError };
                } else {
                    let data = {
                        courseUniqueId: courseDetails.uniqueId,
                        enrolmentDate: DateTimeUtil.formatDateTime(DateTimeUtil.createDateTimeObject(new Date().toISOString().split('T')[0], "00:00"), DateTimeUtil.getPreferredTimeZoneForUser()),
                        offlinePaymentApprovalRequest: AppConstants.nText,
                        razorpayPaymentActiveFlag: AppConstants.yText
                    }
                    let response = await AuthorizedDataService.postRequestWithResponseCode(
                        AppConstants.enrolInCourseAPI,
                        data,
                        AppConstants.emptyString,
                        AppConstants.emptyString
                    );

                    if (response) {
                        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                            return { message: ErrorMessages.sessionExpiredCourseEnrolmentError };
                        } else {
                            const body = await response.json();
                            return { message: this.getEnrolmentStatus(body, courseDetails.cost, courseDetails.subscriptionType) };
                        }
                    } else {
                        return { message: this.getEnrolmentStatus(AppConstants.nullText, courseDetails.cost) }
                    }
                }
            }
        } catch {
            if (courseDetails.cost === 0 || courseDetails.cost === null || courseDetails.cost === AppConstants.freeText) {
                return { message: ErrorMessages.enrollmentFailError };
            } else {
                return { message: ErrorMessages.paymentDeductedEnrolmentError };
            }
        }
    },

    getEnrolmentStatus(data, cost, subscriptionType) {
        if (data === 1) {
            return subscriptionType ? (subscriptionType === AppConstants.durationBasedTrackingText ? SuccessMessage.addDurationSuccessMessage : SuccessMessage.addClassSuccessMessage) : SuccessMessage.studentEnrollmentSuccess;
        } else if (data === -111) {
            return ErrorMessages.alreadyEnroledInCourseError;
        } else if (data === -113) {
            return ErrorMessages.courseRegistrationEndedError;
        } else if (data === -121) {
            return ErrorMessages.unpublishedCourseEnrolmentError;
        } else if (data === -122) {
            return ErrorMessages.enrolmentsDisabledForCourseError;
        } else if (data === -141) {
            return ErrorMessages.subscriptionActiveEnrolError;
        } else if (data === -143) {
            return ErrorMessages.reRequestForCODerror
        } else {
            if (cost === 0 || cost === null || cost === undefined || cost === AppConstants.emptyString || cost === AppConstants.freeText) {
                return ErrorMessages.enrollmentFailError;
            } else {
                return ErrorMessages.paymentDeductedEnrolmentError;
            }
        }
    }
}