import React from "react";
import { AppConstants } from "../../constants/AppConstants";
import classes from './IndividualSkillsPageList.module.css';
import { HiOutlineLightBulb } from 'react-icons/hi';
import {TiTickOutline} from 'react-icons/ti';
import {BiBriefcase} from 'react-icons/bi';
export const IndividualSkillsPageList=(props)=>{
return(
    <>
    <div className={classes.skillList}>
    <h1 className={classes.heading}>{props.skillHighlightsHeading.title} <span>{props.skill}</span></h1>
     <div className={classes.skillThreeColumn}>
                <div className={classes.interestSection}>
                    <h4>{AppConstants.couldBeForYou}</h4>
                    <div className={classes.listBackground}>
                        <ul>
                            {props.skillInterest.split(',').map( (listItem, index) =>(
                            <li key={index}><TiTickOutline className={classes.couldBeForYouIcon}/>{listItem}</li>))  }
                        </ul>
                    </div>
                </div>
            <div className={classes.requiredSection}>
                <h4>{AppConstants.skillsRequiredToBeGoodIn}</h4>
                <div className={classes.listBackground}>
                    <ul>
                    {props.skillRequired.split(',').map( (listItem, index) =>(
                    <li key={index}><HiOutlineLightBulb className={classes.skillsRequiredToBeGoodInIcon}/>{listItem}</li>
                    ))}
                    </ul>
                </div>   
            </div>
            <div className={classes.useSection}>
                <h4>{AppConstants.whereCanYouPut}</h4>
                <div className={classes.listBackground}>
                    <ul>
                    {props.skillUse.split(',').map( (listItem, index) =>(
                    <li key={index}><BiBriefcase className={classes.whereCanYouPutIcon}/>{listItem}</li>
                    ))}
                    </ul>
                </div>
            </div>
            </div>
            </div>
    </>
)
}