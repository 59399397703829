import { TiArrowLeft } from "react-icons/ti";
import classes from "./MarkSpecificCourseAttendance.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { useEffect, useState } from "react";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { ViewSelectedWeekDays } from "../../calendar/ViewSelectedWeekDays";
import { BiLoaderAlt } from "react-icons/bi";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Auth from "../../../utils/Auth";
import { QuizAuthenticationForm } from "../../quizEventComponent/QuizAuthenticationForm";
import { LandingPageFooter } from "../../../layout/LandingPageLayout/LandingPageFooter";
import { LandingPageTopNavigation } from "../../../layout/LandingPageLayout/LandingPageTopNavigation";
import { Logout } from "../../../common/Logout";
import { AttendanceBasedTrackingScreen } from "./attendanceBasedTrackingScreen/AttendanceBasedTrackingScreen";
import { CounterBasedTrackingScreen } from "./counterBasedTrackingScreen/CounterBasedTrackingScreen";
import { Row } from "react-bootstrap";
import { MathUtil } from "../../../utils/MathUtil";

export const MarkSpecificCourseAttendance = () => {
    const [selectedCourse, setSelectedCourse] = useState({});
    const [showPopupForLogin, setShowPopupForLogin] = useState(AppConstants.falseText);
    const [pageLoaderSpinner, setPageLoaderSpinner] = useState(AppConstants.trueText);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [logoutMessage, setLogoutMessage] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const { courseUniqueId } = useParams();
    const [landingPageData] = useLoaderData();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCourseDetails = async () => {
            const url = `${AppConstants.getSpecificCourseDetailsAPI}${courseUniqueId}`;
            const response = await AuthorizedDataService.getRequestWithResponseCode(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                setLogoutMessage(AppConstants.trueText);
                setPageLoaderSpinner(AppConstants.falseText);
            } else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                navigate(AppConstants.asterisk);
            } else {
                if ((response.trackingType === AppConstants.durationBasedTrackingText && response.isAttendanceEnabledFlag === AppConstants.nText) || (response.courseType === AppConstants.structuredCourseType && response.trackingType === AppConstants.trackingNotEnabledText)) {
                    navigate(AppConstants.asterisk);
                } else {
                    setSelectedCourse(response);
                    setPageLoaderSpinner(AppConstants.falseText);
                }
            }
        };

        if (courseUniqueId) {
            if (Auth.isLogin()) {
                if (Auth.getUserRole() !== AppConstants.userRoleExpert && Auth.getUserRole() !== AppConstants.userRoleAgency) {
                    navigate(AppConstants.asterisk);
                    setShowPopupForLogin(AppConstants.falseText);
                } else {
                    fetchCourseDetails();
                }
            } else {
                setShowPopupForLogin(AppConstants.trueText)
            }
        }

    }, [courseUniqueId, navigate, timeZone]);

    const viewAllCourseScreen = () => {
        const url = `${process.env.REACT_APP_FRONT_END_URL}course/mark-attendance`;
        window.location.href = url;
    }

    const closeAuthenticationForm = () => {
        window.location.reload();
        setShowPopupForLogin(AppConstants.trueText);
    };


    return (
        <>
            {
                showPopupForLogin ? <div className={classes.loginSection}>
                    {/* <LoginPageTopNavigation /> */}
                    <QuizAuthenticationForm disableSignup={AppConstants.trueText} closeAuthenticationForm={closeAuthenticationForm} signupHeading="Signup" loginHeading="Login" />
                    {/* <LandingPageFooter landingPageData={landingPageData} /> */}
                </div> : (pageLoaderSpinner || logoutMessage) ? <>
                    <LandingPageTopNavigation landingPageData={landingPageData} />
                    <div className={classes.loaderContainer}>
                        {pageLoaderSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}
                        {logoutMessage && <p className={classes.sessionExpiredText}>Your session has been expired. Please <span onClick={Logout.logout}>login</span> to continue.</p>}
                    </div>
                </>
                    : <>
                        <LandingPageTopNavigation landingPageData={landingPageData} />
                        <div className={classes.pageContainer}>
                            <TiArrowLeft onClick={viewAllCourseScreen} className={classes.obrBackButton} />
                            <Row>
                                <h1 className={classes.markAttendanceHeading}>{(selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.nText) ? "Track Classes" : "Mark and View Attendance"}</h1>
                            </Row>
                            <div className={classes.infoSection}>
                                {selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.nText && <div className={classes.thumbnailSection}>
                                    <div className={classes.thumbnailContainer}>
                                        <img className={classes.thumbnail} src={selectedCourse.courseThumbnailImageAbsolutePath !== null ? selectedCourse.courseThumbnailImageAbsolutePath : defaultCourseThumbnail} alt="Course Thumbnail"></img>
                                    </div>
                                    <div className={classes.thumbnailDetailsSection}>
                                        <div className={classes.courseTitle}>{selectedCourse.courseTitle}</div>
                                        {selectedCourse.courseType === AppConstants.structuredCourseType && <div className={classes.courseDescription}>
                                            {selectedCourse.startTime.split(",")[1]}&nbsp;-&nbsp;{selectedCourse.endTime.split(",")[1]} {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</div>}
                                        {((selectedCourse.weekDays?.length !== 0) || (selectedCourse.startTime != null) || (selectedCourse.endTime != null)) && <div className={`${classes.courseDescription}`}>
                                            <div className={classes.courseTimings}>{selectedCourse.startTime?.split(",")[0]} - {selectedCourse.endTime?.split(",")[0]} {timeZone === AppConstants.utcTimezone && selectedCourse.courseType === AppConstants.flexibleCourseType && `(${selectedCourse.preferredTimezone})`}</div>
                                            {(selectedCourse.weekDays && selectedCourse.weekDays?.length !== 0) && <ViewSelectedWeekDays selectedValues={selectedCourse.weekDays} />}
                                        </div>}
                                        <div className={classes.courseDescription}>{MathUtil.formatNumberToLocaleString(selectedCourse.participantsDetails.length)} Enrolments</div>
                                    </div>
                                </div>}
                            </div>
                            {selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.nText && <div>
                                <CounterBasedTrackingScreen selectedCourse={selectedCourse} courseUniqueId={courseUniqueId} />
                            </div>}
                            {((selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.yText) || (selectedCourse.trackingType === AppConstants.durationBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.yText)) && <div>
                                <AttendanceBasedTrackingScreen selectedCourse={selectedCourse} courseUniqueId={courseUniqueId} />
                            </div>}
                        </div>
                        <LandingPageFooter landingPageData={landingPageData} />
                    </>
            }
        </>
    )
}

