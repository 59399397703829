import { useState } from "react";
import classes from "./ExpertWelcomePageVideo.module.css";
import { AppConstants } from "../../constants/AppConstants";
import { IoMdClose } from "react-icons/io";

export const ExpertWelcomePageVideo = ({ video }) => {
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(AppConstants.trueText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;

    const playVideo = () => {
        setVideoPlaying(true);
        setShowPlayIcon(false);
    };

    const handleClose = () => {
        setVideoPlaying(false);
        setShowPlayIcon(true);
    };

    return (
        <>
            <div className={classes.videoContainer}>
                {isVideoPlaying ? (<div><video controls autoPlay className={classes.videoContent}>
                    <source src={video.videoPath} type="video/mp4" />
                </video>  <div>
                        <IoMdClose className={classes.closeButton} onClick={handleClose} />
                    </div></div>
                ) : (<img src={video.imagePath} className={classes.videoContent} alt="Thumbnail" />)}
                <div className={classes.videoPlayImage}>
                    {(showPlayIcon) && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={classes.videoIcon} />}
                </div>

            </div>
            {
                video.title && <div className={classes.videoTitle}>
                    <p>{video.title}</p>
                </div>
            }
        </>

    )
}