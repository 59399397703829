import { useState } from "react";
import { Card } from "react-bootstrap";
import parse from 'html-react-parser';
import classes from './QrenciaNews.module.css';
import { AppConstants } from "../../constants/AppConstants";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './QrenciaNewsCarousel.css';
import { VideoPopup } from "../popup/VideoPopup";

export const QrenciaNews = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const backgroundGreyDotsUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.backgroundGreyDotsPath}`;
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.pinkTriangleImagePath}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const [qrenciaNews, setQrenciaNews] = useState(props.landingPageData.news);
    const [showVideoPopup, setShowVideoPopup] = useState(AppConstants.falseText);
    const [videoId, setVideoId] = useState(AppConstants.emptyString);
    const [qrenciaNewsSectionDetails, setQrenciaNewsSectionDetails] = useState(props.landingPageData.newsContainer);

    const responsiveSlider = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 800 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1
        }
    };

    const cardClickHandler = (newsLink) => {
        if (newsLink != null) {
            window.open(newsLink, AppConstants.openInNewTab);
        }
    };

    const closeVideoPopup = () => {
        setShowVideoPopup(AppConstants.falseText);
        setVideoId(AppConstants.emptyString);
    };

    return (
        <>
            {qrenciaNewsSectionDetails.active === AppConstants.trueText && <div className={classes.newsSection}>
                <div className={classes.backgrounImageSection}>
                    <img
                        className={classes.backgroundDotsImage}
                        src={backgroundGreyDotsUrl}
                        alt="Background Cover"
                    />
                </div>
                <div className={classes.newsHeadingSection}>
                    <img
                        className={classes.blueTriangleImage}
                        src={blueTriangleImageUrl}
                        alt="Blue Triangle"
                    />
                    <h2 className={classes.titleText}>{parse(qrenciaNewsSectionDetails.title)}</h2>
                </div>
                <div className={classes.newsCarouselSection}>
                    {qrenciaNews !== AppConstants.nullText && <Carousel responsive={responsiveSlider} autoPlay={showVideoPopup ? AppConstants.falseText : AppConstants.trueText}
                        autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
                        customTransition={AppConstants.qrenciaNewsCustomTransition}
                        rewindWithAnimation={AppConstants.trueText}
                        transitionDuration={AppConstants.qrenciaNewsTransitionDuration}
                        infinite={AppConstants.trueText}
                        className="newsSectionCarousel">
                        {qrenciaNews.filter((data) => data.isActiveFlg === AppConstants.trueFlag).filter((data) => data.status === AppConstants.trueFlag).map((news) => (
                            <Card key={news.id} className={`${classes.newsCard} ${news.link != null ? classes.cursorPointer : classes.cursorDefault}`} onClick={() => cardClickHandler(news.link)}>
                                <div className={classes.cardImageSection}>
                                    <Card.Img src={news.image} className={classes.newsImage} />
                                    {news.videoLink !== AppConstants.nullText && <img onClick={(e) => { e.stopPropagation(); setShowVideoPopup(AppConstants.trueText); setVideoId(news.videoLink) }}
                                        className={classes.videoPlayIcon}
                                        src={videoPlayIconUrl}
                                        alt="Video Play Button"
                                    />}
                                </div>
                                <Card.Body className={classes.newsCardBody}>
                                    <h1 className={classes.newsTitle}>
                                        {news.title}
                                    </h1>
                                    <p className={classes.newsDescription}>
                                        {news.description}
                                    </p>
                                </Card.Body>
                            </Card>
                        ))}
                    </Carousel>}
                </div>
                {showVideoPopup && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeVideoPopup} videoUrl={videoId} />}
            </div>}
        </>
    );
}