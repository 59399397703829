import React, { useState } from 'react';
import classes from './QuizListingPageBanner.module.css'
import { AppConstants } from '../../constants/AppConstants';
import parse from 'html-react-parser';

export const QuizListingPageBanner = (props) => {
    const [bannerImageMobile, setBannerImageMobile] = useState(...props.bannerImage.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.mobileText)));
    const [bannerImageTablet, setBannerImageTablet] = useState(...props.bannerImage.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.tabletText)));
    const [bannerImageWeb, setBannerImageWeb] = useState(...props.bannerImage.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.webText)));

    return (
        <>
            <div className={classes.mobileImage}>
                <div className={classes.epBannerItem}>
                    <img src={bannerImageMobile.imageUrl} alt="Mobile Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        {bannerImageMobile.title && <h3>{parse(bannerImageMobile.title)}</h3>}
                        {bannerImageMobile.subTitle && <p className={classes.subHeadingText}>{parse(bannerImageMobile.subTitle)}</p>}
                    </div>
                </div>
            </div>
            <div className={classes.tabletImage}>
                <div className={classes.epBannerItem}>
                    <img src={bannerImageTablet.imageUrl} alt="Tablet Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        {bannerImageTablet.title && <h3>{parse(bannerImageTablet.title)}</h3>}
                        {bannerImageTablet.subTitle && <p className={classes.subHeadingText}>{parse(bannerImageTablet.subTitle)}</p>}
                    </div>
                </div>
            </div>
            <div className={classes.webImage}>
                <div className={classes.epBannerItem}>
                    <img src={bannerImageWeb.imageUrl} alt="Web Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        {bannerImageWeb.title && <h3>{parse(bannerImageWeb.title)}</h3>}
                        {bannerImageWeb.subTitle && <p className={classes.subHeadingText}>{parse(bannerImageWeb.subTitle)}</p>}
                    </div>
                </div>
            </div>

        </>
    );
}