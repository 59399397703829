export const StudentTemplatesConstants = {
    skillsArray: ["Batik", "Cartooning", "Digital Art"],
    activityArray: ["Competition", "QTalk", "SKill Labs", "Nudges", "Mentorship", ""],
    modeArray: ["Online", "Offline"],
    activitySnapshotCardTitles: {
        competitionsParticipated: "Competitions Participated",
        skillLabsEnrolled: "Courses Enrolled",
        nudgeRaisedAndReceived: "Nudge Raised and Received",
        mentorshipSessionEnrolled: "Mentorship Session Enrolled",
        workshopsEnrolled: "Workshops Enrolled"
    },
    learningActivityTabs: {
        competition: { displayText: "Competition", key: "competition" },
        Courses: { displayText: "Course", key: "Course" },
        workshop: { displayText: "Workshop", key: "workshop" },
        mentorship: { displayText: "Mentorship", key: "mentorship" },
        nudge: { displayText: "Nudge", key: "nudge" },
        qtalks: { displayText: "Qtalks", key: "qtalks" },
        brainBooster: { displayText: "Brain Booster", key: "brainBooster" },
    }
};