import { useState } from "react"
import { Button } from "react-bootstrap"
import { AppConstants } from "../../constants/AppConstants";
import { BiLoaderAlt } from "react-icons/bi";
import { BsMagic } from 'react-icons/bs';
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import Auth from "../../utils/Auth";

export const LearningActivityAiGenerationComponent = (props) => {
    const [aiGeneratorSpinner, setAiGeneratorSpinner] = useState(AppConstants.falseText);
    const getImageBinary = (aiImages) => {
        return aiImages.map(async (image) => {
            return fetch(image.imageURL)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(ErrorMessages.aiImageGenerationError);
                    }
                    return response.arrayBuffer();
                }).then(imageData => {
                    const dataTransfer = new DataTransfer();
                    const file = new File([imageData], image.name, { type: image.mimeType });
                    dataTransfer.items.add(file);
                    return { id: image.id, dataTransfer, imageURL: image.imageURL };
                }).catch(error => {
                    return AppConstants.nullText;
                });
        });
    };

    const fetchAiGeneratedImages = async (payload) => {
        try {
            const { userEmail, name, profileImagePreSignedUrl, userRole, agencyName } = Auth.getLoggedInUserDetails();
            const data = {
                ...payload,
                creatorProfilePictureURL: profileImagePreSignedUrl,
                sessionId: userEmail,
                creatorName: userRole === AppConstants.userRoleAgency ? agencyName : name
            };
            const url = AppConstants.getAIBannerImagesAPI;
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok) {
                const body = await response.json();
                let responseArray = getImageBinary(body.data.s3Objects);
                if (responseArray) {
                    let imageData = await Promise.all(responseArray)
                    props.setImageGeneratedArgs(props.payload);
                    props.getAiImages(imageData);
                    return imageData;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    const fetchDescriptionForLearningActivity = async (data) => {
        try {

            const url = AppConstants.getRecommendedAIGeneratedInformationAPI;
            const response = await AuthorizedDataService.postRequest(url, data, AppConstants.emptyString, AppConstants.emptyString);
            if (response) {
                const body = await response.json();
                props.getAiDescription(body.descriptionText);
                return body.descriptionText;
            } else {
                return AppConstants.emptyEditorString;
            }

        } catch (error) {
            return AppConstants.emptyEditorString;
        }
    };

    const fetchAIgeneratedContent = async () => {
        setAiGeneratorSpinner(AppConstants.trueText);
        const { userEmail } = Auth.getLoggedInUserDetails();

        const data = {
            ...props.payload,
            sessionId: userEmail,
        };

        data.skillName = props.skills.filter(skill => data.skillName === skill.skillId)[0].skillName;

        const [imageResponse, descriptionResponse] = await Promise.all([fetchAiGeneratedImages(data), fetchDescriptionForLearningActivity(data)]);

        if (imageResponse && descriptionResponse) {
            setAiGeneratorSpinner(AppConstants.falseText);
        };

        props.aiGeneratedContentError({
            imageData: imageResponse,
            descriptionData: descriptionResponse
        });
    }

    return (
        <div>
            <Button disabled={props.disabled || aiGeneratorSpinner} onClick={fetchAIgeneratedContent}>Generate content using AI {aiGeneratorSpinner ? <BiLoaderAlt className="spinner" /> : <BsMagic />}</Button>
        </div>
    )
};