import { useState,useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import formClass from "../../common/FormStyling.module.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from './ShowSchoolStudentList.module.css';
import { BiLoaderAlt } from 'react-icons/bi';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import "../expertManageCompetitionComponent/dataGrid.css"
import { ErrorMessages } from '../../constants/ErrorMessages';

export const ShowSchoolStudentList = () => {
    const [selectedClass, setSelectedClass] = useState(AppConstants.emptyString);
    const [mappedStudentList, setMappedStudentList] = useState([]);
    const [mappedStudentSpinner, setMappedStudentSpinner] = useState(AppConstants.falseText);
    const [sections, setSections] = useState([]);
    const [classValues, setClassValues] = useState(null);
    const [selectedSection, setSelectedSection] = useState(AppConstants.emptyString);
    const [columnDefs] = useState([
        { field: 'studentQrenciaId', tooltipField: 'studentQrenciaId', headerName: 'Qrencia Id' },
        { field: 'studentName', tooltipField: 'studentName', headerName: 'Name' },
        { field: 'studentEmail', tooltipField: 'studentEmail', headerName: 'Email' },
        { field: 'studentMobileNumber', tooltipField: 'studentMobileNumber', headerName: 'Mobile' },
    ]);

    //fetch all skills
    useEffect(() => {
        fetchClasses();
        async function fetchClasses() {
            const url = AppConstants.getClassesInSchoolAPI;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            )
            if (response) {
                setClassValues(response);
            }
        }
             }, []);

    const fetchStudentList = async (e) => {
        try {
            e.preventDefault();
            setMappedStudentSpinner(AppConstants.trueText);
            const url = `${AppConstants.fetchSchoolStudentListAPI}${selectedClass}&studentSection=${selectedSection}`
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setMappedStudentList(response);
                setMappedStudentSpinner(AppConstants.falseText);
            } else {
                setMappedStudentList([]);
                setMappedStudentSpinner(AppConstants.falseText);
            }

        } catch (error) {
            setMappedStudentList([]);
            setMappedStudentSpinner(AppConstants.falseText);
        }
    }

    const handleClassChange = async (e) => {
        setSelectedClass(e.target.value);
        if (e.target.value) {
            setSelectedSection(AppConstants.emptyString);
            setMappedStudentList([]);
            const url = `${AppConstants.fetchSectionsForSelectedClassAPI}${e.target.value}`;
            let response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSections(response);
            } else {
                setSections([]);
            }
        } else {
            setMappedStudentList([]);
            setSelectedSection(AppConstants.emptyString);
        }
    };

    const handleSectionChange = (event) => {
        setSelectedSection(event.target.value);
    };

    return (
        <div className={classes.container}>
            <form onSubmit={fetchStudentList}>
                <Row className="mb-1 mt-1">
                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Form.Label className={formClass.formLabel}>Class</Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name="classDropdown"
                            value={selectedClass}
                            onChange={e => handleClassChange(e)}
                            required
                        ><option value={AppConstants.emptyString} defaultValue>Select a Class</option>
                            {classValues?.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>

                    <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Form.Label className={formClass.formLabel}>Section</Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name="sectionDropdown"
                            value={selectedSection}
                            onChange={e => handleSectionChange(e)}
                            required
                        ><option value={AppConstants.emptyString} defaultValue>Select a Section</option>
                            {sections?.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={2} xl={3}>
                        <Button type="submit" disabled={mappedStudentSpinner} className={classes.selectButton}>View {mappedStudentSpinner && <BiLoaderAlt className='spinner' />}</Button>
                    </Col>
                </Row>
            </form>

            <Row className='mt-4'>
                {mappedStudentList?.length > AppConstants.zeroIndex && selectedClass !== AppConstants.emptyString && selectedSection !== AppConstants.emptyString && <h6 className='mt-2'>List of students (Class {selectedClass}{selectedSection})</h6>}
                {mappedStudentList?.length > AppConstants.zeroIndex && selectedClass !== AppConstants.emptyString && selectedSection !== AppConstants.emptyString && <div className="ag-theme-alpine">
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={mappedStudentList}
                        pagination={true}
                        paginationPageSize={5}
                        enableBrowserTooltips={true}
                        domLayout='autoHeight'
                        suppressRowHoverHighlight={true}
                        defaultColDef={{
                            filter: true,
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            resizable: true,
                            suppressMenu: true,
                            suppressHorizontalScroll: true,
                            floatingFilterComponentParams: { suppressFilterButton: true },
                            floatingFilter: true,
                        }}
                        enableFilter={true}
                        enableSorting={true}
                        enableSearching={true}
                        headerClass={classes.obrGridHeader}
                        rowClass={classes.obrGridTable}
                    />
                </div>}
                {(mappedStudentList.length === AppConstants.zeroIndex || selectedClass === AppConstants.emptyString || selectedSection === AppConstants.emptyString) && <div>
                    <p className={classes.noDataAvailableBox}>{ErrorMessages.mapStudentsSelectClassAndSectionMessage}</p>
                </div>}
            </Row>
        </div>
    )
}