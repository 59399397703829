import { Link } from "react-router-dom";
import { React, useState } from "react";
import 'moment-timezone';
import { Row, Col, Card } from "react-bootstrap";
import { FaRegCalendarCheck } from 'react-icons/fa';
import { TbRefreshAlert } from 'react-icons/tb';
import { BiCalendarX, BiDownload } from 'react-icons/bi';
import { MdShareLocation } from 'react-icons/md';
import studentCompetitionWidgetCards from './StudentCompetitionWidgetCards.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { Tooltip } from "react-tooltip";

export const StudentUpcomingCoursesWidgetCards = (props) => {
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.rightWidgetCardCounts);

    //download course guidelines
    async function downloadPreRead(course) {
        setDownloadFailed(false);
        const fileUrl = course.courseDescriptionDocumentAbsolutePath;

        try {
            FileOperationsUtil.downloadDocument(fileUrl, course.courseTitle, AppConstants.guidelinesFile);
        } catch (error) {
            setDownloadFailed(true);
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={studentCompetitionWidgetCards.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((course) => (
                    <Col className={studentCompetitionWidgetCards.competitionCardSection} key={course.id}>
                        <Card className={studentCompetitionWidgetCards.competitionCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.skillCategorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Body className={`${studentCompetitionWidgetCards.competitionCardBody} ${studentCompetitionWidgetCards.courseCardBody}`}>
                                <Card.Title className={studentCompetitionWidgetCards.competitionTitle}><Link>{course.courseTitle}</Link></Card.Title>
                                <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                    <FaRegCalendarCheck className={studentCompetitionWidgetCards.blueCalendarIcon} /> <div className={studentCompetitionWidgetCards.dateTimeText}>
                                        Start:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(course.startTime)} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                    <BiCalendarX className={`${studentCompetitionWidgetCards.blueCalendarIcon} ${studentCompetitionWidgetCards.endDateIcon}`} /> <div className={studentCompetitionWidgetCards.dateTimeText}>
                                        End:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(course.endTime)} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${course.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${course.userSlug}`) }} className={studentCompetitionWidgetCards.dateTimeSection}>By : {course.organizedBy}</div>
                                <div>
                                    {course.modeOfDelivery === AppConstants.modeOfDelivery[0] && <div onClick={stopPropagation}>
                                        {course.sessionLink !== null ? <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={course.sessionLink} target="_blank"><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Session Link</a> : <p className={studentCompetitionWidgetCards.courseOnlineModeText}><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Online</p>} {course.courseDescriptionDocumentAbsolutePath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {course.courseDescriptionDocumentAbsolutePath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(course) }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={() => { downloadPreRead(course) }} />}</a>}
                                    </div>}
                                    {course.modeOfDelivery === AppConstants.modeOfDelivery[1] && <div onClick={stopPropagation}>
                                        <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={course.geoLocation} target="_blank"><span className={studentCompetitionWidgetCards.widgitLocationSpan} data-tooltip-place="top" data-tooltip-id={`${course.uniqueId}-offline-upcoming`}><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Venue Map</span></a>{course.courseDescriptionDocumentAbsolutePath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {course.courseDescriptionDocumentAbsolutePath != null && <BiDownload className={studentCompetitionWidgetCards.locationIcon} />} {course.courseDescriptionDocumentAbsolutePath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(course) }}>Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(course) }} />}</a>}
                                    </div>}
                                    <Tooltip className={studentCompetitionWidgetCards.tooltipMessage} id={`${course.uniqueId}-offline-upcoming`}><span className={studentCompetitionWidgetCards.tooltipMessage}>{course?.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.rightWidgetCardCounts)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}