import Auth from "../utils/Auth";
import { Logout } from "../common/Logout";
import { DateTimeUtil } from "../utils/DateTimeUtil";

export const AuthorizedDataService = {

  getRequest(uri, invalidResponse, noResponse, requestHeaders) {
    return this.get(uri, invalidResponse, noResponse, 0, requestHeaders);
  },
  //Method to get authorized data using GET method
  async get(uri, invalidResponse, noResponse, getRequestCount, requestHeaders) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = `Bearer ${Auth.getAuthToken()}`;
    try {
      const header = { 'Authorization': token, preferredTimeZone: DateTimeUtil.getPreferredTimeZoneForUser() };
      const response = await fetch(url, { headers: header });
      if (!response.ok) {
        if (response.status === 401 && getRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            getRequestCount++;
            return this.get(uri, invalidResponse, noResponse, getRequestCount);
          }
          else {
            throw new Error(invalidResponse);
          }
        }
        else {
          throw new Error(invalidResponse);
        }
      }
      else {
        return await response.json();
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  getRequestWithResponseCode(uri, invalidResponse, noResponse) {
    return this.getWithResponse(uri, invalidResponse, noResponse, 0);
  },
  //Method to get authorized data using GET method
  async getWithResponse(uri, invalidResponse, noResponse, getRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = `Bearer ${Auth.getAuthToken()}`;
    try {
      const header = { 'Authorization': token, preferredTimeZone: DateTimeUtil.getPreferredTimeZoneForUser() };
      const response = await fetch(url, { headers: header });
      if (!response.ok) {
        if (response.status === 401 && getRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            getRequestCount++;
            return this.getWithResponse(uri, invalidResponse, noResponse, getRequestCount);
          }
          else {
            return await response;
          }
        }
        else {
          return await response;
        }
      }
      else {
        return await response.json();
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  postRequest(uri, data, invalidResponse, noResponse) {
    return this.post(uri, data, invalidResponse, noResponse, 0);
  },
  //Method for Post Request
  async post(uri, data, invalidResponse, noResponse, postRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        if (response.status === 401 && postRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            postRequestCount++;
            return this.post(uri, data, invalidResponse, noResponse, postRequestCount);
          }
          else {
            throw new Error(invalidResponse);
          }
        }
        else {
          throw new Error(invalidResponse);
        }
      }
      else {
        return await response;
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  postRequestWithResponseCode(uri, data, invalidResponse, noResponse) {
    return this.postWithResponse(uri, data, invalidResponse, noResponse, 0);
  },
  //Method for Post Request
  async postWithResponse(uri, data, invalidResponse, noResponse, postRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        if (response.status === 401 && postRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            postRequestCount++;
            return this.postWithResponse(uri, data, invalidResponse, noResponse, postRequestCount);
          }
          else {
            return await response;
          }
        }
        else {
          return await response;
        }
      }
      else {
        return await response;
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  //Method to send put request
  putRequest(uri, data, invalidResponse, noResponse) {
    return this.put(uri, data, invalidResponse, noResponse, 0);
  },
  async put(uri, data, invalidResponse, noResponse, putRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        if (response.status === 401 && putRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            putRequestCount++;
            return this.put(uri, data, invalidResponse, noResponse, putRequestCount);
          }
          else {
            throw new Error(invalidResponse);
          }
        }
        else {
          throw new Error(invalidResponse);
        }
      }
      else {
        return await response;
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },


  //Method to send put request
  putRequestWithResponseCode(uri, data, invalidResponse, noResponse) {
    return this.putWithResponseCode(uri, data, invalidResponse, noResponse, 0);
  },
  async putWithResponseCode(uri, data, invalidResponse, noResponse, putRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        if (response.status === 401 && putRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            putRequestCount++;
            return this.putWithResponseCode(uri, data, invalidResponse, noResponse, putRequestCount);
          }
          else {
            return await response;
          }
        }
        else {
          return await response;
        }
      }
      else {
        return await response;
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  //Method to Delete 
  deleteRequest(uri, invalidResponse, noResponse) {
    return this.delete(uri, invalidResponse, noResponse, 0);
  },
  async delete(uri, invalidResponse, noResponse, deleteRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401 && deleteRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            deleteRequestCount++;
            this.delete(uri, invalidResponse, noResponse, deleteRequestCount);
          }
          else {
            throw new Error(invalidResponse);
          }
        }
        else {
          throw new Error(invalidResponse);
        }
      }
      else {
        return await response.json();
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },

  postMultipartRequest(uri, data, invalidResponse, noResponse) {
    return this.postMultipart(uri, data, invalidResponse, noResponse, 0);
  },
  //Method for Post Request
  async postMultipart(uri, data, invalidResponse, noResponse, postRequestCount) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const token = Auth.getAuthToken();
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: data
      });
      if (!response.ok) {
        if (response.status === 401 && postRequestCount === 0) {
          const updateAccessTokenResponse = await Logout.updateAccessToken();
          if (updateAccessTokenResponse) {
            postRequestCount++;
            return this.postMultipart(uri, data, invalidResponse, noResponse, postRequestCount);
          }
          else {
            throw new Error(invalidResponse);
          }
        }
        else {
          return await response;
        }
      }
      else {
        return await response;
      }
    } catch (error) {
      console.error(noResponse, error);
    }
  },
};



