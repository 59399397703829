import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from "./AgencyMapStudentsPage.module.css"
import { UploadSchoolStudentSheet } from '../../component/schoolStudentMapping/UploadSchoolStudentSheet';
import { ShowSchoolStudentList } from '../../component/schoolStudentMapping/ShowSchoolStudentList';

export const AgencyMapStudentsPage = () => {
    const navigate = useNavigate();
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    useEffect(() => {
        const getClubQrenciaAssociationStatus = async () => {
            try {
                const url = AppConstants.getLoggedInAgencyTypeAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response && response.agencyType !== AppConstants.agencyTypes.school) {
                    navigate(errorPageUrl);
                }
            } catch (error) {
                navigate(errorPageUrl);
            }
        };
        getClubQrenciaAssociationStatus();
    }, []);

    return (
        <div className="agencyContentSection">
            <h3 className={classes.heading}>Add Students</h3>
            <UploadSchoolStudentSheet />
            <h3 className={`${classes.heading} mt-5`}>View Students</h3>
            <ShowSchoolStudentList />
        </div>
    )
}