import { MarkAttendanceRightWidget } from '../../component/widgets/MarkAttendanceRightWidget';
import classes from './ExpertRightWidgetSpace.module.css';
export const ExpertRightWidgetSpace = () => {
    return (
        <>
            <div className={classes.widgetSection}>
                <MarkAttendanceRightWidget />
            </div>
        </>
    )
}