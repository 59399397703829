import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { BiCalendarCheck, BiCalendarX, BiLoaderAlt } from "react-icons/bi";
import { TbCertificate, TbChartHistogram } from "react-icons/tb";
import { MdOutlineViewList } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import Moment from 'react-moment';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import 'moment-timezone';
import modalClass from "../CompetitionsCards.module.css";
import classes from "./StudentLearningActivityCompetitionCards.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import Auth from "../../../utils/Auth";
import { MdOutlineLeaderboard } from "react-icons/md";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { TeamEnrolmentDetailsModal } from "../../modal/teamEnrolmentDetailsModal/TeamEnrolmentDetailsModal.js";

export const StudentLearningActivityCompetitionCards = (props) => {
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.myLearningCardCount);
    const [cardDetails, setCardDetails] = useState(props.cardDetails);
    const [certificateSpinner, setCertificateSpinner] = useState(new Array(props.cardDetails?.length).fill(false));
    const [resultSpinner, setResultSpinner] = useState(new Array(props.cardDetails?.length).fill(false));
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const userDetails = Auth.getLoggedInUserDetails();
    const [zipCertificateSpinner, setZipCertificateSpinner] = useState(AppConstants.falseText);
    const [participantZipCertificateSpinner, setParticipantZipCertificateSpinner] = useState(AppConstants.falseText);
    const [showTeamCertificateModal, setShowTeamCertificateModal] = useState(AppConstants.falseText);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const [selectedLearningActivity, setSelectedLearningActivity] = useState({});
    const onCloseModal = () => {
        setShowModal(false);
        setModalMessage(AppConstants.emptyString);
    };

    const showTeamDetails = (e, learningActivity) => {
        e.stopPropagation();
        setSelectedLearningActivity({ uniqueId: learningActivity.uniqueId });
        setShowTeamDetailsModal(AppConstants.trueText);
    };

    const onCloseTeamModal = () => {
        setShowTeamCertificateModal(AppConstants.falseText);
        setModalMessage(AppConstants.emptyString);
        setSelectedTeam({});
    }

    const closeTeamDetailsModal = () => {
        setSelectedLearningActivity({});
        setShowTeamDetailsModal(AppConstants.falseText);
    }


    useEffect(() => {
        setCardDetails(props.cardDetails);
    }, [props.cardDetails])

    async function downloadCertificate(competitionData, index) {
        setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
        let url;
        let response;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        if (competitionData.teamEnrolmentEnabledFlag === AppConstants.yText) {
            url = AppConstants.downloadParticipantTeamCompetitionCertificateAPI;
            let data = {
                uniqueId: competitionData.uniqueId,
                skillName: competitionData.skillName,
                markingType: competitionData.markingType,
                entityType: "Participant",
                creatorEmail: competitionData.creatorEmail,
                participantQrenciaId: Auth.getLoggedInUserDetails().userId
            };
            response = await FileOperationsUtil.downloadFileUsingPostRequest(
                url,
                fileName,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
        }
        else {
            url = `${AppConstants.downloadCertificateAPI}${competitionData.uniqueId}&studentQrenciaId=${userDetails.userId}&skillName=${competitionData.skillName}`;
            response = await FileOperationsUtil.downloadFile(
                url,
                fileName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
        }
        if (response !== undefined) {
            if (response.ok && response.status === 200) {
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
            } else if (response.status === 409) {
                setShowModal(true);
                onCloseTeamModal();
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setModalMessage(ErrorMessages.certificateNotAvailableError);
            } else {
                setShowModal(true);
                onCloseTeamModal();
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            }
        } else {
            setShowModal(true);
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
            setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
        }
    };


    const downloadZipCertificatesForTeam = async (competitionData, certificateIssuedTo) => {
        if (certificateIssuedTo === AppConstants.downloadZipApiParams.team) {
            setZipCertificateSpinner(AppConstants.trueText);
        } else {
            setParticipantZipCertificateSpinner(AppConstants.trueText);
        }

        let data = {
            uniqueId: competitionData.uniqueId,
            skillName: competitionData.skillName,
            markingType: competitionData.markingType,
            entityType: certificateIssuedTo,
            creatorEmail: competitionData.creatorEmail

        };
        const url = AppConstants.generateCertificateZipForTeamsAPI
        const name = `${competitionData.title}${AppConstants.hyphen}${certificateIssuedTo}${AppConstants.hyphen}${AppConstants.certificatesZipFileName}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        let response = await FileOperationsUtil.downloadFileUsingPostRequest(
            url,
            fileName,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined) {
            if (certificateIssuedTo === AppConstants.downloadZipApiParams.team) {
                setZipCertificateSpinner(AppConstants.falseText);
            } else {
                setParticipantZipCertificateSpinner(AppConstants.falseText);
            }
            if (!response.ok) {
                setShowModal(true);
                if (certificateIssuedTo === AppConstants.downloadZipApiParams.team) {
                    setZipCertificateSpinner(AppConstants.falseText);
                } else {
                    setParticipantZipCertificateSpinner(AppConstants.falseText);
                }
                setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            }
        } else {
            setShowModal(true);
            if (certificateIssuedTo === AppConstants.downloadZipApiParams.team) {
                setZipCertificateSpinner(AppConstants.falseText);
            } else {
                setParticipantZipCertificateSpinner(AppConstants.falseText);
            }
            setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
        }
    }

    async function downloadResult(competitionData, index) {
        setResultSpinner(resultSpinner => resultSpinner.map((state, idx) => idx === index ? true : state));

        const url = `${AppConstants.downloadCompetitionResultAPI}${competitionData.competitionId}${AppConstants.downloadCompetitionResultSecondAPI}${competitionData.studentQrenciaId}`;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.competitionResultFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setResultSpinner(resultSpinner => resultSpinner.map((state, idx) => idx === index ? false : state));
            } else {
                setShowModal(true);
                setResultSpinner(resultSpinner => resultSpinner.map((state, idx) => idx === index ? false : state));
                setModalMessage(ErrorMessages.downloadCompetitionResultError);
            }
        } else {
            setShowModal(true);
            setResultSpinner(resultSpinner => resultSpinner.map((state, idx) => idx === index ? false : state));
            setModalMessage(ErrorMessages.downloadCompetitionResultError);
        }
    };

    const selectTeamForDownloadingCertificates = (cardData, index) => {
        setShowTeamCertificateModal(AppConstants.trueText);
        setSelectedTeam({ cardData, index });
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={2} className="cardSection">
                {cardDetails?.length && cardDetails?.slice(0, cardCount).map((cardData, index) => (
                    <Col key={index}>
                        <div className={classes.card} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitions}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.competionSlug}`)}>
                            <div className={classes.imageContainer}>
                                <img variant="top" className={classes.cardImage} src={cardData.thumbnailImagePath || defaultThumbnail} alt="Competition thubmnail" />
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.cardTitle}>
                                    <Link className="cardLink" data-tooltip-id={`${cardData.competionSlug}-title`}>{cardData.title}</Link>
                                    <Tooltip className={classes.titleTooltipMessage} id={`${cardData.competionSlug}-title`}><span></span>{cardData.title}</Tooltip>
                                </div>

                                <p className={classes.activityTypeCompetition}>{cardData.type} {cardData.clubQrenciaFlg === AppConstants.yText && "|"} {cardData.clubQrenciaFlg === AppConstants.yText && "School"}</p>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at  <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment>  {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                <div className={`${classes.iconSection}`}>
                                    {cardData.templateId !== 0 && cardData.templateId !== null && ((cardData.completionStatus == AppConstants.completeText && cardData.resultDate == null) || (cardData.resultDeclaredFlag == AppConstants.yText)) && Auth.getUserRole() === AppConstants.userRoleStudent && <div>
                                        {certificateSpinner[index] ? <BiLoaderAlt className="spinner" /> : <TbCertificate onClick={(e) => { stopPropagation(e); downloadCertificate(cardData, index) }} data-tooltip-place="top" data-tooltip-id="certificate" />}
                                    </div>}
                                    {cardData.templateId !== 0 && cardData.templateId !== null && Auth.getUserRole() === AppConstants.userRoleTeam && ((cardData.completionStatus == AppConstants.completeText && cardData.resultDate == null) || (cardData.resultDeclaredFlag == AppConstants.yText)) && < div >
                                        {< TbCertificate onClick={(e) => { stopPropagation(e); selectTeamForDownloadingCertificates(cardData, index) }} data-tooltip-place="top" data-tooltip-id="certificate" />}
                                    </div>}
                                    {cardData.disablePerformanceResultFlag === AppConstants.nText && cardData.individualParticipantRank && Auth.getUserRole() === AppConstants.userRoleStudent && <a onClick={stopPropagation} target={AppConstants.openInNewTab} className={classes.performanceReportLink} href={`${AppConstants.forwardSlash}${AppConstants.studentPerformanceText}${AppConstants.forwardSlash}${cardData.uniqueId}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userUniqueId}`}><TbChartHistogram data-tooltip-place="top" data-tooltip-id="performance" /></a>}
                                    {cardData.skillName === AppConstants.gymnasticsSkillName && cardData.awardType !== AppConstants.nullText && <a onClick={stopPropagation} target={AppConstants.openInNewTab} className={classes.performanceReportLink} href={`${AppConstants.forwardSlash}${AppConstants.scoreboardText}${AppConstants.forwardSlash}${cardData.competionSlug}`}><MdOutlineLeaderboard data-tooltip-place="top" data-tooltip-id="scoreboard" /></a>}
                                    {Auth.getUserRole() === AppConstants.userRoleTeam && <div>
                                        {<MdOutlineViewList onClick={(e) => showTeamDetails(e, cardData)} data-tooltip-place="top" data-tooltip-id="teamDetails" />}
                                    </div>}
                                    <Tooltip className={classes.tooltipMessage} id="certificate"><span className={classes.tooltipMessage}>Download Certificate</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="participantsZipCertificate"><span className={classes.tooltipMessage}>Download Certificate for participants</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="performance"><span className={classes.tooltipMessage}>View Performance Analysis</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="result"><span className={classes.tooltipMessage}>Download Result</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="scoreboard"><span className={classes.tooltipMessage}>Scoreboard</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="teamDetails"><span className={classes.tooltipMessage}>View Team Details</span></Tooltip>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row >
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.myLearningCardCount)}>Load more</button>}
                </Col>
            </Row>
            {showTeamDetailsModal && <TeamEnrolmentDetailsModal learningActivityDetails={selectedLearningActivity} closeTeamDetailsModal={closeTeamDetailsModal} />}
            {
                Auth.getUserRole() === AppConstants.userRoleStudent && <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                Auth.getUserRole() === AppConstants.userRoleTeam && <Modal show={showTeamCertificateModal} onHide={onCloseTeamModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {modalMessage && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        {!modalMessage && <div className={modalClass.modalAlertText}>
                            <p className={`${classes.downloadCertificateLink} mb-2`}>Team Certificate <span onClick={() => downloadZipCertificatesForTeam(selectedTeam.cardData, AppConstants.downloadZipApiParams.team)}>Download {zipCertificateSpinner ? <BiLoaderAlt className="spinner" /> : <IoCloudDownloadOutline />}</span> </p>
                            <p className={classes.downloadCertificateLink}>Participants' Certificates <span onClick={() => downloadZipCertificatesForTeam(selectedTeam.cardData, AppConstants.downloadZipApiParams.participant)}>Download {participantZipCertificateSpinner ? <BiLoaderAlt className="spinner" /> : <IoCloudDownloadOutline />}</span> </p>
                        </div>}
                        {modalMessage && <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={onCloseTeamModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}