import { useCallback, useEffect } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from "../../services/DataService";
import { StringUtils } from "../../utils/StringUtils";

export const OnboardingWorkshopPage = () => {

    const navigateToURL = useCallback((url) => {
        if (StringUtils.isValidURL(url)) {
            if (url.endsWith('*')) {
                window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
            } else {
                window.open(url, AppConstants.newPageTargetSelf);
            }
        } else {
            window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
        }
    }, []);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            try {
                const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);

                if (response) {
                    const key = response.find(item => item.keyName === AppConstants.onboardingWorkshopLinkKey);
                    if (key && key.keyValue) {
                        navigateToURL(key.keyValue);
                    } else {
                        navigateToURL(AppConstants.forwardSlash)
                    }
                } else {
                    navigateToURL(AppConstants.forwardSlash)
                }
            } catch (error) {
                navigateToURL(AppConstants.forwardSlash)
            }
        };

        fetchConfigurableKeys();
    }, [navigateToURL]);

    return null;
};
