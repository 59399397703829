import React, { useEffect, useState } from 'react';
import styles from "./StudentRankSection.module.css";
import { AppConstants } from '../../constants/AppConstants';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment-timezone'
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const StudentRankSection = (props) => {
    const [competitionData, setCompetitionData] = useState({});
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const rankCardboardCutoutImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.rankCardboardCutoutImage}`;
    const bgAbstractColorImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.bgAbstractColorImage}`;
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const timeZoneAbbr = DateTimeUtil.getZoneNameForPreferredTimeZone(timeZone);

    useEffect(() => {
        setCompetitionData(props.competitionDetails);
    }, [props.competitionDetails])


    const isCompetitionDateSame = (start, end) => {
        return moment(moment(start?.replace(' ', 'T') + 'Z').tz(timeZone)).format('YYYY-MM-DD').toString() === moment(end?.replace(' ', 'T') + 'Z').tz(timeZone).format('YYYY-MM-DD').toString();
    }

    return (
        <>
            <div className={styles.rankSectionContainer}>
                <div className={styles.rankContainer}>
                    <div className={styles.rankBoardContainer}>
                        <img className={styles.rankBoardImage} src={rankCardboardCutoutImage} alt="rank board" />
                    </div>
                    {/* <div className={styles.rankNumberContainer}>
                        <p className={styles.rankNumber}>{competitionData?.positionInNumber}</p>
                        <p className={styles.rankText}>{AppConstants.performanceAnalysisPageRankSectionStaticText.rankOutOfText} {competitionData?.totalParticipations}</p>
                    </div> */}
                    {/* <img className={styles.bgImageLeft} src={bgAbstractColorImage} alt="background shapes left" />
                    <img className={styles.bgImageRight} src={bgAbstractColorImage} alt="background shapes left" /> */}
                </div>
                <div className={styles.rankContentContainer}>
                    <div className={styles.rankContentContainerWidth}>
                        <h1 className={styles.rankHolderName}>Hi <strong>{competitionData?.studentName}</strong>,</h1>
                        {(isCompetitionDateSame(competitionData.competitionStartDateFormat, competitionData.competitionEndDateFormat)) && <p className={styles.rankMessage}>{AppConstants.performanceAnalysisPageRankSectionStaticText.congratulatingTextPartOne}
                            <strong>{competitionData?.competitionName}</strong>{AppConstants.performanceAnalysisPageRankSectionStaticText.heldOnText}
                            &nbsp;{<Moment format={AppConstants.fullMonthDateFormat} tz={timeZone}>{competitionData.competitionStartDateFormat?.replace(' ', 'T') + 'Z'}</Moment>}.</p>}
                        {!(isCompetitionDateSame(competitionData.competitionStartDateFormat, competitionData.competitionEndDateFormat))
                            && <p className={styles.rankMessage}>{AppConstants.performanceAnalysisPageRankSectionStaticText.congratulatingTextPartOne}
                                <strong>{competitionData?.competitionName}</strong>{AppConstants.performanceAnalysisPageRankSectionStaticText.heldFromText}
                                &nbsp;<Moment format={AppConstants.fullMonthDateFormat} tz={timeZone}>{competitionData.competitionStartDateFormat?.replace(' ', 'T') + 'Z'}</Moment> {AppConstants.performanceAnalysisPageRankSectionStaticText.toText} <Moment format={AppConstants.fullMonthDateFormat} tz={timeZone}>{competitionData.competitionEndDateFormat?.replace(' ', 'T') + 'Z'}</Moment>.</p>}
                        <h1 className={styles.letsAnalyzeText}><strong>{AppConstants.performanceAnalysisPageRankSectionStaticText.letsAnalyzText}</strong></h1>
                        <p className={styles.yourPerformanceText}>{AppConstants.performanceAnalysisPageRankSectionStaticText.yourPerformanceText}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

