import { useState } from "react";
import { StudentViewNudgesCards } from "../../component/cards/studentNudgesCards/StudentViewNudgesCards";
import { StudentRaiseNudgeForm } from "../../component/formControls/StudentRaiseNudgeForm";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { StudentNudgeDetailsSection } from "../../component/cards/studentNudgesCards/StudentNudgeDetailsSection";
import { FaArrowLeft } from "react-icons/fa";
import { StudentNudgesTabs } from "../../component/cards/studentNudgesCards/StudentNudgesTabs";
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const StudentNudgesPage = () => {
    const [activeNudgesArray, setActiveNudgesArray] = useState([]);
    const [closedNudgesArray, setClosedNudgesArray] = useState([]);
    const [viewRaiseNudgeSection, setViewRaiseNudgeSection] = useState(true);
    const [viewOpenNudgesSection, setViewOpenNudgesSection] = useState(false);
    const [viewResolvedNudgesSection, setViewResolvedNudgesSection] = useState(false);
    const [viewDetailsSection, setViewDetailsSection] = useState(false);
    const [selectedNudgeData, setSelectedNudgeData] = useState([]);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();

    //on selecting competition type Tab
    function nudgeTabSelectionHandler(option) {
        if (option === AppConstants.studentNudgesTabHeading[0]) {
            setViewRaiseNudgeSection(true);
            setViewOpenNudgesSection(false);
            setViewResolvedNudgesSection(false);
            setViewDetailsSection(false);
        } else if (option === AppConstants.studentNudgesTabHeading[1]) {
            setViewRaiseNudgeSection(false);
            setViewOpenNudgesSection(true);
            setViewResolvedNudgesSection(false);
            setViewDetailsSection(false);
            //get active nudges
            async function getActiveNudges() {
                const url = AppConstants.getActiveNudgesStudentAPIWithParam;
                const requestHeaders = {
                    preferredTimezone
                }
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
                if (response !== undefined) {
                    setActiveNudgesArray(response);
                } else {
                    setActiveNudgesArray([]);
                }
            }
            getActiveNudges();
        } else if (option === AppConstants.studentNudgesTabHeading[2]) {
            setViewRaiseNudgeSection(false);
            setViewOpenNudgesSection(false);
            setViewResolvedNudgesSection(true);
            setViewDetailsSection(false);
            //get closed nudges
            async function getClosedNudges() {
                const url = AppConstants.getClosedNudgesStudentAPIWithParam;
                const requestHeaders = {
                    preferredTimezone
                }
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
                if (response !== undefined) {
                    setClosedNudgesArray(response);
                } else {
                    setClosedNudgesArray([]);
                }
            }
            getClosedNudges();
        }
    }

    //view nudge details
    const viewNudgeDetails = (options) => {
        setViewDetailsSection(true);
        setSelectedNudgeData(options);
    }

    //back to raise nudge section
    const backToRaiseNudgeSection = () => {
        setViewDetailsSection(false);
    }
    return (
        <>
            <div className="studentContentSection studentSection">
                <div className="mt-3">
                    <StudentNudgesTabs onSelection={nudgeTabSelectionHandler} />
                    {!viewDetailsSection && <div>
                        {/* <h1 className="studentRaiseNudgeHeading">Raise Nudge <AiOutlineInfoCircle data-tooltip-place="bottom" data-tooltip-id="raiseNudgeId" className="studentRaiseNudgeIButton" /></h1>
                <Tooltip className="studentRaiseNudgeTooltip" border={true} id="raiseNudgeId">{AppConstants.StudentRaiseNudgeIbuttonMessage}</Tooltip> */}
                        {viewRaiseNudgeSection && <StudentRaiseNudgeForm category="Nudge" />}
                        {/* <h1 className="studentRaiseNudgeHeading mt-3">Active Nudges</h1> */}
                        {viewOpenNudgesSection && <StudentViewNudgesCards nudgesData={activeNudgesArray} closedNudges={false} onViewClick={viewNudgeDetails} />}
                        {/* <h1 className="studentRaiseNudgeHeading mt-3">Closed Nudges</h1> */}
                        {viewResolvedNudgesSection && <StudentViewNudgesCards nudgesData={closedNudgesArray} closedNudges={true} onViewClick={viewNudgeDetails} />}
                    </div>}
                    {viewDetailsSection && <div>
                        <div><FaArrowLeft onClick={() => backToRaiseNudgeSection()} className="studentRaiseNudgeBackButton" /></div>
                        <StudentNudgeDetailsSection nudgesData={selectedNudgeData} onBackButtonClick={backToRaiseNudgeSection} />
                    </div>}
                </div>
            </div>
        </>
    )
}