import { NavLink } from 'react-router-dom';
import { Badge } from "react-bootstrap";
import { FaChevronDown, FaRegBell } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { AppConstants } from "../../constants/AppConstants";
import Dropdown from 'react-bootstrap/Dropdown';
import './PartnerSuccessHeader.css';
import classes from './PartnerSuccessHeader.module.css';
import { Logout } from '../../common/Logout';
import Auth from '../../utils/Auth';

export const PartnerSuccessHeader = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaLogoFilePath}`;
    return (
        <>
            <header className={classes.header}>
                <div className={classes.logoSection}>
                    <NavLink to={AppConstants.forwardSlash}>
                        <img src={qrenciaLogoUrl} className={classes.logo} alt="Qrencia Logo" />
                    </NavLink>
                </div>
                <div className={classes.headerContentSection}>
                    <div className={classes.notificationSection}>
                        <FaRegBell className={classes.notificationImage} />
                        <Badge bg="danger" className={classes.notificationBadge}>0</Badge>
                    </div>
                    <div className={classes.profileSection}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div>
                            <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={classes.profileImage} alt="Profile" />
                        </div>}
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                            <div className={classes.userInitials}>{Auth.getLoggedInUserDetails().nameInitials}</div>
                        </div>}
                        <Dropdown>
                            <Dropdown.Toggle className={classes.profilePopoverButton}>
                            <p className={classes.profileName}>{Auth.getLoggedInName().length < 16 ? Auth.getLoggedInName() : Auth.getLoggedInName().substr(0, 16) + ".."}</p> <FaChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Dropdown.Item className={classes.profileDropdownItem}><RiUserSettingsLine className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Update Profile</p></Dropdown.Item> */}
                                <Dropdown.Item className={classes.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Logout</p></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </>
    );
}