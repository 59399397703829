import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import validator from 'validator';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { AppConstants } from '../../../constants/AppConstants';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import classes from "./AddJudgesComponent.module.css";
import formClass from "../../../common/FormStyling.module.css"

export const AddJudgesComponent = (props) => {
    const { addJudgeToList, isGymnasticsSkillSelected, numberOfJudges } = props;
    const { isEmail, isLength } = validator;
    const [judgeName, setJudgeName] = useState(AppConstants.emptyString);
    const [judgeEmail, setJudgeEmail] = useState(AppConstants.emptyString);
    const [nameError, setNameError] = useState(AppConstants.emptyString);
    const [emailError, setEmailError] = useState(AppConstants.emptyString);

    const addJudge = () => {
        let isValid = true;
        if (!props.rubricsConfigured) {
            return;
        }

        if (!isLength(judgeName, { min: 1 })) {
            isValid = false;
            setNameError(ErrorMessages.invalidUserName);
        }
        if (!judgeName.trim()) {
            isValid = false;
            setNameError(ErrorMessages.invalidUserName);
        }
        if (!(isLength(judgeEmail, { min: 0 }) && isEmail(judgeEmail))) {
            isValid = false;
            setEmailError(ErrorMessages.judgesInvalidEmail);
        }

        if (isValid) {
            addJudgeToList({ judgeName, judgeEmail });
            setJudgeEmail(AppConstants.emptyString);
            setJudgeName(AppConstants.emptyString);
        }
    }

    const showAddButton = () => {
        return props.rubricsConfigured ? (isGymnasticsSkillSelected && numberOfJudges >= 1) ? AppConstants.falseText : AppConstants.trueText : AppConstants.trueText;
    };

    return (
        <div>
            {showAddButton() && <Row className='mt-2'>
                {!props.rubricsConfigured && <Row>
                    <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You need to configure judging parameters to add judges</p></div>
                </Row>}
                <Col sm={12} md={12} lg={4}>
                    <Form.Label className={props.rubricsConfigured ? formClass.formLabel : formClass.formLabelDisabled}>Name</Form.Label>
                    <Form.Control disabled={!props.rubricsConfigured} className={formClass.formInput} type="text" placeholder='Enter name' value={judgeName} onChange={(e) => { setJudgeName(e.target.value); setNameError("") }} />
                    <span className={classes.errorText}>{nameError}</span>
                </Col>
                <Col sm={12} md={12} lg={4}>
                    <Form.Label className={props.rubricsConfigured ? formClass.formLabel : formClass.formLabelDisabled}>Email</Form.Label>
                    <Form.Control disabled={!props.rubricsConfigured} className={formClass.formInput} type="email" placeholder='Enter email' value={judgeEmail} onChange={(e) => { setJudgeEmail(e.target.value); setEmailError("") }} />
                    <span className={classes.errorText}>{emailError}</span>
                </Col>
                <Col sm={12} md={12} lg={4}>
                    <Button disabled={!props.rubricsConfigured} onClick={addJudge} className={classes.addButton}>Add</Button>
                </Col>
            </Row>
            }
        </div>
    );
};