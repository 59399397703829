import ModalVideo from 'react-modal-video';
import './VideoPopup.css';
import { useState } from "react";
export const VideoPopup =(props)=>{
    const [isOpen, setOpen] = useState(true);

    function closePopup(){
        setOpen(false);
        props.onHideQrenciaAtGlanceVideoPopup();
    }

    return(
        <>
        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.videoUrl} onClose={closePopup} />
        </>
    );
}