import { useState, useEffect } from 'react';
import { AppConstants } from '../../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../../services/AuthorizedDataService';
import { StudentFeaturedCourseCards } from '../../../cards/studentFeaturedCourseCards/StudentFeaturedCourseCards';
import { DateTimeUtil } from '../../../../utils/DateTimeUtil';

export const StudentFeaturedCourses = (props) => {
    const [courses, setCourses] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        async function fetchFeaturedCourses() {
            const url = `${AppConstants.getFeaturedCoursesForStudentAPI}${preferredTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                setCourses(response);
                setFilteredList(response);
            }
        }

        if (props.categoryList?.length > AppConstants.zeroIndex) {
            fetchFeaturedCourses();
        }

    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(courses);
            } else {
                setFilteredList(courses.filter(course => course.categoryName === props.selectedCategory));
            }

        } else {
            setFilteredList(courses);
        }
    }, [props.selectedCategory]);

    return (
        <div>
            {filteredList?.length > AppConstants.zeroIndex && <StudentFeaturedCourseCards minimumReviewCount={props.minimumReviewCount} courseData={filteredList} category="Featured" />}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No featured courses for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className='noDataAvailableError'>No featured courses to display at the moment</p>}
        </div>
    )
}