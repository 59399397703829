import { useEffect, useState } from "react";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { AppConstants } from "../../../constants/AppConstants";
import { Button, Modal, Table } from "react-bootstrap";
import modalClass from "../../cards/CompetitionsCards.module.css";
import classes from "./TeamEnrolmentDetails.module.css";
import { BiLoaderAlt } from "react-icons/bi";
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";

export const TeamEnrolmentDetailsModal = (props) => {
    const { closeTeamDetailsModal, learningActivityDetails } = props;
    const [showSpinner, setShowSpinner] = useState(AppConstants.trueText);
    const [teamDetails, setTeamDetails] = useState([]);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchTeamDetails = async () => {
            const url = `${AppConstants.getTeamCompetitionParticipantsAPI}${learningActivityDetails.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyEditorString);

            if (response) {
                setShowSpinner(AppConstants.falseText);
                const groupedTeams = response.reduce((acc, participant) => {
                    const { teamName } = participant;
                    if (!acc[teamName]) {
                        acc[teamName] = [];
                    }
                    acc[teamName].push(participant);
                    return acc;
                }, {});

                const teamArray = Object.keys(groupedTeams).map(teamName => ({
                    teamName,
                    participants: groupedTeams[teamName]
                }));

                setTeamDetails(teamArray);
            } else {
                setShowSpinner(AppConstants.falseText);
            }
        }

        if (learningActivityDetails.uniqueId) {
            fetchTeamDetails();
        }
    }, [learningActivityDetails.uniqueId]);

    return (
        <Modal size="xl" show={true} onHide={closeTeamDetailsModal} backdrop="static" keyboard={false} centered>
            {!showSpinner && <Modal.Header closeButton className={classes.modalHeading}> Registered Teams</Modal.Header>}
            {!showSpinner && <>
                <Modal.Body>
                    {<div className={classes.tableContainer}>
                        {teamDetails.length > 0 &&
                            teamDetails.map(team => (<div key={team.teamName}>
                                <h3 className={classes.teamNameHeadings}>{team.teamName}</h3>
                                <Table striped bordered>
                                    <thead className={classes.tableHead}>
                                        <th className={classes.tableData}>Participant Name</th>
                                        <th className={classes.tableData}>Gender</th>
                                        <th className={classes.tableData}>Qrencia ID</th>
                                        <th className={classes.tableData}>Enrolled On</th>
                                    </thead>
                                    <tbody className={classes.tableBody}>
                                        {team.participants?.map((participant, index) => <tr key={index}>
                                            <td className={classes.tableData}>{participant.studentName}</td>
                                            <td className={classes.tableData}>{participant.gender || AppConstants.notAvailableText}</td>
                                            <td className={classes.tableData}>{participant.userQrenciaId || AppConstants.notAvailableText}</td>
                                            <td className={classes.tableData}><Moment format="hh:mm A" tz={timeZone}>{participant.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment></td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </div>))
                        }
                        {!teamDetails.length && <div className={classes.teamDetailsNotAvailable}>
                            <p className="noDataAvailableError">You have not registered any teams.</p>
                        </div>}
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={closeTeamDetailsModal}>Close</Button>
                </Modal.Footer>
            </>}
            {showSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}

        </Modal>
    )
}