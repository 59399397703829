import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from "../../services/DataService";
import { ReviewCardsComponent } from "../cards/reviewCardsComponent/ReviewCardsComponent";
import classes from "./IndividualSkillReview.module.css";
import { CourseReviewCards } from "../cards/courseReviewCards/CourseReviewCards";

export const IndividualSkillsReview = (props) => {
    const { categorySlug, skillSlug } = useParams();
    const [expertReviews, setExpertReviews] = useState([]);
    const [courseReviews, setCourseReviews] = useState([]);
    const [workshopReviews, setWorkshopReviews] = useState([]);
    const [expertReviewCardCount, setExpertReviewCardCount] = useState(AppConstants.cardCounts.individualSkillsReviewCount);
    const [courseReviewCardCount, setCourseReviewCardCount] = useState(AppConstants.cardCounts.individualSkillsReviewCount);
    const [workshopReviewCardCount, setWorkshopReviewCardCount] = useState(AppConstants.cardCounts.individualSkillsReviewCount);
    const [skillData, setSkillData] = useState(...props.data.filter((item) => item.categorySlug.toLowerCase() === categorySlug.toLowerCase() && item.skillSlug.toLowerCase() === skillSlug.toLowerCase()));

    const fetchLearningActivityReviews = useCallback(async (learningActivityType) => {
        const url = `${AppConstants.getLearningActivityReviewsForSkillAPI}${skillData?.skillId}&learningActivityType=${learningActivityType}`;
        const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response) {
            return response;
        } else {
            return [];
        }
    }, [skillData?.skillId]);


    useEffect(() => {
        const fetchExpertReviews = async () => {
            const url = `${AppConstants.getReviewsForCreatorAPI}${skillData.skillId}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setExpertReviews(response)
            } else {
                setExpertReviews([]);
            }
        }

        const fetchReviews = async () => {
            const courseReviews = await fetchLearningActivityReviews(AppConstants.learningActivitiesTypes.course);
            const workshopReviews = await fetchLearningActivityReviews(AppConstants.learningActivitiesTypes.workshop);
            setCourseReviews(courseReviews);
            setWorkshopReviews(workshopReviews);
        }

        if (skillData?.skillId) {
            fetchExpertReviews();
            fetchReviews();
        }
    }, [skillData, fetchLearningActivityReviews]);


    return (
        ((expertReviews && expertReviews?.length > 0) || (courseReviews && courseReviews.length > 0) || (workshopReviews && workshopReviews.length > 0)) ?
            <div className={classes.reviewSectionContainer}>
                {expertReviews?.length > 0 && <div>
                    <div className={classes.reviews}>
                        <h3 className={classes.reviewHeading}>{AppConstants.whatLearnersSayAboutText} {skillData.skill} experts</h3>
                        {expertReviews?.slice(0, expertReviewCardCount).map((review, index) => {
                            return <div>
                                <ReviewCardsComponent review={review} individualSkillsFlag={AppConstants.trueText} />
                                {(index + 1 < expertReviewCardCount) && (index + 1 < expertReviews.length) && <hr className={classes.horizontalBreak} />}
                            </div>

                        })}
                        {expertReviews && expertReviews?.length > 0 && <div className={classes.loadMoreButton} >
                            {expertReviewCardCount < expertReviews?.length && <button className="loadMoreButton" onClick={() => setExpertReviewCardCount(expertReviewCardCount + AppConstants.cardCounts.individualSkillsReviewCount)}>{AppConstants.moreReviewsText}</button>}
                        </div>}
                    </div>
                </div>}
                {courseReviews?.length > 0 && <div>
                    <div className={classes.reviews}>
                        <h3 className={classes.reviewHeading}>{AppConstants.whatLearnersSayAboutText} {skillData.skill} courses</h3>
                        {courseReviews?.slice(0, courseReviewCardCount).map((review, index) => {
                            return <div>
                                <CourseReviewCards review={review} individualSkillsFlag={AppConstants.trueText} />
                                {(index + 1 < courseReviewCardCount) && (index + 1 < courseReviews.length) && <hr className={classes.horizontalBreak} />}
                            </div>
                        }
                        )}
                        {courseReviews?.length > 0 && <div className={classes.loadMoreButton} >
                            {courseReviewCardCount < courseReviews?.length && <button className="loadMoreButton" onClick={() => setCourseReviewCardCount(courseReviewCardCount + AppConstants.cardCounts.individualSkillsReviewCount)}>{AppConstants.moreReviewsText}</button>
                            }
                        </div>}
                    </div>
                </div>}
                {workshopReviews && workshopReviews?.length > 0 && <div>
                    <div className={classes.reviews}>
                        <h3 className={classes.reviewHeading}>{AppConstants.whatLearnersSayAboutText} {skillData.skill} workshops</h3>
                        {workshopReviews?.slice(0, workshopReviewCardCount).map((review, index) => {
                            return <div>
                                <CourseReviewCards review={review} individualSkillsFlag={AppConstants.trueText} />
                                {(index + 1 < workshopReviewCardCount) && (index + 1 < workshopReviewCardCount.length) && <hr className={classes.horizontalBreak} />}
                            </div>
                        }
                        )}
                        {workshopReviews?.length > 0 && <div className={classes.loadMoreButton} >
                            {workshopReviewCardCount < workshopReviews?.length && <button className="loadMoreButton" onClick={() => setWorkshopReviewCardCount(workshopReviewCardCount + AppConstants.cardCounts.individualSkillsReviewCount)}>{AppConstants.moreReviewsText}</button>
                            }
                        </div>}
                    </div>
                </div>}
            </div>
            : AppConstants.nullText
    )
};