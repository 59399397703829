import { useCallback, useEffect, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { Card, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { MathUtil } from "../../../utils/MathUtil";
import { FaFilter } from "react-icons/fa6";
import { StringUtils } from "../../../utils/StringUtils";

export const InsightStats = () => {
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [qrenciaStats, setQrenciaStats] = useState({});

    const fetchQrenciaStats = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            const params = {
                selectedDateStartTime: encodeURIComponent(fromDateString) || AppConstants.emptyString,
                selectedDateEndTime: encodeURIComponent(toDateString) || AppConstants.emptyString,
                locale: DateTimeUtil.getPreferredTimeZoneForUser()
            }
            url = StringUtils.createURLWithParams(AppConstants.getQrenciaInsightsAPI, params);
        } else {
            url = AppConstants.getQrenciaInsightsAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setQrenciaStats(response);
        } else {
            setQrenciaStats({});
        }
    }, []);


    useEffect(() => {
        fetchQrenciaStats();
    }, [fetchQrenciaStats]);


    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        fetchQrenciaStats(fromDateString, toDateString);
    };

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    };

    return (
        <div>
            <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
            {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
            <Row>
                <div className={`${dashboardClass.dashboardCards} mb-2`}>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.feeCollectedCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Total Learners</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.registeredStudents)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.expertCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Total Onboarded Creators</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalCreators)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.workshopCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Listed Experts</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.numberOfListedExperts)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.ageGroupCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Creators with BOQ</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.revenueGeneratingCreators)} <span className={dashboardClass.boqPercentageText}>({MathUtil.formatNumberToLocaleString(qrenciaStats.percentageRevenueGeneratingCreators)} %)</span> </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.courseCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Creators with Physical Branding</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalCreatorsWithPhysicalBranding)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.submissionCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>B2B Partnerships</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.numberOfB2BPartnerships)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.revenueColor}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Total Revenue (B2B Partnerships)</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>INR {MathUtil.formatNumberToLocaleString(qrenciaStats.revenueGeneratedFromB2BPartnerships)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.absentCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Campaign Expense (This Month)</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>INR {MathUtil.formatNumberToLocaleString(qrenciaStats.amountSpentOnCampaignThisMonth)} </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.insightCards} ${dashboardClass.absentCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Campaign Expense (Last Month)</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>INR {MathUtil.formatNumberToLocaleString(qrenciaStats.amountSpentOnCampaignLastMonth)} </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    )
}