import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { LoginPageTopNavigation } from '../../layout/LoginPageLayout/LoginPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { TermsAndConditionsPageBannerSection } from '../../component/extendedPageComponents/termsAndConditions/TermsAndConditionsPageBannerSection'

export const TermsAndConditionsPage = () => {

   const [landingPageData, termsAndConditionsData] = useLoaderData();

   useEffect(() => {
      async function scrollToTop() {
         window.scrollTo({
            top: 0,
            behavior: 'instant'
         });
      };
      scrollToTop()
   }, [landingPageData]);

   return (
      <>
         <LoginPageTopNavigation />
         <TermsAndConditionsPageBannerSection termsAndConditionsData={termsAndConditionsData} />
         <LandingPageFooter landingPageData={landingPageData} />
      </>

   )
}