import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ViewSchoolLearningActivitiyDetails } from "../../component/schoolMonitoringDashboard/ViewSchoolLearningActivityDetails";
import { ViewSchoolLearningActivitiyStatistics } from "../../component/schoolMonitoringDashboard/ViewSchoolLearningActivityStatistics";
import classes from "./MonitorLearningActivitiesPage.module.css";

export const MonitorLearningActivitiesPage = () => {
    const navigate = useNavigate();
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;

    useEffect(() => {
        const getClubQrenciaAssociationStatus = async () => {
            try {
                const url = AppConstants.getLoggedInAgencyTypeAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response && response.agencyType !== AppConstants.agencyTypes.school) {
                    navigate(errorPageUrl);
                }
            } catch (error) {
                navigate(errorPageUrl);
            }
        };
        getClubQrenciaAssociationStatus();
    }, []);

    return (
        <div className="agencyContentSection">
            <h3 className={classes.heading}>Dashboard</h3>
            <ViewSchoolLearningActivitiyStatistics />
            <h3 className={classes.heading}>Learning Activities</h3>
            <ViewSchoolLearningActivitiyDetails />
        </div>
    )
}