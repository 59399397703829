import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { Button, Table } from "react-bootstrap";
import markParticipantsStyles from "./ExpertMarkParticipantsScreen.module.css";
import classes from "./GymnasticsMarkParticipantScreen.module.css";
import { BiLoaderAlt } from "react-icons/bi";
import { DataService } from "../../services/DataService";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { FormValidationUtils } from "../../utils/FormValidationUtils";

export const GymnasticsMarkParticipantScreen = ({ judgeUniqueId, studentUniqueId, competitionDetails, participantsDetails, competitionAspectDetails = [] }) => {
    const initialSubmitValues = {
        varient: AppConstants.emptyString,
        message: AppConstants.emptyString,
        submitSpinner: AppConstants.falseText,
        saveSpinner: AppConstants.falseText
    };
    const [judgingParameters, setJudgingParameters] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
    const [submitInfo, setSubmitInfo] = useState(initialSubmitValues);
    const [showTotalMarksOnSubmit, setShowTotalMarksOnSubmit] = useState(AppConstants.falseText);
    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const transformAspects = () => {
            let parameters = [];
            const getAspectValue = (aspect) => {
                return {
                    aspectDescription: aspect.aspectDescription,
                    aspectId: aspect.uniqueAspectId,
                    marksAllotted: aspect.marksAllotted ?? AppConstants.emptyString
                }
            }

            parameters = competitionAspectDetails.reduce((acc, aspect) => {
                const existingCriterion = acc.find(criterion => criterion.criteriaId === aspect.uniqueCriteriaId);

                if (existingCriterion) {
                    existingCriterion.aspects.push(getAspectValue(aspect));
                } else {
                    acc.push({
                        criteriaId: aspect.uniqueCriteriaId,
                        criteriaName: aspect.criteriaName,
                        averageDeduction: aspect.averageDeduction ?? AppConstants.emptyString,
                        gender: aspect.gender,
                        levelWiseOutOfValue: aspect.levelWiseOutOfValue || AppConstants.emptyString,
                        executionScore: aspect.executionScore ?? aspect.executionMaxScore,
                        finalScore: aspect.finalScore ?? AppConstants.emptyString,
                        executionMaxScore: aspect.executionMaxScore || AppConstants.emptyString,
                        aspects: [getAspectValue(aspect)],
                    });
                }

                return acc;
            }, []);
            const totalScore = calculateTotalScore(parameters);
            const maximumScore = competitionDetails.showPercentageFlag === AppConstants.yText ? parameters.length * (parseFloat(parameters[0].levelWiseOutOfValue)) : 0;
            setTotalScore(totalScore);
            setMaxScore(maximumScore);
            setJudgingParameters(parameters);
        }


        if (competitionAspectDetails.length > 0) {
            transformAspects();
        }
    }, [competitionAspectDetails]);

    const calculateAverageDeduction = (aspects) => {
        const filteredAspects = aspects.filter(aspect => !AppConstants.gymnasticsStaticAspects.includes(aspect.aspectDescription))
        const totalDeduction = filteredAspects.reduce((sum, aspect) => sum + (parseFloat(aspect.marksAllotted) || 0), 0);

        return filteredAspects.length > 0 ? parseFloat((totalDeduction / filteredAspects.length).toFixed(2)) : 0;
    };

    const getDScoreValue = (aspects) => {
        let marksalloted = aspects.filter(aspect => aspect.aspectDescription === AppConstants.gymnasticsStatisAspectMapping.dScore)[0]?.marksAllotted;
        return marksalloted ? parseFloat(marksalloted) : 0;
    };

    const getPenaltyValue = (aspects) => {
        let marksalloted = aspects.filter(aspect => aspect.aspectDescription === AppConstants.gymnasticsStatisAspectMapping.penalty)[0]?.marksAllotted;
        return marksalloted ? parseFloat(marksalloted) : 0;
    }

    const calculateExecutionScore = (dScore, executionMaxScore) => {
        // Calculating execution score as D + maximum E Score
        let eScore = parseFloat(dScore) + parseFloat(executionMaxScore);
        return (typeof eScore === 'number') ? eScore.toFixed(2) : eScore;
    };

    const calculateFinalScore = (executionScore, averageDeduction, penalty) => {
        return (parseFloat(executionScore) - parseFloat(averageDeduction) - parseFloat(penalty)).toFixed(2);
    };

    const calculateTotalScore = (newJudgementParameters) => {
        return newJudgementParameters.reduce((sum, criteria) => sum + (parseFloat(criteria.finalScore) || 0), 0)?.toFixed(2);
    };

    const getPercentageValue = () => {
        if (maxScore === 0) return AppConstants.notAvailableText;

        return `${(100 * calculateTotalScore(judgingParameters) / maxScore).toFixed(2)} %`;
    };

    const handleInputMarks = (event, aspect, criteriaIndex, aspectIndex) => {
        const { value } = event.target;
        if (aspect.aspectDescription === AppConstants.gymnasticsStatisAspectMapping.dScore && judgingParameters[criteriaIndex].levelWiseOutOfValue && parseFloat(value) > parseFloat(judgingParameters[criteriaIndex].levelWiseOutOfValue)) {
            event.preventDefault();
            return;
        }

        let newAspect = { ...aspect };
        let newJudgingParameters = [...judgingParameters];

        newAspect.marksAllotted = value;
        newJudgingParameters[criteriaIndex].aspects[aspectIndex] = newAspect;

        const averageDeduction = calculateAverageDeduction(newJudgingParameters[criteriaIndex].aspects);
        const executionScore = calculateExecutionScore(getDScoreValue(newJudgingParameters[criteriaIndex].aspects), newJudgingParameters[criteriaIndex].executionMaxScore);
        const finalScore = isAnyAspectMarked(newJudgingParameters[criteriaIndex]) ? calculateFinalScore(executionScore, averageDeduction, getPenaltyValue(newJudgingParameters[criteriaIndex].aspects)) : AppConstants.emptyString;

        newJudgingParameters[criteriaIndex] = {
            ...newJudgingParameters[criteriaIndex],
            executionScore,
            finalScore,
            averageDeduction
        };

        const totalScore = calculateTotalScore(newJudgingParameters);
        setTotalScore(totalScore);
        setJudgingParameters(newJudgingParameters);
    };

    const handleErrors = (errorValues) => {
        setSubmitInfo(errorValues);
        let timer = setTimeout(() => {
            setSubmitInfo(initialSubmitValues);
        }, AppConstants.messageDisappearTime);
        return () => { clearTimeout(timer) };
    }

    const checkIfNoAspectHasBeenMarked = (criteria) => {
        return criteria.aspects.every(aspect => (aspect.marksAllotted === null || aspect.marksAllotted === AppConstants.emptyString));
    };

    const checkAllAspectsHaveBeenMarked = (criteria) => {
        return criteria.aspects.every(aspect => (aspect.marksAllotted !== null && aspect.marksAllotted !== AppConstants.emptyString));
    };

    const isAnyAspectMarked = (criteria) => {
        return criteria.aspects.some(aspect => aspect.marksAllotted !== null && aspect.marksAllotted !== AppConstants.emptyString);
    };


    const getAvgDeductionForIncrementalMarking = (criteria) => {
        const filteredAspects = criteria.aspects?.filter(aspect => !AppConstants.gymnasticsStaticAspects.includes(aspect.aspectDescription) && aspect.marksAllotted !== AppConstants.nullText && aspect.marksAllotted !== AppConstants.emptyString)

        return filteredAspects.length > 0 ? (criteria.averageDeduction === AppConstants.nullText || criteria.averageDeduction === AppConstants.emptyString) ? AppConstants.nullText : criteria.averageDeduction : AppConstants.nullText;
    };

    const getTotatForIncrementalMarking = () => {
        let totalMarks = 0;
        let markedFlag = false;

        judgingParameters.forEach(criteria => {
            if (checkAllAspectsHaveBeenMarked(criteria)) {
                markedFlag = true;
                totalMarks += parseFloat(criteria.finalScore);
            }
        });

        return markedFlag ? (totalMarks).toFixed(2) : AppConstants.nullText;
    }

    const saveParticipantMarks = async () => {
        setSubmitInfo({ ...submitInfo, saveSpinner: AppConstants.trueText });
        let displayPercentage = AppConstants.trueText;

        const data = {
            competitionId: competitionDetails.competitionId,
            judgeUniqueId,
            studentUniqueId: competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText ? AppConstants.nullText : studentUniqueId,
            competitionTeamEnrolmentFlag: competitionDetails.teamEnrolmentEnabledFlag,
            competitionMarkingType: competitionDetails.markingType,
            teamParticipantUniqueId: competitionDetails.markingType === AppConstants.markingTypes.individual.value ? studentUniqueId : AppConstants.nullText,
            teamUniqueId: competitionDetails.markingType === AppConstants.markingTypes.team.value ? studentUniqueId : AppConstants.nullText,
            totalScore: getTotatForIncrementalMarking(),
            criteria: judgingParameters.map(criteria => {
                const isCriteiaNull = checkIfNoAspectHasBeenMarked(criteria);
                const allAspectsMarked = checkAllAspectsHaveBeenMarked(criteria);
                displayPercentage = (displayPercentage && allAspectsMarked) ? AppConstants.trueText : AppConstants.falseText;
                return {
                    criteriaId: criteria.criteriaId,
                    averageDeduction: !isCriteiaNull ? getAvgDeductionForIncrementalMarking(criteria) : AppConstants.nullText,
                    escore: isCriteiaNull ? AppConstants.nullText : criteria.executionScore,
                    finalScore: !allAspectsMarked ? AppConstants.nullText : criteria.finalScore,
                    aspects: criteria.aspects.filter(aspect => (aspect.marksAllotted !== null && aspect.marksAllotted !== AppConstants.emptyString))?.map(aspect => ({
                        aspectId: aspect.aspectId,
                        marksAllotted: aspect.marksAllotted === AppConstants.emptyString ? null : aspect.marksAllotted
                    }))
                }
            })
        }

        const url = AppConstants.postStudentMarksForGymnasticsCompetitionAPI;
        const response = await DataService.postWithResponseCode(url, data, AppConstants.emptyString, AppConstants.emptyString);
        if (response && response.status === AppConstants.httpResponseCodes.responseCode201) {
            handleErrors({ ...submitInfo, saveSpinner: AppConstants.falseText, message: SuccessMessage.savedMarksSuccess, varient: AppConstants.alertVarient[0] })
            setShowTotalMarksOnSubmit(displayPercentage);
        } else {
            handleErrors({ ...submitInfo, saveSpinner: AppConstants.falseText, message: ErrorMessages.saveMarksError, varient: AppConstants.alertVarient[1] });
        }
    };

    const submitParticipantsMarks = async (e) => {
        if (e.target.checkValidity()) {
            e.preventDefault();
            setSubmitInfo({ ...submitInfo, submitSpinner: AppConstants.trueText });
            const data = {
                competitionId: competitionDetails.competitionId,
                judgeUniqueId,
                studentUniqueId: competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText ? AppConstants.nullText : studentUniqueId,
                competitionTeamEnrolmentFlag: competitionDetails.teamEnrolmentEnabledFlag,
                competitionMarkingType: competitionDetails.markingType,
                teamParticipantUniqueId: competitionDetails.markingType === AppConstants.markingTypes.individual.value ? studentUniqueId : AppConstants.nullText,
                teamUniqueId: competitionDetails.markingType === AppConstants.markingTypes.team.value ? studentUniqueId : AppConstants.nullText,
                totalScore,
                criteria: judgingParameters.map(criteria => ({
                    criteriaId: criteria.criteriaId,
                    averageDeduction: criteria.averageDeduction,
                    escore: criteria.executionScore,
                    finalScore: criteria.finalScore,
                    aspects: criteria.aspects.map(aspect => ({
                        aspectId: aspect.aspectId,
                        marksAllotted: aspect.marksAllotted
                    }))
                }))
            }

            const url = AppConstants.postStudentMarksForGymnasticsCompetitionAPI;
            const response = await DataService.postWithResponseCode(url, data, AppConstants.emptyString, AppConstants.emptyString);
            if (response && response.status === AppConstants.httpResponseCodes.responseCode201) {
                handleErrors({ ...submitInfo, submitSpinner: AppConstants.falseText, message: SuccessMessage.submitMarksSuccess, varient: AppConstants.alertVarient[0] });
                setShowTotalMarksOnSubmit(AppConstants.trueText);
            } else {
                handleErrors({ ...submitInfo, submitSpinner: AppConstants.falseText, message: ErrorMessages.submitMarksError, varient: AppConstants.alertVarient[1] });
            }
        }
    };

    const validateKeyDown = (event) => {
        const regex = /^\d*\.?\d*$/;

        if ((!regex.test(event.key) && !AppConstants.keyboardUtilKeys.includes(event.key))) {
            event.preventDefault();
            return;
        }

        FormValidationUtils.preventNumberWithSpecialCharacters(event, ["-", "+"]);
    };

    return (
        <form onSubmit={(e) => { submitParticipantsMarks(e) }} >
            {judgingParameters.map((criteria, criteriaIndex) => (
                <div key={criteria.criteriaId} className={classes.tableSection}>
                    <h2 className={classes.criteriaHeading}>{criteriaIndex + 1}. {criteria.criteriaName}</h2>
                    <Table bordered>
                        <thead>
                            <tr className={markParticipantsStyles.tableHeadings}>
                                {
                                    criteria.aspects.filter(aspect => aspect.aspectDescription !== AppConstants.gymnasticsStatisAspectMapping.penalty).map(aspect => (
                                        <th className={classes.tableData} key={aspect.aspectId}>{aspect.aspectDescription}</th>
                                    ))
                                }
                                <th className={`${classes.tableData} ${classes.disabledHeader}`}>Avg Execution</th>
                                <th className={classes.tableData}>{AppConstants.gymnasticsStatisAspectMapping.penalty}</th>
                                <th className={`${classes.tableData} ${classes.disabledHeader}`}>D + E</th>
                                <th className={`${classes.tableData} ${classes.disabledHeader}`}>Final Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {
                                    criteria.aspects.map((aspect, index) => (
                                        aspect.aspectDescription !== AppConstants.gymnasticsStatisAspectMapping.penalty ? (
                                            <td className={classes.tableData} key={aspect.aspectId}>
                                                <input className={`${markParticipantsStyles.inputMarksBox} ${classes.inputField}`} value={aspect.marksAllotted}
                                                    onChange={(e) => { handleInputMarks(e, aspect, criteriaIndex, index) }} step={0.1} type="number" inputMode="numeric" min={0} max={aspect.aspectMarks} onKeyDown={validateKeyDown} required />
                                            </td>
                                        ) : null
                                    ))
                                }

                                <td className={classes.tableData}>
                                    <input className={`${markParticipantsStyles.inputMarksBox} ${classes.inputField}`} value={criteria.averageDeduction} readOnly disabled type="number" min={0} />
                                </td>
                                {
                                    criteria.aspects.map((aspect, index) => (
                                        aspect.aspectDescription === AppConstants.gymnasticsStatisAspectMapping.penalty && (
                                            <td className={classes.tableData} key={aspect.aspectId}>
                                                <input className={`${markParticipantsStyles.inputMarksBox} ${classes.inputField}`} value={aspect.marksAllotted} step={0.1} type="number" min={0}
                                                    onChange={(e) => { handleInputMarks(e, aspect, criteriaIndex, index) }} onKeyDown={validateKeyDown} inputMode="numeric" required />
                                            </td>
                                        )
                                    ))
                                }

                                <td className={classes.tableData}>
                                    <input className={`${markParticipantsStyles.inputMarksBox} ${classes.inputField}`} value={criteria.executionScore} readOnly disabled type="number" min={0} />
                                </td>
                                <td className={classes.tableData}>
                                    <input className={`${markParticipantsStyles.inputMarksBox} ${classes.inputField}`} value={criteria.finalScore} readOnly disabled type="number" min={0} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            ))}

            {<div className={classes.tableSection}>
                <h2 className={`${classes.criteriaHeading} ${classes.totalScoreHeading}`}>Total Score</h2>
                <Table bordered>
                    <thead>
                        <tr className={markParticipantsStyles.tableHeadings}>
                            {
                                judgingParameters.map(criteria => (
                                    <th key={criteria.criteriaId}>{criteria.criteriaName}</th>
                                ))
                            }
                            <th>Total Score</th>
                            {competitionDetails.showPercentageFlag === AppConstants.yText && <th>Percentage</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={classes.tableRows}>
                            {
                                judgingParameters.map(criteria => (
                                    <td key={criteria.criteriaId}>{checkAllAspectsHaveBeenMarked(criteria) ? (criteria.finalScore || 0) : "-"}</td>
                                ))
                            }
                            <td>{(participantsDetails[0]?.competitionMarkedFlag === AppConstants.doneText || showTotalMarksOnSubmit) ? totalScore : "-"}</td>
                            {competitionDetails.showPercentageFlag === AppConstants.yText && <td>{(participantsDetails[0]?.competitionMarkedFlag === AppConstants.doneText || showTotalMarksOnSubmit) ? getPercentageValue() : "-"}</td>}
                        </tr>
                    </tbody>
                </Table>
            </div>}
            <div className={`${markParticipantsStyles.submitButtonContainer} mt-4`}>
                <Button onClick={saveParticipantMarks} disabled={competitionAspectDetails?.length === 0} className={`${markParticipantsStyles.submitButton} ${classes.saveButton}`}>Save {submitInfo.saveSpinner && <BiLoaderAlt className={markParticipantsStyles.spinner} />}</Button>
                <Button disabled={competitionAspectDetails?.length === 0} type="submit" className={markParticipantsStyles.submitButton}>Submit{submitInfo.submitSpinner && <BiLoaderAlt className={markParticipantsStyles.spinner} />}</Button>            </div>
            {submitInfo.message && <ErrorSuccessAlertMessage message={submitInfo.message} varient={submitInfo.varient} />}
        </form>
    )
}

