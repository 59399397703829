import React, { useState, useEffect } from "react";
import { StudentLearningActivityTabs } from "./StudentLearningActivityTabs";
import { StudentLearningActivityCompetitionCards } from "../cards/studentLearningActivityCompetitionCards/StudentLearningActivityCompetitionCards";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { StudentTemplatesConstants } from "../../constants/StudentTemplatesConstants";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./StudentLearningActivity.module.css";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { StudentLearningActivityCoursesCards } from "../cards/studentLearningActivityCompetitionCards/StudentLearningActivityCoursesCards";
import { StudentLearningActivityNudgesCard } from "../cards/studentLearningActivityCompetitionCards/StudentLearningActivityNudgesCard";
import { StudentLearningActivityWorkshopsCards } from "../cards/studentLearningActivityCompetitionCards/StudentLearningActivityWorkshopsCard";
import { DataService } from "../../services/DataService";
import Auth from "../../utils/Auth";

export const StudentLearningActivitiy = () => {
    const learningActivityTabs = StudentTemplatesConstants.learningActivityTabs;
    const [currentTab, setCurrentTab] = useState(learningActivityTabs.competition.key);
    const [cardDetails, setCardDetails] = useState({ competitionDetails: [], courseDetails: [], nudgesDetails: [] });
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.zeroIndex);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchAllStudentLearningActivity = async () => {
            const url = `${AppConstants.studentMylearningActivitiesAPI}${preferredTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setCardDetails(response);
            } else {
                setCardDetails({ competitionDetails: [], courseDetails: [], nudgesDetails: [] });
            }
        }

        fetchAllStudentLearningActivity();
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, []);

    const onTabChange = (value) => {
        setCurrentTab(value)
    };

    return (
        <>
            <h3 className={classes.learningActivityHeading}>Past Learning Activities</h3>
            <div className={classes.activityNav}>
                <StudentLearningActivityTabs defaultKey={learningActivityTabs.competition.key} onTabChange={onTabChange} />
            </div>
            <div className={classes.activityContainer}>
                <div className={classes.cardsContainer}>
                    {currentTab === learningActivityTabs.competition.key && ((cardDetails?.competitionDetails && cardDetails?.competitionDetails?.length !== 0) ? <StudentLearningActivityCompetitionCards cardDetails={cardDetails?.competitionDetails} /> : <div className={classes.noResultsText}>{ErrorMessages.noCompetitionsFoundText}</div>)}
                    {currentTab === learningActivityTabs.Courses.key && Auth.getUserRole() === AppConstants.userRoleStudent && ((cardDetails?.courseDetails?.length !== 0) ? <StudentLearningActivityCoursesCards minimumReviewCount={minimumReviewCount} cardDetails={cardDetails?.courseDetails} /> : <div className={classes.noResultsText}>{ErrorMessages.noCoursesFoundText}</div>)}
                    {currentTab === learningActivityTabs.nudge.key && Auth.getUserRole() === AppConstants.userRoleStudent && ((cardDetails?.nudgesDetails?.length !== 0) ? <StudentLearningActivityNudgesCard cardDetails={cardDetails?.nudgesDetails} /> : <div className={classes.noResultsText}>{ErrorMessages.noNudgesFoundText}</div>)}
                    {currentTab === learningActivityTabs.workshop.key && Auth.getUserRole() === AppConstants.userRoleStudent && ((cardDetails?.workshopDetails?.length !== 0) ? <StudentLearningActivityWorkshopsCards cardDetails={cardDetails?.workshopDetails} /> : <div className={classes.noResultsText}>{ErrorMessages.noWorkshopsFoundText}</div>)}
                </div>
            </div>
        </>
    )
}