import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import Moment from "react-moment";
import { DateTime } from "luxon";
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { BiCalendarCheck, BiCalendarX, BiWallet, BiLoaderAlt } from "react-icons/bi";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import 'moment-timezone';
import { TbShare3 } from "react-icons/tb";
import classes from '../competitionListingCards/CompetitionListingPageCards.module.scss'
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import Auth from "../../../utils/Auth";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const WorkshopListingPageCards = (props) => {
    const [cardCount, setCardCount] = useState(0);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardDetails, setCardDetails] = useState(props.workshopsOfCategory);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [editIndex, setEditIndex] = useState(null);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const frontendURL = process.env.REACT_APP_FRONT_END_URL;
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);

    useWarnOnLeave(shouldWarn);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.workshopDefaultThumbnailImage}`;

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + viewMoreCardsCount);
    }, [cardDetails]);


    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.workshopsOfCategory !== cardDetails) {
            setCardDetails(props.workshopsOfCategory);
        }
    }, [props.workshopsOfCategory]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole, cost }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.workshop, payOfflineFlag, creatorName, creatorRole, startTime, cost });
        setTransactionId(AppConstants.emptyString);
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);

            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInWorkshop(id, title, uniqueId);
                }
            }
        } else {
            enrolInWorkshop(id, title, uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInWorkshop = async (id, title, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.onlyStudentEnrolWorkshopError);
            setPaymentStatus(AppConstants.emptyString);
            setShowModal(AppConstants.trueText);
            return;
        }
        const workshop = cardDetails.filter(card => card.workshopUniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setShouldWarn(response.shouldWarn);
        setDisplayNetworkError(response.networkError);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzPay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setTransactionId(response.razorpay_payment_id);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInWorkshop(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzPay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzPay.open();
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
        setTransactionId(AppConstants.emptyString);
        if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                setShowModal(AppConstants.falseText);
                setConfirmationPopupActivityDetails({});
            }
        }
    }

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.workshopSlug}`);
    };


    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const getActionButtonText = (workshop) => {
        if (workshop.isOfflinePaymentRequested === AppConstants.yText) {
            return "Requested";
        }
        if (workshop.enrolledOn === null) {
            return "Enrol Now";
        } else {
            return "Enrolled";
        }
    };

    return (
        <>
            <Row xs={1} sm={2} md={3} lg={4} xl={4} className={classes.cardSection}>
                {cardDetails.length && cardDetails?.slice(0, cardCount).map((workshop, index) => (
                    <Col key={workshop.workshopUniqueId} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card className={classes.cardDiv} key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${workshop.skillCategorySlug}${AppConstants.forwardSlash}${workshop.skillSlug}${AppConstants.forwardSlash}${workshop.workshopSlug}`)}>
                            <Card.Img variant="top" className={classes.cardImage} src={workshop.workshopThumbnailImagePath ? workshop.workshopThumbnailImagePath : defaultWorkshopThumbnail} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(workshop) }} />
                            <Card.Body className={classes.cardBody}>
                                <div className={classes.subTitleContainer}>
                                    <div className={`${classes.cardSubTitle} ${classes.workshopDiffColor}`}>{workshop.workshopModeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span onClick={stopPropagation} className={classes.modeSubtitle} data-tooltip-place="top" data-tooltip-id={workshop.workshopUniqueId}><a className={`${classes.workshopLink} ${classes.onlineIconWorkshop}`} href={workshop.workshopGeoLocation} target={AppConstants.openInNewTab}><CgPin className={`${classes.geoLocationIcon} ${classes.onlineIconWorkshop}`} /> {workshop.workshopModeOfDelivery.toUpperCase()}</a></span> : <span className={`${classes.onlineIconWorkshop} ${classes.modeSubtitle}`}>{workshop.workshopModeOfDelivery.toUpperCase()}</span>} </div>
                                    <Tooltip className={classes.tooltipMessage} id={workshop.workshopUniqueId}><span className={classes.tooltipMessage}>{workshop.workshopAddress}</span></Tooltip>
                                </div>
                                <Card.Text className={`${classes.cardTitle} ${classes.titleFixedHeight}`}>
                                    <Link className={classes.cardLink} data-tooltip-id={`${workshop.workshopUniqueId}-title`}>{workshop.workshopTitle}</Link>
                                    <Tooltip className={classes.courseTitleTooltip} id={`${workshop.workshopUniqueId}-title`}><span>{workshop.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(workshop.userRole === AppConstants.userRoleExpert ? `${frontendURL}${AppConstants.expert}${AppConstants.forwardSlash}${workshop.userSlug}` : `${frontendURL}${AppConstants.agency}${AppConstants.forwardSlash}${workshop.userSlug}`) }} className={`${classes.createdBy} ${classes.cardTimeAndDate}`}>By : {workshop.organizedBy}</Card.Text>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarCheck className={classes.calendarIcon} />&nbsp;</div>
                                    <div className={classes.dateTimeText}>
                                        Start:&nbsp;<span className={classes.competitionDateTimeFormat}><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.workshopUtcStartDateString.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.workshopUtcStartDateString.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className={classes.calendarIcon} />&nbsp;</div>
                                    <div className={classes.dateTimeText}>
                                        End:&nbsp;<span className={classes.competitionDateTimeFormat}><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.workshopUtcEndDateString.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.workshopUtcEndDateString.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className={classes.buttonSection}>
                                    <div className={classes.learningActivityCostInfo}><BiWallet /> {PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 })}</div>
                                    <Button disabled={workshop.isOfflinePaymentRequested === AppConstants.yText || workshop.enrolledOn !== AppConstants.nullText} type="button" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: workshop.workshopId, title: workshop.workshopTitle, uniqueId: workshop.workshopUniqueId, startTime: workshop.workshopUtcStartDateString, payOfflineFlag: workshop.payOfflineFlag, creatorName: workshop.organizedBy, creatorRole: workshop.userRole }) }} className={classes.cardButton}>{getActionButtonText(workshop)}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                {loading && cardCount<cardDetails?.length && (
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                )}
            </Row>
            {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={() => setShowModal(AppConstants.trueText)} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} confirmBeforeEnrolment={confirmBeforeEnrolment} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}