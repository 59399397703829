import { useState } from 'react';
import parse from 'html-react-parser';
import { Button, Modal } from "react-bootstrap";
import Moment from 'react-moment';
import ReactQuill from 'react-quill';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { AppConstants } from '../../../constants/AppConstants';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { SuccessMessage } from '../../../constants/SuccessMessage';
import StudentQueryCards from "./StudentQueryCards.module.css";

export const StudentQueryCard = ({ query, updateFetchDataFlag }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showAnswer, setShowAnswer] = useState(false);
    const [expertResponse, setExpertResponse] = useState(AppConstants.emptyEditorString);
    const [showModal, setShowModal] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalAlertText, setModalAlertText] = useState(AppConstants.emptyString);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
        if (modalSuccess) {
            updateFetchDataFlag();
        }
    };

    const submitResponse = async () => {
        try {
            setShowSpinner(true);
            let data = {
                queryUniqueId: query.queryUniqueId,
                question: query.question,
                answer: expertResponse,
                studentEmail: query.studentEmail,
                studentName: query.studentName,
                learningActivityTitle: query.title,
                learningActivityType: query.learningActivityType

            };
            const url = AppConstants.expertRespondToStudentQueryAPI;
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response && response.ok) {
                setModalAlertText(SuccessMessage.studentQueryResponseSuccess);
                setExpertResponse(AppConstants.emptyEditorString);
                setShowAnswer(false);
                setShowSpinner(false);
                updateFetchDataFlag();
            } else {
                setModalAlertText(ErrorMessages.studentQueryResponseError);
                setModalSuccess(false);
                setModalError(true);
                setShowModal(true);
                setShowSpinner(false);
            }
        } catch (error) {
            setModalAlertText(ErrorMessages.studentQueryResponseError);
            setModalSuccess(false);
            setModalError(true);
            setShowModal(true);
            setShowSpinner(false);
        }
    };

    const disabledSubmitButton = () => {
        return (expertResponse.replace(/<(.|\n)*?>/g, '').trim().length === 0)
    }

    return (
        <div className={StudentQueryCards.cardContainer}>
            <div className={StudentQueryCards.questionContainer}>
                <div className={StudentQueryCards.question}>Question: {query.question}</div>
            </div>
            {!query.answer && !showAnswer && <Button onClick={() => setShowAnswer(true)} className={StudentQueryCards.viewButton}>Respond</Button>}
            {<div className={StudentQueryCards.answerContainer}>
                {query.answer && <div className={`${StudentQueryCards.answer} quillListIndent ql-editor`}>{parse(query.answer)}</div>}
                {query.respondedOn && <div className={StudentQueryCards.respondedOnSection}>
                    Responded on : <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{query.respondedOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{query.respondedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}
                </div>}
                {showAnswer && !query.answer && <div className={StudentQueryCards.quillContainer}>
                    <ReactQuill placeholder='Type your response' className={StudentQueryCards.textEditor} value={expertResponse} formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules} onChange={setExpertResponse} />
                    <Button className={StudentQueryCards.submitButton} disabled={disabledSubmitButton() || showSpinner} onClick={submitResponse}>Submit{showSpinner && <BiLoaderAlt className='spinner' />}</Button>
                    <Button className={StudentQueryCards.submitButton} disabled={showSpinner} onClick={() => { setShowAnswer(false); setExpertResponse(AppConstants.emptyEditorString) }} >Cancel</Button>
                </div>}
            </div>}
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Body className={StudentQueryCards.modalBody}>
                    {modalError && <div className={StudentQueryCards.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={StudentQueryCards.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>
                <Modal.Footer className={StudentQueryCards.modalFooter}>
                    {(modalSuccess || modalError) && <Button className={StudentQueryCards.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}