import { NavLink } from 'react-router-dom';
import classes from '../LandingPageLayout/LandingPageTopNavigation.module.css';
import { AppConstants } from "../../constants/AppConstants";

export const LoginPageTopNavigation = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    
    return(
        <>
            <header className={classes.header}>
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>
                <div className={classes.navItemSection}>
                    <nav>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                {/* <NavLink to="/sign-up" className={classes.signUpButton}>
                                    Sign up
                                </NavLink> */}
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
}