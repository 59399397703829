import React, { useState, useEffect } from 'react';
import classes from "../../individualCoursePage/individualCoursePageBanner/IndividualCoursePageBanner.module.css"
import { AppConstants } from '../../../constants/AppConstants';

export const IndividualWorkshopPageBanner = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultWebBanner}`;
    const defaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultTabletBanner}`;
    const defaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultMobileBanner}`;
    const [mobileImage, setMobileImage] = useState(null);
    const [tabletImage, setTabletImage] = useState(null);
    const [webImage, setWebImage] = useState(null);

    useEffect(() => {
        setWebImage(props.workshopDetails.promotionImageWebAbsolutePath ? props.workshopDetails.promotionImageWebAbsolutePath : defaultWebBanner);
        setTabletImage(props.workshopDetails.promotionImageTabletAbsolutePath ? props.workshopDetails.promotionImageTabletAbsolutePath : defaultTabletBanner);
        setMobileImage(props.workshopDetails.promotionImageMobileAbsolutePath ? props.workshopDetails.promotionImageMobileAbsolutePath : defaultMobileBanner);
    }, [props.workshopDetails]);

    return (
        <>
            <div className={classes.courseMobileImgContainer}>
                {!props.workshopDetails.promotionImageMobileAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.workshopDetails?.workshopTitle}</p></div>}
                <img src={mobileImage} alt="web-banner" className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseTabletImgContainer}>
                {!props.workshopDetails.promotionImageTabletAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.workshopDetails?.workshopTitle}</p></div>}
                <img src={tabletImage} alt="tab-banner" className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseBannerImgContainer}>
                {!props.workshopDetails.promotionImageWebAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.workshopDetails?.workshopTitle}</p></div>}
                <img src={webImage} alt="mobile-banner" className={classes.courseWebBannerImage} />
            </div>
        </>
    );
}
