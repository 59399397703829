import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from './SkillsCards.module.css';
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { BiLoaderAlt } from "react-icons/bi";

export const SkillsCards = (props) => {
  const [cardCount, setCardCount] = useState(16);
  const [cardDisplayedCount, setCardDisplayedCount] = useState(16);

  const { lastElementRef, loading } = useInfiniteScroll(() => {
    setCardCount(prevCount => prevCount + cardDisplayedCount);
  }, [props.skillsOfCategory]);


  return (
    <>
      <Row xs={2} sm={2} md={3} lg={4} xl={4} className={classes.skillsCardsSection}>
        {props.skillsOfCategory.sort((a, b) => { if (a.skill.toLowerCase() < b.skill.toLowerCase()) { return -1 } }).slice(0, cardCount).map((skillsCards, index) => (
          <Col ref={index === cardCount - 1 ? lastElementRef : null}>
            <Card className={classes.skillsCard} key={skillsCards.id}>
              <Link to={`${AppConstants.forwardSlash}${AppConstants.skills}${AppConstants.forwardSlash}${skillsCards.categorySlug}${AppConstants.forwardSlash}${skillsCards.skillSlug}`} state={skillsCards}>
                <Card.Img variant="top" src={skillsCards.skillthumbnailImage} />
                <Card.Body className={classes.cardBody}>
                  {props.selectedAge !== "Select Age" &&
                    <Card.Title className={classes.ageRange}>{skillsCards.category}</Card.Title>
                  }
                  <Card.Text className={classes.skillName}>{skillsCards.skill}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
      {loading && cardCount < props.skillsOfCategory?.length && (
        <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
          <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
        </Row>
      )}

    </>
  );
}