import { Tabs, Tab } from "react-bootstrap";
import "./ProfileSkillCategoryTab.css";

export const ProfileSkillCategoryTab = (props) => {
  function selectCategory(e) {
      props.onSelection(e);
  };

  return (
    <div className="profileSkillCategoryDiv">
        <Tabs className="categoryTabContainer"
          defaultActiveKey={props.categoryList[0]}
          transition={false}
          id="profileSkillCategoryFilterTabs"
          onSelect={(e) => selectCategory(e)}
      >
          {props.categoryList.map((category) => {
              return (
                  <Tab eventKey={category} title={category} key={category}></Tab>
              )
          })}
      </Tabs>
    </div>
  );
}