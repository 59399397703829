import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { IndividualWorkshopPageLayout } from "../../component/individualWorkshopPage/IndividualWorkshopPageLayout";

export const IndividualWorkshopPage = () => {
    const [landingPageData] = useLoaderData();
    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <IndividualWorkshopPageLayout />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}

