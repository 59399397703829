import { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";
import { StudentUpcomingCoursesWidgetCards } from '../cards/StudentUpcomingCoursesWidgetCards';
import { AppConstants } from '../../constants/AppConstants';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { OneWeekCalendar } from '../calendar/OneWeekCalendar';
import { ErrorMessages } from '../../constants/ErrorMessages';
import classes from './StudentUpcomingCompetitionsWidget.module.css';
import './widget.css';
import Auth from '../../utils/Auth';

export const StudentUpcomingCoursesWidget = () => {
    const today = new Date();
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const timeZoneAbbr = DateTimeUtil.getZoneNameForPreferredTimeZone(timeZone);

    const [open, setOpen] = useState(true);
    // const [selectedDate, setSelectedDate] = useState(new Date());
    // const [courses, setCourses] = useState([]);
    // const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
    const [upcomingCoursesData, setUpcomingCoursesData] = useState([]);

    useEffect(() => {
        const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
        const timeZoneAbbr = DateTimeUtil.getZoneNameForPreferredTimeZone(timeZone);
        const selectedDateStartTime = new Date();
        selectedDateStartTime.setDate(selectedDateStartTime.getDate() + 1);
        selectedDateStartTime.setHours(0, 0, 0, 0);

        const userSelectedDateStartTime = encodeURIComponent(selectedDateStartTime);

        const selectedDateEndTime = new Date();
        selectedDateEndTime.setHours(23);
        selectedDateEndTime.setMinutes(59);
        selectedDateEndTime.setSeconds(59);

        const userSelectedDateEndTime = encodeURIComponent(selectedDateEndTime);
        const userFetchedTimeZone = encodeURIComponent(timeZone);

        const getCoursesDataOnDate = async () => {
            const url = `${AppConstants.studentUpcomingCompetitionsAndCoursesAPI}?${AppConstants.selectedDateStartTimeParam}&${userSelectedDateStartTime}&${AppConstants.selectedDateEndTimeParam}${userSelectedDateEndTime}${AppConstants.localeParam}${userFetchedTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setUpcomingCoursesData(response?.courseDetails);
            } else {
                setUpcomingCoursesData([]);
            }
        }
        getCoursesDataOnDate();
    }, []);

    // const changeSelectedDate = (date) => {
    //     setSelectedDate(date);
    // };

    return (
        <div className="quickLinkSection">
            <div className="widgetSection">
                <div className="headingSection">
                    <h1 className="widgetHeading">Upcoming Courses</h1>
                </div>
                <div className="iconSection">
                    {!open && <HiOutlinePlusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                    {open && <HiOutlineMinusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                </div>
            </div>
            <Collapse in={open}>
                <div id="studentUpcomingCompetitionWidget">
                    <div className="collapseDiv">
                        {/* <OneWeekCalendar changeSelectedDate={changeSelectedDate} startDate={startDate} numOfDays={7} weekDayFormat="ddd" dateFormat="DD" monthFormat="MMM" /> */}
                        {upcomingCoursesData?.length !== 0 && <StudentUpcomingCoursesWidgetCards cardDetails={upcomingCoursesData} />}
                        {upcomingCoursesData?.length === 0 && <div className={classes.noCompetitionsAvailableSection}>{ErrorMessages.noUpcomingEnroledCoursesFallbackMessage}</div>}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}