import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import classes from './LandingPageFooter.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { NavLink } from 'react-router-dom';
import { FooterSectionExternalLinks } from '../../component/lists/FooterSectionExternalLinks';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { DateTime } from "luxon";

export const LandingPageFooter = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaWhiteLogoFilePath}`;
    const [footerDetails, setFooterDetails] = useState(props.landingPageData.footer);
    const [footerListTitles, setFooterListTitles] = useState([]);
    var filteredList = useMemo(getFilteredList, [footerDetails, footerListTitles]);

    const year = DateTime.now().setZone(DateTimeUtil.getPreferredTimeZoneForUser())?.toFormat("yyyy");

    function updateFooterLinkTitles() {
        let footerData = new Map();
        for (let obj of props.landingPageData.footer.externalLinks) {
            footerData.set(obj.title, obj.title);
        }
        setFooterListTitles([...footerData.values()]);
    }


    function getFilteredList() {
        if (footerListTitles?.length == 0) {
            updateFooterLinkTitles();
        }
    }

    return (
        <>
            {footerDetails.active === AppConstants.trueText && <div className={classes.footerSection}>
                <div className={classes.footerContentSection}>
                    <div className={classes.addressSection}>
                        <div>
                            <NavLink to="/">
                                <img
                                    className={classes.qrenciaLogo}
                                    src={qrenciaLogoUrl}
                                    alt="Qrencia Logo"
                                />
                            </NavLink>
                        </div>
                        <div className={classes.addressContentSection}>
                            <h1 className={classes.addressContent}>{footerDetails.address.title}</h1>
                            <h2 className={classes.addressContent}>{footerDetails.address.subTitle}</h2>
                        </div>
                    </div>
                    <div className={classes.externalLinksSection}>
                        {footerListTitles?.map((f, idx) => (
                            <div key={idx} className={classes.externalLinkListSection}>
                                <FooterSectionExternalLinks externalLinkDetails={footerDetails.externalLinks?.filter((data) => data.title === f)} />
                            </div>
                        ))
                        }
                        {footerDetails.contactDetails[0].active === AppConstants.trueText &&
                            <div className={classes.externalLinkListContactSection}>
                                <h1 className={classes.externalLinkTitle}>{footerDetails.contactDetails[0].title}</h1>
                                <h1 className={classes.externalLinkListElements} onClick={() => window.location = `tel:${footerDetails.contactDetails[0].subTitle}`}>{footerDetails.contactDetails[0].subTitle}</h1>
                                <h1 className={classes.externalLinkListElements} onClick={() => window.location = `mailto:${footerDetails.contactDetails[0].description}`}>{footerDetails.contactDetails[0].description}</h1>
                            </div>
                        }
                    </div>
                </div>
                <hr></hr>
                <div className={classes.footerSocialMediaLinkSection}>
                    <div className={classes.footerLowerSections}>© {year} {footerDetails.copyRightText}</div>
                    <div className={classes.footerTermsPrivacyLowerSections}><NavLink to={AppConstants.termsAndConditions} className={classes.footerTermsPrivacyLink}>{footerDetails.termsAndConditionsText}</NavLink> | <NavLink to={AppConstants.privacyPolicy} className={classes.footerTermsPrivacyLink}>{footerDetails.privacyPolicyText}</NavLink> | <NavLink to={AppConstants.refundPolicyPage} className={classes.footerTermsPrivacyLink}>{footerDetails.refundPolicyText}</NavLink></div>
                    <div className={classes.footerIconsLowerSections}>
                        {footerDetails?.socialMediaLinks?.filter((data) => data.active === AppConstants.trueText).map((socialLink) => (
                            <div key={socialLink.id} className={classes.socialLinkIconSection}>
                                <Link to={socialLink.link} target="_blank">
                                    <img
                                        className={classes.socialLinkIcons}
                                        src={socialLink.imagePath}
                                        alt="Social Media Icons"
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            }
        </>
    );
}