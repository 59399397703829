import { NavLink } from 'react-router-dom';
import classes from './TopNavigationWithoutLogin.module.css';
import { AppConstants } from "../../constants/AppConstants";

export const TopNavigationWithoutLogin = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    return (
        <>
            <header className={classes.header}>
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>
            </header>
        </>
    );
}