import { useCallback, useEffect, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { Card, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { FaFilter } from "react-icons/fa6";
import { MathUtil } from "../../../utils/MathUtil";

export const LearningActivitiesOnQrenciaStats = () => {
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [qrenciaStats, setQrenciaStats] = useState({});

    const constructURL = (url, fromDateString, toDateString) => {
        if (fromDateString) {
            url = `${url}?${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDateString)}`
        }
        if (toDateString && !fromDateString) {
            url = `${url}?${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        } else if (toDateString && fromDateString) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        }
        if (fromDateString || toDateString) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        return url;
    };

    const fetchQrenciaStats = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getQrenciaStatsForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getQrenciaStatsForAdminAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setQrenciaStats(response);
        } else {
            setQrenciaStats({});
        }
    }, []);

    useEffect(() => {
        fetchQrenciaStats();
    }, [fetchQrenciaStats]);


    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        fetchQrenciaStats(fromDateString, toDateString);
    }

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    }

    return (
        <div>
            <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
            {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
            <Row>
                <div className={`${dashboardClass.dashboardCards} mb-4`}>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.competitionCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Competitions</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalPublishedCompetitions)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.courseCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Courses</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalPublishedCourses)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.workshopCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Workshops</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalPublishedWorkshops)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.nudgeCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Nudge Raised</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaStats.totalNudgeRaised)}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    )
}