import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { useNavigate } from "react-router-dom";
import { BiLoaderAlt } from 'react-icons/bi';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import expertViewJudingScreen from "./ExpertViewJudgingScreen.module.css";
import { ExpertJudgingScreenBanner } from './ExpertJudgingScreenBanner';
import "../expertManageCompetitionComponent/dataGrid.css";
import { MathUtil } from "../../utils/MathUtil";
import { StringUtils } from "../../utils/StringUtils";

export const ExpertViewJudgingScreen = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const { competitionSlug, judgeUniqueId } = useParams();
    const [judgingEnabledFlag, setJudgingEnabledFlag] = useState();
    const [judgingPageLoader, setJudgingPageLoader] = useState(true);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const [judgeDetails, setJudgeDetails] = useState();
    const [competitionDetails, setCompetitionDetails] = useState();
    const [competitionParticipants, setCompetitionParticipants] = useState();
    const [judgedCount, setJudgedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();
    const [judgingGridWidth, setJudgingGridWidth] = useState();
    const gridRef = useRef(null);

    useEffect(() => {
        async function getCompetitionInformation() {
            const url = `${AppConstants.getCompetitionSummaryAPI}${competitionSlug}${AppConstants.getCompetitionSummaryAPIPart2}${timeZone}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined && response !== null) {
                setCompetitionDetails(response);
            }
            else {
                navigate(errorPageUrl);
            }
        };
        getCompetitionInformation();
    }, []);

    useEffect(() => {
        if (competitionDetails != undefined) {
            async function getJudgingEnabledFlag() {
                setJudgingPageLoader(true);
                const url = `${AppConstants.getJudgingEnabledFlagAPI}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setJudgingEnabledFlag(response);
                    setJudgingPageLoader(false);
                    if (response === false) {
                        navigate(errorPageUrl);
                    }
                } else {
                    setJudgingEnabledFlag(false);
                    setJudgingPageLoader(false);
                    navigate(errorPageUrl);
                }
            };
            getJudgingEnabledFlag();

            async function getJudgeInformation() {
                const url = `${AppConstants.getJudgeDetailsAPI}${judgeUniqueId}${AppConstants.getJudgeDetailsAPISecondParam}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined && response !== null) {
                    setJudgeDetails(response);
                }
                else {
                    navigate(errorPageUrl);
                }

            };
            getJudgeInformation();

            async function getCompetitionParticipantsInformation() {
                const params = {
                    competitionId: competitionDetails.competitionId,
                    judgeId: judgeUniqueId,
                    skillName: competitionDetails.skillName,
                    teamEnrolmentEnabledFlag: competitionDetails.teamEnrolmentEnabledFlag,
                    markingType: competitionDetails.markingType
                }
                const url = StringUtils.createURLWithParams(AppConstants.getParticipantsForJudgingScreenAPI, params);
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    setCompetitionParticipants(response.sort((a, b) => {
                        return a.solutionSubmissionFlag === AppConstants.yText ? -1 : 1;
                    }));
                    setJudgedCount(response.filter(item => item.competitionMarkedFlag === AppConstants.doneText).length);
                    setTotalCount(response.length);
                }
            };
            getCompetitionParticipantsInformation();
        }

    }, [competitionDetails]);


    useEffect(() => {
        const getGridWidth = () => {
            if (gridRef.current) {
                const gridWidth = gridRef.current.offsetWidth;
                setJudgingGridWidth(gridWidth);
            }

            if (competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText) {
                if (competitionDetails?.markingType === AppConstants.markingTypes.team.value) {
                    let updatedColumns = [...teamColumnDefs];
                    updatedColumns.splice(1, 0, ...teamAdditionalColumns);
                    defineMarkingTable(updatedColumns);
                } else {
                    let updatedColumns = [...columnDefs];
                    updatedColumns.splice(4, 0, ...teamAdditionalColumns);
                    defineMarkingTable(updatedColumns);
                }


            } else {
                defineMarkingTable(columnDefs);
            }

        }
        getGridWidth();
    }, [competitionDetails]);

    const getJudgingStatusTextClass = ({ data }) => {
        if (data.competitionMarkedFlag === AppConstants.doneText) {
            return `${expertViewJudingScreen.doneText}`;
        } else if (data.competitionMarkedFlag === AppConstants.inProgressText) {
            return `${expertViewJudingScreen.inProgressText}`;
        } else if (data.competitionMarkedFlag === AppConstants.notMarkedText) {
            return `${expertViewJudingScreen.notMarkedText}`;
        } else {
            return AppConstants.emptyString;
        }
    };

    //resize columns above 1400px
    const onGridReady = useCallback((params) => {
        if (window.innerWidth >= 1400 && gridRef.current) {
            params.api.sizeColumnsToFit();
        }
    }, []);

    const [columnDefsAllParticipantsGrid, setColumnDefsAllParticipantsGrid] = useState([]);


    const columnDefs = [
        {
            headerName: "S. No",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 70
        },
        { field: 'participantName', tooltipField: 'participantName', headerName: 'Name', minWidth: 150, suppressMovable: true },
        {
            headerName: 'Gender',
            field: 'participantGender',
            valueGetter: ({ data }) => data.participantGender ? data.participantGender : "-",
            tooltipValueGetter: ({ data }) => data.participantGender ? data.participantGender : "-",
            suppressMovable: true,
            minWidth: 100
        },
        {
            field: 'marks', tooltipValueGetter: function getValue(params) {
                if (competitionDetails.skillName === AppConstants.gymnasticsSkillName) {
                    return `${params.data.marksAllotted}/${params.data.maximumMarks}`;

                } else {
                    return params.data.competitionTotalMarks ? `${params.data.marksAllotted}/${params.data.competitionTotalMarks}` : params.data.marksAllotted;
                }
            },
            headerName: 'Marks',
            valueGetter:
                function getValue(params) {
                    const { skillName, showPercentageFlag } = competitionDetails;
                    if (skillName === AppConstants.gymnasticsSkillName) {
                        if (showPercentageFlag === AppConstants.yText) {
                            return `${params.data.marksAllotted}/${params.data.maximumMarks}`;
                        } else {
                            return `${params.data.marksAllotted}`;
                        }
                    } else {
                        return params.data.competitionTotalMarks ? `${params.data.marksAllotted}/${params.data.competitionTotalMarks}` : params.data.marksAllotted;
                    }
                },
            suppressMovable: true,
            width: 100,
            minWidth: 100
        },
        {
            field: 'competitionMarkedFlag', minWidth: 120, tooltipField: 'competitionMarkedFlag', headerName: 'Judging Status',
            cellClass: getJudgingStatusTextClass,
            suppressMovable: true,
            headerClass: "multi-line-header"
        },
        {
            field: 'participantQrenciaId',
            headerName: 'Qrencia ID', suppressMovable: true, minWidth: 120, headerClass: "multi-line-header",
            valueGetter: ({ data }) => data.participantQrenciaId ? data.participantQrenciaId : AppConstants.notAvailableText,
            tooltipValueGetter: ({ data }) => data.participantQrenciaId ? data.participantQrenciaId : AppConstants.notAvailableText,
        },
        {
            field: 'judge', minWidth: 120, headerName: 'Action', tooltipValueGetter: (params) => (params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText) ? "Submission is not uploaded by the participant" : null
            , suppressMovable: true, cellRendererFramework: (params) => <div><Button disabled={params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText} className="gridButton" onClick={() => { handleView(params.data) }}>Judge</Button></div>
        }
    ];

    const teamColumnDefs = [
        {
            headerName: "S. No",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 70
        },
        {
            field: 'marks', tooltipValueGetter: function getValue(params) {
                if (competitionDetails.skillName === AppConstants.gymnasticsSkillName) {
                    return `${params.data.marksAllotted}/${params.data.maximumMarks}`;

                } else {
                    return params.data.competitionTotalMarks ? `${params.data.marksAllotted}/${params.data.competitionTotalMarks}` : params.data.marksAllotted;
                }
            },
            headerName: 'Marks',
            valueGetter:
                function getValue(params) {
                    const { skillName, showPercentageFlag } = competitionDetails;
                    if (skillName === AppConstants.gymnasticsSkillName) {
                        if (showPercentageFlag === AppConstants.yText) {
                            return `${params.data.marksAllotted}/${params.data.maximumMarks}`;
                        } else {
                            return `${params.data.marksAllotted}`;
                        }
                    } else {
                        return params.data.competitionTotalMarks ? `${params.data.marksAllotted}/${params.data.competitionTotalMarks}` : params.data.marksAllotted;
                    }
                },
            suppressMovable: true,
            width: 100,
            minWidth: 100
        },
        {
            field: 'competitionMarkedFlag', minWidth: 120, tooltipField: 'competitionMarkedFlag', headerName: 'Judging Status',
            cellClass: getJudgingStatusTextClass,
            suppressMovable: true,
            headerClass: "multi-line-header"
        },
        {
            field: 'judge', minWidth: 120, headerName: 'Action', tooltipValueGetter: (params) => (params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText) ? "Submission is not uploaded by the participant" : null
            , suppressMovable: true, cellRendererFramework: (params) => <div><Button disabled={params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText} className="gridButton" onClick={() => { handleView(params.data) }}>Judge</Button></div>
        }
    ];


    const teamAdditionalColumns = [{
        headerName: 'Team Name',
        field: 'teamName',
        valueGetter: ({ data }) => data.teamName ?? AppConstants.notAvailableText,
        tooltipValueGetter: ({ data }) => data.teamName ?? AppConstants.notAvailableText,
        suppressMovable: true,
        headerClass: 'multi-line-header',
        width: 200
    },
    {
        headerName: 'Institute Name',
        field: 'instituteName',
        valueGetter: ({ data }) => data.instituteName ?? AppConstants.notAvailableText,
        tooltipValueGetter: ({ data }) => data.instituteName ?? AppConstants.notAvailableText,
        suppressMovable: true,
        headerClass: 'multi-line-header',
        minWidth: 150
    },
    {
        headerName: 'Institute Type',
        field: 'instituteType',
        valueGetter: ({ data }) => data.instituteType ?? AppConstants.notAvailableText,
        tooltipValueGetter: ({ data }) => data.instituteType ?? AppConstants.notAvailableText,
        suppressMovable: true,
        headerClass: 'multi-line-header',
        minWidth: 120
    }];

    const defineMarkingTable = (columns) => {
        setColumnDefsAllParticipantsGrid(columns)
    };


    const defaultColDefDesktop = useMemo(() => ({
        width: '100%',
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        flex: 1,
        suppressHorizontalScroll: true,
        floatingFilterComponentParams: { suppressFilterButton: true }
    }), []);

    const defaultColDefMobile = useMemo(() => ({
        width: '100%',
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        suppressHorizontalScroll: true,
        floatingFilterComponentParams: { suppressFilterButton: true }
    }), []);

    //handle click on view button
    async function handleView(params) {
        let url = AppConstants.emptyString;
        if (competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText) {
            url = AppConstants.studentText + AppConstants.forwardSlash + params.uniqueId;
        } else {
            url = AppConstants.studentText + AppConstants.forwardSlash + params.participantQrenciaId;
        }

        navigate(url);
    }

    return (
        <>
            {judgingPageLoader && <div className={expertViewJudingScreen.errorContainer}>
                <p className={expertViewJudingScreen.loaderIcon}><BiLoaderAlt className={expertViewJudingScreen.spinner} /></p>
            </div>}
            {!judgingPageLoader && competitionDetails !== null && competitionDetails !== undefined && <ExpertJudgingScreenBanner competitionDetail={competitionDetails} />}
            {!judgingPageLoader && competitionDetails !== null && competitionDetails !== undefined &&
                <div className={expertViewJudingScreen.participantSection}>
                    <div className={expertViewJudingScreen.thumbnailSection}>
                        <div className={expertViewJudingScreen.thumbnailContainer}>
                            <img className={expertViewJudingScreen.thumbnail} src={competitionDetails.thumbnailImage !== null ? competitionDetails.thumbnailImage : ""} alt="Competition Thumbnail"></img>
                        </div>
                        <div className={expertViewJudingScreen.thumbnailDetailsSection}>
                            <div className={expertViewJudingScreen.competitionTitle}>{competitionDetails.competitionTitle}</div>
                            <div className={expertViewJudingScreen.competitionDescription}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionStartTime?.replace(' ', 'T') + 'Z'}</Moment> <p className={expertViewJudingScreen.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionStartTime?.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> <p className={expertViewJudingScreen.hyphenText}>{AppConstants.hyphen}</p> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionEndTime?.replace(' ', 'T') + 'Z'}</Moment> <p className={expertViewJudingScreen.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionEndTime?.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetails.preferredTimezone})`} {competitionDetails.venueLocation != null && <span className={expertViewJudingScreen.location}> ({competitionDetails.venueLocation})</span>}</div>
                            {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.nText && <div className={expertViewJudingScreen.competitionDescription}>{MathUtil.formatNumberToLocaleString(competitionDetails?.enrolledCandidates)} {" "}{competitionDetails?.enrolledCandidates === 1 ? "Enrollement" : "Enrollements"}</div>}
                            {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && (
                                <div className={expertViewJudingScreen.competitionDescription}>
                                    {MathUtil.formatNumberToLocaleString(competitionDetails?.teamCount)}{" "}
                                    {competitionDetails?.teamCount === 1 ? "Team" : "Teams"}

                                    {competitionDetails?.markingType === AppConstants.markingTypes.individual.value && (
                                        <>
                                            {" ("}{competitionDetails?.enrolledCandidates === 1 ? "Total Participant : " : "Total Participants : "}{MathUtil.formatNumberToLocaleString(competitionDetails?.enrolledCandidates)}{")"}
                                        </>
                                    )}
                                </div>
                            )}
                            <div className={expertViewJudingScreen.competitionDescription}><strong>Judge:</strong> {judgeDetails?.judgeName} ({judgeDetails?.judgeEmail})</div>
                        </div>
                    </div>
                    <div className={expertViewJudingScreen.headingSection}>
                        <div className={expertViewJudingScreen.headingSectionDiv}>
                            {(competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.nText || (competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails?.markingType === AppConstants.markingTypes.individual.value)) && <h1 className={expertViewJudingScreen.sectionHeading}>Participants</h1>}
                            {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails?.markingType === AppConstants.markingTypes.team.value && <h1 className={expertViewJudingScreen.sectionHeading}>Teams</h1>}
                        </div>
                        <div className={expertViewJudingScreen.judingCountDiv}>
                            {(competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.nText || (competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails?.markingType === AppConstants.markingTypes.individual.value)) && <p className={expertViewJudingScreen.participantsJudgedCount}>Participants Judged: {MathUtil.formatNumberToLocaleString(judgedCount)} out of {MathUtil.formatNumberToLocaleString(totalCount)}</p>}
                            {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails?.markingType === AppConstants.markingTypes.team.value && <p className={expertViewJudingScreen.participantsJudgedCount}>Teams Judged: {MathUtil.formatNumberToLocaleString(judgedCount)} out of {MathUtil.formatNumberToLocaleString(totalCount)}</p>}
                        </div>
                    </div>
                    {<div className={expertViewJudingScreen.agGridDesktop}>
                        <div className="allParticipantsGrid">
                            <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                <AgGridReact
                                    className={expertViewJudingScreen.gridSection}
                                    ref={gridRef}
                                    defaultColDef={defaultColDefDesktop}
                                    rowData={competitionParticipants}
                                    columnDefs={columnDefsAllParticipantsGrid}
                                    enableBrowserTooltips={true}
                                    domLayout='autoHeight'
                                    suppressRowHoverHighlight={true}
                                    onGridReady={onGridReady}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                    }{
                        <div className={expertViewJudingScreen.agGridMobile}>
                            <div className="allParticipantsGrid">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        className={expertViewJudingScreen.gridSection}
                                        ref={gridRef}
                                        defaultColDef={defaultColDefMobile}
                                        rowData={competitionParticipants}
                                        columnDefs={columnDefsAllParticipantsGrid}
                                        enableBrowserTooltips={true}
                                        domLayout='autoHeight'
                                        suppressRowHoverHighlight={true}
                                        onGridReady={onGridReady}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    )
}
