import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { FaRegCalendarCheck } from 'react-icons/fa';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { MdShareLocation } from 'react-icons/md';
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import studentCompetitionWidgetCards from './StudentCompetitionWidgetCards.module.css';
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import 'moment-timezone';
import { Tooltip } from "react-tooltip";
import { BiDownload, BiCalendarX } from "react-icons/bi";
import { TbRefreshAlert } from "react-icons/tb";

export const StudentUpcomingWorkshopsWidgetCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.studentUpcomingWorkshopsCardsCount);

    //download workshop pre-read
    async function downloadPreRead(workshop) {
        setDownloadFailed(false);
        const fileUrl = workshop.descriptionDocumentAbsolutePath;
        try {
            FileOperationsUtil.downloadDocument(fileUrl, workshop.workshopTitle, AppConstants.brochureFile);
        } catch (error) {
            setDownloadFailed(true);
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={studentCompetitionWidgetCards.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((workshop) => (
                    <Col className={studentCompetitionWidgetCards.competitionCardSection} key={workshop.id}>
                        <Card className={studentCompetitionWidgetCards.competitionCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${workshop.skillCategorySlug}${AppConstants.forwardSlash}${workshop.skillSlug}${AppConstants.forwardSlash}${workshop.workshopSlug}`)}>
                            <Card.Body className={`${studentCompetitionWidgetCards.competitionCardBody} ${studentCompetitionWidgetCards.workshopCardBody}`}>
                                <Card.Title className={studentCompetitionWidgetCards.competitionTitle}><Link>{workshop.workshopTitle}</Link></Card.Title>
                                <div>
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaRegCalendarCheck className={studentCompetitionWidgetCards.workshopCalendarIcon} /> <div className={studentCompetitionWidgetCards.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span></div>
                                    </div>
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <BiCalendarX className={`${studentCompetitionWidgetCards.workshopCalendarIcon} ${studentCompetitionWidgetCards.endDateIcon}`} /> <div className={studentCompetitionWidgetCards.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span></div>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${workshop.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${workshop.userSlug}`) }} className={studentCompetitionWidgetCards.dateTimeSection}>By : {workshop.organizerName}</div>
                                <div>
                                    {workshop.workshopModeOfDelivery === AppConstants.modeOfDelivery[0] && <div onClick={stopPropagation}>
                                        {workshop.workshopLink !== null ? <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={workshop.workshopLink} target="_blank"><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Workshop Link</a> : <p className={studentCompetitionWidgetCards.courseOnlineModeText}><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Online</p>} {workshop.descriptionDocumentAbsolutePath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {workshop.descriptionDocumentAbsolutePath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(workshop) }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Brochure {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(workshop) }} />}</a>}
                                    </div>}
                                    {workshop.workshopModeOfDelivery === AppConstants.modeOfDelivery[1] && <div onClick={stopPropagation}>
                                        <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={workshop.geoLocation} target="_blank"><span className={studentCompetitionWidgetCards.widgitLocationSpan} data-tooltip-place="top" data-tooltip-id={workshop.uniqueId} > <MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Venue Map</span></a> {workshop.descriptionDocumentAbsolutePath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {workshop.descriptionDocumentAbsolutePath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(workshop) }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Brochure {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(workshop) }} />}</a>}
                                    </div>}
                                    <Tooltip className={studentCompetitionWidgetCards.tooltipMessage} id={workshop.uniqueId}><span className={studentCompetitionWidgetCards.tooltipMessage}>{workshop?.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.studentUpcomingWorkshopsCardsCount)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}