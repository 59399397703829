import React, { useEffect, useState } from "react";
import { IndividualCompetitionBanner } from "../individualCompetition/IndividualCompetitionBanner";
import { useParams } from "react-router-dom";
import { BiLoaderAlt } from 'react-icons/bi';
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import Auth from "../../utils/Auth";
import { useNavigate } from "react-router-dom";
import { ApproveCompetitionLeftSection } from "./ApproveCompetitionLeftSection";
import { ApproveCompetitionRightSection } from "./ApproveCompetitionRightSection";
import classes from "../individualCompetition/IndividualCompetition.module.css"
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const ApproveCompetitionLayout = () => {
    const { competitionSlug } = useParams();
    const [competitionDetails, setCompetitionDetails] = useState([]);
    const [cometitionDetailsLoader, setCometitionDetailsLoader] = useState(AppConstants.trueText);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();
    const navigate = useNavigate();

    useEffect(() => {
        //fetch Competitions details
        if (Auth.isLogin() === AppConstants.trueText) {
            async function getCompetitionDetails() {
                setCometitionDetailsLoader(AppConstants.trueText);
                const url = `${AppConstants.getFeeWaivedCompetitionDetailsAPI}${AppConstants.forwardSlash}${competitionSlug}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setCompetitionDetails(response);
                    setCometitionDetailsLoader(AppConstants.falseText);
                } else {
                    setCompetitionDetails([]);
                    setCometitionDetailsLoader(AppConstants.falseText);
                    navigate(AppConstants.asterisk);
                }
            };
            getCompetitionDetails();
        } else {
            async function getCompetitionDetailsUnAurhorized() {
                setCometitionDetailsLoader(AppConstants.trueText);
                const url = `${AppConstants.getFeeWaivedCompetitionDetailsAPI}${AppConstants.forwardSlash}${competitionSlug}`;
                const requestHeaders = {
                    preferredTimezone
                }
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
                if (response !== undefined) {
                    setCompetitionDetails(response);
                    setCometitionDetailsLoader(AppConstants.falseText);
                } else {
                    setCompetitionDetails([]);
                    setCometitionDetailsLoader(AppConstants.falseText);
                    navigate(AppConstants.asterisk);
                }
            };
            getCompetitionDetailsUnAurhorized();
        }

    }, [])

    return (
        <>
            {!cometitionDetailsLoader && competitionDetails.length !== 0 && <IndividualCompetitionBanner competitionData={competitionDetails} />}
            {!cometitionDetailsLoader && competitionDetails.length !== 0 && <div className="row flex-column-reverse flex-lg-row">
                <div className="col-md-12 col-lg-8 col-xl-8 p-0">
                    <ApproveCompetitionLeftSection competitionData={competitionDetails} />
                </div>
                <div className="col-md-12 col-lg-4 col-xl-4 p-0">
                    <ApproveCompetitionRightSection competitionData={competitionDetails} />
                </div>
            </div>}
            {cometitionDetailsLoader && <div className={classes.errorContainer}>
                <p className={classes.loaderIcon}><BiLoaderAlt className={classes.spinner} /></p>
            </div>}
        </>
    );

}