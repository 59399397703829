import React, { useState, useEffect } from "react";
import classes from "../createCompetitionComponents/AdvancedCompetitionSettings/AdvancedCompetitionSettings.module.css";
import { AppConstants } from "../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Tooltip } from "react-tooltip";
import { Col, Form, Row } from "react-bootstrap";
import formClass from "../../common/FormStyling.module.css";

export const EditAdvancedCompetitionSettings = (props) => {
    const competitionData = props.competitionDetails;
    const teamEnrolmentFormLabels = AppConstants.teamEnrolmentsFormLables;
    const enrolledParticipants = competitionData.participantsDetails?.length;
    const [feeWaiver, setFeeWaiver] = useState(competitionData.competitionFeeWaiverFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [publicEnrolments, setPublicEnrolments] = useState(competitionData.disablePublicEnrolmentFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [performanceReport, setPerformanceReport] = useState(competitionData.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [onlyForClubQrencia, setOnlyForCLubQrencia] = useState(competitionData.clubQrenciaFlg === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(competitionData.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [isOfflineFlagUpdatedOnce, setIsOfflineFlagUpdatedOnce] = useState(AppConstants.falseText);
    const [enableTeamEnrolments, setEnableTeamEnrolments] = useState(competitionData.teamEnrolmentEnabledFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [markingType, setMarkingType] = useState(competitionData.markingType || AppConstants.emptyString);
    const [markingParameter, setMarkingParameter] = useState(competitionData.markingParameter || AppConstants.emptyString);
    const [teamParticipantsLimit, setTeamParticipantsLimit] = useState(competitionData.teamParticipantsLimit || AppConstants.emptyString);
    const [teamsLimit, setTeamsLimit] = useState(competitionData.teamsLimit || AppConstants.emptyString);

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({
                performanceReport, enableOfflinePayment,
                enableTeamEnrolments,
                markingParameter,
                markingType,
                teamParticipantsLimit,
                teamsLimit
            });
        }
        handleChange();
    }, [performanceReport, enableOfflinePayment, markingParameter, markingType, enableTeamEnrolments, teamParticipantsLimit, teamsLimit]);

    useEffect(() => {
        if (!props.rubricsConfigured) {
            setPerformanceReport(AppConstants.trueText);
            setMarkingType(AppConstants.emptyString);
            setMarkingParameter(AppConstants.emptyString);
        }
    }, []);

    useEffect(() => {
        let netAmount = props.cost ? parseInt(props.cost) : 0;
        if (netAmount <= 0 && isOfflineFlagUpdatedOnce) {
            setEnableOfflinePayment(AppConstants.falseText);
        }
        if (!isOfflineFlagUpdatedOnce) {
            setIsOfflineFlagUpdatedOnce(AppConstants.trueText);
        }
    }, [props.cost, isOfflineFlagUpdatedOnce]);

    const isOfflinePayemtnDisabled = () => {
        if (competitionData.competitionStatus === AppConstants.competitionStatus.past) return true;
        let netAmount = props.cost ? parseInt(props.cost) : 0;
        if (netAmount <= 0) {
            return true;
        } else {
            return false;
        }
    };

    const changeTeamEnrolments = (e) => {
        if (e.target.checked) {
            setPerformanceReport(AppConstants.trueText);
        }
        setEnableTeamEnrolments(!enableTeamEnrolments);
        setMarkingParameter(AppConstants.emptyString);
        setMarkingType(AppConstants.emptyString);
        setTeamParticipantsLimit(AppConstants.emptyString);
        setTeamsLimit(AppConstants.emptyString);
    };

    const changeMarkingType = (event) => {
        setMarkingType(event.target.value);
        setMarkingParameter(AppConstants.emptyString);
    };

    const checkValidNumber = (event) => {
        let excludedKeywords = [".", "-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };

    const changeParticipationLimit = (event) => {
        setTeamParticipantsLimit(event.target.value);
    }

    const changeTeamLimit = (event) => {
        setTeamsLimit(event.target.value);
    }

    return (
        <>
            <div className={classes.mainContainer}>
                {!props.rubricsConfigured && <div className={classes.warningInfoContainer}>
                    <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />Since judging parameters are not configured, the result will not be shared with the participants</p></div>
                </div>}
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}> <span className="checkboxInputForm">
                        <input type="checkbox" checked={feeWaiver} readOnly={true} disabled={true} />
                    </span>Request Fee Waiver <RiInformationFill className={classes.infoButton} data-tooltip-id="feeWaiverInfo" /></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm">
                        <input type="checkbox" checked={publicEnrolments} readOnly={true} disabled={true} />
                    </span>Disable Public Enrolments <RiInformationFill className={classes.infoButton} data-tooltip-id="publicEnrolmentsInfo" /></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm">
                        <input disabled={(enrolledParticipants > 0 && competitionData.disablePerformanceResultFlag === AppConstants.nText) || enableTeamEnrolments} readOnly={(enrolledParticipants > 0 && competitionData.disablePerformanceResultFlag === AppConstants.nText) || enableTeamEnrolments} type="checkbox" checked={props.rubricsConfigured ? performanceReport : AppConstants.trueText} onChange={() => { setPerformanceReport(!performanceReport); }} />
                    </span>Disable Performance Analysis <RiInformationFill className={classes.infoButton} data-tooltip-id="performanceReportInfo" /></div>
                </div>
                {<div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm"><input className={classes.switchInput} disabled={isOfflinePayemtnDisabled() || onlyForClubQrencia || feeWaiver} type="checkbox" checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /></div>
                    <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
                </div>}
                <Tooltip className={classes.tooltipMessage} id="feeWaiverInfo"><span className={classes.tooltipMessage}>{AppConstants.feeWaiverInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="publicEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePublicEnrolmentInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="performanceReportInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePerformanceAnalysisInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="clubQrenciaInfo"><span className={classes.tooltipMessage}>By selecting this, the competition will be created exclusively for your school and for the below selected classes.</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="teamEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.enableTeamEnrolmentsInfo}</span></Tooltip>
            </div>

            {<div className={classes.switchContainer}>
                <div className={classes.switchText}><span className="checkboxInputForm"><input type="checkbox" checked={enableTeamEnrolments} onChange={(e) => changeTeamEnrolments(e)} disabled={enrolledParticipants > 0 || onlyForClubQrencia} readonly={enrolledParticipants?.length > 0} />
                </span>Enable Team Enrolments <RiInformationFill className={classes.infoButton} data-tooltip-id="teamEnrolmentsInfo" /></div>
            </div>}
            {enableTeamEnrolments && <Row className="mb-4" xs={1} sm={2} md={2} lg={2} xl={3}>
                <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.teamParticipantsLimit.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        placeholder="Team Size"
                        name={teamEnrolmentFormLabels.teamParticipantsLimit.name}
                        value={teamParticipantsLimit}
                        onKeyDown={checkValidNumber}
                        onChange={changeParticipationLimit}
                        disabled={enrolledParticipants > 0 || props.competitionDetails.competitionStatus === AppConstants.competitionStatus.past}
                        readOnly={enrolledParticipants > 0 || props.competitionDetails.competitionStatus === AppConstants.competitionStatus.past}
                    />
                </Form.Group>
                <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.teamsLimit.label}</Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        placeholder="Number of Teams Allowed"
                        name={teamEnrolmentFormLabels.teamsLimit.name}
                        value={teamsLimit}
                        onKeyDown={checkValidNumber}
                        onChange={changeTeamLimit}
                        disabled={props.competitionDetails.competitionStatus === AppConstants.competitionStatus.past}
                        readOnly={props.competitionDetails.competitionStatus === AppConstants.competitionStatus.past}
                    />
                </Form.Group>
                {props.rubricsConfigured && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.markingType.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name={teamEnrolmentFormLabels.markingType.name}
                        value={markingType}
                        disabled={(!props.rubricsConfigured || props.judgingStartedFlag)}
                        readOnly={!props.rubricsConfigured || props.judgingStartedFlag}
                        onChange={changeMarkingType} >
                        <option value={AppConstants.emptyString} defaultValue>Select marking type</option>
                        {Object.values(AppConstants.markingTypes)?.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>}
                {markingType === AppConstants.markingTypes.individual.value && props.rubricsConfigured && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.markingParameter.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name={teamEnrolmentFormLabels.markingParameter.name}
                        value={markingParameter}
                        disabled={(!props.rubricsConfigured || props.judgingStartedFlag)}
                        readOnly={!props.rubricsConfigured || props.judgingStartedFlag}
                        onChange={(e) => setMarkingParameter(e.target.value)} >
                        <option value={AppConstants.emptyString} defaultValue>Select scoring parameter</option>
                        {Object.values(AppConstants.markingParameters)?.map(option => (
                            <option key={option.value} value={option.key}>
                                {option.value}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>}
            </Row>}
            {props.isExpertAssociatedWithClubQrencia === AppConstants.yText && <div className={classes.switchContainer}>
                <div className={classes.switchText}><span className="checkboxInputForm">
                    <input readOnly={true} disabled={true} type="checkbox" checked={onlyForClubQrencia} />
                </span>Only for Club Qrencia <RiInformationFill className={classes.infoButton} data-tooltip-id="clubQrenciaInfo" /></div>
            </div>}
            {onlyForClubQrencia && !enableTeamEnrolments && <div className="row mb-4"> {
                <div className={classes.selectedClassesSection}>
                    Selected Classes: {competitionData.selectedClasses?.replace(/\-+/g, '').replace(/,+/g, ', ')}
                </div>
            }
            </div>

            }
        </>
    )
}