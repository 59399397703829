import { NavLink } from 'react-router-dom';
import classes from './LandingPageTopNavigation.module.css';
import { AppConstants } from "../../constants/AppConstants";
import templateClasses from '../ExpertPageLayout/ExpertHeader.module.css';
import Auth from '../../utils/Auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from "react-icons/fa";
import { BiLogOut, BiHome } from "react-icons/bi";
import { Logout } from '../../common/Logout';
import { RiProfileLine } from "react-icons/ri";

export const LandingPageTopNavigation = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    const userBrandingPageLink = Auth.isLogin() ? `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole()?.toLowerCase()}/${Auth.getLoggedInUserDetails()?.userSlug}` : AppConstants.emptyString;

    function getUserHomePageUrl() {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleExpert) {
                window.open(AppConstants.expertHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                window.open(AppConstants.studentHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
                window.open(AppConstants.adminNumberTrackingPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
                window.open(AppConstants.agencyHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
                window.open(AppConstants.salesNumberTrackingPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                window.open(AppConstants.marketingBulkSignupPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                window.open(AppConstants.partnerSuccessNumberTracking, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
                window.open(AppConstants.teamHomePath, AppConstants.newPageTargetSelf);
            } else {
                window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
            }
        }
        else {
            window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
        }
    }

    const showBrandingPageLink = () => {
        if ((Auth.getUserRole() === AppConstants.userRoleExpert || Auth.getUserRole() === AppConstants.userRoleAgency) && window.location.href !== `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole().toLowerCase()}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userSlug}`) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <header className={classes.header}>
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>

                <div className={classes.navItemSection}>
                    <nav>
                        {!Auth.isLogin() &&
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <NavLink to={AppConstants.loginPageUrl} className={classes.loginButton}>
                                        Login
                                    </NavLink>
                                </li>
                                <li className={classes.listItem}>
                                    <NavLink to={AppConstants.signup} className={classes.signUpButton}>
                                        Join Now
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    </nav>
                </div>
                {Auth.isLogin() &&
                    <div className={templateClasses.profileSection}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !== null && <div>
                            <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={templateClasses.profileImage} alt="Profile" />
                        </div>}
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                            <div className={templateClasses.userInitials}>{Auth.getLoggedInUserDetails().nameInitials}</div>
                        </div>}
                        <Dropdown>
                            <Dropdown.Toggle className={templateClasses.profilePopoverButton}>
                                <p className={templateClasses.profileName}>{Auth.getLoggedInName().length < 16 ? Auth.getLoggedInName() : Auth.getLoggedInName().substr(0, 16) + ".."}</p> <FaChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={getUserHomePageUrl}><BiHome className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Home</p></Dropdown.Item>
                                {(showBrandingPageLink()) && <Dropdown.Item onClick={() => window.open(userBrandingPageLink, AppConstants.openInNewTab)} className={templateClasses.profileDropdownItem}><RiProfileLine className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Branding Page</p></Dropdown.Item>}
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Logout</p></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }
            </header>
        </>
    );
}