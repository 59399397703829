import React, { useState, useEffect } from 'react';
import micrositePageBanner from './MicrositePageBanner.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const MicrositePageBanner = (props) => {
    const [mobileImage, setMobileImage] = useState(null);
    const [tabletImage, setTabletImage] = useState(null);
    const [webImage, setWebImage] = useState(null);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const expertDefaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertMicrositeDefaultWebBanner}`;
    const expertDefaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertMicrositeDefaultTabBanner}`;
    const expertDefaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertMicrositeDefaultMobileBanner}`;
    const agencyDefaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.agencyMicrositeDefaultWebBanner}`;
    const agencyDefaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.agencyMicrositeDefaultTabBanner}`;
    const agencyDefaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.agencyMicrositeDefaultMobileBanner}`;

    useEffect(() => {
        if (props.userRole?.toLowerCase() === AppConstants.userRoleExpert?.toLocaleLowerCase()) {
            setWebImage(props.micrositeBannerData.webBannerImagePath || expertDefaultWebBanner);
            setTabletImage(props.micrositeBannerData.tabletBannerImagePath || expertDefaultTabletBanner);
            setMobileImage(props.micrositeBannerData.mobileBannerImagePath || expertDefaultMobileBanner);
        } else {
            setWebImage(props.micrositeBannerData.webBannerImageAbsolutePath || agencyDefaultWebBanner);
            setTabletImage(props.micrositeBannerData.tabletBannerImageAbsolutePath || agencyDefaultTabletBanner);
            setMobileImage(props.micrositeBannerData.mobileBannerImageAbsolutePath || agencyDefaultMobileBanner);
        }
    }, [props.micrositeBannerData]);

    return (
        <>
            <div className={micrositePageBanner.micrositeMobileImgContainer}>
                {props.userRole?.toLowerCase() === AppConstants.userRoleExpert?.toLocaleLowerCase() && !props.micrositeBannerData.mobileBannerImagePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.firstName} {props.micrositeBannerData.lastName}</p>
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.yText && props.micrositeBannerData?.organizationDetails !== null && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.organizationDetails?.organizationDesignation}, {props.micrositeBannerData?.organizationDetails?.organizationName}</p>}
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.nText && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.city}{props.micrositeBannerData?.state ? `, ${props.micrositeBannerData?.state}` : ""}</p>}
                </div>}
                {props.userRole?.toLowerCase() === AppConstants.userRoleAgency?.toLocaleLowerCase() && !props.micrositeBannerData.mobileBannerImageAbsolutePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.agencyName}</p>
                    {(props.micrositeBannerData?.agencyState !== null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}, {props.micrositeBannerData?.agencyState}</p>}
                    {(props.micrositeBannerData?.agencyState === null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}</p>}
                </div>}
                <img src={mobileImage} alt={AppConstants.micrositeBannerText} className={micrositePageBanner.micrositeBannerImage} />
            </div>
            {<div className={micrositePageBanner.micrositeTabletImgContainer}>
                {props.userRole?.toLowerCase() === AppConstants.userRoleExpert?.toLocaleLowerCase() && !props.micrositeBannerData.tabletBannerImagePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.firstName} {props.micrositeBannerData.lastName}</p>
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.yText && props.micrositeBannerData?.organizationDetails !== null && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.organizationDetails?.organizationDesignation}, {props.micrositeBannerData?.organizationDetails?.organizationName}</p>}
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.nText && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.city}{props.micrositeBannerData?.state ? `, ${props.micrositeBannerData?.state}` : ""}</p>}
                </div>}
                {props.userRole?.toLowerCase() === AppConstants.userRoleAgency?.toLocaleLowerCase() && !props.micrositeBannerData.tabletBannerImageAbsolutePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.agencyName}</p>
                    {(props.micrositeBannerData?.agencyState !== null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}, {props.micrositeBannerData?.agencyState}</p>}
                    {(props.micrositeBannerData?.agencyState === null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}</p>}
                </div>}
                <img src={tabletImage} alt={AppConstants.micrositeBannerText} className={micrositePageBanner.micrositeBannerImage} />
            </div>}
            {<div className={micrositePageBanner.micrositeBannerImgContainer}>
                {props.userRole?.toLowerCase() === AppConstants.userRoleExpert?.toLocaleLowerCase() && !props.micrositeBannerData.webBannerImagePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.firstName} {props.micrositeBannerData.lastName}</p>
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.yText && props.micrositeBannerData?.organizationDetails !== null && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.organizationDetails?.organizationDesignation}, {props.micrositeBannerData?.organizationDetails?.organizationName}</p>}
                    {props.micrositeBannerData.isCreatorAPartOfQrencia === AppConstants.nText && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.city}{props.micrositeBannerData?.state ? `, ${props.micrositeBannerData?.state}` : ""}</p>}
                </div>}
                {props.userRole?.toLowerCase() === AppConstants.userRoleAgency?.toLocaleLowerCase() && !props.micrositeBannerData.webBannerImageAbsolutePath && <div className={micrositePageBanner.micrositeBannerTitleContainer}>
                    <p className={micrositePageBanner.micrositeBannerTitle}>{props.micrositeBannerData.agencyName}</p>
                    {(props.micrositeBannerData?.agencyState !== null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}, {props.micrositeBannerData?.agencyState}</p>}
                    {(props.micrositeBannerData?.agencyState === null) && <p className={micrositePageBanner.micrositeBannerSubtitle}>{props.micrositeBannerData?.agencyCity}</p>}
                </div>}
                <img src={webImage} alt={AppConstants.micrositeBannerText} className={micrositePageBanner.micrositeWebBannerImage} />
            </div>}
        </>
    );
}