import React, { useState, useEffect } from 'react';
import classes from './AdminCreateObrComponent.module.css';
import formClass from '../../common/FormStyling.module.css'
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import { ExpertObrDetails } from '../formControls/adminForms/createObrForms/ExpertObrDetails';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AgencyObrDetails } from '../formControls/adminForms/createObrForms/AgencyObrDetails';
import { Form, Row } from 'react-bootstrap';
import { AdminManageNotActiveObr } from '../formControls/adminForms/createObrForms/AdminManageNotActiveObr';
import { AdminManageActiveObr } from '../formControls/adminForms/createObrForms/AdminManageActiveObr';

export const AdminCreateObrComponent = () => {
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [noActionObrList, setNoActionObrList] = useState([]);
    const [actionObrList, setActionObrList] = useState([]);
    const [expertObr, setExpertObr] = useState({});
    const [showSubmitForm, setShowSubmitForm] = useState(AppConstants.falseText);
    const [showActivExpertDetails, setShowActiveExpertDetails] = useState(AppConstants.falseText);
    const [showNotActiveExpertDetails, setShowNotActiveExpertDetails] = useState(AppConstants.falseText);
    const [selectedActiveObr, SetSelectedActiveObr] = useState(AppConstants.emptyString);
    const [selectedNotActiveObr, SetSelectedNotActiveObr] = useState(AppConstants.emptyString);
    const [obrCreatedFor, setObrCreatedFor] = useState(AppConstants.emptyString);
    const [noActionAgencyObrList, setNoActionAgencyObrList] = useState([]);
    const [actionsAgencyObrList, setActionsAgencyObrList] = useState([]);
    const [filterUserObrRole, setFilterUserObrRole] = useState(AppConstants.userRoleExpert);
    const [creatorGalleryContent, setCreatorGalleryContent] = useState([]);

    //fetch Countries
    useEffect(() => {
        async function fetchCountries() {
            const url = AppConstants.fetchCountriesAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setCountryList(response);
            return null;
        };
        fetchCountries()

        async function fetchStates() {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(AppConstants.getStatesApi, config);
            const responseData = await response.json();
            setStateList(responseData);
            return null;
        };
        fetchStates()

        async function getNoActionsObr() {
            const url = AppConstants.getAllExpertObrDetails + AppConstants.falseText;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setNoActionObrList(response);
            return null;
        };
        getNoActionsObr()

        async function getActionsObr() {
            const url = AppConstants.getAllExpertObrDetails + AppConstants.trueText;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            setActionObrList(response);
            return null;
        };
        getActionsObr()

        async function getActionsObrForAgency() {
            const url = `${AppConstants.getAgencyObrDetailsByActionStatusAPI}${AppConstants.trueText}`;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            setActionsAgencyObrList(response);
            return null;
        }
        getActionsObrForAgency();

        async function getNoActionsObrForAgency() {
            const url = `${AppConstants.getAgencyObrDetailsByActionStatusAPI}${AppConstants.falseText}`;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setNoActionAgencyObrList(response);
            return null;
        };
        getNoActionsObrForAgency();
    }, []);

    function showDetailsNotActiveHandler(value) {
        setShowNotActiveExpertDetails(!showNotActiveExpertDetails);
    }
    function showDetailsActiveHandler(value) {
        setShowActiveExpertDetails(!showActivExpertDetails);
    }

    function handleCreateObr(expertObrObject) {
        setExpertObr(expertObrObject);
    }
    function handleShowSubmitForm() {
        setShowSubmitForm(!showSubmitForm);
    }
    function activeObrSelectionHandler(obr) {
        if (obr.galleryMediaPaths?.length > 0) {
            let mediaArray = filterUserObrRole === AppConstants.userRoleExpert ? obr.galleryMediaPaths : obr.galleryMediaAbsolutePaths;
            setCreatorGalleryContent(mediaArray?.split(",").map(value => ({
                url: value,
                type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[value.split('.').pop().toLowerCase()]) ? "Image" : "Video"
            })));
        } else {
            setCreatorGalleryContent([]);
        }

        SetSelectedActiveObr(obr);
    }
    function notActiveObrSelectionHandler(obr) {
        SetSelectedNotActiveObr(obr);
        if (obr.galleryMediaPaths?.length > 0) {
            let mediaArray = filterUserObrRole === AppConstants.userRoleExpert ? obr.galleryMediaPaths : obr.galleryMediaAbsolutePaths;
            setCreatorGalleryContent(mediaArray?.split(",").map(value => ({
                url: value,
                type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[value.split('.').pop().toLowerCase()]) ? "Image" : "Video"
            })));
        } else {
            setCreatorGalleryContent([]);
        }
    };

    function handleUserRoleChange(role) {
        setObrCreatedFor(role);
    }

    function handleObrUserRoleChange(role) {
        setFilterUserObrRole(role);
        setCreatorGalleryContent([]);
    }

    return (
        <div className={classes.expertOnBoardingContainer}>
            {/* <h1 className={classes.expertOnBoardingHeading}>{AppConstants.expertCreateObrHeading.onBoradRequestForm}</h1> */}
            {/* <AdminGetObrForEmailForm handleUserRoleChange={handleUserRoleChange} onCreateObr={handleCreateObr} showSubmitForm={showSubmitForm} onShowSubmitFormHandler={handleShowSubmitForm}></AdminGetObrForEmailForm> */}
            {/* {showSubmitForm && obrCreatedFor === AppConstants.userRoleExpert && <AdminSubmitObrForm obrCreatedFor={obrCreatedFor} expertObr={expertObr} countryList={countryList} stateList={stateList} onSubmitDetails={handleCreateObr}></AdminSubmitObrForm>}
            {showSubmitForm && obrCreatedFor === AppConstants.userRoleAgency && <AdminSubmitObrFormForAgency obrCreatedFor={obrCreatedFor} agencyObr={expertObr} countryList={countryList} stateList={stateList} onSubmitDetails={handleCreateObr}></AdminSubmitObrFormForAgency>} */}
            <div className={classes.userRoleBackground}>
                <Row>
                    <div className={classes.userRoleDropdown}>
                        <Form.Label className={formClass.formLabel}>User Role</Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name="userRole"
                            value={filterUserObrRole}
                            onChange={e => handleObrUserRoleChange(e.target.value)}
                        >
                            {AppConstants.obrUserRoles?.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </Row>
            </div>
            {(!showNotActiveExpertDetails && !showActivExpertDetails) && <h1 className={classes.expertOnBoardingHeading}>{AppConstants.expertCreateObrHeading.noActionTakenObr}</h1>}
            {(!showNotActiveExpertDetails && !showActivExpertDetails) && <AdminManageNotActiveObr userRole={filterUserObrRole} noActionAgencyObrList={noActionAgencyObrList} noActionObrList={noActionObrList} onObrSelection={notActiveObrSelectionHandler} onNotActiveShowDetails={showDetailsNotActiveHandler}></AdminManageNotActiveObr>}
            {showNotActiveExpertDetails && <h1 className={classes.expertOnBoardingHeading}>{AppConstants.expertCreateObrHeading.detailsObr}</h1>}
            {showNotActiveExpertDetails && selectedNotActiveObr.selectedUserRole === AppConstants.userRoleExpert && <ExpertObrDetails onNotActiveShowDetails={showDetailsNotActiveHandler} selectedObr={selectedNotActiveObr} isNotActiveSection={AppConstants.trueText}></ExpertObrDetails>}
            {showNotActiveExpertDetails && selectedNotActiveObr.selectedUserRole === AppConstants.userRoleAgency && <AgencyObrDetails onNotActiveShowDetails={showDetailsNotActiveHandler} selectedObr={selectedNotActiveObr} isNotActiveSection={AppConstants.trueText}></AgencyObrDetails>}
            {(!showActivExpertDetails && !showNotActiveExpertDetails) && <h1 className={classes.expertOnBoardingHeading}>{AppConstants.expertCreateObrHeading.actionTakenObr}</h1>}
            {(!showActivExpertDetails && !showNotActiveExpertDetails) && <AdminManageActiveObr userRole={filterUserObrRole} actionsAgencyObrList={actionsAgencyObrList} actionObrList={actionObrList} onActiveShowDetails={showDetailsActiveHandler} onObrSelection={activeObrSelectionHandler}></AdminManageActiveObr>}
            {showActivExpertDetails && <h1 className={classes.expertOnBoardingHeading}>{AppConstants.expertCreateObrHeading.detailsObr}</h1>}
            {showActivExpertDetails && selectedActiveObr.selectedUserRole === AppConstants.userRoleExpert && <ExpertObrDetails onActiveShowDetails={showDetailsActiveHandler} selectedObr={selectedActiveObr} creatorGalleryContent={creatorGalleryContent} isNotActiveSection={AppConstants.falseText}></ExpertObrDetails>}
            {showActivExpertDetails && selectedActiveObr.selectedUserRole === AppConstants.userRoleAgency && <AgencyObrDetails onActiveShowDetails={showDetailsActiveHandler} selectedObr={selectedActiveObr} creatorGalleryContent={creatorGalleryContent} isNotActiveSection={AppConstants.falseText}></AgencyObrDetails>}
        </div>
    )
}