import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import { ExpertMicrositeCoursesCards } from '../cards/expertMicrositeCards/ExpertMicrositeCoursesCards';
import { EnrolmentModal } from '../modal/EnrolmentModal';
import { AppConstants } from '../../constants/AppConstants';
import { PaymentsUtil } from "../../utils/PaymentsUtil.js";
import Auth from '../../utils/Auth';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { DateTimeUtil } from '../../utils/DateTimeUtil.js';
import { PostStartEnrolmentModal } from '../modal/PostStartEnrolmentModal.js';
import { StringUtils } from '../../utils/StringUtils.js';
import { PayOfflineConfirmationModal } from '../modal/PayOfflineConfirmationModal.js';
import { useEnrolment } from '../../hooks/useEnrolment.js';
import useWarnOnLeave from '../../hooks/useWarnOnLeave.js';

export const MicrositeCoursesSection = (props) => {
    const [coursesDetailsObj, setCoursesDetailsObj] = useState([]);
    const [courseId, setCourseId] = useState(AppConstants.emptyString);
    const [courseTitle, setCourseTitle] = useState(AppConstants.emptyString);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [courseUniqueId, setCourseUniqueId] = useState(AppConstants.emptyString);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [payloadData, setPayloadData] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);

    useEffect(() => {
        setCoursesDetailsObj(props.courseData)
    }, [props.courseData]);

    // verify payment
    useEffect(() => {
        const verifyPayment = async (payload, course) => {
            try {
                PaymentsUtil.verifyPayment(payload).then((response) => {
                    if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                        setPaymentStatus(AppConstants.paymentStatus.verified);
                        setTransactionId(response.transactionId);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        onClickEnroll(course.courseId, course.courseTitle, course.uniqueId, AppConstants.paymentStatus.verified);
                    } else {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(response.message);
                        setTransactionId(response.transactionId);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        handleModalShow();
                    }
                }).catch((error) => {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.verifyPaymentFailedError);
                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                    setPaymentStatus(AppConstants.emptyString)
                    handleModalShow();
                });
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        };


        if (paymentParam && coursesDetailsObj.length > 0) {
            const payload = StringUtils.decodeBase64EncodedString(paymentParam);
            if (payload !== null && payload.category === props.category) {
                const course = coursesDetailsObj.filter(card => card.uniqueId === payload.uniqueId)[0];
                if (!course) {
                    setPaymentStatus(AppConstants.emptyString);
                    setPayloadData({});
                    return;
                }
                payload.learningActivityName = course.courseTitle;
                setPayloadData(payload);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setCourseTitle(course.courseTitle);
                setCourseId(course.courseId);
                setCourseUniqueId(course.uniqueId);

                const timer = setTimeout(() => {
                    verifyPayment(payload, course);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }

    }, [paymentParam, coursesDetailsObj]);


    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag, creatorName, creatorRole });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            if (startTime === AppConstants.nullText) {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    onClickEnroll(id, title, uniqueId);
                }
            } else {
                const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
                const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const currentDateTime = DateTime.local().setZone(timeZone);
                // check if the learning activity has started.
                if (currentDateTime > startDateTime) {
                    // show confirmation modal to the user.
                    setShowConfirmationModal(AppConstants.trueText);
                } else {
                    if (payOfflineFlag === AppConstants.yText) {
                        setShowOfflinePaymentModal(AppConstants.trueText);
                    } else {
                        onClickEnroll(id, title, uniqueId);
                    }
                }
            }
        } else {
            onClickEnroll(id, title, uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                onClickEnroll(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);

            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            onClickEnroll(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };


    const handleModalClose = () => {
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                if (modalTypeError) {
                    setShowModal(false);
                    setConfirmationPopupActivityDetails({});
                } else {
                    window.location.reload();
                }
            }
        }
    };
    const handleModalShow = () => {
        setShowModal(true);
    };

    //enroll to courses
    const onClickEnroll = async (courseId, courseTitle, courseUniqueId, status) => {

        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.onlyStudentEnrolCourseError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return null;
        }

        const course = coursesDetailsObj.filter(card => card.uniqueId === courseUniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });
        let response = await enrolInLearningActivity({
            uniqueId: courseUniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseType === AppConstants.flexibleCourseType && course.courseEnrolledOnDate !== null ? course.trackingType : AppConstants.falseText
        }, status)


        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        onClickEnroll(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    onClickEnroll(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    return (
        <>
            <div>
                {coursesDetailsObj.length !== 0 &&
                    <ExpertMicrositeCoursesCards
                        userRoleForMicrosite={props.userRoleForMicrosite}
                        courseData={coursesDetailsObj}
                        onClickEnroll={onClickEnroll}
                        confirmBeforeEnrolment={confirmBeforeEnrolment}
                    />}
            </div>
            {(message !== AppConstants.emptyString || payloadData.category === props.category) && (showModal || payloadData.category === props.category) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} enrolInCourse={onClickEnroll} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}
