import { AppConstants } from "../constants/AppConstants";
import Auth from "../utils/Auth";

export const Logout = {
    logout() {
        const apiURL = process.env.REACT_APP_API_URL;
        const reactAppUrl = process.env.REACT_APP_API_URL;
        const logoutUrl = `${apiURL}` + AppConstants.logoutApi;
        const token = Auth.getAuthToken();
        const refreshToken = (JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails))?.refreshToken) ? JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails))?.refreshToken : null;
        fetch(logoutUrl, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'refresh_token': refreshToken
            }
        })
            .then((data) => {
                if (data.ok) {
                    localStorage.removeItem(AppConstants.sessionKeyAccesToken);
                    localStorage.removeItem(AppConstants.sessionKeyUserDetails);
                    localStorage.clear();
                    window.location.href = reactAppUrl;
                    window.location.reload();
                }
                else {
                    localStorage.clear();
                    window.location.href = reactAppUrl;
                    window.location.reload();
                }
            }).catch((error) => {
                localStorage.clear();
                window.location.href = reactAppUrl;
                window.location.reload();
            });
    },

    async updateAccessToken() {
        const username = JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails)).userEmail;
        const refreshToken = (JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails)).refreshToken) ? JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails)).refreshToken : null;
        const apiURL = process.env.REACT_APP_API_URL;
        const token = Auth.getAuthToken();
        const refreshTokenUrl = `${apiURL}` + AppConstants.refreshTokenApi + encodeURIComponent(username);
        const response = await fetch(refreshTokenUrl, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'refresh_token': refreshToken
            }
        });
        if (!response.ok) {
            this.logout();
            return false;
        }
        else {
            const accessTokenDetails = await response.json();
            const userDetails = JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails));
            userDetails.accessToken = accessTokenDetails.accessToken;
            localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(userDetails));
            localStorage.setItem(AppConstants.sessionKeyAccesToken, accessTokenDetails.accessToken);
            return true;
        }
    }
};