import { useState, useEffect, useMemo } from "react";
import parse from 'html-react-parser';
import classes from './LandingPageCategoryWiseSearchSkill.module.css';
import { LandingPageSkillCards } from '../cards/LandingPageSkillCards';
import { SkillCategoryTabs } from '../lists/SkillCategoryTabs';
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';

export const LandingPageCategoryWiseSearchSkill = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const lightBlueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.lightBlueTriangleImagePath}`;
    const [searchCategoryWiseSkillSectionDetails, setSearchCategoryWiseSkillSectionDetails] = useState(props.searchCategoryWiseSkillsSectionDetails);
    const [qrenciaCategoryWiseSkillsDetails, setQrenciaCategoryWiseSkillsDetails] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [categoryList, setCategoryList] = useState([]);
    var filteredList = useMemo(getFilteredList, [selectedCategory, qrenciaCategoryWiseSkillsDetails]);

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            var data = new Map();
            for (let obj of response) {
                data.set(obj.categoryId, obj.category);
            }
            setCategoryList([...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId));
            setQrenciaCategoryWiseSkillsDetails(response);
        }
    }, []);

    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    }

    // Function to get filtered list
    function getFilteredList() {
        if (!selectedCategory) {
            return qrenciaCategoryWiseSkillsDetails.filter(item => item.category === categoryList[0]).sort((a, b) => {
                if (a.isTrending === b.isTrending) {
                  return a.skill.localeCompare(b.skill); // Alphabetical sorting within the same trending status
                }
                return a.isTrending === "Y" ? -1 : 1; // Trending skills first
              });
        }
        return qrenciaCategoryWiseSkillsDetails.filter(item => item.category === selectedCategory).sort((a, b) => {
            if (a.isTrending === b.isTrending) {
              return a.skill.localeCompare(b.skill); // Alphabetical sorting within the same trending status
            }
            return a.isTrending === "Y" ? -1 : 1; // Trending skills first
          });
    }

    return (
        <>
            <div className={classes.searchCategoryWiseSkillSection}>
                <img
                    className={classes.blueTriangleImage}
                    src={lightBlueTriangleImageUrl}
                />
                <h1 className={classes.heading}>{parse(searchCategoryWiseSkillSectionDetails.title)}</h1>
                <p className={classes.subHeading}>{parse(searchCategoryWiseSkillSectionDetails.subTitle)}</p>
                <SkillCategoryTabs categoryList={categoryList} onSelection={categorySelectionHandler} />
                <LandingPageSkillCards skillsOfCategory={filteredList}/>
                <div>
                    {filteredList.length != 0 && <Link to={`${AppConstants.skills}${AppConstants.forwardSlash}${filteredList[0].categorySlug}`}><Button className={classes.exploreMoreButton}>{searchCategoryWiseSkillSectionDetails.buttonText}</Button></Link>}
                    {filteredList.length == 0 && <Link to={AppConstants.skills}><Button className={classes.exploreMoreButton}>{searchCategoryWiseSkillSectionDetails.buttonText}</Button></Link>}
                </div>
            </div>
        </>
    );
}