import { StudentUpcomingCompetitionsWidget } from '../../component/widgets/StudentUpcomingCompetitionsWidget';
import classes from './TeamRightWidgetSpace.module.css';
import { StudentOfflinePaymentRequestsWidget } from '../../component/widgets/StudentOfflinePaymentRequestsWidget';
import { AppConstants } from '../../constants/AppConstants';
export const TeamRightWidgetSpace = () => {
    return (
        <>
            <div className={classes.widgetSection}>
                <StudentOfflinePaymentRequestsWidget teamEnrolmentEnabledFlag={AppConstants.yText} />
                <StudentUpcomingCompetitionsWidget />
            </div>
        </>
    )
}