import { AppConstants } from "../../constants/AppConstants";
import classes from "./QrenciaLoader.module.css";

export const QrenciaLoader = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    return <div className={classes.preLoaderContainer} >
        <div className={classes.preLoaderDiv}>
            <div className={classes.loader}></div>
            <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
            {props.loaderText && <p className={classes.loaderText}>{props.loaderText}</p>}
        </div>
    </div >
}