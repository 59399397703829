import { useState, useEffect } from 'react';
import { AppConstants } from '../../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../../services/AuthorizedDataService';
import { StudentWorkshopCards } from '../../../cards/studentWorkshopCards/StudentWorkshopCards';

export const StudentRecentlyPublishedWorkshops = (props) => {
    const [workshops, setWorkshops] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        async function fetchRecentlyPublishedWorkshops() {
            const url = AppConstants.getRecentlyPublishedWorkshopsForStudentAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                setWorkshops(response);
                setFilteredList(response);
            }
        }

        if (props.categoryList?.length > AppConstants.zeroIndex) {
            fetchRecentlyPublishedWorkshops();
        }

    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(workshops);
            } else {
                setFilteredList(workshops.filter(workshop => workshop.categoryName === props.selectedCategory));
            }
        } else {
            setFilteredList(workshops);
        }
    }, [props.selectedCategory]);

    return (
        <div>
            {filteredList?.length > AppConstants.zeroIndex && <StudentWorkshopCards category="Recently Published" workshopData={filteredList} />}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No recently published workshops for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className='noDataAvailableError'>No recently published workshops to display at the moment</p>}
        </div>
    )
}