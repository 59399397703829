import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { AppConstants } from '../../constants/AppConstants';
import classes from "./PostStartEnrolmentModal.module.css";

export const PostStartEnrolmentModal = ({ updateConfirmationStatus, learningActivityDetails }) => {
    return (
        <Modal show={true} onHide={() => updateConfirmationStatus(AppConstants.falseText)} backdrop="static" keyboard={false} centered>
            <Modal.Body className={modalClass.modalBody}>
                {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                <div className={modalClass.modalAlertText}>
                    <p><b>{learningActivityDetails.id} | {learningActivityDetails.title}</b></p>
                    <p>This {learningActivityDetails.type?.toLowerCase()} has already started. Are you sure you want to enrol in this {learningActivityDetails.type?.toLowerCase()}?</p>
                </div>
            </Modal.Body>
            <Modal.Footer className={modalClass.modalFooter}>
                {<Button className={classes.modalNoButton} onClick={() => updateConfirmationStatus(AppConstants.falseText, learningActivityDetails.type)}>No</Button>}
                {<Button className={classes.modalYesButton} onClick={() => updateConfirmationStatus(AppConstants.trueText, learningActivityDetails.type)}>Enrol</Button>}
            </Modal.Footer>
        </Modal>
    );
};
