
import { Card, Col, Row } from "react-bootstrap";
import classes from "./NudgeOfferingsCards.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export const NudgeOfferingsCards = (props) => {
    const cardCount = 6;

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const competitionDefaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const workshopDefaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.workshopDefaultThumbnailImage}`;
    const courseDefaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;


    const handleCardClick = (offering) => {
        const learningActivityType = offering.learningActivityType;
        window.open(`${process.env.REACT_APP_FRONT_END_URL}${learningActivityType === AppConstants.learningActivitiesTypes.competition ? AppConstants.competitionsText : learningActivityType === AppConstants.learningActivitiesTypes.course ? AppConstants.coursesText : AppConstants.workshopsText}${AppConstants.forwardSlash}${offering.learningActivityLink}`, AppConstants.openInNewTab);
    };

    const getDefaultThumbnail = (learningActivityType) => {
        return learningActivityType === AppConstants.learningActivitiesTypes.competition ? competitionDefaultThumbnail
            : learningActivityType === AppConstants.learningActivitiesTypes.course ? courseDefaultThumbnail : workshopDefaultThumbnail;
    };

    return (
        <Row xs={2} sm={3} md={3} lg={3} xl={3} className={classes.cardsSection}>
            {props.cardDetails?.slice(0, cardCount).map(offering => (
                <Col key={offering.learningActivityLink}>
                    <Card className={classes.card} onClick={() => handleCardClick(offering)}>
                        <Card.Img src={offering.learningActivityThumbnailImage || getDefaultThumbnail(offering.learningActivityType)} className={classes.thumbnailImage} />
                        <Card.Body className={classes.cardBody}>
                            <h1 className={classes.title}>
                                <Link className={`${classes.linkedText}  ${classes.singleLine}`} data-tooltip-id={`${offering.learningActivityLink}-title`}>{offering.learningActivityName}</Link>
                                <Tooltip className="courseTitleTooltip" id={`${offering.learningActivityLink}-title`}><span>{offering.learningActivityName}</span></Tooltip>
                            </h1>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}