import { Modal, Accordion, Row, Col, Button } from "react-bootstrap"
import { AppConstants } from "../../../constants/AppConstants";
import { useState } from "react";
import { DateTime } from "luxon";
import classes from "./DateFilterComponent.module.css";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const DateFilterComponent = ({ onDateSelectionHandle, handleDateSelectionModalClose, dateFilterDisplayValue }) => {
    const presets = AppConstants.dateFilterPresets;
    const [toDate, setToDate] = useState(AppConstants.emptyString);
    const [fromDate, setFromDate] = useState(AppConstants.emptyString);
    const [dateFilterError, setDateFilterError] = useState(AppConstants.emptyString);

    useState(() => {
        const presetValues = Object.values(presets).map(item => item.label);
        if (dateFilterDisplayValue && !presetValues?.includes(dateFilterDisplayValue)) {
            const dateArr = dateFilterDisplayValue.split(" ");
            if (dateArr.length === 2 && dateArr[0] === AppConstants.fromText) {
                setFromDate(dateArr[1]);
            } else if (dateArr.length === 2 && dateArr[0] === AppConstants.toText) {
                setToDate(dateArr[1]);
            } else if (dateArr.length === 3) {
                setToDate(dateArr[2]);
                setFromDate(dateArr[0]);
            }
        }
    }, []);

    const onSelectDate = () => {
        let fromDateString = AppConstants.emptyString;
        let toDateString = AppConstants.emptyString;
        let displayValue = AppConstants.emptyString;
        if (fromDate) {
            fromDateString = DateTime.fromISO(fromDate).toJSDate();
        }

        if (toDate) {
            toDateString = DateTime.fromISO(toDate).endOf("day").toJSDate();
        }

        if (fromDate && toDate) {
            displayValue = `${fromDate} to ${toDate}`;
            if (fromDate > toDate) {
                setDateFilterError(ErrorMessages.invalidDateRangeError);
                let timer = setTimeout(() => {
                    setDateFilterError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else if (fromDate && !toDate) {
            displayValue = `${AppConstants.fromText} ${fromDate}`
        } else if (!fromDate && toDate) {
            displayValue = `${AppConstants.toText} ${toDate}`
        } else {
            displayValue = presets.allTime.label
        }

        onDateSelectionHandle({ fromDateString, toDateString, displayValue });
    };

    const selectPresetDate = (presetValue) => {
        const currentDate = DateTime.now();
        let startDate = AppConstants.emptyString;
        let endDate = AppConstants.emptyString;
        let fromDateString = AppConstants.emptyString;
        let toDateString = AppConstants.emptyString;
        let displayValue = presetValue.label;

        if (presetValue.key === presets.today.key) {
            startDate = currentDate.startOf("day").toISODate();
            endDate = currentDate.endOf("day").toISO();
        } else if (presetValue.key === presets.weekToDate.key) {
            startDate = currentDate.startOf('week').toISODate();
            endDate = currentDate.endOf("day").toISO();;
        } else if (presetValue.key === presets.monthToDate.key) {
            startDate = currentDate.startOf('month').toISODate();
            endDate = currentDate.endOf("day").toISO();
        } else if (presetValue.key === presets.yearToDate.key) {
            startDate = currentDate.startOf('year').toISODate();
            endDate = currentDate.endOf("day").toISO();
        } else if (presetValue.key === presets.previousMonth.key) {
            startDate = currentDate.minus({ months: 1 }).startOf('month');
            endDate = currentDate.minus({ months: 1 }).endOf('month');
        } else if (presetValue.key === presets.previousYear.key) {
            startDate = currentDate.minus({ years: 1 }).startOf('year');
            endDate = currentDate.minus({ years: 1 }).endOf('year');
        } else if (presetValue.key === presets.lastThreeMonths.key) {
            startDate = currentDate.minus({ months: 3 }).set({ day: currentDate.day });
            endDate = currentDate.endOf("day").toISO();
        } else if (presetValue.key === presets.lastSixMonths.key) {
            startDate = currentDate.minus({ months: 6 }).set({ day: currentDate.day });
            endDate = currentDate.endOf("day").toISO();
        }

        if (startDate) {
            fromDateString = DateTime.fromISO(startDate).toJSDate();
        }

        if (endDate) {
            toDateString = DateTime.fromISO(endDate).toJSDate();
        }

        onDateSelectionHandle({ fromDateString, toDateString, displayValue });
    };

    return (
        <Modal size="md" show={AppConstants.trueText} onHide={handleDateSelectionModalClose} keyboard={false} centered>
            <div className="expertCompDetailsAccordion">
                <Modal.Body className={classes.modalBody}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Presets</Accordion.Header>
                            <Accordion.Body>
                                <div className="row expertAccordionContentSection">
                                    <Row>
                                        {Object.values(presets).map(item => (
                                            <Col key={item.key} xs={12} sm={6} md={4} lg={4} xl={4}>
                                                <div className={classes.datePresets}>
                                                    <span onClick={() => selectPresetDate(item)} className={classes.datePresetLabel}>{item.label}</span>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Custom</Accordion.Header>
                            <Accordion.Body>
                                <div className="row expertAccordionContentSection">
                                    <Col xs={12} sm={6} md={6} lg={1} xl={1}>
                                        <span className={classes.fromText}>From</span>
                                    </Col>
                                    <Col className={classes.expertObrFormInputBox} xs={12} sm={6} md={6} lg={5} xl={5}>
                                        <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={1} xl={1}>
                                        <span className={classes.fromText}>To</span>
                                    </Col>

                                    <Col className={classes.expertObrFormInputBox} xs={12} sm={6} md={6} lg={5} xl={5}>
                                        <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                    </Col>
                                    {dateFilterError && <ErrorSuccessAlertMessage message={dateFilterError} varient={AppConstants.alertVarient[1]} />}
                                </div>
                                <div className={classes.buttonSection}>
                                    <Button className={classes.customDateSelectButton} onClick={onSelectDate}>Select</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>
            </div>
        </Modal>
    )
}