import { useEffect, useState } from 'react';
import { Collapse } from "react-bootstrap";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";
import { AppConstants } from "../../constants/AppConstants";
import './widget.css';
import { StudentWidgetNudgesCards } from '../cards/StudentWidgetNudgesCards';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
export const StudentNudgesWidget = () => {
    const [open, setOpen] = useState(true);
    const [nudgeWidgetData, setNudgeWidgetData] = useState([]);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        async function fetchMyActivities() {
            const url = `${AppConstants.studentNudgesForWidgetAPI}`;
            const requestHeader = {
                preferredTimezone,
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeader
            );
            if (response) {
                setNudgeWidgetData(response);
            } else {
                setNudgeWidgetData([]);
            }
        }
        fetchMyActivities();
    }, []);

    return (
        <>
            <div className="quickLinkSection">
                <div className="widgetSection">
                    <div className="headingSection">
                        <h1 className="widgetHeading">{AppConstants.nudgesText}</h1>
                    </div>
                    <div className="iconSection">
                        {!open && <HiOutlinePlusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                        {open && <HiOutlineMinusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                    </div>
                </div>
                <Collapse in={open}>
                    <div id="studentNudgeWidget">
                        <div className="collapseDiv">
                            {nudgeWidgetData && nudgeWidgetData?.length !== 0 && <StudentWidgetNudgesCards cardDetails={nudgeWidgetData} />}
                            {(nudgeWidgetData?.length === 0) && <div className="noActivityAvailableSection">You have not received any response regarding the raised nudges!</div>}
                        </div>
                    </div>
                </Collapse>
            </div>
        </>
    )
}