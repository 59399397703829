import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { CiCircleRemove } from "react-icons/ci";

import { AppConstants } from '../constants/AppConstants';

export const DragDropFile = ({
    classes,
    headingText,
    getSelectedFile,
    maxFileSizeInBytes,
    isDisabled = false,
    defaultFile,
    selectedCompetitionId,
    editFlag,
    allowedTypes = AppConstants.asterisk }) => {

    /*
        getSelectedFile is a method which accepts the 'selectedFile' state variable as argument and passes it back to parent component.
        allowedTypes is comma separated list of file types that to be uploaded. e.g. 'image/png, image/jpeg'
    */

    const [selectedFile, setSelectedFile] = useState(defaultFile || AppConstants.nullText);
    const [error, setError] = useState(AppConstants.emptyString);


    useEffect(() => {
        if (!editFlag) {
            setSelectedFile(defaultFile);
        }
    }, [defaultFile]);

    useEffect(() => {
        if (!editFlag) {
            setSelectedFile(AppConstants.nullText);
        }
    }, [selectedCompetitionId]);


    const onFileDrop = (e) => {
        let file = e.target.files[0];

        if (null !== file) {
            if (file.size < maxFileSizeInBytes) {
                setSelectedFile(e.target.files[0]);
                setError(AppConstants.emptyString);
                getSelectedFile(e.target.files[0]);
            } else {
                setSelectedFile(AppConstants.nullText)
                getSelectedFile(AppConstants.nullText);
                setError(`Please upload file less than ${maxFileSizeInBytes / (1024 * 1024)} MB`)
            }
        }
    }

    const removeSelectedFile = () => {
        setSelectedFile(AppConstants.nullText)
        getSelectedFile(AppConstants.nullText);
        setError(AppConstants.emptyString);
    }

    return (
        <div className={classes}>
            <h3>{headingText}</h3>
            <AiOutlineCloudUpload />
            {!selectedFile && error === "" ? <p>Click/Drop to upload your files here</p> :
                selectedFile && error === "" ?
                    <p className="dropFileName" style={{ textAlign: "center", zIndex: "1000" }}>File selected: <span style={{ color: "#239B56" }}>{selectedFile.name}</span>
                        {!isDisabled && <CiCircleRemove style={{ cursor: "pointer", height: "1.2em", width: "1.2em", color: "red" }} onClick={removeSelectedFile} />}
                    </p> :
                    <p className="dropFileError" style={{ textAlign: "center" }}><span style={{ color: "red" }}>{error}</span>Click to upload again</p>}
            <input disabled={isDisabled} type="file" value="" accept={allowedTypes} onChange={onFileDrop} />
        </div>
    )
};