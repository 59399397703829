import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FaChalkboardTeacher, FaRegCalendarCheck } from 'react-icons/fa';
import { BiCalendarX, BiLinkExternal } from 'react-icons/bi';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { TbRefreshAlert } from 'react-icons/tb';
import { BiDownload } from 'react-icons/bi';
import { MdOutlineLeaderboard, MdShareLocation } from 'react-icons/md';
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import classes from './StudentCompetitionWidgetCards.module.css';
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import 'moment-timezone';
import { Tooltip } from "react-tooltip";
import moment from "moment-timezone";
import { AiOutlineClockCircle } from "react-icons/ai";
import { LiaCommentsSolid } from "react-icons/lia";
import { CourseFeedbackModal } from "../modal/CourseFeedbackModal";
import Auth from "../../utils/Auth";
import { TeamEnrolmentDetailsModal } from "../modal/teamEnrolmentDetailsModal/TeamEnrolmentDetailsModal";
import { CiViewList } from "react-icons/ci";
import { DateTime } from "luxon";
import { TeamEnrolmentForm } from "../formControls/teamEnrolmentForm/TeamEnrolmentForm";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { EnrolInCompetition } from "../studentPageComponents/EnrolInCompetition";
import { EnrolmentModal } from "../modal/EnrolmentModal";

export const StudentHomeTodaysActivitiesCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [downloadFailed, setDownloadFailed] = useState(AppConstants.falseText);
    const [cardCount, setCardCount] = useState(0);
    const [learningActivities, setLearningActivities] = useState(props.todaysActivity)
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [showFeedbackModal, setShowFeedbackModal] = useState(AppConstants.falseText);
    const [selectedLearningActivity, setSelectedLearningActivity] = useState({});
    const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [showTeamEnrolmentForm, setShowTeamEnrolmentForm] = useState(AppConstants.falseText);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);

    const learnerDetails = [{
        studentEmail: Auth.getLoggedInUserDetails().userEmail,
        studentName: Auth.getLoggedInName()
    }];

    useEffect(() => {
        setLearningActivities(props.todaysActivity);
    }, [props.todaysActivity]);


    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(AppConstants.cardCounts.todaysActivityDesktopCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.todaysActivityDesktopCardsCount)
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(AppConstants.cardCounts.todaysActivityTabCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.todaysActivityTabCardsCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(AppConstants.cardCounts.todaysActivityMobileCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.todaysActivityMobileCardsCount)
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    //download  pre-read
    async function downloadPreRead(fileUrl, title, fileName) {
        setDownloadFailed(AppConstants.falseText);
        try {
            FileOperationsUtil.downloadDocument(fileUrl, title, fileName);
        } catch (error) {
            setDownloadFailed(AppConstants.trueText);
        }
    };

    const showSubmissionText = (competition) => {
        let currentDate = moment(new Date());
        let competitionDate = DateTimeUtil.utcToPreferredTimezone(competition.startDateTimeUTC);
        var duration = moment.duration(currentDate.diff(competitionDate));
        var hours = duration.asHours();

        if (hours > AppConstants.submissionStartedWindowInHrs && competition.allowSubmissionFlag === AppConstants.yText) {
            return {
                showSubmission: AppConstants.trueText,
                showGetStartedText: AppConstants.falseText,
                live: AppConstants.trueText
            }
        } else if (hours > 0) {
            return {
                showSubmission: AppConstants.falseText,
                showGetStartedText: AppConstants.trueText,
                live: AppConstants.trueText
            }
        } else {
            return {
                showSubmission: AppConstants.falseText,
                showGetStartedText: AppConstants.falseText,
                live: AppConstants.falseText
            }
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const openFeedbackModal = (e, learningActivity) => {
        e.stopPropagation();
        setSelectedLearningActivity({
            learningActivityUniqueId: learningActivity.uniqueId,
            userImagePath: learningActivity.expertImagePath,
            organizedBy: learningActivity.organizedBy,
            learningActivityName: learningActivity.courseTitle
        })
        setShowFeedbackModal(AppConstants.trueText);
    };

    const closeFeedbackModal = () => {
        setSelectedLearningActivity({});
        setShowFeedbackModal(AppConstants.falseText);
    };

    const showTeamDetails = (e, learningActivity) => {
        e.stopPropagation();
        setSelectedLearningActivity({ uniqueId: learningActivity.uniqueId });
        setShowTeamDetailsModal(AppConstants.trueText);
    };

    const closeTeamDetailsModal = () => {
        setSelectedLearningActivity({});
        setShowTeamDetailsModal(AppConstants.falseText);
    };

    const enrolInCompetition = async () => {
        setShowTeamEnrolmentForm(AppConstants.trueText);
        return;
    };

    const seatsFilledConfirmation = async (uniqueId, teamsLimit) => {
        const url = `${AppConstants.checkTeamEnrolmentEligibilityAPI}?competitionUniqueId=${uniqueId}`;
        const response = await AuthorizedDataService.getRequestWithResponseCode(url, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            if (response && response.status === 500) {
                const data = await response.json();
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.emptyString);
                setMessage(EnrolInCompetition.getEnrolmentStatusForTeamCompetitions({ ...data, message: 0 }, null));
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
    const confirmBeforeEnrolment = async ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole, cost, teamParticipantsLimit, teamsLimit }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.competition, payOfflineFlag, creatorName, creatorRole, cost, teamParticipantsLimit, teamsLimit });
        if (Auth.getUserRole() === AppConstants.userRoleTeam) {
            let teamEligibility = await seatsFilledConfirmation(uniqueId, teamsLimit);
            if (!teamEligibility) {
                return;
            }
        }
        const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
        const currentDateTime = DateTime.local().setZone(timeZone);
        if (currentDateTime > startDateTime) {
            setShowConfirmationModal(AppConstants.trueText);
        } else {
            enrolInCompetition();
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        if (status) {
            enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const hideTeamEnrolmentForm = () => {
        setShowTeamEnrolmentForm(AppConstants.falseText);
    };
    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };
    return (
        <div className={classes.todaysActivityContainer}>
            <Row xs={1} sm={1} md={1} lg={2} xl={2}>
                {learningActivities?.slice(0, cardCount).map((learningActivity) => (
                    <Col className={classes.learningActivityCardSection} key={learningActivity.uniqueId}>
                        {learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && <Card className={classes.learningActivityCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${learningActivity.categorySlug}${AppConstants.forwardSlash}${learningActivity.skillSlug}${AppConstants.forwardSlash}${learningActivity.competionSlug}`)}>
                            <Card.Body className={classes.learningActivityCardBody}>
                                <Card.Text className={`cardTitle titleFixedHeight  ${classes.learningActivityTitle}`}>
                                    <Link className="cardLink" data-tooltip-id={`${learningActivity.competionSlug}-title-todays`} >{learningActivity.title}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${learningActivity.competionSlug}-title-todays`}><span>{learningActivity.title}</span></Tooltip>
                                </Card.Text>
                                <div>
                                    {learningActivity.clubQrenciaFlg === AppConstants.yText && <div className={classes.dateTimeSection}>
                                        <FaChalkboardTeacher className={classes.orangeCalendarIcon} /><div className={classes.dateTimeText}>School</div></div>}
                                    <div className={classes.dateTimeSection}>
                                        <FaRegCalendarCheck className={classes.orangeCalendarIcon} /> <div className={classes.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>
                                    <div className={classes.dateTimeSection}>
                                        <BiCalendarX className={`${classes.orangeCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${learningActivity.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${learningActivity.userSlug}`) }} className={classes.dateTimeSection}>By : {learningActivity.organizedBy}</div>
                                <Tooltip className={classes.tooltipMessage} id={`${learningActivity.id}-mySchool`}><span className={classes.tooltipMessage}>My School Competition</span></Tooltip>
                                <div>
                                    {learningActivity.type.toUpperCase() === AppConstants.offlineText && <div>
                                        <a rel="noreferrer" onClick={stopPropagation} className={classes.competitionCardLink} href={learningActivity.geoLocation} target="_blank"><span data-tooltip-place="top" data-tooltip-id={learningActivity.uniqueId} className={classes.widgitLocationSpan}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Venue Map</span></a> {learningActivity.guidelinesDocumentPath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.guidelinesDocumentPath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.guidelinesDocumentPath, learningActivity.title, AppConstants.guidelinesFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.guidelinesDocumentPath, learningActivity.title, AppConstants.guidelinesFile) }} />}</a>}
                                    </div>}
                                    <Tooltip className={classes.tooltipMessage} id={learningActivity.uniqueId}><span className={classes.tooltipMessage}>{learningActivity?.venueLocation}</span></Tooltip>
                                    {learningActivity.type.toUpperCase() === AppConstants.onlineText && <div>
                                        <p className={classes.competitionOnlineModeText}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Online</p> {learningActivity.guidelinesDocumentPath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.guidelinesDocumentPath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.guidelinesDocumentPath, learningActivity.title, AppConstants.guidelinesFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.guidelinesDocumentPath, learningActivity.title, AppConstants.guidelinesFile) }} />}</a>}
                                    </div>}
                                    {Auth.getUserRole() === AppConstants.userRoleTeam && <p onClick={(e) => showTeamDetails(e, learningActivity)} className={classes.competitionCardLink}><CiViewList /> View Team Details</p>}
                                    {showSubmissionText(learningActivity).showGetStartedText && <div onClick={e => e.stopPropagation()} className={classes.falshText}><a href={`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${learningActivity.categorySlug}${AppConstants.forwardSlash}${learningActivity.skillSlug}${AppConstants.forwardSlash}${learningActivity.competionSlug}`} target={AppConstants.openInNewTab}>{showSubmissionText(learningActivity).live && <span><span className={classes.liveCompetition}>Live</span> <span className={classes.linkSeperator}>|</span></span>} <BiLinkExternal /> Click here to get started</a> {learningActivity.awardType !== AppConstants.nullText && <> <span className={classes.linkSeperator}>|</span> <a target={AppConstants.openInNewTab} href={`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.scoreboardText}${AppConstants.forwardSlash}${learningActivity.competionSlug}`}><MdOutlineLeaderboard /> Scoreboard</a></>}</div>}
                                    {showSubmissionText(learningActivity).showSubmission && <div onClick={e => e.stopPropagation()} className={classes.falshText}><a href={`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${learningActivity.categorySlug}${AppConstants.forwardSlash}${learningActivity.skillSlug}${AppConstants.forwardSlash}${learningActivity.competionSlug}`} target={AppConstants.openInNewTab}>{showSubmissionText(learningActivity).live && <span> <span className={classes.liveCompetition}>Live</span> <span className={classes.linkSeperator}>|</span></span>} <BiLinkExternal /> Click here to submit</a> {learningActivity.awardType !== AppConstants.nullText && <> <span className={classes.linkSeperator}>|</span> <a target={AppConstants.openInNewTab} href={`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.scoreboardText}${AppConstants.forwardSlash}${learningActivity.competionSlug}`}><MdOutlineLeaderboard /> Scoreboard</a></>}</div>}
                                    {Auth.getUserRole() === AppConstants.userRoleTeam && <Button type="button" className={`mt-2 ${classes.addTeamButton}`} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: learningActivity.competitionId, title: learningActivity.title, uniqueId: learningActivity.uniqueId, startTime: learningActivity.startDateTimeUTC, payOfflineFlag: learningActivity.payOfflineFlag, creatorName: learningActivity.organizedBy, creatorRole: learningActivity.userRole, cost: learningActivity.cost, teamParticipantsLimit: learningActivity.teamParticipantsLimit, teamsLimit: learningActivity.teamsLimit }) }}>Add New Team</Button>}

                                </div>
                            </Card.Body>
                        </Card>}
                        {learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && <Card className={classes.learningActivityCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${learningActivity.skillCategorySlug}${AppConstants.forwardSlash}${learningActivity.skillSlug}${AppConstants.forwardSlash}${learningActivity.courseSlug}`)}>
                            <Card.Body className={`${classes.learningActivityCardBody} ${classes.courseCardBody}`}>
                                <Card.Text className={`cardTitle titleFixedHeight  ${classes.learningActivityTitle}`}>
                                    <Link className="cardLink" data-tooltip-id={`${learningActivity.courseSlug}-title-todays`}>{learningActivity.courseTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${learningActivity.courseSlug}-title-todays`}><span>{learningActivity.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {learningActivity.courseType === AppConstants.structuredCourseType && <div>
                                    <div className={classes.dateTimeSection}>
                                        <FaRegCalendarCheck className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(learningActivity.startTime)} {learningActivity.preferredTimezone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>
                                    <div className={classes.dateTimeSection}>
                                        <BiCalendarX className={`${classes.blueCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(learningActivity.endTime)} {learningActivity.preferredTimezone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>
                                </div>}
                                {learningActivity.courseType === AppConstants.flexibleCourseType && <div>
                                    <div className={classes.dateTimeSection}>
                                        <AiOutlineClockCircle className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}><span className="competitionDateTimeFormat">{`${DateTimeUtil.convertUtcTimeToLocalTime(learningActivity.windowStartTime)} to ${DateTimeUtil.convertUtcTimeToLocalTime(learningActivity.windowEndTime)}`} {timeZone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`} {learningActivity.reviewCount >= props.minimumReviewCount && `| ${learningActivity.reviewCount} ${learningActivity.reviewCount > 1 ? "Reviews" : "Review"}`}</span></div>
                                    </div>
                                    {learningActivity.trackingType === AppConstants.classBasedTrackingText && <div className={classes.dateTimeSection}>
                                        <FaChalkboardTeacher className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}><span className="competitionDateTimeFormat">{learningActivity.remainingClasses} {learningActivity.remainingClasses > 1 ? "classes" : "class"} remaining</span></div>
                                    </div>}
                                    {learningActivity.trackingType === AppConstants.classBasedTrackingText && learningActivity.subscriptionEndDate && <div className={classes.dateTimeSection}>
                                        <BiCalendarX className={`${classes.blueCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>Valid till: &nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.subscriptionEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.subscriptionEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>}
                                    {learningActivity.trackingType === AppConstants.durationBasedTrackingText && <div className={classes.dateTimeSection}>
                                        <BiCalendarX className={`${classes.blueCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.subscriptionEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.subscriptionEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span></div>
                                    </div>}
                                </div>}
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${learningActivity.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${learningActivity.userSlug}`) }} className={classes.dateTimeSection}>By : {learningActivity.organizedBy}</div>
                                <div>
                                    {learningActivity.modeOfDelivery === AppConstants.modeOfDelivery[0] && <div>
                                        {learningActivity.sessionLink !== null ?
                                            <a rel="noreferrer" onClick={stopPropagation} className={classes.competitionCardLink} href={learningActivity.sessionLink} target="_blank"><MdShareLocation className={classes.widgetCardsLocationIcon} /> Session Link</a>
                                            : <p className={classes.courseOnlineModeText}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Online</p>}
                                        {learningActivity.courseDescriptionDocumentAbsolutePath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.courseDescriptionDocumentAbsolutePath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.courseDescriptionDocumentAbsolutePath, learningActivity.courseTitle, AppConstants.guidelinesFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.courseDescriptionDocumentAbsolutePath, learningActivity.courseTitle, AppConstants.guidelinesFile) }} />}</a>}
                                        {learningActivity.feedbackCount > 0 && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>}
                                        {learningActivity.feedbackCount > 0 && <span onClick={e => openFeedbackModal(e, learningActivity)} className={`${classes.competitionCardLink} ${classes.feedbackText} ${classes.pulsingText}`}><LiaCommentsSolid className={classes.widgetCardsLocationIcon} /> Feedback</span>}
                                    </div>}
                                    {learningActivity.modeOfDelivery === AppConstants.modeOfDelivery[1] && <div>
                                        <a rel="noreferrer" onClick={stopPropagation} className={classes.competitionCardLink} href={learningActivity.geoLocation} target="_blank"><span data-tooltip-place="top" data-tooltip-id={learningActivity.uniqueId} className={classes.widgitLocationSpan}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Venue Map</span></a> {learningActivity.courseDescriptionDocumentAbsolutePath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.courseDescriptionDocumentAbsolutePath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.courseDescriptionDocumentAbsolutePath, learningActivity.courseTitle, AppConstants.guidelinesFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.courseDescriptionDocumentAbsolutePath, learningActivity.courseTitle, AppConstants.guidelinesFile) }} />} </a>}
                                        {learningActivity.feedbackCount > 0 && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>}
                                        {learningActivity.feedbackCount > 0 && <span onClick={e => openFeedbackModal(e, learningActivity)} className={`${classes.competitionCardLink} ${classes.feedbackText} ${classes.pulsingText}`}><LiaCommentsSolid className={classes.widgetCardsLocationIcon} /> Feedback</span>}
                                    </div>}
                                    <Tooltip className={classes.tooltipMessage} id={learningActivity.uniqueId}><span className={classes.tooltipMessage}>{learningActivity?.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>}
                        {learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && <Card className={classes.learningActivityCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${learningActivity.skillCategorySlug}${AppConstants.forwardSlash}${learningActivity.skillSlug}${AppConstants.forwardSlash}${learningActivity.workshopSlug}`)}>
                            <Card.Body className={`${classes.learningActivityCardBody} ${classes.workshopCardBody}`}>
                                <Card.Text className={`cardTitle titleFixedHeight  ${classes.learningActivityTitle}`}>
                                    <Link className="cardLink" data-tooltip-id={`${learningActivity.workshopTitle}-title-todays`}>{learningActivity.workshopTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${learningActivity.workshopTitle}-title-todays`}><span>{learningActivity.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                <div>
                                    <div className={classes.dateTimeSection}>
                                        <FaRegCalendarCheck className={classes.workshopCalendarIcon} /> <div className={classes.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.perferredTimeZone})`}</span></div>
                                    </div>
                                    <div className={classes.dateTimeSection}>
                                        <BiCalendarX className={`${classes.workshopCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{learningActivity.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${learningActivity.perferredTimeZone})`}</span></div>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${learningActivity.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${learningActivity.userSlug}`) }} className={classes.dateTimeSection}>By : {learningActivity.organizerName}</div>
                                <div>
                                    {learningActivity.workshopModeOfDelivery === AppConstants.modeOfDelivery[0] && <div>
                                        {learningActivity.workshopLink !== null ? <a rel="noreferrer" onClick={stopPropagation} className={classes.competitionCardLink} href={learningActivity.workshopLink} target="_blank"><MdShareLocation className={classes.widgetCardsLocationIcon} /> Workshop Link</a> : <p className={classes.courseOnlineModeText}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Online</p>} {learningActivity.descriptionDocumentAbsolutePath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.descriptionDocumentAbsolutePath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.descriptionDocumentAbsolutePath, learningActivity.workshopTitle, AppConstants.brochureFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Brochure {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.descriptionDocumentAbsolutePath, learningActivity.workshopTitle, AppConstants.brochureFile) }} />}</a>}
                                    </div>}
                                    {learningActivity.workshopModeOfDelivery === AppConstants.modeOfDelivery[1] && <div>
                                        <a rel="noreferrer" onClick={stopPropagation} className={classes.competitionCardLink} href={learningActivity.geoLocation} target="_blank"><span data-tooltip-place="top" data-tooltip-id={learningActivity.uniqueId} className={classes.widgitLocationSpan}><MdShareLocation className={classes.widgetCardsLocationIcon} /> Venue Map</span></a> {learningActivity.descriptionDocumentAbsolutePath != null && <span onClick={stopPropagation} className={classes.linkSeperator}>|</span>} {learningActivity.descriptionDocumentAbsolutePath != null && <a className={classes.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.descriptionDocumentAbsolutePath, learningActivity.workshopTitle, AppConstants.brochureFile) }}><BiDownload className={classes.widgetCardsLocationIcon} /> Brochure {downloadFailed && <TbRefreshAlert className={classes.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(learningActivity.descriptionDocumentAbsolutePath, learningActivity.workshopTitle, AppConstants.brochureFile) }} />}</a>}
                                    </div>}
                                    <Tooltip className={classes.tooltipMessage} id={learningActivity.uniqueId}><span className={classes.tooltipMessage}>{learningActivity?.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>}
                    </Col>
                ))}

                {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
                {showFeedbackModal && <CourseFeedbackModal learningActivityDetails={selectedLearningActivity} closeFeedbackModal={closeFeedbackModal} modalHeading="Class feedback" mode={AppConstants.actionModes.view} learnerDetails={learnerDetails} />}
                {showTeamDetailsModal && <TeamEnrolmentDetailsModal learningActivityDetails={selectedLearningActivity} closeTeamDetailsModal={closeTeamDetailsModal} />}
                {showTeamEnrolmentForm && <TeamEnrolmentForm hideTeamEnrolmentForm={hideTeamEnrolmentForm} enrolmentDetails={confirmationPopupActivityDetails} />}
                {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={AppConstants.emptyString} enrolmentDetails={confirmationPopupActivityDetails}></EnrolmentModal>}

            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={`loadMore`}>
                <Col className="loadMoreButtonSection">
                    {cardCount < (learningActivities.length) && <button className={`loadMoreButton  ${classes.loadMore}`} onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
        </div>
    );
}