import { ExpertSchoolLEarningActivityDetails } from "../../component/expertMySchoolComponents/ExpertSchoolLearningActivityDetails"
import { ExpertSchoolLearningActivityStatistics } from "../../component/expertMySchoolComponents/ExpertSchoolLearningActivityStatistics"
import classes from "./ExpertMySchool.module.css"
export const ExpertMySchoolPage = () => {
    return (
        <div className="expertContentSection">
            <h3 className={classes.heading}>Dashboard</h3>
            <ExpertSchoolLearningActivityStatistics />
            <h3 className={`${classes.heading} ${classes.headingMargin}`}>Learning Activities</h3>
            <ExpertSchoolLEarningActivityDetails />
        </div>
    )
}