import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { CountdownTimer } from "../../utils/CountDownUtil";
import { VideoPopup } from '../popup/VideoPopup';
import { TbShare3 } from 'react-icons/tb';
import { ErrorSuccessAlertMessage } from '../../component/errorSuccessMessage/ErrorSuccessAlertMessage';
import { AppConstants } from "../../constants/AppConstants";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import classes from "./UpcomingQTalksSection.module.css";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DateTime } from "luxon";

export const UpcomingQTalksSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const purpleTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.purpleTriangleImagePath}`;

    const faceBookIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.facebookIconPath}`;
    const instagramIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.instagramIconPath}`;
    const linkedInIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.linkedInIconPath}`;
    const twitterIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.twitterIconPath}`;
    const youtubeIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.youtubeIconPath}`;
    const websiteIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.websiteIconPath}`;
    const copyIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.copyIconPath}`;
    const mailIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.mailIconPath}`;
    const [upcomingQtalks, setUpcomingQtalks] = useState(props.landingPageData.qtalksContainer);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [upcomingQtalkForLandingPage, setUpcomingQtalkForLandingPage] = useState(...props.qtalksData.filter((qtalk) => qtalk.showOnLandingPage.toLowerCase() === AppConstants.trueFlag.toLowerCase()));
    //const utcToLocalDateTime = new Date(DateTimeUtil.utcToLocal(`${upcomingQtalkForLandingPage.qtalkDate}${AppConstants.space}${upcomingQtalkForLandingPage.qtalkStartTime}`));
    //const utcToLocalDateTime = DateTime.fromJSDate(new Date(DateTimeUtil.utcToLocal(`${upcomingQtalkForLandingPage.qtalkDate}${AppConstants.space}${upcomingQtalkForLandingPage.qtalkStartTime}`))).setZone(preferredTimezone);
    const utcToLocalDateTime = DateTime.fromISO(`${upcomingQtalkForLandingPage.qtalkDate}T${upcomingQtalkForLandingPage.qtalkStartTime}Z`).setZone(preferredTimezone);
    const [qTalkEndTime, setEndTime] = useState(DateTime.fromISO(`${upcomingQtalkForLandingPage.qtalkDate}T${upcomingQtalkForLandingPage.qtalkEndTime}Z`).setZone(preferredTimezone).toFormat("HH:mm"));
    const timeZoneName = DateTimeUtil.getTimeZoneName(utcToLocalDateTime);
    const [linktoWatch, setLinkToWatch] = useState(AppConstants.emptyString);
    const [qTalkDate, setQtalkDate] = useState(utcToLocalDateTime.day);
    const [qTalkYear, setQtalkYear] = useState(utcToLocalDateTime.year);
    const [qTalkMonth, setQTalkMonth] = useState(AppConstants.monthNames[utcToLocalDateTime.month - 1]);
    const [qTalkDay, setQTalkDay] = useState(AppConstants.dayName[utcToLocalDateTime.weekday]);
    const [qTalkStarttime, setQtalkStartTime] = useState(utcToLocalDateTime.toFormat('HH:mm'));
    const [showQtalkModal, setShowQtalkModal] = useState(false);
    const [qTalkLive, setqTalkLive] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const dynamicClass = qTalkLive === AppConstants.trueText ? classes.watchNowActive : classes.watchnowInactive;

    const linkToShare = AppConstants.youtubeWatchLinkPrefix + upcomingQtalkForLandingPage.qtalkYouTubeVideoLink;
    const linkToShareOnMail = AppConstants.youtubeEmbedLinkPrefix + upcomingQtalkForLandingPage.qtalkYouTubeVideoLink;
    const linkToQtalksYoutubePage = AppConstants.linkYoutube;
    const staticClasses = classes.btnWatchNow;
    //success Message
    const [errorSuccessAlertVisiblity, setErrorSuccessAlertVisiblity] = useState(false);
    const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState('');
    const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState('');
    const [showLinkCopied, setShowLinkCopied] = useState(false);
    //Handler Function When counter goes OFF
    function timeOverHandler() {
        setqTalkLive(AppConstants.trueText)
        setLinkToWatch(upcomingQtalkForLandingPage.qtalkYouTubeVideoLink);
        return qTalkLive;
    }

    //Method to open youtube to watch Qtalks
    function watchNowClickHandler(event) {
        if (qTalkLive) {
            setShowQtalkModal(true);
        }
        event.preventDefault();
    }
    //Share on social media 
    function shareOnSocialMedia() {
        setShowSocialMediaPannel(!showSocialMediaPannel);

    }
    //function to copy to clipboard
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(linkToShare);
        setShowLinkCopied(true);
        var timer = setTimeout(() => {
            setShowLinkCopied(false);
        }, 5000);

    }
    //function Send on Mail
    function sendOnMail() {
        window.open(`${AppConstants.mailToURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //Function to Share on facebook
    function shareOnFacebook() {
        window.open(`${AppConstants.facebookShareURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //Function to share on Linkedin
    function shareOnLinkedIn() {
        window.open(`${AppConstants.linkedInShareURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //function to share on twitter
    function shareOnTwitter() {
        window.open(`${AppConstants.twitterShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Close the Modal div
    function closeQtalk() {
        setShowQtalkModal(false);
    }
    return (
        <>
            {upcomingQtalks.active === AppConstants.trueText &&
                <div className={classes.upcomingQtalksContainerRelative}>
                    <div className={classes.upcomingQtalksImageContainer}>
                        <img src={upcomingQtalkForLandingPage.qtalkWebBackgroungImagePath} alt={upcomingQtalkForLandingPage.qtalkTitle} className={classes.upcomingQtalksWebImage} />
                        <img src={upcomingQtalkForLandingPage.qtalkTabletBackgroungImagePath} alt={upcomingQtalkForLandingPage.qtalkTitle} className={classes.upcomingQtalksTabImage} />
                        <img src={upcomingQtalkForLandingPage.qtalkMobileBackgroungImagePath} alt={upcomingQtalkForLandingPage.qtalkTitle} className={classes.upcomingQtalksMobileImage} />
                    </div>

                    <div className={classes.upcomingQtalksContainer}>
                        <div className={classes.aboutQtalkSection}>
                            <h2 className={classes.qTalktitleText}>{qTalkLive ? AppConstants.qTalksHeading : parse(upcomingQtalks.title)}</h2>
                            <img
                                className={classes.qTalkpurpleTriangleImage}
                                src={purpleTriangleImageUrl}
                                alt="Purple Triangle"
                            />
                            <h1 className={classes.qTalksubTitle}>{parse(upcomingQtalkForLandingPage.qtalkTitle)}</h1>
                            <p className={classes.qTalkdescription}>{parse(upcomingQtalkForLandingPage.qtalkDescription)}</p>
                            <hr className={classes.qtalkSeparatorLine} />
                            <div className={classes.speakerContainer}>
                                <div className={classes.speakerImageContainer}>
                                    <img src={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerImagePath} alt={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerName} className={classes.qTalkSpeakerImage} />
                                </div>
                                <div className={classes.speakerDetailsContainer}>
                                    <h4 className={classes.qTalkSpeakerName}>{upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerName}</h4>
                                    <p className={classes.qTalkSpeakerDesignation}>{upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerDesignation}</p>
                                    <div className={classes.unOrderedList}>
                                        {parse(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].aboutSpeaker)}
                                    </div>
                                    <div className={classes.socialMediaIconsContainer}>
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerFacebookLink != AppConstants.emptyString && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerFacebookLink != AppConstants.nullText) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerFacebookLink} target={AppConstants.openInNewTab}>
                                                <img src={faceBookIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerFacebookProfileLink} />
                                            </Link>}
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerInstagramLink != AppConstants.emptyString && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerInstagramLink != AppConstants.nullText) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerInstagramLink} target={AppConstants.openInNewTab}>
                                                <img src={instagramIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerInstagramLink} />
                                            </Link>}
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerLinkedInLink != AppConstants.emptyString && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerLinkedInLink != AppConstants.nullText) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerLinkedInLink} target={AppConstants.openInNewTab}>
                                                <img src={linkedInIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerLinkedInLink} />
                                            </Link>}
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerTwitterLink != AppConstants.emptyString && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerTwitterLink != AppConstants.nullText) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerTwitterLink} target={AppConstants.openInNewTab}>
                                                <img src={twitterIconPath} className={classes.socialMediaIconsItem} />
                                            </Link>}
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerYoutubeLink != AppConstants.nullText && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerYoutubeLink != AppConstants.emptyString) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerYoutubeLink} target={AppConstants.openInNewTab}>
                                                <img src={youtubeIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerYoutubeLink} />
                                            </Link>}
                                        {(upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerWebsiteLink != AppConstants.emptyString && upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerWebsiteLink != AppConstants.nullText) &&
                                            <Link className={classes.disableLink} to={upcomingQtalkForLandingPage.qtalkSpeakersDetails[0].speakerWebsiteLink} target={AppConstants.openInNewTab}>
                                                <img src={websiteIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerWebsiteLink} />
                                            </Link>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.watchNowSectionContainer}>
                            <div className={classes.watchNowMainContainer}>
                                <div className={classes.watchNow}>
                                    <div className={classes.qTalkDateTimeContainer}>
                                        <div className={classes.qTalkStartTime}>
                                            <p className={classes.qTalkTimeLabels}>Start</p>
                                            <p className={classes.qTalkTimeContent}>{qTalkStarttime}</p>
                                        </div>
                                        <div className={classes.qTalkDateSection}>
                                            <div className={classes.qTalkDate}>
                                                <p className={classes.month}>{qTalkMonth}</p>
                                                <p className={classes.boldDate}>{qTalkDate.toString().padStart(2, "0")}</p>
                                                <p className={classes.month}>{qTalkYear}</p>
                                                <p className={classes.day}>{qTalkDay}</p>
                                            </div>
                                        </div>
                                        <div className={classes.qTalkEndTime}>
                                            <p className={classes.qTalkTimeLabels}>End</p>
                                            <p className={classes.qTalkTimeContent}>{qTalkEndTime}</p>
                                        </div>

                                        {showSocialMediaPannel && <div className={classes.shareIconPannelContainer}>

                                            <img src={faceBookIconPath} className={classes.shareIcons} onClick={shareOnFacebook} alt={AppConstants.speakerFacebookProfileLink} />
                                            <img src={linkedInIconPath} className={classes.shareIcons} onClick={shareOnLinkedIn} alt={AppConstants.speakerLinkedInLink} />
                                            <img src={twitterIconPath} onClick={shareOnTwitter} className={classes.shareIcons} />
                                            <img src={mailIconPath} onClick={sendOnMail} className={classes.shareIcons} />
                                            {!showLinkCopied && <img src={copyIconPath} className={classes.shareIcons} onClick={copyToClipBoard} />}
                                            {showLinkCopied && <BsFillCheckCircleFill className={classes.showLinkCopiedIcon} />}

                                        </div>}
                                    </div>
                                    <hr className={classes.borderBottom}></hr>
                                    {!qTalkLive && <div className={classes.qTalkCounterContainer}>
                                        <CountdownTimer countDownTimerDateBoxClass={classes.countDownTimerDateBox} countDownTimerDateBoxContainer={classes.countDownTimerDateBoxContainer} countDownTimerDateSeperatorClass={classes.countDownTimerDateSeperator} utcToLocalDateTime={utcToLocalDateTime} onTimeOver={timeOverHandler} />
                                    </div>}
                                    {!qTalkLive && <div className={classes.qTalkCounterSectionLabels}>
                                        <div className={classes.counterDaysLabel}>DAYS</div>
                                        <div className={classes.counterHoursLabel}>HOURS</div>
                                        <div className={classes.counterMinutesLabel}>MINUTES</div>
                                    </div>}
                                </div>

                                <div className={classes.qTalkWatchNowButtonContainer}>
                                    {/*<div class={classes.qTalkyoutubeIconContainer}>
                                        <div class={classes.youtubeImage}>
                                            <img src={youtubeIconImageUrl} alt="youtube icon" class={classes.qTalkyoutubeIcon} />
                                        </div>
                                        <div className={classes.watchForFreeTextContainer}>
                                            <p className={classes.watchForText}>{AppConstants.watchForText}</p>
                                            <p className={classes.freeText}>{AppConstants.freeText}</p>
                                        </div>
                                        </div>*/}
                                    <button className={` ${dynamicClass} ${staticClasses}`} onClick={watchNowClickHandler} >{AppConstants.WatchNowButtonText}</button>
                                    <Link to={linkToQtalksYoutubePage} target={AppConstants.openInNewTab} className={classes.watchMoreQtalksLink}><p className={classes.watchMoreQtalks}>{AppConstants.watchMoreQtalks}</p></Link>
                                </div>
                                <div className={classes.qTalkshareIconContainer}>
                                    <TbShare3 className={classes.qTalkshareIcon} onClick={shareOnSocialMedia} />
                                </div>
                                {errorSuccessAlertVisiblity &&
                                    <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                                }
                            </div>
                        </div>
                    </div>


                    {(showQtalkModal && qTalkLive) && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeQtalk} videoUrl={upcomingQtalkForLandingPage.qtalkYouTubeVideoLink} />}
                </div>
            }
        </>
    )
}