import { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap"
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage"
import { AppConstants } from "../../constants/AppConstants";
import Auth from "../../utils/Auth";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import modalClass from "../cards/CompetitionsCards.module.css";
import formClass from "../../common/FormStyling.module.css";
import classes from "./AskQuestionModal.module.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";

export const AskQuestionModal = ({ learningActivityDetails, closeQuestionModal }) => {
    const [studentQuery, setStudentQuery] = useState(AppConstants.emptyString);
    const [submitQuerySpinner, setSubmitQuerySpinner] = useState(AppConstants.falseText);
    const [submitQueryError, setSubmitQueryError] = useState(AppConstants.emptyString);
    const [showSubmitQuerySuccessMessage, setShowSubmitQuerySuccessMessage] = useState(AppConstants.falseText);

    const submitQuestion = async () => {
        try {
            const userDetails = Auth.getLoggedInUserDetails();
            setSubmitQuerySpinner(true);
            const url = AppConstants.postStudentQueryAPI;
            let data = {
                question: studentQuery,
                learningActivityIntegerId: learningActivityDetails.id,
                learningActivityTitle: learningActivityDetails.title,
                learningActivityType: learningActivityDetails.learningActivityType,
                organizerEmail: learningActivityDetails.organizerEmail,
                organizerName: learningActivityDetails.organizerName,
                studentName: userDetails.name
            }

            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response && response.ok) {
                setSubmitQuerySpinner(false);
                setShowSubmitQuerySuccessMessage(true);
                setStudentQuery(AppConstants.emptyString);
            } else {
                setSubmitQuerySpinner(false);
                setSubmitQueryError(ErrorMessages.studentCourseQuerySubmitError)
                let timer = setTimeout(() => {
                    setSubmitQueryError(AppConstants.emptyString)
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setSubmitQuerySpinner(false);
        }
    };

    const handleModalClose = () => {
        setStudentQuery(AppConstants.emptyString);
        setShowSubmitQuerySuccessMessage(false);
        closeQuestionModal();
    }

    return (
        <div>
            <Modal show={true} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    {!showSubmitQuerySuccessMessage && <p className={classes.greetingText}>Hi {Auth.getLoggedInUserDetails()?.firstName}, please ask your question.</p>}
                    {!showSubmitQuerySuccessMessage && <div className={classes.addQuestionsContainer}>
                        <Form.Label className={formClass.formLabel}>Question</Form.Label>
                        <Form.Control as="textarea" placeholder="Enter your question here" className={formClass.formTextAreaInput} value={studentQuery} onChange={(e) => setStudentQuery(e.target.value)} />
                    </div>}
                    {showSubmitQuerySuccessMessage && <div>
                        <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>
                        <div className={modalClass.modalAlertText}>Hi {Auth.getLoggedInUserDetails()?.firstName}, your question has been sent to {learningActivityDetails.userRole === AppConstants.userRoleAgency ? learningActivityDetails.agencyName : learningActivityDetails.organizerName}. Please expect the response via email shortly</div>
                    </div>}
                    {submitQueryError.length > 0 && <ErrorSuccessAlertMessage message={submitQueryError} varient={AppConstants.alertVarient[1]} />}
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button onClick={handleModalClose} className={classes.modalBtn}>Close</Button>
                    {!showSubmitQuerySuccessMessage && <Button disabled={studentQuery.trim()?.length === 0} onClick={submitQuestion} className={classes.modalBtn}>Submit{submitQuerySpinner && <BiLoaderAlt className="spinner" />}</Button>}
                </Modal.Footer>
            </Modal>
        </div>
    );
}