import React, { useState, useEffect } from 'react';
import classes from '../../expertListingPage/ExpertListingPageBanner.module.css'
import { AppConstants } from '../../../constants/AppConstants';
import parse from 'html-react-parser';
import { DataService } from '../../../services/DataService';

export const CoursesListingPageBanner = (props) => {
    const [webContent, setWebContent] = useState({})
    const [mobileContent, setMobileContent] = useState({})
    const [tabContent, setTabContent] = useState({})

    useEffect(() => {
        async function courseBannerLoader() {
            const url = `${AppConstants.getPageContentAPI}/${AppConstants.coursesPageName}`
            const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
            );
        
            response.map((ele) => {
            checkDeviceType(ele)
            })
        }
        courseBannerLoader()
    }, [])

    function checkDeviceType(ele) {
        // Split the URL by '/' and get the last part
        const parts = ele.imageUrl.split('/');
        const deviceTypeStr = parts[parts.length - 1];
        
        // Extract the substring before the file extension
        const substr = deviceTypeStr.split('.')[0];

        // Check for 'web', 'mobile', or 'tab' in the substring
        if (substr.includes('web')) {
            setWebContent(ele)
        } else if (substr.includes('mobile')) {
            setMobileContent(ele)
        } else if (substr.includes('Tab')) {
            setTabContent(ele)
        }
    }

 
    return (
        <>
           {Object.keys(mobileContent).length > 0 && ( <div className={classes.mobileImage}>
                <div className={classes.epBannerItem}>
                    <img src={Object.keys(mobileContent).length ? mobileContent.imageUrl : ''} alt="Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        <h3>{parse(Object.keys(mobileContent).length ? mobileContent?.metaTitle : '')}</h3>
                        {Object.keys(mobileContent).length && mobileContent.metaDescription && <p className={classes.subHeadingText}>{parse(Object.keys(mobileContent).length ? mobileContent.metaDescription : '')}</p>}
                    </div>
                </div>
            </div>)}
            {Object.keys(tabContent).length > 0 && (<div className={classes.tabletImage}>
                <div className={classes.epBannerItem}>
                    <img src={Object.keys(tabContent).length ? tabContent.imageUrl : ''} alt="Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        <h3>{parse(Object.keys(tabContent).length ? tabContent?.metaTitle : '')}</h3>
                        {Object.keys(tabContent).length && tabContent.metaDescription && <p className={classes.subHeadingText}>{parse(Object.keys(tabContent).length ? tabContent.metaDescription : '')}</p>}
                    </div>
                </div>
            </div>)}
            {Object.keys(webContent).length > 0 && (<div className={classes.webImage}>
                <div className={classes.epBannerItem}>
                    <img src={Object.keys(webContent).length ? webContent.imageUrl : ''} alt="Banner" className={classes.epBannerImage} />
                    <div className={classes.epBannerText}>
                        <h3>{parse(Object.keys(webContent).length ? webContent?.metaTitle : '')}</h3>
                        {Object.keys(webContent).length && webContent.metaDescription && <p className={classes.subHeadingText}>{parse(Object.keys(webContent).length ? webContent.metaDescription : '')}</p>}
                    </div>
                </div>
            </div>)}

        </>
    );
}