import React, { useState, useEffect } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../../constants/AppConstants";
import classes from "../../createCourseComponent/addCourseCertificate/AddCourseCertificate.module.css";
import formClass from "../../../common/FormStyling.module.css"
import { CourseCertificateTemplate } from "../../createCourseComponent/courseCertificateTemplate/CourseCertificateTemplate"
import { AiOutlineDownload } from "react-icons/ai";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import parse from 'html-react-parser';
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { WorkshopCertificateTemplate } from "../../createWorkshopComponent/workshopCertificateTemplate/WorkshopCertificateTemplate";

export const EditCourseCertificate = (props) => {
    const { changeCertificateData, certificateData, startDate, endDate, activityTitle, skills, skillId, activityType } = props;
    const formLabels = AppConstants.addCertificateFormLabels;
    const activityStartDate = moment(startDate).format('DD MMMM YYYY');
    const stages = AppConstants.editCourseActions;
    const activityEndDate = moment(endDate).format('DD MMMM YYYY');
    const [skillName, setSkillName] = useState(AppConstants.emptyString);
    const validImgaeInput = AppConstants.supportedImageFormats;

    const initialValues = {
        logoOne: {},
        logoTwo: {},
        logoThree: {},
        certificateMessage: certificateData.certificateMessage || AppConstants.emptyString,
        signatoryOneName: certificateData.certificateSignatoryOneName || AppConstants.emptyString,
        signatoryOneDesignation: certificateData.certificateSignatoryOneDesignation || AppConstants.emptyString,
        signatoryOneOrganization: certificateData.certificateSignatoryOneOrganization || AppConstants.emptyString,
        signatoryTwoName: certificateData.certificateSignatoryTwoName || AppConstants.emptyString,
        signatoryTwoDesignation: certificateData.certificateSignatoryTwoDesignation || AppConstants.emptyString,
        signatoryTwoOrganization: certificateData.certificateSignatoryTwoOrganization || AppConstants.emptyString,
        signatoryThreeName: certificateData.certificateSignatoryThreeName || AppConstants.emptyString,
        signatoryThreeDesignation: certificateData.certificateSignatoryThreeDesignation || AppConstants.emptyString,
        signatoryThreeOrganization: certificateData.certificateSignatoryThreeOrganization || AppConstants.emptyString,
        signatoryImageOne: {},
        signatoryImageTwo: {},
        signatoryImageThree: {}
    };

    const [validated, setValidated] = useState(false);
    const [previewLogo, setPreviewLogo] = useState([null, null, null]);
    const [previewSignature, setPreviewSignature] = useState([null, null, null]);
    const [uploadedLogoOne, setUploadedLogoOne] = useState(certificateData.certificateLogoOneAbsolutePath);
    const [uploadedLogoTwo, setUploadedLogoTwo] = useState(certificateData.certificateLogoTwoAbsolutePath);
    const [uploadedLogoThree, setUploadedLogoThree] = useState(certificateData.certificateLogoThreeAbsolutePath);
    const [uploadedSignatoryImageOne, setUploadedSignatoryImageOne] = useState(certificateData.certificateSignatoryOneImageAbsolutePath);
    const [uploadedSignatoryImageTwo, setUploadedSignatoryImageTwo] = useState(certificateData.certificateSignatoryTwoImageAbsolutePath);
    const [uploadedSignatoryImageThree, setUploadedSignatoryImageThree] = useState(certificateData.certificateSignatoryThreeImageAbsolutePath);
    const [logoOneSpinner, setLogoOneSpinner] = useState(false);
    const [logoTwoSpinner, setLogoTwoSpinner] = useState(false);
    const [logoThreeSpinner, setLogoThreeSpinner] = useState(false);
    const [signatureOneSpinner, setSignatureOneSpinner] = useState(false);
    const [signatureTwoSpinner, setSignatureTwoSpinner] = useState(false);
    const [signatureThreeSpinner, setSignatureThreeSpinner] = useState(false);
    const [errorValues, setErrorValues] = useState({
        logoOne: AppConstants.emptyString,
        logoTwo: AppConstants.emptyString,
        logoThree: AppConstants.emptyString,
        signatoryImageOne: AppConstants.emptyString,
        signatoryImageTwo: AppConstants.emptyString,
        signatoryImageThree: AppConstants.emptyString
    });

    const validationSchema = Yup.object().shape({
        certificateMessage: Yup.string().notRequired(),
    });

    const { handleSubmit, values, errors, handleBlur, setFieldValue, handleChange } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: () => { },
    });

    useEffect(() => {
        if (skillId) {
            skills.map(skill => {
                if (skill.skillId === skillId) {
                    setSkillName(skill.skillName);
                }
            })
        }
    }, [skillId])

    useEffect(() => {
        let messageUpdated = false;
        let titleUpdated = false;
        let logoUpdated = false;
        let signatoryOneUpdated = AppConstants.emptyString;
        let signatoryTwoUpdated = AppConstants.emptyString;
        let signatoryThreeUpdated = AppConstants.emptyString;
        if (values.certificateMessage?.trim().length > 0) messageUpdated = true;
        else messageUpdated = false;

        if (values.logoOne.size > 0 || values.logoTwo.size > 0 || values.logoThree.size > 0 || uploadedLogoOne || uploadedLogoTwo || uploadedLogoThree) {
            logoUpdated = true;
        } else {
            logoUpdated = false;
        }
        // validate signatory one
        if ((values.signatoryImageOne?.size > 0 || uploadedSignatoryImageOne?.length > 0) && values.signatoryOneName.trim().length > 0 && values.signatoryOneDesignation.trim().length > 0 && values.signatoryOneOrganization.trim().length > 0) {
            signatoryOneUpdated = AppConstants.completeText
        } else if (values.signatoryImageOne?.size > 0 || uploadedSignatoryImageOne?.length > 0 || values.signatoryOneName.trim().length > 0 || values.signatoryOneDesignation.trim().length > 0 || values.signatoryOneOrganization.trim().length > 0) {
            signatoryOneUpdated = AppConstants.incompleteText
        } else {
            signatoryOneUpdated = AppConstants.emptyString
        }
        // validate signatory Two
        if ((values.signatoryImageTwo?.size > 0 || uploadedSignatoryImageTwo?.length > 0) && values.signatoryTwoName.trim().length > 0 && values.signatoryTwoDesignation.trim().length > 0 && values.signatoryTwoOrganization.trim().length > 0) {
            signatoryTwoUpdated = AppConstants.completeText
        } else if (values.signatoryImageTwo?.size > 0 || uploadedSignatoryImageTwo?.length > 0 || values.signatoryTwoName.trim().length > 0 || values.signatoryTwoDesignation.trim().length > 0 || values.signatoryTwoOrganization.trim().length > 0) {
            signatoryTwoUpdated = AppConstants.incompleteText
        } else {
            signatoryTwoUpdated = AppConstants.emptyString
        }

        // validate signatory Three
        if ((values.signatoryImageThree?.size > 0 || uploadedSignatoryImageThree?.length > 0) && values.signatoryThreeName.trim().length > 0 && values.signatoryThreeDesignation.trim().length > 0 && values.signatoryThreeOrganization.trim().length > 0) {
            signatoryThreeUpdated = AppConstants.completeText
        } else if (values.signatoryImageThree?.size > 0 || uploadedSignatoryImageThree?.length > 0 || values.signatoryThreeName.trim().length > 0 || values.signatoryThreeDesignation.trim().length > 0 || values.signatoryThreeOrganization.trim().length > 0) {
            signatoryThreeUpdated = AppConstants.incompleteText
        } else {
            signatoryThreeUpdated = AppConstants.emptyString
        };

        const changeParentState = () => {
            changeCertificateData({
                ...values,
                messageUpdated, logoUpdated,
                signatoryOneUpdated, signatoryTwoUpdated,
                signatoryThreeUpdated,
                uploadedLogoOne, uploadedLogoTwo, uploadedLogoThree, uploadedSignatoryImageOne, uploadedSignatoryImageTwo, uploadedSignatoryImageThree, certificateDataErrors: errorValues
            });
        }
        changeParentState();
    }, [values, uploadedLogoOne, uploadedLogoTwo, uploadedLogoThree, uploadedSignatoryImageOne, uploadedSignatoryImageTwo, uploadedSignatoryImageThree, errorValues]);

    const extractFileName = (name) => {
        let arr = name.split("/");
        const regex = /[0-9]*-/
        return arr[4]?.replace(regex, "");
    };

    const getDataTransfer = (name) => {
        const dataTransfer = new DataTransfer()
        const file = new File([''], name, { type: 'text/plain' })
        dataTransfer.items.add(file);
        return dataTransfer;
    }

    useEffect(() => {
        if (certificateData.certificateLogoOneAbsolutePath !== null) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? certificateData.certificateLogoOneAbsolutePath : state));
        }
        if (certificateData.certificateLogoTwoAbsolutePath !== null) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? certificateData.certificateLogoTwoAbsolutePath : state));
        }
        if (certificateData.certificateLogoThreeAbsolutePath !== null) {
            setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? certificateData.certificateLogoThreeAbsolutePath : state));
        }
        if (certificateData.certificateSignatoryOneImageAbsolutePath !== null) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? certificateData.certificateSignatoryOneImageAbsolutePath : state));
        }
        if (certificateData.certificateSignatoryTwoImageAbsolutePath !== null) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? certificateData.certificateSignatoryTwoImageAbsolutePath : state));
        }
        if (certificateData.certificateSignatoryThreeImageAbsolutePath !== null) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? certificateData.certificateSignatoryThreeImageAbsolutePath : state));
        }
    }, [certificateData]);

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldValue(id, AppConstants.emptyString);
        setErrorValues(errorValues => ({ ...errorValues, [id]: AppConstants.emptyString }))
        if (id === formLabels.logoOne.name) {
            setUploadedLogoOne(AppConstants.emptyString);
            setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? null : state));
        } else if (id === formLabels.logoTwo.name) {
            setUploadedLogoTwo(AppConstants.emptyString);
            setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? null : state));
        } else if (id === formLabels.logoThree.name) {
            setUploadedLogoThree(AppConstants.emptyString);
            setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? null : state));
        } else if (id === formLabels.signatoryImageOne.name) {
            setUploadedSignatoryImageOne(AppConstants.emptyString);
            setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? null : state));
        } else if (id === formLabels.signatoryImageTwo.name) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? null : state));
            setUploadedSignatoryImageTwo(AppConstants.emptyString);
        } else if (id === formLabels.signatoryImageThree.name) {
            setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? null : state));
            setUploadedSignatoryImageThree(AppConstants.emptyString);
        }
    }

    const changeLogo = (event, title) => {
        let name = event.target?.name;
        if (event.target.files[0]) {
            let type = event.target.files[0].type;
            if (!validImgaeInput.includes(type)) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileTypeOtherThanJpgOrPngError}` }))
                return null;
            } else if (event.target.files[0].size > AppConstants.image5MBSizeLimit) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileSizeMoreThan5mbError}` }))
                return null;
            }
            setFieldValue(name, event.target.files[0]);
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            const image = URL.createObjectURL(event.target.files[0]);
            if (name === formLabels.logoOne.name) {
                setUploadedLogoOne(AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? image : state));
            } else if (name === formLabels.logoTwo.name) {
                setUploadedLogoTwo(AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? image : state));
            } else if (name === formLabels.logoThree.name) {
                setUploadedLogoThree(AppConstants.emptyString);
                setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? image : state));
            }
        } else {
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            if (name === formLabels.logoOne.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 0 ? null : state));
            } else if (name === formLabels.logoTwo.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 1 ? null : state));
            } else if (name === formLabels.logoThree.name) {
                setPreviewLogo(prevState => prevState.map((state, i) => i === 2 ? null : state));
            }
            setFieldValue(name, {});
        }
    }

    const changeSignature = (event, title) => {
        let name = event.target?.name;
        if (event.target?.files[0]) {
            let type = event.target.files[0].type;
            if (!validImgaeInput.includes(type)) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileTypeOtherThanJpgOrPngError}` }))
                return null;
            } else if (event.target.files[0].size > AppConstants.image5MBSizeLimit) {
                setErrorValues(errorValues => ({ ...errorValues, [name]: `${title} ${ErrorMessages.fileSizeMoreThan5mbError}` }))
                return null;
            }
            setFieldValue(name, event.target.files[0]);
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            const image = URL.createObjectURL(event.target.files[0]);
            if (name === formLabels.signatoryImageOne.name) {
                setUploadedSignatoryImageOne(AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? image : state));
            } else if (name === formLabels.signatoryImageTwo.name) {
                setUploadedSignatoryImageTwo(AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? image : state));
            } else if (name === formLabels.signatoryImageThree.name) {
                setUploadedSignatoryImageThree(AppConstants.emptyString);
                setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? image : state));
            }
        } else {
            setErrorValues(errorValues => ({ ...errorValues, [name]: AppConstants.emptyString }))
            if (name === formLabels.signatoryImageOne.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 0 ? null : state));
            } else if (name === formLabels.signatoryImageTwo.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 1 ? null : state));
            } else if (name === formLabels.signatoryImageThree.name) {
                setPreviewSignature(prevState => prevState.map((state, i) => i === 2 ? null : state));
            }
            setFieldValue(name, {});
        }
    }
    const downloadFile = async (fileUrl, name) => {
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${activityTitle}-${name}`);
        if (resp) {
            if (name === formLabels.logoOne.name) {
                setLogoOneSpinner(false);
            } else if (name === formLabels.logoTwo.name) {
                setLogoTwoSpinner(false);
            } else if (name === formLabels.logoThree.name) {
                setLogoThreeSpinner(false);
            } else if (name === formLabels.signatoryImageOne.name) {
                setSignatureOneSpinner(false);
            } else if (name === formLabels.signatoryImageTwo.name) {
                setSignatureTwoSpinner(false);
            } else if (name === formLabels.signatoryImageThree.name) {
                setSignatureThreeSpinner(false);
            }
        } else {
            if (name === formLabels.logoOne.name) {
                setLogoOneSpinner(false);
            } else if (name === formLabels.logoTwo.name) {
                setLogoTwoSpinner(false);
            } else if (name === formLabels.logoThree.name) {
                setLogoThreeSpinner(false);
            } else if (name === formLabels.signatoryImageOne.name) {
                setSignatureOneSpinner(false);
            } else if (name === formLabels.signatoryImageTwo.name) {
                setSignatureTwoSpinner(false);
            } else if (name === formLabels.signatoryImageThree.name) {
                setSignatureThreeSpinner(false);
            }
        }
    }
    const moveToNextSection = () => {
        props.saveCurrentStep({ step: stages.indexOf(props.stage) });
    };

    return (
        <div>
            <div className={classes.configureCertContainer}>
                <Form className={classes.inputContainer} noValidate validated={validated} onSubmit={handleSubmit} >
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoOne.name} name={formLabels.logoOne.name} accept="image/png, image/jpeg,image/jpg" label={formLabels.logoOne.label} handleFileChange={(event) => changeLogo(event, formLabels.logoOne.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoOne}
                                defaultFile={uploadedLogoOne ? getDataTransfer(extractFileName(certificateData.certificateLogoOneRelativePath) || "Logo_One").files[0] : ""} />
                            {certificateData.certificateLogoOneAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateLogoOneAbsolutePath, formLabels.logoOne.name); setLogoOneSpinner(true); }} data-tooltip-id="downloadLogoOne" className={classes.removeBtn}>{logoOneSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoOne.name)} data-tooltip-id="removeLogoOne" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadLogoOne"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoOne"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.logoOne && <div className="errorText">{errorValues.logoOne}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoTwo.name} name={formLabels.logoTwo.name} accept="image/png, image/jpeg,image/jpg" label={formLabels.logoTwo.label} handleFileChange={(event) => changeLogo(event, formLabels.logoTwo.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoTwo}
                                defaultFile={uploadedLogoTwo ? getDataTransfer(extractFileName(certificateData.certificateLogoTwoRelativePath) || "Logo_Two").files[0] : ""} />
                            {certificateData.certificateLogoTwoAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateLogoTwoAbsolutePath, formLabels.logoTwo.name); setLogoTwoSpinner(true) }} data-tooltip-id="downloadLogoTwo" className={classes.removeBtn}>{logoTwoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoTwo.name)} data-tooltip-id="removeLogoTwo" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadLogoTwo"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoTwo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.logoTwo && <div className="errorText">{errorValues.logoTwo}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.logoThree.name} name={formLabels.logoThree.name} accept="image/png, image/jpeg,image/jpg" label={formLabels.logoThree.label} handleFileChange={(event) => changeLogo(event, formLabels.logoThree.label)} infoMessage={AppConstants.preferredLogoHeightMessage} inputFile={values.logoThree}
                                defaultFile={uploadedLogoThree ? getDataTransfer(extractFileName(certificateData.certificateLogoThreeRelativePath) || "Logo_Three").files[0] : ""} />
                            {certificateData.certificateLogoThreeAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateLogoThreeAbsolutePath, formLabels.logoThree.name); setLogoThreeSpinner(true) }} data-tooltip-id="downloadLogoThree" className={classes.removeBtn}>{logoThreeSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.logoThree.name, formLabels.logoThree.label)} data-tooltip-id="removeLogoThree" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadLogoThree"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeLogoThree"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="logoThreeMessage"><span className={classes.tooltipMessage}>{parse(AppConstants.preferredLogoHeightMessage)}</span></Tooltip>
                        </div>
                        {errorValues.logoThree && <div className="errorText">{errorValues.logoThree}</div>}
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2">
                            <Form.Label className={formClass.formLabel}>{formLabels.certificateMessage.label}</Form.Label>
                            <Form.Control className={formClass.formTextAreaInput}
                                placeholder="Certificate Message" as="textarea"
                                maxLength={140}
                                name={formLabels.certificateMessage.name}
                                value={values.certificateMessage} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid" >{errors.certificateMessage}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Label className={classes.signatoryLabel}>Signatory One Details</Form.Label>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Name"
                                maxLength={26}
                                name={formLabels.signatoryOneName.name}
                                value={values.signatoryOneName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageOne.name} name={formLabels.signatoryImageOne.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.signatoryImageOne.label} handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageOne.label)} inputFile={values.signatoryImageOne}
                                defaultFile={uploadedSignatoryImageOne ? getDataTransfer(extractFileName(certificateData.certificateSignatoryOneImageRelativePath) || "SignatoryImageOne").files[0] : ""} />
                            {certificateData.certificateSignatoryOneImageAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateSignatoryOneImageAbsolutePath, formLabels.signatoryImageOne.name); setSignatureOneSpinner(true) }} data-tooltip-id="downloadSignatoryOne" className={classes.removeBtn}>{signatureOneSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageOne.name)} data-tooltip-id="removeSignatoryOne" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadSignatoryOne"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryOne"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageOne && <div className="errorText">{errorValues.signatoryImageOne}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Designation"
                                maxLength={30}
                                name={formLabels.signatoryOneDesignation.name}
                                value={values.signatoryOneDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryOneOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryOneOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={35}
                                placeholder="Organization"
                                name={formLabels.signatoryOneOrganization.name}
                                value={values.signatoryOneOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Label className={classes.signatoryLabel}>Signatory Two Details</Form.Label>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Name"
                                maxLength={26}
                                name={formLabels.signatoryTwoName.name}
                                value={values.signatoryTwoName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageTwo.name} name={formLabels.signatoryImageTwo.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.signatoryImageTwo.label} handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageTwo.label)} inputFile={values.signatoryImageTwo}
                                defaultFile={uploadedSignatoryImageTwo ? getDataTransfer(extractFileName(certificateData.certificateSignatoryTwoImageRelativePath) || "SignatoryImageTwo").files[0] : ""} />
                            {certificateData.certificateSignatoryTwoImageAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateSignatoryTwoImageAbsolutePath, formLabels.signatoryImageTwo.name); setSignatureTwoSpinner(true) }} data-tooltip-id="downloadSignatoryTwo" className={classes.removeBtn}>{signatureTwoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageTwo.name)} data-tooltip-id="removeSignatoryTwo" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadSignatoryTwo"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryTwo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageTwo && <div className="errorText">{errorValues.signatoryImageTwo}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={30}
                                placeholder="Designation"
                                name={formLabels.signatoryTwoDesignation.name}
                                value={values.signatoryTwoDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryTwoOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryTwoOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={35}
                                placeholder="Organization"
                                name={formLabels.signatoryTwoOrganization.name}
                                value={values.signatoryTwoOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Label className={classes.signatoryLabel}>Signatory Three Details</Form.Label>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeName">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeName.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={26}
                                placeholder="Name"
                                name={formLabels.signatoryThreeName.name}
                                value={values.signatoryThreeName} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id={formLabels.signatoryImageThree.name} name={formLabels.signatoryImageThree.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.signatoryImageThree.label} handleFileChange={(event) => changeSignature(event, formLabels.signatoryImageThree.label)} inputFile={values.signatoryImageThree}
                                defaultFile={uploadedSignatoryImageThree ? getDataTransfer(extractFileName(certificateData.certificateSignatoryThreeImageRelativePath) || "SignatoryImageThree").files[0] : ""} />

                            {certificateData.certificateSignatoryThreeImageAbsolutePath && <button type="button" onClick={() => { downloadFile(certificateData.certificateSignatoryThreeImageAbsolutePath, formLabels.signatoryImageThree.name); setSignatureThreeSpinner(true) }} data-tooltip-id="downloadSignatoryThree" className={classes.removeBtn}>{signatureThreeSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.signatoryImageThree.name)} data-tooltip-id="removeSignatoryThree" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="downloadSignatoryThree"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                            <Tooltip className={classes.tooltipMessage} id="removeSignatoryThree"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {errorValues.signatoryImageThree && <div className="errorText">{errorValues.signatoryImageThree}</div>}
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeDesignation">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeDesignation.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                maxLength={30}
                                placeholder="Designation"
                                name={formLabels.signatoryThreeDesignation.name}
                                value={values.signatoryThreeDesignation} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="signatoryThreeOrganization">
                            <Form.Label className={formClass.formLabel}>{formLabels.signatoryThreeOrganization.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="text"
                                placeholder="Organization"
                                maxLength={35}
                                name={formLabels.signatoryThreeOrganization.name}
                                value={values.signatoryThreeOrganization} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <div className={classes.previewContainer}>
                    {activityType === AppConstants.coursesText ? <CourseCertificateTemplate values={values} template={props.template} previewLogo={previewLogo} previewSignature={previewSignature} courseTitle={activityTitle} skillName={skillName} startDate={activityStartDate} endDate={activityEndDate} />
                        : <WorkshopCertificateTemplate values={values} template={props.template} previewLogo={previewLogo} previewSignature={previewSignature} workshopTitle={activityTitle} skillName={skillName} startDate={activityStartDate} endDate={activityEndDate} />
                    }
                </div>
            </div>
            <div className={classes.nextBtn}>
                <Button onClick={moveToNextSection}>Next</Button>
            </div>
        </div>
    );
};