import { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaChalkboardTeacher, FaRegCalendarCheck } from 'react-icons/fa';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import classes from './StudentCompetitionWidgetCards.module.css';
import 'moment-timezone';
import { BiWallet } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { PaymentsUtil } from "../../utils/PaymentsUtil";

export const StudentPendingEnrolmentWidgetCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(2);

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((learningActivity) => (
                    <Col className={classes.competitionCardSection} key={learningActivity.learningActivitySlug}>
                        <Card className={classes.competitionCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition ? AppConstants.competitionsText : learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course ? AppConstants.coursesText : AppConstants.workshopsText}${AppConstants.forwardSlash}${learningActivity.learningActivityCategorySlug}${AppConstants.forwardSlash}${learningActivity.learningActivitySkillSlug}${AppConstants.forwardSlash}${learningActivity.learningActivitySlug}`)}>
                            <Card.Body className={`${classes.competitionCardBody} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.courseCardBody} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCardBody}`}>
                                <Card.Title className={classes.competitionTitle}>{learningActivity.learningActivityTitle}</Card.Title>
                                <div className={classes.dateTimeSection}>
                                    <BiWallet className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div className={classes.dateTimeText}><span className="competitionDateTimeFormat">Cost: {PaymentsUtil.calculateCost({ cost: learningActivity.learningActivityCost, discount: learningActivity.learningActivityDiscount })}</span></div>
                                </div>
                                {learningActivity.courseType === AppConstants.flexibleCourseType && <div>
                                    {learningActivity.learningActivityNumberOfClasses > 0 && <div className={classes.dateTimeSection}>
                                        <FaChalkboardTeacher className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div className={classes.dateTimeText}><span className="competitionDateTimeFormat">Classes: {learningActivity.learningActivityNumberOfClasses} {learningActivity.learningActivityNumberOfClasses > 1 ? "classes" : "class"}</span></div>
                                    </div>}
                                    {learningActivity.learningActivityPackageDuration > 0 && <div className={classes.dateTimeSection}>
                                        <FaChalkboardTeacher className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div className={classes.dateTimeText}><span className="competitionDateTimeFormat">Duration: {learningActivity.learningActivityPackageDuration} {learningActivity.learningActivityPackageDuration > 1 ? "Days" : "Day"}</span></div>
                                    </div>}
                                </div>}
                                <div className={classes.dateTimeSection}>
                                    <FaRegCalendarCheck className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div className={classes.dateTimeText}>
                                        <span className="competitionDateTimeFormat"> Requested On:&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivity.offlinepaymentRequestedOn.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>,  <Moment format="Do MMM YYYY" tz={timeZone}>{learningActivity.offlinepaymentRequestedOn.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>  {learningActivity.preferredTimezone === AppConstants.utcTimezone && `(${learningActivity.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                {learningActivity.creatorUserRole === AppConstants.userRoleExpert && <div className={classes.dateTimeSection}>
                                    <AiOutlineUser className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${learningActivity.userSlug}`) }} className={classes.dateTimeText}><span className="competitionDateTimeFormat">Expert: {learningActivity.expertName}</span></div>
                                </div>}
                                {learningActivity.creatorUserRole === AppConstants.userRoleAgency && <div className={classes.dateTimeSection}>
                                    <BsBuilding className={`${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.competition && classes.orangeCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.course && classes.blueCalendarIcon} ${learningActivity.learningActivityType === AppConstants.learningActivitiesTypes.workshop && classes.workshopCalendarIcon}`} /> <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${learningActivity.userSlug}`) }} className={classes.dateTimeText}><span className="competitionDateTimeFormat">Agency: {learningActivity.agencyName}</span></div>
                                </div>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + 2)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}