import React, { useEffect, useState } from "react";
import classes from './AdminGetObrForEmailForm.module.css';
import { Button, Row, Col, Card, Badge } from "react-bootstrap";
import 'moment-timezone';
import { AgGridReact } from 'ag-grid-react';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlineUser } from 'react-icons/ai';
import { CiMobile2 } from 'react-icons/ci';
import { ImCross } from 'react-icons/im';
import { TiTick } from 'react-icons/ti';
import { BsAward } from 'react-icons/bs';
import { TbShare3, TbCircleChevronRight } from 'react-icons/tb';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import "../../../expertManageCompetitionComponent/dataGrid.css";
import { ErrorMessages } from '../../../../constants/ErrorMessages';
import { AppConstants } from "../../../../constants/AppConstants";
import { SuccessMessage } from "../../../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { FaChalkboardTeacher } from 'react-icons/fa'

export const AdminManageActiveObr = (props) => {
   const [showIndividualObr, setShowIndividualObr] = useState(AppConstants.falseText);
   const [sendEmailErrorActiveObr, setSendEmailErrorActiveObr] = useState(AppConstants.falseText);
   const [sendEmailMessageActiveObr, setSendEmailMessageActiveObr] = useState(AppConstants.emptyString);
   const [sendMessageVarient, setSendMessageVarient] = useState(AppConstants.emptyString);
   const [cardCount, setCardCount] = useState(6);
   const [userRole, setUserRole] = useState(props.userRole);

   useEffect(() => {
      setUserRole(props.userRole);
   }, [props.userRole]);

   function getCssClassForExpertObrStatus(param) {
      const defaultClassName = `${classes.expertObrStatusField}`;
      let additionalClassName = AppConstants.emptyString;
      if (param.obrStatus === AppConstants.expertObrStatus.statusApproved) {
         additionalClassName = `${classes.expertObrStatusFieldGreen}`;

      }
      else if (param.obrStatus === AppConstants.expertObrStatus.statusRejected) {
         additionalClassName = `${classes.expertObrStatusFieldRed}`;
      }
      else if (param.obrStatus === AppConstants.expertObrStatus.statusDraft) {
         additionalClassName = `${classes.expertObrStatusFieldAmber}`;
      }
      else if (param.obrStatus === AppConstants.expertObrStatus.statusInReview) {
         additionalClassName = `${classes.expertObrStatusFieldBlue}`;
      }
      return (defaultClassName + AppConstants.space + additionalClassName);
   };

   const getValidTextClass = ({ value }) => {
      if (value === AppConstants.expertObrStatus.statusAccepted) {
         return `${classes.obrGreenText}`;
      } else if (value === AppConstants.expertObrStatus.statusRejected) {
         return `${classes.obrRedText}`;
      }
      else if (value === AppConstants.expertObrStatus.statusDraft) {
         return `${classes.obrOrangeColorText}`;
      }
      else if (value === AppConstants.expertObrStatus.statusInReview) {
         return `${classes.obrBlueDarkText}`;
      }
   };


   const addColorTextClass = ({ value }) => {
      if (value === AppConstants.activeText) {
         return `${classes.obrGreenText}`;
      } else {
         return `${classes.obrRedText}`;
      }
   };


   //handle click on view button
   function toggleDetails() {
      setShowIndividualObr(!showIndividualObr);
      props.onActiveShowDetails(!showIndividualObr);
   }
   function handleDetailsComponent(params) {
      props.onObrSelection({ ...params, selectedUserRole: userRole });
      toggleDetails();
   }
   function sendEmail(params) {
      let sendEmailUrl = AppConstants.emptyString;
      if (userRole === AppConstants.userRoleExpert) {
         sendEmailUrl = AppConstants.sendObrLinkToExpertAPI;
      } else if (userRole === AppConstants.userRoleAgency) {
         sendEmailUrl = AppConstants.sendObrLinkToAgencyAPI;
      }
      const data = params;
      const response = AuthorizedDataService.post(sendEmailUrl,
         data,
         AppConstants.emptyString,
         AppConstants.emptyString);
      if (response === AppConstants.nullText || response === undefined) {
         setSendEmailErrorActiveObr(AppConstants.trueText);
         setSendEmailMessageActiveObr(ErrorMessages.cannotSendEmailError);
         setSendMessageVarient(AppConstants.alertVarient[1]);
         setTimeout(() => setSendEmailErrorActiveObr(AppConstants.falseText), AppConstants.messageDisappearTime);
      }
      else if (response !== AppConstants.nullText || response !== undefined) {
         setSendEmailErrorActiveObr(AppConstants.trueText);
         setSendEmailMessageActiveObr(SuccessMessage.obrEmailSentSuccessMessage);
         setSendMessageVarient(AppConstants.alertVarient[AppConstants.zeroIndex]);
         setTimeout(() => setSendEmailErrorActiveObr(AppConstants.falseText), AppConstants.messageDisappearTime);
      }

      else if (response.status === AppConstants.httpResponseCodes.responseCode200) {
         setSendEmailErrorActiveObr(AppConstants.trueText);
         setSendEmailMessageActiveObr(SuccessMessage.obrEmailSentSuccessMessage);
         setSendMessageVarient(AppConstants.alertVarient[AppConstants.zeroIndex]);
         setTimeout(() => setSendEmailErrorActiveObr(AppConstants.falseText), AppConstants.messageDisappearTime);
      }
      else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
         setSendEmailErrorActiveObr(AppConstants.trueText);
         setSendEmailMessageActiveObr(ErrorMessages.cannotSendEmailError);
         setSendMessageVarient(AppConstants.alertVarient[1]);
         setTimeout(() => setSendEmailErrorActiveObr(AppConstants.falseText), AppConstants.messageDisappearTime);
      }
      else if (response === -11) {
         setSendEmailErrorActiveObr(AppConstants.trueText);
         setSendEmailMessageActiveObr(ErrorMessages.cannotSendEmailError);
         setSendMessageVarient(AppConstants.alertVarient[1]);
         setTimeout(() => setSendEmailErrorActiveObr(AppConstants.falseText), AppConstants.messageDisappearTime);
      }

   };

   function getSKillValueString(selectedSkills) {
      if (!selectedSkills === null || !selectedSkills?.length) {
         return AppConstants.notAvailableText;
      }
      let value = AppConstants.emptyString;
      selectedSkills?.forEach((skill, i) => {
         if (i > 0) {
            value = `${value}, ${skill.skillName}`;
         } else {
            value = skill.skillName;
         }
      });
      return value;
   }


   const columnDefs = [
      {
         headerName: "Name",
         field: "name",
         width: 300,
         tooltipValueGetter: ({ data }) => `${data.expertFirstName}${AppConstants.space}${data.expertLastName}`,
         valueGetter: (params) => {
            return `${params.data.expertFirstName}${AppConstants.space}${params.data.expertLastName}`;
         },
         filter: 'agTextColumnFilter'

      },
      {
         headerName: "Email",
         field: "expertEmail",
         tooltipField: "expertEmail",
         width: 200,
         filter: 'agTextColumnFilter'
      },
      {
         headerName: "Phone",
         field: "phoneNumber",
         tooltipField: "phoneNumber",
         filter: 'agTextColumnFilter',
         width: 110,
      },
      {
         headerName: "Skill",
         field: "skill",
         width: 100,
         filter: 'agTextColumnFilter',
         tooltipValueGetter: (params) => { return getSKillValueString(params.data.selectedSkills) },
         valueGetter: (params) => { return getSKillValueString(params.data.selectedSkills) }
      },
      {
         headerName: "Status",
         field: "obrStatus",
         tooltipField: "obrStatus",
         width: 100,
         filter: 'agTextColumnFilter',
         cellClass: getValidTextClass

      },
      {
         headerName: "Is Active",
         field: "isActiveFlag",
         width: 200,
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            if (params.data.isActiveFlag === AppConstants.trueFlag) {
               return AppConstants.activeText;
            } else {
               return AppConstants.inactiveText;
            }
         },
         tooltipValueGetter: (params) => {
            if (params.data.isActiveFlag === AppConstants.trueFlag) {
               return AppConstants.activeText;
            } else {
               return AppConstants.inactiveText;
            }
         },
         cellClass: addColorTextClass
      },
      {
         headerName: "Action",
         field: "action",
         cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
            <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Send Link" className={`gridButton ${classes.gridIcon}`} onClick={() => { sendEmail(params.data) }} disabled={params.data.obrStatus === AppConstants.expertObrStatus.statusAccepted || params.data.obrStatus === AppConstants.expertObrStatus.statusRejected || params.data.isActiveFlag === AppConstants.falseFlag}><TbShare3 /></Button>
            <Button data-bs-toggle="tooltip" data-bs-placement="top" title="View" className={`gridButton ${classes.gridIcon}`} onClick={() => { handleDetailsComponent(params.data) }}><TbCircleChevronRight /></Button>
         </div>,
         width: 100,
      }
   ]

   const agencyColumnDefs = [
      {
         headerName: "Agency Name",
         field: "agencyName",
         width: 300,
         tooltipValueGetter: ({ data }) => `${data.agencyName}`,
         valueGetter: (params) => {
            return `${params.data.agencyName}`;
         },
         filter: 'agTextColumnFilter'

      },
      {
         headerName: "SPOC Name",
         field: "agencyHeadFirstName",
         width: 300,
         tooltipValueGetter: ({ data }) => `${data.agencyHeadFirstName}${AppConstants.space}${data.agencyHeadLastName}`,
         valueGetter: (params) => {
            return `${params.data.agencyHeadFirstName}${AppConstants.space}${params.data.agencyHeadLastName}`;
         },
         filter: 'agTextColumnFilter'

      },
      {
         headerName: "SPOC Email",
         field: "agencyHeadEmail",
         tooltipField: "agencyHeadEmail",
         width: 200,
         filter: 'agTextColumnFilter'
      },
      {
         headerName: "SPOC Phone",
         field: "agencyHeadPhoneNumber",
         tooltipField: "agencyHeadPhoneNumber",
         filter: 'agTextColumnFilter',
         width: 110,
      },
      {
         headerName: "Agency Type",
         field: "agencyType",
         tooltipField: "agencyType",
         filter: 'agTextColumnFilter',
         width: 100,
      },
      {
         headerName: "Skill",
         field: "skill",
         width: 100,
         filter: 'agTextColumnFilter',
         tooltipValueGetter: (params) => { return getSKillValueString(params.data?.selectedSkills) },
         valueGetter: (params) => { return getSKillValueString(params.data?.selectedSkills) }
      },
      {
         headerName: "Status",
         field: "obrStatus",
         tooltipField: "obrStatus",
         width: 100,
         filter: 'agTextColumnFilter',
         cellClass: getValidTextClass

      },
      {
         headerName: "Is Active",
         field: "isActiveFlag",
         width: 200,
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            if (params.data.isActiveFlag === AppConstants.trueFlag) {
               return AppConstants.activeText;
            } else {
               return AppConstants.inactiveText;
            }
         },
         tooltipValueGetter: (params) => {
            if (params.data.isActiveFlag === AppConstants.trueFlag) {
               return AppConstants.activeText;
            } else {
               return AppConstants.inactiveText;
            }
         },
         cellClass: addColorTextClass
      },
      {
         headerName: "Action",
         field: "action",
         cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
            <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Send Link" className={`gridButton ${classes.gridIcon}`} onClick={() => { sendEmail(params.data) }} disabled={params.data.obrStatus === AppConstants.expertObrStatus.statusAccepted || params.data.obrStatus === AppConstants.expertObrStatus.statusRejected || params.data.isActiveFlag === AppConstants.falseFlag}><TbShare3 /></Button>
            <Button data-bs-toggle="tooltip" data-bs-placement="top" title="View" className={`gridButton ${classes.gridIcon}`} onClick={() => { handleDetailsComponent(params.data) }}><TbCircleChevronRight /></Button>
         </div>,
         width: 100,
      }
   ]
   return (
      <>
         <div className={classes.showExpertObrGrid}>
            <div className={classes.createObrFormContainer}>
               <Row>
                  < Col xs={12} sm={12} md={12} lg={12} xl={12}>
                     <div className="ag-theme-alpine">
                        <AgGridReact
                           columnDefs={userRole === AppConstants.userRoleExpert ? columnDefs : agencyColumnDefs}
                           rowData={userRole === AppConstants.userRoleExpert ? props.actionObrList : props.actionsAgencyObrList}
                           pagination={true}
                           paginationPageSize={5}
                           enableBrowserTooltips={true}
                           domLayout='autoHeight'
                           suppressRowHoverHighlight={true}
                           defaultColDef={{
                              filter: true,
                              sortable: true,
                              flex: 1,
                              minWidth: 100,
                              resizable: true,
                              suppressMenu: true,
                              suppressHorizontalScroll: true,
                              floatingFilterComponentParams: { suppressFilterButton: true },
                              floatingFilter: true,
                           }}
                           enableFilter={true}
                           enableSorting={true}
                           enableSearching={true}
                           headerClass={classes.obrGridHeader}
                           rowClass={classes.obrGridTable}
                        />
                     </div>
                  </Col>
               </Row>
               {sendEmailErrorActiveObr && <ErrorSuccessAlertMessage message={sendEmailMessageActiveObr} varient={sendMessageVarient} />}
            </div>
         </div>
         <div className={classes.showExpertObrCards}>
            <div className={classes.createObrFormContainer} >
               {userRole === AppConstants.userRoleExpert && <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                  {props.actionObrList?.length === 0 ? (<p className="text-center w-100">You have no OBR currently in transit for review or approval for Experts</p>) : (props.actionObrList?.slice(0, cardCount).map(item => (
                     <Col className={classes.activeObrCardsColumn} key={item.expertObrUniqueId}>
                        <Card key={item.id} className={classes.expertObrCards}>
                           <Card.Body>
                              <div className={classes.obrCardsContainer}>
                                 <Badge className={getCssClassForExpertObrStatus(item)}>{item.obrStatus}</Badge>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><AiOutlineUser /></Card.Text>
                                 <Card.Text>{item.expertFirstName} {item.expertLastName}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text ><HiOutlineMail /></Card.Text>
                                 <Card.Text className={classes.expertEmailText}>{item.expertEmail}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><CiMobile2 /></Card.Text>
                                 <Card.Text>{item.phoneNumber}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><BsAward /></Card.Text>
                                 <Card.Text>{getSKillValueString(item.selectedSkills)}</Card.Text>
                              </div>
                              {item.isActiveFlag === AppConstants.trueFlag && <div className={classes.obrCardsContainer}>
                                 <Card.Text><TiTick className={classes.expertObrCardTickIcon} />{AppConstants.activeText}</Card.Text>
                              </div>}
                              {item.isActiveFlag !== AppConstants.trueFlag && <div className={classes.obrCardsContainer}>
                                 <Card.Text><ImCross className={classes.expertObrCardCrossIcon} />{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.inactiveText}</Card.Text>
                              </div>}
                              <div className={classes.obrCardsButtonContainer}>
                                 <Button className="gridButton" onClick={() => { handleDetailsComponent(item) }}>{AppConstants.expertCreateObrButtons.showDetailsBtn.label}</Button>
                                 <Button className="gridButton" onClick={() => { sendEmail(item) }} disabled={item.obrStatus === AppConstants.expertObrStatus.statusAccepted || item.obrStatus === AppConstants.expertObrStatus.statusRejected || item.isActiveFlag === AppConstants.falseFlag}>{AppConstants.expertCreateObrButtons.sendLinkBtn.label}</Button>
                              </div>
                           </Card.Body>
                        </Card></Col>)))}

               </Row>}
               {userRole === AppConstants.userRoleAgency && <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                  {props.actionsAgencyObrList?.length === 0 ? (<p className="text-center w-100">You have no OBR currently in transit for review or approval for Agencies</p>) : (props.actionsAgencyObrList?.slice(0, cardCount).map(item => (
                     <Col className={classes.activeObrCardsColumn} key={item.expertObrUniqueId}>
                        <Card key={item.id} className={classes.expertObrCards}>
                           <Card.Body>
                              <div className={classes.obrCardsContainer}>
                                 <Badge className={getCssClassForExpertObrStatus(item)}>{item.obrStatus}</Badge>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><HiOutlineBuildingOffice2 /></Card.Text>
                                 <Card.Text>{item.agencyName}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><AiOutlineUser /></Card.Text>
                                 <Card.Text>{item.agencyHeadFirstName} {item.agencyHeadLastName}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text ><HiOutlineMail /></Card.Text>
                                 <Card.Text className={classes.expertEmailText}>{item.agencyHeadEmail}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><CiMobile2 /></Card.Text>
                                 <Card.Text>{item.agencyHeadPhoneNumber}</Card.Text>
                              </div>
                              <div className={classes.obrCardsContainer}>
                                 <Card.Text><FaChalkboardTeacher /></Card.Text>
                                 <Card.Text>{item.agencyType}</Card.Text>
                              </div>
                              {item.agencyType === AppConstants.agencyTypes.academy && item.selectedSkills && <div className={classes.obrCardsContainer}>
                                 <Card.Text><BsAward /></Card.Text>
                                 <Card.Text>{getSKillValueString(item.selectedSkills)}</Card.Text>
                              </div>}
                              {item.isActiveFlag === AppConstants.trueFlag && <div className={classes.obrCardsContainer}>
                                 <Card.Text><TiTick className={classes.expertObrCardTickIcon} />{AppConstants.activeText}</Card.Text>
                              </div>}
                              {item.isActiveFlag !== AppConstants.trueFlag && <div className={classes.obrCardsContainer}>
                                 <Card.Text><ImCross className={classes.expertObrCardCrossIcon} />{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.space}{AppConstants.inactiveText}</Card.Text>
                              </div>}
                              <div className={classes.obrCardsButtonContainer}>
                                 <Button className="gridButton" onClick={() => { handleDetailsComponent(item) }}>{AppConstants.expertCreateObrButtons.showDetailsBtn.label}</Button>
                                 <Button className="gridButton" onClick={() => { sendEmail(item) }} disabled={item.obrStatus === AppConstants.expertObrStatus.statusAccepted || item.obrStatus === AppConstants.expertObrStatus.statusRejected || item.isActiveFlag === AppConstants.falseFlag}>{AppConstants.expertCreateObrButtons.sendLinkBtn.label}</Button>
                              </div>
                           </Card.Body>
                        </Card></Col>)))}

               </Row>}
               {(userRole === AppConstants.userRoleExpert && props.actionObrList !== AppConstants.nullText && cardCount < props.actionObrList?.length) && <Row>
                  <Col className={classes.loadMoreContainer}>
                     <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + 6)}>{AppConstants.expertCreateObrButtons.loadMoreBtn.label}</Button>
                  </Col>
               </Row>}
               {(userRole === AppConstants.userRoleAgency && props.actionsAgencyObrList !== AppConstants.nullText && cardCount < props.actionsAgencyObrList?.length) && <Row>
                  <Col className={classes.loadMoreContainer}>
                     <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + 6)}>{AppConstants.expertCreateObrButtons.loadMoreBtn.label}</Button>
                  </Col>
               </Row>}
               {sendEmailErrorActiveObr && <ErrorSuccessAlertMessage message={sendEmailMessageActiveObr} varient={sendMessageVarient} />}
            </div>
         </div>

      </>

   );
}