import { AppConstants } from "../../../constants/AppConstants";
import classes from "../../createCourseComponent/courseCertificateTemplate/CourseCertificateTemplate.module.css";

export const WorkshopCertificateTemplate = ({ values, template = [], previewLogo, previewSignature, workshopTitle, skillName, startDate, endDate }) => {

    return (
    <div className={classes.certificate}>
        <img className={classes.certificateBackground} src={template[2]?.templateImagePath} />
        <div className={classes.certificateContent}>
            <div className={classes.logoSection}>
                {
                    previewLogo.map((logo, i) => {
                        if (logo) {
                            return (
                                <img key={i}
                                    className={classes.certificateLogo}
                                    src={logo} alt="Logo" />
                            );
                        }
                    })
                }
            </div>
            <h3 className={classes.certificateTitle}>Certificate of Participation</h3>
            <h6 className={classes.presentedToText}>Presented To</h6>
            <h3 className={classes.nameText}>{`<Participant Name>`}</h3>
            <div className={classes.bodyContainer}>
                <p className={classes.certificateDescription}>For participating in Workshop</p>
                <p className={classes.certificateDescription}><span className={classes.certificateWorkshopTitle}>'{workshopTitle ? workshopTitle : `<Workshop Title>`}'</span> under skill category <span className={classes.certificateWorkshopTitle}>{skillName ? skillName : `<Skill Name>`}</span></p>
                <p className={classes.certificateDescription}>{`conducted by <organization name>`}</p>
                {(startDate === AppConstants.invalidDateText || startDate === AppConstants.emptyString) && (endDate === AppConstants.invalidDateText || endDate === AppConstants.emptyString) && <p className={classes.certificateDescription}>from {startDate === AppConstants.invalidDateText || startDate === AppConstants.emptyString ? `<Start Date>` : startDate} to {endDate === AppConstants.invalidDateText || endDate === AppConstants.emptyString ? `<End Date>` : endDate}</p>}
                {startDate !== AppConstants.invalidDateText && startDate !== AppConstants.emptyString && endDate !== AppConstants.invalidDateText && endDate !== AppConstants.emptyString && startDate!==endDate && <p className={classes.certificateDescription}>from {startDate} to {endDate}</p>}
                {startDate !== AppConstants.invalidDateText && startDate !== AppConstants.emptyString && endDate !== AppConstants.invalidDateText && endDate !== AppConstants.emptyString && startDate===endDate && <p className={classes.certificateDescription}>on {startDate}</p>}
                {(startDate === AppConstants.invalidDateText || startDate === AppConstants.emptyString) && endDate !== AppConstants.invalidDateText && endDate !== AppConstants.emptyString && <p className={classes.certificateDescription}>from {startDate === AppConstants.invalidDateText || startDate === AppConstants.emptyString ? `<Start Date>` : startDate} to {endDate === AppConstants.invalidDateText || endDate === AppConstants.emptyString ? `<End Date>` : endDate}</p>}
                {(endDate === AppConstants.invalidDateText || endDate === AppConstants.emptyString) && startDate !== AppConstants.invalidDateText && startDate !== AppConstants.emptyString && <p className={classes.certificateDescription}>from {startDate === AppConstants.invalidDateText || startDate === AppConstants.emptyString ? `<Start Date>` : startDate} to {endDate === AppConstants.invalidDateText || endDate === AppConstants.emptyString ? `<End Date>` : endDate}</p>}
            </div>
            <div className={classes.certificateMessage}>{values.certificateMessage?.length ? values.certificateMessage : "<Certificate Message>"}</div>
            <div className={classes.signatorySection}>
                {(values.signatoryOneName?.length > 0 || values.signatoryOneDesignation?.length > 0 || values.signatoryOneOrganization?.length > 0 || values.signatoryImageOne?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[0] && <img className={classes.certificateLogo} src={previewSignature[0]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryOneName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryOneDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryOneOrganization}</div>
                        </div>
                    </div>
                }
                {(values.signatoryTwoName?.length > 0 || values.signatoryTwoDesignation?.length > 0 || values.signatoryTwoOrganization?.length > 0 || values.signatoryImageTwo?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[1] && <img className={classes.certificateLogo} src={previewSignature[1]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryTwoName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryTwoDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryTwoOrganization}</div>
                        </div>
                    </div>}
                {(values.signatoryThreeName?.length > 0 || values.signatoryThreeDesignation?.length > 0 || values.signatoryThreeOrganization?.length > 0 || values.signatoryImageThree?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[2] && <img className={classes.certificateLogo} src={previewSignature[2]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryThreeName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryThreeDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryThreeOrganization}</div>
                        </div>
                    </div>}
            </div>
        </div>
    </div>
    );
}
