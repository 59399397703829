import { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import validator from "validator";
import { MdDelete } from 'react-icons/md'
import { Tooltip } from "react-tooltip";
import { RiInformationFill } from "react-icons/ri";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import classes from "../../createCourseComponent/addCourseDetails/AddCourseDetails.module.css";
import formClass from "../../../common/FormStyling.module.css";
import moment from 'moment';
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { AiOutlineDownload } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import ReactQuill, { Quill } from 'react-quill';
import { DateTime } from "luxon";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";

export const EditWorkshopDetails = (props) => {
    const { skills, saveCurrentStep, stage, changeBasicFormDetailsData, changeBasicDetailsFormError, workshopData, status } = props;
    const formLabels = AppConstants.workshopDetailsFormLabels;
    const supportedImageFormats = AppConstants.supportedImageFormats;
    const modeOfDelivery = AppConstants.modeOfDelivery;
    const stages = AppConstants.editWorkshopActions;
    const workshopStatus = AppConstants.workshopStatus;
    const [videoSpinner, setVideoSpinner] = useState(AppConstants.falseText);
    const [registrationDateAdded, setRegistrationDateAdded] = useState(workshopData.workshopUtcRegistrationClosesOn ? AppConstants.trueText : AppConstants.falseText);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(workshopData.workshopUtcRegistrationClosesOn ? AppConstants.trueText : AppConstants.falseText);
    const [uploadedPromotionImageWeb, setUploadedPromotionImageWeb] = useState(workshopData.promotionImageWebAbsolutePath);
    const [uploadedPromotionImageTablet, setUploadedPromotionImageTablet] = useState(workshopData.promotionImageTabletAbsolutePath);
    const [uploadedPromotionImageMobile, setUploadedPromotionImageMobile] = useState(workshopData.promotionImageMobileAbsolutePath);
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState(workshopData.thumbnailImageAbsolutePath);
    const [uploadedPromotionVideo, setUploadedPromotionVideo] = useState(workshopData.promotionVideoAbsolutePath);
    const [uploadedDescriptionDocument, setUploadedDescriptionDocument] = useState(workshopData.descriptionDocumentAbsolutePath);
    const [descriptionDocumentSpinner, setDescriptionDocumentSpinner] = useState(AppConstants.falseText);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(AppConstants.falseText);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(AppConstants.falseText);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(AppConstants.falseText);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(AppConstants.falseText);
    const [description, setDescription] = useState(workshopData.workshopDescription);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.https}://`;
            }
            return url;
        } catch (e) {
            return url;
        }
    };

    let initialValues = {
        title: workshopData.workshopTitle,
        skill: workshopData.skillUniqueId,
        startDate: DateTime.fromISO(`${workshopData.utcStartDate.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat("yyyy-LL-dd"),
        endDate: DateTime.fromISO(`${workshopData.utcEndDate.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat("yyyy-LL-dd"),
        startTime: DateTime.fromISO(`${workshopData.utcStartDate.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm'),
        endTime: DateTime.fromISO(`${workshopData.utcEndDate.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm'),
        registrationClosingDate: workshopData.workshopUtcRegistrationClosesOn ? DateTime.fromISO(`${workshopData.workshopUtcRegistrationClosesOn?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat) : AppConstants.emptyString,
        registrationClosingTime: workshopData.workshopUtcRegistrationClosesOn ? DateTime.fromISO(`${workshopData.workshopUtcRegistrationClosesOn?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm') : AppConstants.emptyString,
        durationInHours: workshopData.workshopDuration,
        modeOfDelivery: workshopData.workshopModeOfDelivery,
        address: workshopData.address || AppConstants.emptyString,
        workshopLink: workshopData.workshopLink || AppConstants.emptyString,
        geoLocation: workshopData.geoLocation || AppConstants.emptyString,
        cost: workshopData.workshopCost ? workshopData.workshopCost : AppConstants.emptyString,
        expertName: workshopData.spocName || AppConstants.emptyString,
        expertEmail: workshopData.spocEmail || AppConstants.emptyString,
        expertMobile: workshopData.spocMobileNumber || AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionVideo: AppConstants.emptyString,
        descriptionDocument: AppConstants.emptyString,
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().max(100, ErrorMessages.invalidWorkshopTitle).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.label}`),
        descriptionDocument: Yup.mixed().notRequired().test("file_size_description", ErrorMessages.workshopDescriptionSizeLimit, (value) => { return value ? (value.size <= 50 * 1000 * 1000) : true }).test('file_Type', ErrorMessages.workshopDescriptionPdfFormat, (value) => { return value ? (["application/pdf"].includes(value.type)) : AppConstants.trueText }),
        skill: skills?.length && Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        startDate: (status !== workshopStatus.upcoming && workshopData.status === AppConstants.publishedText) ? Yup.date().notRequired() : Yup.date().required(ErrorMessages.workshopStartDateRequired).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.startDate.label} ${ErrorMessages.pastDateError}`),
        endDate: status === workshopStatus.past ? Yup.date().notRequired() : Yup.date().required(ErrorMessages.workshopEndDateRequired).test(
            "end_time_before_start", ErrorMessages.workshopEndDateError,
            function (value) {
                const { startDate } = this.parent;
                return moment(startDate).isSameOrBefore(moment(value));
            }
        ).test("end_date_before_today", ErrorMessages.workshopPastEndDateError, function (value) {
            const date = new Date(value);
            return moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(date, timeZone)).isAfter(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone));
        }),
        startTime: status !== workshopStatus.upcoming ? Yup.string().notRequired() : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}start time`).test(
            "start_time_test", ErrorMessages.workshopStartTimeError, function (value) {
                const { startDate } = this.parent;
                if (startDate) {
                    const d = new Date(startDate);
                    let hourMins = value.split(":");
                    let currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis()
                } else {
                    return AppConstants.trueText;
                }
            }
        ),
        endTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}end time`).test(
            "end_time_test", ErrorMessages.workshopEndTimeError,
            function (value) {
                const { startTime, startDate, endDate } = this.parent;
                // if start date and end date are same then check end time must be greater than start time
                if (endDate) {
                    if (moment(endDate).isSame(moment(startDate))) {
                        return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
                    } else {
                        return AppConstants.trueText;
                    }
                } else {
                    return AppConstants.trueText;
                }
            }
        ).test("end_time_before_current_test", ErrorMessages.workshopEndTimeBeforeCurrentTime, function (value) {
            const { endDate } = this.parent;
            if (endDate) {
                const d = new Date(endDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedEndDate = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                return selectedEndDate.toMillis() > currentDateTime.toMillis()
            } else {
                return AppConstants.trueText;
            }
        }),
        registrationClosingDate: (moment(workshopData.workshopRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).test(
            "registration_closing_edit_test", "Invalid Registration closing date", function (value) {
                if (status === workshopStatus.past) {
                    return true;
                }
                const { endDate } = this.parent;
                const dateTime = DateTime.fromJSDate(new Date(workshopData.workshopRegistrationClosesOn)).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                return (value.toString() === dateTime.toJSDate().toString()) || moment(value).isBetween(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone(timeZone).toJSDate(), endDate, AppConstants.nullText, AppConstants.trueText);
            }
        ) : (status === workshopStatus.past) ? Yup.date().notRequired() : registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.workshopRegistrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`) : Yup.date().notRequired()),
        registrationClosingTime: (status === workshopStatus.past) ? Yup.string().notRequired() : registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.workshopRegistrationTimeBeforeEndTimeError,
            function (value) {
                const { endTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return AppConstants.trueText;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                if (registrationClosingDate) {
                    const d = new Date(registrationClosingDate);
                    let hourMins = value.split(":");
                    let currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    if (moment(workshopData.workshopRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() !== workshopData.workshopRegistrationClosesOn) && selectedDateTime.toMillis() < currentDateTime.toMillis()) {
                        return AppConstants.falseText;
                    } else if (moment(workshopData.workshopRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() === workshopData.workshopRegistrationClosesOn)) {
                        return AppConstants.trueText;
                    } else return selectedDateTime.toMillis() > currentDateTime.toMillis();
                } else {
                    return AppConstants.trueText;
                }
            }) : Yup.string().notRequired(),
        durationInHours: Yup.number().required(ErrorMessages.workshopDurationRequired).min(0.1, ErrorMessages.workshopDurationError).test(
            "step_of_0_5",
            ErrorMessages.workshopDurationIn30intervalError,
            function (value) {
                return Math.abs(value % 0.5) === 0
            }
        ),
        cost: Yup.number().notRequired().min(0, ErrorMessages.minimumCostError),
        modeOfDelivery: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.modeOfDeliveryText}`).oneOf(AppConstants.modeOfDelivery),
        address: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.workshopTypeOffline,
            then: () => Yup.string().trim().required(ErrorMessages.enterAddressError)
        }),
        workshopLink: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOnline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for workshop link`,
                function (value = "") { return validator.isURL(value) })
        }),
        geoLocation: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.workshopTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for geo location`,
                function (value = "") { return validator.isURL(value) })
        }),
        expertEmail: Yup.string().notRequired().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, ErrorMessages.invalidEmailFormat),
        expertName: Yup.string().notRequired().matches(/^[a-zA-Z .]*$/, ErrorMessages.invalidUserNameWithRegex),
        expertMobile: Yup.string().notRequired().matches(/^[1-9]{1}\d{5,}$/, "Please Enter a valid mobile number"),
        thumbnailImage: (!uploadedThumbnailImage) ? Yup.mixed().notRequired().test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizeThumbnail', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText })
            : Yup.mixed().notRequired().test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizeThumbnail', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText }),
        promotionImageForWeb: (!uploadedPromotionImageWeb) ? Yup.mixed().notRequired().test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizeweb', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText })
            : Yup.mixed().notRequired().test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizeweb', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizetablet', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileTypeMobile', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizemobile', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : AppConstants.trueText }),
        promotionVideo: Yup.mixed().notRequired().test("fileTypeVideo", ErrorMessages.promotionVideoTypeError, (value) => { return value ? (AppConstants.promotionalVideoAllowedFormats.includes(value.type)) : AppConstants.trueText }).test('fileSizeVideo', ErrorMessages.promotionVideoFileSizeError, (value) => { return value ? (value.size <= 200 * 1000 * 1000) : AppConstants.trueText })
    });

    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const checkValidMobile = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const { values, touched, errors, setFieldValue, handleBlur, handleChange, setFieldTouched } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: AppConstants.trueText,
        handleSubmit: () => { }
    });

    const extractFileName = (name) => {
        let arr = name.split("/");
        const regex = /[0-9]*-/
        return arr[4]?.replace(regex, "");
    };

    const getDataTransfer = (name) => {
        const dataTransfer = new DataTransfer()
        const file = new File([''], name, { type: 'text/plain' })
        dataTransfer.items.add(file);
        return dataTransfer;
    }

    useEffect(() => {
        const changeParentState = () => {
            changeBasicFormDetailsData({
                ...values,
                description,
                uploadedDescriptionDocument,
                uploadedThumbnailImage, uploadedPromotionImageWeb,
                uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedPromotionVideo
            });
        }
        changeParentState();
    }, [values, description, uploadedDescriptionDocument, uploadedThumbnailImage, uploadedPromotionImageWeb,
        uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedPromotionVideo, status]);

    useEffect(() => {
        const changeParentState = () => {
            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors);
                } else {
                    changeBasicDetailsFormError({ description: "Please enter a description", ...errors });
                }
            } else {
                changeBasicDetailsFormError(errors);
            }
        }
        changeParentState();
    }, [errors, description]);

    const changeModeOfDelivery = (event) => {
        setFieldValue(formLabels.modeOfDelivery.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.competitionTypeOffline) {
                setFieldValue(formLabels.workshopLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.competitionTypeOnline) {
                setFieldValue(formLabels.address.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.address.name, AppConstants.emptyString);
            setFieldValue(formLabels.workshopLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    };

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldTouched(id);
        setFieldValue(id, AppConstants.emptyString);
    };

    const downloadFile = async (fileUrl, name) => {
        let ext = getFileExtension(fileUrl)
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${workshopData.workshopTitle}-${name}${AppConstants.dot}${ext}`);
        if (resp) {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        } else {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        }
    };

    const downloadVideoFile = async (fileUrl, name) => {
        setVideoSpinner(AppConstants.trueText);;
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${workshopData.workshopTitle}-${name}`);
        if (resp) {
            setVideoSpinner(AppConstants.falseText);
        } else {
            setVideoSpinner(AppConstants.falseText);
        }
    };

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(AppConstants.trueText);
        } else {
            setRegistrationDateAdded(AppConstants.falseText);
        }
    }

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(AppConstants.trueText)
        } else {
            setRegistrationTimeAdded(AppConstants.falseText)
        }
    };

    const getFileExtension = (url) => {
        return url.split(".").pop();
    };

    const moveToNextSection = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const getRemainingLengthText = (limit, textField) => {
        if (!textField || textField === AppConstants.emptyString) {
            return AppConstants.emptyString;
        }

        return `${limit - textField.length} out of ${limit} characters remaining`;
    }

    return (
        <div className={classes.formContainer}>
            <Form noValidate>
                <Row className="mb-2" >
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === workshopStatus.past}
                            required placeholder="Workshop Title"
                            type="text" maxLength={100}
                            name={formLabels.title.name}
                            value={values.title} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.title && errors.title}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.title}</Form.Control.Feedback>
                        {values.title?.length > 0 && <span className={classes.remainingChars}>{getRemainingLengthText(100, values.title)}</span>}
                    </Form.Group>
                </Row>

                <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                    <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                        <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span><RiInformationFill data-tooltip-id="workshopDescriptionInfo" className={classes.infoButton} ></RiInformationFill></Form.Label>
                        <ReactQuill
                            formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                            onChange={setDescription} preserveWhitespace={true}
                            value={description}
                            className={classes.textEditor} theme="snow" placeholder="Description of your workshop"
                        />
                        <Tooltip className={classes.tooltipMessage} id="workshopDescriptionInfo"><span className={classes.tooltipMessage}>{AppConstants.workshopDescriptionInfo}</span></Tooltip>

                    </Form.Group>
                </Row>
                <Row className="mb-2 mt-3">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.descriptionDocument.name} name={formLabels.descriptionDocument.name} disabled={status === workshopStatus.past} accept=".pdf" label={formLabels.descriptionDocument.label} handleFileChange={e => { setFieldValue(formLabels.descriptionDocument.name, e.target.files[0]); setUploadedDescriptionDocument(AppConstants.emptyString) }} infoMessage={AppConstants.workshopBrochureInfo} inputFile={values.descriptionDocument}
                                    defaultFile={uploadedDescriptionDocument && getDataTransfer(extractFileName(workshopData.descriptionDocumentRelativePath) || "Description_Document").files[0]} />
                                {errors.descriptionDocument && <div className="errorText">{errors.descriptionDocument}</div>}
                                <Tooltip className={classes.tooltipMessage} id="workshopBrochureInfo"><span className={classes.tooltipMessage}>{AppConstants.workshopBrochureInfo}</span></Tooltip>
                            </Form.Group>
                        </div>
                        {workshopData.descriptionDocumentAbsolutePath && <button type="button" onClick={() => { downloadFile(workshopData.descriptionDocumentAbsolutePath, formLabels.descriptionDocument.name); setDescriptionDocumentSpinner(AppConstants.trueText); }} data-tooltip-id="downloaddescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{descriptionDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedDescriptionDocument(AppConstants.emptyString); removeFile(formLabels.descriptionDocument.name); }} data-tooltip-id="removedescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removedescriptionDoc"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloaddescriptionDoc"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row>
                    {skills?.length > 1 && <Form.Group className="mb-2">
                        <Form.Label className={`${(status !== workshopStatus.upcoming || (status === workshopStatus.upcoming && workshopData.participantDetails.length > 0 && workshopData.status === AppConstants.publishedText) || (status === workshopStatus.upcoming && workshopData.participantDetails.length > 0 && workshopData.status === AppConstants.workshopPublishedStatus.unpublished)) ? formClass.formLabelDisabled : formClass.formLabel}`}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.skill.name}
                            disabled={status !== workshopStatus.upcoming || (status === workshopStatus.upcoming && workshopData.participantDetails.length > 0 && workshopData.status === AppConstants.publishedText) || (status === workshopStatus.upcoming && workshopData.participantDetails.length > 0 && workshopData.status === AppConstants.workshopPublishedStatus.unpublished)}
                            value={values.skill} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.skill && errors.skill}>
                            <option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                            {skills?.map(option => (
                                <option key={option.skillId} value={option.skillId}>
                                    {option.skillName}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.skill}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.startDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={(workshopData.status === AppConstants.publishedText && status !== AppConstants.workshopStatus.upcoming) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.startDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={workshopData.status === AppConstants.publishedText && status !== AppConstants.workshopStatus.upcoming}
                            name={formLabels.startDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.startDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.startDate && errors.startDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.startTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                        <Form.Label className={(workshopData.status === AppConstants.publishedText && status !== AppConstants.workshopStatus.upcoming) ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.startTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.startTime.name}
                            type="time"
                            disabled={workshopData.status === AppConstants.publishedText && status !== AppConstants.workshopStatus.upcoming}
                            value={values.startTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.startTime && errors.startTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startTime}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === workshopStatus.past}
                            name={formLabels.endDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.endDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endDate && errors.endDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.endTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.endTime.name}
                            type="time"
                            value={values.endTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endTime && errors.endTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2 mt-2">
                    <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                        <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                        <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.workshopRegistrationDeadlineInfo}</span></Tooltip>
                    </Form.Group>
                </Row>

                <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                        <Form.Label className={status === workshopStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingDate.name}
                            disabled={status === workshopStatus.past}
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                            value={values.registrationClosingDate} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingDate(e)}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                        <Form.Label className={status === workshopStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingTime.name}
                            disabled={status === workshopStatus.past}
                            type="time"
                            value={values.registrationClosingTime} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingTime(e)}
                            isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.durationInHours.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === workshopStatus.past}
                            type="number"
                            min={0}
                            placeholder="Duration in hours"
                            onKeyDown={checkValidCost}
                            name={formLabels.durationInHours.name}
                            value={values.durationInHours} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.durationInHours && errors.durationInHours}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.durationInHours}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={workshopData.participantDetails.length > 0 ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.cost.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            disabled={status === workshopStatus.past || workshopData.participantDetails.length > 0}
                            placeholder="Workshop cost"
                            name={formLabels.cost.name}
                            value={values.cost} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.cost && errors.cost}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.modeOfDelivery.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            disabled={status === workshopStatus.past}
                            name={formLabels.modeOfDelivery.name}
                            value={values.modeOfDelivery} onBlur={handleBlur}
                            onChange={(event) => changeModeOfDelivery(event)}
                            isInvalid={touched.modeOfDelivery && errors.modeOfDelivery}>
                            <option value={AppConstants.emptyString} defaultValue>Select Type</option>
                            {modeOfDelivery?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.competitionType}</Form.Control.Feedback>
                    </Form.Group>
                    {values.modeOfDelivery === modeOfDelivery[0] && <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.workshopLink.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url" placeholder="Workshop link"
                            disabled={status === workshopStatus.past}
                            name={formLabels.workshopLink.name}
                            value={values.workshopLink} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.workshopLink && errors.workshopLink} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.workshopLink}</Form.Control.Feedback>
                    </Form.Group>}
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenueLocation">
                        <Form.Label className={formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url"
                            placeholder="Workshop venue location URL"
                            name={formLabels.geoLocation.name}
                            value={values.geoLocation} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.geoLocation && errors.geoLocation} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row>
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                        <Form.Label className={formClass.formLabel}>{formLabels.address.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formTextAreaInput}
                            as="textarea"
                            disabled={status === workshopStatus.past}
                            placeholder="Workshop address"
                            name={formLabels.address.name}
                            value={values.address} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address && errors.address} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>

                <Row>
                    <Form.Label className={classes.contactLabel}>SPOC Details</Form.Label>
                </Row>
                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertName.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === workshopStatus.past}
                            placeholder="SPOC name"
                            type="text" maxLength={30}
                            name={formLabels.expertName.name}
                            value={values.expertName} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertName && errors.expertName}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertMobile.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC mobile number"
                            type="number"
                            name={formLabels.expertMobile.name}
                            value={values.expertMobile} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.expertMobile && errors.expertMobile}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertMobile}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertEmail.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC email id"
                            type="text" maxLength={50}
                            name={formLabels.expertEmail.name}
                            value={values.expertEmail} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertEmail && errors.expertEmail}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertEmail}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} disabled={status === workshopStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={e => { setFieldValue("thumbnailImage", e.target.files[0]); setUploadedThumbnailImage(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsThumbnailMessage} inputFile={values.thumbnailImage}
                                    defaultFile={uploadedThumbnailImage && getDataTransfer(extractFileName(workshopData.thumbnailImageRelativePath) || "Thumbnail_Image").files[0]} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </Form.Group>
                        </div>
                        {workshopData.thumbnailImageAbsolutePath && <button type="button" onClick={() => { downloadFile(workshopData.thumbnailImageAbsolutePath, formLabels.thumbnailImage.name); setThumbnailSpinner(AppConstants.trueText); }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedThumbnailImage(AppConstants.emptyString); removeFile(formLabels.thumbnailImage.name); }} data-tooltip-id="removeThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadThumbnail"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                            <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} disabled={status === workshopStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={e => { setFieldValue("promotionImageForWeb", e.target.files[0]); setUploadedPromotionImageWeb(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsWebBannerMessage} inputFile={values.promotionImageForWeb}
                                defaultFile={uploadedPromotionImageWeb ? getDataTransfer(extractFileName(workshopData.promotionImageWebRelativePath) || "Promotion_Image_Web").files[0] : ""} />
                            {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                        </Form.Group>
                        {workshopData.promotionImageWebAbsolutePath && <button type="button" onClick={() => { downloadFile(workshopData.promotionImageWebAbsolutePath, formLabels.promotionImageForWeb.name); setPromotionImageWebSpinner(AppConstants.trueText); }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageWeb(AppConstants.emptyString); removeFile(formLabels.promotionImageForWeb.name) }} data-tooltip-id="removeWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadWebImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} disabled={status === workshopStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={e => { setFieldValue("promotionImageForTablet", e.target.files[0]); setUploadedPromotionImageTablet(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsTabletBannerMessage} inputFile={values.promotionImageForTablet}
                                defaultFile={uploadedPromotionImageTablet ? getDataTransfer(extractFileName(workshopData.promotionImageTabletRelativePath) || "Promotion_Image_Tablet").files[0] : ""} />
                            {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                        </Form.Group>
                        {workshopData.promotionImageTabletAbsolutePath && <button type="button" onClick={() => { downloadFile(workshopData.promotionImageTabletAbsolutePath, formLabels.promotionImageForTablet.name); setPromotionImageTabletSpinner(AppConstants.trueText); }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageTablet(AppConstants.emptyString); removeFile(formLabels.promotionImageForTablet.name) }} data-tooltip-id="removeTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadTabletImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} disabled={status === workshopStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={e => { setFieldValue("promotionImageForMobile", e.target.files[0]); setUploadedPromotionImageMobile(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsMobileBannerMessage} inputFile={values.promotionImageForMobile}
                                defaultFile={uploadedPromotionImageMobile ? getDataTransfer(extractFileName(workshopData.promotionImageMobileRelativePath) || "Promotion_Image_Mobile").files[0] : ""} />
                            {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                        </Form.Group>
                        {workshopData.promotionImageMobileAbsolutePath && <button type="button" onClick={() => { downloadFile(workshopData.promotionImageMobileAbsolutePath, formLabels.promotionImageForMobile.name); setPromotionImageMobileSpinner(AppConstants.trueText);; }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageMobile(AppConstants.emptyString); removeFile(formLabels.promotionImageForMobile.name) }} data-tooltip-id="removeMobileImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadMoblieImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionVideo.name} name={formLabels.promotionVideo.name} disabled={status === workshopStatus.past} accept="video/mp4,video/mkv,video/x-m4v,video/*" label={formLabels.promotionVideo.label} handleFileChange={e => { setFieldValue(formLabels.promotionVideo.name, e.target.files[0]); setUploadedPromotionVideo(AppConstants.emptyString) }} infoMessage={AppConstants.workshopPromotionVideoInfo} inputFile={values.promotionVideo}
                                defaultFile={uploadedPromotionVideo ? getDataTransfer(extractFileName(workshopData.promotionVideoRelativePath) || "Promotion_Video").files[0] : ""} />
                            {errors.promotionVideo && <div className="errorText">{errors.promotionVideo}</div>}
                        </Form.Group>
                        {workshopData.promotionVideoAbsolutePath && <button type="button" onClick={() => downloadVideoFile(workshopData.promotionVideoAbsolutePath, `${formLabels.promotionVideo.name}.${getFileExtension(workshopData.promotionVideoRelativePath)}`)} data-tooltip-id="downloadVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{videoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== workshopStatus.past && <button type="button" onClick={() => { setUploadedPromotionVideo(AppConstants.emptyString); removeFile(formLabels.promotionVideo.name) }} data-tooltip-id="removePromotionVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removePromotionVideo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadVideo"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
            </Form>
            <div className={classes.nextBtn}>
                <Button onClick={() => moveToNextSection()}>{AppConstants.nextText}</Button>
            </div>
        </div>
    )
}
