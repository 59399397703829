import React, { useState, useEffect, useCallback } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { Form } from "react-bootstrap";
import { BiChevronDown } from 'react-icons/bi';
import classes from './StudentProfile.module.scss';

export const AdvanceProfilingStep2 = ({ nextStep, formValues, setFormValues }) => {
    const [selectedState, setSelectedState] = useState(formValues.state);
    const [statesList, setStatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const selectedCountry = formValues.country;

    const fetchCities = useCallback(async () => {
        if (selectedState !== AppConstants.emptyString && statesList.length > 0) {
            let stateISO = statesList.filter(state => state.name === selectedState)[0]?.iso2;
            let config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(`${AppConstants.getCountriesApi}${AppConstants.forwardSlash}${selectedCountry}/states${AppConstants.forwardSlash}${stateISO}${AppConstants.getCityUri}`, config);
            const responseData = await response.json();
            if (responseData.length !== 0 && (responseData instanceof Array)) {
                setCitiesList(responseData);
            } else {
                setCitiesList([]);
            }
            return null;
        }
    }, [selectedState, selectedCountry, setCitiesList, statesList]);

    useEffect(() => {
        const fetchStates = async () => {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(AppConstants.getStatesApi, config);
            const responseData = await response.json();
            const sortedData = responseData.sort((a, b) => a.name.localeCompare(b.name));
            setStatesList(sortedData);
        };

        fetchStates();
    }, [setStatesList]);

    useEffect(() => {
        fetchCities();
    }, [selectedState, fetchCities]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //method called on selecting state
    function handleStateChange(e) {
        const { name, value } = e.target;
        let updatedValues = { ...formValues };

        if (!e.target.value) {
            setCitiesList([]);
        }

        setSelectedState(e.target.value);
        if (selectedCountry === AppConstants.countryAbbreviationIndia) {
            updatedValues = { ...updatedValues, [name]: value, "city": AppConstants.emptyString };
        } else {
            updatedValues = { ...updatedValues, [name]: value };
            setFormValues();
        }
        setFormValues({ ...updatedValues });
    };

    return (
        <div className={classes.step2Container}>
            <p className={classes.formLocationText}>Could you share your location with us?</p>
            <p className={classes.formLocationSubText}>We’d love to recommend some learning activities based on your area!</p>
            <div className='col-md-12 col-sm-12 col-xs-12 mb-2' >
                <div className={classes.formGroup}>
                    {selectedCountry === AppConstants.countryAbbreviationIndia && <div className={classes.inputWrapper}>
                        <Form.Select className={formValues.state ? classes.formInput : classes.formInputPlaceholder} onChange={handleStateChange} name="state" required value={formValues.state}>
                            <option value="">Please select state</option>
                            {statesList.map((state) => (
                                <option value={state.name} key={state.id}>{state.name}</option>
                            ))}
                        </Form.Select>
                        <BiChevronDown className={classes.inputIcon} />
                    </div>}
                </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12 mb-2'>
                <div className={classes.formGroup}>
                    {selectedCountry === AppConstants.countryAbbreviationIndia && <div className={classes.inputWrapper}>
                        <Form.Select className={formValues.city ? classes.formInput : classes.formInputPlaceholder} value={formValues.city} onChange={handleChange} name="city" required>
                            <option value="" >Please select city</option>
                            {citiesList?.map((city) => (
                                <option value={city.name} key={city.id}>{city.name}</option>
                            ))}
                        </Form.Select>
                        <BiChevronDown className={classes.inputIcon} />
                    </div>}
                </div>
            </div>
            {selectedCountry !== AppConstants.countryAbbreviationIndia && <div className='col-md-12 col-sm-12 col-xs-12 mb-2'>
                <div className={classes.formGroup}>
                    <div className={classes.inputWrapper}>
                        <Form.Control className={formValues.city ? classes.formInput : classes.formInputPlaceholder} placeholder="Enter state" name="state" required value={formValues.state} onChange={handleChange} type='text' />
                    </div>
                </div>
            </div>}
            {selectedCountry !== AppConstants.countryAbbreviationIndia && <div className='col-md-12 col-sm-12 col-xs-12 mb-2'>
                <div className={classes.formGroup}>
                    <div className={classes.inputWrapper}>
                        <Form.Control className={formValues.city ? classes.formInput : classes.formInputPlaceholder} placeholder="Enter city" name="city" required value={formValues.city} onChange={handleChange} type='text' />
                    </div>
                </div>
            </div>}
        </div>
    );

}