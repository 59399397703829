import { useContext, useEffect } from "react";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { useLoaderData, useNavigate } from 'react-router-dom';
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
import classes from "../expert/ExpressionOfInterestPage.module.css";
import { ExpressionOfInterestThankyouPageDetailsSection } from "../../component/eoiPageComponents/expressionOfInterestThankyouPageDetailsSection/ExpressionOfInterestThankyouPageDetailsSection";
import { ExpressionOfInterestPageBanner } from "../../component/eoiPageComponents/expressionOfInterestPageBanner/ExpressionOfInterestPageBanner";
import { SelectionContext } from '../../context/SelectionContext';
import { AppConstants } from "../../constants/AppConstants";

export const ExpressionOfInterestThankyouPage = () => {
    const [landingPageData, eoiImageData, eoiCarouselData] = useLoaderData();
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const { expertName, setExpertName } = useContext(SelectionContext);

    useEffect(() => {
        if (expertName === '' || expertName === null || expertName === undefined) {
            navigate(errorPageUrl);
        }
    }, [expertName, navigate, errorPageUrl])

    // scroll function
    const scrollToSection = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    };

    scrollToSection();
    return (
        <>
            <LoginPageTopNavigation landingPageData={landingPageData} />
            <div className="row">
                <ExpressionOfInterestPageBanner eoiCarouselData={eoiCarouselData} />
            </div>
            <div className={`row mb-3 ${classes.messageSection}`}>
                <ExpressionOfInterestThankyouPageDetailsSection expertName={expertName} />
            </div>
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    )
}