import { useState, useEffect, useRef } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import { MdClear } from "react-icons/md";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./SuggestiveCompetitionSearch.css";
import { AppConstants } from "../../constants/AppConstants";

export const SuggestiveCompetitionSearch = (props) => {
    const [competitionsList, setCompetitionsList] = useState([]);
    const typeaheadRef = useRef(null);

    useEffect(() => {
        setCompetitionsList(props.competitionsList);
    }, [props.competitionsList]);


    const handleChange = (value) => {
        if (value[0]?.competitionId) {
            props.handleCompetitionChange(value[0]);
        } else {
            props.handleCompetitionChange(AppConstants.emptyString);
        }
    };

    const clearTypeaheadValue = () => {
        typeaheadRef?.current?.clear();
        handleChange([]);
    }

    return (
        <div className="SuggestiveCompetitionSearch">
            <div className="SuggestiveCompetitionSearchInput">
                <label className="suggestedSearchLabel">Competition</label>
                <div className="suggestiveSearchTypeaheadContainer">
                    <Typeahead
                        ref={typeaheadRef}
                        className="SuggestedSearchInputBox"
                        placeholder="Search Competition"
                        name="suggestedCompetitionSearch"
                        id="suggestedCompetitionSearch"
                        labelKey="key"
                        filterBy={['title', 'uniqueId']}
                        onChange={handleChange}
                        options={competitionsList}
                    />
                    <MdClear className="suggestedSearchClearIcon" onClick={clearTypeaheadValue} />
                </div>
            </div>
        </div>
    )
}