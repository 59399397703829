import React from 'react';
import { ExpertTodaysActivitiesWidget } from '../../component/widgets/ExpertTodaysActivitiesWidget';
import { CreatorHomePageCarousel } from '../../component/creatorHomePageCarousel/CreatorHomePageCarousel';

export const ExpertHomePage = () => {
    return (
        <>
            <div className="expertContentSection">
                <ExpertTodaysActivitiesWidget />
                <CreatorHomePageCarousel />
            </div>
        </>
    )
}