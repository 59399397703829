import classes from "./ExpertTodaysActivitySection.module.css";
import './widget.css';
import { AppConstants } from "../../constants/AppConstants";

export const ExpertTodaysActivitiesWidget = () => {

    return (
        <>
            <>
                <h3 className={classes.heading}>{AppConstants.todaysActivitiesText}</h3>
                <p className='noDataAvailableError'>{AppConstants.noTodaysActivitiesAvailable}</p>

            </>
        </>
    )
}