import React, { useState, useEffect, useCallback } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import classes from "./AddMedalCriteria.module.css";
import { Col, Form, Row, Table } from "react-bootstrap";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FormValidationUtils } from "../../../utils/FormValidationUtils";
import { FaMedal } from 'react-icons/fa';
import formClass from "../../../common/FormStyling.module.css";

export const AddMedalCriteria = (props) => {
    const gymnasticsMedals = AppConstants.gymnasticsMedals;
    const instituteTypes = AppConstants.agencyTypes;
    const medals = Object.values(gymnasticsMedals);
    const { changeMedalsData, competitionDetails, rubricsConfigured, showPercentageFlag, enableTeamEnrolments, markingType } = props;
    const gymnasticsAwardType = Object.values(AppConstants.gymnasticsAwardType);
    const initialvalues = {
        gold: AppConstants.emptyString,
        silver: AppConstants.emptyString,
        bronze: AppConstants.emptyString
    };

    const [academyValues, setAcademyValues] = useState(initialvalues);
    const [schoolValues, setSchoolValues] = useState(initialvalues);
    const [individualValues, setIndividualValues] = useState(initialvalues);
    const [academyErrors, setAcademyErrors] = useState(initialvalues);
    const [schoolErrors, setSchoolErrors] = useState(initialvalues);
    const [individualErrors, setIndividualErrors] = useState(initialvalues);
    const [awardType, setAwardType] = useState(AppConstants.emptyString);
    const [coupleCriterias, setCoupleCriterias] = useState(AppConstants.falseText);

    const resetState = useCallback(() => {
        setAwardType(AppConstants.emptyString);
        setAcademyValues(initialvalues);
        setAcademyErrors(initialvalues);
        setSchoolValues(initialvalues);
        setSchoolErrors(initialvalues);
        setIndividualValues(initialvalues);
        setIndividualErrors(initialvalues);
        setCoupleCriterias(AppConstants.falseText);
    }, []);

    useEffect(() => {
        resetState();
    }, [enableTeamEnrolments, resetState]);

    useEffect(() => {
        if (competitionDetails.id && (!rubricsConfigured || showPercentageFlag !== AppConstants.yText)) {
            resetState();
            if (competitionDetails.awardType === AppConstants.gymnasticsAwardType.rankBased) {
                setAwardType(competitionDetails.awardType);
            }
        } else if (competitionDetails.id && rubricsConfigured && showPercentageFlag === AppConstants.yText) {
            setAwardType(competitionDetails.awardType || AppConstants.emptyString);
            const medalDetails = competitionDetails.competitionMedalDetails || [];
            const academy = { ...initialvalues };
            const school = { ...initialvalues };
            const individual = { ...initialvalues };
            if (medalDetails.length > 0 && showPercentageFlag === AppConstants.yText) {
                medalDetails.forEach(({ medal, instituteType, totalScorePercentage }) => {
                    const medalText = medal.toLowerCase();
                    const percentage = totalScorePercentage.toString();
                    if (instituteType === instituteTypes.academy) academy[medalText] = percentage;
                    if (instituteType === instituteTypes.school) school[medalText] = percentage;
                    if (instituteType === AppConstants.nullText) individual[medalText] = percentage;
                });
            }

            setAcademyValues(academy);
            setSchoolValues(school);
            setIndividualValues(individual)
        } else if (!rubricsConfigured || !competitionDetails.id || showPercentageFlag !== AppConstants.yText) {
            resetState();
        }
    }, [competitionDetails, rubricsConfigured, showPercentageFlag, resetState]);

    useEffect(() => {
        const changeParentState = () => {
            changeMedalsData({ medalData: { academy: academyValues, school: schoolValues, individual: individualValues, awardType }, medalErrors: { academy: academyErrors, school: schoolErrors, individual: individualErrors } });
        }

        changeParentState();
    }, [academyValues, schoolValues, schoolErrors, academyErrors, individualValues, individualErrors, awardType]);

    const updateFormErrors = (updatedValues, updatedErrors, type) => {
        if (updatedValues.silver && updatedValues.gold && updatedValues.silver >= updatedValues.gold) {
            updatedErrors.silver = type === AppConstants.individualText ? ErrorMessages.silverMedalCriteriaError : `${ErrorMessages.silverMedalCriteriaError} for ${type.toLowerCase()}`;
        } else {
            updatedErrors.silver = AppConstants.emptyString;
        }

        if (updatedValues.bronze && updatedValues.silver && updatedValues.bronze >= updatedValues.silver) {
            updatedErrors.bronze = (type === AppConstants.individualText) ? ErrorMessages.bronzeAndSilverMedalCriteriaError : `${ErrorMessages.bronzeAndSilverMedalCriteriaError} for ${type.toLowerCase()}`;
        } else if (updatedValues.bronze && updatedValues.gold && updatedValues.bronze >= updatedValues.gold) {
            updatedErrors.bronze = (type === AppConstants.individualText) ? ErrorMessages.bronzeAndGoldMedalCriteriaError : `${ErrorMessages.bronzeAndGoldMedalCriteriaError} for ${type.toLowerCase()}`;
        } else {
            updatedErrors.bronze = AppConstants.emptyString;
        }
    };

    const handleChange = (event, type) => {
        const { name, value } = event.target;
        const newValue = value !== AppConstants.emptyString ? Number(value) : value;

        if (newValue > 100) return;

        if (coupleCriterias) {
            updateValuesForCoupledCriteria(name, newValue);
        } else {
            const updatedValues = { ...(type === instituteTypes.academy ? academyValues : type === instituteTypes.school ? schoolValues : individualValues), [name]: newValue };
            const updatedErrors = { ...initialvalues };

            updateFormErrors(updatedValues, updatedErrors, type);

            if (type === instituteTypes.academy) {
                setAcademyValues(updatedValues);
                setAcademyErrors(updatedErrors);
            } else if (type === instituteTypes.school) {
                setSchoolValues(updatedValues);
                setSchoolErrors(updatedErrors);
            } else {
                setIndividualValues(updatedValues);
                setIndividualErrors(updatedErrors);
            }
        }
    }

    const updateValuesForCoupledCriteria = (name, newValue) => {
        const updatedAcademyValues = { ...academyValues, [name]: newValue };
        const updatedSchoolValues = { ...schoolValues, [name]: newValue };

        const updatedAcademyErrors = { ...academyErrors };
        const updatedSchoolErrors = { ...schoolErrors };

        updateFormErrors(updatedAcademyValues, updatedAcademyErrors, instituteTypes.academy);
        updateFormErrors(updatedSchoolValues, updatedSchoolErrors, instituteTypes.school);

        setAcademyValues(updatedAcademyValues);
        setSchoolValues(updatedSchoolValues);

        setAcademyErrors(updatedAcademyErrors);
        setSchoolErrors(updatedSchoolErrors);
    }

    const getMedalInfoText = (index, values) => {
        const { gold, silver, bronze } = values;
        let medalText = AppConstants.emptyString;

        if (index === 0 && gold) {
            medalText = `(${gold}% & above)`;
        } else if (index === 1 && silver) {
            if (gold) {
                medalText = `(${silver}% and above, but below ${gold}%)`;
            } else {
                medalText = `(${silver}% & above)`;
            }
        } else if (index === 2 && bronze) {
            if (silver) {
                medalText = `(${bronze}% and above, but below ${silver}%)`;
            } else if (gold) {
                medalText = `(${bronze}% and above, but below ${gold}%)`;
            } else {
                medalText = `(${bronze}% & above)`;
            }
        }

        return medalText;
    };

    const changeAwardType = (event) => {
        const { value } = event.target;
        setAwardType(value);
        if (value !== AppConstants.gymnasticsAwardType.medalBased) {
            setSchoolValues(initialvalues);
            setSchoolErrors(initialvalues);
            setAcademyValues(initialvalues);
            setAcademyErrors(initialvalues);
            setIndividualErrors(initialvalues);
            setIndividualValues(initialvalues);
            setCoupleCriterias(AppConstants.falseText);
        }
    };


    const renderMedalRow = (values, errors, type) => (
        <Table className={classes.medalTable} striped bordered hover cellPadding={0} cellSpacing={0} responsive="lg">
            <thead>
                <tr>
                    <th align="center" className={classes.headers}>Medal</th>
                    <th align="center" className={classes.headers}>Criteria (%)</th>
                </tr>
            </thead>
            <tbody>
                {medals.map(({ label, name, medalName }, index) => (
                    <tr key={index}>
                        <td align="center" className={classes.medalRowData}>
                            {label}&nbsp;<FaMedal className={medalName === 'Gold' ? classes.goldMedal : medalName === 'Silver' ? classes.silverMedal : classes.bronzeMedal} />
                        </td>
                        <td className={classes.medalCriteriaInputs}>
                            <input
                                type="number"
                                name={name}
                                className={classes.inputField}
                                value={values[name]}
                                onChange={(e) => handleChange(e, type)}
                                disabled={!rubricsConfigured || showPercentageFlag !== AppConstants.yText}
                                onKeyDown={(e) => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+"])}
                            />
                            <p className={classes.medalInfoText}>
                                {errors[name] ? "Invalid Range" : getMedalInfoText(index, values)}
                            </p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    return (
        <>
            <Row className={classes.generalInfoContainer}>
                <h3 className={classes.generalInfoHeading}>{AppConstants.generalInformationText}</h3>
                {enableTeamEnrolments ?
                    AppConstants.gymnasticsTeamMarkingAwardInfo.map((info, id) => <p key={id}
                        className={classes.generalInfoText}>
                        {info}
                    </p>)
                    : AppConstants.gymnasticsAwardGeneralInfo.map((info, id) => <p key={id}
                        className={classes.generalInfoText}>
                        {info}
                    </p>)
                }
            </Row>
            {!rubricsConfigured && <Row>
                <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />You need to configure judging parameters to add award criteria</p></div>
            </Row>}
            <Row className={classes.awardTypeInput} xs={1} sm={3} md={2} lg={2} xl={3}>
                <Form.Group className="mt-2 mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>Award Type<span className="redAstrisk">*</span></Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name="awardType"
                        value={awardType}
                        disabled={!rubricsConfigured}
                        aria-readonly={!rubricsConfigured}
                        onChange={changeAwardType}
                    ><option value={AppConstants.emptyString} defaultValue>Select award criteria</option>
                        {gymnasticsAwardType?.map((type, i) => (
                            <option key={i} value={type}>
                                {type}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row >

            {awardType === AppConstants.gymnasticsAwardType.medalBased && showPercentageFlag !== AppConstants.yText && <div className={classes.warningInfoContainer}>
                <div className={classes.warningMessage}><p><AiOutlineExclamationCircle /> Medal criteria can only be added when you select option to show percentage while configuring judging parameters</p></div>
            </div>}
            {awardType === AppConstants.gymnasticsAwardType.medalBased && showPercentageFlag === AppConstants.yText && enableTeamEnrolments && <Row className={classes.switchContainer}>
                <div className={classes.switchText}>Use same criteria for Academy and School <span><input type="checkbox" checked={coupleCriterias} onChange={() => setCoupleCriterias(!coupleCriterias)} /></span></div>
            </Row>}
            {awardType === AppConstants.gymnasticsAwardType.medalBased &&
                <>
                    {enableTeamEnrolments && <Row className={classes.instituteTypeHeading}>Criteria for Academy</Row>}
                    {enableTeamEnrolments && renderMedalRow(academyValues, academyErrors, instituteTypes.academy)}

                    {enableTeamEnrolments && <Row className={classes.instituteTypeHeading}>Criteria for School</Row>}
                    {enableTeamEnrolments && renderMedalRow(schoolValues, schoolErrors, instituteTypes.school)}

                    {!enableTeamEnrolments && renderMedalRow(individualValues, individualErrors, AppConstants.individualText)}
                </>}
        </>
    )
}