import { EnrolInCompetition } from "../component/studentPageComponents/EnrolInCompetition";
import { EnrolInCourse } from "../component/studentPageComponents/EnrolInCourse";
import { EnrolInWorkshop } from "../component/studentPageComponents/EnrolInWorkshop";
import { AppConstants } from "../constants/AppConstants";
import { ErrorMessages } from "../constants/ErrorMessages";
import { SuccessMessage } from "../constants/SuccessMessage";
import { DateTimeUtil } from "../utils/DateTimeUtil";
import { PaymentsUtil } from "../utils/PaymentsUtil";
import { useOnlineStatus } from "./useOnlineStatus";

export const useEnrolment = () => {
    const isOnlineRef = useOnlineStatus();

    const enrolmentMethods = {
        competition: async (details) => {
            return await EnrolInCompetition.enrol(details);
        },
        course: async (details) => {
            return await EnrolInCourse.enrol(details);
        },
        workshop: async (details) => {
            return await EnrolInWorkshop.enrol(details);
        },
        enrolTeam: async (details) => {
            return await EnrolInCompetition.enrolInTeamCompetitions(details)
        }
    };

    const getResponseObject = (response) => {
        if (response.message === SuccessMessage.paymentInitiatedMessage) {
            return {
                ...response,
                modalTypeError: AppConstants.falseText,
                paymentStatus: AppConstants.emptyString,
                showModal: AppConstants.trueText,
                showRazorpayPopup: AppConstants.trueText,
                networkError: AppConstants.falseText,
                shouldWarn: AppConstants.falseText
            }
        } else if (response.message === SuccessMessage.studentEnrollmentSuccess || response.message === SuccessMessage.teamEnrollmentSuccess || response.message === SuccessMessage.addDurationSuccessMessage || response.message === SuccessMessage.addClassSuccessMessage) {
            return {
                modalTypeError: AppConstants.falseText,
                message: response.message,
                paymentStatus: AppConstants.emptyString,
                showModal: AppConstants.trueText,
                showRazorpayPopup: AppConstants.falseText,
                networkError: AppConstants.falseText,
                shouldWarn: AppConstants.falseText
            }
        } else {
            return {
                modalTypeError: AppConstants.trueText,
                message: response.message,
                paymentStatus: AppConstants.emptyString,
                showModal: AppConstants.trueText,
                showRazorpayPopup: AppConstants.falseText,
                shouldWarn: AppConstants.falseText,
                networkError: response.message === ErrorMessages.networkIssueError ? AppConstants.trueText : AppConstants.falseText
            }
        }
    }

    const enrolInLearningActivity = async (learningActivityDetails, status) => {
        let response;
        const { uniqueId, learningActivityName, learningActivityType, learningActivityId, cost } = learningActivityDetails;
        if (cost === AppConstants.freeText || status === AppConstants.paymentStatus.verified) {
            response = await enrolmentMethods[learningActivityType.toLowerCase()](learningActivityDetails);
            if (!isOnlineRef?.current) {
                response.message = ErrorMessages.networkIssueError;
            }
        } else {
            response = await PaymentsUtil.initiatePaymentWithRazorpay({
                uniqueId: uniqueId,
                learningActivityType,
                learningActivityName,
                learningActivityId
            });
        };

        return getResponseObject(response);
    };

    const enrolInTeamCompetitions = async (learningActivityDetails, teamDetails, status) => {
        let response;
        const payload = {
            uniqueId: learningActivityDetails.uniqueId,
            locale: DateTimeUtil.getPreferredTimeZoneForUser(),
            learnersForTeamEnrolments: teamDetails,
            learningActivityType: AppConstants.learningActivitiesTypes.competition,
            offlineEnrolmentRequestFlag: AppConstants.nText,
            teamLimits: learningActivityDetails.teamsLimit,
            cost: learningActivityDetails.cost
        }

        if (learningActivityDetails.cost === AppConstants.freeText || status === AppConstants.paymentStatus.verified) {
            response = await enrolmentMethods.enrolTeam(payload);
            if (!isOnlineRef?.current) {
                response.message = ErrorMessages.networkIssueError;
            }
        } else {
            response = await PaymentsUtil.initiatePaymentsForTeamEnrolments({
                payload,
                uniqueId: learningActivityDetails.uniqueId,
                learningActivityType: AppConstants.learningActivitiesTypes.competition,
                learningActivityName: learningActivityDetails.title,
                learningActivityId: learningActivityDetails.id,
                teamDetails
            });
        }

        return getResponseObject(response);
    };
    return { enrolInLearningActivity, enrolInTeamCompetitions };
}

