import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import classes from '../CompetitionsCards.module.css';
import Moment from 'react-moment';
import 'moment-timezone';
import '../CompetitionCards.css';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { AppConstants } from "../../../constants/AppConstants";
import { TbShare3 } from "react-icons/tb";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import Auth from "../../../utils/Auth";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTime } from "luxon";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { TeamEnrolmentForm } from "../../formControls/teamEnrolmentForm/TeamEnrolmentForm";
import { EnrolInCompetition } from "../../studentPageComponents/EnrolInCompetition";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";

export const ExpertMicrositeCompetitionsCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.competitionsData);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [editIndex, setEditIndex] = useState(null);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [showTeamEnrolmentForm, setShowTeamEnrolmentForm] = useState(AppConstants.falseText);
    const [userSignupDone, setUserSignupDone] = useState(AppConstants.falseText);

    useWarnOnLeave(shouldWarn);
    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    //Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText}${AppConstants.forwardSlash}${shareData.categorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.competionSlug}`);
    }
    const seatsFilledConfirmation = async (uniqueId, teamsLimit) => {
        const url = `${AppConstants.checkTeamEnrolmentEligibilityAPI}?competitionUniqueId=${uniqueId}`;
        const response = await AuthorizedDataService.getRequestWithResponseCode(url, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            if (response && response.status === 500) {
                const data = await response.json();
                setResponses({
                    modalTypeError: AppConstants.trueText,
                    message: EnrolInCompetition.getEnrolmentStatusForTeamCompetitions({ ...data, message: 0 }, null),
                    paymentStatus: AppConstants.emptyString,
                    showModal: AppConstants.trueText,
                    showRazorpayPopup: AppConstants.falseText,
                    shouldWarn: AppConstants.falseText,
                    networkError: AppConstants.falseText
                });
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
    const confirmBeforeEnrolment = async ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole, isTeamEnrolmentEnabled, cost, teamParticipantsLimit, teamsLimit }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.competition, creatorName, creatorRole, payOfflineFlag, isTeamEnrolmentEnabled, cost, teamParticipantsLimit, teamsLimit });
        if (Auth.isLogin() && ((Auth.getUserRole() === AppConstants.userRoleStudent && isTeamEnrolmentEnabled !== AppConstants.yText) || (Auth.getUserRole() === AppConstants.userRoleTeam && isTeamEnrolmentEnabled === AppConstants.yText))) {
            if (isTeamEnrolmentEnabled === AppConstants.yText) {
                let teamEligibility = await seatsFilledConfirmation(uniqueId, teamsLimit);
                if (!teamEligibility) {
                    return;
                }
            }
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText && Auth.getUserRole() === AppConstants.userRoleStudent) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolHere(id, title, uniqueId, isTeamEnrolmentEnabled);
                }
            }
        } else {
            enrolHere(id, title, uniqueId, isTeamEnrolmentEnabled);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, confirmationPopupActivityDetails.isTeamEnrolmentEnabled);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, confirmationPopupActivityDetails.isTeamEnrolmentEnabled);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const setResponses = (response) => {
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const hideTeamEnrolmentForm = () => {
        setShowTeamEnrolmentForm(AppConstants.falseText);
    };

    const handleShowTeamEnrolmentForm = () => {
        if (!Auth.isLogin()) {
            setResponses({
                modalTypeError: AppConstants.trueText,
                message: ErrorMessages.pleaseLogInToEnrolError,
                paymentStatus: AppConstants.emptyString,
                showModal: AppConstants.trueText,
                showRazorpayPopup: AppConstants.falseText,
                shouldWarn: AppConstants.falseText,
                networkError: AppConstants.falseText
            });
        } else {
            setShowModal(AppConstants.falseText);
            setShowTeamEnrolmentForm(AppConstants.trueText);
        }
    }

    const enrolHere = async (competitionId, competitionTitle, uniqueId, isTeamEnrolmentEnabled, status) => {
        if (Auth.isLogin() && ((Auth.getUserRole() !== AppConstants.userRoleStudent && isTeamEnrolmentEnabled !== AppConstants.yText) || (Auth.getUserRole() !== AppConstants.userRoleTeam && isTeamEnrolmentEnabled === AppConstants.yText))) {
            setModalTypeError(true);
            setMessage(isTeamEnrolmentEnabled === AppConstants.yText ? ErrorMessages.onlyTeamEnrolmentsAllowedError : ErrorMessages.enrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }
        const cost = PaymentsUtil.calculateCost({ cost: cardDetails.filter(card => card.uniqueId === uniqueId)[0].cost, discount: 0 });

        if (isTeamEnrolmentEnabled === AppConstants.yText) {
            handleShowTeamEnrolmentForm();
            return;
        } else {
            let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);
            setResponses({ ...response, isTeamEnrolmentEnabled });
        }
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, confirmationPopupActivityDetails.isTeamEnrolmentEnabled, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const showRazorpayPopup = ({ options, learningActivityDetails, isTeamEnrolmentEnabled }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    enrolHere(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, isTeamEnrolmentEnabled, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const handleModalClose = () => {
        if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                if (modalTypeError) {
                    setShowModal(false);
                    setConfirmationPopupActivityDetails({});
                    setUserSignupDone(AppConstants.falseText);
                } else {
                    window.location.reload();
                }
            }
        }
    }

    const handleModalShow = () => {
        setShowModal(true);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    const getAgeGroups = (competiton) => {
        if (competiton.minimumAge !== 0 && competiton.maximumAge !== 0) {
            return `AGE ${competiton.minimumAge}-${competiton.maximumAge}`;
        } else if (competiton.minimumAge === 0 && competiton.maximumAge !== 0) {
            return `AGE ${props.signupMinimumAge}-${competiton.maximumAge}`;
        } else if (competiton.minimumAge !== 0 && competiton.maximumAge === 0) {
            return `AGE ${competiton.minimumAge}+`;
        } else {
            return "ALL AGES";
        }
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {cardDetails.slice(0, cardCount).map((cardData, index) => (
                    <Col key={cardData.uniqueId}>
                        <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.competionSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCompetitionThumbnail} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(cardData) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className="cardSubTitle competitionDiffColor">{getAgeGroups(cardData)} <span className="seperator">{AppConstants.verticalBar}</span> {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.uniqueId}><a onClick={stopPropagation} href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCompetition"><CgPin className="geoLocationIcon onlineIconCompetition" /> {cardData.type.toUpperCase()}</a></span> : <span className="onlineIconCompetition modeSubtitle">{cardData.type.toUpperCase()}</span>}</div>
                                    <Tooltip className="tooltipMessage" id={cardData.uniqueId}><span className="tooltipMessage">{cardData.venueLocation}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${cardData.uniqueId}-title`}>{cardData.title}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${cardData.uniqueId}-title`}><span>{cardData.title}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                {props.userRoleForMicrosite === AppConstants.userRoleAgency && <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.userRole === AppConstants.userRoleAgency ? cardData.agencyName : cardData.expertName}</Card.Text>}
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span>
                                    </div>
                                </div>
                                {cardData.disablePublicEnrolmentFlag === AppConstants.nText && <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    {((cardData.isOfflinePaymentRequested === AppConstants.nText || !cardData.isOfflinePaymentRequested) && (cardData.isStudentEnrolled === AppConstants.falseTextString || cardData.isStudentEnrolled === null)) && <Button type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.competitionId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.startDateTimeUTC, payOfflineFlag: cardData.payOfflineFlag, creatorName: cardData.userRole === AppConstants.userRoleAgency ? cardData.agencyName : cardData.expertName, creatorRole: cardData.userRole, isTeamEnrolmentEnabled: cardData.isTeamEnrolmentEnabled, cost: cardData.cost, teamParticipantsLimit: cardData.teamParticipantsLimit, teamsLimit: cardData.teamsLimit }) }}>{cardData.isTeamEnrolmentEnabled === AppConstants.yText ? "Enrol Team" : "Enrol Now"}</Button>}
                                    {(cardData?.isStudentEnrolled === AppConstants.trueTextString || cardData.isOfflinePaymentRequested === AppConstants.yText) && <Button type="button" className="cardButton" disabled>{cardData.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrolled"}</Button>}
                                </div>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails.length && <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</Button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} learningActivityType={AppConstants.learningActivitiesTypes.competition} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry} setUserSignupDone={setUserSignupDone}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showTeamEnrolmentForm && <TeamEnrolmentForm hideTeamEnrolmentForm={hideTeamEnrolmentForm} enrolmentDetails={confirmationPopupActivityDetails} userSignupDone={userSignupDone} />}
        </>
    )
}

