import React from "react";
import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { IndividualCompetition } from "../../component/individualCompetition/IndividualCompetitionPageLayout";
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
export const IndividualCompetitionPage = () => {
    const [landingPageData] = useLoaderData();

    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <IndividualCompetition />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}

