import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { ExpertHeader } from '../../layout/ExpertPageLayout/ExpertHeader';
import { ExpertLeftNav } from '../../layout/ExpertPageLayout/ExpertLeftNav';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { ExpertFooter } from '../../layout/ExpertPageLayout/ExpertFooter';
import { ExpertRightWidgetSpace } from '../../layout/ExpertPageLayout/ExpertRightWidgetSpace';
import classes from './ExpertTemplate.module.css';
import { useLoaderData } from 'react-router-dom';

export const ExpertTemplate = () => {
    const [footerDetails, associationStatus] = useLoaderData();
    const [open, setOpen] = useState(false);

    const myActivitiesHandler = () => {
        if (open === true) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
    }

    return (
        <>
            <ExpertHeader />
            <div>
                <div className={classes.myActivitiesSection}>
                    <Button className={classes.myActivitiesButton} onClick={myActivitiesHandler}>My Activities {!open && <RiArrowDownSLine className={classes.activityIcon} />}
                        {open && <RiArrowUpSLine className={classes.activityIcon} />}</Button>
                </div>
                <Row>
                    <Col lg={2}><ExpertLeftNav associationStatus={associationStatus} /></Col>
                    <div className={classes.mobileViewRightWidgetSection}>{open && <ExpertRightWidgetSpace />}</div>
                    <Col xs={12} sm={12} md={8} lg={7}>
                        <Outlet />
                    </Col>
                    <Col md={4} lg={3} className="d-none d-md-block d-lg-block" ><ExpertRightWidgetSpace /></Col>
                </Row>
            </div>
            {footerDetails != null && <ExpertFooter footerDetails={footerDetails} />}
        </>
    )
}

