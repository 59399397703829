import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import classes from '../cards/CompetitionsCards.module.css';
import loginFormClasses from '../formControls/LoginPageForm.module.css';
import '../../index.css';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { Button, Form } from "react-bootstrap";
import { AppConstants } from '../../constants/AppConstants';
import Auth from '../../utils/Auth';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { RiErrorWarningLine } from 'react-icons/ri';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import formClass from "../../common/FormStyling.module.css";
import { DataService } from '../../services/DataService';

export const AcceptAgencyInvitationLinkModal = (props) => {

    const { onShow, modalTypeError, onClose, updateInvitaionStatus, status } = props;

    const [passwordType, setPasswordType] = useState('password');
    const [showError, setShowError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const initialValues = { emailId: AppConstants.emptyString, password: AppConstants.emptyString };
    const [loginFormValues, setLoginFormValues] = useState(initialValues);

    const togglePassword = () => {
        if (passwordType === AppConstants.fieldTypePassword) {
            setPasswordType(AppConstants.fieldTypeText);
            return;
        }
        else {
            setPasswordType(AppConstants.fieldTypePassword);
            return;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginFormValues({ ...loginFormValues, [name]: value });
    };

    const loginUser = async (e) => {
        e.preventDefault();
        setShowSpinner(AppConstants.trueText);
        const authData = {
            userName: loginFormValues.emailId,
            userPassword: loginFormValues.password
        }

        const resp = await DataService.post(
            AppConstants.authenticationUri,
            authData,
            AppConstants.emptyString
        );

        if (!resp.ok || resp.status !== AppConstants.httpResponseCodes.responseCode200) {
            setShowError(ErrorMessages.loginFailed);
            setShowSpinner(false);
            const timer = setTimeout(() => {
                setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
        }

        const body = await resp.json();

        if (body !== AppConstants.nullText) {
            const token = body.accessToken;
            if (token !== null && token !== undefined) {
                setLoginFormValues(initialValues);
                setShowError(AppConstants.emptyString);
                setShowSpinner(false);
                localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(body));
                updateInvitaionStatus(status);
                return;
            }
        }
    }

    return (
        <>
            {(Auth.isLogin() && props.message !== ErrorMessages.loginToContinueText) ? (
                <Modal show={onShow} onHide={onClose} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={classes.modalBody}>
                        {(modalTypeError === AppConstants.falseText) && <div className={classes.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        {(modalTypeError === AppConstants.trueText) && <div className={classes.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={classes.modalAlertText}>
                            {props.message}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={classes.modalFooter}>
                        <Button className={classes.modalCloseButton} onClick={onClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            ) : (
                <Modal show={onShow} onHide={onClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton className={loginFormClasses.loginModalHeader}>
                        <Modal.Title className={loginFormClasses.loginModalTitle}>Login to {status === "Accepted" ? "Accept" : "Reject"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={classes.modalBody}>
                        <form onSubmit={loginUser}>
                            <div className={loginFormClasses.searchFormElementsSection}>
                                <div>
                                    {showError && <p className="errorMessage"><RiErrorWarningLine />{showError}</p>}
                                </div>
                                <div>
                                    <Form.Label htmlFor="login-email" className={formClass.formLabel}>Email</Form.Label>
                                    <Form.Control id="login-email" value={loginFormValues.emailId} onChange={handleChange} name="emailId" className={formClass.formInput} type="email" required />
                                </div>
                                <div>
                                    <Form.Label htmlFor="login-password" className={formClass.formLabel}>Password</Form.Label>
                                    <Form.Control id="login-password" value={loginFormValues.password} onChange={handleChange} name="password" className={formClass.formInput} minLength={8} maxLength={16} type={passwordType} required />
                                    <div className={loginFormClasses.showHidePasswordIcon}>
                                        {passwordType === AppConstants.fieldTypePassword ? <FaEye onClick={togglePassword} /> : <FaEyeSlash onClick={togglePassword} />}
                                    </div>
                                </div>
                                <div>
                                    {showSpinner && <Button type="button" className={loginFormClasses.loginButton}><BiLoaderAlt className={loginFormClasses.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" className={loginFormClasses.loginButton}>{status === AppConstants.agencyExpertInvitationStatus.accepted ? "Accept" : "Reject"}</Button>}
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            )
            }
        </>
    );
}