import { React, useState } from "react";
import parse from 'html-react-parser';
import { AppConstants } from "../../../constants/AppConstants";
import classes from './IndividualSkillsRelevanceSection.module.css'
export const IndividualSkillsRelevanceSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const redRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.redRectangleImagePath}`;
    const blueRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.blueRectangleImagePath}`;
    const funFactIconImage = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.funFactIconImage}`;
    return (
        <>
            <div className={classes.relevanceSectionContainer}>
                <div className={classes.relevanceSectionText}>
                    <h3 className={classes.heading}>{AppConstants.relevanceOfSkill}{parse(props.skill)}{AppConstants.inPresentTime}</h3>
                    <p className={classes.relevanceContent}>{parse(props.skillRelevanceText)}</p>
                </div>
                <div className={classes.relevanceSectionImg}>
                    <div className={classes.redFrameIsp}>
                        <img
                            src={redRectangleImageUrl}
                            alt="Red Frame top"
                        />
                    </div>
                    <div>
                        <img src={props.skillRelevanceImagePath} className={classes.relevanceImage} alt={`${props.skillName} ${AppConstants.relevanceText}`} />
                    </div>
                    <div className={classes.blueFrameIsp}>
                        {/* <img
                            src={blueRectangleImageUrl}
                            alt="Blue bottom frame"
                        /> */}
                        {<img src={funFactIconImage} alt={`${props.skillName} ${AppConstants.funFact}`} className={classes.funFactStaticImage} />}
                        {<div className={classes.overlay}>
                            <p className={classes.overlayText}>{parse(props.skillFunFact)}</p>
                        </div>}
                    </div>
                </div>

            </div>
        </>
    )

}