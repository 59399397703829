import { NavLink } from 'react-router-dom';
import classes from '../LandingPageLayout/LandingPageDedicatedNavbar.module.css';
import { AppConstants } from "../../constants/AppConstants";
import templateClasses from '../ExpertPageLayout/ExpertHeader.module.css';
import Auth from '../../utils/Auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from "react-icons/fa";
import { BiLogOut, BiHome } from "react-icons/bi";
import { Logout } from '../../common/Logout';
import { Link } from 'react-scroll';
import { useEffect, useState } from 'react';
import { RiProfileLine } from "react-icons/ri";

export const IndividualSkillPageNavigation = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    const userBrandingPageLink = Auth.isLogin() ? `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole()?.toLowerCase()}/${Auth.getLoggedInUserDetails()?.userSlug}` : AppConstants.emptyString;
    const [showMenu, setShowMenu] = useState(false);
    const [activityList, setActivityList] = useState([]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    //add active class on nav-item click
    function addActiveClass(e) {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        let activities = [];
        function getMatchingActivity(activityName) {
            let matchingActivity = props.activitiesList?.filter(activity => activity.activityType === activityName)[0];
            if (matchingActivity !== undefined) {
                activities.push(matchingActivity)
            }
        }

        if (props.expertExistsForSkill) {
            setActivityList(props.activitiesList);
        } else {
            if (props.allActivitiesForSkill.courseDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[1])
            }
            if (props.allActivitiesForSkill.competitionDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[2])
            }

            if (props.allActivitiesForSkill.workshopDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[3])
            }
            setActivityList(activities);
        }

    }, [props.activitiesList, props.expertExistsForSkill]);

    function getUserHomePageUrl() {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === 'Expert') {
                window.open(AppConstants.expertHomePath, AppConstants.newPageTargetSelf);
            }
            else if (Auth.getUserRole() === 'Student') {
                window.open(AppConstants.studentHomePath, AppConstants.newPageTargetSelf);
            }
            else if (Auth.getUserRole() === 'Admin') {
                window.open(AppConstants.adminNumberTrackingPath, AppConstants.newPageTargetSelf);
            }
            else if (Auth.getUserRole() === 'Agency') {
                window.open(AppConstants.agencyHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === 'Sales') {
                window.open(AppConstants.salesLeadsPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                window.open(AppConstants.marketingBulkSignupPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                window.open(AppConstants.partnerSuccessNumberTracking, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
                window.open(AppConstants.teamHomePath, AppConstants.newPageTargetSelf);
            } else {
                window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
            }
        }
        else {
            window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
        }
    }

    return (
        <>
            <header className={classes.individualPageHeader}>
                {activityList?.length > 0 && <div className={classes.hamburger} onClick={toggleMenu}>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                </div>}
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>

                <div className={classes.navItemSection}>
                    <nav>
                        <ul className={classes.list}>
                            {activityList?.length > 0 && activityList?.map(activity => (
                                <li
                                    key={activity.activityId}
                                    className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb} ${classes.skillPageList}`}
                                >
                                    <Link
                                        onClick={(e) => { addActiveClass(e); props.getNavigationSelectedActivity(activity) }}
                                        to="activitySection"
                                        duration={100}
                                        className={classes.sectionNavItem}
                                    >
                                        {activity.activityTitle}
                                    </Link>
                                </li>
                            ))}
                            {!Auth.isLogin() && <li className={classes.listItem}>
                                <NavLink to={AppConstants.loginPageUrl} className={classes.loginButton}>
                                    Login
                                </NavLink>
                            </li>}
                            {!Auth.isLogin() && <li className={classes.listItem}>
                                <NavLink to={AppConstants.signup} className={classes.signUpButton}>
                                    Join Now
                                </NavLink>
                            </li>}
                        </ul>
                    </nav>
                </div>
                {Auth.isLogin() &&
                    <div className={`${templateClasses.profileSection} ms-3`}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !== null && <div>
                            <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={templateClasses.profileImage} alt="Profile" />
                        </div>}
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                            <div className={templateClasses.userInitials}>{Auth.getLoggedInUserDetails().nameInitials}</div>
                        </div>}
                        <Dropdown>
                            <Dropdown.Toggle className={templateClasses.profilePopoverButton}>
                                <p className={templateClasses.profileName}>{Auth.getLoggedInName().length < 16 ? Auth.getLoggedInName() : Auth.getLoggedInName().substr(0, 16) + ".."}</p> <FaChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={getUserHomePageUrl}><BiHome className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Home</p></Dropdown.Item>
                                {(Auth.getUserRole() === AppConstants.userRoleExpert || Auth.getUserRole() === AppConstants.userRoleAgency) && <Dropdown.Item onClick={() => window.open(userBrandingPageLink, AppConstants.openInNewTab)} className={templateClasses.profileDropdownItem}><RiProfileLine className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Branding Page</p></Dropdown.Item>}
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Logout</p></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                {showMenu && <nav>
                    <ul className={`${classes.listOnMobile} ${showMenu ? classes.showMenu : ''}`}>
                        {activityList?.length > 0 && activityList?.map(activity => (
                            <li
                                key={activity.activityId}
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                            >
                                <Link
                                    onClick={(e) => { addActiveClass(e); props.getNavigationSelectedActivity(activity) }}
                                    to="activitySection"
                                    duration={100}
                                    className={classes.sectionNavItem}
                                >
                                    {activity.activityTitle}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>}
            </header>
        </>
    );
}