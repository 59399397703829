import { useRef, useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import classes from './LandingPageSearchSkillCompetitionForm.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { SelectionContext } from '../../context/SelectionContext';

export const LandingPageSearchSkillCompetitionForm = (props) => {
    const [formDetails, setFormDetails] = useState(props.formSectionDetails);
    const [qrenciaSkillsDetails, setQrenciaSkillsDetails] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(AppConstants.emptyString);
    const [selectedActivityType, setSelectedActivityType] = useState(AppConstants.emptyString);
    const [skillError, setSkillError] = useState(false);
    const [skillErrorMessage, setSkillErrorMessage] = useState(AppConstants.emptyString);
    const { selectedActivity, setSelectedActivity } = useContext(SelectionContext);
    const [activitiesList, setActivitiesList] = useState(props.activitiesList);
    const history = useNavigate();
    const [searchSkillText, setSearchSkillText] = useState(AppConstants.searchSkillSectionTexts[0]);
    const typeaheadRef = useRef(null);

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            const sortedSkills = response.sort((a, b) => {
                if (a.skill < b.skill) return -1;
                if (a.skill > b.skill) return 1;
                return 0;
            });
            setQrenciaSkillsDetails(sortedSkills);
        }
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Get the index of the next search skill text
            const currentIndex = AppConstants.searchSkillSectionTexts.indexOf(searchSkillText);
            const nextIndex = (currentIndex + 1) % AppConstants.searchSkillSectionTexts.length;

            // Update the search skill text state with the next value
            setSearchSkillText(AppConstants.searchSkillSectionTexts[nextIndex]);
        }, AppConstants.searchSkillTextChangeDuration);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [searchSkillText]);

    const submitSelectedSkill = (value) => {
        setSelectedSkill(value);
        if (value != undefined && value != null && value != "" && value.length != 0) {
            setSkillError(false);
            setSelectedActivity(AppConstants.emptyString);
            setSelectedActivityType(AppConstants.emptyString);
        }

    }

    const submitSelectedActivityType = (e) => {
        setSelectedActivity(e.target.value);
        setSelectedActivityType(e.target.value);
    }

    const submitForm = (event) => {
        //preventing page refresh and http call
        event.preventDefault();
        submitSearchForm();
    };

    const submitSearchForm = () => {
        setSkillError(false);
        let isSubmit = true;
        if (selectedSkill === undefined || selectedSkill === null || selectedSkill === "" || selectedSkill.length === 0) {
            setSkillError(true);
            setSkillErrorMessage(ErrorMessages.landingPageSkillSelectionError);
            isSubmit = false;
        }
        if (isSubmit) {
            history(`${AppConstants.forwardSlash}${AppConstants.skills}${AppConstants.forwardSlash}${selectedSkill[0].categorySlug}${AppConstants.forwardSlash}${selectedSkill[0].skillSlug}`);
        }
    }
    return (
        <>
            <div className={classes.searchFormSection}>
                <h1 className={classes.formHeading}>{formDetails.title}</h1>
                <form onSubmit={submitForm}>
                    <div className={classes.searchFormElementsSection}>

                        <div className={classes.formDropdown}>
                            <Typeahead
                                className={classes.typeaheadFormField}
                                name="skill"
                                id="lpSkillTypeahead"
                                labelKey="skill"
                                onChange={submitSelectedSkill}
                                options={qrenciaSkillsDetails}
                                ref={typeaheadRef}
                                placeholder="Enter Skill"
                            />
                            {skillError && <p className={classes.skillErrorMessage}><AiOutlineExclamationCircle /> {skillErrorMessage}</p>}
                        </div>
                        <div className={classes.formDropdown}>
                            <Form.Select onChange={submitSelectedActivityType} value={selectedActivityType} className={classes.searchFormField}>
                                <option value="" defaultValue>{searchSkillText}</option>
                                {activitiesList.map((activities) => (
                                    <option value={activities.activityType} key={activities.id}>{activities.activityTitle}</option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className={classes.formSearchButton}>
                            <Button type="submit" value="Submit" className={classes.searchButton}>{formDetails.buttonText}</Button>
                            <HiOutlineSearch onClick={submitSearchForm} type="submit" value="Submit" className={classes.searchIcon} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}