import { AppConstants } from "../../constants/AppConstants";
import { BulkUploadSheetComponent } from '../../component/bulkUploadSheetComponent/BulkUploadSheetComponent';
import { SuccessMessage } from "../../constants/SuccessMessage";

export const AdminBulkSignupPage = () => {
    return (
        <div className="adminContentSection">
            <BulkUploadSheetComponent heading="Bulk Signup Learners" uploadSheetAPI={AppConstants.adminStudentBulkSignUpAPI} downloadedSheetName={AppConstants.adminBulkSignupStudentList} downloadTemplateAPI={AppConstants.downloadStudentBulkSignUpTemplateAPI} multipartFileName="studentSignUpAndEnrolSheet" uploadFileLabel="Learners Sheet" successMessage={SuccessMessage.adminBulkSignupSheetUploadSuccess} />
        </div>
    )
}