import { TiArrowLeft } from "react-icons/ti";
import classes from "../../adminDashboardComponents/learningActivityDetailsComponent/LearningActivityDetailsComponent.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { Button, Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { MathUtil } from "../../../utils/MathUtil";

export const CreatorLearningActivityDetails = ({ hideDetailsSection, creatorLearningActivityDetails, creatorName }) => {
    const keys = Object.keys(creatorLearningActivityDetails);

    const getColumnDefinitions = (key) => {
        let learningActivityType;
        if (key?.toLowerCase().includes(AppConstants.learningActivitiesTypes.competition.toLowerCase())) {
            learningActivityType = AppConstants.learningActivitiesTypes.competition;
        } else if (key?.toLowerCase().includes(AppConstants.learningActivitiesTypes.course.toLowerCase())) {
            learningActivityType = AppConstants.learningActivitiesTypes.course;
        } else {
            learningActivityType = AppConstants.learningActivitiesTypes.workshop;
        }

        const learningActivityColumnDefs = [
            {
                headerName: `${learningActivityType} Name`,
                field: "title",
                width: 300,
                tooltipValueGetter: ({ data }) => `${data.title}`,
                valueGetter: (params) => {
                    return params.data.title;
                },
                filter: 'agTextColumnFilter'
            },
            {
                headerName: `Page Link`,
                field: "action",
                cellRendererFramework: ({ data }) => <div className={classes.obrGridActionBox}>
                    <Button data-bs-toggle="tooltip" data-bs-placement="top" title="View" className={`gridButton ${classes.gridIcon}`} onClick={() => { window.open(`${process.env.REACT_APP_FRONT_END_URL}${learningActivityType === AppConstants.learningActivitiesTypes.competition ? data.competitionLink : learningActivityType === AppConstants.learningActivitiesTypes.course ? data.courseLink : data.workshopLink}`, AppConstants.openInNewTab) }}>View</Button>
                </div>,
                width: 100,
            },
            {
                headerName: `Enrolments`,
                field: "enrolledStudents",
                width: 300,
                tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.enrolledStudents),
                valueGetter: (params) => {
                    return MathUtil.formatNumberToLocaleString(params.data.enrolledStudents)
                },
                filter: 'agTextColumnFilter'
            },
            {
                headerName: `Cost`,
                field: "cost",
                width: 300,
                tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.cost),
                valueGetter: (params) => {
                    return MathUtil.formatNumberToLocaleString(params.data.cost)
                },
                filter: 'agTextColumnFilter'
            },
        ];

        return learningActivityColumnDefs || [];
    }
    return (
        <div>
            <Row>
                <div>
                    <TiArrowLeft onClick={hideDetailsSection} className={classes.obrBackButton} />
                    <Row className="mb-1 mt-1">
                        <h3 className={classes.creatorNameHeading}>Creator Name - {creatorName}</h3>
                    </Row>

                    {
                        keys.map(key => {
                            return <Row className="mb-2">
                                <div className={classes.tableDetailsHeading}>{key === "competitionDetails" ? "Competiton Details:" : key === "courseDetails" ? "Course Details:" : "Workshop Details:"}</div>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            className="expertObrDetailsGrid"
                                            columnDefs={getColumnDefinitions(key)}
                                            pagination={true}
                                            paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                            rowData={creatorLearningActivityDetails[key] || []}
                                            enableBrowserTooltips={true}
                                            domLayout='autoHeight'
                                            suppressRowHoverHighlight={true}
                                            defaultColDef={{
                                                filter: true,
                                                sortable: true,
                                                flex: 1,
                                                minWidth: 100,
                                                resizable: true,
                                                suppressMenu: true,
                                                suppressHorizontalScroll: true,
                                                floatingFilterComponentParams: { suppressFilterButton: true },
                                                floatingFilter: true,
                                            }}
                                            enableFilter={true}
                                            enableSorting={true}
                                            enableSearching={true}
                                            headerClass={classes.obrGridHeader}
                                            rowClass={classes.obrGridTable}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        })
                    }
                </div>
            </Row>
        </div>
    )
}