import { Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AppConstants } from '../../constants/AppConstants';
import classes from "./ExpertSchoolLearningActivityStatistics.module.css";
import { MathUtil } from '../../utils/MathUtil';

export const ExpertSchoolLearningActivityStatistics = () => {
    const [learningActivityStats, setLearningActivityStats] = useState([]);

    useEffect(() => {
        const fetchLearningActivityStatics = async () => {
            const url = AppConstants.expertFetchCompetitionsStatsUnderMySchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setLearningActivityStats(response);
            } else {
                setLearningActivityStats([]);
            }
        }

        fetchLearningActivityStatics();
    }, []);
    return (
        <div className={classes.dashboardCards}>
            <Card className={`${classes.countCards} ${classes.competitionCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Upcoming</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.upcomingCompetitions)}</Card.Text>
                </Card.Body>
            </Card>
            <Card className={`${classes.countCards} ${classes.courseCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Ongoing</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.ongoingCompetitions)}</Card.Text>

                </Card.Body>
            </Card>
            <Card className={`${classes.countCards} ${classes.teachersCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Past</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.pastCompetitions)}</Card.Text>
                </Card.Body>
            </Card>
            <Card className={`${classes.countCards} ${classes.studentCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Enrolled</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.totalEnrolledStudents)}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}