import { useState } from "react";
import { VideoPopup } from '../popup/VideoPopup';
import classes from './FooterSectionExternalLinks.module.css';
import { AppConstants } from "../../constants/AppConstants";

export const FooterSectionExternalLinks = (props) => {
    const [footerExternalLinkDetails, setFooterExternalLinkDetails] = useState(props.externalLinkDetails);
    const [isClicked, setIsClicked] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    function OpenVideoPopup(list) {
        if (list.videoPath != null) {
            setIsClicked(true);
            setVideoUrl(list.videoPath);
        }
        else {
            window.open(list.link);
        }

    }
    function closeQrenciaAtGlancePopup() {
        setIsClicked(false);
    }

    return (
        <>
            <div>
                <h1 className={classes.externalLinkTitle}>{footerExternalLinkDetails[0].title}</h1>
                {footerExternalLinkDetails.filter((data) => data.active === AppConstants.trueText).map((list) => (
                    <div key={list.id}>
                        <li onClick={e => OpenVideoPopup(list)} className={classes.externalLinkListElements}>{list.subTitle}</li>
                        {(list.link && list.description) && <li className={classes.externalLinkListElements}><a className={classes.eoiLink} href={list.link}>{list.description}</a></li>}
                    </div>
                ))}
            </div>
            {isClicked && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeQrenciaAtGlancePopup} videoUrl={videoUrl} />}
        </>
    );
}