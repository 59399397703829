import { useState } from 'react';
import videoSectionStyles from "./ExpressionOfInterestVideoSection.module.css";
import { AppConstants } from '../../../constants/AppConstants';
import { VideoPopup } from '../../popup/VideoPopup';
import { FaPaperPlane } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai'
import { BsGlobeAmericas } from 'react-icons/bs';
import { HiOutlineDocumentText } from 'react-icons/hi';

export const ExpressionOfInterestVideoSection = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [isClicked, setIsClicked] = useState(AppConstants.falseText);
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const videoMessageThumbnail = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiVideoThumbnailImagePath}`;
    const brochureLink = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.expertBrochurePdfPath}`;
    const aboutUsLink = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiAboutUsLink}`;

    function openVideoPopup() {
        setIsClicked(true);
    }

    function closeVideoPopup() {
        setIsClicked(false);
    }

    return (
        <>
            <p className={videoSectionStyles.secitonHeading}>Watch how Qrencia works for you!</p>
            <div className={videoSectionStyles.section}>
                <div className={videoSectionStyles.videoPopupSection}>
                    <div className={videoSectionStyles.thumbnailImage}>
                        <img onClick={openVideoPopup}
                            className={videoSectionStyles.videoThumbnailImage}
                            src={videoMessageThumbnail}
                            alt="Video Thumbnail"
                        />
                        <img onClick={openVideoPopup}
                            className={videoSectionStyles.videoPlayIcon}
                            src={videoPlayIconUrl}
                            alt="Video Play Button"
                        />
                    </div>
                </div>
                {isClicked && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeVideoPopup} videoUrl={AppConstants.eoiPageVideoId} />}
            </div>
            <div className={videoSectionStyles.importantLinksSection}>
                <p className={videoSectionStyles.importantLinksHeading}>Important Links</p>
                <div className={videoSectionStyles.links}>
                    <a target={AppConstants.openInNewTab} href={brochureLink} className={videoSectionStyles.linkText}><HiOutlineDocumentText /> Brochure</a>
                    <a target={AppConstants.openInNewTab} href="/extra-curricular-skills" className={videoSectionStyles.linkText}><FaPaperPlane /> Skills on Qrencia</a>
                    <a target={AppConstants.openInNewTab} href={`/${AppConstants.expertPageRoute}`} className={videoSectionStyles.linkText}><AiOutlineUser /> Experts on Qrencia</a>
                    <a target={AppConstants.openInNewTab} href={aboutUsLink} className={videoSectionStyles.linkText}><BsGlobeAmericas /> About us</a>
                </div>
            </div>
        </>
    );
}