import { useState, useEffect } from "react";
import classes from './QrenciaSkillsSection.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { LandingPageSearchSkillCompetitionForm } from '../../component/formControls/LandingPageSearchSkillCompetitionForm';
import { LandingPageCategoryWiseSearchSkill } from './LandingPageCategoryWiseSearchSkill';

export const QrenciaSkillsSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const sectionBackgroundImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.qrenciaSkillsBackgroundImagePath}`;
    const [qrenciaSearchSkillCompetitionSectionDetails, setQrenciaSkillCompetitionSectionDetails] = useState(props.landingPageData.searchSkillCompetition);
    const [qrenciaExploreSkillsSectionDetails, setQrenciaExploreSkillsSectionDetails] = useState(props.landingPageData.exploreSkills);
    const [bgImage, setBgImage] = useState(sectionBackgroundImageUrl);
    const [qrenciaSkills, setQrenciaSkills] = useState([]);

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setQrenciaSkills(response);
        }
    }, []);

    return (
        <>
            {qrenciaExploreSkillsSectionDetails.active === AppConstants.trueText && <div className={classes.skillsMainContainer} style={{ backgroundImage: `url(${bgImage})`}} >
                {qrenciaSearchSkillCompetitionSectionDetails.active === AppConstants.trueText && <LandingPageSearchSkillCompetitionForm formSectionDetails={qrenciaSearchSkillCompetitionSectionDetails} activitiesList={props.activitiesList} />}
                {qrenciaExploreSkillsSectionDetails.active === AppConstants.trueText && <LandingPageCategoryWiseSearchSkill searchCategoryWiseSkillsSectionDetails={qrenciaExploreSkillsSectionDetails} qrenciaSkillsDetails={qrenciaSkills} />}
            </div>
            }
        </>
    );
}