import { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { AppConstants } from '../../constants/AppConstants';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { MathUtil } from "../../utils/MathUtil";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from './AdminDashboardPage.module.css';
import formClass from "../../common/FormStyling.module.css";
import { AgGridReact } from 'ag-grid-react';
import { FaFilter, FaRegEye } from "react-icons/fa6";
import { CiViewTable } from "react-icons/ci";
import { LearningActivityDetailsComponent } from '../../component/adminDashboardComponents/learningActivityDetailsComponent/LearningActivityDetailsComponent';
import { DateFilterComponent } from '../../component/adminDashboardComponents/dateFilterComponent/DateFilterComponent';
import moment from 'moment';
import { NudgeAsigneeDetailsModal } from '../../component/modal/NudgeAsigneeDetailsModal';

export const AdminDashboardPage = () => {
    const [selectedLearningActivity, setSelectedLearningActivity] = useState(AppConstants.emptyString);
    const [fromDate, setFromDate] = useState(AppConstants.emptyString);
    const [toDate, setToDate] = useState(AppConstants.emptyString);
    const [showLearningActivityDetails, setShowLearningActivityDetails] = useState(AppConstants.falseText);
    const [learningActivityList, setLearningActivityList] = useState([]);
    const [selectedUniqueId, setSelectedUniqueId] = useState(AppConstants.emptyString);
    const [showDateSelectionModal, setShowDateSelectionModal] = useState(AppConstants.falseText);
    const [selectedLearningActivityDetails, setSelectedLearningActivityDetails] = useState({});
    const [dateFilterDisplayValue, setDateFilterDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [showLearningActivitySection, setShowLearningActivitySection] = useState(AppConstants.falseText);
    const [showAsigneeModal, setShowAsigneeModal] = useState(AppConstants.falseText);
    const [selectedNudgeId, setSelectedNudgeId] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const hideLearningActivityDetailsSection = () => {
        setShowLearningActivityDetails(AppConstants.falseText);
        setSelectedUniqueId(AppConstants.emptyString);
    };

    const handleDetailsComponent = async (data) => {
        setSelectedUniqueId(data.learningActivityUniqueId);
        const url = `${AppConstants.getSelectedLearningInfoForAdminAPI}${selectedLearningActivity}&learningActivityUniqueId=${data.learningActivityUniqueId}&teamEnrolmentEnabledFlag=${data.teamEnrolmentEnabledFlag}`;

        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response) {
            setShowLearningActivityDetails(AppConstants.trueText);
            setSelectedLearningActivityDetails(response);
        } else {
            setSelectedLearningActivityDetails({});
            setShowLearningActivityDetails(AppConstants.falseText);
        }
    }

    const selectLearningActivity = async () => {
        if (!selectedLearningActivity) return null;

        let url = `${AppConstants.getPublishedLearningActivitiesForAdminAPI}${selectedLearningActivity}`;
        const locale = DateTimeUtil.getPreferredTimeZoneForUser();

        if (fromDate) {
            url = `${url}&${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDate)}`;
        }

        if (toDate) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDate)}`;
        }

        if (fromDate || toDate) {
            url = `${url}&locale=${locale}`
        }

        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setLearningActivityList(response);
            setShowLearningActivitySection(AppConstants.trueText);
        } else {
            setLearningActivityList([]);
            setShowLearningActivitySection(AppConstants.trueText);
        }
    };

    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setFromDate(fromDateString);
        setToDate(toDateString);
        setShowDateSelectionModal(AppConstants.falseText);
        setDateFilterDisplayValue(displayValue);
    }

    const handleDateSelectionModalClose = () => {
        setShowDateSelectionModal(AppConstants.falseText);
    }

    const resetLearningActivityDetails = () => {
        setFromDate(AppConstants.emptyString);
        setToDate(AppConstants.emptyString);
        setShowDateSelectionModal(AppConstants.falseText);
        setShowLearningActivitySection(AppConstants.falseText);
        setShowLearningActivityDetails(AppConstants.falseText);
        setSelectedLearningActivityDetails({});
        setDateFilterDisplayValue(AppConstants.dateFilterPresets.allTime.label);
    }

    const learningActivityColumnDefs = [
        {
            headerName: `${selectedLearningActivity} ID`,
            field: "learningActivityId",
            maxWidth: 110,
            tooltipValueGetter: ({ data }) => `${data.learningActivityId}`,
            valueGetter: (params) => {
                return params.data.learningActivityId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `${selectedLearningActivity} Title`,
            field: "learningActivityTitle",
            width: 400,
            tooltipValueGetter: ({ data }) => `${data.learningActivityTitle}`,
            valueGetter: (params) => {
                return `${params.data.learningActivityTitle}`;
            },
            filter: 'agTextColumnFilter'

        },
        {
            headerName: `Creator Name`,
            field: "creatorName",
            width: 400,
            tooltipValueGetter: ({ data }) => `${data.userRole === AppConstants.userRoleExpert ? data.creatorName : data.agencyName}`,
            valueGetter: ({ data }) => {
                return `${data.userRole === AppConstants.userRoleExpert ? data.creatorName : data.agencyName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Start Date",
            field: "utcStartTime",
            maxWidth: 150,
            tooltipValueGetter: ({ data }) => (selectedLearningActivity === AppConstants.learningActivitiesTypes.course && data.courseType === AppConstants.flexibleCourseType) ? AppConstants.notAvailableText : `${moment.utc(data.utcStartTime).tz(timeZone).format('D MMM YYYY, hh:mm A')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return (selectedLearningActivity === AppConstants.learningActivitiesTypes.course && data.courseType === AppConstants.flexibleCourseType) ? AppConstants.notAvailableText : `${moment.utc(data.utcStartTime).tz(timeZone).format('D MMM YYYY, hh:mm A')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Skill`,
            field: "skill",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => `${data.skill}`,
            valueGetter: (params) => {
                return `${params.data.skill}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Enrolments`,
            field: "enrolledStudents",
            tooltipValueGetter: ({ data }) => {
                if (selectedLearningActivity.toLowerCase() === AppConstants.competitionText && data.teamEnrolmentEnabledFlag === AppConstants.yText) {
                    return `${data.registeredTeamCount} ${data.registeredTeamCount === 1 ? '(Team)' : '(Teams)'}`;
                }
                return data.enrolledStudents;
            },
            valueGetter: ({ data }) => {
                if (selectedLearningActivity.toLowerCase() === AppConstants.competitionText && data.teamEnrolmentEnabledFlag === AppConstants.yText) {
                    return `${data.registeredTeamCount} ${data.registeredTeamCount === 1 ? '(Team)' : '(Teams)'}`;
                }
                return data.enrolledStudents;
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="View" className={`gridButton ${classes.gridIcon}`} onClick={() => { handleDetailsComponent(params.data) }}>Details</Button>
            </div>,
            maxWidth: 100,
        }
    ];

    const nudgeColumnDefs = [
        {
            headerName: `Nudge ID`,
            field: "learningActivityId",
            maxWidth: 100,
            tooltipValueGetter: ({ data }) => `${data.learningActivityId}`,
            valueGetter: (params) => {
                return params.data.learningActivityId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Learner Name`,
            field: "learnerName",
            maxWidth: 160,
            tooltipValueGetter: ({ data }) => `${data.learnerName}`,
            valueGetter: (params) => {
                return `${params.data.learnerName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Question`,
            field: "question",
            width: 400,
            tooltipValueGetter: ({ data }) => `${data.learningActivityTitle}`,
            valueGetter: (params) => {
                return `${params.data.learningActivityTitle}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Skill`,
            field: "skill",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => `${data.skill}`,
            valueGetter: (params) => {
                return `${params.data.skill}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Status`,
            field: "status",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => `${data.status}`,
            valueGetter: (params) => {
                return `${params.data.status}`;
            },
            filter: 'agTextColumnFilter',
            cellStyle: params => {
                if (params.data.status === AppConstants.closedText) {
                    return { color: 'red', fontWeight: "500" };
                }
                if (params.value === AppConstants.activeText) {
                    return { color: 'green', fontWeight: "500" };
                }
                return null;
            }
        },
        {
            headerName: `Raised to Multiple Experts`,
            field: "raisedToSpecificExpertFlag",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => data.raisedToSpecificExpertFlag ? data.raisedToSpecificExpertFlag === AppConstants.yText ? "No" : "Yes" : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.raisedToSpecificExpertFlag ? data.raisedToSpecificExpertFlag === AppConstants.yText ? "No" : "Yes" : AppConstants.notAvailableText
            },
            filter: 'agTextColumnFilter',
            headerClass: "multi-line-header"
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Details" className={`gridButton ${classes.gridIcon}`} onClick={() => { handleDetailsComponent(params.data) }}><FaRegEye /></Button>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Assigned Creators" className={`gridButton ${classes.gridIcon}`} onClick={() => { showNudgeAssigneeDetails(params.data) }}><CiViewTable /></Button>
            </div>,
            maxWidth: 100,
        }
    ];

    const teamEnrolmentFlagColumn = {
        headerName: "Team Enrolment",
        field: "teamEnrolmentEnabledFlag",
        maxWidth: 120,
        tooltipValueGetter: ({ data }) => `${data.teamEnrolmentEnabledFlag}`,
        valueGetter: (params) => {
            return `${params.data.teamEnrolmentEnabledFlag}`;
        },
        filter: 'agTextColumnFilter'
    }

    const showNudgeAssigneeDetails = (data) => {
        setSelectedNudgeId(data.learningActivityUniqueId);
        setShowAsigneeModal(AppConstants.trueText);
    };

    const closeNudgeAsigneeModal = () => {
        setSelectedNudgeId(AppConstants.emptyString);
        setShowAsigneeModal(AppConstants.falseText);
    };

    const getColumnsForLearningActivity = () => {
        if (selectedLearningActivity === AppConstants.learningActivitiesTypes.competition) {
            let updatedColumns = [...learningActivityColumnDefs];
            updatedColumns.splice(5, 0, teamEnrolmentFlagColumn);
            return updatedColumns;
        } else {

            return learningActivityColumnDefs;
        }
    }

    return (
        <div className="adminContentSection">
            <div className={classes.suggestiveCompetitionSearchComponent}>
                <Row className="mb-1 mt-1">
                    <Col className={classes.expertObrFormInputBox} xs={12} sm={12} md={4} lg={4}>
                        <div className={classes.userRoleDropdown}>
                            <Form.Label className={formClass.formLabel}>Learning Activity</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name="userRole"
                                value={selectedLearningActivity}
                                disabled={showLearningActivitySection}
                                onChange={e => setSelectedLearningActivity(e.target.value)}
                            >
                                <option value="">
                                    Select Learning Activity
                                </option>
                                {AppConstants.learningActivityTypesArray?.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                    <div className={`${classes.dateFilterText} ${showLearningActivitySection && classes.dateFilterTextDisabled}`} onClick={() => setShowDateSelectionModal(showLearningActivitySection ? AppConstants.falseText : AppConstants.trueText)}>{dateFilterDisplayValue}<FaFilter /></div>
                    <Col className={classes.expertObrFormInputBox} xs={12} sm={12} md={6} lg={2}>
                        {!showLearningActivitySection && <Button className={classes.selectButton} disabled={!selectedLearningActivity} onClick={selectLearningActivity}>Select</Button>}
                        {showLearningActivitySection && <Button className={classes.selectButton} onClick={resetLearningActivityDetails}>Reset</Button>}
                    </Col>
                </Row>
                {showDateSelectionModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateFilterDisplayValue} />}
            </div>

            {showLearningActivitySection && !showLearningActivityDetails && <div className={classes.dashboardContainer}>
                <Row>
                    < Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                className="expertObrDetailsGrid"
                                columnDefs={selectedLearningActivity === AppConstants.learningActivitiesTypes.nudge ? nudgeColumnDefs : getColumnsForLearningActivity()}
                                pagination={true}
                                paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                rowData={learningActivityList}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>}
            {showAsigneeModal && <NudgeAsigneeDetailsModal selectedNudgeId={selectedNudgeId} closeNudgeAsigneeModal={closeNudgeAsigneeModal} />}
            {showLearningActivityDetails && showLearningActivitySection && <LearningActivityDetailsComponent learningActivityType={selectedLearningActivity} learningActivityDetails={selectedLearningActivityDetails} hideLearningActivityDetailsSection={hideLearningActivityDetailsSection} uniqueId={selectedUniqueId} dateFilterDisplayValue={dateFilterDisplayValue} />}
        </div>
    )
}