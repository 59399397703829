import { Button, Col } from "react-bootstrap";
import classes from "./FeedbackPageLearnerCards.module.css";

export const FeedbackPageLearnerCards = ({ learnerDetails, openFeedbackPopup }) => {
    return (
        <Col>
            <div className={classes.cardContainer}>
                <div className={classes.profileImageSection}>
                    {learnerDetails.leanerImagePath ? <div className={classes.nameInitials}><img src={learnerDetails.studentImage} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.nameInitials}>{learnerDetails.studentNameInitials}</div>}
                    <div>
                        <p className={classes.learnerName}>{learnerDetails.studentName}</p>
                        <p className={classes.emailText}>QID: {learnerDetails.userQrenciaId} | {learnerDetails.mobileNumberWithCallingCode}</p>
                    </div>
                </div>
                <div className={classes.cardButtonSection}>
                    {<Button onClick={() => openFeedbackPopup(learnerDetails)} className={classes.feedbackButton} >Feedback</Button>}
                </div>
            </div>
        </Col>
    )

}