import { useState, useEffect } from 'react';
import classes from "./StudentCompetitionsPage.module.css"
import { AppConstants } from "../../constants/AppConstants";
import { StudentSkillCategoryTabs } from '../../component/lists/StudentSkillCategoryTabs';
import { DataService } from '../../services/DataService';
import { StudentCompetitions } from '../../component/studentPageComponents/StudentCompetitions/StudentCompetitions';

export const StudentCompetitionsPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [categoryList, setCategoryList] = useState([]);
    const [minimumAgeLimit, setMinimumAgeLimit] = useState(AppConstants.emptyString);

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const skillsData = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (skillsData.length > AppConstants.zeroIndex) {
                var data = new Map();
                for (let obj of skillsData) {
                    data.set(obj.categoryId, obj.category);
                }
                let skillCategoryArray = [...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId);
                setCategoryList([AppConstants.AllText, ...skillCategoryArray]);
            }
        }

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumAgeLimit(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue))
            }
        };

        fetchConfigurableKeys();
        fetchSkills();
    }, []);

    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    };

    return (
        <div className="studentContentSection">
            {categoryList.length > AppConstants.zeroIndex && <div className={classes.section}>
                <div className={classes.filterSection}>
                    <StudentSkillCategoryTabs categoryList={categoryList} onSelection={categorySelectionHandler} />
                </div>
                <h3 className={classes.sectionHeading}>Featured Competitions</h3>
                <StudentCompetitions minimumAgeLimit={minimumAgeLimit} selectedCategory={selectedCategory} categoryList={categoryList} category="featured" apiEndpoint={AppConstants.getFeaturedCompetitionsForStudentAPI} />
                <h3 className={classes.sectionHeading}>Recently Published</h3>
                <StudentCompetitions minimumAgeLimit={minimumAgeLimit} selectedCategory={selectedCategory} categoryList={categoryList} category="recently published" apiEndpoint={AppConstants.getRecentlyPublishedCompetitionsForStudentAPI} />
            </div>}
        </div>
    )
}