import React from "react";
import { useState } from "react";
import parse from 'html-react-parser';
import { AppConstants } from "../../../constants/AppConstants";
import classes from './IndividualSkillsIntroSection.module.css';
import { IoMdClose } from "react-icons/io";


export const IndividualSkillsIntroSection = (props) => {
    
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const blueRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.blueRectangleImagePath}`;
    const funFactIconImage = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.funFactIconImage}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(props.skillFunFactVideoPath!=null);
 
    const playVideo = () => {
        setVideoPlaying(true);
        setShowPlayIcon(false);
      };

      const handleClose = () => {
        setVideoPlaying(false);
        setShowPlayIcon(true);
      };
   
  return (
        <>
            <div className={classes.containerTextWrap}>
                <div className={classes.whatIsSection}>
                    <h3 className={classes.heading}>{AppConstants.whatIs}{parse(props.skill)}{AppConstants.questionMark}</h3>
                    <p className={classes.contentSkillsIntro}>{parse(props.skillIntroduction)}</p>
                    <div className={classes.historySection}>
                        <h3 className={classes.headingHistory}>{AppConstants.histroyOf}{parse(props.skill)}</h3>
                        <p className={classes.contentSkillsIntro}>{parse(props.skillHistory)}</p>
                    </div>
                </div>
                <div className={classes.funFactSection}>
                    <div className={classes.blueFrameTopRight}>
                        <img
                            src={blueRectangleImageUrl}
                            alt="Blue Top frame"
                        />
                    </div>
                    <div >
                        <div>

                        {isVideoPlaying ?(<div><video controls autoPlay className={classes.funFactContentImage}>
                           <source src={props.skillFunFactVideoPath} type="video/mp4" />
                        </video>  <div>
                          <IoMdClose className={classes.closeButton} onClick={handleClose} />
                       </div></div>              
                       ) : (<img src={props.skillFunFactImageUrl} className={classes.funFactContentImage} alt={`${props.skillName} ${AppConstants.funFact}`} />)}
                        </div> 
                        {/* {!isVideoPlaying && <img src={funFactIconImage} alt={`${props.skillName} ${AppConstants.funFact}`} className={classes.funFactStaticImage} />}
                        {!isVideoPlaying && <div className={classes.overlay}>
                            <p className={classes.overlayText}>{parse(props.skillFunFact)}</p>
                        </div>} */}
                        <div className={classes.videoPlayImage}>
                          {(showPlayIcon) && <img src={videoPlayIconUrl}  onClick={playVideo} alt="Video Play Button" className={classes.funFactVideoIcon}/>}
                       </div>
                    </div>
                    <div>
                 </div>
                </div>
            </div>
        </>
    )
}