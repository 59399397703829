import React from 'react';
import { TopNavigationWithoutLogin } from '../../layout/ExtendedPagesLayout/TopNavigationWithoutLogin';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { ExpertViewJudgingScreen } from '../../component/competitionJudgingComponents/ExpertViewJudgingScreen';
import { useLoaderData } from 'react-router-dom';

export const ExpertJudgingScreenPage = () => {
    const [footerDetails, judgingScreenDesktopBannerContent, judgingScreenTabletBannerContent, judgingScreenMobileBannerContent] = useLoaderData();

    return (
        <>
            <TopNavigationWithoutLogin />
            <ExpertViewJudgingScreen desktopBannerContent={judgingScreenDesktopBannerContent} tabletBannerContent={judgingScreenTabletBannerContent} mobileBannerContent={judgingScreenMobileBannerContent} />
            <LandingPageFooter landingPageData={footerDetails} />
        </>
    )
}

