import { useCallback, useEffect, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { AgGridReact } from "ag-grid-react";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { Card, Col, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { FaFilter } from "react-icons/fa6";
import moment from "moment";
import { MathUtil } from "../../../utils/MathUtil";

export const EnrolmentsOnQrenciaSection = () => {
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [learnerDetails, setLearnerDetails] = useState([]);
    const [qrenciaLearnersStats, setQrenciaLearnersStats] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const constructURL = (url, fromDateString, toDateString) => {
        if (fromDateString) {
            url = `${url}?${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDateString)}`
        }
        if (toDateString && !fromDateString) {
            url = `${url}?${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        } else if (toDateString && fromDateString) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        }
        if (fromDateString || toDateString) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        return url;
    };

    const fetchQrenciaLearnerDetails = useCallback(async (fromDateString, toDateString) => {
        let url = constructURL(AppConstants.qrenciaEnrolledLearnersAPI, fromDateString, toDateString);

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setLearnerDetails(response);
        } else {
            setLearnerDetails([]);
        }
    }, []);

    const fetchQrenciaLearnersStats = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getQrenciaStatsForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getQrenciaStatsForAdminAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setQrenciaLearnersStats(response);
        } else {
            setQrenciaLearnersStats({});
        }
    }, []);

    useEffect(() => {
        fetchQrenciaLearnerDetails();
        fetchQrenciaLearnersStats();
    }, [fetchQrenciaLearnerDetails, fetchQrenciaLearnersStats]);


    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        fetchQrenciaLearnersStats(fromDateString, toDateString);
        fetchQrenciaLearnerDetails(fromDateString, toDateString);
    }

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    }
    const getPaymentMethod = (data) => {
        if (data.learningActivityAccessType === "Free") {
            return data.learningActivityAccessType;
        }
        return `${data.learningActivityAccessType} (${data.paymentMode})`;
    }

    const learningActivityColumnDefs = [
        {
            headerName: `Learner ID`,
            field: "learnerQrenciaId",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learnerQrenciaId}`,
            valueGetter: (params) => {
                return params.data.learnerQrenciaId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Name`,
            field: "learnerName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learnerName}`,
            valueGetter: (params) => {
                return `${params.data.learnerName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Age`,
            field: "learnerAge",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learnerAge}`,
            valueGetter: (params) => {
                return `${params.data.learnerAge}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Learning Activity Type",
            field: "learningActivityType",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learningActivityType.charAt(0).toUpperCase()}${data.learningActivityType.slice(1)}`,
            valueGetter: (params) => {
                return `${params.data.learningActivityType.charAt(0).toUpperCase()}${params.data.learningActivityType.slice(1)}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: `Learning Activity Name`,
            field: "learningActivityName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learningActivityName}`,
            valueGetter: (params) => {
                return `${params.data.learningActivityName}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Creator Name",
            field: "creatorName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.creatorName}`,
            valueGetter: (params) => {
                return `${params.data.creatorName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Skill",
            field: "skill",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.skill}`,
            valueGetter: (params) => {
                return `${params.data.skill}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Paid or Free",
            field: "learningActivityAccessType",
            width: 300,
            tooltipValueGetter: ({ data }) => getPaymentMethod(data),
            valueGetter: ({ data }) => {
                return getPaymentMethod(data);
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Cost",
            field: "learningActivityCost",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learningActivityCost ? `INR ${data.learningActivityCost}` : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.learningActivityCost ? `INR ${data.learningActivityCost}` : AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Enrolled On",
            field: "dateOfEnrolment",
            width: 300,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.dateOfEnrolment).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.dateOfEnrolment).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Last Subscribed On",
            field: "subscriptionStartDate",
            minWidth: 150,
            tooltipValueGetter: ({ data }) => data.subscriptionStartDate ? `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A",
            valueGetter: ({ data }) => {
                return data.subscriptionStartDate ? `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A";
            },
            filter: 'agTextColumnFilter'
        }
    ];

    return (
        <div>
            <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
            {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
            <Row>
                <div className={`${dashboardClass.dashboardCards} mb-2`}>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.competitionCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Competition Enrolments</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaLearnersStats.competitionsEnrolments)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.courseCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Course Enrolments</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaLearnersStats.coursesEnrolments)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.workshopCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Workshop Enrolments</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaLearnersStats.workshopsEnrolments)}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <Row className="mt-4">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            className="expertObrDetailsGrid"
                            columnDefs={learningActivityColumnDefs}
                            pagination={true}
                            paginationPageSize={AppConstants.adminDashboardPaginationSize}
                            rowData={learnerDetails}
                            enableBrowserTooltips={true}
                            domLayout='autoHeight'
                            suppressRowHoverHighlight={true}
                            defaultColDef={{
                                filter: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                resizable: true,
                                suppressMenu: true,
                                suppressHorizontalScroll: true,
                                floatingFilterComponentParams: { suppressFilterButton: true },
                                floatingFilter: true,
                            }}
                            enableFilter={true}
                            enableSorting={true}
                            enableSearching={true}
                            headerClass={classes.obrGridHeader}
                            rowClass={classes.obrGridTable}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}