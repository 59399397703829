import { useState } from "react";
import parse from 'html-react-parser';
import { AppConstants } from "../../constants/AppConstants";
import classes from './DiscoverYourPassionUpdatedSection.module.css';
import qrenciaAtGlanceClasses from './QrenciaAtGlanceSection.module.css';
import { DiscoverPassionContentSection } from "../lists/DiscoverPassionContentSection";

export const DiscoverYourPassionUpdatedSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [isClicked, setIsClicked] = useState(false);
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueTriangleImagePath}`;
    const backgroundGreyDotsUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.backgroundGreyDotsSmallPath}`;
    const [discoverPassionDetails, setDiscoverPassionDetails] = useState(props.landingPageData.discoverPassion);

    function OpenVideoPopup() {
        setIsClicked(true);
    }
    function closeDiscoverPassionPopup() {
        setIsClicked(false);
    }

    return (
        <>
            {discoverPassionDetails.active === AppConstants.trueText &&
                <div className={classes.discoverYourPassionSection}>
                    <div className={classes.discoverYourPassionContent}>
                        <div className={classes.discoverContentSection}>
                            <h2 className={qrenciaAtGlanceClasses.titleText}>{parse(discoverPassionDetails.description)}</h2>
                            <img
                                className={classes.blueTriangleImage}
                                src={blueTriangleImageUrl}
                                alt="Blue Triangle"
                            />
                            <h2 className={classes.heading}>{parse(discoverPassionDetails.title)}</h2>
                            <DiscoverPassionContentSection pointers={discoverPassionDetails.list} />
                        </div>
                        {/* <div className={qrenciaAtGlanceClasses.videoPopupSection}>
                            <div>
                                <div className={qrenciaAtGlanceClasses.thumbnailImage}>
                                    <img onClick={OpenVideoPopup}
                                        className={qrenciaAtGlanceClasses.videoThumbnailImage}
                                        src={discoverPassionDetails.bannerImage.imagePath}
                                        alt="Video Thumbnail"
                                    />
                                </div>
                                <div className={qrenciaAtGlanceClasses.videoPlayImage}>
                                    <img onClick={OpenVideoPopup}
                                        className={qrenciaAtGlanceClasses.videoPlayIcon}
                                        src={videoPlayIconUrl}
                                        alt="Video Play Button"
                                    />
                                </div>
                                <div className={qrenciaAtGlanceClasses.blueFrame}>
                                    <img onClick={OpenVideoPopup}
                                        src={blueRectangleImageUrl}
                                        alt="Video Frame" className={qrenciaAtGlanceClasses.blueImage}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className={classes.backgrounImageSection}>
                        <img
                            className={classes.backgroundDotsImage}
                            src={backgroundGreyDotsUrl}
                            alt="Background Cover"
                        />
                    </div>
                    {/* {isClicked && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeDiscoverPassionPopup} videoUrl={discoverPassionDetails.bannerImage.videoPath} />} */}
                </div>
            }
        </>
    );
}