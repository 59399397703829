import { useEffect, useState } from 'react';
import { Collapse } from "react-bootstrap";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { MarkAttendanceWidgetCards } from './MarkAttendanceWidgetCards';
import classes from "./MarkAttendanceRightWidget.module.css";
import './widget.css';

export const MarkAttendanceRightWidget = () => {
    const [open, setOpen] = useState(true);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchcourses = async () => {
            const url = AppConstants.getAvailableCoursesForAttendanceAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setCourses([...response.todaysCourses, ...response.remainingCourses]);
            } else {
                setCourses([]);
            }
        }

        fetchcourses();
    }, []);

    return (
        <>
            <div className="quickLinkSection">
                <div className="widgetSection">
                    <div className="headingSection">
                        <h1 className="widgetHeading">Attendance and Tracking</h1>
                    </div>
                    <div className="iconSection">
                        {!open && <HiOutlinePlusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                        {open && <HiOutlineMinusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                    </div>
                </div>
                <Collapse in={open}>
                    <div id="expertUpcomingCompetitionsWidget">
                        <div className="collapseDiv">
                            {(courses.length !== 0) && <MarkAttendanceWidgetCards cardDetails={courses} />}
                            {(courses.length === 0) && <div className={classes.noCoursesAvailableSection}>No courses to display at the moment</div>}
                        </div>
                    </div>
                </Collapse>
            </div>
        </>
    )
}