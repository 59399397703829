import React from 'react';
import {IndividualQtalk} from "../../component/extendedPageComponents/qtalks/IndividualQtalk";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { useLoaderData, useParams} from "react-router-dom";
export const IndividualQtalksPage = ()=>{
    const [landingPageData, qTalksData] = useLoaderData();
    
    return(
        <>
        <LandingPageTopNavigation landingPageData={landingPageData} />
        <IndividualQtalk qTalksData={qTalksData}/>
        <LandingPageFooter landingPageData={landingPageData} />
        </>
    )
}