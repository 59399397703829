import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import styles from "./StudentRaiseNudgeSection.module.css";
import { AppConstants } from '../../constants/AppConstants';
import { StudentRaiseNudgeForm } from '../formControls/StudentRaiseNudgeForm';
import '../../index.css';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { useSearchParams } from 'react-router-dom';
import Auth from '../../utils/Auth';
import { useParams } from 'react-router-dom';
import { LoginModal } from '../modal/LoginModal';

export const StudentRaiseNudgeSection = (props) => {
    const { studentUniqueId } = useParams();
    const [studentWeakCreteria, setStudentWeakCreteria] = useState(props.studentWeakCreteria);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.qrenciaGlobeImage}`;
    const [showModal, setShowModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));

    const [passwordType, setPasswordType] = useState('password');
    const [showError, setShowError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const initialValues = { emailId: AppConstants.emptyString, password: AppConstants.emptyString };
    const [loginFormValues, setLoginFormValues] = useState(initialValues);

    const togglePassword = () => {
        if (passwordType === AppConstants.fieldTypePassword) {
            setPasswordType(AppConstants.fieldTypeText);
            return;
        }
        else {
            setPasswordType(AppConstants.fieldTypePassword);
            return;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginFormValues({ ...loginFormValues, [name]: value });
    };

    const loginUser = (e) => {
        e.preventDefault();
        setShowSpinner(AppConstants.trueText);
        const apiURL = process.env.REACT_APP_API_URL;
        const authenticationUrl = `${apiURL}` + AppConstants.authenticationUri;
        const authData = {
            userName: loginFormValues.emailId,
            userPassword: loginFormValues.password
        }
        fetch(authenticationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/JSON'
            },
            body: JSON.stringify(authData)
        })
            .then((response) => {
                if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                    handleModalClose();
                    return response.json();
                }
                else {
                    setShowError(ErrorMessages.loginFailed);
                    setShowSpinner(false);
                    const timer = setTimeout(() => {
                        setShowError(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => clearTimeout(timer);
                }
            })
            .then((data) => {
                if (data !== null) {
                    const token = data.accessToken;
                    if (token !== null && token !== undefined) {
                        setLoginFormValues(initialValues);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                        localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(data));
                        window.location.reload();
                    }
                }
            })
    }

    useEffect(() => {
        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return null;
            }
        };

        if (paymentParam) {
            let payload = decodeBase64EncodedString(paymentParam);
            if (payload && payload.category === props.category) {
                showModalHandler();
            }
        }

    }, [paymentParam]);

    const showModalHandler = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    }

    return (
        <>
            <div className={styles.raiseNudgeSectionContainer}>
                <div className={styles.topHeadingContainer}>
                    <h1 className={styles.headingText}><strong>{AppConstants.performanceAnalysisPageRaiseNudgeSectionStaticText.headingText}</strong></h1>
                    <p className={styles.subHeadingText}>{AppConstants.performanceAnalysisPageRaiseNudgeSectionStaticText.subHeadingText}</p>
                </div>
                <div className={styles.bottomContentContainer}>
                    <div className={styles.qrenciaGlobeContainer}>
                        <img className={styles.qrenciaGlobeLeft} src={qrenciaGlobeImage} alt="Qrencia Globe" />
                        <div className={styles.weakCreteriaContainer}>
                            {studentWeakCreteria.map((items, index) => (<div key={index} className={styles.weakCreteria}>
                                <p>{items.criteriaName}</p>
                            </div>))}
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button className={styles.raiseNudgeButton} onClick={() => { showModalHandler() }}>Raise Nudge</Button>
                        {/* <Button className={styles.raiseNudgeButtonDisabled}>Raise Nudge</Button> */}
                    </div>
                </div>
            </div>
            {Auth.isLogin() ? (
                <Modal size="lg" show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.modalTitle}>Raise Nudge</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={styles.modalBody}>
                        {Auth.getUserRole() === AppConstants.userRoleStudent ? <StudentRaiseNudgeForm category={AppConstants.learningActivitiesTypes.nudge} /> : <p className={styles.errorContainer}>Only Students can raise nudge!</p>}
                    </Modal.Body>
                </Modal>) :
                (showModal && <LoginModal onShow={showModalHandler} onClose={handleModalClose} />)}
        </>
    );
}

