import { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";
import { StudentUpcomingWorkshopsWidgetCards } from '../cards/StudentUpcomingWorkshopsWidgetCards.js';
import { AppConstants } from '../../constants/AppConstants';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from '../../constants/ErrorMessages';
import classes from './StudentUpcomingCompetitionsWidget.module.css';
import './widget.css';
import { DateTime } from 'luxon';

export const StudentUpcomingWorkshopsWidget = () => {
    const [open, setOpen] = useState(true);
    const [upcomingWorkshopsData, setUpcomingWorkshopsData] = useState([]);

    useEffect(() => {
        async function getUpcomingWorkshops() {
            const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
            const createDateTimeObject = (date, time) => {
                const dateArr = date.split(AppConstants.hyphen);

                const dateTime = DateTime.fromObject({
                    year: dateArr[0],
                    month: dateArr[1],
                    day: dateArr[2],
                    hour: 0,
                    minute: 0,
                    second: 0
                }, { zone: timeZone });

                return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);;
            };

            const currentTime = DateTime.now().setZone(timeZone).plus({ days: 1 }).toString();
            const selectedDateStartTime = createDateTimeObject(currentTime.split("T")[0], "00:00:00");
            const userSelectedDateStartTime = encodeURIComponent(selectedDateStartTime);
            const selectedDateEndTime = new Date();
            const userSelectedDateEndTime = encodeURIComponent(selectedDateEndTime);
            const userFetchedTimeZone = encodeURIComponent(timeZone);
            const url = `${AppConstants.studentUpcomingCompetitionsAndCoursesAPI}?${AppConstants.selectedDateStartTimeParam}${userSelectedDateStartTime}&${AppConstants.selectedDateEndTimeParam}${userSelectedDateEndTime}${AppConstants.localeParam}${userFetchedTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setUpcomingWorkshopsData(response?.workshopDetails);
            } else {
                setUpcomingWorkshopsData([]);
            }
        }
        getUpcomingWorkshops();
    }, []);

    return (
        <div className="quickLinkSection">
            <div className="widgetSection">
                <div className="headingSection">
                    <h1 className="widgetHeading">Upcoming Workshops</h1>
                </div>
                <div className="iconSection">
                    {!open && <HiOutlinePlusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                    {open && <HiOutlineMinusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                </div>
            </div>
            <Collapse in={open}>
                <div id="studentUpcomingWorkshopsWidget">
                    <div className="collapseDiv">
                        {upcomingWorkshopsData?.length !== 0 && <StudentUpcomingWorkshopsWidgetCards cardDetails={upcomingWorkshopsData} />}
                        {upcomingWorkshopsData?.length === 0 && <div className={classes.noCompetitionsAvailableSection}>{ErrorMessages.noUpcomingEnroledWorkshopsFallbackMessage}</div>}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}