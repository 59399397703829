import { useEffect, useState } from "react";
import { AppConstants } from "../constants/AppConstants";
import classes from "../component/quizEventComponent/InverseCounterInMilliseconds.module.css";
import { MdOutlineTimer } from "react-icons/md";

export const InverseCounterInMilliseconds = (props) => {
    const [seconds, setSeconds] = useState(AppConstants.emptyString);
    const [timerAlertClass, setTimerAlertClass] = useState(AppConstants.falseText);
    const [time, setTime] = useState({ m: 0, s: 0 });
    const [paused, setPaused] = useState(false);

    let timer = 0;

    useEffect(() => {
        setSeconds(props.durationInMs / 1000)
    }, [props.durationInMs]);

    useEffect(() => {
        let timeLeftVar = secondsToTime(seconds);
        setTime(timeLeftVar);
        if (!paused) {
            startTimer();
        }

        return () => {
            clearInterval(timer);
        };
    }, [seconds, paused]);

    useEffect(() => {
        if (props.pauseTimer) {
            clearInterval(timer);
            setPaused(true);
        } else {
            setPaused(false);
        }
    }, [props.pauseTimer]);


    const secondsToTime = (secs) => {
        let minutes = Math.floor(secs / (60));

        let divisor_for_seconds = secs % (60);
        let seconds = Math.ceil(divisor_for_seconds);

        return {
            m: minutes,
            s: seconds
        };
    };

    const startTimer = () => {
        if (timer === 0 && seconds > 0) {
            timer = setInterval(countDown, 1000);
        }
    };

    const countDown = () => {
        let secondsLeft = seconds - 1;
        setSeconds(secondsLeft);
        setTime(secondsToTime(secondsLeft));

        if (secondsLeft < 30 && !timerAlertClass) {
            setTimerAlertClass(AppConstants.trueText);
        }

        if (secondsLeft === 0) {
            clearInterval(timer);
            props.onTimerUp();
        }
    };

    return (
        <div>
            <p className={timerAlertClass ? classes.alertCounter : classes.counter}><MdOutlineTimer /> {time.m} min {time.s} sec</p>
        </div>
    )
}