import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { useLoaderData } from 'react-router-dom';
import classes from "./ExpertOnboardingStatusPage.module.css";

import { ExpertObrPageBanner } from "../../component/expertObrComponent/ExpertObrPageBanner";
import { ExpertObrStatusForm } from "../../component/formControls/ExpertObrStatusForm";
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
export const ExpertOnboardingStatusPage = () => {
    const [landingPageData, desktopBannerContent, tabletBannerContent, mobileBannerContent] = useLoaderData();
    return (
        <div className={classes.container}>
            <LoginPageTopNavigation landingPageData={landingPageData} />
            <ExpertObrPageBanner desktopBannerContent={desktopBannerContent} tabletBannerContent={tabletBannerContent} mobileBannerContent={mobileBannerContent} />
            <ExpertObrStatusForm />
            <LandingPageFooter landingPageData={landingPageData} />
        </div>
    )
}