import React, { useEffect, useState } from "react";
import classes from "./IndividualQtalk.module.css"
import {DataService} from "../../../services/DataService";
import {QtalksBanner} from "../qtalks/QtalksBanner";

import {QTalksKeyHighlightSection} from "../qtalks/QTalksKeyHighlightSection";

import { useParams } from "react-router-dom";
import {AppConstants} from "../../../constants/AppConstants";
import { Breadcrumbs } from "../../../utils/BreadcrumbsUtil";

export const IndividualQtalk = (props) => {
    const { categorySlug, skillSlug, qTalkId } = useParams();
    const qTalkIdfromSlug = qTalkId.substring(2,qTalkId.length);
    const [qTalk, setQTalk] = useState(...props.qTalksData.filter((item)=>parseInt(item.qtalkId)===parseInt(qTalkIdfromSlug)));
    

    useEffect(()=>{
        fetchQtalks();
        async function fetchQtalks(){
            const url = AppConstants.getAllQtalksDataAPI;
            const response = await DataService.get(
              url,
              AppConstants.emptyString,
              AppConstants.emptyString
            );
            setQTalk(...response.filter((item)=>parseInt(item.qtalkId)===parseInt(qTalkIdfromSlug)));
        }
    },[])

    return (
        <>
            
            <Breadcrumbs class={classes.qTalkBreadcrumbs} />
            <QtalksBanner qTalk={qTalk} />
            <QTalksKeyHighlightSection qTalk={qTalk}/>
        </>
    )
}