import { useState, useEffect } from "react"
import { Row, Col, Button } from "react-bootstrap"
import { AddJudgesComponent } from "../judgesComponent/AddJudgesComponent/AddJudgesComponent"
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage"
import { ViewJudgeCard } from "../cards/viewJudgesCard/ViewJudgeCard"
import classes from "./EditJudges.module.css"
import { AppConstants } from "../../constants/AppConstants"
import { ErrorMessages } from "../../constants/ErrorMessages"

export const EditJudges = ({ competitionStatus, changeJudgesData, judgeDetails, rubricsConfigured, stage, saveCurrentStep, isGymnasticsSkillSelected, selectedSkill }) => {
    const status = AppConstants.competitionStatus;
    const stages = AppConstants.editCompetitionActions;
    const [judges, setJudges] = useState([])
    const [emails, setEmails] = useState([]);
    const [emailError, setEmailError] = useState(AppConstants.emptyString);
    const [showDuplicateEmailError, setShowDuplicateEmailError] = useState(false);
    const [copiedSkill, setCopiedSkill] = useState(AppConstants.nullText);
    const [judgesUpdatedFlag, setJudgesUpdatedFlag] = useState(AppConstants.falseText);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDuplicateEmailError(false);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [showDuplicateEmailError]);


    useEffect(() => {
        const saveJudgesData = () => {
            changeJudgesData(judges);
        }
        saveJudgesData();
    }, [judges]);

    useEffect(() => {
        if (judgeDetails.length > 0) {
            if (selectedSkill !== copiedSkill) {
                setJudges([]);
                setEmails([]);
                setCopiedSkill(selectedSkill);
            } else {
                if (!judgesUpdatedFlag) {
                    setJudgesUpdatedFlag(AppConstants.trueText);
                    setJudges(judgeDetails);
                    setEmails(judgeDetails.map(judge => judge.judgeEmail));
                }
            }
        } else {
            setJudges([]);
            setEmails([]);
        }
    }, [judgeDetails, selectedSkill, copiedSkill, judgesUpdatedFlag]);

    const addJudgeToList = (judge) => {
        if (!emails.some(emailId => emailId.toLowerCase() === judge.judgeEmail.toLowerCase())) {
            emails.push(judge.judgeEmail);
            setJudges([...judges, judge])
        } else {
            setShowDuplicateEmailError(true);
            setEmailError(ErrorMessages.judgeEmailAlreadyAdded);
        }
    };

    const removeJudgeFromList = (judgeToRemove) => {
        setJudges(judges.filter(judge => judge.judgeEmail !== judgeToRemove.judgeEmail));
        setEmails(emails.filter(id => id !== judgeToRemove.judgeEmail));
    }

    const handleSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    return <div className={`${classes.addViewJudgesContainer} ${competitionStatus === status.complete && classes.inactiveComponent}`}>
        <AddJudgesComponent competitionStatus={competitionStatus} addJudgeToList={addJudgeToList} rubricsConfigured={rubricsConfigured} numberOfJudges={judges?.length} isGymnasticsSkillSelected={isGymnasticsSkillSelected} />
        {showDuplicateEmailError && <ErrorSuccessAlertMessage message={emailError} varient={AppConstants.alertVarient[1]} />}
        {judges?.length > 0 && rubricsConfigured && <div className={classes.viewJudgeContainer}>
            <div className={classes.judgeHeadingText}>{`Judges (${judges.length})`}</div>
            <Row xs={1} sm={2} md={2} lg={2} xl={3} className="mb-2">
                {
                    judges?.map((judge, i) => <Col key={judge.judgeEmail}> <ViewJudgeCard
                        key={i} judgeDetails={judge}
                        removeJudge={removeJudgeFromList}
                        showDeleteButton={true}
                        showSendMailButton={false}
                        competitionStatus={competitionStatus}
                    /></Col>)
                }
            </Row >
        </div>}
        <div className={classes.nextBtn}>
            <Button type="submit" onClick={handleSubmit}>{AppConstants.nextText}</Button>
        </div>
    </div>
}