import React from 'react';
import { LoginPageCarousel } from '../component/carousels/LoginPageCarousel';
import { useLoaderData } from 'react-router-dom';
import './signup.css';
import { ResetPasswordPageForm } from '../component/formControls/ResetPasswordPageForm';

const ResetPassword = () => {

    const [pageSpecificStaticData] = useLoaderData();

    return (
        <>
            <div className='row main-container-signup'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 carousel-container'>
                    <LoginPageCarousel pageSpecificStaticData={pageSpecificStaticData} />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 login-form-container'>
                    <ResetPasswordPageForm />
                </div>
            </div>
        </>
    );
}

export default ResetPassword;