import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { IndividualCoursePageLayout } from "../../component/individualCoursePage/IndividualCoursePageLayout";

export const DedicatedCoursPage = () => {
    const [landingPageData] = useLoaderData();
    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <IndividualCoursePageLayout />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}

