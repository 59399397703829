import Auth from "./Auth";
import { DateTimeUtil } from "./DateTimeUtil";
import { AppConstants } from "../constants/AppConstants";
import { ErrorMessages } from "../constants/ErrorMessages";
import { SuccessMessage } from "../constants/SuccessMessage";
import { AuthorizedDataService } from "../services/AuthorizedDataService"
import { EnrolInCompetition } from "../component/studentPageComponents/EnrolInCompetition";
import { EnrolInCourse } from "../component/studentPageComponents/EnrolInCourse";
import { EnrolInWorkshop } from "../component/studentPageComponents/EnrolInWorkshop";
import { StringUtils } from "./StringUtils";

export const PaymentsUtil = {

    /**
     * Mehod to initiate payment using phonepay Payment Gateway
     */
    async initiatePayment(props) {
        try {
            if (!Auth.isLogin()) {
                return ErrorMessages.pleaseLogInToEnrolError;
            } else {
                const url = AppConstants.initiatePaymentAPI;
                const payload = {
                    uniqueId: props.uniqueId,
                    redirectUrl: props.redirectUrl,
                    learningActivityType: props.learningActivityType
                };

                const response = await AuthorizedDataService.postRequestWithResponseCode(
                    url,
                    payload,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                        const body = await response.json();
                        const url = body?.data?.instrumentResponse?.redirectInfo?.url;
                        if (url) {
                            setTimeout(() => {
                                window.open(url, AppConstants.newPageTargetSelf);
                            }, AppConstants.paymentGatewayRedirectTimeout);
                        }
                        return SuccessMessage.paymentInitiatedMessage;
                    } else if (!response.ok && response.status === AppConstants.httpResponseCodes.responseCode403) {
                        return props.learningActivityType === AppConstants.learningActivitiesTypes.nudge ? ErrorMessages.sessionExpiredPaymentInitiatedForNudge : `${ErrorMessages.sessionExpiredPaymentInitiatedForEnrolment} ${props.learningActivityType.toLowerCase()}`
                    } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.nudge) {
                        return ErrorMessages.initiatePaymentFailedError;
                    } else {
                        const data = await response.json();
                        if (data === -25) {
                            return ErrorMessages.initiatePaymentFailedError;
                        }
                        if (props.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                            return EnrolInCompetition.getEnrolmentStatus(data, props.cost);
                        } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                            return EnrolInCourse.getEnrolmentStatus(data, props.cost);
                        } if (props.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
                            return EnrolInWorkshop.getEnrolmentStatus(data, props.cost);
                        } else {
                            return ErrorMessages.initiatePaymentFailedError;
                        }
                    }
                } else {
                    return ErrorMessages.initiatePaymentFailedError;
                }
            }
        } catch (error) {
            return ErrorMessages.initiatePaymentFailedError;
        }
    },

    /**
     * Mehod to initiate verify using phonepay Payment Gateway
     */
    async verifyPayment(props) {
        try {
            const url = AppConstants.VerifyPaymentStatusAPI;
            const payload = {
                uniqueId: props.uniqueId,
                learningActivityType: props.learningActivityType,
                learningActivityName: props.learningActivityName,
                preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser()
            };

            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                payload,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined && response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                const body = await response.json();
                return {
                    verificationStatus: AppConstants.paymentStatus.verified,
                    transactionId: body.transactionId,
                    message: SuccessMessage.paymentVerifiedMessage
                }
            } else {
                const body = await response.json();
                return {
                    verificationStatus: AppConstants.paymentStatus.failed,
                    transactionId: body.transactionId,
                    message: ErrorMessages.verifyPaymentFailedError
                }
            }
        } catch (error) {
            return {
                verificationStatus: AppConstants.paymentStatus.failed,
                transactionId: AppConstants.emptyString,
                message: ErrorMessages.verifyPaymentFailedException
            }
        }
    },

    calculateCost({ cost, discount, teamSize, teamEnrolmentFlag }) {
        try {
            if (cost === 0 || cost === AppConstants.emptyString || cost === null || cost === undefined || cost === discount) {
                return AppConstants.freeText;
            } else if (teamEnrolmentFlag == null) {
                let netAmount = discount ? cost - discount : cost;
                let formattedNumber = netAmount.toLocaleString('en-US');
                return `INR ${formattedNumber}`;
            } else {
                let netAmount = discount ? (cost - discount) * teamSize : cost * teamSize;
                let formattedNumber = netAmount.toLocaleString('en-US');
                return `INR ${formattedNumber}`;
            }
        } catch (error) {
            return AppConstants.emptyString;
        }
    },

    getRazorpayPaymentOptions(orderDetails) {
        return {
            key: orderDetails.apiKey,
            amount: orderDetails.amount,
            currency: orderDetails.currency,
            name: orderDetails.bussinessName,
            description: orderDetails.learningActivityName,
            order_id: orderDetails.orderId,
            animation: AppConstants.trueText,
            image: `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.logoImageS3Path}${AppConstants.forwardSlash}${AppConstants.qLogo}`,
            prefill: {
                contact: orderDetails.learnerContactNumber,
            },
            theme: {
                color: "#224b7f",
            },
            display: {
                emi: AppConstants.falseText,
                cardlessEmi: AppConstants.falseText,
                paypaylaterLater: AppConstants.falseText
            },
            modal: {
                ondismiss: function () {
                    window.location.reload();
                },
            },
            method: {
                "card": true,
                "netbanking": true,
                "wallet": true,
                "upi": true,
                "international": true
            },
            config: {
                display: {
                    hide: [
                        { method: 'paylater' },
                        { method: 'emi' }
                    ]
                }
            }
        }
    },

    /**
     * Mehod to initiate payment using RazorPay Payment Gateway
     */
    async initiatePaymentWithRazorpay(props) {
        try {
            if (!Auth.isLogin()) {
                return ErrorMessages.pleaseLogInToEnrolError;
            } else {
                const url = AppConstants.razorpayInitiatePaymentsAPI;
                const payload = {
                    uniqueId: props.uniqueId,
                    learningActivityType: props.learningActivityType
                };
                const response = await AuthorizedDataService.postRequestWithResponseCode(
                    url,
                    payload,
                    AppConstants.emptyString
                );

                if (response.status === AppConstants.httpResponseCodes.responseCode201 && response.ok) {
                    const body = await response.json();
                    const orderDetails = StringUtils.decodeBase64EncodedString(body.responseToken);
                    const options = this.getRazorpayPaymentOptions(orderDetails);
                    return {
                        message: SuccessMessage.paymentInitiatedMessage,
                        options,
                        learningActivityDetails: {
                            uniqueId: props.uniqueId,
                            learningActivityType: props.learningActivityType,
                            learningActivityName: props.learningActivityName,
                            orderId: orderDetails.orderId,
                            receipt: orderDetails.receipt,
                            userLearningActivityPaymentDetailsId: orderDetails.userLearningActivityPaymentDetailsId
                        }
                    }

                } else if (!response.ok && response.status === AppConstants.httpResponseCodes.responseCode403) {
                    let message = props.learningActivityType === AppConstants.learningActivitiesTypes.nudge ? ErrorMessages.sessionExpiredPaymentInitiatedForNudge : `${ErrorMessages.sessionExpiredPaymentInitiatedForEnrolment} ${props.learningActivityType.toLowerCase()}`
                    return { message };
                } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.nudge) {
                    return { message: ErrorMessages.initiatePaymentFailedError };
                } else {
                    const data = await response.json();
                    if (data.statusCode === -25) {
                        return { message: ErrorMessages.initiatePaymentFailedError };
                    }
                    if (props.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                        return { message: EnrolInCompetition.getEnrolmentStatus(data.statusCode, props.cost) }
                    } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                        return { message: EnrolInCourse.getEnrolmentStatus(data.statusCode, props.cost) }
                    } if (props.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
                        return { message: EnrolInWorkshop.getEnrolmentStatus(data.statusCode, props.cost) }
                    } else {
                        return { message: ErrorMessages.initiatePaymentFailedError };
                    }
                }
            }
        } catch (error) {
            return { message: ErrorMessages.initiatePaymentFailedError };
        }
    },

    /**
     * Mehod to verify payment done through RazorPay Payment Gateway
     */
    async verifyRazorpayPaymentStatus(paymentResponseDetails, learningActivityDetails, teamDetails) {
        const url = AppConstants.razorpayVerifyPaymentsAPI;
        const payload = {
            razorpayPaymentId: paymentResponseDetails.razorpay_payment_id,
            razorpayOrderId: paymentResponseDetails.razorpay_order_id,
            razorpaySignature: paymentResponseDetails.razorpay_signature,
            receipt: learningActivityDetails.receipt,
            userLearningActivityPaymentDetailsId: learningActivityDetails.userLearningActivityPaymentDetailsId,
            learningActivityUniqueId: learningActivityDetails.uniqueId,
            learningActivityType: learningActivityDetails.learningActivityType,
            learningActivityName: learningActivityDetails.learningActivityName,
            preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser(),
            learnersForTeamEnrolments: teamDetails || null
        }

        const response = await AuthorizedDataService.postRequest(
            url,
            payload,
            AppConstants.emptyString
        );

        if (response !== undefined && response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
            return {
                verificationStatus: AppConstants.paymentStatus.verified,
                transactionId: paymentResponseDetails.razorpay_payment_id,
                message: SuccessMessage.paymentVerifiedMessage
            }
        } else {
            return {
                verificationStatus: AppConstants.paymentStatus.failed,
                transactionId: paymentResponseDetails.razorpay_payment_id,
                message: ErrorMessages.verifyPaymentFailedError
            }
        }
    },

    /**
     * Mehod to update the failure payment status for razorpay payment gateway
     */
    async capturePaymentFailureState(paymentResponseDetails) {
        const url = AppConstants.capturePaymentFailureStateAPI;
        const response = await AuthorizedDataService.postRequest(
            url,
            paymentResponseDetails,
            AppConstants.emptyString
        );

        if (response.status === 200 && response.ok) {
            return AppConstants.trueText;
        } else {
            return AppConstants.falseText
        }
    },

    handleRazorpayPaymentFailure(response, learningActivityDetails, teamDetails) {
        const paymentData = {
            razorpayOrderId: response.error.metadata.order_id || learningActivityDetails.orderId,
            razorpayPaymentId: response.error.metadata.payment_id,
            failureReason: response.error.reason,
            learningActivityUniqueId: learningActivityDetails.uniqueId,
            learningActivityType: learningActivityDetails.learningActivityType,
            learningActivityName: learningActivityDetails.learningActivityName,
            preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser(),
            receipt: learningActivityDetails.receipt,
            userLearningActivityPaymentDetailsId: learningActivityDetails.userLearningActivityPaymentDetailsId,
            learnersForTeamEnrolments: teamDetails
        }
        this.capturePaymentFailureState(paymentData);
    },

    async initiatePaymentsForTeamEnrolments(props) {
        try {
            if (!Auth.isLogin()) {
                return ErrorMessages.pleaseLogInToEnrolError;
            } else {
                const url = AppConstants.initiateOrderForTeamEnrolmentAPI;
                const response = await AuthorizedDataService.postRequestWithResponseCode(url, props.payload, AppConstants.emptyString);

                if (response.status === AppConstants.httpResponseCodes.responseCode201 && response.ok) {
                    const body = await response.json();
                    const orderDetails = StringUtils.decodeBase64EncodedString(body.responseToken);
                    const options = this.getRazorpayPaymentOptions(orderDetails);

                    return {
                        message: SuccessMessage.paymentInitiatedMessage,
                        options,
                        learningActivityDetails: {
                            uniqueId: props.uniqueId,
                            learningActivityType: props.learningActivityType,
                            learningActivityName: props.learningActivityName,
                            orderId: orderDetails.orderId,
                            receipt: orderDetails.receipt,
                            userLearningActivityPaymentDetailsId: orderDetails.userLearningActivityPaymentDetailsId
                        },
                        teamDetails: props.teamDetails
                    }

                } else if (!response.ok && response.status === AppConstants.httpResponseCodes.responseCode403) {
                    let message = `${ErrorMessages.sessionExpiredPaymentInitiatedForEnrolment} ${props.learningActivityType.toLowerCase()}`
                    return { message };
                } else {
                    const data = await response.json();
                    if (data.statusCode === -25) {
                        return { message: ErrorMessages.initiatePaymentFailedError };
                    }

                    if (props.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                        return { message: EnrolInCompetition.getEnrolmentStatusForTeamCompetitions(data, props.cost) }
                    } else {
                        return { message: ErrorMessages.initiatePaymentFailedError };
                    }
                }
            }
        } catch (error) {
            return { message: ErrorMessages.initiatePaymentFailedError };
        }
    },
}