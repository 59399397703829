import React, { useEffect, useState, useRef } from 'react';
import { AppConstants } from '../../../../constants/AppConstants';
import { Button, Row, Col } from "react-bootstrap";
import 'moment-timezone';
import moment from 'moment';
import { BiLoaderAlt } from "react-icons/bi";
import ReactQuill from "react-quill";
import classes from './AdminGetObrForEmailForm.module.css';
import { TiArrowLeft } from "react-icons/ti";
import { BiDownload } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';
import parse from 'html-react-parser';
import { SuccessMessage } from "../../../../constants/SuccessMessage";
import { ErrorMessages } from "../../../../constants/ErrorMessages";
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import { FileOperationsUtil } from "../../../../utils/FileOperationsUtil";
import { ErrorSuccessAlertMessage } from "../../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { DateTimeUtil } from '../../../../utils/DateTimeUtil';
import { ImageGalleryModal } from '../../../modal/ImageGalleryModal';

export const AgencyObrDetails = (props) => {
    const [showGrid, setShowGrid] = useState(AppConstants.falseText);
    const [obrCommentText, setObrCommentText] = useState(AppConstants.emptyEditorString);
    const [obrCommentSpinner, setObrCommentSpinner] = useState(AppConstants.falseText);
    const [obrApproveSpinner, setObrApproveSpinner] = useState(AppConstants.falseText);
    const [obrRejectSpinner, setObrRejectSpinner] = useState(AppConstants.falseText);
    const [obrDeactivateSpinner, setObrDeactivateSpinner] = useState(AppConstants.falseText);
    const [obrActivateSpinner, setObrActivateSpinner] = useState(AppConstants.falseText);
    const [obrCommentHasError, setObrCommentHasError] = useState(AppConstants.falseText);
    const [obrCommentMessage, setObrCommentMessage] = useState(AppConstants.emptyString);
    const [obrCommentHasErrorVarient, setObrCommentHasErrorVarient] = useState(AppConstants.emptyString);
    const [downloadFailed, setDownloadFailed] = useState(false);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showCreatorGallery, setShowCreatorGallery] = useState(AppConstants.falseText);
    const [isActive, setIsActive] = useState(props.selectedObr.isActiveFlag === AppConstants.trueFlag ? AppConstants.trueText : AppConstants.falseText);
    const [expertStatusFontColorClass, setExpertStatusFontColorClass] = useState("expertObrStatusField");

    useEffect(() => {
        function getCssClassForAgencyObrStatus() {
            const defaultClassName = `${classes.expertObrStatusField}`;
            let additionalClassName = AppConstants.emptyString;
            if (props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusApproved) {
                additionalClassName = `${classes.expertObrStatusFieldGreen}`;
            } else if (props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusRejected) {
                additionalClassName = `${classes.expertObrStatusFieldRed}`;
            } else if (props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusDraft) {
                additionalClassName = `${classes.expertObrStatusFieldAmber}`;
            } else if (props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusInReview) {
                additionalClassName = `${classes.expertObrStatusFieldBlue}`;
            }
            setExpertStatusFontColorClass(defaultClassName + AppConstants.space + additionalClassName);
        }
        getCssClassForAgencyObrStatus();
    }, []);

    const reactQuillRef = useRef();
    //show and hide grid table and details component
    function toggleShowGrid() {
        setShowGrid(!showGrid);
        if (props.isNotActiveSection) {
            props.onNotActiveShowDetails();
        }
        else if (!props.isNotActiveSection) {
            props.onActiveShowDetails();
        }

    }
    //download competition pre-read
    async function downloadObrDocument(e, fileUrl, agencyObr, fileType, fileExtension) {
        e.preventDefault();
        setDownloadFailed(false);
        const fileName = `${agencyObr.agencyHeadFirstName}${AppConstants.space}${agencyObr.agencyHeadLastName}${AppConstants.hyphen}${agencyObr.agencyObrId}${AppConstants.hyphen}${fileType}`

        try {
            FileOperationsUtil.downloadFileFromS3(fileUrl, fileName, fileExtension);
        } catch (error) {
            setDownloadFailed(true);
        }
    }

    // check if comments are more than 500 characters
    const checkCharacterCount = (event) => {
        const editor = reactQuillRef.current.unprivilegedEditor;
        if (editor.getLength() > 500 && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (editor.getLength() > 501) {
            setObrCommentHasError(true);
            setObrCommentMessage(ErrorMessages.obrCommentLength);
            setTimeout(() => setObrCommentHasError(false), AppConstants.messageDisappearTime);
        } else {
            setObrCommentHasError(false);
        }
    };

    //handle comment change
    const handleObrCommentChange = (value) => {
        setObrCommentText(value);
    }
    const sendCommentAndStatusOfObr = async (obrStatus) => {
        let isSubmit = true;
        if (obrStatus !== AppConstants.expertObrStatus.statusApproved && (obrCommentText === undefined || obrCommentText === null || obrCommentText === AppConstants.emptyEditorString || obrCommentText.replace(/<(.|\n)*?>/g, '').trim().length === 0)) {
            setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
            setObrCommentSpinner(AppConstants.falseText);
            setObrRejectSpinner(AppConstants.falseText);
            setObrApproveSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(ErrorMessages.obrCommentEmpty);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
        }

        const editor = reactQuillRef.current.unprivilegedEditor;
        if (editor.getLength() > 501) {
            setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
            setObrCommentSpinner(AppConstants.falseText);
            setObrRejectSpinner(AppConstants.falseText);
            setObrApproveSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.falseText);
            setObrCommentMessage(ErrorMessages.obrCommentLength);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            isSubmit = AppConstants.falseText;
        }
        if (isSubmit) {
            const formValues = props.selectedObr;
            let data = { agencyObrUniqueId: formValues.agencyObrUniqueId, comment: obrCommentText, obrStatus: obrStatus, agencyType: formValues.agencyType };
            if (obrCommentText.replace(/<(.|\n)*?>/g, '').trim().length === AppConstants.zeroIndex) {
                data.comment = "N/A";
            };

            const url = AppConstants.updateAgencyObrStatusAPI;
            const response = await AuthorizedDataService.putRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setObrCommentSpinner(AppConstants.falseText);
                setObrRejectSpinner(AppConstants.falseText);
                setObrApproveSpinner(AppConstants.falseText);
                if (response !== undefined && response !== AppConstants.nullText) {
                    if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                        formValues.obrStatus = obrStatus;
                        setObrCommentHasError(AppConstants.trueText);
                        setObrCommentHasErrorVarient(AppConstants.alertVarient[AppConstants.zeroIndex]);
                        if (obrStatus === AppConstants.expertObrStatus.statusApproved) {
                            setObrCommentMessage(SuccessMessage.obrApproved);
                        }
                        else if (obrStatus === AppConstants.expertObrStatus.statusRejected) {
                            setObrCommentMessage(SuccessMessage.obrRejected);
                        }
                        else if (obrStatus === AppConstants.expertObrStatus.statusDraft) {
                            setObrCommentMessage(SuccessMessage.obrInDraft);
                        }
                        else {
                            setObrCommentMessage(SuccessMessage.obrSuccessStatusUpdated);
                        }

                        setObrRejectSpinner(AppConstants.falseText);
                        setObrApproveSpinner(AppConstants.falseText);
                        setObrCommentSpinner(AppConstants.falseText);
                        setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
                        setObrCommentText(AppConstants.nullText);
                        isSubmit = AppConstants.falseText;
                    } else {
                        setObrCommentHasError(AppConstants.trueText);
                        setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
                        setObrCommentMessage(ErrorMessages.obrSubmitError);
                        setObrRejectSpinner(AppConstants.falseText);
                        setObrApproveSpinner(AppConstants.falseText);
                        setObrCommentSpinner(AppConstants.falseText);
                        setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
                    }
                } else {
                    setObrCommentHasError(AppConstants.trueText);
                    setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
                    setObrCommentMessage(ErrorMessages.obrSubmitError);
                    setObrRejectSpinner(AppConstants.falseText);
                    setObrApproveSpinner(AppConstants.falseText);
                    setObrCommentSpinner(AppConstants.falseText);
                    setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);

                }
            } else {
                setObrCommentHasError(AppConstants.trueText);
                setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
                setObrCommentMessage(ErrorMessages.obrSubmitError);
                setObrRejectSpinner(AppConstants.falseText);
                setObrApproveSpinner(AppConstants.falseText);
                setObrCommentSpinner(AppConstants.falseText);
                setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            }
        }
    }

    const hanldeGalleryClose = () => {
        setShowCreatorGallery(AppConstants.falseText);
    }

    //Sumit Form from In Review to Draft
    const seekChanges = (e) => {
        setObrCommentHasError(AppConstants.falseText);
        setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
        e.preventDefault();
        setObrCommentSpinner(AppConstants.trueText);
        sendCommentAndStatusOfObr(AppConstants.expertObrStatus.statusDraft);
    }
    //Approve Obr
    const approveObr = (e) => {
        setObrCommentHasError(AppConstants.falseText);
        setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
        e.preventDefault();
        setObrApproveSpinner(AppConstants.trueText);
        sendCommentAndStatusOfObr(AppConstants.expertObrStatus.statusApproved);
    }
    //Reject Obr
    const rejectObr = (e) => {
        setObrCommentHasError(AppConstants.falseText);
        setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
        e.preventDefault();
        setObrRejectSpinner(AppConstants.trueText);
        sendCommentAndStatusOfObr(AppConstants.expertObrStatus.statusRejected);
    }
    //Deactivate Obr
    const deactivateObr = async (e, agencyObr) => {
        e.preventDefault();
        setObrDeactivateSpinner(AppConstants.trueText);
        const deactivateUrl = `${AppConstants.updateAgencyObrIsActiveFlagAPI}${agencyObr.agencyObrUniqueId}${AppConstants.updateExpertObrIsActiveFlagAPIparttwo}${AppConstants.expertObrValidityCode.deactivate}`;
        const response = await AuthorizedDataService.putRequest(
            deactivateUrl,
            AppConstants.emptyString,
            AppConstants.emptyString
        )
        if (response !== AppConstants.nullText || response !== undefined || response.status === AppConstants.httpResponseCodes.responseCode200 || response === 1) {
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(SuccessMessage.obrDeactivateSuccess);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[0]);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            setIsActive(!isActive);
            setObrDeactivateSpinner(AppConstants.falseText);
        }
        else if (response.status === AppConstants.httpResponseCodes.responseCode200 || response === -25) {

            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(SuccessMessage.obrDeactivateSuccess);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[0]);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            setObrDeactivateSpinner(AppConstants.falseText);
            setIsActive(!isActive);
        }
        else {
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(ErrorMessages.obrDeactivateError);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);
            setIsActive(!isActive);
        }

    }
    //Activate Obr
    const activateObr = async (e, agencyObr) => {
        e.preventDefault();
        setObrActivateSpinner(AppConstants.trueText);
        // TODO: Change API
        const activateUrl = `${AppConstants.updateAgencyObrIsActiveFlagAPI}${agencyObr.agencyObrUniqueId}${AppConstants.updateExpertObrIsActiveFlagAPIparttwo}${AppConstants.expertObrValidityCode.activate}`;
        const response = await AuthorizedDataService.putRequest(
            activateUrl,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if ((response.status === AppConstants.httpResponseCodes.responseCode200) || (response === 1)) {
            setObrActivateSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(SuccessMessage.obrActivatedSuccecss);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[AppConstants.zeroIndex]);

            setIsActive(!isActive);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);

        }
        else if ((response.status === AppConstants.httpResponseCodes.responseCode200) || (response === -25)) {
            setObrActivateSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(SuccessMessage.obrActivatedSuccecss);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[AppConstants.zeroIndex]);

            setIsActive(!isActive);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);

        }
        else if (response.status === AppConstants.httpResponseCodes.responseCode403 || response.status === AppConstants.httpResponseCodes.responseCode404 || response.status === AppConstants.httpResponseCodes.responseCode500) {
            setObrActivateSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(ErrorMessages.obrActivateError);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);

            setIsActive(!isActive);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);

        }
        else {
            setObrActivateSpinner(AppConstants.falseText);
            setObrCommentHasError(AppConstants.trueText);
            setObrCommentMessage(ErrorMessages.obrActivateError);
            setObrCommentHasErrorVarient(AppConstants.alertVarient[1]);

            setIsActive(!isActive);
            setTimeout(() => setObrCommentHasError(AppConstants.falseText), AppConstants.messageDisappearTime);

        }

    }

    return (

        <div className={classes.createObrFormContainer}>
            <TiArrowLeft onClick={toggleShowGrid} className={classes.obrBackButton} />
            <Row >
                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabelObrId}>{AppConstants.expertCreateObrForm.expertObrId.label}</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyObrId}</p>
                    </div>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabelObrId}>{AppConstants.expertCreateObrForm.obrStatus.label}</p>
                        <p className={`${expertStatusFontColorClass}`}>{props.selectedObr.obrStatus}</p>
                    </div>
                </Col>
                <hr className={classes.obrDetailsColumHrContainer} />
            </Row>
            <Row className={classes.expertObrRowLeftBorder}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.obrDetailsColumContainer}>
                        <h5 className={classes.obrSectionHeading}>Institute Head Details</h5>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc First Name</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadFirstName}</p>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc Last Name</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadLastName}</p>
                    </div>
                </Col>
                <Col className={classes.emailColumn} xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc Email</p>
                        <p className={classes.expertEmailText}>{props.selectedObr.agencyHeadEmail}</p>
                    </div>
                </Col>

                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc Phone Number</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadPhoneNumber}</p>
                    </div>
                </Col>

                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc Country</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadCountry}</p>
                    </div>
                </Col>

                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc State</p>
                        {(props.selectedObr.agencyHeadState !== null && props.selectedObr.agencyHeadState !== AppConstants.notAvailableText) && <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadState}</p>}
                        {(props.selectedObr.agencyHeadState === AppConstants.notAvailableText || props.selectedObr.agencyHeadState === null) && <p className={classes.expertDetailsText}>N/A</p>}
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Spoc City</p>
                        {(props.selectedObr.agencyHeadCity !== null && props.selectedObr.agencyHeadCity !== AppConstants.notAvailableText) && <p className={classes.expertDetailsText}>{props.selectedObr.agencyHeadCity}</p>}
                        {(props.selectedObr.agencyHeadCity === AppConstants.notAvailableText || props.selectedObr.agencyHeadCity === null) && <p className={classes.expertDetailsText}>N/A</p>}

                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>User Role</p>
                        <p className={classes.expertDetailsText}>{AppConstants.userRoleAgency}</p>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>Agency Type</p>
                        <p className={classes.expertDetailsText}>{props.selectedObr.agencyType}</p>
                    </div>
                </Col>
                {props.selectedObr.agencyType === AppConstants.agencyTypes.academy && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>{AppConstants.expertCreateObrForm.selectedSkills.label}</p>
                        {(props.selectedObr.selectedSkills !== AppConstants.nullText) &&
                            (<ul className={classes.skillsList}>
                                {props.selectedObr.selectedSkills.map((item, index) => (
                                    <li className={classes.expertDetailsListElement} key={index}>{item.skillName}{index !== props.selectedObr.selectedSkills.length - 1 && ", "}</li>
                                )
                                )}
                            </ul>)}
                    </div>
                </Col>}
            </Row>
            {props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusCreated && <Row className={classes.expertObrRowLeftBorder}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.obrDetailsColumContainer}>
                        <h5 className={classes.obrSectionHeading}>Skill Institute Information</h5>
                    </div>
                </Col>
                {props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusCreated &&
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Name</p>
                            <p className={classes.expertDetailsText}>{props.selectedObr.agencyName}</p>
                        </div>
                    </Col>
                }
            </Row>}
            {props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusCreated && <Row className={classes.expertObrRowLeftBorder}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.obrDetailsColumContainer}>
                        <h5 className={classes.obrSectionHeading}>OBR Link</h5>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>{AppConstants.expertCreateObrForm.expertObrLinkExpiryDate.label}</p>
                        {(props.selectedObr.agencyObrLinkExpiryDate !== AppConstants.nullText && props.selectedObr.agencyObrLinkExpiryDate !== AppConstants.notAvailableText) && <p className={classes.expertDetailsText}>{moment.utc(props.selectedObr.agencyObrLinkExpiryDate).tz(timeZone).format('D MMM, YYYY HH:mm')} {timeZone === AppConstants.utcTimezone && "(UTC)"}</p>}
                        {(props.selectedObr.agencyObrLinkExpiryDate === AppConstants.notAvailableText) && <p className={classes.expertDetailsText}>{props.selectedObr.agencyObrLinkExpiryDate}</p>}
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>{AppConstants.expertCreateObrForm.expertObrLinkExpirationStatus.label}</p>
                        {props.selectedObr.agencyObrLinkExpirationStatus === "Valid" && <p className={`${classes.expertDetailsText} ${classes.obrGreenText}`}>{props.selectedObr.agencyObrLinkExpirationStatus}</p>}
                        {props.selectedObr.agencyObrLinkExpirationStatus === "Expired" && <p className={`${classes.expertDetailsText} ${classes.obrRedText}`}>{props.selectedObr.agencyObrLinkExpirationStatus}</p>}
                    </div>
                </Col>
            </Row>}

            {props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusCreated &&
                (props.selectedObr.userImageAbsolutePath !== AppConstants.nullText ||
                    props.selectedObr.videoMessageAbsolutePath !== AppConstants.nullText ||
                    props.selectedObr.webBannerImageAbsolutePath !== AppConstants.nullText ||
                    props.selectedObr.tabletBannerImageAbsolutePath !== AppConstants.nullText ||
                    props.selectedObr.mobileBannerImageAbsolutePath !== AppConstants.nullText ||
                    props.selectedObr.yearsOfExperience !== AppConstants.nullText

                ) &&
                <Row className={classes.expertObrRowLeftBorder}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.obrDetailsColumContainer}>
                            <h5 className={classes.obrSectionHeading}>Microsite Information</h5>
                        </div>
                    </Col>
                    {props.selectedObr.userImageAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Profile Picture</p>
                            {(props.selectedObr.userImageAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.userImageAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.userProfileImage, props.selectedObr.userImageRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.videoMessageAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Video Message</p>
                            {(props.selectedObr.videoMessageAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.videoMessageAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.userVideoMessage, props.selectedObr.videoMessageRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.videoMessageThumbnailAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Video Message Thumbnail Image</p>
                            {(props.selectedObr.videoMessageThumbnailAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.videoMessageThumbnailAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.videoMessageThumbnail, props.selectedObr.videoMessageThumbnailRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.webBannerImageAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Web Banner Image</p>
                            {(props.selectedObr.webBannerImageAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.webBannerImageAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.userWebBannerImage, props.selectedObr.webBannerImageRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.tabletBannerImageAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Tablet Banner Image</p>
                            {(props.selectedObr.tabletBannerImageAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.tabletBannerImageAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.userTabletBannerImage, props.selectedObr.tabletBannerImageRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.mobileBannerImageAbsolutePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.obrDetailsColumContainer}>
                            <p className={classes.expertDetailsLabel}>Mobile Banner Image</p>
                            {(props.selectedObr.mobileBannerImageAbsolutePath !== AppConstants.nullText) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.mobileBannerImageAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.userMobileBannerImage, props.selectedObr.mobileBannerImageRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                        </div>
                    </Col>}
                    {props.selectedObr.yearsOfExperience !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Years Of Experience</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr.yearsOfExperience !== null ? props.selectedObr.yearsOfExperience : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.galleryMediaPaths !== null && props.creatorGalleryContent.length > 0 &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Agency Spotlight</p>
                                <p onClick={() => setShowCreatorGallery(AppConstants.trueText)} className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} >View</p>
                            </div>
                        </Col>}
                </Row>}

            {props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusCreated
                && (props.selectedObr.agencyName !== null
                    || props.selectedObr.agencyEmail !== null
                    || props.selectedObr.agencyContactNumber !== null
                    || props.selectedObr.agencyCountryName !== null
                    || props.selectedObr.agencyState !== null || props.selectedObr.agencyCity !== null
                    || props.selectedObr.agencyLinkedinLink !== null || props.selectedObr.agencyFacebookLink !== null || props.selectedObr.agencyInstagramLink !== null || props.selectedObr.agencyYoutubeLink !== null || props.selectedObr.agencyWebsiteLink !== null
                    || props.selectedObr.agencyAddress !== null || props.selectedObr.agencyGeoLocation !== null || props.selectedObr.agencyDescription !== null) &&
                <Row className={classes.expertObrRowLeftBorder}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.obrDetailsColumContainer}>
                            <h5 className={`${classes.obrSectionHeading} mt-4`}>Skill Institute Information</h5>
                        </div>
                    </Col>
                    {props.selectedObr.agencyName !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Name</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr.agencyName !== null ? props.selectedObr.agencyName : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyEmail !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Email</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr.agencyEmail !== null ? props.selectedObr?.agencyEmail : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyContactNumber !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Mobile Number</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyContactNumber !== null ? props.selectedObr?.agencyContactNumber : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyCountryName !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Country</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyCountryName !== null ? props.selectedObr?.agencyCountryName : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyState !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>State</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyState !== null ? props.selectedObr?.agencyState : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyCity !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>City</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyCity !== null ? props.selectedObr?.agencyCity : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyLinkedinLink !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Linkedin</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyLinkedinLink}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyFacebookLink !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Facebook</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyFacebookLink}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyInstagramLink !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Instagram</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyInstagramLink}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyWebsiteLink !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Website</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyWebsiteLink}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyYoutubeLink !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Youtube</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyYoutubeLink}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}

                    {props.selectedObr.agencyAddress !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Address</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyAddress !== null ? props.selectedObr?.agencyAddress : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyGeoLocation !== null &&
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Geo Location</p>
                                <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={props.selectedObr?.agencyGeoLocation}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                    {props.selectedObr.agencyDescription !== null &&
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className={classes.obrDetailsColumContainer}>
                                <p className={classes.expertDetailsLabel}>Description</p>
                                <p className={classes.expertDetailsText}>{props.selectedObr?.agencyDescription !== null ? props.selectedObr?.agencyDescription : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>}
                </Row>}

            {props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusCreated && props.selectedObr.agencyCentresDetails !== null && props.selectedObr.agencyCentresDetails?.length !== 0 &&
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row className={classes.expertObrRowLeftBorder}>
                        <h5 className={`${classes.obrSectionHeading} mt-4`}>Skill Institute Centre(s)</h5>
                    </Row>
                </Col>}
            {props.selectedObr?.agencyCentresDetails?.length !== 0 && <div>
                {props.selectedObr?.agencyCentresDetails?.map((center, index) => (
                    <div>
                        <Row className={classes.expertObrRowLeftBorder}>
                            {center.centreName !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Name</p>
                                        <p className={classes.expertDetailsText}>{center.centreName !== null ? center.centreName : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreSpocEmail !== null &&
                                <Col className={classes.emailColumn} xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Spoc Email</p>
                                        <p className={classes.expertEmailText}>{center.centreSpocEmail !== null ? center.centreSpocEmail : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreSpocPhoneNumber !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Spoc Phone Number</p>
                                        <p className={classes.expertDetailsText}>{center.centreSpocPhoneNumber !== null ? center.centreSpocPhoneNumber : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreCountryName !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Country</p>
                                        <p className={classes.expertDetailsText}>{center.centreCountryName !== null ? center.centreCountryName : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreState !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>State</p>
                                        <p className={classes.expertDetailsText}>{center.centreState !== null ? center.centreState : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreCity !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>City</p>
                                        <p className={classes.expertDetailsText}>{center.centreCity !== null ? center.centreCity : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreAddress !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Address</p>
                                        <p className={classes.expertDetailsText}>{center.centreAddress !== null ? center.centreAddress : AppConstants.notAvailableText}</p>
                                    </div>
                                </Col>}
                            {center.centreGeoLocation !== null &&
                                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <div className={classes.obrDetailsColumContainer}>
                                        <p className={classes.expertDetailsLabel}>Geo Location</p>
                                        <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={center.centreGeoLocation}>Click Here <MdOpenInNew /></a>
                                    </div>
                                </Col>}
                        </Row>
                        {index !== props.selectedObr.expertOrganizationDetails?.organizationCentreDetails.length - 1 && <hr className='mt-4 mb-1' />}
                    </div>
                ))}
            </div>}
            {props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusCreated && <Row className={classes.expertObrRowLeftBorder}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.obrDetailsColumContainer}>
                        <h5 className={`${classes.obrSectionHeading} mt-4`}>Documents</h5>
                    </div>
                </Col>
                {<Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>{AppConstants.expertCreateObrForm.addressProofPath.label}</p>
                        {(props.selectedObr.addressProofRelativePath !== AppConstants.nullText) && (props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusDraft) ? <BiDownload className={classes.expertObrDownloadIcon} onClick={e => downloadObrDocument(e, props.selectedObr.addressProofAbsolutePath, props.selectedObr, AppConstants.expertObrDocumentsName.addressProofDocument, props.selectedObr.addressProofRelativePath.split('.').pop())} /> : <p className={classes.expertDetailsText}>{AppConstants.notAvailableText}</p>}
                    </div>
                </Col>}
            </Row>}

            {props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusCreated && <Row className={classes.expertObrRowLeftBorder}>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div className={classes.obrDetailsColumContainer}>
                        <p className={classes.expertDetailsLabel}>{AppConstants.expertCreateObrForm.comment.label}</p>
                        {<p className={classes.expertDetailsText}>{props.selectedObr.comment !== null ? parse(props.selectedObr.comment) : AppConstants.notAvailableText}</p>}
                    </div>
                </Col>
            </Row>}

            {isActive && <Row className={classes.commentQuillEditor}>
                {(props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusInReview) && <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <ReactQuill
                        onKeyDown={checkCharacterCount}
                        readOnly={props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusDraft || props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusCreated}
                        className={classes.obrCommentBox} theme="snow"
                        placeholder="Enter Comments here"
                        ref={reactQuillRef}
                        maxLength={500}
                        onChange={handleObrCommentChange} />
                </Col>}
            </Row>}
            <Row className={classes.expertDetailsButtonRow}>
                {(isActive && props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusInReview) && <Col className='mb-2' xs={6} sm={6} md={3} lg={2} xl={2}>
                    <Button onClick={e => seekChanges(e)} className={classes.expertDetailsAmberButton}>{AppConstants.expertCreateObrButtons.askForChangeBtn.label}{obrCommentSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </Col>}
                {(isActive && props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusInReview) && <Col className='mb-2' xs={6} sm={6} md={3} lg={2} xl={2}>
                    <Button onClick={e => approveObr(e)} className={classes.expertDetailsGreenButton}>{AppConstants.expertCreateObrButtons.approveBtn.label} {obrApproveSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </Col>}
                {(isActive && props.selectedObr.obrStatus === AppConstants.expertObrStatus.statusInReview) && <Col className='mb-2' xs={6} sm={6} md={3} lg={2} xl={2}>
                    <Button onClick={e => rejectObr(e)} className={classes.expertDetailsRedButton}>{AppConstants.expertCreateObrButtons.rejectBtn.label} {obrRejectSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </Col>}
                {(isActive && props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusRejected && props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusAccepted) && <Col className='mb-2' xs={6} sm={6} md={3} lg={2} xl={2}>
                    <Button onClick={e => deactivateObr(e, props.selectedObr)} className={classes.expertDetailsRedButton}>{AppConstants.expertCreateObrButtons.deActivateBtn.label} {obrDeactivateSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </Col>}
                {(!isActive && props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusRejected && props.selectedObr.obrStatus !== AppConstants.expertObrStatus.statusAccepted) && <Col className='mb-2' xs={6} sm={6} md={3} lg={2} xl={2}>
                    <Button onClick={e => activateObr(e, props.selectedObr)} className={classes.expertDetailsGreenButton}>{AppConstants.expertCreateObrButtons.activateBtn.label} {obrActivateSpinner && <BiLoaderAlt className="spinner" />}</Button>
                </Col>}
            </Row>
            {showCreatorGallery && <ImageGalleryModal creatorGalleryContent={props.creatorGalleryContent} hanldeGalleryClose={hanldeGalleryClose} showDownloadIcon={AppConstants.trueText} />}
            {obrCommentHasError && <ErrorSuccessAlertMessage message={obrCommentMessage} varient={obrCommentHasErrorVarient} />}
        </div>
    )
}