import { useLoaderData, useNavigate } from "react-router-dom";
import { AppConstants } from "../../../constants/AppConstants";
import { LandingPageFooter } from "../../../layout/LandingPageLayout/LandingPageFooter";
import { useEffect, useState } from "react";
import { QuizAuthenticationForm } from "../../quizEventComponent/QuizAuthenticationForm";
import classes from "./MarkAttendanceScreen.module.css";
import Auth from "../../../utils/Auth";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { MarkCourseAttendanceCards } from "../markCourseAttendanceCards/MarkCourseAttendanceCards";
import { MdClear } from "react-icons/md";
import { LandingPageTopNavigation } from "../../../layout/LandingPageLayout/LandingPageTopNavigation";
import { BiLoaderAlt } from "react-icons/bi";
import { Logout } from "../../../common/Logout";

export const MarkAttendanceScreen = () => {
    const [landingPageData] = useLoaderData();
    const [showPopupForLogin, setShowPopupForLogin] = useState(AppConstants.falseText);
    const [todaysCourses, setTodaysCourses] = useState([]);
    const [availableCourses, setAvailableCourses] = useState([]);
    const [todaysCoursesSearchText, setTodaysCoursesSearchText] = useState(AppConstants.emptyString);
    const [availableCoursesSearchText, setAvailableCoursesSearchText] = useState(AppConstants.emptyString);
    const [filteredTodaysCourses, setFilteredTodaysCourses] = useState([]);
    const [filteredAvailableCourses, setFilteredAvailableCourses] = useState([]);
    const [logoutMessage, setLogoutMessage] = useState(AppConstants.falseText);
    const [pageLoaderSpinner, setPageLoaderSpinner] = useState(AppConstants.trueText);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPageData = async () => {
            const url = AppConstants.getAvailableCoursesForAttendanceAPI;
            const response = await AuthorizedDataService.getRequestWithResponseCode(
                url,
                AppConstants.emptyString, AppConstants.emptyString
            );

            if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                setPageLoaderSpinner(AppConstants.falseText);
                setLogoutMessage(AppConstants.trueText);
            } else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                setTodaysCourses([]);
                setAvailableCourses([]);
                setFilteredTodaysCourses([]);
                setFilteredAvailableCourses([]);
                setPageLoaderSpinner(AppConstants.falseText);
            } else {
                setTodaysCourses(response.todaysCourses);
                setAvailableCourses(response.remainingCourses);
                setFilteredTodaysCourses(response.todaysCourses);
                setFilteredAvailableCourses(response.remainingCourses);
                setPageLoaderSpinner(AppConstants.falseText);
            }
        };

        if (Auth.isLogin()) {
            if (Auth.getUserRole() !== AppConstants.userRoleExpert && Auth.getUserRole() !== AppConstants.userRoleAgency) {
                navigate(AppConstants.asterisk);
                setShowPopupForLogin(AppConstants.falseText);
            } else {
                fetchPageData();
            }
        } else {
            setShowPopupForLogin(AppConstants.trueText);
        }
    }, []);


    const closeAuthenticationForm = () => {
        window.location.reload();
    };

    const searchTodaysCourses = (e) => {
        setTodaysCoursesSearchText(e.target.value);
        let filteredCourses = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? todaysCourses.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : todaysCourses;
        setFilteredTodaysCourses(filteredCourses);
    };

    const searchAvailableCourses = (e) => {
        setAvailableCoursesSearchText(e.target.value);
        let filteredCourses = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? availableCourses.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : availableCourses;
        setFilteredAvailableCourses(filteredCourses);
    };

    const resetTodaysCourseFilter = () => {
        setTodaysCoursesSearchText(AppConstants.emptyString);
        setFilteredTodaysCourses(todaysCourses);
    };

    const resetAvailableCoursesFilter = () => {
        setAvailableCoursesSearchText(AppConstants.emptyString);
        setFilteredAvailableCourses(availableCourses);
    };

    return (
        <div>
            {Auth.isLogin() && <LandingPageTopNavigation landingPageData={landingPageData} />}
            {showPopupForLogin ?
                <div className={classes.loginSection}>
                    <QuizAuthenticationForm disableSignup={AppConstants.trueText} closeAuthenticationForm={closeAuthenticationForm} signupHeading="Signup" loginHeading="Login" />
                    {/* <LandingPageFooter landingPageData={landingPageData} /> */}
                </div>
                : (pageLoaderSpinner || logoutMessage) ? <div className={classes.loaderContainer}>
                    {pageLoaderSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}
                    {logoutMessage && <p className={classes.sessionExpiredText}>Your Session has been expired. Please <span onClick={Logout.logout}>login</span> to continue.</p>}
                </div> : <>
                    <div className={classes.pageContainer}>
                        <p className={`${classes.courseHeading} mb-2`}>Today's Courses</p>
                        <div className={classes.inputBoxContainer}>
                            <input type="text" className={classes.searchInputBox} value={todaysCoursesSearchText} onChange={(e) => { searchTodaysCourses(e) }} placeholder="Search courses" />
                            {todaysCoursesSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetTodaysCourseFilter() }} />}
                        </div>
                        {filteredTodaysCourses.length > 0 && <MarkCourseAttendanceCards courseData={filteredTodaysCourses} />}
                        {filteredTodaysCourses.length === 0 && <div>
                            <p className="noDataAvailableError">You have no courses scheduled for today</p>
                        </div>}

                        {(availableCourses.length > 0) && <p className={`${classes.courseHeading} mt-4 mb-2`}>Ongoing Courses</p>}
                        {(availableCourses.length > 0) && <div className={classes.inputBoxContainer}>
                            <input type="text" className={classes.searchInputBox} value={availableCoursesSearchText} onChange={(e) => { searchAvailableCourses(e) }} placeholder="Search courses" />
                            {availableCoursesSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetAvailableCoursesFilter() }} />}
                        </div>}
                        {filteredAvailableCourses.length > 0 && <MarkCourseAttendanceCards courseData={filteredAvailableCourses} />}
                        {(filteredAvailableCourses.length === 0 && availableCourses.length > 0) && <div>
                            <p className="noDataAvailableError">No courses to display</p>
                        </div>}
                    </div>
                    <LandingPageFooter landingPageData={landingPageData} />
                </>}
        </div>
    )
}