import { Button, Col, Modal, Row } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./LearningActivityDetailsModal.module.css";
import { MathUtil } from "../../utils/MathUtil";
import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import modalClass from "../cards/CompetitionsCards.module.css";
import moment from "moment";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";

export const OfferingsModal = ({ offeringDetails, hideDetailsSection, creatorName }) => {
    const [offerings, setOfferings] = useState([]);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [totalFeeCollected, setTotalFeeCollected] = useState(0);
    const offeringsGridRef = useRef();

    useEffect(() => {
        let totalFee = 0;
        const competitionDetails = offeringDetails.competitionDetails?.map(item => ({ ...item, learningActivityType: AppConstants.learningActivitiesTypes.competition })) || [];
        const courseDetails = offeringDetails.courseDetails?.map(item => ({ ...item, learningActivityType: AppConstants.learningActivitiesTypes.course })) || [];
        const workshopDetails = offeringDetails.workshopDetails?.map(item => ({ ...item, learningActivityType: AppConstants.learningActivitiesTypes.workshop })) || [];
        const offeringArray = [...competitionDetails, ...courseDetails, ...workshopDetails];
        offeringArray.forEach(learningActivity => totalFee = learningActivity.totalFeeCollected ? totalFee + learningActivity.totalFeeCollected : totalFee + 0)
        setOfferings([...offeringArray].sort((a, b) => new Date(a.utcStartTime) - new Date(b.utcStartTime)));
        setTotalFeeCollected(totalFee);
    }, [offeringDetails]);

    const getValidTextClass = ({ value }) => {
        if (value === AppConstants.learningActivitiesTypes.competition) {
            return `${classes.competitionTextColor}`;
        } else if (value === AppConstants.learningActivitiesTypes.course) {
            return `${classes.courseTextColor}`;
        }
        else if (value === AppConstants.learningActivitiesTypes.workshop) {
            return `${classes.workshopTextColor}`;
        }
    };

    const learningActivityColumnDefs = [
        {
            headerName: `Title`,
            field: "title",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.title}`,
            valueGetter: (params) => {
                return params.data.title;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Type`,
            field: "learningActivityType",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.learningActivityType}`,
            valueGetter: (params) => {
                return `${params.data.learningActivityType}`;
            },
            filter: 'agTextColumnFilter',
            cellClass: getValidTextClass
        },
        {
            headerName: `Start Date`,
            field: "utcStartTime",
            width: 300,
            tooltipValueGetter: ({ data }) => data.utcStartTime ? `${moment.utc(data.utcStartTime).tz(timeZone).format('D MMM YYYY, hh:mm A')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.utcStartTime ? `${moment.utc(data.utcStartTime).tz(timeZone).format('D MMM YYYY, hh:mm A')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter',
            cellClass: getValidTextClass
        },
        {
            headerName: `Cost`,
            field: "cost",
            width: 300,
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.cost),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.cost);
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: `Enrolments`,
            field: "enrolledStudents",
            maxWidth: 80,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.enrolledStudents),
            valueGetter: (params) => {
                return MathUtil.formatNumberToLocaleString(params.data.enrolledStudents);
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Fee Collected",
            field: "totalFeeCollected",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalFeeCollected),
            valueGetter: (params) => {
                return MathUtil.formatNumberToLocaleString(params.data.totalFeeCollected);
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: `Page Link`,
            field: "action",
            cellRendererFramework: ({ data }) => <div className={classes.obrGridActionBox}>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="View" className={`gridButton ${classes.gridIcon}`} onClick={() => { window.open(`${process.env.REACT_APP_FRONT_END_URL}${data.learningActivityType === AppConstants.learningActivitiesTypes.competition ? data.competitionLink : data.learningActivityType === AppConstants.learningActivitiesTypes.course ? data.courseLink : data.workshopLink}`, AppConstants.openInNewTab) }}>View</Button>
            </div>,
            maxWidth: 90,
        },
    ];

    const onBtnExport = useCallback(() => {
        const selectedColumns = ['title', 'learningActivityType', 'utcStartTime', 'cost', 'enrolledStudents', 'totalFeeCollected']; // Define the columns to export
        const csvData = offeringsGridRef.current?.api.getDataAsCsv({
            columnKeys: selectedColumns,
            columnSeparator: AppConstants.agGridColumnSeparator,
            suppressQuotes: AppConstants.trueText
        });

        FileOperationsUtil.downloadCsvDataAsExcel(csvData, `${creatorName}-Offerings.xlsx`);
    }, [creatorName]);

    return (
        <div>
            <Modal size="xl" show={AppConstants.trueText} onHide={hideDetailsSection} keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    <Row className="mb-2 mt-1">
                        <h3 className={classes.creatorNameHeading}>Offerings by - {creatorName}</h3>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    ref={offeringsGridRef}
                                    className="expertObrDetailsGrid"
                                    columnDefs={learningActivityColumnDefs}
                                    pagination={true}
                                    paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                    rowData={offerings || []}
                                    enableBrowserTooltips={true}
                                    domLayout='autoHeight'
                                    suppressRowHoverHighlight={true}
                                    defaultColDef={{
                                        filter: true,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        resizable: true,
                                        suppressMenu: true,
                                        suppressHorizontalScroll: true,
                                        floatingFilterComponentParams: { suppressFilterButton: true },
                                        floatingFilter: true,
                                    }}
                                    enableFilter={true}
                                    enableSorting={true}
                                    enableSearching={true}
                                    headerClass={classes.obrGridHeader}
                                    rowClass={classes.obrGridTable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-1 mt-4">
                        <h3 className={classes.revenueCollectedTest}>Total Revenue - INR {MathUtil.formatNumberToLocaleString(totalFeeCollected)}</h3>
                    </Row>
                    {offerings && offerings?.length > 0 && <Row className="mt-2">
                        <div>
                            <Button className={classes.exportButton} onClick={onBtnExport}>Download</Button>
                        </div>
                    </Row>}
                    <Row>
                        <div className={classes.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={hideDetailsSection}>Close</Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}