import React from "react";
import { useState } from "react";
import parse from 'html-react-parser';
import { AppConstants } from "../../../constants/AppConstants";
import classes from './TermsAndConditionsPageBannerSection.module.css';

export const TermsAndConditionsPageBannerSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const sectionBackgroundImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.qrenciaSkillsBackgroundImagePath}`;
    const [bgImage, setBgImage] = useState(sectionBackgroundImageUrl);

    return (
        <>
        <div className={classes.termsAndConditionsMainContainer} style={{ backgroundImage: `url(${bgImage})`}}>     
           <div className={classes.termsAndConditionsSubContainer}>
              <div className={classes.termsAndConditionsHeading}>
               <h1 className={classes.termsAndConditionsHeadingTitle}>{props.termsAndConditionsData[0].title}</h1></div>
              <div className={classes.termsAndConditionsDate}>{props.termsAndConditionsData[0].subTitle}</div>
              <div className={classes.termsAndConditionsDescription}>{parse(props.termsAndConditionsData[0].description)}</div>
           </div>
        </div>
        </>
    )
}