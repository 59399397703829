import React, { useState } from 'react';
import { FaCalendarAlt, FaChild, FaRegCalendarTimes } from 'react-icons/fa';
import { BsFillStopwatchFill } from 'react-icons/bs';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import classes from "../individualCompetition/RightCompetitionDetailsSection.module.css";
import { AppConstants } from '../../constants/AppConstants';
import { Button, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Auth from '../../utils/Auth';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { ApproveCompetitionModal } from '../modal/ApproveCompetitionModal';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from '../../constants/ErrorMessages';

export const ApproveCompetitionRightSection = (props) => {
    const [competitionDetailsObj, setCompetitionDetailsObj] = useState(props.competitionData);
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [competitionId, setCompetitionId] = useState(AppConstants.emptyString);
    const [competitionTitle, setCompetitionTitle] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [feeWaiverUpdatedOn, setFeeWaiverUpdatedOn] = useState(props.competitionData.competitionFeeWaiverApprovalFlagUpdatedOn);
    const [feeWaiverApprovalFlag, setFeeWaiverApprovalFlag] = useState(props.competitionData.competitionFeeWaiverApprovalFlag);

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    }

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    }

    const updateApproveStats = async (statusFlag) => {
        try {
            if (!Auth.isLogin()) {
                setModalTypeError(AppConstants.trueText);
                setMessage(ErrorMessages.loginToContinueText);
                setCompetitionTitle(competitionDetailsObj.competitionTitle);
                setCompetitionId(competitionDetailsObj.competitionStringId);
                handleModalShow();
            } else {
                const url = `${AppConstants.updateCompetitionFeeWaverApprovalFlagAPI}/${competitionDetailsObj.competitionSlug}/${statusFlag}`;
                const response = await AuthorizedDataService.putRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response && response.ok && response.status === 200) {
                    setModalTypeError(AppConstants.falseText);
                    if (statusFlag === AppConstants.yText) {
                        setMessage(SuccessMessage.adminApproveCompetitionSuccess);
                        setFeeWaiverApprovalFlag(AppConstants.yText)
                    } else {
                        setMessage(ErrorMessages.adminApproveCompetitionError);
                        setFeeWaiverApprovalFlag(AppConstants.nText);
                    }
                    setFeeWaiverUpdatedOn(new Date());
                    setCompetitionTitle(competitionDetailsObj.competitionTitle);
                    setCompetitionId(competitionDetailsObj.competitionStringId);
                    handleModalShow();
                }
                else if (response && response.status === 500) {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.tryAgainErrorText);
                    setCompetitionTitle(competitionDetailsObj.competitionTitle);
                    setCompetitionId(competitionDetailsObj.competitionStringId);
                    handleModalShow();
                }
                else if (response === undefined) {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.approveRejectRestriction);
                    setCompetitionTitle(competitionDetailsObj.competitionTitle);
                    setCompetitionId(competitionDetailsObj.competitionStringId);
                    handleModalShow();
                }
                else {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.tryAgainErrorText);
                    setCompetitionTitle(competitionDetailsObj.competitionTitle);
                    setCompetitionId(competitionDetailsObj.competitionStringId);
                    handleModalShow();
                }
            }
        } catch (error) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.tryAgainErrorText);
            setCompetitionTitle(competitionDetailsObj.competitionTitle);
            setCompetitionId(competitionDetailsObj.competitionStringId);
            handleModalShow();
        }
    }

    return (
        <>
            <div className={classes.rightContainer}>
                {feeWaiverUpdatedOn === AppConstants.nullText ?
                    <div className={classes.competitionApproveSection}>
                        <Button className={classes.competitionEnrollButton} onClick={() => updateApproveStats(AppConstants.yText)}>Approve</Button>
                        <Button className={classes.competitionEnrollButton} onClick={() => updateApproveStats(AppConstants.nText)}>Reject</Button>
                    </div> : feeWaiverApprovalFlag === AppConstants.nText ? <div className={classes.competitionApproveTextSection}>
                        <p className={classes.competitionEnrollText}>Fee waiver request have been rejected for this competition </p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />

                    </div> : <div className={classes.competitionApproveTextSection}>
                        <p className={classes.competitionEnrollText}>Fee waiver request have been approved for this competition</p>
                        <AiOutlineCheckCircle className='text-success fs-4' />
                    </div>}
                {/* {(competitionDetailsObj.competitionComplete === AppConstants.falseText && DateTimeUtil.utcToLocal(competitionDetailsObj.competitionUTCEndTime) <= new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>} */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime) <= new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                {(competitionDetailsObj.competitionComplete) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                <div className={classes.competationDetailsCard}>
                    <Row xs={1} sm={1} md={2} lg={1} xl={1}>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <BsFillStopwatchFill className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Registration Deadline</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaCalendarAlt className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Start Time</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaRegCalendarTimes className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>End Time</p>
                                    <p className={classes.detailsSubHeading}> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaChild className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Eligibility</p>
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>All age groups</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit} - {competitionDetailsObj.competitionMaxAgeLimit} Years</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit}{AppConstants.plusYearText}</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>{AppConstants.belowText} {competitionDetailsObj.competitionMaxAgeLimit}{AppConstants.yearsText}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {(message !== AppConstants.emptyString) && showModal && <ApproveCompetitionModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} learningActivityId={competitionId} learningActivityTitle={competitionTitle}></ApproveCompetitionModal>}
                </div>
            </div >
        </>
    );
}