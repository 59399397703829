import { useEffect, useState } from 'react';
import classes from "./AdminAIFeedbackPage.module.css";
import formClass from "../../common/FormStyling.module.css";
import { Button, Form } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorSuccessAlertMessage } from "../../component/errorSuccessMessage/ErrorSuccessAlertMessage";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from "../../constants/ErrorMessages";
import parse from 'html-react-parser';
import Table from 'react-bootstrap/Table';
import Auth from '../../utils/Auth';
import { BiLoaderAlt, BiHappyAlt, BiSad } from "react-icons/bi";
import { HiEmojiHappy, HiEmojiSad, HiOutlineEmojiSad, HiOutlineEmojiHappy } from "react-icons/hi";
import { FileUploadInput } from '../../component/fileUploadInput/FileUploadInput';

export const AdminAIFeedbackPage = () => {
    const [drawingFileInput, setDrawingFileInput] = useState(AppConstants.emptyString);
    const [uploadDrawingSpinner, setUploadDrawingSpinner] = useState(AppConstants.falseText);
    const [uploadDrawingButtonSpinner, setUploadDrawingButtonSpinner] = useState(AppConstants.falseText);
    const [fileError, setFileError] = useState(AppConstants.emptyString);
    const [showFeedback, setShowFeedback] = useState(AppConstants.falseText);
    const [feedback, setFeedback] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.qrenciaGlobeImage}`;
    const [texts, setTexts] = useState(AppConstants.feedbackPageSpinnerText);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const lastMessage = AppConstants.feedbackPageSpinnerLastText;
    const [lastMessageDisplayed, setLastMessageDisplayed] = useState(AppConstants.falseText);
    const [hideGenerateButton, setHideGenerateButton] = useState(AppConstants.falseText);
    const [drawingResults, setDrawingResults] = useState(AppConstants.emptyString);

    const removeFile = (id) => {
        setCurrentIndex(-1);
        setLastMessageDisplayed(AppConstants.falseText);
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.emptyString;
        input.type = "file";
        setDrawingFileInput(AppConstants.emptyString);
        setShowFeedback(AppConstants.falseText);
        setUploadDrawingSpinner(AppConstants.falseText);
        setUploadDrawingButtonSpinner(AppConstants.falseText);
        setHideGenerateButton(AppConstants.falseText);
    }

    const resetScreen = () => {
        removeFile("drawingInput");
        setShowFeedback(AppConstants.falseText);
        setUploadDrawingSpinner(AppConstants.falseText);
        setUploadDrawingButtonSpinner(AppConstants.falseText);
        setHideGenerateButton(AppConstants.falseText);
    }

    const uploadDrawing = async () => {
        const validFormats = ['image/jpeg', 'image/jpg'];
        if (drawingFileInput && drawingFileInput.size > AppConstants.size6MB) {
            setFileError(ErrorMessages.aiDrawingUploadFileSizeError);
            let timer = setTimeout(() => {
                setFileError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
        if (!validFormats.includes(drawingFileInput.type)) {
            setFileError(ErrorMessages.aiDrawingUploadFileFormatError);
            let timer = setTimeout(() => {
                setFileError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }

        const url = AppConstants.getDrawingFeedbackAPI;
        const formData = new FormData();
        setUploadDrawingButtonSpinner(AppConstants.trueText);
        setCurrentIndex(-1);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setUploadDrawingSpinner(AppConstants.trueText);
        formData.append('image', drawingFileInput);
        try {
            let response = await AuthorizedDataService.postMultipartRequest(url,
                formData,
                AppConstants.emptyString,
                AppConstants.emptyString);

            if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                const body = await response.json();
                setFeedback(body.assessmentText.replace(/\n/g, '<br />'));
                setDrawingResults(body.drawingResults);
                setShowFeedback(AppConstants.trueText);
                setUploadDrawingSpinner(AppConstants.falseText);
                setUploadDrawingButtonSpinner(AppConstants.falseText);
                setHideGenerateButton(AppConstants.trueText);
            }
            else if (response.status === AppConstants.httpResponseCodes.responseCode504) {
                setHideGenerateButton(AppConstants.trueText);
                uploadDrawing();
            }
            else {
                setHideGenerateButton(AppConstants.trueText);
                setUploadDrawingSpinner(AppConstants.falseText);
                setUploadDrawingButtonSpinner(AppConstants.falseText);
                setFileError(ErrorMessages.aiDrawingUploadParseError);
                let timer = setTimeout(() => {
                    setFileError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        }
        catch (error) {
            setUploadDrawingSpinner(AppConstants.falseText);
            setUploadDrawingButtonSpinner(AppConstants.falseText);
            setFileError(ErrorMessages.aiDrawingUploadParseError);
            let timer = setTimeout(() => {
                setFileError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentIndex < texts.length - 1) {
                setCurrentIndex(prevIndex => prevIndex + 1);
            } else {
                setLastMessageDisplayed(AppConstants.trueText);
                clearInterval(interval); // Stop the interval once last message is reached
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex, texts.length]);

    return (
        <>
            <div className="adminContentSection">
                <h3 className={classes.heading}>Artificial Intelligence Based Assessment Engine</h3>
                <div className={classes.feedbackUploadSection}>
                    <div className={classes.uploadFileSection}>
                        <div>
                            <FileUploadInput id="drawingInput" label="Upload Drawing" name="drawingInput" accept="image/jpg,image/jpeg" handleFileChange={event => { setDrawingFileInput(event.target.files[AppConstants.zeroIndex]); setCurrentIndex(-1); }} inputFile={drawingFileInput} />
                        </div>
                        <Button onClick={resetScreen} disabled={uploadDrawingButtonSpinner} className={classes.buttons}>Reset</Button>
                        {!hideGenerateButton && <Button onClick={uploadDrawing} disabled={uploadDrawingButtonSpinner || !drawingFileInput?.size} className={classes.buttons}>Generate Feedback {uploadDrawingButtonSpinner && <BiLoaderAlt className="spinner" />}</Button>}
                    </div>
                    {fileError !== AppConstants.emptyString && <ErrorSuccessAlertMessage message={fileError} varient={AppConstants.alertVarient[1]} />}
                </div>

                {!uploadDrawingButtonSpinner && showFeedback && <div className={classes.feedbackResponseSection}>
                    <p className={classes.feedbackResponseText}>Hi {Auth.getLoggedInName()},</p><br></br>
                    <p className={classes.feedbackResponseText}>{parse(feedback)}</p><br></br>
                    <p className={classes.feedbackResponseText}>Here are the marks for your drawing.</p>
                    <Table striped bordered className={classes.feedbackResponseSection}>
                        <thead>
                            <tr>
                                <th className={classes.tableHeader}>Criteria</th>
                                <th className={classes.tableHeader}>Aspect</th>
                                <th className={classes.tableHeaderMarks}>Analysis</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drawingResults.map((drawingResult, index) => (
                                drawingResult.criteria.aspects.map((aspect, aspectIndex) => (
                                    <tr key={`${index}-${aspectIndex}`} className={classes.tableRow}>
                                        {aspectIndex === 0 && (
                                            <td className={classes.tableRowCriteria} rowSpan={drawingResult.criteria.aspects.length}>{drawingResult.criteria.name}</td>
                                        )}
                                        <td>{aspect.name}</td>
                                        <td className={classes.tableRowMarks}>{aspect.marks === 0 ? <BiSad className={classes.sadEmoji} /> : <BiHappyAlt className={classes.happyEmoji} />}</td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </Table>
                </div>}
                {uploadDrawingSpinner &&
                    <div className={classes.preLoaderDiv}>
                        <div className={classes.loader}></div>
                        <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                        {!lastMessageDisplayed && currentIndex < texts.length && <p className={classes.loaderText}>{texts[currentIndex]}</p>}
                        {lastMessageDisplayed && <p className={classes.loaderText}>{lastMessage}</p>}
                    </div>}
            </div>
        </>
    )
}