import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import classes from "./SubmitFeedbackTextEditor.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { BiLoaderAlt } from "react-icons/bi";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const SubmitFeedbackTextEditor = ({ learnerDetails, label, placeholder, selectedResponse, learningActivityDetails, closeEditForm, refreshFeedbackHistory }) => {
    const [submitSpinner, setSubmitSpinner] = useState(AppConstants.falseText);
    const [responseText, setResponseText] = useState(AppConstants.emptyEditorString);
    const [alertVarient, setAlertVarient] = useState(AppConstants.emptyString);
    const [submitMessage, setSubmitMessage] = useState(AppConstants.emptyString);

    useEffect(() => {
        let timer = setTimeout(() => {
            setSubmitMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => { clearTimeout(timer) };
    }, [submitMessage]);

    useEffect(() => {
        if (selectedResponse?.response) {
            setResponseText(selectedResponse.response);
        }
    }, [selectedResponse]);

    const submitFeedback = async () => {
        setSubmitSpinner(AppConstants.trueText);
        let url = AppConstants.submitFeedbackAPI;
        let data = {
            learningActivityUniqueId: learningActivityDetails.learningActivityUniqueId,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: learningActivityDetails.learningActivityId,
            learningActivityName: learningActivityDetails.learningActivityTitle,
            feedbackRemarks: responseText,
            feedbackId: selectedResponse?.id || AppConstants.nullText,
            feedbackUniqueId: selectedResponse?.uniqueId || AppConstants.nullText,
            studentFeedbackInfo: learnerDetails.map(student =>
            ({
                deleteFlag: AppConstants.nText,
                studentEmail: student.studentEmail
            }))
        };

        const response = await AuthorizedDataService.postRequest(url, data, AppConstants.emptyString, AppConstants.emptyString);
        if (response) {
            if (!selectedResponse?.response) {
                setResponseText(AppConstants.emptyEditorString);
            }
            setSubmitSpinner(AppConstants.falseText);
            setAlertVarient(AppConstants.alertVarient[0]);
            setSubmitMessage(learnerDetails.length > 1 ? SuccessMessage.feedbackSharedSuccessfullyMessage : !selectedResponse?.response ? SuccessMessage.feedbackSharedSuccessfullyWithLearnerMessage : SuccessMessage.feedbackUpdatedSuccessfullyMessage);
            if (refreshFeedbackHistory) {
                refreshFeedbackHistory(selectedResponse?.response ? AppConstants.actionModes.edit : AppConstants.actionModes.create);
            }
        } else {
            setSubmitSpinner(AppConstants.falseText);
            setAlertVarient(AppConstants.alertVarient[1]);
            setSubmitMessage(ErrorMessages.feedbackSubmitErrorMessage);
        }
    };

    const isButtonDisabled = (value) => {
        const regex = /<(.|\n)*?>/g;
        if (value === undefined || value === null || value.replace(regex, '').trim().length === 0) {
            return true;
        }

        return false;
    };

    const cancelEditFeedback = () => {
        setSubmitMessage(AppConstants.emptyString);
        closeEditForm();
    };

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="studentRaiseNudgeForm mt-4 p-0">
                    {label && <Form.Label className="descriptionLabel">{label}</Form.Label>}
                    <ReactQuill
                        className="StudentRaiseNudgeQuillInputBox"
                        placeholder={placeholder}
                        value={responseText}
                        formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                        onChange={setResponseText} />
                </div>
            </Col>
            <div className={classes.buttonSection}>
                <Button type="submit" disabled={submitSpinner || isButtonDisabled(responseText)} onClick={submitFeedback} className={classes.submitButton}>{selectedResponse?.response ? "Save" : "Submit"} {submitSpinner && <BiLoaderAlt className="spinner" />}</Button>
                {selectedResponse?.response && !submitSpinner && <Button className={classes.submitButton} onClick={cancelEditFeedback}>Cancel</Button>}
            </div>
            {submitMessage && <ErrorSuccessAlertMessage message={submitMessage} varient={alertVarient} />}
        </Row>
    )
}
