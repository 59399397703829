import classes from "../../createCourseComponent/courseCertificateTemplate/CourseCertificateTemplate.module.css"

export const CertificateTemplate = ({ values, template = [], previewLogo, previewSignature, courseTitle, skillName, startDate, endDate }) => {
    return <div className={classes.certificate}>
        <img className={classes.certificateBackground} src={template[0]?.templateImagePath} />
        <div className={classes.certificateContent}>
            <div className={classes.logoSection}>
                {
                    previewLogo.map((logo, i) => {
                        if (logo) {
                            return (
                                <img key={i}
                                    className={classes.certificateLogo}
                                    src={logo} alt="Logo" />
                            );
                        }
                    })
                }
            </div>
            <h3 className={classes.certificateTitle}>{`<Certificate Title>`}</h3>
            <h6 className={classes.presentedToText}>Presented to</h6>
            <h3 className={classes.nameText}>{`<Participant Name>`}</h3>
            <div className={classes.bodyContainer}>
                <div className={classes.certificateBody}>{values.certificateDescription?.length ? values.certificateDescription : "<Certificate Body>"}</div>
            </div>
            <div className={classes.certificateMessage}>{values.certificateMessage?.length ? values.certificateMessage : "<Certificate Message>"}</div>
            <div className={classes.signatorySection}>
                {(values.signatoryOneName?.length > 0 || values.signatoryOneDesignation?.length > 0 || values.signatoryOneOrganization?.length > 0 || values.signatoryImageOne?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[0] && <img className={classes.certificateLogo} src={previewSignature[0]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryOneName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryOneDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryOneOrganization}</div>
                        </div>
                    </div>
                }
                {(values.signatoryTwoName?.length > 0 || values.signatoryTwoDesignation?.length > 0 || values.signatoryTwoOrganization?.length > 0 || values.signatoryImageTwo?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[1] && <img className={classes.certificateLogo} src={previewSignature[1]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryTwoName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryTwoDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryTwoOrganization}</div>
                        </div>
                    </div>}
                {(values.signatoryThreeName?.length > 0 || values.signatoryThreeDesignation?.length > 0 || values.signatoryThreeOrganization?.length > 0 || values.signatoryImageThree?.size > 0) &&
                    <div className={classes.signatoryCard}>
                        <div className={classes.signatoryImage}>
                            {previewSignature[2] && <img className={classes.certificateLogo} src={previewSignature[2]} />}
                        </div>
                        <div className={classes.signatoryDetails}>
                            <div className={classes.signatoryName}>{values.signatoryThreeName}</div>
                            <div className={classes.signatoryDesignation}>{values.signatoryThreeDesignation}</div>
                            <div className={classes.signatoryOrg}>{values.signatoryThreeOrganization}</div>
                        </div>
                    </div>}
            </div>
        </div>
    </div>
}