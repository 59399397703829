import { useState } from "react";
import parse from 'html-react-parser';
import { ImpactCreatedByQrenciaCards } from '../cards/ImpactCreatedByQrenciaCards';
import { AppConstants } from "../../constants/AppConstants";
import { Row, Col } from "react-bootstrap";
import classes from './ImpactCreatedByQrenciaSection.module.css';

export const ImpactCreatedByQrenciaSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const pinkBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.pinkBgOvalImagePath}`;
    const blueBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueBgOvalImagePath}`;
    const lightPinkBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.lightPinkBgOvalImagePath}`;
    const purpleBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.purpleBgOvalImagePath}`;
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.orangeTriangleImagePath}`;
    const sectionBackgroundImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.impactOfQrenciaSectionBgImagePath}`;
    const [impactCreatedByQrenciaDetails, setImpactCreatedByQrenciaDetails] = useState(props.landingPageData.impact);
    const [bgImage, setBgImage] = useState(sectionBackgroundImageUrl);

    return (
        <>
            {impactCreatedByQrenciaDetails.active === AppConstants.trueText &&
                <div className={classes.impactCreatedByQrenciaSection} style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
                    <img
                        className={classes.blueTriangleImage}
                        src={blueTriangleImageUrl}
                    />
                    <h1 className={classes.heading}>{parse(impactCreatedByQrenciaDetails.title)}</h1>
                    <p className={classes.subHeading}>{parse(impactCreatedByQrenciaDetails.subTitle)}</p>
                    <Row xs={2} sm={2} md={4} lg={4} xl={4}>
                        <Col className={classes.ovalImages}>
                            <img
                                className={classes.pinkBgOval}
                                src={pinkBgOvalImage}
                                alt="Oval cover"
                            />
                        </Col>
                        <Col className={classes.ovalImages}>
                            <img
                                className={classes.blueBgOval}
                                src={blueBgOvalImage}
                                alt="Oval cover"
                            />
                        </Col>
                        <Col className={classes.ovalImages}>
                            <img
                                className={classes.lightPinkBgOval}
                                src={lightPinkBgOvalImage}
                                alt="Oval cover"
                            />
                        </Col>
                        <Col className={classes.ovalImages}>
                            <img
                                className={classes.purpleBgOval}
                                src={purpleBgOvalImage}
                                alt="Oval cover"
                            />
                        </Col>

                    </Row>
                    <ImpactCreatedByQrenciaCards cardDetails={impactCreatedByQrenciaDetails.cards} />
                </div>}
        </>
    );
}