import { useState, useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import classes from './WeekDaysSelect.module.css';

export const WeekdaysSelect = (props) => {
    const { selectedValues, handleDaysSelection } = props;
    const weekDays = AppConstants.weekDaysOptions;
    const [selectedDays, setSelectedDays] = useState([]);
    const [activeDays, setActiveDays] = useState(new Array(7).fill(false));

    useEffect(() => {
        let activeArr = new Array(7).fill(false);
        let daysArr = [];

        weekDays?.forEach((day, i) => {
            if (selectedValues?.includes(day.name)) {
                activeArr[i] = true;
                daysArr.push(day.name)
            }
        });

        setActiveDays(activeArr);
        setSelectedDays(daysArr);
    }, [selectedValues]);


    const toggleCurrentDay = (index, flag) => {
        let arr = [];
        setActiveDays(activeDays.map((value, i) => i === index ? !value : value));
        weekDays.forEach((day, i) => {
            if (index === i && flag) {
                arr.push(day.name);
            } else if (index !== i && activeDays[i]) {
                arr.push(day.name);
            }
        });

        handleDaysSelection(arr);
        setSelectedDays(arr);
    }

    return (
        <div className={classes.container}>
            {
                weekDays.map((day, i) => <div onClick={() => toggleCurrentDay(i, !activeDays[i])}
                    className={`${classes.day} ${activeDays[i] && classes.activeDay}`} key={day.key}>
                    {day.key}
                </div>)
            }
        </div>
    )
}