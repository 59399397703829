import React, { useState, useEffect } from "react";
import { Button, Collapse, Toast, ToastContainer, Modal } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowRoundBack } from "react-icons/io"
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { FaArrowLeft } from 'react-icons/fa';
import { DateTime } from "luxon";
import { AppConstants } from "../../constants/AppConstants"
import classes from "../../pages/expert/ExpertCreateCompetition.module.css"
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { EditCourseDetails } from "./editCourseDetails/EditCourseDetails";
import { EditFrequentlyAskedQuestions } from "./editFrequentlyAskedQuestions/EditFrequentlyAskedQuestions";
import { EditCourseCertificate } from "./editCourseCertificate/EditCourseCertificate";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import Auth from "../../utils/Auth";
import modalClass from "../cards/CompetitionsCards.module.css";
import toastClass from "../createCourseComponent/createCourseToast.module.css"
import { EditCourseAttendanceDetails } from "./editCourseAttendanceDetails/EditCourseAttendanceDetails";
import { EditAdvancedCourseSettings } from "./editAdvancedCourseSettings/EditAdvancedCourseSettings";

export const EditCourseComponent = (props) => {
    const [selectedCourse, setSelectedCourse] = useState({});
    const stages = AppConstants.editCourseActions;
    const basicDetailsFormLabels = AppConstants.courseDetailsFormLabels;
    const certificateFromLabels = AppConstants.courseCertificateLabels;
    const [showSpinner, setShowSpinner] = useState(false);
    const [open, setOpen] = useState([true, false, false, false, false]);
    const [basicDetailsData, setBasicDetailsData] = useState({});
    const [basicDetailsErrors, setBasicDetailsErros] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [certificateData, setCertificateData] = useState({});
    const [toastBody, setToastBody] = useState(AppConstants.emptyString);
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [courseLink, setCourseLink] = useState(false);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [template, setTemplate] = useState({});
    const [skills, setSkills] = useState([]);
    const [status, setStatus] = useState(AppConstants.emptyString);
    const [showLoader, setShowLoader] = useState(true);
    const [showDetailsError, setShowDetailsError] = useState(false);
    const [toastBackground, setToastBackground] = useState(AppConstants.alertVarient[1]);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [attendanceData, setAttendanceData] = useState({});
    const [advancedSettingsData, setAdvancedSettingsData] = useState(AppConstants.emptyString);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const fetchCourseDetails = async () => {
            const url = `${AppConstants.getSpecificCourseDetailsAPI}${props.selectedCourse}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setSelectedCourse(response);
                setFaqData(response.courseFaqs)
                setShowLoader(false);
                setShowDetailsError(false);
            } else {
                setShowDetailsError(true);
                setSelectedCourse([]);
                setShowLoader(false);
            }
        };
        const getCertificateTemplate = async () => {
            const url = AppConstants.fetchCertificateTemplateApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setTemplate(response);
        };
        const fetchSkillsForExpert = async () => {
            const url = AppConstants.fetchExpertSkillsApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSkills(response);
            }
        };

        async function fetchSkillsForAgency() {
            const url = AppConstants.getSkillsofAgencyAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                // Sort the skills alphabetically based on skillName
                setSkills(response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                ));
            }
        };

        if (Auth.getLoggedInUserDetails().userRole === AppConstants.userRoleAgency) {
            fetchSkillsForAgency();
        } else {
            fetchSkillsForExpert();
        }
        fetchCourseDetails();
        getCertificateTemplate();
    }, []);

    useEffect(() => {
        const evalCourseStatus = () => {
            if (selectedCourse.courseType === AppConstants.flexibleCourseType) {
                setStatus(AppConstants.courseStatus.ongoing);
            } else {
                const startTime = `${selectedCourse.utcStartDate} ${selectedCourse.windowStartTime}`;
                const endTime = `${selectedCourse.utcEndDate} ${selectedCourse.windowEndTime}`;
                const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const endDateTime = DateTime.fromISO(endTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const currentDateTime = DateTime.local().setZone(timeZone);
                if (currentDateTime > endDateTime) {
                    setStatus(AppConstants.courseStatus.past);
                } else if (currentDateTime < startDateTime) {
                    setStatus(AppConstants.courseStatus.upcoming);
                } else {
                    setStatus(AppConstants.courseStatus.ongoing);
                }
            }
        };
        if (selectedCourse.uniqueId) {
            evalCourseStatus();
        }
    }, [selectedCourse]);

    useEffect(() => {
        let errorValues = Object.values(basicDetailsErrors);
        setFormErrors(errorValues)

    }, [basicDetailsErrors]);

    // function to capture basic details
    const changeBasicFormDetailsData = (newData) => {
        setBasicDetailsData(newData);
    };

    // function to capture basic details  errors
    const changeBasicDetailsFormError = (errors) => {
        setBasicDetailsErros(errors);
    };

    // function to capture frequently asked questions
    const changeFaqData = (newData) => {
        setFaqData(newData);
    };

    const changeCertificateData = (newData) => {
        setCertificateData(newData);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const saveCurrentStep = ({ step }) => {
        setOpen(prevState => prevState.map((state, i) => {
            if (i <= step) state = false;
            if (i === step + 1) state = true;
            return state;
        }));
        scrollToTop();
    };

    const closeModal = () => {
        setShowModal(false);
        if (courseLink.length > 0) {
            props.onHideEdit();
        }
    }

    const getDateObject = (date, time) => {
        if (!date) {
            return null;
        }
        const dateArr = date.split(AppConstants.hyphen);
        const timeArr = time.split(AppConstants.colon);
        const dateTime = DateTime.fromObject({
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2],
            hour: timeArr[0],
            minute: timeArr[1]
        }, { zone: timeZone });

        return dateTime;
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);
    }

    const formatRichTextContent = (content) => {
        return content.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, '');
    }

    const buildBasicDetailsProps = (filePaths) => {
        let registration_close_time;
        if (basicDetailsData.courseType === AppConstants.structuredCourseType) {
            if (basicDetailsData.registrationClosingDate !== AppConstants.emptyString && basicDetailsData.registrationClosingTime !== AppConstants.emptyString) {
                registration_close_time = formatDateTime(getDateObject(basicDetailsData.registrationClosingDate, basicDetailsData.registrationClosingTime));
            } else {
                // Setting default value of registration closing time to 1 minute before course end time
                let date = getDateObject(basicDetailsData.endDate, basicDetailsData.windowEndTime);
                registration_close_time = formatDateTime(date.minus({ minutes: 1 })); // Subtracting 1 minute from course end time.
            }
        }

        let courseDetails = {
            uniqueId: selectedCourse.uniqueId,
            courseTitle: basicDetailsData.title.trim(),
            courseDescription: formatRichTextContent(basicDetailsData.description),
            courseDescriptionDocument: filePaths.descriptionDocument ? filePaths.descriptionDocument : (basicDetailsData.uploadedDescriptionDocument ? selectedCourse.courseDescriptionDocumentRelativePath : null),
            numberOfClasses: basicDetailsData.numberOfClasses,
            startTime: basicDetailsData.courseType === AppConstants.structuredCourseType ? formatDateTime(getDateObject(basicDetailsData.startDate, basicDetailsData.windowStartTime)) : AppConstants.nullText,
            endTime: basicDetailsData.courseType === AppConstants.structuredCourseType ? formatDateTime(getDateObject(basicDetailsData.endDate, basicDetailsData.windowEndTime)) : AppConstants.nullText,
            windowStartTime: basicDetailsData.windowStartTime,
            windowEndTime: basicDetailsData.windowEndTime,
            courseRegistrationClosesOn: basicDetailsData.courseType === AppConstants.structuredCourseType ? registration_close_time : AppConstants.nullText,
            weekDays: basicDetailsData.courseWeekDays || null,
            duration: basicDetailsData.durationInHours,
            modeOfDelivery: basicDetailsData.modeOfDelivery,
            address: basicDetailsData.modeOfDelivery === AppConstants.competitionTypeOffline ? basicDetailsData.address.replace(/\n/g, ' ')?.trim() : null,
            sessionLink: basicDetailsData.modeOfDelivery === AppConstants.competitionTypeOnline ? basicDetailsData.sessionLink?.trim() : null,
            geoLocation: basicDetailsData.modeOfDelivery === AppConstants.competitionTypeOffline ? basicDetailsData.geoLocation?.trim() : AppConstants.nullText,
            spocName: basicDetailsData.expertName.trim() || null,
            spocEmail: basicDetailsData.expertEmail.trim() || null,
            spocMobileNumber: basicDetailsData.expertMobile || null,
            cost: basicDetailsData.cost,
            discount: basicDetailsData.discount >= 0 ? basicDetailsData.discount : null,
            skillId: skills.length === 1 ? skills[0].skillId : basicDetailsData.skill,
            courseThumbnailImagePath: filePaths.thumbnailImage ? filePaths.thumbnailImage : (basicDetailsData.uploadedThumbnailImage ? selectedCourse.courseThumbnailImageRelativePath : null),
            coursePromotionWebImagePath: filePaths.promotionImageForWeb ? filePaths.promotionImageForWeb : (basicDetailsData.uploadedPromotionImageWeb ? selectedCourse.coursePromotionWebImageRelativePath : null),
            coursePromotionMobileImagePath: filePaths.promotionImageForMobile ? filePaths.promotionImageForMobile : (basicDetailsData.uploadedPromotionImageMobile ? selectedCourse.coursePromotionMobileImageRelativePath : null),
            coursePromotionTabletImagePath: filePaths.promotionImageForTablet ? filePaths.promotionImageForTablet : (basicDetailsData.uploadedPromotionImageTablet ? selectedCourse.coursePromotionTabletImageRelativePath : null),
            coursePromotionVideoPath: filePaths.promotionVideo ? filePaths.promotionVideo : (basicDetailsData.uploadedPromotionVideo ? selectedCourse.coursePromotionVideoRelativePath : null),
            disableEnrolmentFlag: AppConstants.nText,
            trackingType: basicDetailsData.courseType === AppConstants.flexibleCourseType ? attendanceData.attendanceValues.trackingType : attendanceData.attendanceValues?.trackingType || AppConstants.trackingNotEnabledText,
            packageDuration: attendanceData.attendanceValues.packageDuration || null,
            isAttendanceEnabledFlag: basicDetailsData.courseType === AppConstants.structuredCourseType ? (attendanceData.attendanceValues.trackingType === AppConstants.structuredAttendanceTrackingOptions.classsBasedTracking.value ? AppConstants.yText : AppConstants.nText) : attendanceData.attendanceValues.isAttendanceEnabledFlag ? AppConstants.yText : AppConstants.nText,
            isAbsentCountedAsAClassFlag: basicDetailsData.courseType === AppConstants.structuredCourseType ? AppConstants.nText : (attendanceData.attendanceValues.trackingType === AppConstants.flexiAttendanceTrackingOptions.durationBasedTracking.value) ? AppConstants.nText : attendanceData.attendanceValues.isAbsentCountedAsAClassFlag ? AppConstants.yText : AppConstants.nText,
            courseType: basicDetailsData.courseType,
            payOfflineFlag: advancedSettingsData.enableOfflinePayment ? AppConstants.yText : AppConstants.nText,
            validity: basicDetailsData.courseType === AppConstants.structuredCourseType ? AppConstants.nullText : (attendanceData.attendanceValues.classValidity || AppConstants.nullText)
        }

        return courseDetails;
    };

    const buildCertificateProps = (filePaths) => {
        let courseCertificate = {
            certificateTitle: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.certificateMessage.trim() ? AppConstants.courseCertificateTilte : null) : AppConstants.nullText,
            certificateMessage: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.certificateMessage.trim() || null) : AppConstants.nullText,
            certificateLogoOnePath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.logoOne ? filePaths.logoOne : certificateData.uploadedLogoOne ? selectedCourse.certificateLogoOneRelativePath : null) : AppConstants.nullText,
            certificateLogoTwoPath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.logoTwo ? filePaths.logoTwo : certificateData.uploadedLogoTwo ? selectedCourse.certificateLogoTwoRelativePath : null) : AppConstants.nullText,
            certificateLogoThreePath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.logoThree ? filePaths.logoThree : certificateData.uploadedLogoThree ? selectedCourse.certificateLogoThreeRelativePath : null) : AppConstants.nullText,
            certificateLogoFourPath: null,
            certificateSignatoryOneName: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryOneName || null) : AppConstants.nullText,
            certificateSignatoryTwoName: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryTwoName || null) : AppConstants.nullText,
            certificateSignatoryThreeName: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryThreeName || null) : AppConstants.nullText,
            certificateSignatoryOneDesignation: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryOneDesignation || null) : AppConstants.nullText,
            certificateSignatoryTwoDesignation: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryTwoDesignation || null) : AppConstants.nullText,
            certificateSignatoryThreeDesignation: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryThreeDesignation || null) : AppConstants.nullText,
            certificateSignatoryOneImagePath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.signatoryImageOne ? filePaths.signatoryImageOne : certificateData.uploadedSignatoryImageOne ? selectedCourse.certificateSignatoryOneImageRelativePath : null) : AppConstants.nullText,
            certificateSignatoryTwoImagePath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.signatoryImageTwo ? filePaths.signatoryImageTwo : certificateData.uploadedSignatoryImageTwo ? selectedCourse.certificateSignatoryTwoImageRelativePath : null) : AppConstants.nullText,
            certificateSignatoryThreeImagePath: basicDetailsData.courseType === AppConstants.structuredCourseType ? (filePaths.signatoryImageThree ? filePaths.signatoryImageThree : certificateData.uploadedSignatoryImageThree ? selectedCourse.certificateSignatoryThreeImageRelativePath : null) : AppConstants.nullText,
            certificateSignatoryOneOrganization: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryOneOrganization || null) : AppConstants.nullText,
            certificateSignatoryTwoOrganization: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryTwoOrganization || null) : AppConstants.nullText,
            certificateSignatoryThreeOrganization: basicDetailsData.courseType === AppConstants.structuredCourseType ? (certificateData.signatoryThreeOrganization || null) : AppConstants.nullText,
            templateId: template[1]?.id
        };

        return courseCertificate;
    }

    const checkFormValidity = () => {
        if (formErrors?.length === 0 && basicDetailsData.title?.trim() !== AppConstants.emptyString && basicDetailsData.description?.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
            return true;
        } else {
            return false;
        }
    };

    const changeAdvancedSettings = (newData) => {
        setAdvancedSettingsData(newData);
    };

    const checkCertificateValidity = () => {
        if (selectedCourse.courseType === AppConstants.flexibleCourseType) {
            return AppConstants.trueText;
        }

        let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
        for (let i = 0; i < certificateErrorMessages.length; i++) {
            if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                return false;
            }
        }

        if (certificateData.logoUpdated && certificateData.messageUpdated) {
            if (certificateData.signatoryOneUpdated === AppConstants.incompleteText || certificateData.signatoryTwoUpdated === AppConstants.incompleteText || certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                return false;
            } else if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return false;
            } else {
                return true;
            }
        } else if (certificateData.logoUpdated || certificateData.messageUpdated) {
            return false;
        } else {
            if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return true;
            } else {
                return false;
            }
        }
    };

    const manageErrors = (errorMsg) => {
        setToastBody(errorMsg);
        setShowToast(true);
        setShowSpinner(false);
    };

    const uploadFiles = async () => {
        try {
            const timestamp = DateTimeUtil.fetchCurrentTimestamp();
            const userDetails = Auth.getLoggedInUserDetails();
            let filePaths = {
                thumbnailImage: null,
                promotionImageForMobile: null,
                promotionImageForTablet: null,
                promotionImageForWeb: null,
                promotionVideo: null,
                descriptionDocument: null,
                logoOne: null,
                logoTwo: null,
                logoThree: null,
                signatoryImageOne: null,
                signatoryImageTwo: null,
                signatoryImageThree: null
            };

            let params = [];
            if (basicDetailsData.thumbnailImage?.size) params.push({ file: basicDetailsData.thumbnailImage, previousPath: selectedCourse.courseThumbnailImageRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.thumbnailImage.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForWeb?.size) params.push({ file: basicDetailsData.promotionImageForWeb, previousPath: selectedCourse.coursePromotionWebImageRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.promotionImageForWeb.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForMobile?.size) params.push({ file: basicDetailsData.promotionImageForMobile, previousPath: selectedCourse.coursePromotionMobileImageRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.promotionImageForMobile.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForTablet?.size) params.push({ file: basicDetailsData.promotionImageForTablet, previousPath: selectedCourse.coursePromotionTabletImageRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.promotionImageForTablet.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionVideo?.size) params.push({ file: basicDetailsData.promotionVideo, previousPath: selectedCourse.coursePromotionVideoRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.promotionVideo.name, access: AppConstants.publicText });
            if (basicDetailsData.descriptionDocument?.size) params.push({ file: basicDetailsData.descriptionDocument, previousPath: selectedCourse.courseDescriptionDocumentRelativePath, folder: AppConstants.courseDetailsFolder, id: basicDetailsFormLabels.descriptionDocument.name, access: AppConstants.publicText });
            if (certificateData.logoOne?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.logoOne, previousPath: selectedCourse.certificateLogoOneRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoOne.name });
            if (certificateData.logoTwo?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.logoTwo, previousPath: selectedCourse.certificateLogoTwoRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoTwo.name });
            if (certificateData.logoThree?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.logoThree, previousPath: selectedCourse.certificateLogoThreeRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoThree.name });
            if (certificateData.signatoryImageOne?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.signatoryImageOne, previousPath: selectedCourse.certificateSignatoryOneImageRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageOne.name });
            if (certificateData.signatoryImageTwo?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.signatoryImageTwo, previousPath: selectedCourse.certificateSignatoryTwoImageRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageTwo.name });
            if (certificateData.signatoryImageThree?.size && selectedCourse.courseType === AppConstants.structuredCourseType) params.push({ file: certificateData.signatoryImageThree, previousPath: selectedCourse.certificateSignatoryThreeImageRelativePath, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageThree.name });

            for (let i = 0; i < params.length; i++) {
                let { file, previousPath, folder, id } = params[i];
                let path;
                let ext = file.name.split(".").pop();
                if (previousPath) {
                    let folderUrl = previousPath.substr(0, previousPath.lastIndexOf("\/") + 1);
                    path = `${folderUrl}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                } else {
                    path = `${AppConstants.coursesUploadPath}${AppConstants.forwardSlash}${userDetails.userId}${AppConstants.forwardSlash}${timestamp}${AppConstants.forwardSlash}${folder}${AppConstants.forwardSlash}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                }
                let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline, AppConstants.s3ContentTypeOctetStream);
                if (!(response instanceof Error)) {
                    filePaths[id] = response.objectKey;
                } else {
                    return null;
                }
            }

            return filePaths;
        } catch {
            return null;
        }
    };

    const changeAttendanceData = (newData) => {
        setAttendanceData({ ...newData });
    }

    const submitFormDetails = async () => {
        try {
            const formValid = checkFormValidity();
            const certificateValid = basicDetailsData.courseType === AppConstants.flexibleCourseType ? AppConstants.trueText : checkCertificateValidity();
            const attendanceErrors = Object.values(attendanceData.attendanceErrors);
            if (formValid && certificateValid && attendanceErrors?.length === 0) {
                setShowSpinner(true);

                const filePaths = await uploadFiles();
                if (filePaths === null) {
                    throw new Error(ErrorMessages.failedCourseUpdate);
                }

                const url = AppConstants.updateCourseDetailsAPI;
                let data = {
                    courseDetails: buildBasicDetailsProps(filePaths),
                    courseFaqs: faqData?.map(faq => {
                        return {
                            uniqueId: faq.uniqueId,
                            question: faq.question,
                            answer: faq.answer
                        }
                    }),
                    courseCertificateDetails: buildCertificateProps(filePaths),
                    locale: DateTimeUtil.getPreferredTimeZoneForUser()
                }

                const response = await AuthorizedDataService.putRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    if (response.ok && response.status === 200) {
                        const text = await response.text();
                        const link = `${process.env.REACT_APP_FRONT_END_URL}${text}`;
                        setCourseLink(link);
                        setShowSpinner(false);
                        setModalTypeError(false);
                        setShowModal(true);
                        setModalMessage(SuccessMessage.courseUpdateSuccessful);
                        setShowSpinner(false);
                    } else {
                        setOpen([false, false, false, false, false]);
                        scrollToTop();
                        setCourseLink(AppConstants.emptyString);
                        setShowModal(true);
                        setModalTypeError(true);
                        setModalMessage(ErrorMessages.failedCourseUpdate)
                        setShowSpinner(false);
                    }
                } else {
                    setOpen([false, false, false, false, false]);
                    scrollToTop();
                    setCourseLink(AppConstants.emptyString);
                    setShowModal(true);
                    setModalTypeError(true);
                    setModalMessage(ErrorMessages.failedCourseUpdate)
                    setShowSpinner(false);
                }
            } else {
                scrollToTop();
                if (!formValid) {
                    setOpen([true, false, false, false, false]);
                    if (formErrors.length > 0) {
                        manageErrors(formErrors[0]);
                    } else {
                        manageErrors(ErrorMessages.addCourseDetailsError);
                    }
                } else if (!certificateValid) {
                    setOpen([false, false, true, false, false]);
                    let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
                    for (let i = 0; i < certificateErrorMessages.length; i++) {
                        if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                            manageErrors(certificateErrorMessages[i]);
                            return null;
                        }
                    }
                    if (!certificateData.logoUpdated) {
                        manageErrors(ErrorMessages.atleastOneLogoError);
                    } else if (!certificateData.messageUpdated) {
                        manageErrors(ErrorMessages.certificateMessageError)
                    } else if (certificateData.signatoryOneUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryOneError);
                    } else if (certificateData.signatoryTwoUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryTwoError);
                    } else if (certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryThreeError);
                    } else {
                        manageErrors(ErrorMessages.addOneSignatoryDetails);
                    }
                } else if (attendanceErrors.length > 0) {
                    setOpen([false, false, false, true, false]);
                    manageErrors(attendanceErrors[0]);
                }
            }
        } catch (error) {
            scrollToTop();
            setCourseLink(AppConstants.emptyString);
            setOpen([false, false, false, false, false])
            setShowModal(true);
            setModalTypeError(true);
            setModalMessage(ErrorMessages.failedCourseUpdate)
            setShowSpinner(false);
        }
    };

    return (
        <div>
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div> : showDetailsError ? <div className={classes.errorContainer}>
                <FaArrowLeft onClick={() => props.onHideEdit()} className={classes.goBackButton} />
                <ErrorSuccessAlertMessage message={ErrorMessages.fetchCourseDetailsError} varient={AppConstants.alertVarient[1]} />
            </div> :
                <div className={classes.createCompContainer}>
                    <IoMdArrowRoundBack className={classes.backButton} onClick={props.onHideEdit} />
                    <div className={classes.infoComponents}>
                        {
                            stages.map((stage, idx) => {
                                return (!(idx === 2 && selectedCourse.courseType === AppConstants.flexibleCourseType) && <div key={idx} className={classes.collapseContainer}>
                                    <div className={classes.headerSection}>
                                        <div className={classes.heading}>
                                            {stage}
                                        </div>
                                        <div className={classes.collapseIcons}>
                                            {!open[idx] && <IoIosArrowDown onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                            {open[idx] && <IoIosArrowUp onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                        </div>
                                    </div>
                                    <Collapse in={open[idx]}>
                                        <div id="competition-collapse-container">
                                            <div className={classes.collapseDiv}>
                                                {idx === 0 && <EditCourseDetails skills={skills} saveCurrentStep={saveCurrentStep} stage={stage} changeBasicFormDetailsData={changeBasicFormDetailsData} changeBasicDetailsFormError={changeBasicDetailsFormError} courseData={selectedCourse} status={status} />}
                                                {idx === 1 && <EditFrequentlyAskedQuestions saveCurrentStep={saveCurrentStep} stage={stage} changeFaqData={changeFaqData} faqCollection={selectedCourse.courseFaqs} status={status} activityType={AppConstants.courseText} />}
                                                {idx === 2 && selectedCourse.courseType === AppConstants.structuredCourseType && <EditCourseCertificate saveCurrentStep={saveCurrentStep} stage={stage} template={template} changeCertificateData={changeCertificateData} certificateData={selectedCourse} status={status} activityTitle={basicDetailsData.title} startDate={basicDetailsData.startDate} endDate={basicDetailsData.endDate} skills={skills} skillId={basicDetailsData.skill} activityType={AppConstants.coursesText} />}
                                                {idx === 3 && <EditCourseAttendanceDetails courseType={selectedCourse.courseType} changeAttendanceData={changeAttendanceData} selectedCourseData={selectedCourse} stage={stage} saveCurrentStep={saveCurrentStep} />}
                                                {idx === 4 && <EditAdvancedCourseSettings cost={basicDetailsData.cost} discount={basicDetailsData.discount} changeAdvancedSettings={changeAdvancedSettings} selectedCourseData={selectedCourse} />}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>);
                            })
                        }
                        <div className={classes.createButton}>
                            {<Button disabled={showSpinner} onClick={submitFormDetails} type="submit" className={classes.createComptitionButton}>{showSpinner ? <BiLoaderAlt className="spinner" /> : "Submit"}</Button>}
                        </div>
                    </div>

                    <ToastContainer
                        className="p-3"
                        position='middle-center'>
                        <Toast show={showToast}
                            onClose={() => setShowToast(false)} bg={toastBackground}
                            animation delay={AppConstants.toastDelay} autohide={false}>
                            <Toast.Header className={toastClass.toastHeader}>
                                <strong className="me-auto">Error</strong>
                            </Toast.Header>
                            <Toast.Body className="text-white">
                                {toastBody} <br></br>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>

                    <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                        <Modal.Body className={modalClass.modalBody}>
                            {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                            {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                            <div className={modalClass.modalAlertText}>
                                {modalMessage}
                            </div>
                            <div className={modalClass.modalAlertText}>
                                {courseLink && <a href={courseLink} className={classes.shareLink} target="_blank" rel="noreferrer">Share course link</a>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className={modalClass.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>}
        </div>
    )
}