import React from 'react';
import './signup.css'
import { SignupAndLinkToSchoolPageCarousel } from '../component/signupPageComponents/SignupAndLinkToSchoolPageCarousel';
import { useLoaderData } from 'react-router-dom';
import { SignupAndLinkToSchool } from '../component/formControls/SignupAndLinkToSchool';

export const SignupAndLinkToSchoolPage = () => {
    const [signupPageSectionDetails] = useLoaderData();
    return <>
        <div className='row main-container-signup'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 carousel-container'>
                <SignupAndLinkToSchoolPageCarousel signupPageData={signupPageSectionDetails} />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 signup-form-container'>
                <SignupAndLinkToSchool />
            </div>
        </div>
    </>
}