import { Navigate } from 'react-router-dom';
import Auth from '../utils/Auth';
import { AppConstants } from '../constants/AppConstants';

const PrivateRoutes = (props) => {
    if (Auth.isLogin()) {
        if (Auth.getUserRole() === props.roles) {
            return props.children;
        } else {
            if (Auth.getUserRole() === AppConstants.userRoleExpert) {
                return <Navigate to={AppConstants.expertHomePath} />
            } else if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                return <Navigate to={AppConstants.studentHomePath} />
            } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
                return <Navigate to={AppConstants.adminNumberTrackingPath} />
            } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
                return <Navigate to={AppConstants.agencyHomePath} />
            } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
                return <Navigate to={AppConstants.salesNumberTrackingPath} />
            } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                return <Navigate to={AppConstants.marketingBulkSignupPath} />
            } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                return <Navigate to={AppConstants.partnerSuccessNumberTracking} />
            } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
                return <Navigate to={AppConstants.teamHomePath} />
            } else {
                return props.children
            }
        }

    }
    else {
        const loginPageUrl = AppConstants.loginPageUrl;
        return <Navigate to={loginPageUrl} />
    }
};

export default PrivateRoutes;