import React, { useState } from 'react';
import { AppConstants } from "../../constants/AppConstants";
import classes from './SearchSkillsBar.module.css';
import {Button} from 'react-bootstrap';
import {TbRefresh} from 'react-icons/tb';
export const SearchSkillsBar = (props) => {

  const [searchTerm, setSearchTerm] = useState(props.selectedSkillName);
  const [selectedAge, setSelectedAge] = useState(props.selectedAge);
  //const [selectedSkillName,setSelectedSkillName] =useState(props.selectedSkillName)

  //Value of Search Skill Name typeahed
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    props.onSearch(event.target.value);
  };

  //Value of Search Age Drop drown
  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
    props.onAgeChange(event.target.value);
  }

  //Reset the values of input and select
  const handleResetFields = () =>{
    props.onResetFilter();
  }

  return (
    <>
      <div className={classes.containerSearchSkill}>
        <div className={classes.containerSearchItem}><input
          type="text"
          value={props.selectedSkillName}
          onChange={handleSearchInputChange}
          placeholder={AppConstants.searchSkill}
        />
        </div>
        {/* <div className={classes.containerSearchItem}>
          <select value={props.selectedAge} onChange={handleAgeChange}>
            <option value={AppConstants.selectAge}>{AppConstants.selectAge}</option>
            {AppConstants.ageGroupArray.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
            <option value={AppConstants.eighteenPlusYears}>{AppConstants.eighteenPlusYears}</option>
          </select>
        </div> */}
        <Button onClick={handleResetFields} className={classes.resetButton}>Reset</Button>
      </div>

    </>


  )

}