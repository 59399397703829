import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import classes from './SkillCategoryListSection.module.css';
import { AppConstants } from "../../constants/AppConstants";

export const SkillCategoryListSection = (props) => {

    const { categorySlug } = useParams();
    const [activeLink, setActiveLink] = useState(categorySlug !== undefined ? categorySlug : AppConstants.AllText);

    //Group Skills Data by Category
    const data = new Map();
    for (let obj of props.categoryList) {
        data.set(obj.categoryUniqueId, obj);
    }
    const categoriesList = [...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId);

    //Handler to return selected Skills Value
    function selectCategory(e) {

        setActiveLink(e.target.getAttribute(AppConstants.categorySlug));
        props.onSelection(e.target.innerText);
    };
    return (
        <nav className={classes.containerSkillsCategory}>
            <ul className={classes.listSkillsCategory}>
                <Link to={`${props.linkToNavigate}`} className={classes.disableLink}>
                    <li key={AppConstants.AllText} onClick={selectCategory} className={activeLink === AppConstants.AllText ? classes.active : classes.inactive} categorySlug={AppConstants.AllText}>{AppConstants.AllText}</li>
                </Link>
                {categoriesList.map((category) => (
                    <Link to={`${props.linkToNavigate}${AppConstants.forwardSlash}${category.categorySlug}`} className={classes.disableLink}>
                        <li key={category.categoryUniqueId} onClick={selectCategory} className={activeLink === category.categorySlug ? classes.active : classes.inactive} categorySlug={category.categorySlug}>{category.category}</li>
                    </Link>
                ))}

            </ul>
        </nav>
    );
}