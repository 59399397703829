import React, { useState, useEffect, useRef } from "react";
import parse from 'html-react-parser'
import { Tooltip } from "react-tooltip";
import { Button } from "react-bootstrap";
import { RiInformationFill } from "react-icons/ri";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./FileUploadInput.module.css";

/**
 * props - 
 * id (string): (required) id attribute of the input element 
 * name (string): (required) name attribute of the input element 
 * label (string):(optional) the label of the input field 
 * accept (string):(optional) file formats which the input element can capture. (default="*")
 * disabled (boolean): to set the input element disabled (optional, default=false)
 * handleFileChange(): (required) This method takes event as an argument. The select event will get passed to the parent component. files can be extracted at parent component by using event.target.files[0]
 * multiple (boolean): attribute that specifies if a single file will be selected or multiple files will be selected (not requred, false by default)
 * infoMessage (string): (optional) to show the message on the info icon next to label
 * inputFile (File): (required) the file selected from handleFileChange method (at parent component)
 * defaultFile (File): (optional) is used to prepopulate the  name of the file on the input area (if any)
 * mandatory (boolean): (optional) is used to put a * mark next to the label, (default=false)
 * placeholder: (optional) Message to be displayed instead of "Select a file"
 */

export const FileUploadInput = (props) => {
    const [selectedFile, setSelectedFile] = useState(AppConstants.emptyString);
    const [maxFileNameLength, setMaxFileNameLength] = useState(AppConstants.emptyString);
    const inputRef = useRef(AppConstants.nullText);

    useEffect(() => {
        if (props.multiple && props.inputFile?.length > 0) {
            props.inputFile?.length === 1 ? setSelectedFile({ name: `${props.inputFile?.length} file selected` }) : setSelectedFile({ name: `${props.inputFile?.length} files selected` });
        } else {
            if (props.inputFile?.name) {
                setSelectedFile(props.inputFile);
            } else if (props.defaultFile?.name) {
                setSelectedFile(props.defaultFile);
            } else {
                setSelectedFile(AppConstants.emptyString);
            }
        }
    }, [props.inputFile, props.defaultFile]);

    useEffect(() => {
        const handleResize = () => {
            if (inputRef.current) {
                const width = inputRef.current.offsetWidth;
                if (width > 600) {
                    setMaxFileNameLength(-1);
                } else if (width > 500) {
                    setMaxFileNameLength(55);
                } else if (width > 450) {
                    setMaxFileNameLength(45);
                } else if (width > 350) {
                    setMaxFileNameLength(40);
                } else if (width > 280) {
                    setMaxFileNameLength(22);
                } else if (width > 240) {
                    setMaxFileNameLength(18)
                } else {
                    setMaxFileNameLength(10);
                }
            }
        };

        // Initial measurement on mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeSelectedFile = (event) => {
        props.handleFileChange(event);
    };

    const truncateFileName = (fileName, maxLength) => {
        if (fileName) {
            if (maxLength === -1 || maxLength > fileName.length) {
                return fileName;
            }
            return fileName.substring(AppConstants.zeroIndex, maxLength) + '...';
        }
    };

    return <div className={`${classes.fileUploadContainer} ${props.disabled && classes.fileUploadContainerDisabled}`}>
        {props.label && <label className={`${classes.fileUploadLabel} ${props.disabled && classes.fileUploadLabelDisabled}`}>{props.label}{props.mandatory && <span className="redAstrisk">{AppConstants.asterisk}</span>} {props.infoMessage && <RiInformationFill data-tooltip-id={props.id} className={classes.infoButton} />}</label>}
        <input
            ref={inputRef}
            id={props.id}
            name={props.name}
            disabled={props.disabled || false}
            accept={props.accept || AppConstants.asterisk}
            type={AppConstants.inputFieldTypes.file}
            multiple={props.multiple || false}
            className={classes.fileUploadInput}
            onChange={changeSelectedFile}
        />
        <div className={`${classes.fileNameContainer} ${props.disabled && classes.fileNameContainerDisabled}`}>
            <div className={classes.fileText}>{(selectedFile?.name) ? truncateFileName(selectedFile.name, maxFileNameLength) : props.placeholder ? props.placeholder : AppConstants.selectFileText}</div>
        </div>
        <Button className={`${classes.chooseButton} ${props.obrClasses ? classes.chooseButtonObr : AppConstants.emptyString}`}>Choose File</Button>
        {props.infoMessage && <Tooltip className={classes.tooltipMessage} id={props.id}><span className={classes.tooltipMessage}>{parse(props.infoMessage)}</span></Tooltip>}
    </div>
};