import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiCalendarCheck, BiCalendarX, BiLoaderAlt, BiWallet } from "react-icons/bi";
import { TbShare3 } from 'react-icons/tb';
import courseCards from '../viewCourseCards/ViewCourseCards.module.css';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle, AiOutlineQuestionCircle } from 'react-icons/ai';
import { AppConstants } from "../../../constants/AppConstants";
import classes from "../publishedUnpublishedCourseCards/PublishedUnpublishedCourseCards.module.css";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import '../CourseCards.css';
import Moment from "react-moment";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil"
import { CgPin } from "react-icons/cg";
import { MathUtil } from "../../../utils/MathUtil";

export const PublishedUnpublishedWorkshopCards = (props) => {
    const [workshopCards, setWorkshopCards] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [modalAlertText, setModalAlertText] = useState("");
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState("");
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [publishAlert, setPublishAlert] = useState(AppConstants.emptyString);
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [participantsImageList, setParticipantImageList] = useState([]);

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;

    useEffect(() => {
        const populateParticipantImageList = () => {
            let imageArray = [];
            for (let i = 0; i < props.workshopData.length; i++) {
                let partcipantsData = props.workshopData[i].participantDetails;
                let imageList = [];
                partcipantsData?.map(data => {
                    if (data.userImage) imageList.push(data.userImage)
                });
                imageArray.push(imageList);
            }
            setParticipantImageList(imageArray);
        }
        populateParticipantImageList();
    }, [props.workshopData]);

    useEffect(() => {
        setWorkshopCards(props.workshopData);
    }, [props.workshopData]);

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.workshopSlug}`);
    }

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    //show/hide modal
    const handleModalClose = () => {
        setShowModal(false);
        setModalSpinner(false);
        setModalSuccess(false);
        setModalError(false);
        setPublishAlert(AppConstants.emptyString);
    }
    const handleModalShow = () => {
        setShowModal(true);
        setModalSpinner(false);
        setModalSuccess(false);
        setModalError(false);
    }

    function setModalDataFunc(data) {
        setModalData(data);
        setModalSpinner(false);
        setModalSuccess(false);
        setModalError(false);
        if (props.workshopType === AppConstants.workshopPublishedStatus.unpublished) {
            setModalAlertText(`${AppConstants.publishWorkshopConfirmation}${AppConstants.space}${AppConstants.hyphen}${AppConstants.space}${data.workshopTitle}${" ?"}`);
        } else {
            setModalAlertText(`${AppConstants.unpublishWorkshopConfirmation}${AppConstants.space}${AppConstants.hyphen}${AppConstants.space}${data.workshopTitle}${" ?"}`);
        }
    }

    //update selected workshop status
    async function updateWorkshopStatus(workshopData) {
        setModalSpinner(true);
        let status = props.workshopType === AppConstants.workshopPublishedStatus.published ? AppConstants.workshopPublishedStatus.unpublished : AppConstants.workshopPublishedStatus.published;
        const url = `${AppConstants.updateWorkshopStatusAPI}${workshopData.uniqueId}&status=${encodeURIComponent(status)}&locale=${encodeURIComponent(timeZone)}`;
        const response = await AuthorizedDataService.put(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined && response.ok) {
            if (props.workshopType === AppConstants.workshopPublishedStatus.unpublished) {
                setModalAlertText(`${workshopData.workshopTitle}${AppConstants.space}${SuccessMessage.publishedSuccessfullyText}`);
            } else {
                setModalAlertText(`${workshopData.workshopTitle}${AppConstants.space}${SuccessMessage.unpublishedSuccessfullyText}`);
            }
            setModalSpinner(false);
            setModalSuccess(true);
            setModalError(false);
            props.refreshWorkshopsData();
        } else {
            if (props.workshopType === AppConstants.unpublishedWorkhopText) {
                setModalAlertText(ErrorMessages.failedToPublishWorkshopError);
            } else {
                setModalAlertText(ErrorMessages.failedToUnpublishWorkshopError);
            }
            setModalSpinner(false);
            setModalSuccess(false);
            setModalError(true);
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const navigateToGeolocation = (mode, location) => {
        if (mode?.toUpperCase() === "OFFLINE") {
            window.open(location, AppConstants.openInNewTab);
        }
    }

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {workshopCards !== undefined && workshopCards.slice(0, cardCount).map((workshop, index) => (
                    <Col key={workshop.uniqueId}>
                        <Card className="cardDiv" key={index} onClick={(e) => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${workshop.skillCategorySlug}${AppConstants.forwardSlash}${workshop.skillSlug}${AppConstants.forwardSlash}${workshop.workshopSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={workshop.thumbnailImageAbsolutePath ? workshop.thumbnailImageAbsolutePath : defaultWorkshopThumbnail} />
                            <div className={classes.uniqueIdText}>{workshop.workshopId}</div>
                            <TbShare3 className={courseCards.shareIcon} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(workshop) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div data-tooltip-id={`${workshop.uniqueId}-offline`} onClick={(e) => { stopPropagation(e); navigateToGeolocation(workshop.workshopModeOfDelivery.toUpperCase(), workshop.geoLocation) }} className={`cardSubTitle workshopDiffColor`}>{workshop.workshopModeOfDelivery.toUpperCase()}</div>
                                    <div className="cardsCostInfoText"><BiWallet /> {PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 })}</div>
                                </div>
                                {workshop.workshopModeOfDelivery.toUpperCase() === "OFFLINE" && <Tooltip className="tooltipMessage" id={`${workshop.uniqueId}-offline`}><span className="tooltipMessage">{workshop.address}</span></Tooltip>}
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${workshop.uniqueId}-title`}>{workshop.workshopTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${workshop.uniqueId}-title`}><span>{workshop.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainer}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="buttonSection">
                                    <div>
                                        <span onClick={stopPropagation} data-tooltip-id={`pastWorkshopEditInfo-${workshop.uniqueId}`}><Button type="button" disabled={workshop.workshopStatus === AppConstants.workshopStatus.past} onClick={(e) => { stopPropagation(e); props.onClickEdit(workshop) }} className="cardButton me-2">Edit</Button></span>
                                        {workshop.workshopStatus === AppConstants.workshopStatus.past && < Tooltip className="courseEditTooltip" id={`pastWorkshopEditInfo-${workshop.uniqueId}`}><span>{ErrorMessages.cannotEditPastWorkshopInfo}</span></Tooltip>}
                                        <span onClick={stopPropagation} data-tooltip-id={`pastWorkshopPublishInfo-${workshop.uniqueId}`}>{props.workshopType === AppConstants.workshopPublishedStatus.unpublished && <Button onClick={(e) => { stopPropagation(e); handleModalShow(); setModalDataFunc(workshop) }} type="button" disabled={workshop.workshopStatus === AppConstants.workshopStatus.past || workshop.workshopStatus === AppConstants.workshopStatus.ongoing} className="cardButton">Publish</Button>}</span>
                                        <span onClick={stopPropagation} data-tooltip-id={`pastWorkshopUnPublishInfo-${workshop.uniqueId}`}>{props.workshopType === AppConstants.workshopPublishedStatus.published && <Button disabled={workshop.enrolledStudents !== 0 || workshop.pendingCodRequestsFlag === AppConstants.yText} onClick={(e) => { stopPropagation(e); handleModalShow(); setModalDataFunc(workshop) }} type="button" className="cardButton">Unpublish</Button>}</span>
                                        {workshop.workshopStatus === AppConstants.workshopStatus.past && <Tooltip className="courseEditTooltip" id={`pastWorkshopPublishInfo-${workshop.uniqueId}`}><span>{ErrorMessages.cannotPublishPastWorkshopInfo}</span></Tooltip>}
                                        {workshop.workshopStatus === AppConstants.workshopStatus.ongoing && <Tooltip className="courseEditTooltip" id={`pastWorkshopPublishInfo-${workshop.uniqueId}`}><span>{ErrorMessages.cannotPublishOngoingWorkshopInfo}</span></Tooltip>}
                                        {(workshop.enrolledStudents !== 0 || workshop.pendingCodRequestsFlag === AppConstants.yText) && <Tooltip className="courseEditTooltip" id={`pastWorkshopUnPublishInfo-${workshop.uniqueId}`}><span>{workshop.enrolledStudents !== 0 ? ErrorMessages.cannotUnPublishWorkshopWithEnrolmentsInfo : ErrorMessages.pendingWorkshopEnrolmentRequestUnpublishError}</span></Tooltip>}
                                    </div>
                                </div>
                                <div className="cardUserProfileImageSection">
                                    <Tooltip className="tooltipMessage" id={workshop.uniqueId}><span className="tooltipMessage">{workshop.address}</span></Tooltip>
                                </div>
                                <div className="cardUserProfileImageSectionExpert mt-1">
                                    <div className="cardUserProfileImageContainerExpert">
                                        {(workshop?.length !== 0 && workshop.participantDetails !== null && workshop?.participantDetails?.length !== 0 && participantsImageList?.length !== 0 && participantsImageList[index]?.length !== 0) && <div className="cardProfileImageSection">
                                            {workshop.length !== 0 && workshop.participantDetails !== null
                                                && participantsImageList[index]?.slice(0, AppConstants.studentProfileImageLimit).map((imgageSrc, i) => (
                                                    <img src={imgageSrc} key={workshopCards[index]?.participantDetails?.userQrenciaId} className="cardProfileImage" alt="student profile" />
                                                ))}
                                        </div>}
                                        <div className="cardEnrolledCountSection"><div className="cardText">{MathUtil.formatNumberToLocaleString(workshop.enrolledStudents)} Enrolled</div></div>
                                    </div>
                                    <div onClick={stopPropagation}>
                                        {(workshop.workshopModeOfDelivery.toUpperCase() === AppConstants.physicalText || workshop.workshopModeOfDelivery.toUpperCase() === AppConstants.offlineText) && <span className="onlineIcon" data-tooltip-place="top" data-tooltip-id={workshop.uniqueId}><a href={workshop.geoLocation} target={AppConstants.openInNewTab} className="cardLink"><CgPin className="geoLocationIcon" /></a></span>}
                                    </div>
                                    <Tooltip className="tooltipMessage" id={workshop.uniqueId}><span className="tooltipMessage">{workshop.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row >
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={classes.modalBody}>
                    {!(modalSuccess || modalError) && <div className={classes.modalAlertIcon}><AiOutlineQuestionCircle /></div>}
                    {modalSuccess && <div className={classes.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {modalError && <div className={classes.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={classes.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={classes.modalFooter}>
                    {!(modalSuccess || modalError) && publishAlert.length === 0 && <Button className={classes.modalNoButton} onClick={handleModalClose}>No</Button>}
                    {!(modalSuccess || modalError) && publishAlert.length === 0 && <Button className={classes.modalYesButton} onClick={() => updateWorkshopStatus(modalData)}>Yes {modalSpinner && <BiLoaderAlt className="spinner" />}</Button>}
                    {(modalSuccess || modalError) && <Button className={classes.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className={classes.loadMoreDiv}>
                    {cardCount < workshopCards.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}