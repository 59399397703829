import React, { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { IndividualSkill } from "../../component/extendedPageComponents/skills/IndividualSkills";
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from "../../services/DataService";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { BiLoaderAlt } from 'react-icons/bi';
import Auth from "../../utils/Auth";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { IndividualSkillsReview } from "../../component/individualSkillsReviewComponent/IndividualSkillsReview";

export const IndividualSkillsPage = () => {
  const { categorySlug, skillSlug } = useParams();
  const [allActivitiesForSkill, setAllActivitiesForSkill] = useState(null);
  const [landingPageData, skillsData, bannerSectionData, footerData, keyHighlightsHeadingData, activitySectionStaticContent, activitiesList] = useLoaderData();

  useEffect(() => {
    const fetchActivityListData = async () => {
      const headers = {
        preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser()
      }
      const url = `${AppConstants.ISPgetAllActivitiesForSkillAPI}${skillSlug}`;
      let response;

      if (Auth.isLogin()) {
        response = await AuthorizedDataService.get(
          url,
          AppConstants.emptyString,
          AppConstants.emptyString,
          headers
        );
      } else {
        response = await DataService.get(
          url,
          AppConstants.emptyString,
          AppConstants.emptyString,
          headers
        );
      }

      if (response) {
        setAllActivitiesForSkill(response);
      } else {
        setAllActivitiesForSkill([]);
      }
    };
    fetchActivityListData();
  }, []);

  return (
    <>
      {allActivitiesForSkill != null && <IndividualSkill data={skillsData} landingPageData={landingPageData} keyHighlightsHeadingData={keyHighlightsHeadingData} activitySectionStaticContent={activitySectionStaticContent} allActivitiesForSkill={allActivitiesForSkill} />}
      {allActivitiesForSkill == null && <div className="errorContainer">
        <p className="loaderIcon"><BiLoaderAlt className="spinner" /></p>
      </div>}
      {allActivitiesForSkill != null && <IndividualSkillsReview data={skillsData} />}
      {allActivitiesForSkill != null && <LandingPageFooter landingPageData={landingPageData} />}
    </>
  );

}


