import { useState, useEffect, useRef } from 'react';
import validator from 'validator';
import ReactQuill from 'react-quill';
import { Button, Modal } from 'react-bootstrap';
import { BiLoaderAlt } from "react-icons/bi";
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { BsSendFill } from "react-icons/bs";
import { IoMdAttach } from "react-icons/io";
import Auth from '../../../utils/Auth';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import { FileOperationsUtil } from '../../../utils/FileOperationsUtil';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { AppConstants } from '../../../constants/AppConstants';
import { EmailProfileCards } from '../../cards/emailProfileCards/EmailProfileCards';
import { ErrorSuccessAlertMessage } from '../../errorSuccessMessage/ErrorSuccessAlertMessage';
import modalClass from "../../cards/CompetitionsCards.module.css";
import classes from "./ComposeMailForm.module.css";
import "../../expertBulkMessaging/SuggestedSearchComponent/SuggestedSearch.css";
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { SuccessMessage } from '../../../constants/SuccessMessage';

export const ComposeMailForm = (props) => {
    const { competitionDetails } = props
    const [subject, setSubject] = useState(AppConstants.emptyString);
    const [activeEmail, setActiveEmail] = useState(AppConstants.emptyString);
    const [recipientsList, setRecipientsList] = useState([]);
    const addRecipientRef = useRef(null);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [fileSizeError, setFileSizeError] = useState(AppConstants.emptyString);
    const [showFileSizeError, setShowFileSizeError] = useState(false);
    const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState(AppConstants.emptyString);
    const [emailDeliveredAlert, setEmailDeliveredAlert] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(false);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [sendMailSpinner, setSendMailSpinner] = useState(false);
    const [emailBody, setEmailBody] = useState(AppConstants.emptyEditorString);
    const [disableSendButton, setDisableEmailButton] = useState(true);
    const [attachmentSize, setAttachmentSize] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowFileSizeError(false);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [showFileSizeError]);

    useEffect(() => {
        setRecipientsList(props.participantsDetails.map(participant => {
            return {
                userName: participant.userName,
                userEmail: participant.userEmail,
                userImage: participant.userImagePath
            }
        }));

    }, [props.participantsDetails]);

    useEffect(() => {
        if (recipientsList.length === 0 || emailBody === AppConstants.emptyEditorString || subject.trim() === AppConstants.emptyString) {
            setDisableEmailButton(true);
        } else {
            setDisableEmailButton(false);
        }
    }, [recipientsList, emailBody, subject]);

    const onCloseModal = () => {
        setShowModal(false);
    }

    const removeRecipient = (userEmail) => {
        setRecipientsList(recipientsList => recipientsList.filter(recipient => recipient.userEmail !== userEmail));
    }

    // Function to add recipient's email address in recipientsList array
    const addRecipients = (event) => {
        if (event.key === AppConstants.enterKey || event.key === AppConstants.space) {
            // check if emial is valid
            if (validator.isEmail(activeEmail)) {
                let alreadyPresent = false;
                // add recipient to the list if not already present in list
                recipientsList.forEach(recipient => {
                    if (recipient.userEmail === activeEmail) alreadyPresent = true;
                });

                if (!alreadyPresent) {
                    let newRecipient = {
                        userName: AppConstants.emptyString,
                        userEmail: activeEmail
                    };
                    setRecipientsList([...recipientsList, newRecipient]);
                    setActiveEmail(AppConstants.emptyString)
                }
            }
            event.preventDefault();
        } else if (event.key === AppConstants.backspaceKey) {
            // check if activeEmail has some value otherwise remove last recipient
            if (activeEmail.length === 0 && recipientsList.length > 0) {
                removeRecipient(recipientsList[recipientsList.length - 1]?.userEmail);
                event.preventDefault();
            }
        }
    };

    const focusRecipientDiv = () => {
        const element = addRecipientRef?.current;
        element.classList?.add(classes.recipientFocus)
    };

    const removeRecipientDevFocus = () => {
        const element = addRecipientRef?.current;
        element.classList?.remove(classes.recipientFocus)
    };

    // Add files to attachments array
    const changeAttachments = (e) => {
        let files = e.target.files;
        let filesArray = [];
        if (files?.length) {

            for (let i = 0; i < files.length; i++) {
                if (files[i].size + attachmentSize < 25 * 1024 * 1024 && files[i].size > 0) {
                    setAttachmentSize(size => size + files[i].size);
                    filesArray.push({
                        file: files[i],
                        id: Math.floor((Math.random() * 100000) + 1).toString()
                    });
                } else {
                    if (!files[i].size) {
                        setFileSizeError(ErrorMessages.uploadFileMoreThanZeroBytes);
                    } else {
                        setFileSizeError(ErrorMessages.attachmentsLessThan25Mb);
                    }
                    setShowFileSizeError(true);
                    setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                }
            }
            setAttachedFiles([...attachedFiles, ...filesArray]);
        }
    }

    // Remove files from attachments array
    const removeAttachedFile = (fileId) => {
        let fileSize = 0;
        attachedFiles.forEach(file => {
            if (file.id === fileId) {
                fileSize = file.file.size;
            }
        })
        setAttachedFiles(attachedFiles => attachedFiles.filter(fileData => fileData.id !== fileId))
        setAttachmentSize(size => size - fileSize);
    }

    const uploadAttachments = async (file, path) => {
        let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPrivate, AppConstants.s3FileContentDispositionInline);
        if (!(response instanceof Error)) {
            return response.objectKey;
        }
    };

    const changeInputFileValue = () => {
        const input = document.getElementById("attachmentInput");
        input.value = "";
        input.type = "file";
    }

    const sendEmail = async () => {
        try {
            setSendMailSpinner(true);
            let attachments = [];

            const userId = Auth.getLoggedInUserDetails().userId;
            const path = `${AppConstants.competitionUploadFolder}${AppConstants.forwardSlash}${competitionDetails.competitionId}${AppConstants.forwardSlash}${AppConstants.attachmentsText}${AppConstants.forwardSlash}${DateTimeUtil.fetchCurrentTimestamp()}${AppConstants.hyphen}${userId}${AppConstants.forwardSlash}`

            // upload attached files
            for (let i = 0; i < attachedFiles.length; i++) {
                let fileName = attachedFiles[i].file.name.replace(/\s+/g, AppConstants.emptyString);
                let filePath = await uploadAttachments(attachedFiles[i].file, `${path}${attachedFiles[0].id}${AppConstants.hyphen}${attachedFiles[i].file.name}`);
                attachments.push(filePath);
            }

            let data = {
                recipientList: recipientsList.map(recipient => recipient.userEmail),
                subject,
                emailBody,
                attachments,
                uniqueCompetitionId: competitionDetails.uniqueId
            };

            const url = AppConstants.sendBulkEmailsAPI
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                setSendMailSpinner(false);
                if (response.ok) {
                    setEmailDeliveredAlert(SuccessMessage.emailDeliveredSuccessfully);
                    setModalTypeError(false);
                    setShowModal(true);
                    setAttachedFiles([]);
                    setAttachmentSize(0);
                    setSubject(AppConstants.emptyString);
                    setEmailBody(AppConstants.emptyEditorString);
                } else {
                    setEmailDeliveredAlert(ErrorMessages.cannotSendEmailError);
                    setModalTypeError(true);
                    setShowModal(true);
                }
            } else {
                setSendMailSpinner(false);
                setEmailDeliveredAlert(ErrorMessages.cannotSendEmailError);
                setModalTypeError(true);
                setShowModal(true);
            }
        } catch (error) {
            setSendMailSpinner(false);
            setEmailDeliveredAlert(ErrorMessages.cannotSendEmailError);
            setModalTypeError(true);
            setShowModal(true);
        }
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.emailTo}>
                <p className={`${classes.emailLabels} ${classes.toLabel}`}>To:</p>
                <div ref={addRecipientRef} className={classes.recipient}>
                    {
                        recipientsList?.map(recipient => <EmailProfileCards key={recipient.userEmail} userEmail={recipient.userEmail} userName={recipient.userName} userImage={recipient.userImage} removeRecipient={removeRecipient} />)
                    }
                    <input onFocus={focusRecipientDiv} onBlur={removeRecipientDevFocus} onKeyDown={addRecipients} type="text" value={activeEmail} onChange={(e) => setActiveEmail(e.target.value)} />
                </div>
            </div>
            <div className={classes.emailSubject}>
                <p className={classes.emailLabels}>Subject:</p>
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className={`${classes.emailBody} mailFormEditor`}>
                <ReactQuill className={classes.editor} placeholder='Email Body' value={emailBody} onChange={setEmailBody} />
            </div>
            {showFileSizeError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={fileSizeError} varient={errorSuccessAlertVarient} /></div>}
            {
                attachedFiles.length > 0 && <div className={classes.attachedFiles}>
                    {
                        attachedFiles.map(fileData => <div className={classes.filePreview} key={fileData.id}>
                            <div className={classes.filePreviewName}>{fileData.file.name}</div>
                            <div className={classes.filePreviewIcon}><RxCross2 onClick={() => removeAttachedFile(fileData.id)} /></div>
                        </div>)
                    }
                </div>
            }
            <div className={classes.iconSection}>
                <Button className={classes.attachButton}><IoMdAttach />
                    <input className={classes.attachButtonInput} id="attachmentInput" type="file" accept=".pdf,image/png,image/jpeg,image/jpg,.txt,.zip" multiple onChange={changeAttachments} onClick={changeInputFileValue} />
                    Attach
                </Button>
                <Button disabled={disableSendButton} className={disableSendButton && classes.disableSend} onClick={sendEmail}>
                    {sendMailSpinner ? <BiLoaderAlt className='spinner' /> : <BsSendFill />}Send</Button>
            </div>

            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        <p><b>{competitionDetails.competitionId} | {competitionDetails.title}</b></p>
                        {emailDeliveredAlert}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}