
import { useRef, useCallback, useState } from "react";

const useInfiniteScroll = (callback, dependencies) => {
    const [loading, setLoading] = useState(false);
    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading) {
                setLoading(true);
                setTimeout(() => {
                    callback();
                    setLoading(false);
                }, 1000); // Simulating network delay
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, ...dependencies]);

    return { lastElementRef, loading };
};

export default useInfiniteScroll;