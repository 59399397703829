import { useState, useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import moment from 'moment';
import 'moment-timezone';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import "../expertManageCompetitionComponent/dataGrid.css"
import classes from "./ViewSchoolLearningActivityDetails.module.css";
import { Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { AiOutlineUser } from 'react-icons/ai';
import { BsAward } from 'react-icons/bs'
import { FaRegGem, FaRegPaperPlane } from 'react-icons/fa';
import { BiCalendarCheck, BiCalendarX } from "react-icons/bi";
import "../expertManageCompetitionComponent/viewCompetitionDetailsAccordion.css"
import { LearningActivityDetailsModal } from '../modal/LearningActivityDetailsModal';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { DateTimeUtil } from '../../utils/DateTimeUtil';

export const ViewSchoolLearningActivitiyDetails = () => {
    const [learningActivitiesData, setLearningActivitiesData] = useState([]);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [selectedLearningActivity, setSelectedLearningActivity] = useState({});
    const [cardCount, setCardCount] = useState(6);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchLearningActivityData = async () => {
            const url = `${AppConstants.agencyMonitoringDashboardLearningActivityAPI}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setLearningActivitiesData(response);
            } else {
                setLearningActivitiesData([]);
            }
        }

        fetchLearningActivityData();
    }, []);

    function getCssClassForEventType(param) {
        const defaultClassName = `${classes.eventTypeField}`;
        let additionalClassName = AppConstants.emptyString;
        if (param.learningActivityType.toLowerCase() === AppConstants.competitionText) {
            additionalClassName = `${classes.competitionTextColor}`;
        } else if (param.learningActivityType.toLowerCase() === AppConstants.courseText) {
            additionalClassName = `${classes.courseTextColor}`;
        }

        return (defaultClassName + AppConstants.space + additionalClassName)
    };

    const handleDetailsComponent = async (data) => {
        const url = `${AppConstants.fetchSchoolLearningActivityStatsAPI}${data.learningActivityUniqueId}&learningActivityType=Competition`;

        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setSelectedLearningActivity({ ...data, ...response });
            setShowModal(AppConstants.trueText);
        } else {
            setShowModal(AppConstants.falseText);
        }
    };

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
    };


    const addColorTextClass = ({ data }) => {
        if (data.learningActivityType.toLowerCase() === AppConstants.competitionText) {
            return `${classes.competitionTextColor}`;
        } else {
            return `${classes.courseTextColor}`;
        }
    };

    const columnDefs = [
        {
            headerName: "S. No",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 70
        },
        {
            headerName: "Learning Activity",
            field: "learningActivityName",
            width: 300,
            filter: 'agTextColumnFilter',
            tooltipField: "learningActivityName",
            cellClass: (params) => addColorTextClass(params)
        },
        {
            headerName: "Conducted By",
            field: "expertName",
            width: 300,
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => {
                if (data.expertSkills)
                    return `${data.expertName} (${data.expertSkills})`;
                else
                    return `${data.expertName}`;
            },
            valueGetter: ({ data }) => {
                if (data.expertSkills)
                    return `${data.expertName} (${data.expertSkills})`;
                else
                    return `${data.expertName}`;
            }
        },
        {
            headerName: "Start Date",
            field: "learningActivityStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.learningActivityStartDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`,
            width: 200,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.learningActivityStartDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`;
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "End Date",
            field: "learningActivityEndDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.learningActivityEndDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`,
            width: 200,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.learningActivityEndDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)}  ${timeZone === AppConstants.utcTimezone ? `(${data.preferredTimezone})` : AppConstants.emptyString}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Details",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <Button className="gridButton" onClick={() => { handleDetailsComponent(params.data) }}>{AppConstants.expertCreateObrButtons.showDetailsBtn.label}</Button>
            </div>,
            maxWidth: 100,
        },
    ];

    return (
        <>
            <div className={classes.showGrid}>
                <Row>
                    < Col xs={12} sm={12} md={12} lg={12} xl={12} className={classes.tableRow}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                className="expertObrDetailsGrid"
                                columnDefs={columnDefs}
                                pagination={true}
                                paginationPageSize={5}
                                rowData={learningActivitiesData}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.showCards}>
                <Row xs={1} sm={1} md={1} lg={1} xl={1}>
                    {learningActivitiesData.length === AppConstants.zeroIndex ? (<div>
                        <p className={classes.noDataError}>{ErrorMessages.noLearningActivitiesMessage}</p></div>) : (learningActivitiesData.slice(0, cardCount).map(item => (
                            <Col className={classes.learningActivityCardsColumn} key={item.expertObrUniqueId}>
                                <Card key={item.id} className={classes.learningActivityCards}>
                                    <Card.Body>
                                        <div className={classes.obrCardsContainer}>
                                            <Badge className={`${getCssClassForEventType(item)} mb-1`}>{item.learningActivityType}</Badge>
                                        </div>
                                        <div className={classes.cardsContainer}>
                                            <Card.Text>{item.learningActivityType.toLowerCase() === AppConstants.competitionText ? <FaRegGem /> : <FaRegPaperPlane />}</Card.Text>
                                            <Card.Text>{item.learningActivityName}</Card.Text>
                                        </div>
                                        <div className={classes.cardsContainer}>
                                            <Card.Text><AiOutlineUser /></Card.Text>
                                            <Card.Text>{item.expertName}</Card.Text>
                                        </div>
                                        {item.expertSkills && <div className={classes.cardsContainer}>
                                            <Card.Text><BsAward /></Card.Text>
                                            <Card.Text>{item.expertSkills}</Card.Text>
                                        </div>}
                                        <div className={classes.cardsContainer}>
                                            <Card.Text><BiCalendarCheck /></Card.Text>
                                            <Card.Text>{moment.utc(item.learningActivityStartDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)} {timeZone === AppConstants.utcTimezone && `(${item.preferredTimezone})`}</Card.Text>
                                        </div>
                                        <div className={classes.cardsContainer}>
                                            <Card.Text><BiCalendarX /></Card.Text>
                                            <Card.Text>{moment.utc(item.learningActivityEndDate).tz(timeZone).format(AppConstants.learningActivityGridDateTimeFormat)} {timeZone === AppConstants.utcTimezone && `(${item.preferredTimezone})`}</Card.Text>
                                        </div>
                                        <div className={classes.obrCardsButtonContainer}>
                                            <Button className="gridButton mt-1" onClick={() => { handleDetailsComponent(item) }}>View</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>)))
                    }
                </Row>

                {learningActivitiesData.length !== AppConstants.zeroIndex && cardCount < learningActivitiesData.length && <Row className='mt-2 mb-4'>
                    <Col className={classes.loadMoreContainer}>
                        <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + 6)}>{AppConstants.expertCreateObrButtons.loadMoreBtn.label}</Button>
                    </Col>
                </Row>}
            </div>
            <LearningActivityDetailsModal showModal={showModal} closeModal={closeModal} selectedLearningActivity={selectedLearningActivity} />
        </>

    )
}