import classes from './ExpressionOfInterestThankyouPageDetailsSection.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import parse from 'html-react-parser';
export const ExpressionOfInterestThankyouPageDetailsSection = (props) => {
    return(
        <>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className={classes.section}>
                {AppConstants.expressionOfInterestSalutation}<strong>{props.expertName}</strong>{AppConstants.comma}
                {parse(AppConstants.expressionOfInterestThankyouMessage)}
            </div>
        </div>
        </>
    )
}