import { useState, useEffect } from 'react';
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import classes from "./StudentHomeRecentlyPublishedLearningActivities.module.css";
import { StudentHomeTodaysActivitiesCards } from '../cards/StudentHomeTodaysActivitiesCards';

export const StudentHomeTodaysActivities = (props) => {
    const [todaysActivity, setTodaysActivity] = useState([]);

    useEffect(() => {
        async function fetchMyActivities() {
            const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
            const selectedDateStartTime = DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone);
            const userSelectedDateStartTime = encodeURIComponent(selectedDateStartTime);
            const selectedEndTime = new Date();
            selectedEndTime.setHours(23);
            selectedEndTime.setMinutes(59);
            selectedEndTime.setSeconds(59);
            const selectedDateEndTime = DateTimeUtil.getConvertedJsDateInPreferredTimeZone(selectedEndTime, timeZone);

            const userSelectedDateEndTime = encodeURIComponent(selectedDateEndTime);
            const userFetchedTimeZone = encodeURIComponent(timeZone);

            const url = `${AppConstants.getTodaysActivityForStudentAPI}?${AppConstants.selectedDateStartTimeParam}${userSelectedDateStartTime}&${AppConstants.selectedDateEndTimeParam}${userSelectedDateEndTime}${AppConstants.localeParam}${userFetchedTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                const learningActivityMapping = {
                    competitionDetails: AppConstants.learningActivitiesTypes.competition,
                    courseDetails: AppConstants.learningActivitiesTypes.course,
                    workshopDetails: AppConstants.learningActivitiesTypes.workshop,
                };

                const combinedArray = [];

                for (const [key, value] of Object.entries(response)) {
                    if (value && value?.length > 0) {
                        const learningActivityType = learningActivityMapping[key];

                        value.forEach((item) => {
                            combinedArray.push({ ...item, learningActivityType });
                        });
                    }
                }

                setTodaysActivity(combinedArray)
            } else {
                setTodaysActivity([]);
            }
        }
        fetchMyActivities();
    }, []);

    return (
        <>
            <div className={classes.header}>
                <h1 className={classes.sectionHeading}>{AppConstants.studenyMyActivitiesText}</h1>
            </div>
            {todaysActivity?.length > 0 && <StudentHomeTodaysActivitiesCards category="TodaysActivity" minimumReviewCount={props.minimumReviewCount} todaysActivity={todaysActivity} />}
            {todaysActivity?.length === 0 && <p className='noDataAvailableError mt-0'>{AppConstants.noTodaysActivitiesAvailable}</p>}
        </>
    )
}