import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { useLoaderData } from 'react-router-dom';
import classes from "../expert/ExpertOnboardingStatusPage.module.css";

import { ExpertObrPageBanner } from "../../component/expertObrComponent/ExpertObrPageBanner";
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
import { AgencyObrStatusForm } from "../../component/formControls/AgencyObrStatusForm";

export const AgencyOnboardingStatusPage = () => {
    const [landingPageData, desktopBannerContent, tabletBannerContent, mobileBannerContent] = useLoaderData();
    return (
        <div className={classes.container}>
            <LoginPageTopNavigation landingPageData={landingPageData} />
            <ExpertObrPageBanner desktopBannerContent={desktopBannerContent} tabletBannerContent={tabletBannerContent} mobileBannerContent={mobileBannerContent} />
            <AgencyObrStatusForm />
            <LandingPageFooter landingPageData={landingPageData} />
        </div>
    )
}