import React, { useState, useEffect } from 'react';
import classes from './IndividualCoursePageBanner.module.css';
import { AppConstants } from '../../../constants/AppConstants';

export const IndividualCoursePageBanner = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultWebBanner}`;
    const defaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultTabletBanner}`;
    const defaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultMobileBanner}`;
    const [mobileImage, setMobileImage] = useState(null);
    const [tabletImage, setTabletImage] = useState(null);
    const [webImage, setWebImage] = useState(null);

    useEffect(() => {
        setWebImage(props.courseDetails.coursePromotionWebImageAbsolutePath ? props.courseDetails.coursePromotionWebImageAbsolutePath : defaultWebBanner);
        setTabletImage(props.courseDetails.coursePromotionTabletImageAbsolutePath ? props.courseDetails.coursePromotionTabletImageAbsolutePath : defaultTabletBanner);
        setMobileImage(props.courseDetails.coursePromotionMobileImageAbsolutePath ? props.courseDetails.coursePromotionMobileImageAbsolutePath : defaultMobileBanner);
    }, [props.courseDetails]);

    return (
        <>
            <div className={classes.courseMobileImgContainer}>
                {!props.courseDetails.coursePromotionMobileImageAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.courseDetails?.courseTitle}</p></div>}
                <img src={mobileImage} alt={AppConstants.courseBannerText} className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseTabletImgContainer}>
                {!props.courseDetails.coursePromotionTabletImageAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.courseDetails?.courseTitle}</p></div>}
                <img src={tabletImage} alt={AppConstants.courseBannerText} className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseBannerImgContainer}>
                {!props.courseDetails.coursePromotionWebImageAbsolutePath && <div className={classes.courseBannerTitleContainer}><p className={classes.courseBannerTitle}>{props?.courseDetails?.courseTitle}</p></div>}
                <img src={webImage} alt={AppConstants.courseBannerText} className={classes.courseWebBannerImage} />
            </div>
        </>
    );

}
