import React from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { useLoaderData } from 'react-router-dom';
import { MarketingHeader } from '../../layout/MarketingPageLayout/MarketingHeader';
import { MarketingLeftNav } from '../../layout/MarketingPageLayout/MarketingLeftNav';
import { MarketingFooter } from '../../layout/MarketingPageLayout/MarketingFooter';

export const MarketingTemplate = () => {
    const footerDetails = useLoaderData();

    return (
        <>
            <MarketingHeader />
            <Row>
                <Col lg={2}><MarketingLeftNav /></Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <Outlet />
                </Col>
            </Row>
            {footerDetails != null && <MarketingFooter footerDetails={footerDetails} />}
        </>
    )
}