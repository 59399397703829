import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { DateTime } from "luxon";
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import 'moment-timezone';
import './CompetitionCards.css';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { EnrolmentModal } from "../modal/EnrolmentModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import "react-multi-carousel/lib/styles.css";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import Auth from "../../utils/Auth";
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal";
import { AiOutlineClockCircle } from "react-icons/ai";
import { DataService } from "../../services/DataService";
import { PayOfflineConfirmationModal } from "../modal/PayOfflineConfirmationModal";
import { MathUtil } from "../../utils/MathUtil";
import { useEnrolment } from "../../hooks/useEnrolment";
import useWarnOnLeave from "../../hooks/useWarnOnLeave";
import { ViewSelectedWeekDays } from "../calendar/ViewSelectedWeekDays";

export const PerformanceAnalysisPageLearningActivitiesCard = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardDetailsArray, setCardDetails] = useState(props.cardDetails);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;

    useEffect(() => {
        setCardDetails(props.cardDetails);
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, [props.cardDetails]);

    const getAgeGroups = (competiton) => {
        if (competiton.minAge !== 0 && competiton.maxAge !== 0) {
            return `AGE ${competiton.minAge}-${competiton.maxAge}`;
        } else if (competiton.minAge === 0 && competiton.maxAge !== 0) {
            return `AGE ${signupMinimumAge}-${competiton.maxAge}`;
        } else if (competiton.minAge !== 0 && competiton.maxAge === 0) {
            return `AGE ${competiton.minAge}+`;
        } else {
            return "ALL AGES";
        }
    };


    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, type, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type, payOfflineFlag, creatorName, creatorRole });

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            if (type === AppConstants.learningActivitiesTypes.course && startTime === AppConstants.nullText) {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCourse(id, title, uniqueId);
                }
            } else {
                const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const currentDateTime = DateTime.local().setZone(timeZone);
                // check if the learning activity has started.
                if (currentDateTime > startDateTime) {
                    // show confirmation modal to the user.
                    setShowConfirmationModal(AppConstants.trueText);
                } else {
                    if (payOfflineFlag === AppConstants.yText) {
                        setShowOfflinePaymentModal(AppConstants.trueText);
                    } else {
                        if (type === AppConstants.learningActivitiesTypes.competition) {
                            enrolHere(id, title, uniqueId);
                        } else if (type === AppConstants.learningActivitiesTypes.course) {
                            enrolInCourse(id, title, uniqueId);
                        } else if (type === AppConstants.learningActivitiesTypes.workshop) {
                            enrolInWorkshop(id, title, uniqueId);
                        }
                    }
                }
            }
        } else {
            if (type === AppConstants.learningActivitiesTypes.competition) {
                enrolHere(id, title, uniqueId);
            } else if (type === AppConstants.learningActivitiesTypes.course) {
                enrolInCourse(id, title, uniqueId);
            } else if (type === AppConstants.learningActivitiesTypes.workshop) {
                enrolInWorkshop(id, title, uniqueId);
            }
        }
    };

    const updateConfirmationStatus = (status, type) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                if (type === AppConstants.learningActivitiesTypes.competition) {
                    enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                } else if (type === AppConstants.learningActivitiesTypes.course) {
                    enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                } else if (type === AppConstants.learningActivitiesTypes.workshop) {
                    enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                }
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.competition) {
                enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            } else if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.course) {
                enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            } else if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.workshop) {
                enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolHere = async (competitionId, competitionTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.enrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return AppConstants.nullText;
        }

        const cost = PaymentsUtil.calculateCost({ cost: cardDetailsArray.filter(card => card.uniqueId === uniqueId && card.learningActivityType === AppConstants.learningActivitiesTypes.competition)[0].cost, discount: 0 });
        const response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }

    };


    const enrolInCourse = async (courseId, courseTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.onlyStudentEnrolCourseError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return null;
        }

        const course = cardDetailsArray.filter(card => card.uniqueId === uniqueId && card.learningActivityType === AppConstants.learningActivitiesTypes.course)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });
        const response = await enrolInLearningActivity({
            uniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseEnrolledOnDate !== null && course.courseType === AppConstants.flexibleCourseType ? course.trackingType : AppConstants.falseText
        }, status)


        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }

    };

    const enrolInWorkshop = async (id, title, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.onlyStudentEnrolWorkshopError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return AppConstants.nullText;
        }
        const workshop = cardDetailsArray.filter(card => card.uniqueId === uniqueId && card.learningActivityType === AppConstants.learningActivitiesTypes.workshop)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.cost, discount: 0 });

        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    }

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    if (learningActivityDetails.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                        enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    } else if (learningActivityDetails.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                        enrolHere(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    } else if (learningActivityDetails.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
                        enrolInWorkshop(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    }
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.course) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        } else if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.competition) {
            enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        } else {
            enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        }
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
        if (transactionId) {
            window.location.reload();
        }
        setConfirmationPopupActivityDetails({});
    }
    const handleModalShow = () => {
        setShowModal(true);
    }

    //handels no of items to show
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <VscChevronRight />,
        prevArrow: <VscChevronLeft />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991.5,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767.5,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (cardData) => {
        const linkType = cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition ? AppConstants.competitionsText : cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? AppConstants.coursesText : AppConstants.workshopsText;
        const url = `${AppConstants.forwardSlash}${linkType}${AppConstants.forwardSlash}${cardData.skillCategorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.learningActivitySlug}`;
        window.open(url, AppConstants.openInNewTab);
    };

    return (
        <>
            <Slider className="performanceAnalysisPageSlider" {...settings}>
                {cardDetailsArray?.map((cardData, index) => (
                    <div key={cardData.uniqueId}>
                        <Card className="cardDiv performancePageCardWidth" onClick={() => handleCardClick(cardData)}>
                            {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition && <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCompetitionThumbnail} />}
                            {cardData.learningActivityType === AppConstants.learningActivitiesTypes.course && <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCourseThumbnail} />}
                            {cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop && <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultWorkshopThumbnail} />}
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    {cardData.learningActivityType !== AppConstants.learningActivitiesTypes.workshop && <Card.Text className={cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? "cardSubTitle courseDiffColor" : "cardSubTitle competitionDiffColor"}>{cardData.learningActivityType.toUpperCase()} <span className="seperator">{AppConstants.verticalBar}</span> {
                                        cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition && getAgeGroups(cardData)}
                                        {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition && <span className="seperator">{AppConstants.verticalBar}</span>} {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span data-tooltip-id={`${cardData.uniqueId}-offline`} className={cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? "modeSubtitle" : "modeSubtitle"} data-tooltip-place="top"><a onClick={stopPropagation} href={cardData.learningActivityType === "Competition" ? cardData.geoLink : cardData.geoLink} target={AppConstants.openInNewTab} className={cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? "workshopLink onlineIconCourse" : "workshopLink onlineIconCompetition"}><CgPin className={cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? "geoLocationIcon onlineIconCourse" : "geoLocationIcon onlineIconCompetition"} /> {cardData.type.toUpperCase()}</a></span> : <span className={cardData.learningActivityType === AppConstants.learningActivitiesTypes.course ? "onlineIconCourse modeSubtitle" : "onlineIconCompetition modeSubtitle"}>{cardData.type.toUpperCase()}</span>} </Card.Text>}
                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop && <Card.Text className="cardSubTitle workshopDiffColor">{cardData.learningActivityType.toUpperCase()} <span className="seperator">{AppConstants.verticalBar}</span> {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={`${cardData.uniqueId}-offline`}><a onClick={stopPropagation} href={cardData.geoLink} target={AppConstants.openInNewTab} className="workshopLink onlineIconWorkshop"><CgPin className="geoLocationIcon onlineIconWorkshop" />{cardData.type.toUpperCase()}</a></span> : <span className="onlineIconWorkshop modeSubtitle">{cardData.type.toUpperCase()}</span>}</Card.Text>}
                                </div>
                                <Tooltip className="tooltipMessage" id={`${cardData.uniqueId}-offline`}><span className="tooltipMessage">{cardData.location}</span></Tooltip>

                                {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition &&
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <a className="cardLink" data-tooltip-id={`${cardData.learningActivitySlug}-competition-title`} >{cardData.title}</a>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.learningActivitySlug}-competition-title`}><span>{cardData.title}</span></Tooltip>
                                    </Card.Text>}
                                {cardData.learningActivityType === AppConstants.learningActivitiesTypes.course &&
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <a className="cardLink" data-tooltip-id={`${cardData.learningActivitySlug}-course-title`} >{cardData.title}</a>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.learningActivitySlug}-course-title`}><span>{cardData.title}</span></Tooltip>
                                    </Card.Text>}
                                {cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop &&
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <a className="cardLink" data-tooltip-id={`${cardData.learningActivitySlug}-workshop-title`} >{cardData.title}</a>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.learningActivitySlug}-workshop-title`}><span>{cardData.title}</span></Tooltip>
                                    </Card.Text>}
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</Card.Text>
                                {(cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition || cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop) && <>
                                    <div className="emptyDivForCalendar"></div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcStartTime.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcEndTime.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                </>}
                                {cardData.learningActivityType === AppConstants.learningActivitiesTypes.course && <>
                                    <div className="cardTimeAndDate">
                                        <div className="dateTimeText">
                                            <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={cardData.weekDays} /></span>
                                        </div>
                                    </div>
                                    {cardData.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                        <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            &nbsp;<span className="competitionDateTimeFormat">Starts {DateTimeUtil.getDateTimeForCourses(cardData.stringStartTime)} {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>}
                                    {cardData.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                        <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            &nbsp;<span className="competitionDateTimeFormat">{cardData.stringStartTime} - {cardData.stringEndTime} {timeZone === AppConstants.utcTimezone && `(${timeZone})`} {cardData.reviewCount >= minimumReviewCount && <><span className="durationSeparator">|</span> {`${MathUtil.formatNumberToLocaleString(cardData.reviewCount)} ${cardData.reviewCount > 1 ? "Reviews" : "Review"}`}</>}</span>
                                        </div>
                                    </div>}
                                    {cardData.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                        <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            <span data-tooltip-id={`${cardData.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{cardData.numberOfClasses} {cardData.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {cardData.duration} {cardData.duration > 1 ? "Hours" : "Hour"} </span>
                                        </div>
                                    </div>}

                                    {cardData.courseType === AppConstants.flexibleCourseType && cardData.trackingType === AppConstants.classBasedTrackingText && <div className="cardTimeAndDate">
                                        <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            {cardData.enrolmentStatus === AppConstants.subscriptionStatus.active && <span data-tooltip-id={`${cardData.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{cardData.remainingClasses} {cardData.remainingClasses > 1 ? "Classes" : "Class"} remaining </span>}
                                            {cardData.enrolmentStatus !== AppConstants.subscriptionStatus.active && <span data-tooltip-id={`${cardData.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{cardData.numberOfClasses} {cardData.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {cardData.duration} {cardData.duration > 1 ? "Hours" : "Hour"} </span>}
                                        </div>
                                    </div>}
                                    {cardData.courseType === AppConstants.flexibleCourseType && cardData.trackingType === AppConstants.durationBasedTrackingText && <div className="cardTimeAndDate">
                                        {cardData.enrolmentStatus === AppConstants.subscriptionStatus.active && <>  <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                            <div className="dateTimeText">
                                                &nbsp;<span className="competitionDateTimeFormat">Ends&nbsp;<Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                            </div></>}
                                        {cardData.enrolmentStatus !== AppConstants.subscriptionStatus.active && <><div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            <span data-tooltip-id={`${cardData.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{cardData.packageDuration} {cardData.packageDuration > 1 ? "Days" : "Day"} </span>
                                        </div></>}
                                    </div>}
                                </>}

                                <div className="buttonSection">

                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition &&
                                        <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    }
                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition && cardData.disablePublicEnrolmentFlag === AppConstants.nText && <>
                                        <Button disabled={cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString} type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.activityId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.utcStartTime, type: AppConstants.learningActivitiesTypes.competition, creatorName: cardData.organizedBy, creatorRole: cardData.userRole, payOfflineFlag: cardData.payOfflineFlag }) }}>{cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString ? "Enrolled" : "Enrol Now"}</Button>
                                    </>
                                    }
                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.competition && cardData.disablePublicEnrolmentFlag === AppConstants.yText &&
                                        <div>
                                            <span data-tooltip-id={`${cardData.learningActivitySlug}-private`}><RiGitRepositoryPrivateFill /></span>
                                            <Tooltip className="tooltipMessagePrivate" id={`${cardData.learningActivitySlug}-private`}><span>This is a private competition. Public enrolments are disabled for this competition</span></Tooltip>
                                        </div>
                                    }

                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.course &&
                                        <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: cardData.discount })}</div>
                                    }
                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.course && <>
                                        <Button disabled={(cardData.courseType === AppConstants.flexibleCourseType && cardData.enrolmentStatus === AppConstants.subscriptionStatus.active && cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString) || (cardData.courseType === AppConstants.structuredCourseType && cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString)} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.activityId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.courseType === AppConstants.structuredCourseType ? `${cardData.utcStartDate} ${cardData.windowStartTime}` : AppConstants.nullText, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag: cardData.payOfflineFlag, creatorName: cardData.organizedBy, creatorRole: cardData.userRole }) }} type="button" className="cardButton">
                                            {Auth.isLogin() && cardData.courseType === AppConstants.flexibleCourseType && cardData.courseEnrolledOnDate !== AppConstants.nullText && cardData.enrolmentStatus === AppConstants.subscriptionStatus.expired ? AppConstants.renewPlanText
                                                : (Auth.isLogin() && cardData.courseType === AppConstants.flexibleCourseType && cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString && cardData.enrolmentStatus === AppConstants.subscriptionStatus.active) ? "Enrolled"
                                                    : (Auth.isLogin() && cardData.courseType === AppConstants.structuredCourseType && cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString) ? "Enrolled" : "Enrol Now"}</Button>

                                    </>
                                    }

                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop &&
                                        <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    }
                                    {cardData.learningActivityType === AppConstants.learningActivitiesTypes.workshop &&
                                        <Button disabled={cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString} type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.activityId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.utcStartTime, type: AppConstants.learningActivitiesTypes.workshop, creatorName: cardData.organizedBy, creatorRole: cardData.userRole, payOfflineFlag: cardData.payOfflineFlag }) }}>{cardData.isLoggedInStudentEnrolled === AppConstants.trueTextString ? "Enrolled" : "Enrol Now"}</Button>

                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </Slider >
            {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} enrolInCompetition={enrolHere} enrolInCourse={enrolInCourse} enrolInWorkshop={enrolInWorkshop} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} paymentStatus={paymentStatus} confirmBeforeEnrolment={confirmBeforeEnrolment} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
}
