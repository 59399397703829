import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import classes from './BrandingPageTopNavigation.module.css';
import { AppConstants } from "../../constants/AppConstants";
import templateClasses from "../../layout/ExpertPageLayout/ExpertHeader.module.css"
import Auth from '../../utils/Auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from "react-icons/fa";
import { BiLogOut, BiHome } from "react-icons/bi";
import { Logout } from '../../common/Logout';
import { RiProfileLine } from "react-icons/ri";
import { Link } from 'react-scroll';

export const BrandingPageTopNavigation = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    const userBrandingPageLink = Auth.isLogin() ? `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole()?.toLowerCase()}/${Auth.getLoggedInUserDetails()?.userSlug}` : AppConstants.emptyString;
    const [showMenu, setShowMenu] = useState(false);
    const sectionNames = AppConstants.micrositeTopNavigationSections;

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    function getUserHomePageUrl() {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleExpert) {
                window.open(AppConstants.expertHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                window.open(AppConstants.studentHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
                window.open(AppConstants.adminNumberTrackingPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
                window.open(AppConstants.agencyHomePath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
                window.open(AppConstants.salesNumberTrackingPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                window.open(AppConstants.marketingBulkSignupPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                window.open(AppConstants.partnerSuccessRecommendCreatorsPath, AppConstants.newPageTargetSelf);
            } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
                window.open(AppConstants.teamHomePath, AppConstants.newPageTargetSelf);
            } else {
                window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
            }
        }
        else {
            window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
        }
    }

    const showHamburgerOnMobileScreens = () => {
        return (props.topNavigationItems.courses || props.topNavigationItems.competitions || props.topNavigationItems.workshops || props.topNavigationItems.reviews)
    }

    return (
        <>
            <header className={classes.header}>
                {showHamburgerOnMobileScreens() && <div className={classes.hamburger} onClick={toggleMenu}>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                </div>}
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>
                <div className={classes.navItemSection}>
                    <nav>
                        <ul className={classes.list}>
                            {props.topNavigationItems.courses && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}>
                                <Link to={AppConstants.micrositeTopNavigationSections.courses} duration={100} offset={-90} className={classes.sectionNavItem}>
                                    {sectionNames.courses}
                                </Link >
                            </li>}
                            {props.topNavigationItems.competitions && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}>
                                <Link to={AppConstants.micrositeTopNavigationSections.competitions} duration={100} offset={-90} className={classes.sectionNavItem}>
                                    {sectionNames.competitions}
                                </Link >
                            </li>}
                            {props.topNavigationItems.workshops && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}>
                                <Link to={AppConstants.micrositeTopNavigationSections.workshops} duration={100} offset={-90} className={classes.sectionNavItem}>
                                    {sectionNames.workshops}
                                </Link >
                            </li>}
                            {props.topNavigationItems.reviews && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}>
                                <Link to={AppConstants.micrositeTopNavigationSections.reviews} duration={100} offset={-90} className={classes.sectionNavItem}>
                                    {sectionNames.reviews}
                                </Link >
                            </li>}

                            {!Auth.isLogin() && <li className={classes.listItem}>
                                <NavLink to={AppConstants.loginPageUrl} className={classes.loginButton}>
                                    Login
                                </NavLink>
                            </li>}
                            {!Auth.isLogin() && <li className={classes.listItem}>
                                <NavLink to={AppConstants.signup} className={classes.signUpButton}>
                                    Join Now
                                </NavLink>
                            </li>}
                        </ul>
                    </nav>
                </div>
                {Auth.isLogin() &&
                    <div className={`${templateClasses.profileSection} ms-3`}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !== null && <div>
                            <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={templateClasses.profileImage} alt="Profile" />
                        </div>}
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                            <div className={templateClasses.userInitials}>{Auth.getLoggedInUserDetails().nameInitials}</div>
                        </div>}
                        <Dropdown>
                            <Dropdown.Toggle className={templateClasses.profilePopoverButton}>
                                <p className={templateClasses.profileName}>{Auth.getLoggedInName().length < 16 ? Auth.getLoggedInName() : Auth.getLoggedInName().substr(0, 16) + ".."}</p> <FaChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={getUserHomePageUrl}><BiHome className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Home</p></Dropdown.Item>
                                {((Auth.getUserRole() === AppConstants.userRoleExpert || Auth.getUserRole() === AppConstants.userRoleAgency)) && <Dropdown.Item onClick={() => window.open(userBrandingPageLink, AppConstants.openInNewTab)} className={templateClasses.profileDropdownItem}><RiProfileLine className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Branding Page</p></Dropdown.Item>}
                                <Dropdown.Item className={templateClasses.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={templateClasses.profileDropdownOptionIcon} /><p className={templateClasses.profileDropdownOption}>Logout</p></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                {showMenu && <nav>
                    <ul className={`${classes.listOnMobile} ${showMenu ? classes.showMenu : ''}`}>
                        {props.topNavigationItems.courses && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}>
                            <Link to={AppConstants.micrositeTopNavigationSections.courses} duration={100} offset={-60} className={classes.sectionNavItem}>
                                {sectionNames.courses}
                            </Link >
                        </li>}
                        {props.topNavigationItems.competitions && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}>
                            <Link to={AppConstants.micrositeTopNavigationSections.competitions} duration={100} offset={-60} className={classes.sectionNavItem}>
                                {sectionNames.competitions}
                            </Link >
                        </li>}
                        {props.topNavigationItems.workshops && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}>
                            <Link to={AppConstants.micrositeTopNavigationSections.workshops} duration={100} offset={-60} className={classes.sectionNavItem}>
                                {sectionNames.workshops}
                            </Link >
                        </li>}
                        {props.topNavigationItems.reviews && <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}>
                            <Link to={AppConstants.micrositeTopNavigationSections.reviews} duration={100} offset={-60} className={classes.sectionNavItem}>
                                {sectionNames.reviews}
                            </Link >
                        </li>}
                    </ul>
                </nav>}
            </header>
        </>
    );
}