import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import classes from './IndividualSkillsCards.module.css';
import { FaAward } from 'react-icons/fa';
import { BsArrowDownCircle } from 'react-icons/bs';
import WorldFlag from 'react-world-flags';
import { AppConstants } from "../../../constants/AppConstants";
import Auth from "../../../utils/Auth";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import modalClass from "../../cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { LoginModal } from "../../modal/LoginModal";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const IndividualSkillsExpertCards = (props) => {
    const [cardCount, setCardCount] = useState(0);
    const [expertFollowDetails, setExpertFollowDetails] = useState([]);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalAlertText, setModalAlertText] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [loginExpertFollowDetails, setLoginExpertFollowDetails] = useState({});
    const [sessionExpired, setSessionExpired] = useState(AppConstants.falseText);
    const [loggedInAgencyType, setLoggedInAgencyType] = useState(AppConstants.nullText);

    useEffect(() => {
        if (props.allActivitiesForSkill?.length > 0) {
            setExpertFollowDetails(props.allActivitiesForSkill?.map(expert => (
                {
                    uniqueId: expert.userUniqueId,
                    followStatus: expert.loggedInUserFollowStatus === AppConstants.trueTextString ? AppConstants.trueText : AppConstants.falseText
                }
            )));
        }
    }, [props.allActivitiesForSkill]);

    useEffect(() => {
        const fetchLoggedInAgencyType = async () => {
            const url = AppConstants.getLoggedInAgencyTypeAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setLoggedInAgencyType(response.agencyType);
            }
        }

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleAgency) {
            fetchLoggedInAgencyType();
        } else {
            setLoggedInAgencyType(AppConstants.emptyString);
        }
    }, []);

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(3);
            setViewMoreCardsCount(3);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(2);
            setViewMoreCardsCount(2);
        }
        if (viewportWidth <= 575) {
            setCardCount(1);
            setViewMoreCardsCount(1);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    const handleModalClose = () => {
        if ((loginExpertFollowDetails.idx !== null || loginExpertFollowDetails.idx !== undefined)) {
            setLoginExpertFollowDetails({});
            if (!sessionExpired) {
                window.location.reload();
            }
        }
        setShowModal(AppConstants.falseText);
    };

    const followUser = (expert, idx) => {
        setLoginExpertFollowDetails({ expert, idx });
        setShowLoginModal(AppConstants.trueText);
    };


    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) {
                updateFollowStatus(loginExpertFollowDetails.expert, loginExpertFollowDetails.idx, AppConstants.followUnfollowStatus.follow);
            } else {
                const userDetails = Auth.getLoggedInUserDetails();
                setModalAlertText(`Hi ${userDetails.firstName}, ${Auth.getUserRole() === AppConstants.userRoleExpert ? ErrorMessages.expertFollowErrorMessage : ErrorMessages.adminFollowErrorMessage}`);
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
            }
        }
        setShowLoginModal(AppConstants.falseText);
    }

    const updateFollowStatus = async (expert, idx, userFollowParam) => {
        let status = userFollowParam ? userFollowParam : expertFollowDetails[idx]?.followStatus ? AppConstants.followUnfollowStatus.unfollow : AppConstants.followUnfollowStatus.follow;
        const url = `${AppConstants.followUnfollowUserAPI}${expert.userUniqueId}&status=${status}`;
        const response = await AuthorizedDataService.postRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString,
        );

        if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`You have already followed ${expert.userRole === AppConstants.userRoleExpert ? expert.userName : expert.academyName}`) : setModalAlertText(`You have already unfollowed ${expert.userRole === AppConstants.userRoleExpert ? expert.userName : expert.academyName}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode400) {
            const body = await response.json();
            if (body === -22) {
                // Agency cannot follow Agency (School, Skill Org, Housing Society)
                setModalAlertText(ErrorMessages.agencyFollowAgencyError);
            } else if (body === -21) {
                // academy cannot follow other agency
                setModalAlertText(ErrorMessages.academyFollowAgencyError);
            }
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
            setExpertFollowDetails(expertFollowDetails.map(expertDetails => (
                {
                    uniqueId: expertDetails.uniqueId,
                    followStatus: expertDetails.uniqueId === expert.userUniqueId ? (status === AppConstants.followUnfollowStatus.unfollow) ? AppConstants.falseText : AppConstants.trueText : expertDetails.followStatus
                }
            )));
            if (loginExpertFollowDetails.idx >= 0) {
                window.location.reload();
            }
        } else if (response?.status === AppConstants.httpResponseCodes.responseCode403) {
            setModalAlertText(ErrorMessages.sessionExpiredFollowError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setSessionExpired(AppConstants.trueText);
        } else {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`Something went wrong while following ${expert.userRole === AppConstants.userRoleExpert ? expert.userName : expert.academyName}. Please try again.`) : setModalAlertText(`Something went wrong while unfollowing ${expert.userRole === AppConstants.userRoleExpert ? expert.userName : expert.academyName}. Please try again.`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        }
    };

    return (
        <>
            <Row xs={1} sm={1} md={2} lg={3} xl={3} className={`${classes.expertCard} mt-3`}>
                {props.allActivitiesForSkill !== null && props.allActivitiesForSkill?.slice(0, cardCount).map((userCard, index) => (
                    <Col className={classes.expertCardSection}>
                        <Card key={index} className={classes.expertCardBody} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${userCard.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${userCard.userSlug}`)}>
                            <div className={classes.expertMicrositeLinkContainer}>
                                <div className={classes.topExpertContainer}>
                                    {userCard.userRole === AppConstants.userRoleExpert && userCard.topExpertFlag === AppConstants.yText && <div className={classes.topExpertFlagSection}><FaAward className={classes.expertBadgeIcon} /> <p className={classes.expertBadgeText}>Top Expert</p></div>}
                                    {userCard.userRole === AppConstants.userRoleAgency && userCard.topAgencyFlag === AppConstants.yText && <div className={classes.topExpertFlagSection}><FaAward className={classes.expertBadgeIcon} /> <p className={classes.expertBadgeText}>Top Agency</p></div>}
                                </div>
                            </div>
                            <div className={classes.expertCardContainer}>
                                <Card.Img variant="top" className={`${classes.expertCardImage} ${userCard.userRole === AppConstants.userRoleAgency ? classes.academyCardImageBorder : classes.expertCardImageBorder}`} src={userCard.userImageUrl} />
                                <div className={classes.expertFlagSection}><WorldFlag className={classes.expertCountryFlag} code={userCard.countryCode} /></div>
                            </div>
                            <Card.Body className={classes.expertNameContainer}>
                                {userCard.userRole === AppConstants.userRoleExpert && <Card.Title className={classes.expertCardTitle}>{userCard.userName}</Card.Title>}
                                {userCard.userRole === AppConstants.userRoleAgency && <Card.Title className={classes.expertCardTitle}>{userCard.academyName}</Card.Title>}
                                {userCard.userRole === AppConstants.userRoleExpert && userCard.userOrganization != null && <Card.Text className={classes.expertCardSubTitle}>{userCard.userDesignation !== null && `${userCard.userDesignation},`} {userCard.userOrganization}</Card.Text>}
                                {userCard.userRole === AppConstants.userRoleExpert && userCard.userOrganization === null && <Card.Text className={`${classes.expertCardSubTitle} ${classes.expertCardSubTitleHeight}`}>{userCard.userDesignation === null && ` `} </Card.Text>}

                                {userCard.userRole === AppConstants.userRoleAgency && userCard.academyCity != null && <Card.Text className={`${classes.expertCardSubTitle} ${classes.expertCardSubTitleHeight}`}>{userCard.academyState !== null ? `${userCard.academyCity}, ${userCard.academyState}` : `${userCard.academyCity}`}</Card.Text>}

                                {!Auth.isLogin() && <Button className={`${classes.followUnfollowButton} ${classes.follow}`} onClick={e => { stopPropagation(e); followUser(userCard, index) }}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button>}
                                {(Auth.isLogin() && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && ((loggedInAgencyType === AppConstants.agencyTypes.academy && userCard.userRole === AppConstants.userRoleExpert) || (loggedInAgencyType !== AppConstants.agencyTypes.academy && (userCard.userRole === AppConstants.userRoleExpert || userCard.agencyType === AppConstants.agencyTypes.academy))))) && expertFollowDetails[index]?.followStatus) ? <Button className={`${classes.followUnfollowButton} ${classes.following}`} onClick={e => { stopPropagation(e); updateFollowStatus(userCard, index) }}>{AppConstants.followUnfollowStatus.following} </Button> : null}
                                {(Auth.isLogin() && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && ((loggedInAgencyType === AppConstants.agencyTypes.academy && userCard.userRole === AppConstants.userRoleExpert) || (loggedInAgencyType !== AppConstants.agencyTypes.academy && (userCard.userRole === AppConstants.userRoleExpert || userCard.agencyType === AppConstants.agencyTypes.academy))))) && !expertFollowDetails[index]?.followStatus) ? <Button className={`${classes.followUnfollowButton} ${classes.follow}`} onClick={e => { stopPropagation(e); updateFollowStatus(userCard, index) }}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {props.allActivitiesForSkill !== null && <div className={classes.loadMoreButton} >
                {cardCount < props.allActivitiesForSkill?.length && <BsArrowDownCircle className={classes.loadMoreIcon} onClick={() => setCardCount(cardCount + viewMoreCardsCount)} />}
            </div>}
            {(props.allActivitiesForSkill == null || props.allActivitiesForSkill?.length === 0) && <p className='noDataAvailableError'>{`No experts for '${props.skill}' skill to display at the moment`}</p>}
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>
            {showLoginModal && <LoginModal signupMessage={AppConstants.signupFollowMessage} loginMessage={AppConstants.loginFollowMessage} onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
        </>
    );
}