import React from 'react';
import classes from './ExpertObrPageBanner.module.css';
export const ExpertObrPageBanner = (props) => {
    const { desktopBannerContent, tabletBannerContent, mobileBannerContent } = props;

    const desktopImagePath = desktopBannerContent.bannerImage.imagePath;
    const tabletImagePath = tabletBannerContent.bannerImage.imagePath;
    const mobileImagePath = mobileBannerContent.bannerImage.imagePath;

    return (
        <>
            <div className={classes.mobileImgContainer}>
                <img src={mobileImagePath} alt="banner" className={classes.bannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{mobileBannerContent.bannerImage.title}</h5>
                </div>
            </div>
            <div className={classes.tabletImgContainer}>
                <img src={tabletImagePath} alt="banner" className={classes.bannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{tabletBannerContent.bannerImage.title}</h5>
                </div>
            </div>
            <div className={classes.desktopImgContainer}>
                <img src={desktopImagePath} alt="banner" className={classes.bannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{desktopBannerContent.bannerImage.title}</h5>
                </div>
            </div>
        </>
    );
}