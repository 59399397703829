import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import expertMicrositeLayout from './ExpertMicrositeLayout.module.css';
import { BiLoaderAlt } from 'react-icons/bi';
import { MicrositePageBanner } from "./MicrositePageBanner";
import { MicrositePageLeftSection } from "./MicrositePageLeftSection";
import { MicrositePageRightSection } from "./MicrositePageRightSection";
import { AgencyMicrositePageLeftSection } from "../agencyMicrosite/AgencyMicrositePageLeftSection";
import { AgencyMicrositePageRightSection } from "../agencyMicrosite/AgencyMicrositePageRightSection";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";
import Auth from "../../utils/Auth";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { StringUtils } from "../../utils/StringUtils";

export const ExpertMicrositeLayout = (props) => {
    const { micrositeSlug } = useParams();
    const location = useLocation();
    const pathnames = location.pathname.split(AppConstants.forwardSlash).filter((x) => x);
    const userRoleMicrosite = pathnames[AppConstants.zeroIndex];
    const navigate = useNavigate();
    const [micrositeDetails, setMicrositeDetails] = useState([]);
    const [micrositeDetailsLoader, setMicrositeDetailsLoader] = useState(AppConstants.trueText);
    const linkToShare = (userRoleMicrosite === AppConstants.expertText) ? `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expertText}${AppConstants.forwardSlash}${micrositeSlug}` : `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${micrositeSlug}`;
    const [userRole, setUserRole] = useState(AppConstants.emptyString);
    const [creatorGalleryContent, setCreatorGalleryContent] = useState([]);
    const [courseDetails, setCourseDetails] = useState([]);
    const [competitionDetails, setCompetitionDetails] = useState([]);
    const [workshopDetails, setWorkshopDetails] = useState([]);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [micrositeReviews, setMicrositeReviews] = useState([]);

    useEffect(() => {
        //fetch microsite details
        async function getExpertMicrositeDetails() {
            setMicrositeDetailsLoader(AppConstants.trueText);
            let url = AppConstants.emptyString;
            setUserRole(userRoleMicrosite);

            if (userRoleMicrosite?.toLocaleLowerCase() === AppConstants.userRoleAgency.toLocaleLowerCase()) {
                url = `${AppConstants.getAgencyMicrositeDetailsAPI}${micrositeSlug}`;
            } else if (userRoleMicrosite?.toLocaleLowerCase() === AppConstants.userRoleExpert.toLocaleLowerCase()) {
                url = `${AppConstants.getExpertMicrositeDetailsAPI}${micrositeSlug}`;
            } else {
                navigate(AppConstants.asterisk);
            }

            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                if (response.galleryMediaPaths?.length > 0) {
                    setCreatorGalleryContent(response.galleryMediaPaths.split(",")?.map(value => ({
                        url: value,
                        type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[value.split('.').pop().toLowerCase()]) ? AppConstants.mediaTypes.image : AppConstants.mediaTypes.video
                    })));
                }
                setMicrositeDetails(response);
                setMicrositeDetailsLoader(AppConstants.falseText);
            } else {
                setMicrositeDetails([]);
                setMicrositeDetailsLoader(AppConstants.falseText);
                navigate(AppConstants.asterisk);
            }
        };
        getExpertMicrositeDetails();
    }, []);

    useEffect(() => {
        const getFullURL = (endpoint) => {
            let params = { userRole: micrositeDetails?.uniqueId ? AppConstants.userRoleExpert : AppConstants.userRoleAgency };

            if (endpoint === AppConstants.micrositeGetUpcomingWorkshopsAPIWithParam) {
                params = { ...params, creatorUniqueId: micrositeDetails?.uniqueId ? micrositeDetails?.uniqueId : micrositeDetails?.agencyUserUniqueId }
            } else {
                params = { ...params, userUniqueId: micrositeDetails?.uniqueId ? micrositeDetails?.uniqueId : micrositeDetails?.agencyUserUniqueId }
            }

            return StringUtils.createURLWithParams(endpoint, params);
        }

        async function getCoursesDetailsUnAurhorized(url) {
            const requestHeaders = {
                preferredTimezone
            }
            const response = await DataService.get(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            return response || [];
        };

        async function getCoursesDetails(url) {
            const response = await AuthorizedDataService.getRequest(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            return response || [];
        };

        async function getWorkshopsDetails(url) {
            const requestHeaders = {
                preferredTimezone
            }
            const response = await AuthorizedDataService.getRequest(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            return response || [];
        };

        async function getWorkshopsDetailsUnauthorized(url) {
            const requestHeaders = {
                preferredTimezone
            }
            const response = await DataService.get(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            return response || [];
        };

        async function getCompetitionsDetailsUnauthorized(url) {
            const requestHeaders = {
                preferredTimezone
            }
            const response = await DataService.get(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            return response || [];
        };

        async function getCompetitionsDetails(url) {
            const response = await AuthorizedDataService.getRequest(
                getFullURL(url),
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            return response || [];
        };

        async function fetchReviewForUser() {
            const url = `${AppConstants.getCreatorReviewsForMicrositeAPI}${micrositeDetails.uniqueId ? micrositeDetails.uniqueId : micrositeDetails?.agencyUserUniqueId}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            return response || [];
        }


        const fetchAllActivities = async () => {
            const fetchDetails = Auth.isLogin() ? {
                courses: getCoursesDetails(AppConstants.micrositeGetUpcomingCoursesAPIWithParam),
                competitions: getCompetitionsDetails(AppConstants.micrositeGetUpcomingCompetitionsAPIWithParam),
                workshops: getWorkshopsDetails(AppConstants.micrositeGetUpcomingWorkshopsAPIWithParam),
                reviews: fetchReviewForUser()
            } : {
                courses: getCoursesDetailsUnAurhorized(AppConstants.micrositeGetUpcomingCoursesAPIWithParam),
                competitions: getCompetitionsDetailsUnauthorized(AppConstants.micrositeGetUpcomingCompetitionsAPIWithParam),
                workshops: getWorkshopsDetailsUnauthorized(AppConstants.micrositeGetUpcomingWorkshopsAPIWithParam),
                reviews: fetchReviewForUser()
            };

            const [courses, competitions, workshops, reviews] = await Promise.all([fetchDetails.courses, fetchDetails.competitions, fetchDetails.workshops, fetchDetails.reviews]);

            setCourseDetails(courses);
            setWorkshopDetails(workshops);
            setCompetitionDetails(competitions);
            setMicrositeReviews(reviews);

            const updatedTopBanner = {
                competitions: competitions.length > 0 ? AppConstants.trueText : AppConstants.falseText,
                workshops: workshops.length > 0 ? AppConstants.trueText : AppConstants.falseText,
                courses: courses.length > 0 ? AppConstants.trueText : AppConstants.falseText,
                reviews: reviews?.length > 0 ? AppConstants.trueText : AppConstants.falseText
            };

            props.updateTopNavigationItems(updatedTopBanner);
        }

        if ((micrositeDetails?.uniqueId || micrositeDetails?.agencyUserUniqueId)) {
            fetchAllActivities();
        }
    }, [preferredTimezone, micrositeDetails]);


    return (
        <>
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex && <MicrositePageBanner userRole={userRole} micrositeBannerData={micrositeDetails} />}
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex && <div className={expertMicrositeLayout.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={expertMicrositeLayout} linkToShare={linkToShare} />
            </div>}
            {micrositeDetailsLoader && <div className={expertMicrositeLayout.errorContainer}>
                <p className="loaderIcon"><BiLoaderAlt className="spinner" /></p>
            </div>}
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex &&
                <div className={`row mb-3 ${expertMicrositeLayout.micorsiteContainer}`}>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        {userRole === AppConstants.userRoleExpert.toLocaleLowerCase() && <MicrositePageLeftSection micrositeData={micrositeDetails} courseDetails={courseDetails} competitionDetails={competitionDetails} workshopDetails={workshopDetails} creatorGalleryContent={creatorGalleryContent} micrositeReviews={micrositeReviews} />}
                        {userRole === AppConstants.userRoleAgency.toLocaleLowerCase() && <AgencyMicrositePageLeftSection micrositeData={micrositeDetails} courseDetails={courseDetails} competitionDetails={competitionDetails} workshopDetails={workshopDetails} creatorGalleryContent={creatorGalleryContent} micrositeReviews={micrositeReviews} />}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        {userRole === AppConstants.userRoleExpert.toLocaleLowerCase() && <MicrositePageRightSection micrositeData={micrositeDetails} />}
                        {userRole === AppConstants.userRoleAgency.toLocaleLowerCase() && <AgencyMicrositePageRightSection micrositeData={micrositeDetails} />}
                    </div>
                </div>}
        </>
    );

}