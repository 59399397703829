import React, { useState, useEffect } from "react";
import { Button, Collapse, Toast, ToastContainer, Modal } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowRoundBack } from "react-icons/io"
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { FaArrowLeft } from 'react-icons/fa';
import { DateTime } from "luxon";
import { AppConstants } from "../../constants/AppConstants"
import classes from "../../pages/expert/ExpertCreateCompetition.module.css"
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { EditWorkshopDetails } from "./editWorkshopDetails/EditWorkshopDetails";
import { EditFrequentlyAskedQuestions } from "../editCourseComponent/editFrequentlyAskedQuestions/EditFrequentlyAskedQuestions";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import Auth from "../../utils/Auth";
import modalClass from "../cards/CompetitionsCards.module.css";
import toastClass from "../createCourseComponent/createCourseToast.module.css"
import { EditCourseCertificate } from "../editCourseComponent/editCourseCertificate/EditCourseCertificate";
import { EditAdvancedWorkshopSettings } from "./editAdvancedWorkshopSettings/EditAdvancedWorkshopSettings";

export const EditWorkshopComponent = (props) => {
    const [selectedWorkshop, setSelectedWorkshop] = useState({});
    const stages = AppConstants.editWorkshopActions;
    const basicDetailsFormLabels = AppConstants.workshopDetailsFormLabels;
    const certificateFromLabels = AppConstants.courseCertificateLabels;
    const [showSpinner, setShowSpinner] = useState(AppConstants.falseText);
    const [open, setOpen] = useState([AppConstants.trueText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
    const [basicDetailsData, setBasicDetailsData] = useState({});
    const [basicDetailsErrors, setBasicDetailsErros] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [certificateData, setCertificateData] = useState({});
    const [toastBody, setToastBody] = useState(AppConstants.emptyString);
    const [showToast, setShowToast] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [workshopLink, setWorkshopLink] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [template, setTemplate] = useState({});
    const [skills, setSkills] = useState([]);
    const [showLoader, setShowLoader] = useState(AppConstants.trueText);
    const [showDetailsError, setShowDetailsError] = useState(AppConstants.falseText);
    const [toastBackground, setToastBackground] = useState(AppConstants.alertVarient[1]);
    const [advancedSettingsData, setAdvancedSettingsData] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const fetchWorkshopDetails = async () => {
            const url = `${AppConstants.getSpecificWorkshopDetailsAPI}${props.selectedWorkshop}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setSelectedWorkshop(response);
                setFaqData(response.workshopFaqs)
                setShowLoader(AppConstants.falseText);
                setShowDetailsError(AppConstants.falseText);
            } else {
                setShowDetailsError(AppConstants.trueText);
                setSelectedWorkshop([]);
                setShowLoader(AppConstants.falseText);
            }
        };
        const getCertificateTemplate = async () => {
            const url = AppConstants.fetchCertificateTemplateApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setTemplate(response);
        };
        const fetchSkillsForExpert = async () => {
            const url = AppConstants.fetchExpertSkillsApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSkills(response);
            }
        };

        async function fetchSkillsForAgency() {
            const url = AppConstants.getSkillsofAgencyAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                // Sort the skills alphabetically based on skillName
                setSkills(response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                ));
            }
        };

        if (Auth.getLoggedInUserDetails().userRole === AppConstants.userRoleAgency) {
            fetchSkillsForAgency();
        } else {
            fetchSkillsForExpert();
        }
        fetchWorkshopDetails();
        getCertificateTemplate();
    }, []);

    useEffect(() => {
        let errorValues = Object.values(basicDetailsErrors);
        setFormErrors(errorValues)

    }, [basicDetailsErrors]);

    // function to capture basic details
    const changeBasicFormDetailsData = (newData) => {
        setBasicDetailsData(newData);
    };

    // function to capture basic details  errors
    const changeBasicDetailsFormError = (errors) => {
        setBasicDetailsErros(errors);
    };

    // function to capture frequently asked questions
    const changeFaqData = (newData) => {
        setFaqData(newData);
    };

    const changeCertificateData = (newData) => {
        setCertificateData(newData);
    };

    const changeAdvancedSettings = (newData) => {
        setAdvancedSettingsData(newData)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const saveCurrentStep = ({ step }) => {
        setOpen(prevState => prevState.map((state, i) => {
            if (i <= step) state = AppConstants.falseText;
            if (i === step + 1) state = AppConstants.trueText;
            return state;
        }));
        scrollToTop();
    };

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
        if (workshopLink.length > 0) {
            props.onHideEdit();
        }
    }

    const formatRichTextContent = (content) => {
        return content.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, '');
    }

    const getDateObject = (date, time) => {
        if (!date) {
            return AppConstants.nullText;
        }
        const dateArr = date.split(AppConstants.hyphen);
        const timeArr = time.split(AppConstants.colon);
        const dateTime = DateTime.fromObject({
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2],
            hour: timeArr[0],
            minute: timeArr[1],
        }, { zone: timeZone });

        return dateTime;
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);
    };

    const buildBasicDetailsProps = (filePaths) => {
        let registration_close_time;
        if (basicDetailsData.registrationClosingDate !== AppConstants.emptyString && basicDetailsData.registrationClosingTime !== AppConstants.emptyString) {
            registration_close_time = formatDateTime(getDateObject(basicDetailsData.registrationClosingDate, basicDetailsData.registrationClosingTime));
        } else {
            // Setting default value of registration closing time to 1 minute before workshop end time
            let date = getDateObject(basicDetailsData.endDate, basicDetailsData.endTime);
            registration_close_time = formatDateTime(date.minus({ minutes: 1 })); // Subtracting 1 minute from workshop end time.
        }
        let workshopDetails = {
            workshopUniqueId: selectedWorkshop.uniqueId,
            workshopTitle: basicDetailsData.title.trim(),
            workshopDescription: formatRichTextContent(basicDetailsData.description),
            workshopDescriptionDocument: filePaths.descriptionDocument ? filePaths.descriptionDocument : (basicDetailsData.uploadedDescriptionDocument ? selectedWorkshop.descriptionDocumentRelativePath : null),
            workshopStartDate: formatDateTime(getDateObject(basicDetailsData.startDate, basicDetailsData.startTime)),
            workshopEndDate: formatDateTime(getDateObject(basicDetailsData.endDate, basicDetailsData.endTime)),
            workshopDuration: basicDetailsData.durationInHours,
            workshopRegistrationClosesOn: registration_close_time,
            workshopModeOfDelivery: basicDetailsData.modeOfDelivery,
            workshopGeoLocation: basicDetailsData.modeOfDelivery === AppConstants.workshopTypeOffline ? basicDetailsData.geoLocation?.trim() : AppConstants.nullText,
            workshopAddress: basicDetailsData.modeOfDelivery === AppConstants.workshopTypeOffline ? basicDetailsData.address.replace(/\n/g, ' ')?.trim() : AppConstants.nullText,
            workshopLink: basicDetailsData.modeOfDelivery === AppConstants.competitionTypeOnline ? basicDetailsData.workshopLink?.trim() : AppConstants.nullText,
            workshopSpocName: basicDetailsData.expertName.trim() || AppConstants.nullText,
            workshopSpocEmail: basicDetailsData.expertEmail.trim() || AppConstants.nullText,
            workshopSpocMobileNumber: basicDetailsData.expertMobile || AppConstants.nullText,
            workshopCost: basicDetailsData.cost === AppConstants.emptyString ? 0 : basicDetailsData.cost,
            skillId: skills.length === 1 ? skills[0].skillId : basicDetailsData.skill,
            workshopThumbnailImagePath: filePaths.thumbnailImage ? filePaths.thumbnailImage : (basicDetailsData.uploadedThumbnailImage ? selectedWorkshop.thumbnailImageRelativePath : null),
            workshopPromotionWebImagePath: filePaths.promotionImageForWeb ? filePaths.promotionImageForWeb : (basicDetailsData.uploadedPromotionImageWeb ? selectedWorkshop.promotionImageWebRelativePath : null),
            workshopPromotionMobileImagePath: filePaths.promotionImageForMobile ? filePaths.promotionImageForMobile : (basicDetailsData.uploadedPromotionImageMobile ? selectedWorkshop.promotionImageMobileRelativePath : null),
            workshopPromotionTabletImagePath: filePaths.promotionImageForTablet ? filePaths.promotionImageForTablet : (basicDetailsData.uploadedPromotionImageTablet ? selectedWorkshop.promotionImageTabletRelativePath : null),
            workshopPromotionVideoPath: filePaths.promotionVideo ? filePaths.promotionVideo : (basicDetailsData.uploadedPromotionVideo ? selectedWorkshop.promotionVideoRelativePath : null),
            payOfflineFlag: advancedSettingsData.enableOfflinePayment ? AppConstants.yText : AppConstants.nText
        }

        return workshopDetails;
    };

    const buildCertificateProps = (filePaths) => {
        let workshopCertificate = {
            certificateTitle: certificateData.certificateMessage.trim() ? AppConstants.workshopCertificateTilte : AppConstants.nullText,
            certificateMessage: certificateData.certificateMessage.trim() || AppConstants.nullText,
            certificateLogoOnePath: filePaths.logoOne ? filePaths.logoOne : certificateData.uploadedLogoOne ? selectedWorkshop.certificateLogoOneRelativePath : AppConstants.nullText,
            certificateLogoTwoPath: filePaths.logoTwo ? filePaths.logoTwo : certificateData.uploadedLogoTwo ? selectedWorkshop.certificateLogoTwoRelativePath : AppConstants.nullText,
            certificateLogoThreePath: filePaths.logoThree ? filePaths.logoThree : certificateData.uploadedLogoThree ? selectedWorkshop.certificateLogoThreeRelativePath : AppConstants.nullText,
            certificateLogoFourPath: AppConstants.nullText,
            certificateSignatoryOneName: certificateData.signatoryOneName || AppConstants.nullText,
            certificateSignatoryTwoName: certificateData.signatoryTwoName || AppConstants.nullText,
            certificateSignatoryThreeName: certificateData.signatoryThreeName || AppConstants.nullText,
            certificateSignatoryOneDesignation: certificateData.signatoryOneDesignation || AppConstants.nullText,
            certificateSignatoryTwoDesignation: certificateData.signatoryTwoDesignation || AppConstants.nullText,
            certificateSignatoryThreeDesignation: certificateData.signatoryThreeDesignation || AppConstants.nullText,
            certificateSignatoryOneImagePath: filePaths.signatoryImageOne ? filePaths.signatoryImageOne : certificateData.uploadedSignatoryImageOne ? selectedWorkshop.certificateSignatoryOneImageRelativePath : AppConstants.nullText,
            certificateSignatoryTwoImagePath: filePaths.signatoryImageTwo ? filePaths.signatoryImageTwo : certificateData.uploadedSignatoryImageTwo ? selectedWorkshop.certificateSignatoryTwoImageRelativePath : AppConstants.nullText,
            certificateSignatoryThreeImagePath: filePaths.signatoryImageThree ? filePaths.signatoryImageThree : certificateData.uploadedSignatoryImageThree ? selectedWorkshop.certificateSignatoryThreeImageRelativePath : AppConstants.nullText,
            certificateSignatoryOneOrganization: certificateData.signatoryOneOrganization || AppConstants.nullText,
            certificateSignatoryTwoOrganization: certificateData.signatoryTwoOrganization || AppConstants.nullText,
            certificateSignatoryThreeOrganization: certificateData.signatoryThreeOrganization || AppConstants.nullText,
            templateId: template[2]?.id
        };

        return workshopCertificate;
    }

    const checkFormValidity = () => {
        if (formErrors?.length === 0 && basicDetailsData.title?.trim() !== AppConstants.emptyString && basicDetailsData.description?.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
            return AppConstants.trueText;
        } else {
            return AppConstants.falseText;
        }
    };

    const checkCertificateValidity = () => {
        let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
        for (let i = 0; i < certificateErrorMessages.length; i++) {
            if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                return AppConstants.falseText;
            }
        }

        if (certificateData.logoUpdated && certificateData.messageUpdated) {
            if (certificateData.signatoryOneUpdated === AppConstants.incompleteText || certificateData.signatoryTwoUpdated === AppConstants.incompleteText || certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                return AppConstants.falseText;
            } else if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return AppConstants.falseText;
            } else {
                return AppConstants.trueText;
            }
        } else if (certificateData.logoUpdated || certificateData.messageUpdated) {
            return AppConstants.falseText;
        } else {
            if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return AppConstants.trueText;
            } else {
                return AppConstants.falseText;
            }
        }
    };

    const manageErrors = (errorMsg) => {
        setToastBody(errorMsg);
        setShowToast(AppConstants.trueText);
        setShowSpinner(AppConstants.falseText);
    };

    const uploadFiles = async () => {
        try {
            const timestamp = DateTimeUtil.fetchCurrentTimestamp();
            const userDetails = Auth.getLoggedInUserDetails();
            let filePaths = {
                thumbnailImage: AppConstants.nullText,
                promotionImageForMobile: AppConstants.nullText,
                promotionImageForTablet: AppConstants.nullText,
                promotionImageForWeb: AppConstants.nullText,
                promotionVideo: AppConstants.nullText,
                descriptionDocument: AppConstants.nullText,
                logoOne: AppConstants.nullText,
                logoTwo: AppConstants.nullText,
                logoThree: AppConstants.nullText,
                signatoryImageOne: AppConstants.nullText,
                signatoryImageTwo: AppConstants.nullText,
                signatoryImageThree: null
            };

            let params = [];
            if (basicDetailsData.thumbnailImage?.size) params.push({ file: basicDetailsData.thumbnailImage, previousPath: selectedWorkshop.thumbnailImageRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.thumbnailImage.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForWeb?.size) params.push({ file: basicDetailsData.promotionImageForWeb, previousPath: selectedWorkshop.promotionImageWebRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForWeb.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForMobile?.size) params.push({ file: basicDetailsData.promotionImageForMobile, previousPath: selectedWorkshop.promotionImageMobileRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForMobile.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForTablet?.size) params.push({ file: basicDetailsData.promotionImageForTablet, previousPath: selectedWorkshop.promotionImageTabletRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForTablet.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionVideo?.size) params.push({ file: basicDetailsData.promotionVideo, previousPath: selectedWorkshop.promotionVideoRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionVideo.name, access: AppConstants.publicText });
            if (basicDetailsData.descriptionDocument?.size) params.push({ file: basicDetailsData.descriptionDocument, previousPath: selectedWorkshop.descriptionDocumentRelativePath, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.descriptionDocument.name, access: AppConstants.publicText });
            if (certificateData.logoOne?.size) params.push({ file: certificateData.logoOne, previousPath: selectedWorkshop.certificateLogoOneRelativePath, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.logoOne.name });
            if (certificateData.logoTwo?.size) params.push({ file: certificateData.logoTwo, previousPath: selectedWorkshop.certificateLogoTwoRelativePath, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.logoTwo.name });
            if (certificateData.logoThree?.size) params.push({ file: certificateData.logoThree, previousPath: selectedWorkshop.certificateLogoThreeRelativePath, folder: AppConstants.certifworkshopCertificateUploadFoldericateUploadFolder, id: certificateFromLabels.logoThree.name });
            if (certificateData.signatoryImageOne?.size) params.push({ file: certificateData.signatoryImageOne, previousPath: selectedWorkshop.certificateSignatoryOneImageRelativePath, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageOne.name });
            if (certificateData.signatoryImageTwo?.size) params.push({ file: certificateData.signatoryImageTwo, previousPath: selectedWorkshop.certificateSignatoryTwoImageRelativePath, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageTwo.name });
            if (certificateData.signatoryImageThree?.size) params.push({ file: certificateData.signatoryImageThree, previousPath: selectedWorkshop.certificateSignatoryThreeImageRelativePath, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageThree.name });

            for (let i = 0; i < params.length; i++) {
                let { file, previousPath, folder, id } = params[i];
                let path;
                let ext = file.name.split(".").pop();
                if (previousPath) {
                    let folderUrl = previousPath.substr(0, previousPath.lastIndexOf("\/") + 1);
                    path = `${folderUrl}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                } else {
                    path = `${AppConstants.workshopsUploadPath}${AppConstants.forwardSlash}${userDetails.userId}${AppConstants.forwardSlash}${timestamp}${AppConstants.forwardSlash}${folder}${AppConstants.forwardSlash}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                }
                let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline, AppConstants.s3ContentTypeOctetStream);
                if (!(response instanceof Error)) {
                    filePaths[id] = response.objectKey;
                } else {
                    return AppConstants.nullText;
                }
            }

            return filePaths;
        } catch {
            return AppConstants.nullText;
        }
    };

    const submitFormDetails = async () => {
        try {
            const formValid = checkFormValidity();
            const certificateValid = checkCertificateValidity();
            if (formValid && certificateValid) {
                setShowSpinner(AppConstants.trueText);

                const filePaths = await uploadFiles();
                if (filePaths === AppConstants.nullText) {
                    throw new Error(ErrorMessages.failedWorkshopUpdate);
                }

                const url = AppConstants.updateWorkshopDetailsAPI;
                let data = {
                    workshopDetails: buildBasicDetailsProps(filePaths),
                    workshopFaqs: faqData?.map(faq => {
                        return {
                            uniqueId: faq.uniqueId,
                            question: faq.question,
                            answer: faq.answer
                        }
                    }),
                    workshopCertificateDetails: buildCertificateProps(filePaths),
                    locale: DateTimeUtil.getPreferredTimeZoneForUser()
                }

                const response = await AuthorizedDataService.postRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    if (response.ok && response.status === 200) {
                        const text = await response.text();
                        const link = `${process.env.REACT_APP_FRONT_END_URL}${text}`;
                        setWorkshopLink(link);
                        setShowSpinner(AppConstants.falseText);
                        setModalTypeError(AppConstants.falseText);
                        setShowModal(AppConstants.trueText);
                        setModalMessage(SuccessMessage.workshopUpdateSuccessful);
                        setShowSpinner(AppConstants.falseText);
                    } else {
                        setOpen(new Array(stages).fill(AppConstants.falseText));
                        scrollToTop();
                        setWorkshopLink(AppConstants.emptyString);
                        setOpen(new Array(stages).fill(AppConstants.falseText));
                        setShowModal(AppConstants.trueText);
                        setModalTypeError(AppConstants.trueText);
                        setModalMessage(ErrorMessages.failedWorkshopUpdate)
                        setShowSpinner(AppConstants.falseText);
                    }
                } else {
                    setOpen(new Array(stages).fill(AppConstants.falseText));
                    scrollToTop();
                    setWorkshopLink(AppConstants.emptyString);
                    setOpen(new Array(stages).fill(AppConstants.falseText));
                    setShowModal(AppConstants.trueText);
                    setModalTypeError(AppConstants.trueText);
                    setModalMessage(ErrorMessages.failedWorkshopUpdate)
                    setShowSpinner(AppConstants.falseText);
                }
            } else {
                scrollToTop();
                if (!formValid) {
                    setOpen([AppConstants.trueText, AppConstants.falseText, AppConstants.falseText]);
                    if (formErrors.length > 0) {
                        manageErrors(formErrors[0]);
                    } else {
                        manageErrors(ErrorMessages.addWorkshopDetailsError);
                    }
                } if (!certificateValid) {
                    setOpen([AppConstants.falseText, AppConstants.falseText, AppConstants.trueText]);
                    let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
                    for (let i = 0; i < certificateErrorMessages.length; i++) {
                        if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                            manageErrors(certificateErrorMessages[i]);
                            return AppConstants.nullText;
                        }
                    }
                    if (!certificateData.logoUpdated) {
                        manageErrors(ErrorMessages.atleastOneLogoError);
                    } else if (!certificateData.messageUpdated) {
                        manageErrors(ErrorMessages.certificateMessageError)
                    } else if (certificateData.signatoryOneUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryOneError);
                    } else if (certificateData.signatoryTwoUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryTwoError);
                    } else if (certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryThreeError);
                    } else {
                        manageErrors(ErrorMessages.addOneSignatoryDetails);
                    }
                }
            }
        } catch (error) {
            scrollToTop();
            setWorkshopLink(AppConstants.emptyString);
            setOpen(new Array(stages).fill(AppConstants.falseText))
            setShowModal(AppConstants.trueText);
            setModalTypeError(AppConstants.trueText);
            setModalMessage(ErrorMessages.failedWorkshopUpdate)
            setShowSpinner(AppConstants.falseText);
        }
    };

    return (
        <div>
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div> : showDetailsError ? <div className={classes.errorContainer}>
                <FaArrowLeft onClick={() => props.onHideEdit()} className={classes.goBackButton} />
                <ErrorSuccessAlertMessage message={ErrorMessages.fetchWorkshopDetailsError} varient={AppConstants.alertVarient[1]} />
            </div> :
                <div className={classes.createCompContainer}>
                    <IoMdArrowRoundBack className={classes.backButton} onClick={props.onHideEdit} />
                    <div className={classes.infoComponents}>
                        {
                            stages.map((stage, idx) => {
                                return (<div key={idx} className={classes.collapseContainer}>
                                    <div className={classes.headerSection}>
                                        <div className={classes.heading}>
                                            {stage}
                                        </div>
                                        <div className={classes.collapseIcons}>
                                            {!open[idx] && <IoIosArrowDown onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                            {open[idx] && <IoIosArrowUp onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                        </div>
                                    </div>
                                    <Collapse in={open[idx]}>
                                        <div id="competition-collapse-container">
                                            <div className={classes.collapseDiv}>
                                                {idx === 0 && <EditWorkshopDetails skills={skills} saveCurrentStep={saveCurrentStep} stage={stage} changeBasicFormDetailsData={changeBasicFormDetailsData} changeBasicDetailsFormError={changeBasicDetailsFormError} workshopData={selectedWorkshop} status={selectedWorkshop.workshopStatus} />}
                                                {idx === 1 && <EditFrequentlyAskedQuestions saveCurrentStep={saveCurrentStep} stage={stage} status={selectedWorkshop.workshopStatus} changeFaqData={changeFaqData} faqCollection={selectedWorkshop.workShopFaqs} activityType={AppConstants.workshopText} />}
                                                {idx === 2 && <EditCourseCertificate template={template} changeCertificateData={changeCertificateData} certificateData={selectedWorkshop} status={selectedWorkshop.workshopStatus} activityTitle={basicDetailsData.title} startDate={basicDetailsData.startDate} endDate={basicDetailsData.endDate} skills={skills} skillId={basicDetailsData.skill} activityType={AppConstants.workshopsText} stage={stage} saveCurrentStep={saveCurrentStep} />}
                                                {idx === 3 && <EditAdvancedWorkshopSettings cost={basicDetailsData.cost} changeAdvancedSettings={changeAdvancedSettings} selectedWorkshopData={selectedWorkshop} />}
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>);
                            })
                        }
                        <div className={classes.createButton}>
                            {<Button disabled={showSpinner} onClick={submitFormDetails} type="submit" className={classes.createComptitionButton}>{showSpinner ? <BiLoaderAlt className="spinner" /> : "Submit"}</Button>}
                        </div>
                    </div>

                    <ToastContainer
                        className="p-3"
                        position='middle-center'>
                        <Toast show={showToast}
                            onClose={() => setShowToast(false)} bg={toastBackground}
                            animation delay={AppConstants.toastDelay} autohide={false}>
                            <Toast.Header className={toastClass.toastHeader}>
                                <strong className="me-auto">Error</strong>
                            </Toast.Header>
                            <Toast.Body className="text-white">
                                {toastBody} <br></br>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>

                    <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                        <Modal.Body className={modalClass.modalBody}>
                            {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                            {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                            <div className={modalClass.modalAlertText}>
                                {modalMessage}
                            </div>
                            <div className={modalClass.modalAlertText}>
                                {workshopLink && <a href={workshopLink} className={classes.shareLink} target="_blank" rel="noreferrer">Share workshop link</a>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className={modalClass.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>}
        </div>
    )
}