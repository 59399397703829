import { useEffect } from "react";
import { AppConstants } from "../constants/AppConstants";

const useWarnOnLeave = (shouldWarn) => {
    useEffect(() => {
        if (!shouldWarn) return;

        const handleBeforeUnload = (e) => {
            const confirmationMessage = AppConstants.pageReloadWarningMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [shouldWarn]);
};

export default useWarnOnLeave;
