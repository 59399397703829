import React, { useState, useEffect } from 'react';
import { FaCalendarAlt, FaChild, FaRegCalendarTimes, FaBuilding } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi';
import { SiGoogleclassroom } from 'react-icons/si';
import { BsFillStopwatchFill } from 'react-icons/bs';
import { MdGroupAdd } from "react-icons/md";
import { RiGitRepositoryPrivateFill, RiTeamFill } from 'react-icons/ri';
import { DateTime } from "luxon";
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import classes from './RightCompetitionDetailsSection.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { Button, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Auth from '../../utils/Auth';
import { EnrolmentModal } from '../modal/EnrolmentModal';
import { PaymentsUtil } from '../../utils/PaymentsUtil';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { BiWallet } from 'react-icons/bi';
import { PostStartEnrolmentModal } from '../modal/PostStartEnrolmentModal';
import { PayOfflineConfirmationModal } from '../modal/PayOfflineConfirmationModal';
import { useEnrolment } from '../../hooks/useEnrolment';
import useWarnOnLeave from '../../hooks/useWarnOnLeave';
import { TeamEnrolmentForm } from '../formControls/teamEnrolmentForm/TeamEnrolmentForm';
import { EnrolInCompetition } from '../studentPageComponents/EnrolInCompetition';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';

export const RightCompetitionDetailsSection = (props) => {
    const [competitionDetailsObj, setCompetitionDetailsObj] = useState(props.competitionData);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [signupTimerFlag, setSignupTimerFlag] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [isEnrolButtonClickedOnce, setIsEnrolledButtonClickedOnce] = useState(AppConstants.falseText);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [showTeamEnrolmentForm, setShowTeamEnrolmentForm] = useState(AppConstants.falseText);
    const [userSignupDone, setUserSignupDone] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);

    useEffect(() => {
        const openSignupModalAfterTimer = (isEnrolButtonClickedOnce) => {
            if (!isEnrolButtonClickedOnce) {
                setShowModal(AppConstants.trueText);
                setMessage(ErrorMessages.enrollmentUserNotLoggedInError)
                setSignupTimerFlag(AppConstants.trueText);
            }
        }
        if (!Auth.isLogin() && competitionDetailsObj.competitionId && DateTime.fromISO(competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone) && props.signupTimerDuration) {
            let timer = setTimeout(openSignupModalAfterTimer, props.signupTimerDuration * 1000, isEnrolButtonClickedOnce);
            return () => { clearTimeout(timer) };
        }

    }, [competitionDetailsObj, props.signupTimerDuration, isEnrolButtonClickedOnce]);
    const seatsFilledConfirmation = async (uniqueId, teamsLimit) => {
        const url = `${AppConstants.checkTeamEnrolmentEligibilityAPI}?competitionUniqueId=${competitionDetailsObj.competitionId}`;
        const response = await AuthorizedDataService.getRequestWithResponseCode(url, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            if (response && response.status === 500) {
                const data = await response.json();
                setResponses({
                    modalTypeError: AppConstants.trueText,
                    message: EnrolInCompetition.getEnrolmentStatusForTeamCompetitions({ ...data, message: 0 }, null),
                    paymentStatus: AppConstants.emptyString,
                    showModal: AppConstants.trueText,
                    showRazorpayPopup: AppConstants.falseText,
                    shouldWarn: AppConstants.falseText,
                    networkError: AppConstants.falseText
                });
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
    const confirmBeforeEnrolment = async () => {
        setIsEnrolledButtonClickedOnce(AppConstants.trueText);
        setConfirmationPopupActivityDetails({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName, creatorRole: competitionDetailsObj.userRole, type: AppConstants.learningActivitiesTypes.competition, isTeamEnrolmentEnabled: competitionDetailsObj.isTeamEnrolmentEnabled, cost: competitionDetailsObj.competitionCost, teamParticipantsLimit: competitionDetailsObj.teamParticipantLimit, teamsLimit: competitionDetailsObj.teamsLimit });
        if (Auth.isLogin() && ((Auth.getUserRole() === AppConstants.userRoleStudent && competitionDetailsObj.isTeamEnrolmentEnabled !== AppConstants.yText) || (Auth.getUserRole() === AppConstants.userRoleTeam && competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.yText))) {
            if (competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.yText) {
                let teamEligibility = await seatsFilledConfirmation(competitionDetailsObj.competitionStringId, competitionDetailsObj.teamsLimit);
                if (!teamEligibility) {
                    return;
                }
            }
            const startDateTime = DateTime.fromISO(competitionDetailsObj.competitionUTCStartTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (competitionDetailsObj.payOfflineFlag === AppConstants.yText && Auth.getUserRole() === AppConstants.userRoleStudent) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId, competitionDetailsObj.isTeamEnrolmentEnabled);
                }
            }
        } else {
            enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId, competitionDetailsObj.isTeamEnrolmentEnabled);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId, competitionDetailsObj.isTeamEnrolmentEnabled);
            }
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, competitionDetailsObj.isTeamEnrolmentEnabled);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const setResponses = (response) => {
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const handleShowTeamEnrolmentForm = () => {
        if (!Auth.isLogin()) {
            setResponses({
                modalTypeError: AppConstants.trueText,
                message: ErrorMessages.pleaseLogInToEnrolError,
                paymentStatus: AppConstants.emptyString,
                showModal: AppConstants.trueText,
                showRazorpayPopup: AppConstants.falseText,
                shouldWarn: AppConstants.falseText,
                networkError: AppConstants.falseText
            });
        } else {
            setShowModal(AppConstants.falseText);
            setShowTeamEnrolmentForm(AppConstants.trueText);
        }
    }

    const enrolHere = async (id, title, uniqueId, isTeamEnrolmentEnabled, status) => {
        const cost = PaymentsUtil.calculateCost({ cost: competitionDetailsObj.competitionCost, discount: 0 });
        if (Auth.isLogin() && ((Auth.getUserRole() !== AppConstants.userRoleStudent && isTeamEnrolmentEnabled !== AppConstants.yText) || (Auth.getUserRole() !== AppConstants.userRoleTeam && isTeamEnrolmentEnabled === AppConstants.yText))) {
            setMessage(isTeamEnrolmentEnabled === AppConstants.yText ? ErrorMessages.onlyTeamEnrolmentsAllowedError : ErrorMessages.enrollmentUserNotStudentError);
            setModalTypeError(AppConstants.trueText);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        };


        if (isTeamEnrolmentEnabled === AppConstants.yText) {
            handleShowTeamEnrolmentForm();
            return;
        } else {
            let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);
            setResponses({ ...response, isTeamEnrolmentEnabled });
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolHere(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, competitionDetailsObj.isTeamEnrolmentEnabled, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, confirmationPopupActivityDetails.isTeamEnrolmentEnabled, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleModalClose = () => {
        if (signupTimerFlag) {
            setSignupTimerFlag(AppConstants.falseText);
            setMessage(AppConstants.emptyString);
            setShowModal(AppConstants.falseText);
        } else {
            if (transactionId) {
                window.location.reload();
            } else {
                if (userLoggedInAndEnroled) {
                    window.location.reload();
                } else {
                    if (modalTypeError) {
                        setShowModal(false);
                    } else {
                        setShowModal(false);
                        window.location.reload();
                    }
                }
            }
        }
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const hideTeamEnrolmentForm = () => {
        setShowTeamEnrolmentForm(AppConstants.falseText);
    };

    return (
        <>
            <div className={classes.rightContainer}>

                {/* upcoming public competition with registration deadline not closed */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.disablePublicEnrolmentFlag === "N" && !competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{Auth.getUserRole() === AppConstants.userRoleStudent ? AppConstants.alreadyEnrolledinCompetitionText : "Your team is enrolled in this competition"}</p> : <p className={classes.competitionEnrollText}>{(competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.nText) ? AppConstants.wantToParticipateText : "Want to enrol a new team?"}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <Button disabled={competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText} className={classes.competitionEnrollButton} onClick={() => confirmBeforeEnrolment({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName, creatorRole: competitionDetailsObj.userRole })}>{competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrol Now"}</Button>}
                    </div>}

                {/* upcoming private competition with registration deadline not closed */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && !competitionDetailsObj.competitionLive && competitionDetailsObj.disablePublicEnrolmentFlag === "Y" && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{Auth.getUserRole() === AppConstants.userRoleStudent ? AppConstants.alreadyEnrolledinCompetitionText : "Your team is enrolled in this competition"}</p> : <p className={classes.competitionEnrollText}>{AppConstants.privateCompetitionText}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <span><RiGitRepositoryPrivateFill /></span>}
                    </div>}

                {/* upcoming competition with registration deadline closed */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && !competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) < new Date() && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{Auth.getUserRole() === AppConstants.userRoleStudent ? AppConstants.alreadyEnrolledinCompetitionText : "Your team is enrolled in this competition"}</p> : <p className={classes.competitionEnrollText}>{AppConstants.registrationAreClosedText}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <AiOutlineExclamationCircle className='text-danger fs-4' />}
                    </div>}

                {/* Ongoing  competition with registration deadline not closed */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>Competition is now <strong>LIVE &nbsp;</strong><div className={classes.liveDot}> &#x1F534;</div> </p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>{(competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.nText) ? AppConstants.wantToParticipateText : "Want to enrol a new team?"}</p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <Button disabled={competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText} className={classes.competitionEnrollButton} onClick={() => confirmBeforeEnrolment({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorRole: competitionDetailsObj.userRole, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName })}>{competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrol Now"}</Button>}
                        {(Auth.isLogin() && (competitionDetailsObj.roleStudent || Auth.getUserRole() === AppConstants.userRoleTeam) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.onlineText && competitionDetailsObj.competitionVcLink !== null)}
                        {(Auth.isLogin() && (competitionDetailsObj.roleStudent || Auth.getUserRole() === AppConstants.userRoleTeam) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText) &&
                            <Button href={competitionDetailsObj.competitionGeoLocationLink} target={AppConstants.openInNewTab} className={classes.competitionEnrollButton}>Location <BiLinkExternal /></Button>}
                    </div>
                }

                {/* Ongoing  competition with registration deadline closed */}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) < new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>Competition is now <strong>LIVE &nbsp;</strong> <span className={classes.liveDot}> &#x1F534;</span></p>}
                        {(Auth.isLogin() && (competitionDetailsObj.roleStudent || Auth.getUserRole() === AppConstants.userRoleTeam) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText) &&
                            <Button href={competitionDetailsObj.competitionGeoLocationLink} target={AppConstants.openInNewTab} className={classes.competitionEnrollButton}>Location <BiLinkExternal /></Button>}

                        {!competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>{AppConstants.registrationAreClosedText}</p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <AiOutlineExclamationCircle className='text-danger fs-4' />}
                    </div>
                }
                {/* Past  competition*/}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime) <= new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                {/* Past  competition*/}
                {(competitionDetailsObj.competitionComplete) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && Auth.getUserRole() === AppConstants.userRoleTeam && competitionDetailsObj.hasStudentEnrolled && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date() && competitionDetailsObj.competitionComplete === AppConstants.falseText) && <div className={classes.competitionEnrollSection}>
                    {<p className={classes.competitionEnrollText}>{"Want to enrol a new team?"}</p>}
                    {<Button disabled={competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText} className={classes.competitionEnrollButton} onClick={() => confirmBeforeEnrolment({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName, creatorRole: competitionDetailsObj.userRole })}>{competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrol Now"}</Button>}
                </div>}
                <div className={classes.competationDetailsCard}>
                    <Row xs={1} sm={1} md={2} lg={1} xl={1}>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <BiWallet className={classes.detailsIcon} />
                                </div>
                                {competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.nText && <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Fee</p>
                                    <p className={classes.detailsSubHeading}>{PaymentsUtil.calculateCost({ cost: competitionDetailsObj.competitionCost, discount: 0 })}</p>
                                </div>}
                                {competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.yText && <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Fee</p>
                                    <p className={classes.detailsSubHeading}>{PaymentsUtil.calculateCost({ cost: competitionDetailsObj.competitionCost, discount: 0, teamSize: competitionDetailsObj.teamParticipantLimit, teamEnrolmentFlag: AppConstants.yText })}</p>
                                </div>}
                            </div>
                        </Col>
                        {/* <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaUsers className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Enrolments</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.noOfEnrolledParticipants}</p>
                                </div>
                            </div>
                        </Col> */}
                        {competitionDetailsObj.schoolName != null && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaBuilding className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>School</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.schoolName}</p>
                                </div>
                            </div>
                        </Col>}
                        {competitionDetailsObj.selectedClasses != null && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <SiGoogleclassroom className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Class</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.selectedClasses}</p>
                                </div>
                            </div>
                        </Col>}
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <BsFillStopwatchFill className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Registration Deadline</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaCalendarAlt className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Start Time</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaRegCalendarTimes className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>End Time</p>
                                    <p className={classes.detailsSubHeading}> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaChild className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Eligibility</p>
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>All age groups</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit} - {competitionDetailsObj.competitionMaxAgeLimit} Years</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit}{AppConstants.plusYearText}</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>{props.minimumAgeLimit} - {competitionDetailsObj.competitionMaxAgeLimit}{AppConstants.yearsText}</p>}
                                </div>
                            </div>
                        </Col>
                        {competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.yText && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <MdGroupAdd className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Team Participants Eligibility</p>
                                    <p className={classes.detailsSubHeading}>
                                        {competitionDetailsObj.teamParticipantLimit} {competitionDetailsObj.teamParticipantLimit === 1 ? "participant" : "participants"} per team
                                    </p>
                                </div>
                            </div>
                        </Col>}
                        {/* {competitionDetailsObj.isTeamEnrolmentEnabled === AppConstants.yText && competitionDetailsObj.teamParticipantLimit != null && competitionDetailsObj.teamParticipantLimit != 0 && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <RiTeamFill className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Maximum Teams Allowed</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.teamsLimit}</p>
                                </div>
                            </div>
                        </Col>} */}
                    </Row>
                    {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal signupTimerFlag={signupTimerFlag} onShow={handleModalShow} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} creatorName={competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry} teamEnrolmentEnabledFlag={competitionDetailsObj.isTeamEnrolmentEnabled} setUserSignupDone={setUserSignupDone}></EnrolmentModal>}
                    {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
                    {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
                    {showTeamEnrolmentForm && <TeamEnrolmentForm hideTeamEnrolmentForm={hideTeamEnrolmentForm} enrolmentDetails={confirmationPopupActivityDetails} userSignupDone={userSignupDone} />}
                </div>
                {competitionDetailsObj.competitionPromotionalAdImageOne !== null && <div className={classes.competitionAdsSection}>
                    <div>
                        <img src={competitionDetailsObj.competitionPromotionalAdImageOne} className={classes.competitionAdImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>
        </>
    );
}