import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { BiCalendarCheck, BiLoaderAlt, BiCalendarX } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import Moment from 'react-moment';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import 'moment-timezone';
import modalClass from "../CompetitionsCards.module.css";
import classes from "./StudentLearningActivityCourseCards.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import Auth from "../../../utils/Auth";
import { MdOutlineShareLocation } from "react-icons/md";

export const StudentLearningActivityWorkshopsCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardDetails, setCardDetails] = useState(props.cardDetails);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.myLearningCardCount);
    const [certificateSpinner, setCertificateSpinner] = useState(new Array(props.cardDetails?.length).fill(AppConstants.falseText));
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.workshopDefaultThumbnailImage}`;

    const onCloseModal = () => {
        setShowModal(AppConstants.falseText);
    };

    useEffect(() => {
        setCardDetails(props.cardDetails);
    }, [props.cardDetails])

    async function downloadCertificate(workshopData, index) {
        const userQrenciaId = Auth.getLoggedInUserDetails().userId;
        setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? AppConstants.trueText : state));
        const url = `${AppConstants.downloadWorkshopCertificateForStudentAPI}${workshopData.uniqueId}${AppConstants.studentQrenciaIdParam}${userQrenciaId}`;
        const name = `${workshopData.workshopTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined) {
            if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? AppConstants.falseText : state));
            } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                setShowModal(AppConstants.trueText);
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? AppConstants.falseText : state));
                setModalMessage(ErrorMessages.certificateNotAvailableError);
            } else {
                setShowModal(AppConstants.trueText);
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? AppConstants.falseText : state));
                setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            }
        } else {
            setShowModal(AppConstants.trueText);
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? AppConstants.falseText : state));
            setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={2} className="cardSection">
                {cardDetails?.length && cardDetails?.slice(0, cardCount).map((cardData, index) => (
                    <Col key={index}>
                        <div className={classes.card} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${cardData.skillCategorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.workshopSlug}`)}>
                            <div className={classes.imageContainer}>
                                <img variant="top" className={classes.cardImage} src={cardData.thumbnailImageAbsolutePath || defaultThumbnail} alt="Workshop thumbnail" />
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.cardTitle}>
                                    <Link className="cardLink" data-tooltip-id={`${cardData.workshopSlug}-title`}>{cardData.workshopTitle}</Link>
                                    <Tooltip className={classes.titleTooltipMessage} id={`${cardData.workshopSlug}-title`}><span></span>{cardData.workshopTitle}</Tooltip>
                                </div>

                                <p className={classes.activityTypeWorkshop}>{cardData.workshopModeOfDelivery}</p>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizerName}</div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> at  <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcStartDate.replace(' ', 'T') + 'Z'}</Moment>  {cardData.perferredTimeZone === AppConstants.utcTimezone && `(${cardData.perferredTimeZone})`}</span></div>
                                </div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> at  <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcEndDate.replace(' ', 'T') + 'Z'}</Moment>  {cardData.perferredTimeZone === AppConstants.utcTimezone && `(${cardData.perferredTimeZone})`}</span></div>
                                </div>
                                <div className={classes.iconSection}>
                                    {cardData.templateId !== 0 && cardData.templateId !== null && <div>
                                        {certificateSpinner[index] ? <BiLoaderAlt className="spinner" /> : <TbCertificate onClick={(e) => { stopPropagation(e); downloadCertificate(cardData, index) }} data-tooltip-place="top" data-tooltip-id={`${cardData.id}-certificate`} />}
                                    </div>}
                                    {cardData.workshopModeOfDelivery === AppConstants.workshopTypeOffline && <a onClick={stopPropagation} className={classes.performanceReportLink} href={cardData.geoLocation} target={AppConstants.openInNewTab}><MdOutlineShareLocation data-tooltip-place="top" data-tooltip-id={cardData.id} /></a>}
                                    <Tooltip className={classes.tooltipMessage} id={`${cardData.id}-certificate`}><span className={classes.tooltipMessage}>Download Certificate</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id={cardData.id}><span className={classes.tooltipMessage}>{cardData.address}</span></Tooltip>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.myLearningCardCount)}>Load more</button>}
                </Col>
            </Row>
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={AppConstants.falseText} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}