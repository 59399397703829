import { Tabs, Tab } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import './LearningActivitiesTabs.css';
import classes from './LearningActivitiesTabs.module.css';

export const LearningActivitiesTabs = (props) => {

    function selectLearningActitvity(e) {
        props.onSelection(e);
    };

    return (
        <div className="tabsContainer">
            <Tabs
                defaultActiveKey="All"
                id="learningActitvitiesTabs"
                className={classes.skillCategoryTab}
                transition={false}
                onSelect={(e) => selectLearningActitvity(e)}
            >
                <Tab eventKey={AppConstants.tabAllText} title={AppConstants.tabAllText}></Tab>
                <Tab eventKey={AppConstants.tabCompetitionValueText} title={AppConstants.tabCompetitionTitleText}></Tab>
                <Tab eventKey={AppConstants.tabCoursesValueText} title={AppConstants.tabCoursesTitleText}></Tab>
                <Tab eventKey={AppConstants.tabWorkshopValueText} title={AppConstants.tabWorkshopTitleText}></Tab>
            </Tabs>
        </div>
    );
}