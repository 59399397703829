import React from 'react';
import { TopNavigationWithoutLogin } from '../../layout/ExtendedPagesLayout/TopNavigationWithoutLogin';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { ExpertMarkParticipantsScreen } from '../../component/competitionJudgingComponents/ExpertMarkParticipantsScreen';
import { useLoaderData } from 'react-router-dom';

export const ExpertMarkParticipantsOnJudgingScreenPage = () => {
    const [footerDetails, judgingScreenDesktopBannerContent, judgingScreenTabletBannerContent, judgingScreenMobileBannerContent] = useLoaderData();


    return (
        <>
            <TopNavigationWithoutLogin />
            <ExpertMarkParticipantsScreen desktopBannerContent={judgingScreenDesktopBannerContent} tabletBannerContent={judgingScreenTabletBannerContent} mobileBannerContent={judgingScreenMobileBannerContent} />
            <LandingPageFooter landingPageData={footerDetails} />
        </>
    )
}

