import { Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AppConstants } from '../../constants/AppConstants';
import classes from './ViewSchoolLearningActivityStatistics.module.css'
import { MathUtil } from '../../utils/MathUtil';

export const ViewSchoolLearningActivitiyStatistics = () => {
    const [learningActivityStats, setLearningActivityStats] = useState([]);

    useEffect(() => {
        const fetchLearningActivityStatics = async () => {
            const url = AppConstants.agencyMonitoringDashboardStatisticsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setLearningActivityStats(response);
            } else {
                setLearningActivityStats([]);
            }
        }

        fetchLearningActivityStatics();
    }, []);

    return (
        <div className={classes.dashboardCards}>
            <Card className={`${classes.countCards} ${classes.competitionCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Competitions</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.competitions)}</Card.Text>
                </Card.Body>
            </Card>
            {<Card className={`${classes.countCards} ${classes.teachersCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Teachers</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.mappedTeachers)}</Card.Text>

                </Card.Body>
            </Card>}
            {<Card className={`${classes.countCards} ${classes.studentCard}`}>
                <Card.Body>
                    <Card.Title className={classes.cardTitle}>Students</Card.Title>
                    <Card.Text className={`${classes.cardCount} ${classes.count}`}>{MathUtil.formatNumberToLocaleString(learningActivityStats.mappedStudents)}</Card.Text>
                </Card.Body>
            </Card>}
        </div>
    )
}