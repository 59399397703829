import { useEffect, useState } from "react";
import { AppConstants } from "../../../../constants/AppConstants";
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import classes from "../MarkSpecificCourseAttendance.module.css";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { DateTimeUtil } from "../../../../utils/DateTimeUtil";
import { SuccessMessage } from "../../../../constants/SuccessMessage";
import { ErrorMessages } from "../../../../constants/ErrorMessages";
import modalClass from "../../../cards/CompetitionsCards.module.css";
import { ErrorSuccessAlertMessage } from "../../../errorSuccessMessage/ErrorSuccessAlertMessage";

export const CounterBasedTrackingScreen = ({ selectedCourse, courseUniqueId }) => {
    const subscriptionStatus = AppConstants.subscriptionStatus;
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [activeLearners, setActiveLearners] = useState([]);
    const [expiredLearners, setExpiredLearners] = useState([]);
    const [subscriptionReminderSpinner, setSubscriptionReminderSpinner] = useState([]);
    const [saveAttendanceSpinner, setSaveAttendanceSpinner] = useState(AppConstants.falseText);
    const [subscriptionReminderEmailMessage, setSubscriptionReminderEmailMessage] = useState(AppConstants.emptyString);
    const [subscriptionAlertVarient, setSubscriptionAlertVarient] = useState(AppConstants.emptyString);
    const [showAttendanceButton, setShowAttendanceButton] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [saveAttendanceMessage, setSaveAttendanceMessage] = useState(AppConstants.emptyString);
    const [showWarningMessage, setShowWarningMessage] = useState(AppConstants.falseText);


    const columnDefs = [
        {
            headerName: "Learner Id",
            field: "learnerId",
            maxWidth: 100,
            tooltipValueGetter: ({ data }) => data.learnerId,
            valueGetter: ({ data }) => {
                return data.learnerId
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "name",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerName,
            valueGetter: ({ data }) => {
                return data.learnerName
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Phone",
            field: "mobileNumber",
            tooltipField: "mobileNumber",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.mobileNumber,
            valueGetter: ({ data }) => {
                return data.mobileNumber;
            },
        },
    ];

    const expiredColumnDefs = [
        {
            headerName: "Learner Id",
            field: "learnerId",
            maxWidth: 100,
            tooltipValueGetter: ({ data }) => data.learnerId,
            valueGetter: ({ data }) => {
                return data.learnerId
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "name",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerName,
            valueGetter: ({ data }) => {
                return data.learnerName
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Phone",
            field: "mobileNumber",
            tooltipField: "mobileNumber",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.mobileNumber,
            valueGetter: ({ data }) => {
                return data.mobileNumber;
            },
        },
        {
            headerName: "Last Subscribed On",
            field: "subscriptionStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
    ];

    const reminderColumnDefs = [{
        headerName: "Action",
        field: "action",
        cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
            <Button disabled={subscriptionReminderSpinner[params.rowIndex]} onClick={() => sendSubscriptionReminder(params.data.learnerEmail, params.rowIndex)} className={`${classes.reminderButton}`}>Reminder {subscriptionReminderSpinner[params.rowIndex] && <BiLoaderAlt className="spinner" />}</Button>
        </div>,
        minWidth: 200,
    }];

    const getActiveColumnDefs = () => {
        let remainingClasses = [{
            headerName: "Remaining Classes",
            field: "remainingClasses",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <span onClick={() => updateTrackingStatusForId(params.data.learnerId, -1, subscriptionStatus.active)} className={classes.actionButton}>-</span>
                <span className={classes.remainingClassCounterText}>&nbsp;{params.data.remainingClasses}&nbsp;</span>
                <span onClick={() => updateTrackingStatusForId(params.data.learnerId, 1, subscriptionStatus.active)} className={classes.actionButton} >+</span>
            </div >,
            minWidth: 200,
        }];

        const validityColumnDefs = [{
            headerName: "Class Validity",
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A",
            valueGetter: ({ data }) => {
                return data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A";
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            minWidth: 200,
        }];

        if (selectedCourse.validity > 0) {
            return [...columnDefs, ...validityColumnDefs, ...remainingClasses];
        } else {
            return [...columnDefs, ...remainingClasses]
        }
    }

    const getExpiredColumnDefs = () => {
        const remainingClasses = [{
            headerName: "Remaining Classes",
            field: "remainingClasses",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <span onClick={() => updateTrackingStatusForId(params.data.learnerId, -1, subscriptionStatus.expired)} className={classes.actionButton}>-</span>
                <span className={classes.remainingClassCounterText}>&nbsp;{params.data.remainingClasses}&nbsp;</span>
                <span onClick={() => updateTrackingStatusForId(params.data.learnerId, 1, subscriptionStatus.expired)} className={classes.actionButton} >+</span>

            </div >,
            minWidth: 200,
        }];

        const validityColumnDefs = [{
            headerName: selectedCourse.validity > 0 ? "Class Validity" : "Subscription End Date",
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A",
            valueGetter: ({ data }) => {
                return data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A";
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            minWidth: 200,
        }];
        return [...expiredColumnDefs, ...validityColumnDefs, ...remainingClasses]
    }

    useEffect(() => {
        if (selectedCourse.uniqueId) {
            fetchClassTrackingStatus();
        }
    }, [selectedCourse]);

    const fetchClassTrackingStatus = async () => {
        const url = `${AppConstants.getStudentAttendanceDetailsAPI}${courseUniqueId}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setShowWarningMessage(AppConstants.trueText);
            const expiredLearnerDetails = response.filter(learner => learner.subscriptionStatus === AppConstants.subscriptionStatus.expired).map(learner => ({ ...learner, remainingClasses: learner.previousRemainingClasses, previousAvailedClasses: learner.availedClasses }));
            setActiveLearners(response.filter(learner => learner.subscriptionStatus === AppConstants.subscriptionStatus.active).map(learner => ({ ...learner, remainingClasses: learner.previousRemainingClasses, previousAvailedClasses: learner.availedClasses })));
            setExpiredLearners(expiredLearnerDetails);
            setSubscriptionReminderSpinner(new Array(expiredLearnerDetails.length).fill(AppConstants.falseText));
            setShowAttendanceButton(AppConstants.trueText);
        } else {
            setShowWarningMessage(AppConstants.trueText);
            setShowAttendanceButton(AppConstants.falseText);
            setActiveLearners([]);
            setExpiredLearners([]);
            setSubscriptionReminderSpinner([]);
        }
    };

    const saveTrackingDetails = async () => {
        setSaveAttendanceSpinner(AppConstants.trueText);
        let url = AppConstants.saveClassTrackingDetailsAPI;
        let trackingDetails = [...activeLearners, ...expiredLearners];
        const data = {
            "courseUniqueId": courseUniqueId,
            "locale": timeZone,
            "trackingType": selectedCourse.trackingType,
            "attendanceDetails": trackingDetails.map(participant => ({
                availedClasses: participant.availedClasses,
                remainingClasses: participant.remainingClasses,
                learnerEmail: participant.learnerEmail,
                learnerId: participant.learnerId,
                previousRemainingClasses: participant.previousRemainingClasses,
                subscriptionEndDate: participant.subscriptionEndDate
            }))
        };
        const response = await AuthorizedDataService.putRequest(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );


        if (response) {
            setSaveAttendanceSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
            setSaveAttendanceMessage(SuccessMessage.classesTrackedSuccessFullyMessage);
            setModalTypeError(AppConstants.falseText);
            fetchClassTrackingStatus();
        } else {
            setSaveAttendanceSpinner(AppConstants.falseText);
            setSaveAttendanceMessage(ErrorMessages.studentAttendanceUpdateError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        }
    };

    const updateTrackingStatusForAll = (action, status) => {
        setActiveLearners(activeLearners.map(learner => ({ ...learner, remainingClasses: status === AppConstants.subscriptionStatus.active ? (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses ? learner.remainingClasses + action : learner.remainingClasses) : learner.remainingClasses, availedClasses: status === AppConstants.subscriptionStatus.active ? (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses ? learner.availedClasses - action : learner.availedClasses) : learner.availedClasses })));
        setExpiredLearners(expiredLearners.map(learner => ({ ...learner, remainingClasses: status === AppConstants.subscriptionStatus.expired ? (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses ? learner.remainingClasses + action : learner.remainingClasses) : learner.remainingClasses, availedClasses: status === AppConstants.subscriptionStatus.expired ? (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses ? learner.availedClasses - action : learner.availedClasses) : learner.availedClasses })));
    };

    const updateTrackingStatusForId = (learnerId, action, status) => {
        if (status === subscriptionStatus.active) {
            setActiveLearners(activeLearners.map((learner) => {
                if (learner.learnerId === learnerId) {
                    if (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses) {
                        learner.remainingClasses = (learner.remainingClasses + action);
                        learner.availedClasses = (learner.availedClasses - action)
                    }
                }
                return learner;
            }));
        } else {
            setExpiredLearners(expiredLearners.map((learner) => {
                if (learner.learnerId === learnerId) {
                    if (learner.remainingClasses + action <= learner.previousAvailedClasses + learner.previousRemainingClasses) {
                        learner.remainingClasses = (learner.remainingClasses + action);
                        learner.availedClasses = (learner.availedClasses - action)
                    }
                }
                return learner;
            }));
        }
    };

    const onCloseModal = () => {
        setShowModal(AppConstants.falseText);
        setSaveAttendanceMessage(AppConstants.emptyString);
        setModalTypeError(AppConstants.emptyString);
    };

    const sendSubscriptionReminder = async (learnerEmail, index) => {
        try {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            const url = `${AppConstants.sendReminderEmailToRenewFlexiCourseSubscriptionAPI}${selectedCourse.uniqueId}&learnerEmail=${encodeURIComponent(learnerEmail)}`;
            const response = await AuthorizedDataService.post(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setSubscriptionAlertVarient(AppConstants.alertVarient[0]);
                    setSubscriptionReminderEmailMessage(`${SuccessMessage.courseSubscriptionReminderSentSuccessfully} ${learnerEmail}`);
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else {
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                    setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                let timer = setTimeout(() => {
                    setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
            setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
            let timer = setTimeout(() => {
                setSubscriptionReminderEmailMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    return (
        <div>
            {(activeLearners.length === 0 && expiredLearners.length === 0 && showWarningMessage) && <Row className={`mb-4 ${classes.warningSection}`}>
                <div className={classes.noLearnersWarningMessage}><p><AiOutlineExclamationCircle />There are no enrolled learners in this course.</p></div>
            </Row>}
            {activeLearners.length > 0 && <Row className="mt-4 mb-2">
                <div className={classes.markButtonRow}>
                    <h1 className={classes.learnerDetailsHeading}>Active Enrolments</h1>
                    {showAttendanceButton && <Row>
                        <div className={classes.markAllButtonSection}>
                            <div onClick={() => updateTrackingStatusForAll(-1, AppConstants.subscriptionStatus.active)} className={`${classes.markAllButton} ${classes.absentButton}`}>(-) All</div>
                            <div onClick={() => updateTrackingStatusForAll(1, AppConstants.subscriptionStatus.active)} className={`${classes.markAllButton} ${classes.presentButton}`}>(+) All</div>
                            <Button disabled={saveAttendanceSpinner} onClick={saveTrackingDetails} className={classes.saveAttendaceButton}>Save {saveAttendanceSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </div>
                    </Row>}
                </div>
            </Row>}
            {activeLearners.length > 0 && <div className={classes.showGridSection}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={getActiveColumnDefs()}
                                rowData={activeLearners}
                                pagination={true}
                                paginationPageSize={20}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>}

            {activeLearners.length > 0 && <div className={classes.showCardsSection}>
                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                    {activeLearners.map((participant, index) => {
                        return (
                            <Col>
                                <div className={classes.cardContainer}>
                                    <div className={classes.profileImageSection}>
                                        {participant.leanerImagePath ? <div className={classes.nameInitials}><img src={participant.leanerImagePath} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.nameInitials}>{participant.learnerNameInitials}</div>}
                                        <div>
                                            <p className={classes.learnerName}>{participant.learnerName}</p>
                                            <p className={classes.emailText}>QID: {participant.learnerId} | {participant.mobileNumber}</p>
                                        </div>
                                    </div>
                                    {participant.subscriptionStatus === subscriptionStatus.active && <p className={classes.classesLeftText}><span className={`${classes.statusSpan} ${classes.activeSpan}`}>{participant.remainingClasses} Classes Remaining</span></p>}
                                    {showAttendanceButton && <div className={classes.cardButtonSection}>
                                        <Button className={classes.counterCardButton} onClick={() => updateTrackingStatusForId(participant.learnerId, -1, AppConstants.subscriptionStatus.active)}>(-) Class</Button>
                                        <Button className={classes.counterCardButton} onClick={() => updateTrackingStatusForId(participant.learnerId, 1, AppConstants.subscriptionStatus.active)}>(+) Class</Button>
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>}

            {expiredLearners.length > 0 && <Row className="mt-4 mb-2">
                <div className={classes.markButtonRow}>
                    <h1 className={classes.learnerDetailsHeading}>Expired Enrolments</h1>
                    {showAttendanceButton && activeLearners.length === 0 && <Row>
                        <div className={classes.markAllButtonSection}>
                            <div onClick={() => updateTrackingStatusForAll(-1, AppConstants.subscriptionStatus.expired)} className={`${classes.markAllButton} ${classes.absentButton}`}> (-) All</div>
                            <div onClick={() => updateTrackingStatusForAll(1, AppConstants.subscriptionStatus.expired)} className={`${classes.markAllButton} ${classes.presentButton}`}> (+) All</div>
                            <Button disabled={saveAttendanceSpinner} onClick={saveTrackingDetails} className={classes.saveAttendaceButton}>Save {saveAttendanceSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </div>
                    </Row>}
                </div>
            </Row>}

            {expiredLearners.length > 0 && <div className={classes.showGridSection}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={(selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText) ? [...getExpiredColumnDefs(), ...reminderColumnDefs] : getExpiredColumnDefs()}
                                rowData={expiredLearners}
                                pagination={true}
                                paginationPageSize={20}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>}

            {expiredLearners.length > 0 && <div className={classes.showCardsSection}>
                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                    {expiredLearners.map((participant, index) => {
                        return (
                            <Col>
                                <div className={classes.cardContainer}>
                                    <div className={classes.profileImageSection}>
                                        {participant.leanerImagePath ? <div className={classes.nameInitials}><img src={participant.leanerImagePath} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.nameInitials}>{participant.learnerNameInitials}</div>}
                                        <div>
                                            <p className={classes.learnerName}>{participant.learnerName}</p>
                                            <p className={classes.emailText}>QID: {participant.learnerId} | {participant.mobileNumber}</p>
                                        </div>
                                    </div>
                                    {participant.subscriptionStatus === subscriptionStatus.expired && <p className={classes.classesLeftText}><span className={`${classes.statusSpan} ${classes.expiredSpan}`}>{participant.remainingClasses} Classes Remaining</span></p>}
                                    <p className={classes.lastSubscribedText}>Last Subscribed On: {moment.utc(participant.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} {timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}</p>
                                    {participant.subscriptionEndDate && selectedCourse.validity > 0 && <p className={classes.lastSubscribedText}>Valid Till: {moment.utc(participant.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} {timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}</p>}
                                    {participant.subscriptionEndDate && !selectedCourse.validity && <p className={classes.lastSubscribedText}>End: {moment.utc(participant.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} {timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}</p>}
                                    {showAttendanceButton && <div className={classes.cardButtonSection}>
                                        <Button className={classes.counterCardButton} onClick={() => updateTrackingStatusForId(participant.learnerId, -1, AppConstants.subscriptionStatus.expired)}>(-) Class</Button>
                                        <Button className={classes.counterCardButton} onClick={() => updateTrackingStatusForId(participant.learnerId, 1, AppConstants.subscriptionStatus.expired)}>(+) Class</Button>
                                        {(participant.subscriptionStatus === subscriptionStatus.expired && selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText) && <Button disabled={subscriptionReminderSpinner[index]} onClick={() => sendSubscriptionReminder(participant.learnerEmail, index)} className={`${classes.reminderButtonCard}`}>Reminder {subscriptionReminderSpinner[index] && <BiLoaderAlt className="spinner" />}</Button>}
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>}
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {saveAttendanceMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            {subscriptionReminderEmailMessage && <ErrorSuccessAlertMessage message={subscriptionReminderEmailMessage} varient={subscriptionAlertVarient} />}
        </div>
    )
}