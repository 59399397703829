import React, { useState, useEffect } from "react";
import { Button, Collapse, Toast, ToastContainer, Modal } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { AddWorkshopDetails } from "../../component/createWorkshopComponent/addWorkshopDetails/AddWorkshopDetails.js";
import { AddFrequentlyAskedQuestions } from "../../component/createCourseComponent/addFrequentlyAskedQuestions/AddFrequentlyAskedQuestions";
import { AddWorkshopCertificate } from "../../component/createWorkshopComponent/addWorkshopCertificate/AddWorkshopCertificate.js";
import Auth from "../../utils/Auth";
import classes from "./ExpertCreateCompetition.module.css";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import toastClass from "../../component/createCourseComponent/createCourseToast.module.css";
import { SuccessMessage } from "../../constants/SuccessMessage";
import modalClass from "../../component/cards/CompetitionsCards.module.css"
import { DateTime } from "luxon";
import { CopyLearningActivityInput } from "../../component/copyLearningActivityInput/CopyLearningActivityInput.js";
import { AdvancedWorkshopSettings } from "../../component/createWorkshopComponent/advancedWorkshopSettings/AdvancedWorkshopSettings.js";

export const CreateWorkshopPage = () => {

    const stages = AppConstants.createWorkshopActions;
    const basicDetailsFormLabels = AppConstants.workshopDetailsFormLabels;
    const certificateFromLabels = AppConstants.courseCertificateLabels;
    const [showSpinner, setShowSpinner] = useState(AppConstants.falseText);
    const [open, setOpen] = useState([AppConstants.trueText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
    const [basicDetailsData, setBasicDetailsData] = useState({});
    const [basicDetailsErrors, setBasicDetailsErros] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [formErrors, setFormErrors] = useState([]);
    const [certificateData, setCertificateData] = useState({});
    const [toastBody, setToastBody] = useState(AppConstants.emptyString);
    const [showToast, setShowToast] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [workshopPageLink, setWorkshopPageLink] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [template, setTemplate] = useState({});
    const [skills, setSkills] = useState([]);
    const [selectedWorkshopDetails, setSelectedWorkshopDetails] = useState({});
    const [toastBackground, setToastBackground] = useState(AppConstants.alertVarient[1]);
    const [advancedSettingsData, setAdvancedSettingsData] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const fetchSkillsForExpert = async () => {
            const url = AppConstants.fetchExpertSkillsApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                // Sort the skills alphabetically based on skillName
                const sortedSkills = response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                );
                // Set the sorted skills array in the state
                setSkills(sortedSkills);
            }
        };

        async function fetchSkillsForAgency() {
            const url = AppConstants.getSkillsofAgencyAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                // Sort the skills alphabetically based on skillName
                setSkills(response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                ));
            }
        };

        if (Auth.getLoggedInUserDetails().userRole === AppConstants.userRoleAgency) {
            fetchSkillsForAgency();
        } else {
            fetchSkillsForExpert();
        }
    }, []);

    useEffect(() => {
        const getCertificateTemplate = async () => {
            const url = AppConstants.fetchCertificateTemplateApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setTemplate(response);
        }
        getCertificateTemplate();
    }, []);

    useEffect(() => {
        let errorValues = Object.values(basicDetailsErrors);
        setFormErrors(errorValues);

    }, [basicDetailsErrors, basicDetailsData.description]);

    // function to capture basic details
    const changeBasicFormDetailsData = (newData) => {
        setBasicDetailsData(newData);
    }

    // function to capture basic details  errors
    const changeBasicDetailsFormError = (errors) => {
        setBasicDetailsErros(errors);
    }

    // function to capture frequently asked questions
    const changeFaqData = (newData) => {
        setFaqData([...newData]);
    }

    const changeCertificateData = (newData) => {
        setCertificateData(newData);
    };

    const changeAdvancedSettings = (newData) => {
        setAdvancedSettingsData(newData);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
        if (workshopPageLink.length > 0) {
            window.location.reload();
        }
    };

    const saveCurrentStep = ({ step }) => {
        setOpen(prevState => prevState.map((state, i) => {
            if (i <= step) state = AppConstants.falseText;
            if (i === step + 1) state = AppConstants.trueText;
            return state;
        }));
        scrollToTop();
    };

    const formatRichTextContent = (content) => {
        return content.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, '');
    }

    const getDateObject = (date, time) => {
        if (!date) {
            return AppConstants.nullText;
        }
        const dateArr = date.split(AppConstants.hyphen);
        const timeArr = time.split(AppConstants.colon);
        const dateTime = DateTime.fromObject({
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2],
            hour: timeArr[0],
            minute: timeArr[1],
        }, { zone: timeZone });

        return dateTime;
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);
    }

    const buildBasicDetailsProps = (filePaths) => {
        let registration_close_time;
        if (basicDetailsData.registrationClosingDate !== AppConstants.emptyString && basicDetailsData.registrationClosingTime !== AppConstants.emptyString) {
            registration_close_time = formatDateTime(getDateObject(basicDetailsData.registrationClosingDate, basicDetailsData.registrationClosingTime));
        } else {
            // Setting default value of registration closing time to 1 minute before workshop end time
            let date = getDateObject(basicDetailsData.endDate, basicDetailsData.endTime);
            registration_close_time = formatDateTime(date.minus({ minutes: 1 })); // Subtracting 1 minute from workshop end time.
        }
        let workshopDetails = {
            workshopTitle: basicDetailsData.title.trim(),
            workshopDescription: formatRichTextContent(basicDetailsData.description),
            workshopDescriptionDocument: filePaths["Description-Document"] || AppConstants.nullText,
            workshopThumbnailImagePath: filePaths.thumbnailImage,
            workshopModeOfDelivery: basicDetailsData.modeOfDelivery,
            workshopAddress: basicDetailsData.modeOfDelivery === AppConstants.workshopTypeOffline ? basicDetailsData.address.replace(/\n/g, ' ')?.trim() : AppConstants.nullText,
            workshopGeoLocation: basicDetailsData.modeOfDelivery === AppConstants.workshopTypeOffline ? basicDetailsData.geoLocation?.trim() : AppConstants.nullText,
            workshopLink: basicDetailsData.modeOfDelivery === AppConstants.workshopTypeOnline ? basicDetailsData.workshopLink?.trim() : AppConstants.nullText,
            workshopDuration: basicDetailsData.durationInHours,
            workshopCost: basicDetailsData.cost === AppConstants.emptyString ? 0 : basicDetailsData.cost,
            workshopSpocName: basicDetailsData.expertName.trim() || AppConstants.nullText,
            workshopSpocEmail: basicDetailsData.expertEmail.trim() || AppConstants.nullText,
            workshopSpocMobileNumber: basicDetailsData.expertMobile || AppConstants.nullText,
            workshopStartDate: formatDateTime(getDateObject(basicDetailsData.startDate, basicDetailsData.startTime)),
            workshopEndDate: formatDateTime(getDateObject(basicDetailsData.endDate, basicDetailsData.endTime)),
            workshopRegistrationClosesOn: registration_close_time,
            skillId: skills.length === 1 ? skills[0].skillId : basicDetailsData.skill,
            workshopPromotionTabletImagePath: filePaths.promotionImageForTablet || AppConstants.nullText,
            workshopPromotionMobileImagePath: filePaths.promotionImageForMobile || AppConstants.nullText,
            workshopPromotionWebImagePath: filePaths.promotionImageForWeb,
            workshopPromotionVideoPath: filePaths.promotionVideo || AppConstants.nullText,
            payOfflineFlag: advancedSettingsData.enableOfflinePayment ? AppConstants.yText : AppConstants.nText
        }

        return workshopDetails;
    };

    // Function to build request payload object for workshop certificate
    const buildCertificateProps = (filePaths) => {
        let workshopCertificateDetails = {
            certificateMessage: certificateData.certificateMessage.trim() || AppConstants.nullText,
            certificateLogoOnePath: filePaths.logoOne || AppConstants.nullText,
            certificateSignatoryOneName: certificateData.signatoryOneName || AppConstants.nullText,
            certificateSignatoryOneDesignation: certificateData.signatoryOneDesignation || AppConstants.nullText,
            certificateSignatoryOneImagePath: filePaths.signatoryImageOne || AppConstants.nullText,
            certificateLogoTwoPath: filePaths.logoTwo || AppConstants.nullText,
            certificateSignatoryTwoName: certificateData.signatoryTwoName || AppConstants.nullText,
            certificateSignatoryTwoDesignation: certificateData.signatoryTwoDesignation || AppConstants.nullText,
            certificateSignatoryTwoImagePath: filePaths.signatoryImageTwo || AppConstants.nullText,
            certificateLogoThreePath: filePaths.logoThree || AppConstants.nullText,
            certificateSignatoryThreeName: certificateData.signatoryThreeName || AppConstants.nullText,
            certificateSignatoryThreeDesignation: certificateData.signatoryThreeDesignation || AppConstants.nullText,
            certificateSignatoryThreeImagePath: filePaths.signatoryImageThree || AppConstants.nullText,
            certificateSignatoryOneOrganization: certificateData.signatoryOneOrganization || AppConstants.nullText,
            certificateSignatoryTwoOrganization: certificateData.signatoryTwoOrganization || AppConstants.nullText,
            certificateSignatoryThreeOrganization: certificateData.signatoryThreeOrganization || AppConstants.nullText,
            templateId: template[2]?.id
        };

        return workshopCertificateDetails;
    }

    const uploadFiles = async () => {
        try {
            const timestamp = DateTimeUtil.fetchCurrentTimestamp();
            const userDetails = Auth.getLoggedInUserDetails();
            let filePaths = {
                thumbnailImage: AppConstants.nullText,
                promotionImageForMobile: AppConstants.nullText,
                promotionImageForTablet: AppConstants.nullText,
                promotionImageForWeb: AppConstants.nullText,
                promotionVideo: AppConstants.nullText,
                "Description-Document": AppConstants.nullText,
                logoOne: AppConstants.nullText,
                logoTwo: AppConstants.nullText,
                logoThree: AppConstants.nullText,
                signatoryImageOne: AppConstants.nullText,
                signatoryImageTwo: AppConstants.nullText,
                signatoryImageThree: AppConstants.nullText
            };
            // mandatory files
            let params = [];

            // other files
            if (basicDetailsData.thumbnailImage?.size) params.push({ file: basicDetailsData.thumbnailImage, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.thumbnailImage.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForWeb?.size) params.push({ file: basicDetailsData.promotionImageForWeb, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForWeb.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForMobile?.size) params.push({ file: basicDetailsData.promotionImageForMobile, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForMobile.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionImageForTablet?.size) params.push({ file: basicDetailsData.promotionImageForTablet, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionImageForTablet.name, access: AppConstants.publicText });
            if (basicDetailsData.promotionVideo?.size) params.push({ file: basicDetailsData.promotionVideo, folder: AppConstants.workshopDetailsFolder, id: basicDetailsFormLabels.promotionVideo.name, access: AppConstants.publicText });
            if (basicDetailsData.descriptionDocument?.size) params.push({ file: basicDetailsData.descriptionDocument, folder: AppConstants.workshopDetailsFolder, id: "Description-Document", access: AppConstants.publicText });
            if (certificateData.logoOne?.size) params.push({ file: certificateData.logoOne, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.logoOne.name });
            if (certificateData.logoTwo?.size) params.push({ file: certificateData.logoTwo, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.logoTwo.name });
            if (certificateData.logoThree?.size) params.push({ file: certificateData.logoThree, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.logoThree.name });
            if (certificateData.signatoryImageOne?.size) params.push({ file: certificateData.signatoryImageOne, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageOne.name });
            if (certificateData.signatoryImageTwo?.size) params.push({ file: certificateData.signatoryImageTwo, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageTwo.name });
            if (certificateData.signatoryImageThree?.size) params.push({ file: certificateData.signatoryImageThree, folder: AppConstants.workshopCertificateUploadFolder, id: certificateFromLabels.signatoryImageThree.name });

            for (let i = 0; i < params.length; i++) {
                let { file, folder, id } = params[i];
                let ext = file.name.split(".").pop();
                let path = `${AppConstants.workshopsUploadPath}${AppConstants.forwardSlash}${userDetails.userId}${AppConstants.forwardSlash}${timestamp}${AppConstants.forwardSlash}${folder}${AppConstants.forwardSlash}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);
                if (!(response instanceof Error)) {
                    filePaths[id] = response.objectKey;
                } else {
                    return AppConstants.nullText
                }
            }

            return filePaths;
        } catch {
            return AppConstants.nullText;
        }
    };

    const checkFormValidity = () => {
        if (formErrors?.length === 0 && basicDetailsData.title?.trim() !== AppConstants.emptyString && basicDetailsData.description?.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
            return AppConstants.trueText;
        } else {
            return AppConstants.falseText;
        }
    };

    const checkCertificateValidity = () => {
        let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
        for (let i = 0; i < certificateErrorMessages.length; i++) {
            if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                return AppConstants.falseText;
            }
        }
        if (certificateData.logoUpdated && certificateData.messageUpdated) {
            if (certificateData.signatoryOneUpdated === AppConstants.incompleteText || certificateData.signatoryTwoUpdated === AppConstants.incompleteText || certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                return AppConstants.falseText;
            } else if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return AppConstants.falseText;
            } else {
                return AppConstants.trueText;
            }
        } else if (certificateData.logoUpdated || certificateData.messageUpdated) {
            return AppConstants.falseText;
        } else {
            if (certificateData.signatoryOneUpdated === AppConstants.emptyString && certificateData.signatoryTwoUpdated === AppConstants.emptyString && certificateData.signatoryThreeUpdated === AppConstants.emptyString) {
                return AppConstants.trueText;
            } else {
                return AppConstants.falseText;
            }
        }
    };

    // function to manage error handling operations
    const manageErrors = (errorMsg) => {
        setToastBody(errorMsg);
        setShowToast(AppConstants.trueText);
        setShowSpinner(AppConstants.falseText);
    }

    const submitFormDetails = async () => {
        try {
            const formValid = checkFormValidity();
            const certificateValid = checkCertificateValidity();
            if (formValid && certificateValid) {
                setShowSpinner(AppConstants.trueText);
                const filePaths = await uploadFiles();
                if (filePaths === AppConstants.nullText) {
                    throw new Error(ErrorMessages.workshopCreationError);
                }
                const url = AppConstants.createWorkshopAPI;
                let data = {
                    workshopDetails: buildBasicDetailsProps(filePaths),
                    workshopFaqs: faqData,
                    workshopCertificateDetails: buildCertificateProps(filePaths),
                    locale: DateTimeUtil.getPreferredTimeZoneForUser()
                }
                const response = await AuthorizedDataService.postRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response) {
                    if (response.ok && response.status === 201) {
                        const text = await response.text();
                        const link = `${process.env.REACT_APP_FRONT_END_URL}${text}`;
                        setWorkshopPageLink(link);
                        setShowSpinner(AppConstants.falseText);
                        setModalTypeError(AppConstants.falseText);
                        setShowModal(AppConstants.trueText);
                        setModalMessage(SuccessMessage.workshopCreatedSuccessfully);
                        setShowSpinner(AppConstants.falseText);
                    } else {
                        setOpen([AppConstants.falseText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
                        scrollToTop();
                        setWorkshopPageLink(AppConstants.emptyString);
                        setShowModal(AppConstants.trueText);
                        setModalTypeError(AppConstants.trueText);
                        setModalMessage(ErrorMessages.workshopCreationError)
                        setShowSpinner(AppConstants.falseText);
                    }
                } else {
                    setOpen([AppConstants.falseText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
                    scrollToTop();
                    setWorkshopPageLink(AppConstants.emptyString);
                    setShowModal(AppConstants.trueText);
                    setModalTypeError(AppConstants.trueText);
                    setModalMessage(ErrorMessages.workshopCreationError)
                    setShowSpinner(AppConstants.falseText);
                }
            } else {
                scrollToTop();
                if (!formValid) {
                    setOpen([AppConstants.trueText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
                    if (formErrors.length > 0) {
                        manageErrors(formErrors[0]);
                    } else {
                        manageErrors(ErrorMessages.addWorkshopDetailsError);
                    }
                } if (!certificateValid) {
                    setOpen([AppConstants.falseText, AppConstants.falseText, AppConstants.trueText, AppConstants.falseText]);
                    let certificateErrorMessages = Object.values(certificateData.certificateDataErrors);
                    for (let i = 0; i < certificateErrorMessages.length; i++) {
                        if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                            manageErrors(certificateErrorMessages[i]);
                            return AppConstants.nullText;
                        }
                    }
                    if (!certificateData.logoUpdated) {
                        manageErrors(ErrorMessages.atleastOneLogoError);
                    } else if (!certificateData.messageUpdated) {
                        manageErrors(ErrorMessages.certificateMessageError)
                    } else if (certificateData.signatoryOneUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryOneError);
                    } else if (certificateData.signatoryTwoUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryTwoError);
                    } else if (certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                        manageErrors(ErrorMessages.signatoryThreeError);
                    } else {
                        manageErrors(ErrorMessages.addOneSignatoryDetails);
                    }
                }
            }
        } catch (error) {
            scrollToTop();
            setWorkshopPageLink(AppConstants.emptyString);
            setOpen([AppConstants.falseText, AppConstants.falseText, AppConstants.falseText, AppConstants.falseText]);
            setShowModal(AppConstants.trueText);
            setModalTypeError(AppConstants.trueText);
            setModalMessage(ErrorMessages.workshopCreationError)
            setShowSpinner(AppConstants.falseText);
        }
    };

    const setSelectedLearningActivityDetails = (workshopDetails) => {
        setSelectedWorkshopDetails(workshopDetails);
    };

    const resetLearningActivityDetails = () => {
        setSelectedWorkshopDetails({});
    };

    return (
        <div className="expertContentSection">
            <div className={classes.createCompHeading}>{AppConstants.createWorkshopText}</div>
            <div className={classes.createCompContainer}>
                <div className={classes.copyLearningActivityContainer}>
                    <CopyLearningActivityInput learningActivityType={AppConstants.learningActivitiesTypes.workshop} setSelectedLearningActivityDetails={setSelectedLearningActivityDetails} resetLearningActivityDetails={resetLearningActivityDetails} />
                </div>
                <div className={classes.infoComponents}>
                    {
                        stages.map((stage, idx) => {
                            return (<div key={idx} className={classes.collapseContainer}>
                                <div className={classes.headerSection}>
                                    <div className={classes.heading}>
                                        {stage}
                                    </div>
                                    <div className={classes.collapseIcons}>
                                        {!open[idx] && <IoIosArrowDown onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                        {open[idx] && <IoIosArrowUp onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                    </div>
                                </div>
                                <Collapse in={open[idx]}>
                                    <div id="competition-collapse-container">
                                        <div className={classes.collapseDiv}>
                                            {idx === 0 && <AddWorkshopDetails skills={skills} saveCurrentStep={saveCurrentStep} stage={stage} changeBasicFormDetailsData={changeBasicFormDetailsData} changeBasicDetailsFormError={changeBasicDetailsFormError} selectedWorkshopDetails={selectedWorkshopDetails} />}
                                            {idx === 1 && <AddFrequentlyAskedQuestions saveCurrentStep={saveCurrentStep} stage={stage} changeFaqData={changeFaqData} activityType={AppConstants.workshopText} faqCollection={selectedWorkshopDetails.workShopFaqs} />}
                                            {idx === 2 && <AddWorkshopCertificate template={template} changeCertificateData={changeCertificateData} workshopTitle={basicDetailsData.title} stage={stage} saveCurrentStep={saveCurrentStep} startDate={basicDetailsData.startDate} endDate={basicDetailsData.endDate} skills={skills} skillId={basicDetailsData.skill} selectedWorkshopData={selectedWorkshopDetails} />}
                                            {idx === 3 && <AdvancedWorkshopSettings cost={basicDetailsData.cost} selectedWorkshopData={selectedWorkshopDetails} changeAdvancedSettings={changeAdvancedSettings} />}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>);
                        })
                    }
                    <div className={classes.createButton}>
                        {<Button disabled={showSpinner} type="submit" onClick={submitFormDetails} className={classes.createComptitionButton}>{showSpinner ? <BiLoaderAlt className="spinner" /> : "Create"}</Button>}
                    </div>
                </div>

                <ToastContainer
                    className="p-3"
                    position='middle-center'>
                    <Toast show={showToast}
                        onClose={() => setShowToast(AppConstants.falseText)} bg={toastBackground}
                        animation delay={AppConstants.toastDelay} autohide={AppConstants.falseText}>
                        <Toast.Header className={toastClass.toastHeader}>
                            <strong className="me-auto">Error</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">
                            {toastBody} <br></br>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={AppConstants.falseText} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>
                        <div className={modalClass.modalAlertText}>
                            {workshopPageLink && <a href={workshopPageLink} className={classes.shareLink} target="_blank" rel="noreferrer">Share workshop link</a>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}