import { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import parse from 'html-react-parser';
import validator from "validator";
import { MdDelete } from 'react-icons/md'
import { Tooltip } from "react-tooltip";
import { RiInformationFill } from "react-icons/ri";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import classes from "../../createCourseComponent/addCourseDetails/AddCourseDetails.module.css";
import formClass from "../../../common/FormStyling.module.css";
import moment from 'moment';
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { AiOutlineDownload } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { WeekdaysSelect } from "../../calendar/WeekDaysSelect";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import ReactQuill, { Quill } from 'react-quill';
import { DateTime } from "luxon";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";

export const EditCourseDetails = (props) => {
    const { skills, saveCurrentStep, stage, changeBasicFormDetailsData, changeBasicDetailsFormError, courseData, status } = props;
    const formLabels = AppConstants.courseDetailsFormLabels;
    const supportedImageFormats = AppConstants.supportedImageFormats;
    const modeOfDelivery = AppConstants.modeOfDelivery;
    const stages = AppConstants.editCourseActions;
    const courseStatus = AppConstants.courseStatus;
    const [videoSpinner, setVideoSpinner] = useState(false);
    const [uploadedPromotionImageWeb, setUploadedPromotionImageWeb] = useState(courseData.coursePromotionWebImageAbsolutePath);
    const [uploadedPromotionImageTablet, setUploadedPromotionImageTablet] = useState(courseData.coursePromotionTabletImageAbsolutePath);
    const [uploadedPromotionImageMobile, setUploadedPromotionImageMobile] = useState(courseData.coursePromotionMobileImageAbsolutePath);
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState(courseData.courseThumbnailImageAbsolutePath);
    const [uploadedPromotionVideo, setUploadedPromotionVideo] = useState(courseData.coursePromotionVideoAbsolutePath);
    const [uploadedDescriptionDocument, setUploadedDescriptionDocument] = useState(courseData.courseDescriptionDocumentAbsolutePath);
    const [weekDaysArray, setWeekDaysArray] = useState(courseData.weekDays ? courseData.weekDays.split(AppConstants.comma) : []);
    const [descriptionDocumentSpinner, setDescriptionDocumentSpinner] = useState(false);
    const [registrationDateAdded, setRegistrationDateAdded] = useState(courseData.courseUtcRegistrationClosesOn ? AppConstants.trueText : AppConstants.falseText);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(courseData.courseUtcRegistrationClosesOn ? AppConstants.trueText : AppConstants.falseText);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(false);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(false);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(false);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(false);
    const [description, setDescription] = useState(courseData.courseDescription);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                return `http://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }

    let initialValues = {
        courseType: courseData.courseType,
        title: courseData.courseTitle,
        descriptionDocument: AppConstants.emptyString,
        skill: courseData.skillUniqueId,
        startDate: courseData.courseType === AppConstants.structuredCourseType ? DateTime.fromFormat(courseData.startTime, 'hh:mm a, dd LLL yyyy').toFormat('yyyy-LL-dd') : AppConstants.emptyString,
        endDate: courseData.courseType === AppConstants.structuredCourseType ? DateTime.fromFormat(courseData.endTime, 'hh:mm a, dd LLL yyyy').toFormat('yyyy-LL-dd') : AppConstants.emptyString,
        numberOfClasses: courseData.numberOfClasses,
        windowStartTime: DateTime.fromFormat(courseData.startTime.split(",")[0], 'hh:mm a').toFormat('HH:mm'),
        windowEndTime: DateTime.fromFormat(courseData.endTime.split(",")[0], 'hh:mm a').toFormat('HH:mm'),
        registrationClosingDate: courseData.courseUtcRegistrationClosesOn ? DateTime.fromISO(`${courseData.courseUtcRegistrationClosesOn?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat(AppConstants.browserDefaultDateFormat) : AppConstants.emptyString,
        registrationClosingTime: courseData.courseUtcRegistrationClosesOn ? DateTime.fromISO(`${courseData.courseUtcRegistrationClosesOn?.replace(" ", "T")}Z`, { zone: 'utc' }).setZone(timeZone).toFormat('HH:mm') : AppConstants.emptyString,
        courseWeekDays: courseData.weekDays || AppConstants.emptyString,
        durationInHours: courseData.duration,
        modeOfDelivery: courseData.modeOfDelivery,
        address: courseData.address || AppConstants.emptyString,
        sessionLink: courseData.sessionLink || AppConstants.emptyString,
        geoLocation: courseData.geoLocation || AppConstants.emptyString,
        cost: courseData.cost,
        discount: courseData.discount >= 0 ? courseData.discount : AppConstants.emptyString,
        expertName: courseData.spocName || AppConstants.emptyString,
        expertEmail: courseData.spocEmail || AppConstants.emptyString,
        expertMobile: courseData.spocMobileNumber || AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionVideo: AppConstants.emptyString,
    };

    const validationSchema = Yup.object().shape({
        courseType: Yup.string().required(ErrorMessages.selectCourseTypeError),
        title: Yup.string().trim().max(100, ErrorMessages.invalidCourseTitile).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.label}`),
        descriptionDocument: Yup.mixed().notRequired().test("file_size_description", ErrorMessages.courseDescriptionSizeLimit, (value) => { return value ? (value.size <= 50 * 1000 * 1000) : true }).test('file_Type', ErrorMessages.courseDescriptionPdfFormat, (value) => { return value ? (["application/pdf"].includes(value.type)) : true }),
        skill: skills?.length && Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        startDate: courseData.courseType === AppConstants.structuredCourseType ? ((status !== courseStatus.upcoming && courseData.status === AppConstants.publishedText) ? Yup.date().notRequired() : Yup.date().required(ErrorMessages.courseStartDateRequired).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.startDate.label} ${ErrorMessages.pastDateError}`)) : Yup.date().notRequired(),
        endDate: courseData.courseType === AppConstants.structuredCourseType ? (status === courseStatus.past ? Yup.date().notRequired() : Yup.date().required(ErrorMessages.courseEndDateRequired).test(
            "end_time_before_start", ErrorMessages.courseEndDateError,
            function (value) {
                const { startDate } = this.parent;
                return moment(startDate).isSameOrBefore(moment(value));
            }
        ).test("end_date_before_today", ErrorMessages.coursePastEndDateError, function (value) {
            const date = new Date(value);

            return moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(date, timeZone)).isAfter(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone));
        })) : Yup.date().notRequired(),
        numberOfClasses: Yup.number().required(ErrorMessages.courseClassesRequiredError).min(1, ErrorMessages.courseClassesError).integer(`Number of classes ${ErrorMessages.invalidDecimalNumber}`),
        durationInHours: Yup.number().required(ErrorMessages.courseDurationRequired).min(0.1, ErrorMessages.courseDurationError).test(
            "step_of_0_5",
            ErrorMessages.courseDurationIn30intervalError,
            function (value) {
                return Math.abs(value % 0.5) === 0
            }
        ),
        modeOfDelivery: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.modeOfDeliveryText}`).oneOf(AppConstants.modeOfDelivery),
        windowStartTime: courseData.courseType === AppConstants.structuredCourseType ? ((courseStatus === courseStatus.past) ? Yup.string().notRequired() : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space} start time`).test(
            "start_time_test", ErrorMessages.courseStartTimeError, function (value) {
                const { startDate } = this.parent;
                const hourMins = value.split(":");
                const currentDateTime = DateTime.now().setZone(timeZone);
                if (status === courseStatus.upcoming) {
                    const d = new Date(startDate);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis();
                } else {
                    if (courseData.status === "Not Published") {
                        const d = new Date(startDate);
                        const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                        return selectedDateTime.toMillis() > currentDateTime.toMillis();
                    } else {
                        // published and ongoing
                        // check if start time has not changed then return true.
                        if (value.toString() === DateTime.fromFormat(courseData.startTime.split(",")[0], 'hh:mm a').toFormat('HH:mm').toString()) {
                            return true;
                        } else {
                            // if start time is chagned by user then start time should be of future
                            const selectedDateTime = DateTime.fromObject({ year: currentDateTime.year, month: currentDateTime.month, day: currentDateTime.day, hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                            return selectedDateTime.toMillis() > currentDateTime.toMillis();
                        }
                    }
                }
            }
        )) : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space} start time`),
        windowEndTime: (status === courseStatus.past) ? Yup.string().notRequired() : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}end time`).test(
            "end_time_test", ErrorMessages.courseEndTimeError,
            function (value) {
                const { windowStartTime } = this.parent;
                return moment(windowStartTime, 'HH:mm').isBefore(moment(value, 'HH:mm'));
            }
        ),
        registrationClosingDate: courseData.courseType === AppConstants.structuredCourseType ? (moment(courseData.courseRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).test(
            "registration_closing_edit_test", "Invalid Registration closing date", function (value) {
                if (status === courseStatus.past) {
                    return true;
                }
                const { endDate } = this.parent;
                const dateTime = DateTime.fromJSDate(new Date(courseData.courseRegistrationClosesOn)).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                return (value.toString() === dateTime.toJSDate().toString()) || moment(value).isBetween(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone(timeZone).toJSDate(), endDate, AppConstants.nullText, AppConstants.trueText);
            }
        ) : (status === courseStatus.past) ? Yup.date().notRequired() : registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.courseRegistrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`) : Yup.date().notRequired()) : Yup.date().notRequired(),
        registrationClosingTime: courseData.courseType === AppConstants.structuredCourseType ? ((status === courseStatus.past) ? Yup.string().notRequired() : registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.courseRegistrationTimeBeforeEndTimeError,
            function (value) {
                const { windowEndTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(windowEndTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return AppConstants.trueText;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                const d = new Date(registrationClosingDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                if (moment(courseData.courseRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() !== courseData.courseRegistrationClosesOn) && selectedDateTime.toMillis() < currentDateTime.toMillis()) {
                    return AppConstants.falseText;
                } else if (moment(courseData.courseRegistrationClosesOn).isBefore(moment(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(), timeZone))) && (selectedDateTime.toJSDate().toString() === courseData.courseRegistrationClosesOn)) {
                    return AppConstants.trueText;
                } else return selectedDateTime.toMillis() > currentDateTime.toMillis();
            }) : Yup.string().notRequired()) : Yup.date().notRequired(),
        courseWeekDays: Yup.string().required(ErrorMessages.selectCourseWeekdaysError),
        address: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().trim().required(ErrorMessages.enterAddressError)
        }),
        sessionLink: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOnline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for Session Link`,
                function (value = "") { return validator.isURL(value) })
        }),
        geoLocation: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for geo location`,
                function (value = "") { return validator.isURL(value) })
        }),
        cost: Yup.number().required("Please enter a valid course cost").min(courseData.courseType === AppConstants.structuredCourseType ? 0 : AppConstants.flexibleCourseMinimumCost, courseData.courseType === AppConstants.structuredCourseType ? ErrorMessages.minimumCostError : ErrorMessages.minimumCostFlexibleCourseError),
        discount: Yup.number().typeError("Please enter valid discount").notRequired().min(0, ErrorMessages.minimumDiscountError).test("minimum_discount_test", courseData.courseType === AppConstants.structuredCourseType ? "Discount cannot be greater than course cost" : "Discount should be less than course cost", function (value) {
            const { cost } = this.parent;
            if (courseData.courseType === AppConstants.structuredCourseType) {
                if (value && value > cost) return false;
                else return true;
            } else {
                if (value && value + 1 > cost) return false;
                else return true;
            }
        }),
        expertEmail: Yup.string().notRequired().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, ErrorMessages.invalidEmailFormat),
        expertName: Yup.string().notRequired().matches(/^[a-zA-Z .]*$/, ErrorMessages.invalidUserNameWithRegex),
        expertMobile: Yup.string().notRequired().matches(/^[1-9]{1}\d{5,}$/, "Please Enter a valid mobile number"),
        thumbnailImage: (!uploadedThumbnailImage) ? Yup.mixed().notRequired().test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizeThumbnail', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true })
            : Yup.mixed().notRequired().test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizeThumbnail', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForWeb: (!uploadedPromotionImageWeb) ? Yup.mixed().notRequired().test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizeweb', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true })
            : Yup.mixed().notRequired().test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizeweb', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileTypeMobile', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizemobile', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionVideo: Yup.mixed().notRequired().test("fileTypeVideo", ErrorMessages.promotionVideoTypeError, (value) => { return value ? (["video/mp4", "video/mkv", "video/x-m4v", "video/quicktime", "video/ai", "video/ogg", "video/webm", "video/x-msvideo"].includes(value.type)) : true }).test('fileSizeVideo', ErrorMessages.promotionVideoFileSizeError, (value) => { return value ? (value.size <= 200 * 1000 * 1000) : true })
    });

    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const checkValidMobile = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };

    const { handleSubmit, values, touched, errors, setFieldValue, handleBlur, handleChange, setFieldTouched } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        handleSubmit: () => { }
    });

    const extractFileName = (name) => {
        let url = name.substr(0, name.lastIndexOf("\/"));
        let arr = name.split("/");
        const regex = /[0-9]*-/
        return arr[4]?.replace(regex, "");
    };

    const getDataTransfer = (name) => {
        const dataTransfer = new DataTransfer()
        const file = new File([''], name, { type: 'text/plain' })
        dataTransfer.items.add(file);
        return dataTransfer;
    };

    useEffect(() => {
        const changeParentState = () => {
            changeBasicFormDetailsData({
                ...values,
                description,
                uploadedDescriptionDocument,
                uploadedThumbnailImage, uploadedPromotionImageWeb,
                uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedPromotionVideo
            });
        }
        changeParentState();
    }, [values, description, uploadedDescriptionDocument, uploadedThumbnailImage, uploadedPromotionImageWeb,
        uploadedPromotionImageTablet, uploadedPromotionImageMobile, uploadedPromotionVideo, status]);

    useEffect(() => {
        const changeParentState = () => {
            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors);
                } else {
                    changeBasicDetailsFormError({ description: "Please enter a description", ...errors });
                }
            } else {
                changeBasicDetailsFormError(errors);
            }
            //changeBasicDetailsFormError(errors);
        }
        changeParentState();
    }, [errors, description]);


    const changeModeOfDelivery = (event) => {
        setFieldValue(formLabels.modeOfDelivery.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.competitionTypeOffline) {
                setFieldValue(formLabels.sessionLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.competitionTypeOnline) {
                setFieldValue(formLabels.address.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.address.name, AppConstants.emptyString);
            setFieldValue(formLabels.sessionLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    };

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldTouched(id);
        setFieldValue(id, AppConstants.emptyString);
    };

    const downloadFile = async (fileUrl, name) => {
        let ext = getFileExtension(fileUrl)
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${courseData.courseTitle}-${name}${AppConstants.dot}${ext}`);
        if (resp) {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(false);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(false);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(false);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(false);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(false);
            }
        } else {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(false);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(false);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(false);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(false);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(false);
            }
        }
    };

    const downloadVideoFile = async (fileUrl, name) => {
        setVideoSpinner(true);
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${courseData.courseTitle}-${name}`);
        if (resp) {
            setVideoSpinner(false);
        } else {
            setVideoSpinner(false);
        }
    }

    const getFileExtension = (url) => {
        return url.split(".").pop();
    };

    const moveToNextSection = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const handleDaysSelection = (days) => {
        setFieldValue(formLabels.courseWeekDays.name, days.join(AppConstants.comma));
        setWeekDaysArray(days.join(AppConstants.comma));
    };

    const changeStartTimeWindow = (e) => {
        setFieldValue(formLabels.windowStartTime.name, e.target.value)
    }

    const changeEndTimeWindow = (e) => {
        setFieldValue(formLabels.windowEndTime.name, e.target.value)
    };

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(AppConstants.trueText);
        } else {
            setRegistrationDateAdded(AppConstants.falseText);
        }
    }

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(AppConstants.trueText)
        } else {
            setRegistrationTimeAdded(AppConstants.falseText)
        }
    };

    const getRemainingLengthText = (limit, textField) => {
        if (!textField || textField === AppConstants.emptyString) {
            return AppConstants.emptyString;
        }

        return `${limit - textField.length} out of ${limit} characters remaining`;
    }

    return (
        <div className={classes.formContainer}>
            <Form noValidate>
                <Row>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabelDisabled}>{formLabels.courseType.label}<span className="redAstrisk">*</span> <RiInformationFill className={classes.infoButton} data-tooltip-id="courseTypeInfo" /></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled required type="text" value={values.courseType}
                            name={formLabels.courseType.name}
                            onBlur={handleBlur}
                            isInvalid={touched.courseType && errors.courseType}
                        />
                        <Tooltip className={classes.tooltipMessage} id="courseTypeInfo"><span className={classes.tooltipMessage}>{parse(AppConstants.courseTypeDropdownInfo)}</span></Tooltip>
                    </Form.Group>
                </Row>
                <Row className="mb-2" >
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === courseStatus.past}
                            required placeholder="Course Title"
                            type="text" maxLength={100}
                            name={formLabels.title.name}
                            value={values.title} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.title && errors.title}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.title}</Form.Control.Feedback>
                        {values.title?.length > 0 && <span className={classes.remainingChars}>{getRemainingLengthText(100, values.title)}</span>}
                    </Form.Group>
                </Row>

                <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                    <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                        <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span><RiInformationFill data-tooltip-id="courseDescriptionInfo" className={classes.infoButton} ></RiInformationFill></Form.Label>
                        <ReactQuill
                            formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                            onChange={setDescription}
                            value={description}
                            preserveWhitespace={AppConstants.trueText}
                            className={classes.textEditor} theme="snow" placeholder="Description of your course"
                        />
                        <Tooltip className={classes.tooltipMessage} id="courseDescriptionInfo"><span className={classes.tooltipMessage}>{AppConstants.courseDescriptionInfo}</span></Tooltip>

                    </Form.Group>
                </Row>
                <Row className="mb-2 mt-3">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.descriptionDocument.name} name={formLabels.descriptionDocument.name} disabled={status === courseStatus.past} accept=".pdf" label={formLabels.descriptionDocument.label} handleFileChange={e => { setFieldValue(formLabels.descriptionDocument.name, e.target.files[0]); setUploadedDescriptionDocument(AppConstants.emptyString) }} infoMessage={AppConstants.courseBrochureInfo} inputFile={values.descriptionDocument}
                                    defaultFile={uploadedDescriptionDocument && getDataTransfer(extractFileName(courseData.courseDescriptionDocumentRelativePath) || "Description_Document").files[0]} />
                                {errors.descriptionDocument && <div className="errorText">{errors.descriptionDocument}</div>}
                                <Tooltip className={classes.tooltipMessage} id="courseBrochureInfo"><span className={classes.tooltipMessage}>{AppConstants.courseBrochureInfo}</span></Tooltip>
                            </Form.Group>
                        </div>
                        {courseData.courseDescriptionDocumentAbsolutePath && <button type="button" onClick={() => { downloadFile(courseData.courseDescriptionDocumentAbsolutePath, formLabels.descriptionDocument.name); setDescriptionDocumentSpinner(true) }} data-tooltip-id="downloaddescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{descriptionDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedDescriptionDocument(AppConstants.emptyString); removeFile(formLabels.descriptionDocument.name); }} data-tooltip-id="removedescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removedescriptionDoc"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloaddescriptionDoc"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row>
                    {skills?.length > 1 && <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.skill.name}
                            disabled={courseData.courseType === AppConstants.structuredCourseType ? (status !== courseStatus.upcoming || (status === courseStatus.upcoming && courseData.participantsDetails.length > 0 && courseData.status === AppConstants.publishedText) || (status === courseStatus.upcoming && courseData.participantsDetails.length > 0 && courseData.status === AppConstants.coursePublishedStatus.unpublished)) : (courseData.status === AppConstants.publishedCourseText || (courseData.status !== AppConstants.publishedCourseText && courseData.participantsDetails.length > 0))}
                            value={values.skill} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.skill && errors.skill}>
                            <option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                            {skills?.map(option => (
                                <option key={option.skillId} value={option.skillId}>
                                    {option.skillName}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.skill}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                {values.courseType === AppConstants.structuredCourseType && <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.startDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.startDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={courseData.status === AppConstants.publishedText && status !== AppConstants.courseStatus.upcoming}
                            name={formLabels.startDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.startDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.startDate && errors.startDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === courseStatus.past}
                            name={formLabels.endDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.endDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endDate && errors.endDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endDate}</Form.Control.Feedback>
                    </Form.Group>
                </Row>}
                <Row className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.numberOfClasses.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === courseStatus.past}
                            type="number"
                            min={0}
                            placeholder="Number of classes"
                            name={formLabels.numberOfClasses.name}
                            value={values.numberOfClasses} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.numberOfClasses && errors.numberOfClasses}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.numberOfClasses}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.durationInHours.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === courseStatus.past}
                            type="number"
                            min={0}
                            placeholder="Total learning hours"
                            onKeyDown={checkValidCost}
                            name={formLabels.durationInHours.name}
                            value={values.durationInHours} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.durationInHours && errors.durationInHours}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.durationInHours}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.modeOfDelivery.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            disabled={status === courseStatus.past}
                            name={formLabels.modeOfDelivery.name}
                            value={values.modeOfDelivery} onBlur={handleBlur}
                            onChange={(event) => changeModeOfDelivery(event)}
                            isInvalid={touched.modeOfDelivery && errors.modeOfDelivery}>
                            <option value={AppConstants.emptyString} defaultValue>Select Type</option>
                            {modeOfDelivery?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.competitionType}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[0] && <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.sessionLink.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url" placeholder="Course session link"
                            disabled={status === courseStatus.past}
                            name={formLabels.sessionLink.name}
                            value={values.sessionLink} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.sessionLink && errors.sessionLink} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.sessionLink}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenueLocation">
                        <Form.Label className={formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url"
                            placeholder="Course venue location URL"
                            name={formLabels.geoLocation.name}
                            value={values.geoLocation} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.geoLocation && errors.geoLocation} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                        <Form.Label className={formClass.formLabel}>{formLabels.address.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formTextAreaInput}
                            as="textarea"
                            disabled={status === courseStatus.past}
                            placeholder="Course delivery address"
                            name={formLabels.address.name}
                            value={values.address} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address && errors.address} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <hr />
                <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Class timings for the course</Form.Label>
                </Row>
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.windowStartTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                        <Form.Label className={formClass.formLabel}>{formLabels.windowStartTime.label} <span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.windowStartTime.name}
                            type="time"
                            value={values.windowStartTime} onBlur={handleBlur}
                            onChange={e => changeStartTimeWindow(e)}
                            isInvalid={touched.windowStartTime && errors.windowStartTime}
                            disabled={status === courseStatus.past}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.windowStartTime}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.windowEndTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.windowEndTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.windowEndTime.name}
                            type="time"
                            value={values.windowEndTime} onBlur={handleBlur}
                            onChange={e => changeEndTimeWindow(e)}
                            isInvalid={touched.windowEndTime && errors.windowEndTime}
                            disabled={status === courseStatus.past}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.windowEndTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {status !== courseStatus.past && <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Select days <span className="redAstrisk">*</span></Form.Label>
                </Row>}
                {status !== courseStatus.past && <div className={classes.weekCalendar}>
                    <WeekdaysSelect selectedValues={weekDaysArray} handleDaysSelection={handleDaysSelection} />
                    {errors.courseWeekDays && <div className="errorText">{errors.courseWeekDays}</div>}
                </div>}
                {status === courseStatus.past && weekDaysArray.length !== 0 && <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Selected days</Form.Label>
                    <div className={classes.displayInline}>{weekDaysArray.map((day, index) => (
                        <div key={index} className={classes.inlineDisplay}>
                            {index > 0 && ', '}
                            {day}
                        </div>
                    ))}</div>
                </Row>}

                {status === courseStatus.past && weekDaysArray.length === 0 && <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Selected days: N/A</Form.Label>
                </Row>}
                <hr />

                {values.courseType === AppConstants.structuredCourseType && <Row className="mb-2 mt-2">
                    <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                        <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                        <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.courseRegistrationDeadlineInfo}</span></Tooltip>
                    </Form.Group>
                </Row>}
                {values.courseType === AppConstants.structuredCourseType && <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                        <Form.Label className={status === courseStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingDate.name}
                            disabled={status === courseStatus.past}
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                            value={values.registrationClosingDate} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingDate(e)}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                        <Form.Label className={status === courseStatus.past ? formClass.formLabelDisabled : formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingTime.name}
                            disabled={status === courseStatus.past}
                            type="time"
                            value={values.registrationClosingTime} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingTime(e)}
                            isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>}

                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.cost.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            disabled={courseData.courseType === AppConstants.structuredCourseType ? (status === courseStatus.past || courseData.participantsDetails.length > 0) : AppConstants.falseText}
                            placeholder="Course cost"
                            name={formLabels.cost.name}
                            value={values.cost} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.cost && errors.cost}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.discount.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            disabled={courseData.courseType === AppConstants.structuredCourseType ? (status === courseStatus.ongoing && courseData.participantsDetails.length > 0) : AppConstants.falseText}
                            placeholder="Discount amount"
                            name={formLabels.discount.name}
                            value={values.discount} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.discount && errors.discount}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.discount}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Label className={classes.contactLabel}>SPOC Details</Form.Label>
                </Row>
                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertName.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            disabled={status === courseStatus.past}
                            placeholder="SPOC name"
                            type="text" maxLength={30}
                            name={formLabels.expertName.name}
                            value={values.expertName} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertName && errors.expertName}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertMobile.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC mobile number"
                            type="number"
                            name={formLabels.expertMobile.name}
                            value={values.expertMobile} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.expertMobile && errors.expertMobile}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertMobile}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertEmail.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC email id"
                            type="text" maxLength={50}
                            name={formLabels.expertEmail.name}
                            value={values.expertEmail} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertEmail && errors.expertEmail}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertEmail}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} disabled={status === courseStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={e => { setFieldValue("thumbnailImage", e.target.files[0]); setUploadedThumbnailImage(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsThumbnailMessage} inputFile={values.thumbnailImage}
                                    defaultFile={uploadedThumbnailImage && getDataTransfer(extractFileName(courseData.courseThumbnailImageRelativePath) || "Thumbnail_Image").files[0]} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </Form.Group>
                        </div>
                        {courseData.courseThumbnailImageAbsolutePath && <button type="button" onClick={() => { downloadFile(courseData.courseThumbnailImageAbsolutePath, formLabels.thumbnailImage.name); setThumbnailSpinner(true) }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedThumbnailImage(AppConstants.emptyString); removeFile(formLabels.thumbnailImage.name); }} data-tooltip-id="removeThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadThumbnail"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                            <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} disabled={status === courseStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={e => { setFieldValue("promotionImageForWeb", e.target.files[0]); setUploadedPromotionImageWeb(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsWebBannerMessage} inputFile={values.promotionImageForWeb}
                                defaultFile={uploadedPromotionImageWeb ? getDataTransfer(extractFileName(courseData.coursePromotionWebImageRelativePath) || "Promotion_Image_Web").files[0] : ""} />
                            {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                        </Form.Group>
                        {courseData.coursePromotionWebImageAbsolutePath && <button type="button" onClick={() => { downloadFile(courseData.coursePromotionWebImageAbsolutePath, formLabels.promotionImageForWeb.name); setPromotionImageWebSpinner(true) }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageWeb(AppConstants.emptyString); removeFile(formLabels.promotionImageForWeb.name) }} data-tooltip-id="removeWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadWebImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} disabled={status === courseStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={e => { setFieldValue("promotionImageForTablet", e.target.files[0]); setUploadedPromotionImageTablet(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsTabletBannerMessage} inputFile={values.promotionImageForTablet}
                                defaultFile={uploadedPromotionImageTablet ? getDataTransfer(extractFileName(courseData.coursePromotionTabletImageRelativePath) || "Promotion_Image_Tablet").files[0] : ""} />
                            {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                        </Form.Group>
                        {courseData.coursePromotionTabletImageAbsolutePath && <button type="button" onClick={() => { downloadFile(courseData.coursePromotionTabletImageAbsolutePath, formLabels.promotionImageForTablet.name); setPromotionImageTabletSpinner(true) }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageTablet(AppConstants.emptyString); removeFile(formLabels.promotionImageForTablet.name) }} data-tooltip-id="removeTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadTabletImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} disabled={status === courseStatus.past} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={e => { setFieldValue("promotionImageForMobile", e.target.files[0]); setUploadedPromotionImageMobile(AppConstants.emptyString) }} infoMessage={AppConstants.expertCreateCompetitionsMobileBannerMessage} inputFile={values.promotionImageForMobile}
                                defaultFile={uploadedPromotionImageMobile ? getDataTransfer(extractFileName(courseData.coursePromotionMobileImageRelativePath) || "Promotion_Image_Mobile").files[0] : ""} />
                            {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                        </Form.Group>
                        {courseData.coursePromotionMobileImageAbsolutePath && <button type="button" onClick={() => { downloadFile(courseData.coursePromotionMobileImageAbsolutePath, formLabels.promotionImageForMobile.name); setPromotionImageMobileSpinner(true); }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedPromotionImageMobile(AppConstants.emptyString); removeFile(formLabels.promotionImageForMobile.name) }} data-tooltip-id="removeMobileImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadMoblieImage"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionVideo.name} name={formLabels.promotionVideo.name} disabled={status === courseStatus.past} accept="video/mp4,video/mkv,video/x-m4v,video/*" label={formLabels.promotionVideo.label} handleFileChange={e => { setFieldValue(formLabels.promotionVideo.name, e.target.files[0]); setUploadedPromotionVideo(AppConstants.emptyString) }} infoMessage={AppConstants.coursePromotionVideoInfo} inputFile={values.promotionVideo}
                                defaultFile={uploadedPromotionVideo ? getDataTransfer(extractFileName(courseData.coursePromotionVideoRelativePath) || "Promotion_Video").files[0] : ""} />
                            {errors.promotionVideo && <div className="errorText">{errors.promotionVideo}</div>}
                        </Form.Group>
                        {courseData.coursePromotionVideoAbsolutePath && <button type="button" onClick={() => downloadVideoFile(courseData.coursePromotionVideoAbsolutePath, `${formLabels.promotionVideo.name}.${getFileExtension(courseData.coursePromotionVideoRelativePath)}`)} data-tooltip-id="downloadVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{videoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        {status !== courseStatus.past && <button type="button" onClick={() => { setUploadedPromotionVideo(AppConstants.emptyString); removeFile(formLabels.promotionVideo.name) }} data-tooltip-id="removePromotionVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}><MdDelete /></button>}
                        <Tooltip className={classes.tooltipMessage} id="removePromotionVideo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        <Tooltip className={classes.tooltipMessage} id="downloadVideo"><span className={classes.tooltipMessage}>Download file</span></Tooltip>
                    </div>
                </Row>
            </Form>
            <div className={classes.nextBtn}>
                <Button onClick={() => moveToNextSection()}>{AppConstants.nextText}</Button>
            </div>
        </div>
    )
}
