import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col, Button } from "react-bootstrap";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { DataService } from "../../services/DataService";
import { AppConstants } from "../../constants/AppConstants";
import { TeamHeader } from '../../layout/TeamPageLayout/TeamHeader';
import { TeamLeftNav } from '../../layout/TeamPageLayout/TeamLeftnav';
import { TeamRightWidgetSpace } from '../../layout/TeamPageLayout/TeamRightWIdgetSpace';
import { TeamFooter } from '../../layout/TeamPageLayout/TeamFooter';
import classes from './TeamTemplate.module.css';

export const TeamTemplate = () => {
    const [footerDetails, setFooterDetails] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        async function fetchFooterData() {
            const url = AppConstants.landingPageDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setFooterDetails(response);
            return null;
        };
        fetchFooterData();
    }, []);


    const myActivitiesHandler = () => {
        setOpen(!open);
    };

    return (
        <>
            <TeamHeader />
            <div>
                <div className={classes.myActivitiesSection}>
                    <Button className={classes.myActivitiesButton} onClick={myActivitiesHandler}>My Activities {!open && <RiArrowDownSLine className={classes.activityIcon} />}
                        {open && <RiArrowUpSLine className={classes.activityIcon} />}</Button>
                </div>
                <Row>
                    <Col lg={2}><TeamLeftNav /></Col>
                    <div className={classes.mobileViewRightWidgetSection}>{open && <TeamRightWidgetSpace />}</div>
                    <Col xs={12} sm={12} md={8} lg={7} className={classes.templateHeight}>
                        <Outlet />
                    </Col>
                    <Col md={4} lg={3} className="d-none d-md-block d-lg-block" ><TeamRightWidgetSpace /></Col>
                </Row>
            </div>

            {footerDetails != null && <TeamFooter footerDetails={footerDetails} />}
        </>
    )
}
