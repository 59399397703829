import { useEffect, useState } from "react";
import { AppConstants } from "../constants/AppConstants";

export function useInAppBrowserStatus() {
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes("instagram") || userAgent.includes("facebook")) {
            setIsInAppBrowser(AppConstants.trueText);
        } else {
            setIsInAppBrowser(AppConstants.falseText);
        }
    }, []);
    return isInAppBrowser;
}