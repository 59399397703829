import { useEffect, useState } from "react";
import { ViewCompetitionTabs } from "../../component/expertManageCompetitionComponent/viewCompetitionTabs";
import { ViewCompetitionDetailsAccordion } from "../../component/expertManageCompetitionComponent/viewCompetitionDetailsAccordion";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import classes from "./ExpertManageCompetitionsPage.module.css";
import { CompetitionsCards } from "../../component/cards/CompetitionsCards";
import { FaArrowLeft } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../../component/errorSuccessMessage/ErrorSuccessAlertMessage";
import { EditCompetitionComponent } from "../../component/editCompetition/EditCompetitionComponent";
import { StringUtils } from "../../utils/StringUtils";

export const ExpertManageCompetitionsPage = () => {
    const [upcomingCompetitionsSection, setUpcomingCompetitionsSection] = useState(true);
    const [ongoingCompetitionsSection, setOngoingCompetitionsSection] = useState(false);
    const [pastCompetitionsSection, setPastCompetitionsSection] = useState(false);
    const [cancelledCompetitionsSection, setCancelledCompetitionsSection] = useState(false);
    const [showCompetitionDetailsSection, setShowCompetitionDetailsSection] = useState(false);
    const [upcomingCompetitionsArray, setUpcomingCompetitionsArray] = useState([]);
    const [ongoingCompetitionsArray, setOngoingCompetitionsArray] = useState([]);
    const [pastCompetitionsArray, setPastCompetitionsArray] = useState([]);
    const [cancelledCompetitionsArray, setCancelledCompetitionsArray] = useState([]);
    const [selectedCompetitionDetails, setSelectedCompetitionDetails] = useState([]);
    const [upcomingCompetitionSearchTerm, setUpcomingCompetitionSearchTerm] = useState(AppConstants.emptyString);
    const [upcomingCompetitionsFilteredArray, setUpcomingCompetitionsFilteredArray] = useState([]);
    const [ongoingCompetitionSearchTerm, setOngoingCompetitionSearchTerm] = useState(AppConstants.emptyString);
    const [ongoingCompetitionsFilteredArray, setOngoingCompetitionsFilteredArray] = useState([]);
    const [pastCompetitionSearchTerm, setPastCompetitionSearchTerm] = useState(AppConstants.emptyString);
    const [pastCompetitionsFilteredArray, setPastCompetitionsFilteredArray] = useState([]);
    const [cancelledCompetitionSearchTerm, setCancelledCompetitionSearchTerm] = useState(AppConstants.emptyString);
    const [cancelledCompetitionsFilteredArray, setCancelledCompetitionsFilteredArray] = useState([]);
    const [showEditCompetitionsComponent, setShowEditCompetitionsComponent] = useState(false);
    const [competitionTab, setCompetitionTab] = useState(AppConstants.competitionTabHeading[0]);


    useEffect(() => {
        //fetch Upcoming Competitions
        async function getUpcomingCompetitionsData() {
            const url = AppConstants.fetchUpcomingCompetitionsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setUpcomingCompetitionsArray(response);
                setUpcomingCompetitionsFilteredArray(response);
            } else {
                setUpcomingCompetitionsArray([]);
            }
        }
        getUpcomingCompetitionsData();
    }, [])


    //on selecting competition type Tab
    function competitionTypeSelectionHandler(option) {
        setCompetitionTab(option)
        if (option === AppConstants.competitionTabHeading[0]) {
            setUpcomingCompetitionsSection(true);
            setOngoingCompetitionsSection(false);
            setPastCompetitionsSection(false);
            setCancelledCompetitionsSection(false);
            setShowCompetitionDetailsSection(false);
        } else if (option === AppConstants.competitionTabHeading[1]) {
            setOngoingCompetitionsSection(true);
            setUpcomingCompetitionsSection(false);
            setPastCompetitionsSection(false);
            setCancelledCompetitionsSection(false);
            setShowCompetitionDetailsSection(false);
            //fetch ongoing Competitions
            async function getOngoingCompetitionsData() {
                const url = AppConstants.fetchOngoingCompetitionsAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setOngoingCompetitionsArray(response);
                    setOngoingCompetitionsFilteredArray(response);
                } else {
                    setOngoingCompetitionsArray([]);
                }
            }
            getOngoingCompetitionsData();
        } else if (option === AppConstants.competitionTabHeading[2]) {
            setCancelledCompetitionsSection(false);
            setUpcomingCompetitionsSection(false);
            setPastCompetitionsSection(true);
            setOngoingCompetitionsSection(false);
            setShowCompetitionDetailsSection(false);
            //fetch past Competitions
            async function getPastCompetitionsData() {
                const url = AppConstants.fetchPastCompetitionAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setPastCompetitionsArray(response);
                    setPastCompetitionsFilteredArray(response);
                } else {
                    setPastCompetitionsArray([]);
                }
            }
            getPastCompetitionsData();
        } else if (option === AppConstants.competitionTabHeading[3]) {
            setCancelledCompetitionsSection(true);
            setUpcomingCompetitionsSection(false);
            setOngoingCompetitionsSection(false);
            setShowCompetitionDetailsSection(false);
            setPastCompetitionsSection(false);
            //fetch Cancelled Competitions
            async function getCancelledCompetitionsData() {
                const url = AppConstants.fetchCancelledCompetitionAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setCancelledCompetitionsArray(response);
                    setCancelledCompetitionsFilteredArray(response);
                } else {
                    setCancelledCompetitionsArray([]);
                }
            }
            getCancelledCompetitionsData();
        }

    }

    //show selected competition detail
    async function showCompetitionDetails(competitionData) {
        const params = {
            competitionId: competitionData.uniqueId,
            skillName: competitionData.skillName
        }
        const url = StringUtils.createURLWithParams(AppConstants.fetchCompetitionDetailsWithParameterAPI, params);
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setSelectedCompetitionDetails(response);
            setShowCompetitionDetailsSection(true);
            setShowEditCompetitionsComponent(AppConstants.falseText);
        } else {
            setSelectedCompetitionDetails([]);
            setShowCompetitionDetailsSection(true);
            setShowEditCompetitionsComponent(AppConstants.falseText);
        }
    }

    //back to view competitions
    const goBackToViewCompetitions = () => {
        if (showEditCompetitionsComponent) {
            setShowEditCompetitionsComponent(false);
        } else {
            setShowCompetitionDetailsSection(false);
        }
    }

    const showEditCompetition = (value) => {
        setShowEditCompetitionsComponent(value);
    }

    //method called on cancelling competition
    async function onCompetitionCancel() {
        const url = AppConstants.fetchUpcomingCompetitionsAPI;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setUpcomingCompetitionsArray(response);
        } else {
            setUpcomingCompetitionsArray([]);
        }
    }

    //reset upcoming Filter
    const resetALlFilter = () => {
        setUpcomingCompetitionSearchTerm(AppConstants.emptyString);
        setUpcomingCompetitionsFilteredArray(upcomingCompetitionsArray);
    }

    //reset ongoing Filter
    const resetLiveFilter = () => {
        setOngoingCompetitionSearchTerm(AppConstants.emptyString);
        setOngoingCompetitionsFilteredArray(ongoingCompetitionsArray);
    }

    //reset past Filter
    const resetPastFilter = () => {
        setPastCompetitionSearchTerm(AppConstants.emptyString);
        setPastCompetitionsFilteredArray(pastCompetitionsArray);
    }

    //reset cancelled Filter
    const resetCancelledFilter = () => {
        setCancelledCompetitionSearchTerm(AppConstants.emptyString);
        setCancelledCompetitionsFilteredArray(cancelledCompetitionsArray);
    }

    //search upcoming competitions
    const searchUpcomingCompetitions = (e) => {
        setUpcomingCompetitionSearchTerm(e.target.value);
        let upcomingFilteredCompetitions = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? upcomingCompetitionsArray.filter((item) => ((item.title.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.uniqueId.toLowerCase()).includes(e.target.value.toLowerCase())) || (item.competitionId.toLowerCase().includes(e.target.value.toLowerCase())))
            : upcomingCompetitionsArray;
        setUpcomingCompetitionsFilteredArray(upcomingFilteredCompetitions);
    }

    //search ongoing competitions
    const searchOngoingCompetitions = (e) => {
        setOngoingCompetitionSearchTerm(e.target.value);
        let ongoingFilteredCompetitions = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? ongoingCompetitionsArray.filter((item) => ((item.title.toLowerCase().includes(e.target.value.toLowerCase())) || (item.uniqueId.toLowerCase().includes(e.target.value.toLowerCase()))) || (item.competitionId.toLowerCase().includes(e.target.value.toLowerCase())))
            : ongoingCompetitionsArray;
        setOngoingCompetitionsFilteredArray(ongoingFilteredCompetitions);
    }

    //search past competitions
    const searchPastCompetitions = (e) => {
        setPastCompetitionSearchTerm(e.target.value);
        let pastFilteredCompetitions = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? pastCompetitionsArray.filter((item) => ((item.title.toLowerCase().includes(e.target.value.toLowerCase())) || (item.uniqueId.toLowerCase().includes(e.target.value.toLowerCase()))) || (item.competitionId.toLowerCase().includes(e.target.value.toLowerCase())))
            : pastCompetitionsArray;
        setPastCompetitionsFilteredArray(pastFilteredCompetitions);
    }

    //search live competitions
    const searchCancelledCompetitions = (e) => {
        setCancelledCompetitionSearchTerm(e.target.value);
        let cancelledFilteredCompetitions = e.target.value.toLowerCase() !== AppConstants.emptyString.toLowerCase()
            ? cancelledCompetitionsArray.filter((item) => ((item.title.toLowerCase().includes(e.target.value.toLowerCase())) || (item.uniqueId.toLowerCase().includes(e.target.value.toLowerCase()))) || (item.competitionId.toLowerCase().includes(e.target.value.toLowerCase())))
            : cancelledCompetitionsArray;
        setCancelledCompetitionsFilteredArray(cancelledFilteredCompetitions);
    }

    return (
        <>
            <div className="expertContentSection">
                <div className={classes.manageCompContainer}>
                    <div className={classes.manageCompHeading}>{AppConstants.expertManageCompetitionHeadingText}</div>
                    <ViewCompetitionTabs onSelection={competitionTypeSelectionHandler} />
                    {!showCompetitionDetailsSection && <div>
                        {upcomingCompetitionsSection && <div className={classes.allCompetitionsSection}>
                            <div>
                                <input type="text" className={classes.searchInputBox} value={upcomingCompetitionSearchTerm} onChange={(e) => { searchUpcomingCompetitions(e) }} placeholder="Search Competitions" />
                                <MdClear className={classes.resetSearchIcon} onClick={() => { resetALlFilter() }} />
                            </div>
                            {upcomingCompetitionsFilteredArray.length !== 0 && <div>
                                <CompetitionsCards onViewCompDetails={showCompetitionDetails}
                                    competitionsData={upcomingCompetitionsFilteredArray.length === 0 ? upcomingCompetitionsArray : upcomingCompetitionsFilteredArray}
                                    showCancelButton={true}
                                    onCancelCompetition={onCompetitionCancel} />
                            </div>}
                            {upcomingCompetitionsFilteredArray.length === 0 && <div>
                                <p className={classes.noCompetitionsError}>{ErrorMessages.noUpcomingCompetitionnFallBackMessage}</p>
                            </div>}
                        </div>}
                        {ongoingCompetitionsSection && <div className={classes.liveCompetitionsSection}>
                            <div>
                                <input type="text" className={classes.searchInputBox} value={ongoingCompetitionSearchTerm} onChange={(e) => { searchOngoingCompetitions(e) }} placeholder="Search Competitions" />
                                <MdClear className={classes.resetSearchIcon} onClick={() => { resetLiveFilter() }} />
                            </div>
                            {ongoingCompetitionsFilteredArray.length !== 0 && <div>
                                <CompetitionsCards onViewCompDetails={showCompetitionDetails} competitionsData={ongoingCompetitionsFilteredArray.length === 0 ? ongoingCompetitionsArray : ongoingCompetitionsFilteredArray} showCancelButton={false} />
                            </div>}
                            {ongoingCompetitionsFilteredArray.length === 0 && <div>
                                <p className={classes.noCompetitionsError}>{ErrorMessages.noOngoingCompetitionnFallBackMessage}</p>
                            </div>}
                        </div>}
                        {pastCompetitionsSection && <div className={classes.liveCompetitionsSection}>
                            <div>
                                <input type="text" className={classes.searchInputBox} value={pastCompetitionSearchTerm} onChange={(e) => { searchPastCompetitions(e) }} placeholder="Search Competitions" />
                                <MdClear className={classes.resetSearchIcon} onClick={() => { resetPastFilter() }} />
                            </div>
                            {pastCompetitionsFilteredArray.length !== 0 && <div>
                                <CompetitionsCards onViewCompDetails={showCompetitionDetails} competitionsData={pastCompetitionsFilteredArray.length === 0 ? pastCompetitionsArray : pastCompetitionsFilteredArray} showCancelButton={false} />
                            </div>}
                            {pastCompetitionsFilteredArray.length === 0 && <div>
                                <p className={classes.noCompetitionsError}>{ErrorMessages.noPastCompetitionFallbackMesssage}</p>
                            </div>}
                        </div>}
                        {cancelledCompetitionsSection && <div className={classes.cancelledCompetitionsSection}>
                            <div>
                                <input type="text" className={classes.searchInputBox} value={cancelledCompetitionSearchTerm} onChange={(e) => { searchCancelledCompetitions(e) }} placeholder="Search Competitions" />
                                <MdClear className={classes.resetSearchIcon} onClick={() => { resetCancelledFilter() }} />
                            </div>
                            {cancelledCompetitionsFilteredArray.length !== 0 && <div>
                                <CompetitionsCards onViewCompDetails={showCompetitionDetails} competitionsData={cancelledCompetitionsFilteredArray.length === 0 ? cancelledCompetitionsArray : cancelledCompetitionsFilteredArray} showCancelButton={false} />
                            </div>}
                            {cancelledCompetitionsFilteredArray.length === 0 && <div>
                                <p className={classes.noCompetitionsError}>{ErrorMessages.noCancelledCompetitionnFallBackMessage}</p>
                            </div>}
                        </div>}
                    </div>}
                    {showCompetitionDetailsSection && <div className={classes.viewCompetitionDetailsSection}>
                        <div><FaArrowLeft onClick={() => goBackToViewCompetitions()} className={classes.goBackButton} /></div>
                        {selectedCompetitionDetails.length !== 0 ?
                            showEditCompetitionsComponent ? <EditCompetitionComponent selectedSkillName={selectedCompetitionDetails.skillName} competitionId={selectedCompetitionDetails.uniqueId} showCompetitionDetails={showCompetitionDetails} /> : <div><ViewCompetitionDetailsAccordion competitionTab={competitionTab} selectedCompData={selectedCompetitionDetails} showEditCompetition={showEditCompetition} /></div>
                            : <div><ErrorSuccessAlertMessage message={ErrorMessages.expertFetchCompetitionDetailsError} varient={AppConstants.alertVarient[1]} /></div>}
                    </div>}
                </div>
            </div>
        </>
    )
}