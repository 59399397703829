import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./QtalksBanner.module.css";
import parse from 'html-react-parser';
import { TbShare3 } from 'react-icons/tb';
import { SuccessMessage } from "../../../constants/SuccessMessage";
import { ErrorSuccessAlertMessage } from '../../errorSuccessMessage/ErrorSuccessAlertMessage';
import { VideoPopup } from '../../popup/VideoPopup';
import { BsLinkedin, BsFacebook, BsTwitter, BsFillEnvelopeAtFill } from 'react-icons/bs';
import { AiOutlineCopy } from 'react-icons/ai';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AppConstants } from "../../../constants/AppConstants";
import { CountdownTimer } from "../../../utils/CountDownUtil";

export const QtalksBanner = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const domainName = process.env.REACT_APP_FRONT_END_URL;
    const purpleTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.purpleTriangleImagePath}`;
    const orangeRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.orangeRectangleImagePath}`;
    const blueRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueRectangleImagePath}`;
    const youtubeIconImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.youtubeIconImagePath}`;
    const faceBookIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.facebookIconPath}`;
    const instagramIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.instagramIconPath}`;
    const linkedInIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.linkedInIconPath}`;
    const twitterIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.twitterIconPath}`;
    const youtubeIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.youtubeIconPath}`;
    const websiteIconPath = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.websiteIconPath}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;

    const [qTalksData, setQTalksData] = useState(props.qTalk);

    const utcToLocalDateTime = new Date(DateTimeUtil.utcToLocal(`${qTalksData.qtalkDate}${AppConstants.space}${qTalksData.qtalkStartTime}`));
    const [qTalkDate, setQtalkDate] = useState(utcToLocalDateTime.getDate());
    const [qTalkMonth, setQTalkMonth] = useState(AppConstants.monthNames[utcToLocalDateTime.getMonth()]);
    const [qTalkDay, setQTalkDay] = useState(AppConstants.dayName[utcToLocalDateTime.getDay()]);
    const [qTalkStarttime, setQtalkStartTime] = useState(utcToLocalDateTime.toLocaleTimeString());
    const [qTalkEndTime, setEndTime] = useState(new Date(DateTimeUtil.utcToLocal(`${qTalksData.qtalkDate}${AppConstants.space}${qTalksData.qtalkEndTime}`)).toLocaleTimeString());


    const [linktoWatch, setLinkToWatch] = useState(AppConstants.emptyString);
    const linkToShare = `${domainName}${AppConstants.qtalks}${AppConstants.forwardSlash}${qTalksData.skillCategorySlug}${AppConstants.forwardSlash}${qTalksData.skillSlug}${AppConstants.forwardSlash}${AppConstants.qPrefix}${qTalksData.qtalkId}`;

    const linkToShareOnMail = AppConstants.youtubeEmbedLinkPrefix + qTalksData.qtalkYouTubeVideoLink;
    const [showModal, setShowModal] = useState(false);
    const [qTalkLive, setqTalkLive] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);

    const staticClasses = classes.btnWatchNow;
    const dynamicClass = qTalkLive === AppConstants.trueText ? classes.watchNowActive : classes.watchnowInactive;
    //success Message
    const [errorSuccessAlertVisiblity, setErrorSuccessAlertVisiblity] = useState(false);
    const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState('');
    const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState('');


    //Handler Function When counter goes OFF
    function timeOverHandler() {
        setqTalkLive(AppConstants.trueText)
        setLinkToWatch(qTalksData.qtalkYouTubeVideoLink);
        return qTalkLive;
    }

    //Method to open youtube to watch Qtalks
    function watchNowClickHandler(event) {
        if (qTalkLive) {
            window.open(linktoWatch, AppConstants.openInNewTab);
        }
        event.preventDefault();
    }
    //Share on social media 
    function shareOnSocialMedia() {
        setShowSocialMediaPannel(!showSocialMediaPannel);

    }
    //function to copy to clipboard
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(linkToShare);
        setErrorSuccessAlertMessage(SuccessMessage.linkCopiedToClipboard);
        setErrorSuccessAlertVarient(AppConstants.alertVarient[2]);
        setErrorSuccessAlertVisiblity(true);
        setTimeout(() => {
            setErrorSuccessAlertVisiblity(false)
        }, 3000);

    }
    //function Send on Mail
    function sendOnMail() {
        window.open(`${AppConstants.mailToURI}` + `${linkToShareOnMail}`, AppConstants.openInNewTab);
    }
    //Function to Share on facebook
    function shareOnFacebook() {
        window.open(`${AppConstants.facebookShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to share on Linkedin
    function shareOnLinkedIn() {
        window.open(`${AppConstants.linkedInShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //function to share on twitter
    function shareOnTwitter() {
        window.open(`${AppConstants.twitterShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Close the Modal div
    function closeQtalk() {
        setShowModal(false);
    }
    function OpenVideoPopup() {
        setShowModal(true);
    }
    return (
        <>
            <div className={classes.upcomingQtalksContainerRelative}>
                <div className={classes.upcomingQtalksImageContainer}>
                    <img src={qTalksData.qtalkWebBackgroungImagePath} alt={qTalksData.qtalkTitle} className={classes.upcomingQtalksWebImage} />
                    <img src={qTalksData.qtalkTabletBackgroungImagePath} alt={qTalksData.qtalkTitle} className={classes.upcomingQtalksTabImage} />
                    <img src={qTalksData.qtalkMobileBackgroungImagePath} alt={qTalksData.qtalkTitle} className={classes.upcomingQtalksMobileImage} />
                </div>

                <div className={classes.upcomingQtalksContainer}>
                    <div className={classes.aboutQtalkSection}>


                        <img
                            className={classes.qTalkpurpleTriangleImage}
                            src={purpleTriangleImageUrl}
                            alt="Purple Triangle"
                        />
                        <Link to={`${AppConstants.forwardSlash}${AppConstants.qtalks}${AppConstants.forwardSlash}${qTalksData.skillCategorySlug}${AppConstants.forwardSlash}${qTalksData.skillSlug}${AppConstants.forwardSlash}q-${qTalksData.qtalkId}`} className={classes.titleLink}>
                            <h1 className={classes.qTalksubTitle}>{parse(qTalksData.qtalkTitle)}</h1>
                        </Link>
                        <p className={classes.qTalkdescription}>{parse(qTalksData.qtalkDescription)}</p>
                        <hr className={classes.qtalkSeparatorLine} />
                        <div className={classes.speakerContainer}>
                            <div className={classes.speakerImageContainer}>
                                <img src={qTalksData.qtalkSpeakersDetails[0].speakerImagePath} alt={qTalksData.qtalkSpeakersDetails[0].speakerName} className={classes.qTalkSpeakerImage} />
                            </div>
                            <div className={classes.speakerDetailsContainer}>
                                <h4 className={classes.qTalkSpeakerName}>{qTalksData.qtalkSpeakersDetails[0].speakerName}</h4>
                                <p className={classes.qTalkSpeakerDesignation}>{qTalksData.qtalkSpeakersDetails[0].speakerDesignation}</p>
                                <div className={classes.unOrderedList}>
                                    {parse(qTalksData.qtalkSpeakersDetails[0].aboutSpeaker)}
                                </div>
                                <div className={classes.socialMediaIconsContainer}>
                                    {qTalksData.qtalkSpeakersDetails[0].speakerFacebookLink != null &&
                                        <Link className={classes.disableLink} to={qTalksData.qtalkSpeakersDetails[0].speakerFacebookLink} target={AppConstants.openInNewTab}>
                                            <img src={faceBookIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerFacebookProfileLink} />
                                        </Link>}
                                    {qTalksData.qtalkSpeakersDetails[0].speakerInstagramLink != null &&
                                        <Link className={classes.disableLink} to={qTalksData.qtalkSpeakersDetails[0].speakerInstagramLink} target={AppConstants.openInNewTab}>
                                            <img src={instagramIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerInstagramLink} />
                                        </Link>}
                                    {qTalksData.qtalkSpeakersDetails[0].speakerLinkedInLink != null &&
                                        <Link className={classes.disableLink} to={qTalksData.qtalkSpeakersDetails[0].speakerLinkedInLink} target={AppConstants.openInNewTab}>
                                            <img src={linkedInIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerLinkedInLink} />
                                        </Link>}
                                    {qTalksData.qtalkSpeakersDetails[0].speakerTwitterLink != null &&
                                        <Link className={classes.disableLink} to={qTalksData.qtalkSpeakersDetails[0].speakerTwitterLink} target={AppConstants.openInNewTab}>
                                            <img src={twitterIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerTwitterLink} />
                                        </Link>}
                                    {qTalksData.qtalkSpeakersDetails[0].speakerYoutubeLink != null &&
                                        <Link className={classes.disableLink} to={qTalksData.qtalkSpeakersDetails[0].speakerYoutubeLink} target={AppConstants.openInNewTab}>
                                            <img src={youtubeIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerYoutubeLink} />
                                        </Link>}
                                    {qTalksData.qtalkSpeakersDetails[0].speakerWebsiteLink != null &&
                                        <Link className={classes.disableLinkWebsite} to={qTalksData.qtalkSpeakersDetails[0].speakerWebsiteLink} target={AppConstants.openInNewTab}>
                                            <img src={websiteIconPath} className={classes.socialMediaIconsItem} alt={AppConstants.speakerWebsiteLink} />
                                        </Link>}

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className={classes.watchNowSectionContainer}>
                        <div className={classes.watchNowMainContainer}>
                            <div className={classes.watchNow}>
                                <div className={classes.qTalkDateTimeContainer}>
                                    <div className={classes.qTalkStartTime}>
                                        <p className={classes.qTalkTimeLabels}>Start</p>
                                        <p className={classes.qTalkTimeContent}>{qTalkStarttime}</p>
                                    </div>
                                    <div className={classes.qTalkDateSection}>
                                        <div className={classes.qTalkDate}>
                                            <p className={classes.month}>{qTalkMonth}</p>
                                            <p className={classes.boldDate}>{qTalkDate.toString().padStart(2, "0")}</p>
                                            <p className={classes.day}>{qTalkDay}</p>
                                        </div>
                                    </div>
                                    <div className={classes.qTalkEndTime}>
                                        <p className={classes.qTalkTimeLabels}>End</p>
                                        <p className={classes.qTalkTimeContent}>{qTalkEndTime}</p>
                                    </div>

                                    {showSocialMediaPannel && <div className={classes.shareIconPannelContainer}>
                                        <BsLinkedin className={classes.shareOnLinkedIn} onClick={shareOnLinkedIn} />
                                        <BsFacebook className={classes.shareOnFacebook} onClick={shareOnFacebook} />
                                        <BsTwitter className={classes.shareOnTwitter} onClick={shareOnTwitter} />
                                        <BsFillEnvelopeAtFill className={classes.shareOnMailButton} onClick={sendOnMail} />
                                        <AiOutlineCopy className={classes.copyToClipboard} onClick={copyToClipBoard} />
                                    </div>}
                                </div>
                                <hr className={classes.borderBottom}></hr>
                                {!qTalkLive && <div className={classes.qTalkCounterContainer}>
                                    <CountdownTimer countDownTimerDateBoxClass={classes.countDownTimerDateBox} countDownTimerDateBoxContainer={classes.countDownTimerDateBoxContainer} countDownTimerDateSeperatorClass={classes.countDownTimerDateSeperator} utcToLocalDateTime={utcToLocalDateTime} onTimeOver={timeOverHandler} />
                                </div>}
                                {!qTalkLive && <div className={classes.qTalkCounterSectionLabels}>
                                    <div className={classes.counterDaysLabel}>DAYS</div>
                                    <div className={classes.counterHoursLabel}>HOURS</div>
                                    <div className={classes.counterMinutesLabel}>MINUTES</div>
                                </div>}

                                {qTalkLive && <div className={classes.videoPopupSection}>
                                    <div className={classes.orangeFrame}>
                                        <img onClick={OpenVideoPopup}
                                            src={orangeRectangleImageUrl}
                                            alt="Video Frame"
                                        />
                                    </div>
                                    <div className={classes.thumbnailImage}>
                                        <img onClick={OpenVideoPopup}
                                            className={classes.videoThumbnailImage}
                                            src={qTalksData.qtalkThumbnailImagePath}
                                            alt="Video Thumbnail"
                                        />
                                    </div>

                                    <div className={classes.videoPlayImage}>
                                        <img onClick={OpenVideoPopup}
                                            className={classes.videoPlayIcon}
                                            src={videoPlayIconUrl}
                                            alt="Video Play Button"
                                        />
                                    </div>
                                    <div className={classes.blueFrame}>
                                        <img onClick={OpenVideoPopup}
                                            src={blueRectangleImageUrl}
                                            alt="Video Frame"
                                        />
                                    </div>
                                </div>}

                            </div>
                            {!qTalkLive && <div className={classes.qTalkWatchNowButtonContainer}>
                                <div class={classes.qTalkyoutubeIconContainer}>
                                    <div class={classes.youtubeImage}>
                                        <img src={youtubeIconImageUrl} alt="youtube icon" class={classes.qTalkyoutubeIcon} />
                                    </div>
                                    <div className={classes.watchForFreeTextContainer}>
                                        <p className={classes.watchForText}>{AppConstants.watchForText}</p>
                                        <p className={classes.freeText}>{AppConstants.freeText}</p>
                                    </div>
                                </div>
                                <button className={` ${dynamicClass} ${staticClasses}`} onClick={watchNowClickHandler} >{AppConstants.WatchNowButtonText}</button>
                            </div>}
                            <div className={classes.qTalkshareIconContainer}>
                                <TbShare3 className={classes.qTalkshareIcon} onClick={shareOnSocialMedia} />
                            </div>
                            {errorSuccessAlertVisiblity &&
                                <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                            }
                        </div>



                    </div>

                </div>
                {showModal && <VideoPopup onHideQrenciaAtGlanceVideoPopup={closeQtalk} videoUrl={qTalksData.qtalkYouTubeVideoLink} />}
            </div>
        </>
    )
}