import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Element } from 'react-scroll';
import { BiLinkExternal } from 'react-icons/bi';
import { AppConstants } from '../../constants/AppConstants';
import classes from "../individualCompetition/MainCompetitionDetailsSection.module.css";
import "./ApproveCompetitionLeftSeciton.module.css"
import { Button, Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import parse from 'html-react-parser';
import { AiOutlineExclamationCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import modalClass from "../cards/CompetitionsCards.module.css";
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';

export const ApproveCompetitionLeftSection = (props) => {
    const [competitionDetailsObj, setCompetitionDetailsObj] = useState(props.competitionData);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(false);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;


    //add active class on nav-item click
    function addActiveClass(e) {
        const elements = document.querySelectorAll('.active');
        elements.forEach((element, index) => {
            element.classList.remove("active");
            if (index === elements.length - 1) {
                e.target.className = "active";
            }
        });
        if (elements === null || elements === undefined || elements.length === 0) {
            e.target.className = "active";
        }
    }

    const onCloseModal = () => {
        setShowModal(false);
    };

    const downloadChallenge = (competitionDetailsObj) => {
        FileOperationsUtil.downloadProblemStatement(competitionDetailsObj.challengePath, competitionDetailsObj.competitionTitle);
    };

    return (
        <>
            <div className={classes.mainContainerSection}>
                <div className={classes.thumbnailSection}>
                    <div className={classes.thumbnailContainer}>
                        <img className={classes.thumbnail} src={competitionDetailsObj.competitionThumbnailImagePath !== null ? competitionDetailsObj.competitionThumbnailImagePath : defaultThumbnail} alt="Competition Thumbnail"></img>
                    </div>
                    <div className={classes.thumbnailDetailsSection}>
                        <p className={classes.competitionTitle}>{competitionDetailsObj.competitionTitle}</p>
                        <p className={classes.competitionDate}>{competitionDetailsObj.competitionType}</p>
                        <p className={classes.competitionDate}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> - <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                        {competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText && <a className={classes.competitionLink} target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionGeoLocationLink}>{competitionDetailsObj.competitionVenueAddress}</a>}
                        {competitionDetailsObj.competitionType.toUpperCase() === AppConstants.onlineText && competitionDetailsObj.competitionVcLink !== null && <p className={classes.competitionLink}>Join Now : <a className={classes.competitionLink} target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionVcLink}>{competitionDetailsObj.competitionVCLinkType === "Others" ? AppConstants.emptyString : competitionDetailsObj.competitionVCLinkType}<BiLinkExternal className="mb-1" /></a></p>}

                        <div className={`${classes.competitionDate} ${classes.flexDisplay}`}> <div className={classes.marginSkillSection}>Skill &nbsp;</div><p className={classes.competitionSkillHashtag}>#{competitionDetailsObj.competitionSkillName}</p></div>
                    </div>
                </div>
                <div className={classes.competitionNavBarContainer}>
                    <div className="mainCompetitionDetailsSectionNavbar">
                        <Navbar>
                            <Nav id="navList">
                                <Link onClick={(e) => { addActiveClass(e) }} to="details" spy={true} offset={-43} duration={100}>Details</Link>
                                <Link onClick={(e) => { addActiveClass(e) }} to="dates" spy={true} offset={-42} duration={100}>Dates</Link>
                                {/* {(competitionDetailsObj.challengeAdded) &&
                                    <Link onClick={(e) => { addActiveClass(e) }} to="challenge" spy={true} offset={-44} duration={100}>Challenge</Link>} */}
                                <Link onClick={(e) => { addActiveClass(e) }} to="organizer" spy={true} offset={-44} duration={100}>Organizer</Link>
                            </Nav>
                        </Navbar>
                    </div>
                </div>

                <Element id="details">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>About the Competition</h1>
                        <ul>
                            {competitionDetailsObj.competitionDescription && <li><strong>Description</strong></li>}
                            {competitionDetailsObj.competitionDescription && <div className={`${classes.guidelinesText} quillListIndent ql-editor ${classes.quillEditor}`}>{parse(competitionDetailsObj.competitionDescription)}</div>}
                            {(competitionDetailsObj.competitionGuidelinesText !== null) && <li><strong>General Rules and Guidelines</strong></li>}
                            {(competitionDetailsObj.competitionGuidelinesText !== null) && <div className={`${classes.guidelinesText} quillListIndent ql-editor ${classes.quillEditor}`}>{parse(competitionDetailsObj.competitionGuidelinesText)}</div>}
                            {competitionDetailsObj.competitionGuidelinesText === null && competitionDetailsObj.competitionGuidelinesDocPath !== null && <li><strong>General Rules and Guidelines</strong></li>}
                            {competitionDetailsObj.competitionGuidelinesText === null && competitionDetailsObj.competitionGuidelinesDocPath !== null && <div className='mt-2'><a target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionGuidelinesDocPath}>Read Here <BiLinkExternal /></a></div>}
                            {competitionDetailsObj.competitionGuidelinesDocPath === null && competitionDetailsObj.competitionGuidelinesText === null && <li><strong>General Rules and Guidelines</strong></li>}
                            {competitionDetailsObj.competitionGuidelinesDocPath === null && competitionDetailsObj.competitionGuidelinesText === null && <div className='mt-2'>{AppConstants.notAvailableText}<br></br> </div>}
                        </ul>
                    </div>
                </Element>
                <Element id="dates">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>Attempt & Submission Timelines</h1>
                        <ul>
                            <li className={`${classes.basicDetail} ${classes.dateColumn}`}><strong>Competition Date & Time</strong></li> <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> - <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div><br></br>
                            <li className={`${classes.basicDetail} ${classes.dateColumn}`}><strong>Registration Closing Time</strong></li> <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div><br></br>
                            {competitionDetailsObj.resultDate && <li className={classes.dateColumn}><strong>Results {competitionDetailsObj.resultDeclared}</strong></li>} {competitionDetailsObj.resultDate && <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.resultDate}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div>}

                        </ul>
                    </div>
                </Element>
                {/* {competitionDetailsObj.challengeAdded &&
                    <Element id="challenge">
                        <div className={classes.competitionDetailsSection}>
                            <p className={classes.competitionDetailsHeading}>Challenge</p>
                            <ul>
                                {competitionDetailsObj.challengeAdded && <li>
                                    {(competitionDetailsObj.challengeAdded) ? <div className={classes.competitionChallengeContainer}>
                                        <p><strong>Challenge</strong><span data-tooltip-place="right" data-tooltip-id="downloadChallengeId"></span></p>
                                        <Button className={classes.downloadChallengeButton} onClick={() => { downloadChallenge(competitionDetailsObj) }}>Download</Button>
                                    </div> : <div className={classes.competitionChallengeContainer}>
                                        <p><strong>Challenge</strong><span data-tooltip-place="right" data-tooltip-id="competitionNotLiveId"><AiOutlineInfoCircle className={classes.iButton} /></span></p>
                                        <Tooltip className={classes.tooltipMessage} id="competitionNotLiveId">{AppConstants.ibuttonMessageWhenCompetitionNotLive}</Tooltip>
                                    </div>}
                                </li>}
                            </ul>
                        </div>
                    </Element>} */}
                <Element id="organizer">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>About Organizer</h1>
                        <div className={classes.profilePicMainCnt}>
                            <div className={classes.profilePicPreviewCnt}>
                                {competitionDetailsObj.expertProfilePicPath !== null && <a target={AppConstants.openInNewTab} href={competitionDetailsObj.userRole === AppConstants.userRoleExpert ? `/expert/${competitionDetailsObj.userSlug}` : `/agency/${competitionDetailsObj.userSlug}`}><img alt='Profile Avtar' className={classes.previewProfilePic} src={competitionDetailsObj.expertProfilePicPath}></img></a>}
                                {competitionDetailsObj.expertProfilePicPath === null && <p className={classes.initialProfilePreview}>{competitionDetailsObj.expertInitialName}</p>}
                            </div>
                            {competitionDetailsObj.userRole === AppConstants.userRoleExpert && <div className={classes.profilePicPreviewDetails}>
                                <p><strong>Name</strong> : <a className={classes.nameLink} target={AppConstants.openInNewTab} href={`/expert/${competitionDetailsObj.userSlug}`}>{competitionDetailsObj.expertName}</a></p>
                                <p><strong>Skill Expertise</strong> : {competitionDetailsObj.expertSkills}</p>
                                {competitionDetailsObj.expertOrganizationName !== null && competitionDetailsObj.expertOrganizationName !== "N/A" && <p><strong>Organization</strong> : {competitionDetailsObj.expertOrganizationName}</p>}
                            </div>}
                            {competitionDetailsObj.userRole === AppConstants.userRoleAgency && <div className={classes.profilePicPreviewDetails}>
                                <p><strong>Agency Name</strong> : <a className={classes.nameLink} target={AppConstants.openInNewTab} href={`/agency/${competitionDetailsObj.userSlug}`}>{competitionDetailsObj.agencyName}</a></p>
                            </div>}
                        </div>
                    </div>
                </Element>
            </div>
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};