import { useEffect, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { StudentRecentlyPublishedCompetitionCards } from "../../cards/StudentRecentlyPublishedCompetitionCards";

export const StudentCompetitions = (props) => {
    const [filteredList, setFilteredList] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchCompetitionsList = async () => {
            const url = props.apiEndpoint;
            const requestHeaders = {
                preferredTimeZone
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            if (response) {
                setCompetitions(response);
                setFilteredList(response);
            }
            return null;
        }
        if (props.categoryList?.length > 0) {
            fetchCompetitionsList()
        }
    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(competitions);
            } else {
                setFilteredList(competitions?.filter(competition => competition.categoryName === props.selectedCategory));
            }
        } else {
            setFilteredList(competitions);
        }
    }, [props.selectedCategory]);

    return (
        <div>
            {filteredList?.length > 0 && <StudentRecentlyPublishedCompetitionCards minimumAgeLimit={props.minimumAgeLimit} cardDetails={filteredList} teamEnrolmentEnabledFlag={props.teamEnrolmentEnabledFlag} />}
            {(!filteredList || filteredList?.length === 0) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No ${props.category} competitions for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === 0) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className='noDataAvailableError'>{`No ${props.category} competitions to display at the moment`}</p>}
        </div>
    )
}