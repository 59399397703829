import parse from 'html-react-parser';
import { HiSparkles } from "react-icons/hi2";
import { Button } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from "./QrenciaForExpertsSection.module.css";
import discoverPassionClasses from './DiscoverYourPassionUpdatedSection.module.css';

export const QrenciaForExpertsSection = ({ qernciaForExperts }) => {
    const desktopDetails = qernciaForExperts?.list?.filter(listItem => listItem?.imagePath?.includes(AppConstants.desktopText))[0];
    const tabletDetails = qernciaForExperts?.list?.filter(listItem => listItem?.imagePath?.includes(AppConstants.tabletText))[0];
    const mobileDetails = qernciaForExperts?.list?.filter(listItem => listItem?.imagePath?.includes(AppConstants.mobileText))[0];

    const lightBlueTriangleImageUrl = `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}/${AppConstants.lightBlueTriangleImagePath}`;

    const renderSection = (details, buttonClassName, screen) => {
        if (!details.active) return AppConstants.nullText;

        return (
            <div className={screen === AppConstants.desktopText ? classes.qrenciaForExpertsSectionDesktop : screen === AppConstants.tabletText ? classes.qrenciaForExpertsSectionTablet : classes.qrenciaForExpertsSectionMobile}>
                <div className={classes.qrenciaForExpertsContent}>
                    <div className={discoverPassionClasses.discoverContentSection}>
                        <h2 className={classes.titleText}>{qernciaForExperts?.description}</h2>
                        <img className={classes.blueTriangleImage} src={lightBlueTriangleImageUrl} alt="Blue Triangle" />
                        <h2 className={discoverPassionClasses.heading}>{parse(qernciaForExperts.title)}</h2>
                        <div className={classes.imageContainer}>
                            <img className={screen === AppConstants.desktopText ? classes.expertDesktopImage : screen === AppConstants.tabletText ? classes.expertTabletImage : classes.expertMobileImage} src={details.imagePath} alt="for_experts" />
                            <div className={screen === AppConstants.desktopText ? classes.textBoxDesktop : screen === AppConstants.tabletText ? classes.textBoxTablet : classes.textBoxMobile}>
                                <div className={classes.textBoxContent}>
                                    <div className={screen === AppConstants.desktopText ? classes.contentListDesktop : screen === AppConstants.tabletText ? classes.contentListTablet : classes.contentListMobile}>{parse(details.description)}</div>
                                    <Button onClick={() => { window.open(details.link) }} className={buttonClassName}>{details.buttonText} <HiSparkles /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {renderSection(desktopDetails, classes.getStartedButtonDesktop, AppConstants.desktopText)}
            {renderSection(tabletDetails, classes.getStartedButtonTablet, AppConstants.tabletText)}
            {renderSection(mobileDetails, classes.getStartedButtonMobile, AppConstants.mobileText)}
        </>
    );
};
