import React, { useState, useEffect } from 'react';
import classes from '../individualCoursePage/individualCoursePageBanner/IndividualCoursePageBanner.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const QuizEventBanner = (props) => {
    const [mobileImage, setMobileImage] = useState(AppConstants.nullText);
    const [tabletImage, setTabletImage] = useState(AppConstants.nullText);
    const [webImage, setWebImage] = useState(AppConstants.nullText);

    useEffect(() => {
        setWebImage(props.quizDetails.webBannerImage);
        setTabletImage(props.quizDetails.tabletBannerImage);
        setMobileImage(props.quizDetails.mobileBannerImage);
    }, [props.quizDetails]);

    return (
        <>
            <div className={classes.mobileImgContainer}>
                <img src={mobileImage} alt={AppConstants.courseBannerText} className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseTabletImgContainer}>
                <img src={tabletImage} alt={AppConstants.courseBannerText} className={classes.courseBannerImage} />
            </div>
            <div className={classes.courseBannerImgContainer}>
                <img src={webImage} alt={AppConstants.courseBannerText} className={classes.courseWebBannerImage} />
            </div>
        </>
    );

}
