import React, { useEffect, useState } from 'react';
import styles from "./StudentLearningActivity.module.css";
import { AppConstants } from '../../constants/AppConstants';
import { PerformanceAnalysisPageLearningActivitiesCard } from '../cards/PerformanceAnalysisPageLearningActivitiesCard';

export const StudentLearningActivity = (props) => {
    const [learningActivityArray, setLearningActivityArray] = useState(props.learningActivityDetails);
    const [studentDetails, setStudentDetails] = useState(props.competitionDetails);

    useEffect(() => {
        setLearningActivityArray(props.learningActivityDetails);
        setStudentDetails(props.competitionDetails);
    })

    return (
        <>
            <div className={styles.learningActivitySectionContainer}>
                <div className={styles.topHeadingContainer}>
                    <h1 className={styles.headingText}><strong>{AppConstants.performanceAnalysisPageLearningActivitySectionText.headingTextPartOne} </strong>
                        {AppConstants.performanceAnalysisPageLearningActivitySectionText.headingTextPartTwo}</h1>
                    <p className={styles.subHeadingText}>{AppConstants.performanceAnalysisPageLearningActivitySectionText.subHeadingText} {studentDetails.studentName}!</p>
                </div>
                <div className={styles.bottomContentContainer}>
                    <PerformanceAnalysisPageLearningActivitiesCard cardDetails={learningActivityArray} category="Learning Activity" />
                </div>
            </div>
        </>
    );
}

