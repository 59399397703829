import React from "react";
import parse from "html-react-parser";
import { AppConstants } from "../../../constants/AppConstants";
import classes from './QTalksKeyHighlightSection.module.css';
export const QTalksKeyHighlightSection = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const orangeRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.orangeRectangleImagePath}`;
    const blueRectangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueRectangleImagePath}`;
    return (
        <>
            <div className={classes.highLightsContainer}>
                <div className={classes.highLight}>
                    <label className={classes.highLighText}>{parse(AppConstants.qTalkHighlights)}</label>
                    <div className={classes.highLightBulletPoints}>
                    {parse(props.qTalk.qtalkKeyHighlights)}
                    </div>
                </div>
                <div className={classes.imageContainer}>
                <div className={classes.orangeFrame}>
                                        <img 
                                            src={orangeRectangleImageUrl}
                                            alt="Video Frame"
                                        />
                                    </div>
                    <img src={props.qTalk.eventImagePath} alt={AppConstants.qTalksImageAlt} className={classes.qTalkEventImage} />
                    <div className={classes.blueFrame}>
                                        <img 
                                            src={blueRectangleImageUrl}
                                            alt="Video Frame"
                                        />
                                    </div>
                </div>
            </div>

        </>
    )
}