import React from "react";
import { StudentActivitySnapshot } from "../../component/studentPageComponents/StudentActivitySnapshot";
import { StudentLearningActivitiy } from "../../component/studentPageComponents/StudentLearningActivitiy";
import Auth from "../../utils/Auth";
import { AppConstants } from "../../constants/AppConstants";
export const StudentMyLearningPage = () => {
    return (
        <div className="studentContentSection">
            {Auth.getUserRole() === AppConstants.userRoleStudent && <StudentActivitySnapshot />}
            <StudentLearningActivitiy />
        </div>
    )
}