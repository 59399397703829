import { useState } from 'react';
import { Button, Form, Row, Col } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { MdOpenInNew } from 'react-icons/md';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { BiLoaderAlt } from 'react-icons/bi';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { ErrorSuccessAlertMessage } from '../errorSuccessMessage/ErrorSuccessAlertMessage';
import { AppConstants } from '../../constants/AppConstants';
import classes from "./MapTeachersWithSchool.module.css";
import formClass from "../../common/FormStyling.module.css";

export const MapTeachersWithSchool = () => {
    const [expertId, setExpertId] = useState(AppConstants.emptyString);
    const [expertErrorMessage, setExpertErrorMessage] = useState(AppConstants.emptyString);
    const [detailsSpinner, setDetailsSpinner] = useState(AppConstants.falseText);
    const [expertDetails, setExpertDetails] = useState({});
    const [mapAlertMessage, setMapAlertMessage] = useState(AppConstants.emptyString);
    const [mapAlertVarient, setMapAlertVarient] = useState(AppConstants.emptyString);
    const [mapSpinner, setMapSpinner] = useState(AppConstants.falseText);

    const fetchExpertDetails = async (e) => {
        try {
            e.preventDefault();
            setExpertDetails({});
            setDetailsSpinner(AppConstants.trueText);

            const url = `${AppConstants.getExpertDetailsForQrenciaIdAPI}${expertId}`;
            const response = await AuthorizedDataService.getRequestWithResponseCode(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.status === AppConstants.httpResponseCodes.responseCode400) {
                setExpertErrorMessage(`${ErrorMessages.agencyNotExpertIdError}${AppConstants.space}${expertId}${AppConstants.space}${ErrorMessages.doesNotExistsText}`);
                setDetailsSpinner(AppConstants.falseText);
                let timer = setTimeout(() => {
                    setExpertErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                setExpertErrorMessage(ErrorMessages.agencyShowExpertDetailsError);
                setDetailsSpinner(AppConstants.falseText);
                let timer = setTimeout(() => {
                    setExpertErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else if (response) {
                setExpertDetails(response);
                setExpertErrorMessage(AppConstants.emptyString);
                setDetailsSpinner(AppConstants.falseText);
            }
        } catch (error) {
            setExpertErrorMessage(ErrorMessages.agencyShowExpertDetailsError);
            setDetailsSpinner(AppConstants.falseText);
            let timer = setTimeout(() => {
                setExpertErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const mapExpertWithSchool = async () => {
        try {
            setMapSpinner(AppConstants.trueText);
            const url = `${AppConstants.sendMapRequestToExpertByAgencyAPI}${expertDetails.expertUniqueId}`;
            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok) {
                setMapSpinner(AppConstants.falseText);
                setMapAlertVarient(AppConstants.alertVarient[0]);
                setMapAlertMessage(SuccessMessage.mappingLinkSentSuccessfullyMessage);
                let timer = setTimeout(() => {
                    setMapAlertMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                setMapSpinner(AppConstants.falseText);
                setMapAlertVarient(AppConstants.alertVarient[1]);
                setMapAlertMessage(ErrorMessages.expertAlreadyAssociatedWithSameSchool);
                let timer = setTimeout(() => {
                    setMapAlertMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else {
                setMapSpinner(AppConstants.falseText);
                setMapAlertVarient(AppConstants.alertVarient[1]);
                setMapAlertMessage(ErrorMessages.mappingLinkSendError);
                let timer = setTimeout(() => {
                    setMapAlertMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setMapSpinner(AppConstants.falseText);
            setMapAlertVarient(AppConstants.alertVarient[1]);
            setMapAlertMessage(ErrorMessages.mappingLinkSendError);
            let timer = setTimeout(() => {
                setMapAlertMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const handleKeyDown = (event) => {
        let excludedKeywords = AppConstants.excludedKeywordsNumberField;
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    return <div className={classes.detailsContainer}>
        <form onSubmit={(e) => fetchExpertDetails(e)} id="getExpertDetails">
            <Row>
                <Col xs={12} sm={12} md={6} lg={5}>
                    <Form.Label className={formClass.formLabel}>Expert Qrencia Id</Form.Label>
                    <Form.Control className={formClass.uploadFileInput}
                        type={AppConstants.inputFieldTypes.number}
                        onKeyDown={handleKeyDown}
                        required
                        onChange={event => setExpertId(event.target.value)}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                    <Button disabled={detailsSpinner} type="submit" className={classes.selectButton}>Show {detailsSpinner && <BiLoaderAlt className='spinner' />}</Button>
                </Col>
            </Row>
        </form>

        {expertErrorMessage && <ErrorSuccessAlertMessage message={expertErrorMessage} varient={AppConstants.alertVarient[1]} />}

        {expertDetails.expertQrenciaId && <Row className="mt-2">
            <hr className={classes.detailsColumHrContainer} />
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.columnContainer}>
                    <h5 className={classes.detailsHeading}>Expert Details</h5>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Expert Id</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertQrenciaId}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Expert Name</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertName}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Email</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertEmail}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Phone Number</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertCountryMobileCode}-{expertDetails.expertMobileNumber}</p>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Country</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertCountry}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>State</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertState ? expertDetails.expertState : AppConstants.notAvailableText}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>City</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertCity ? expertDetails.expertCity : AppConstants.notAvailableText}</p>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Years of experience</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertYearsOfExperience ? expertDetails.expertYearsOfExperience : AppConstants.notAvailableText}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Expert Rating</p>
                    <p className={classes.expertDetailsText}>{expertDetails.expertRating}</p>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Branding Page</p>
                    <a className={classes.expertDetailsLinkLabel} target={AppConstants.openInNewTab} href={`${AppConstants.forwardSlash}${AppConstants.expert}${AppConstants.forwardSlash}${expertDetails.expertUserSlug}`}>Click Here <MdOpenInNew /></a>
                </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>Skills</p>
                    {(expertDetails.expertSkills !== AppConstants.nullText) &&
                        (<ul className={classes.skillsList}>
                            {expertDetails.expertSkills.map((item, index) => (
                                <li className={classes.expertDetailsListElement} key={index}>{item.skillName}{index !== expertDetails.expertSkills.length - 1 && ", "}</li>
                            )
                            )}
                        </ul>)}
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.columnContainer}>
                    <p className={classes.expertDetailsLabel}>About Expert</p>
                    <p className={classes.expertDetailsText}>{expertDetails.aboutExpert !== null ? expertDetails.aboutExpert : AppConstants.notAvailableText}</p>
                </div>
            </Col>
            <div>
                <span data-tooltip-id="expertAssociatedMessage">
                    <Button disabled={expertDetails.expertAgencyAssociationStatus === AppConstants.agencyExpertInvitationStatus.associated || mapSpinner} onClick={mapExpertWithSchool} className={classes.selectButton}>Map Expert {mapSpinner && <BiLoaderAlt className='spinner' />}</Button>
                </span>
                {expertDetails.expertAgencyAssociationStatus === AppConstants.agencyExpertInvitationStatus.associated && < Tooltip className={classes.tooltipMessage} id="expertAssociatedMessage"><span className={classes.tooltipMessage}>The teacher has already accepted the association request of a school</span></Tooltip>}
            </div>
            {mapAlertMessage && <ErrorSuccessAlertMessage message={mapAlertMessage} varient={mapAlertVarient} />}
        </Row>}
    </div>
}