import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from './AgencyMapExpertsPage.module.css';
import { MapTeachersWithSchool } from "../../component/schoolExpertMapping/MapTeachersWithSchool";
import { ShowSchoolTeachersList } from "../../component/schoolExpertMapping/ShowSchoolTeachersList";

export const AgencyMapExpertsPage = () => {
    const navigate = useNavigate();
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    useEffect(() => {
        const getClubQrenciaAssociationStatus = async () => {
            try {
                const url = AppConstants.getLoggedInAgencyTypeAPI;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response && response.agencyType !== AppConstants.agencyTypes.school) {
                    navigate(errorPageUrl);
                }
            } catch (error) {
                navigate(errorPageUrl);
            }
        };
        getClubQrenciaAssociationStatus();
    }, []);

    return (
        <div className="agencyContentSection">
            <h3 className={classes.heading}>Add Teacher</h3>
            <MapTeachersWithSchool />
            <h3 className={`${classes.heading} mt-4`}>Teachers List</h3>
            <ShowSchoolTeachersList />
        </div>
    )
}