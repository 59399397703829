import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdCopy } from "react-icons/io";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import { Badge, Button, Form } from "react-bootstrap";
import classes from '../../common/EventQuizPage.module.css';
import formClass from "../../common/FormStyling.module.css";
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
import { FaArrowRight } from "react-icons/fa";
import { QrenciaLoader } from "../../component/loader/QrenciaLoader";
import { QuizEventBanner } from "../../component/quizEventComponent/QuizEventBanner";
import { InverseCounterInMilliseconds } from "../../utils/InverseCounterInMilliseconds";
import { Tooltip } from "react-tooltip";
import Auth from "../../utils/Auth";
import { QuizAuthenticationForm } from "../../component/quizEventComponent/QuizAuthenticationForm";
import { CaptchaComponent } from "../../component/quizEventComponent/CaptchaComponent";

export const QuizEventPage = () => {
    const { quizSlug } = useParams();
    const [landingPageData, setLandingPageData] = useState();
    const [pageLoader, setPageLoader] = useState(AppConstants.falseText);
    const [selectedQuestionList, setSelectedQuestionList] = useState([]);
    const [quizType, setQuizType] = useState(AppConstants.emptyString);
    const [answer, setAnswer] = useState(AppConstants.emptyString);
    const [showResultScreen, setShowResultScreen] = useState(AppConstants.falseText);
    const [quizData, setQuizData] = useState({});
    const [captchaSiteKey, setCaptchaSiteKey] = useState(AppConstants.emptyString);
    const [score, setScore] = useState(0);
    const [attempted, setAttempted] = useState(0);
    const [quizLoader, setQuizLoader] = useState(AppConstants.trueText);
    const [successCode, setSuccessCode] = useState(AppConstants.emptyString);
    const [selectedOption, setSelectedOption] = useState(new Array(4).fill(AppConstants.falseText));
    const [currentQuestionCount, setCurrentQuestionCount] = useState(1);
    const [showCaptchaScreen, setShowCaptchaScreen] = useState(AppConstants.falseText);
    const [showLoginScreen, setShowLoginScreen] = useState(AppConstants.falseText);
    const [showResultScreenLoader, setShowResultScreenLoader] = useState(AppConstants.falseText);
    const [isTimerUP, setIsTimerUp] = useState(AppConstants.falseText);
    const [pauseTimer, setPauseTimer] = useState(AppConstants.falseText);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(AppConstants.falseText)

    const navigate = useNavigate();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    const trophyIcon = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.discoverYourPassionS3Path}/${AppConstants.competitionsIconImage}`;

    const fetchPageData = useCallback(async () => {
        setQuizLoader(AppConstants.trueText);
        if (!quizSlug) {
            navigate(AppConstants.asterisk);
        } else {
            const url = `${AppConstants.getQuizDetailsAPI}${quizSlug}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setPageLoader(AppConstants.falseText);
                setQuizData(response);
                setQuizType(response.type);
                setQuizLoader(AppConstants.falseText);
                let selectedQuestions = [];
                const selectedUniqueIds = [];
                if (response.questions.length < response.numberOfQuestionsToDisplay) {
                    navigate(AppConstants.asterisk);
                } else {
                    while (selectedQuestions.length !== response.numberOfQuestionsToDisplay) {
                        let selectedIndex = Math.floor(Math.random() * response.questions.length);
                        let question = response.questions[selectedIndex];
                        if (!selectedUniqueIds.includes(question.questionUniqueId)) {
                            selectedUniqueIds.push(question.questionUniqueId);
                            selectedQuestions.push(question);
                        }
                    }
                    setSelectedQuestionList(selectedQuestions);
                }
            } else {
                setQuizLoader(AppConstants.falseText);
                navigate(AppConstants.asterisk);
                setQuizData(AppConstants.falseText);
            }
        }
    }, [navigate, quizSlug]);

    useEffect(() => {
        fetchLandingPageData();
        async function fetchLandingPageData() {
            const url = AppConstants.landingPageDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setLandingPageData(response);
        };

        async function checkQuizAuthentication() {
            try {
                const url = `${AppConstants.checkIfLoginIsRequiredForQuizAPI}${quizSlug}`;
                const response = await DataService.getWithResponseCode(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
                    const authRequired = await response.json();
                    if (authRequired) {
                        if (!Auth.isLogin()) {
                            setShowLoginScreen(AppConstants.trueText);
                        } else {
                            fetchPageData();
                        }
                    } else {
                        if (Auth.isLogin()) {
                            fetchPageData();
                        } else {
                            setShowCaptchaScreen(AppConstants.trueText);
                        }
                    }
                } else {
                    setQuizLoader(AppConstants.falseText);
                    navigate(AppConstants.asterisk);
                    setQuizData(AppConstants.falseText);
                }
            } catch (error) {
                setQuizLoader(AppConstants.falseText);
                navigate(AppConstants.asterisk);
                setQuizData(AppConstants.falseText);
            }
        }

        async function getRecaptchaKey() {
            const url = AppConstants.getRecaptchaSiteKeyAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setCaptchaSiteKey(response.siteKey);
            }
        }
        getRecaptchaKey();
        checkQuizAuthentication();
    }, [fetchPageData, quizSlug, navigate]);

    const toggleCaptchaScreen = (value) => {
        setShowCaptchaScreen(value);
    };

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            const confirmationMessage = AppConstants.onLeaveMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (showResultScreenLoader) {
            let timer = setTimeout(() => {
                setShowResultScreenLoader(AppConstants.falseText);
            }, 3000);
            return () => { clearTimeout(timer) };
        }
    }, [showResultScreenLoader]);

    const postSuccessCode = async (code) => {
        try {
            const url = AppConstants.postSuccessCodeForQuizAPI;
            const data = {
                uniqueId: quizSlug,
                successCode: code
            }
            const response = await DataService.post(
                url,
                data,
                AppConstants.emptyString
            );

            if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                return AppConstants.trueText;
            } else {
                return AppConstants.falseText
            }
        } catch (error) {
            return AppConstants.falseText;
        }
    }

    function generateSuccessCode(length) {
        const charset = AppConstants.alphaNumericCharSet;
        let result = AppConstants.emptyString;
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset.charAt(randomIndex);
        }
        return result;
    };


    const proceedToNextQuestion = (timerUp = AppConstants.falseText) => {
        setAnswer(AppConstants.emptyString);
        setShowCorrectAnswer(AppConstants.falseText);
        setSelectedOption(new Array(4).fill(AppConstants.falseText));
        if (currentQuestionCount < selectedQuestionList.length && !timerUp) {
            setCurrentQuestionCount(currentQuestionCount => currentQuestionCount + 1);
        } else {
            const code = generateSuccessCode(AppConstants.quizSuccessCodeGeneratorLength);
            setSuccessCode(code);
            postSuccessCode(code);
            setShowResultScreenLoader(AppConstants.trueText);
            setShowResultScreen(AppConstants.trueText);
        }
    }

    const validateAnswer = (timerUp = AppConstants.falseText) => {
        const correctAnswerArray = selectedQuestionList[currentQuestionCount - 1].correctAnswer.split(AppConstants.correctAnswerDelimiter);
        const selectedAnswer = answer !== AppConstants.emptyString ? answer?.toLowerCase()?.trim() : AppConstants.emptyString;
        let validated = AppConstants.falseText;

        if (quizType === AppConstants.quizType.text) {
            if (selectedAnswer !== AppConstants.emptyString) {
                correctAnswerArray.forEach(answer => {
                    if (answer?.toLowerCase()?.trim() === selectedAnswer) {
                        validated = AppConstants.trueText;
                    }
                });
            }
        } else {

            if (answer.toLowerCase().trim() === selectedQuestionList[currentQuestionCount - 1].correctAnswer?.toLowerCase()?.trim()) {
                validated = AppConstants.trueText;
            }
        }

        if (answer !== AppConstants.emptyString && answer.trim().length > 0) {
            setAttempted(attempted => attempted + 1);
        }

        if (validated) {
            setScore(score => score + 1);
        }

        if (quizType === AppConstants.quizType.mcq) {
            setShowCorrectAnswer(AppConstants.trueText);
            setPauseTimer(AppConstants.trueText);
            setTimeout(() => {
                setShowCorrectAnswer(AppConstants.falseText);
                setPauseTimer(AppConstants.falseText);
                proceedToNextQuestion(timerUp);
            }, AppConstants.qiuzCorrectAnswerDisplayDuration);
        } else {
            proceedToNextQuestion(timerUp);
        }
    };

    const onTimerUp = () => {
        validateAnswer(AppConstants.trueText);
        setIsTimerUp(AppConstants.trueText);
    };

    const changeSelectedOption = (idx, answer) => {
        if (!showCorrectAnswer) {
            setSelectedOption(prevState => prevState.map((state, i) => i === idx ? !state : AppConstants.falseText));
            setAnswer(answer)
        }
    };

    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(successCode);
    };

    const closeAuthenticationForm = () => {
        setShowLoginScreen(AppConstants.falseText);
        fetchPageData();
    };

    const isSelectedOptionCorrect = (option) => {
        return selectedQuestionList[currentQuestionCount - 1].correctAnswer?.toLowerCase() === option?.toLowerCase();
    }

    return (
        <>
            {!pageLoader && <div className={classes.eventPage}>
                {
                    showCaptchaScreen ? <div>
                        <LoginPageTopNavigation />
                        <div className={classes.captchaPage}>
                            <p className={classes.captchaPageHeading}>{AppConstants.quizPageHeading}</p>
                            <p className={classes.captchaPageSubtext}>{AppConstants.quizPageSubHeading}</p>
                            {captchaSiteKey && <div className={classes.captchaContainer}>
                                <CaptchaComponent fetchPageData={fetchPageData} toggleCaptchaScreen={toggleCaptchaScreen} captchaSiteKey={captchaSiteKey} />
                            </div>}
                        </div>
                        {landingPageData && <LandingPageFooter landingPageData={landingPageData} />}
                    </div> : showLoginScreen ? <div className={classes.authPage}>
                        <QuizAuthenticationForm closeAuthenticationForm={closeAuthenticationForm} signupHeading="Signup to start the Quiz" loginHeading="Login to start the Quiz" />
                    </div> : quizLoader ? <>
                        <QrenciaLoader loaderText="" />
                    </> : <div>
                        <LoginPageTopNavigation />
                        <QuizEventBanner quizDetails={quizData} />
                        {showResultScreen ? <>
                            {showResultScreenLoader ?
                                <div className={classes.resultLoaderContainer}>
                                    <div className={classes.preLoaderContainer} >
                                        <div className={classes.preLoaderDiv}>
                                            <div className={classes.loader}></div>
                                            <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                                            {isTimerUP && <p className={classes.loaderText}>Oops! Time up</p>}
                                            {<p className={classes.loaderText}>Submitting your answers. Please Wait..</p>}
                                        </div>
                                    </div >
                                </div>
                                : <div className={classes.resultContainer}>
                                    <img className={classes.resultSuccessIcon} alt="trophy_icon" src={trophyIcon} />
                                    <div className={classes.resultScore}>Your score is {score}/{selectedQuestionList.length}</div>
                                    <div>
                                        <p className={classes.successMessage}>{AppConstants.quizPageParticipationMessage}</p>
                                        <p className={classes.successCodeMessage}>{AppConstants.quizCompletionCodeMessage} <span>{successCode}   <IoMdCopy className={classes.copyCodeIcon} onClick={copyToClipBoard} data-tooltip-id="clickToCopyMessage" /></span>
                                            <Tooltip className={classes.tooltipMessage} id="clickToCopyMessage">Click to copy code</Tooltip>
                                        </p>
                                    </div>
                                </div>}
                        </> : <div className={classes.quizContainer}>
                            <InverseCounterInMilliseconds durationInMs={quizData.timerDuration} onTimerUp={onTimerUp} pauseTimer={pauseTimer} />
                            <div className={classes.scoreSection}>
                                <Badge className={classes.attemptBadge}>{attempted}/{selectedQuestionList.length} Attempted</Badge>
                            </div>
                            <div>
                                <div className={classes.questionTextHeading}>Question</div>
                                <div className={classes.questionTextDescription}>{selectedQuestionList[currentQuestionCount - 1]?.question}</div>
                                {quizType === "Text" ? <div className={classes.inputFieldContainer}>
                                    <Form.Group>
                                        <Form.Label className={formClass.formLabel}>Answer</Form.Label>
                                        <Form.Control className={formClass.formInput}
                                            type="text"
                                            placeholder="Type your answer"
                                            value={answer}
                                            onChange={e => setAnswer(e.target.value)} />
                                    </Form.Group>
                                </div>
                                    : <div>
                                        <div className={`${classes.options} ${showCorrectAnswer && isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionOne) && classes.correctOption} ${showCorrectAnswer && !isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionOne) && selectedOption[0] ? classes.wrongOption : selectedOption[0] && classes.selectedOption}`} onClick={() => changeSelectedOption(0, selectedQuestionList[currentQuestionCount - 1]?.optionOne)}>
                                            {selectedQuestionList[currentQuestionCount - 1]?.optionOne}
                                        </div>
                                        <div className={`${classes.options} ${showCorrectAnswer && isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionTwo) && classes.correctOption}  ${showCorrectAnswer && !isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionTwo) && selectedOption[1] ? classes.wrongOption : selectedOption[1] && classes.selectedOption}`} onClick={() => changeSelectedOption(1, selectedQuestionList[currentQuestionCount - 1]?.optionTwo)}>
                                            {selectedQuestionList[currentQuestionCount - 1]?.optionTwo}
                                        </div>
                                        <div className={`${classes.options} ${showCorrectAnswer && isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionThree) && classes.correctOption} ${showCorrectAnswer && !isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionThree) && selectedOption[2] ? classes.wrongOption : selectedOption[2] && classes.selectedOption}`} onClick={() => changeSelectedOption(2, selectedQuestionList[currentQuestionCount - 1]?.optionThree)}>
                                            {selectedQuestionList[currentQuestionCount - 1]?.optionThree}
                                        </div>
                                        <div className={`${classes.options} ${showCorrectAnswer && isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionFour) && classes.correctOption} ${showCorrectAnswer && !isSelectedOptionCorrect(selectedQuestionList[currentQuestionCount - 1]?.optionFour) && selectedOption[3] ? classes.wrongOption : selectedOption[3] && classes.selectedOption}`} onClick={() => changeSelectedOption(3, selectedQuestionList[currentQuestionCount - 1]?.optionFour)}>
                                            {selectedQuestionList[currentQuestionCount - 1]?.optionFour}
                                        </div>
                                    </div>}

                                {quizType === AppConstants.quizType.mcq && <div className={classes.buttonSection}>
                                    <Button disabled={showCorrectAnswer} onClick={() => validateAnswer()} className={classes.nextBtn}>{currentQuestionCount === selectedQuestionList.length ? "Submit" : "Next"} <FaArrowRight /></Button>
                                </div>}
                                {quizType === AppConstants.quizType.text && <div className={classes.buttonSection}>
                                    <Button onClick={() => validateAnswer()} className={classes.nextBtn}>{currentQuestionCount === selectedQuestionList.length ? "Submit" : "Next"} <FaArrowRight /></Button>
                                </div>}
                            </div></div>}
                        {landingPageData && <LandingPageFooter landingPageData={landingPageData} />}
                    </div>
                }
            </div>}
            {pageLoader && <QrenciaLoader loaderText="" />}
        </>
    )
}