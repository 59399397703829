import { NavLink } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { FaChevronDown, FaRegBell } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { HiOutlineBookOpen } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import Auth from "../../utils/Auth";
import { Logout } from "../../common/Logout";
import { AppConstants } from "../../constants/AppConstants";
import "../StudentPageLayout/StudentHeader.css";
import classes from "./TeamHeader.module.css";

export const TeamHeader = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;

    return (
        <>
            <header
                className={`${props.hasShadow
                    ? `${classes.header} ${classes.profilePageHeader}`
                    : classes.header
                    }`}
            >
                <div className={`${props.hasShadow
                    ? `${classes.logoSection}`
                    : classes.logoSection
                    }`}>
                    <NavLink to={AppConstants.forwardSlash}>
                        <img
                            src={qrenciaLogoUrl}
                            className={classes.logo}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>



                {props.page !== "profileUpdate" && (
                    <div className={classes.listSection}>
                        <nav>
                            <ul className={classes.list}>
                                <li>
                                    <NavLink
                                        to={AppConstants.teamMyLearningPath}
                                        className={({ isActive }) =>
                                            isActive ? classes.active : undefined
                                        }> <HiOutlineBookOpen className={classes.linkIcons} /> Past Enrolments
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}
                <div
                    className={
                        props.page && props.page === "profileUpdate"
                            ? `${classes.buttonSection} ${classes.alignRight}`
                            : classes.buttonSection
                    }
                >
                </div>
                {props.page !== "profileUpdate" && <div className={classes.notificationSection}>
                    <FaRegBell className={classes.notificationImage} />
                    <Badge bg="danger" className={classes.notificationBadge}>
                        0
                    </Badge>
                </div>}
                <div className={classes.profileSection}>
                    {Auth.getLoggedInUserDetails()?.profileImagePreSignedUrl != null && (
                        <div>
                            <img
                                src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl}
                                className={classes.profileImage}
                                alt="Profile"
                            />
                        </div>
                    )}
                    {Auth.getLoggedInUserDetails()?.profileImagePreSignedUrl == null && (
                        <div>
                            <div className={classes.userInitials}>
                                {Auth.getLoggedInUserDetails()?.nameInitials?.toUpperCase()}
                            </div>
                        </div>
                    )}
                    <Dropdown>
                        <Dropdown.Toggle className={classes.profilePopoverButton}>
                            <p className={classes.profileName}>
                                {Auth.getLoggedInName().length < 16
                                    ? Auth.getLoggedInName()
                                    : Auth.getLoggedInName().substr(0, 16) + ".."}
                            </p>{" "}
                            <FaChevronDown />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className={classes.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Logout</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        </>
    );
};
