import { useEffect, useState } from 'react'
import { Modal, Row, Col, Card, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { MdOpenInNew } from 'react-icons/md';
import { BiLoaderAlt } from 'react-icons/bi';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import classes from "./LearningActivityDetailsModal.module.css";
import { AppConstants } from '../../constants/AppConstants';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { Tooltip } from 'react-tooltip';
import { ErrorSuccessAlertMessage } from '../errorSuccessMessage/ErrorSuccessAlertMessage';
import Auth from '../../utils/Auth';
import { MathUtil } from '../../utils/MathUtil';

export const LearningActivityDetailsModal = ({ showModal, closeModal, selectedLearningActivity }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [summaryReportSpinner, setSummaryReportSpinner] = useState(AppConstants.falseText);
    const [summaryReportError, setSummaryReportError] = useState(AppConstants.emptyString);

    useEffect(() => {
        return () => {
            setSummaryReportError(AppConstants.emptyString);
            setSummaryReportSpinner(AppConstants.falseText);
        }
    }, []);

    function handleModalClose() {
        setSummaryReportError(AppConstants.emptyString);
        closeModal();
    }


    async function downloadSummaryReport() {
        setSummaryReportSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadResultSummarySheetAPI}${selectedLearningActivity.learningActivityUniqueId}`;
        const name = `${selectedLearningActivity.learningActivityName}${AppConstants.hyphen}${AppConstants.competitionResultSummaryFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined) {
            if (response.ok) {
                setSummaryReportSpinner(AppConstants.falseText);
                setSummaryReportError(AppConstants.emptyString);
            } else {
                setSummaryReportSpinner(AppConstants.falseText);
                setSummaryReportError(ErrorMessages.downloadCompetitionSummaryError);
                let timer = setTimeout(() => {
                    setSummaryReportError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setSummaryReportSpinner(AppConstants.falseText);
            setSummaryReportError(ErrorMessages.downloadCompetitionSummaryError);
            let timer = setTimeout(() => {
                setSummaryReportError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    return (
        <Modal size="xl" show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
            <Modal.Body className={classes.modalBody}>
                <div className={classes.dashboardCards}>
                    <Card className={`${classes.countCards} ${classes.enrolmentCard}`}>
                        <Card.Body>
                            <Card.Title className={classes.cardTitle}>Enrolments</Card.Title>
                            <Card.Text className={`${classes.cardValue} ${classes.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(selectedLearningActivity.totalEnrolments)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${classes.countCards} ${classes.attemptCard}`}>
                        <Card.Body>
                            <Card.Title className={classes.cardTitle}>Participation</Card.Title>
                            { }
                            <Card.Text className={`${classes.cardValue} ${classes.enrolmentCount}`}>{(selectedLearningActivity.isChallengeAdded === AppConstants.falseText || selectedLearningActivity.eventStatus === AppConstants.competitionStatus.upcoming) ? AppConstants.notAvailableText : MathUtil.formatNumberToLocaleString(selectedLearningActivity.totalAttemptInCompetitions)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${classes.countCards} ${classes.submissionCard}`}>
                        <Card.Body>
                            <Card.Title className={classes.cardTitle}>Submissions</Card.Title>
                            <Card.Text className={`${classes.cardValue} ${classes.enrolmentCount}`}>{(selectedLearningActivity.allowSubmissionFlag === AppConstants.nText || selectedLearningActivity.eventStatus === AppConstants.competitionStatus.upcoming) ? AppConstants.notAvailableText : MathUtil.formatNumberToLocaleString(selectedLearningActivity.totalSubmissions)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${classes.countCards} ${classes.absentCard}`}>
                        <Card.Body>
                            <Card.Title className={classes.cardTitle}>Absent</Card.Title>
                            <Card.Text className={`${classes.cardValue} ${classes.enrolmentCount}`}>{selectedLearningActivity.eventStatus !== AppConstants.competitionStatus.past ? AppConstants.notAvailableText : MathUtil.formatNumberToLocaleString(selectedLearningActivity.totalNotAttemtCountInCompetitions)}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <h6 className='mt-5'>Competition Details:</h6>
                <hr />
                <div className={classes.details}>
                    <Row className={classes.expertObrRowLeftBorder}>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Title</p>
                                <p className={classes.detailsText}>{selectedLearningActivity.learningActivityName}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Page Link</p>
                                <a className={classes.detailsLinkLabel} target={AppConstants.openInNewTab} href={`${process.env.REACT_APP_FRONT_END_URL}${selectedLearningActivity.learningActivityLink}`}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Status</p>
                                <p className={classes.detailsText}>{selectedLearningActivity.eventStatus}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Completion Status</p>
                                <p className={classes.detailsText}>{selectedLearningActivity.completionStatus}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Competition Start Time</p>
                                <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedLearningActivity.learningActivityStartDate?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedLearningActivity.learningActivityStartDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedLearningActivity.preferredTimezone})`}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Competition End Time</p>
                                <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedLearningActivity.learningActivityEndDate?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedLearningActivity.learningActivityEndDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedLearningActivity.preferredTimezone})`}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Skill</p>
                                <p className={classes.detailsText}>{selectedLearningActivity.skill}</p>

                            </div>
                        </Col>
                        {Auth.getLoggedInUserDetails().userRole === "Agency" && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Conducted By</p>
                                <p className={classes.detailsText}>{selectedLearningActivity.expertName}</p>
                            </div>
                        </Col>}
                        <div>
                            <span data-tooltip-id="downloadSummaryReport">
                                <Button disabled={selectedLearningActivity.competitionRubricsAdded === AppConstants.falseText || summaryReportSpinner || selectedLearningActivity.completionStatus === "Incomplete"} onClick={downloadSummaryReport} className={classes.selectButton}>Summary Report {summaryReportSpinner && <BiLoaderAlt className='spinner' />}</Button>
                            </span>
                            {selectedLearningActivity.competitionRubricsAdded === AppConstants.falseText && selectedLearningActivity.completionStatus === "Incomplete" && < Tooltip className={classes.tooltipMessage} id="downloadSummaryReport"><span className={classes.tooltipMessage}>Summary Report is not available for this competition as judging parameters are not configured</span></Tooltip>}
                            {selectedLearningActivity.competitionRubricsAdded === AppConstants.trueText && selectedLearningActivity.completionStatus === "Incomplete" && < Tooltip className={classes.tooltipMessage} id="downloadSummaryReport"><span className={classes.tooltipMessage}>Summary will be available once the competition is completed</span></Tooltip>}
                            {selectedLearningActivity.competitionRubricsAdded === AppConstants.falseText && selectedLearningActivity.completionStatus !== "Incomplete" && < Tooltip className={classes.tooltipMessage} id="downloadSummaryReport"><span className={classes.tooltipMessage}>Summary Report is not available for this competition as judging parameters are not configured</span></Tooltip>}

                        </div>
                    </Row>
                    {summaryReportError && <ErrorSuccessAlertMessage message={summaryReportError} varient={AppConstants.alertVarient[1]} />}
                </div>
            </Modal.Body>
            <Modal.Footer className={classes.modalFooter}>
                <Button className={classes.modalCloseButton} onClick={handleModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}