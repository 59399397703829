import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiLoaderAlt, BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { TbShare3 } from "react-icons/tb";
import { AppConstants } from "../../../constants/AppConstants";
import classes from '../viewCourseCards/ViewCourseCards.module.css';
import 'moment-timezone';
import '../CourseCards.css';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import Moment from "react-moment";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { CgPin } from "react-icons/cg";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { MathUtil } from "../../../utils/MathUtil";

export const ViewWorkshopCards = (props) => {
    const [workshopCards, setWorkshopCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [cardDisplayedCount, setCardDisplayedCount] = useState(0);
    const [editIndex, setEditIndex] = useState(AppConstants.nullText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [participantsImageList, setParticipantImageList] = useState([]);
    const learningActivitiesCardsCount = AppConstants.cardCounts;

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.workshopDefaultThumbnailImage}`;

    useEffect(() => {
        const populateParticipantImageList = () => {
            const imageArray = props.workshopData.map(workshop =>
                workshop.participantDetails?.filter(participant => participant.userImage)
                    .map(participant => participant.userImage)
            );
            setParticipantImageList(imageArray);
        }
        populateParticipantImageList();
    }, [props.workshopData]);

    useEffect(() => {
        setWorkshopCards(props.workshopData);
    }, [props.workshopData]);

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.workshopSlug}`);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const navigateToGeolocation = (mode, location) => {
        if (mode?.toUpperCase() === "OFFLINE") {
            window.open(location, AppConstants.openInNewTab);
        }
    }

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + cardDisplayedCount);
    }, [workshopCards]);

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {workshopCards !== undefined && workshopCards.slice(0, cardCount).map((workshop, index) => (
                    <Col key={workshop.uniqueId} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${workshop.skillCategorySlug}${AppConstants.forwardSlash}${workshop.skillSlug}${AppConstants.forwardSlash}${workshop.workshopSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={workshop.thumbnailImageAbsolutePath ? workshop.thumbnailImageAbsolutePath : defaultWorkshopThumbnail} />
                            <div className={classes.uniqueIdText}>{workshop.workshopId}</div>
                            <TbShare3 className={classes.shareIcon} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(workshop) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div data-tooltip-id={`${workshop.uniqueId}-offline`} onClick={(e) => { stopPropagation(e); navigateToGeolocation(workshop.workshopModeOfDelivery.toUpperCase(), workshop.geoLocation) }} className={`cardSubTitle workshopDiffColor`}>{workshop.workshopModeOfDelivery.toUpperCase()}</div>
                                    <div className="cardsCostInfoText"><BiWallet /> {PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: AppConstants.zeroIndex })}</div>
                                </div>
                                {workshop.workshopModeOfDelivery.toUpperCase() === "OFFLINE" && <Tooltip className="tooltipMessage" id={`${workshop.uniqueId}-offline`}><span className="tooltipMessage">{workshop.address}</span></Tooltip>}
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${workshop.uniqueId}-title`}>{workshop.workshopTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${workshop.uniqueId}-title`}><span>{workshop.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainer}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshop.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="buttonSection">
                                    <div>
                                        <span onClick={stopPropagation} data-tooltip-id={`pastWorkshopEditInfo-${workshop.uniqueId}`}><Button type="button" disabled={props.workshopStatus === AppConstants.workshopStatus.past} onClick={(e) => { stopPropagation(e); props.onClickEdit(workshop) }} className="cardButton me-2">Edit</Button></span>
                                        <Button onClick={(e) => { stopPropagation(e); props.onClickView(workshop) }} type="button" className="cardButton">View</Button>
                                    </div>
                                    {props.workshopStatus === AppConstants.workshopStatus.past && < Tooltip className="courseEditTooltip" id={`pastWorkshopEditInfo-${workshop.uniqueId}`}><span>{ErrorMessages.cannotEditPastWorkshopInfo}</span></Tooltip>}
                                </div>
                                <div className="cardUserProfileImageSection">
                                    <Tooltip className="tooltipMessage" id={workshop.uniqueId}><span className="tooltipMessage">{workshop.address}</span></Tooltip>
                                </div>
                                <div className="cardUserProfileImageSectionExpert mt-1">
                                    <div className="cardUserProfileImageContainerExpert">
                                        {(workshop && workshop.participantDetails !== null && workshop?.participantDetails?.length !== 0 && participantsImageList?.length !== 0 && participantsImageList[index]?.length !== 0) && <div className="cardProfileImageSection">
                                            {workshop && workshop.participantDetails !== null
                                                && participantsImageList[index]?.slice(0, AppConstants.studentProfileImageLimit).map((imgageSrc, i) => (
                                                    <img src={imgageSrc} key={workshopCards[index]?.participantDetails?.userQrenciaId} className="cardProfileImage" alt="student profile" />
                                                ))}
                                        </div>}
                                        <div className="cardEnrolledCountSection"><div className="cardText">{MathUtil.formatNumberToLocaleString(workshop?.participantDetails?.length || 0)} Enrolled</div></div>
                                    </div>
                                    <div onClick={stopPropagation}>
                                        {(workshop.workshopModeOfDelivery.toUpperCase() === AppConstants.physicalText || workshop.workshopModeOfDelivery.toUpperCase() === AppConstants.offlineText) && <span className="onlineIcon" data-tooltip-place="top" data-tooltip-id={workshop.uniqueId}><a href={workshop.geoLocation} target={AppConstants.openInNewTab} className="cardLink"><CgPin className="geoLocationIcon" /></a></span>}
                                    </div>
                                    <Tooltip className="tooltipMessage" id={workshop.uniqueId}><span className="tooltipMessage">{workshop.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {loading && cardCount < props.workshopData?.length && (
                <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                </Row>
            )}
        </>
    );
}