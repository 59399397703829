export const DataService = {

  //Method to get data using GET method
  async get(uri, invalidResponse, noResponse, requestHeaders) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    try {
      const header = { ...requestHeaders }
      const response = await fetch(url, { headers: header });
      if (!response.ok) {
        throw new Error(invalidResponse);
      }
      return await response.json();
    } catch (error) {
      console.error(noResponse, error);
    }
  },
  async getWithResponseCode(uri, invalidResponse, noResponse, requestHeaders) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const header = { ...requestHeaders }
    const response = await fetch(url, { headers: header });

    return response;
  },
  //Method for Post Request
  async post(uri, data, invalidResponse, noResponse) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response;
  },
  //Method to send put request
  async put(uri, data, invalidResponse, noResponse) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(invalidResponse + `${response.status}`);
    }
    return response.json();
  },
  //Method for Post Request
  async postWithReturnValue(uri, data, invalidResponse, noResponse) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  },
  async postWithResponseCode(uri, data, invalidResponse, noResponse) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response;
  },
  //Method to send put request with form data
  async putFileOnPreSigned(uri, FormData, config, invalidResponse, noResponse) {
    // const apiURL = process.env.REACT_APP_API_URL;
    const url = uri;

    const response = await fetch(url, {
      method: 'PUT',
      headers: config,
      body: FormData
    });
    return response;

  },

  //Method to Delete

  async delete(uri, invalidResponse, noResponse) {
    const apiURL = process.env.REACT_APP_API_URL;
    const url = `${apiURL}` + uri;
    const response = await fetch(url, {
      method: 'DELETE'
    });
    if (!response.ok) {
      throw new Error(invalidResponse + `${response.status}`);
    }
    return response.json();
  }
};



