import React from "react";
import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation.js";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { ApproveCompetitionLayout } from "../../component/approveFeeWaivedCompetitions/ApproveCompetitionLayout";

export const ApproveFeeWaivedCompetition = () => {
    const [landingPageData] = useLoaderData();

    return (
        <>
            <LandingPageTopNavigation />
            <ApproveCompetitionLayout />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}

