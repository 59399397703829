import React, { useState, useEffect } from "react";
import Moment from 'moment';
import { DateTimeUtil } from "../utils/DateTimeUtil";
import { AppConstants } from "../constants/AppConstants";
import { DateTime } from "luxon";
export const CountdownTimer = (props) => {
  const date = Moment(props.utcToLocalDateTime).format('MMM DD,YYYY');
  const [days, setDays] = useState(AppConstants.timeZero);
  const [hours, setHours] = useState(AppConstants.timeZero);
  const [minutes, setMinutes] = useState(AppConstants.timeZero);



  useEffect(() => {
    const countDownDate = props.utcToLocalDateTime.toMillis();
    const interval = setInterval(() => {
      const now = DateTime.now().setZone(DateTimeUtil.getPreferredTimeZoneForUser()).toMillis();
      const distance = countDownDate - now;

      if (distance <= 0) {
        clearInterval(interval);
        setDays(AppConstants.timeZero);
        setHours(AppConstants.timeZero);
        setMinutes(AppConstants.timeZero);
        props.onTimeOver();
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, "0");
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0");
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0");
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
      }
    }, 1000); // Update every minute (60,000 milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (

    <div className={props.countDownTimerDateBoxContainer}>
      <p className={props.countDownTimerDateBoxClass}>{days.slice(0, 1)}</p>
      <p className={props.countDownTimerDateBoxClass}>{days.slice(1, 2)}</p>
      <p className={props.countDownTimerDateSeperatorClass}>{AppConstants.colon}</p>
      <p className={props.countDownTimerDateBoxClass}>{hours.slice(0, 1)}</p>
      <p className={props.countDownTimerDateBoxClass}>{hours.slice(1, 2)}</p>
      <p className={props.countDownTimerDateSeperatorClass}>{AppConstants.colon}</p>
      <p className={props.countDownTimerDateBoxClass}>{minutes.slice(0, 1)}</p>
      <p className={props.countDownTimerDateBoxClass}>{minutes.slice(1, 2)}</p>

    </div>

  );
};