import React from 'react';
import './signup.css'
import { SignupPageCarousel } from '../component/signupPageComponents/SignupPageCarousel';
import { SignupPageForm } from '../component/formControls/SignupPageForm';
import { AppConstants } from '../constants/AppConstants';

export const Signup = (props) => {
    return (
        <div className='row main-container-signup'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 carousel-container'>
                <SignupPageCarousel {...props} page={AppConstants.signupPageText} />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 signup-form-container'>
                <SignupPageForm />
            </div>
        </div>
    )
}

