import { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { BiLoaderAlt } from "react-icons/bi";
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { FormValidationUtils } from "../../../utils/FormValidationUtils";
import { DataService } from "../../../services/DataService";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { EnrolInCompetition } from "../../studentPageComponents/EnrolInCompetition";
import modalClass from "../../cards/CompetitionsCards.module.css";
import formClass from "../../../common/FormStyling.module.css";
import classes from "./TeamEnrolmentForm.module.css";
import { DateTime } from "luxon";
import { StringUtils } from "../../../utils/StringUtils";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { TeamEnrolmentDetailsModal } from "../../modal/teamEnrolmentDetailsModal/TeamEnrolmentDetailsModal";
import { CiViewList } from "react-icons/ci";
import { Tooltip } from "react-tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import Auth from "../../../utils/Auth";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { MathUtil } from "../../../utils/MathUtil";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage"

export const TeamEnrolmentForm = (props) => {
    const { hideTeamEnrolmentForm, enrolmentDetails } = props;
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(false);
    const { enrolInTeamCompetitions } = useEnrolment();
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showEnrolmentModal, setShowEnrolmentModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showTeamDetailsForm, setShowTeamDetailsForm] = useState(AppConstants.trueText);
    const [offlinePaymentRequestGranted, setOfflinePaymentRequestGranted] = useState(AppConstants.falseText);
    const [offlinePaymentRequestError, setOfflinePaymentRequestError] = useState(AppConstants.emptyString);
    const [offlinePaymentRequestedFlag, setOfflinePaymentRequestedFlag] = useState(AppConstants.falseText);
    const [enrolSpinner, setEnrolSpinner] = useState(AppConstants.emptyString);
    const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(AppConstants.falseText);
    const [minimumAgeLimit, setMinimumAgeLimit] = useState(AppConstants.emptyString);
    const [existingTeams, setExistingTeams] = useState([]);
    const [activeTeamIndex, setActiveTeamIndex] = useState(0);
    const [activeParticipantIndex, setActiveParticipantIndex] = useState(0);
    const allowedTeamParticipants = props.enrolmentDetails?.teamParticipantsLimit;
    const [unsavedTeamError, setUnsavedTeamError] = useState(AppConstants.emptyString);

    useWarnOnLeave(shouldWarn);

    useEffect(() => {
        const fetchTeamDetails = async () => {
            const url = `${AppConstants.getTeamCompetitionParticipantsAPI}${enrolmentDetails.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyEditorString);

            if (response) {
                const groupedTeams = response.reduce((acc, participant) => {
                    const { teamName } = participant;
                    if (!acc.includes(teamName)) {
                        acc.push(teamName);
                    }
                    return acc;
                }, []);

                setExistingTeams(groupedTeams);
            }
        }

        if (enrolmentDetails.uniqueId) {
            fetchTeamDetails();
        }
    }, [enrolmentDetails]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setUnsavedTeamError(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [unsavedTeamError]);

    const teamDetailsObj = {
        participantTeamName: AppConstants.emptyString,
        learnersForTeamEnrolments: [],
        teamSavedFlag: AppConstants.falseText
    };

    const participantDetailsObj = {
        participantFirstName: AppConstants.emptyString,
        participantLastName: AppConstants.emptyString,
        participantGender: AppConstants.emptyString,
        participantDob: AppConstants.emptyString,
        participantTeamName: AppConstants.emptyString,
        isPartOfQrencia: AppConstants.falseText,
        participantQrenciaId: AppConstants.emptyString,
        isQrenciaIdvalid: AppConstants.falseText
    };
    const [teamDetailsForm, setTeamDetailsForm] = useState([{ ...teamDetailsObj, learnersForTeamEnrolments: new Array(allowedTeamParticipants).fill({ ...participantDetailsObj }) }]);
    const [teamErrors, setTeamErrors] = useState([{ ...teamDetailsObj, learnersForTeamEnrolments: new Array(allowedTeamParticipants).fill({ ...participantDetailsObj }) }]);

    useEffect(() => {

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumAgeLimit(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };
        fetchConfigurableKeys();
    }, []);

    const updateTeamErrors = (teamIdx, participantIdx, name, value) => {
        setTeamErrors(prevErrors => {
            return prevErrors.map((teamErr, idx) =>
                idx === teamIdx ? {
                    ...teamErr,
                    learnersForTeamEnrolments: teamErr.learnersForTeamEnrolments.map((learner, learnerIndex) => learnerIndex === participantIdx ? { ...learner, [name]: value } : learner)
                } : teamErr
            )
        });
    }

    const updateValuesForTeamForm = (teamIdx, participantIdx, name, value) => {
        setTeamDetailsForm(prevTeams =>
            prevTeams.map((team, idx) =>
                idx === teamIdx ? {
                    ...team,
                    learnersForTeamEnrolments: team.learnersForTeamEnrolments.map(
                        (participant, pIdx) =>
                            pIdx === participantIdx
                                ? { ...participant, [name]: value }
                                : participant
                    )
                } : team
            ));
    };

    const handleParticipantDetailsChange = (teamIdx, participantIdx, event) => {
        const { name, value } = event.target;
        updateValuesForTeamForm(teamIdx, participantIdx, name, value);
        if (value && teamErrors[teamIdx].learnersForTeamEnrolments[participantIdx][name] !== AppConstants.emptyString) {
            updateTeamErrors(teamIdx, participantIdx, name, AppConstants.emptyString);
        }
    };

    const handleTeamnameChange = (teamIdx, event) => {
        let teamValues = teamDetailsForm;
        teamValues[teamIdx].participantTeamName = event.target.value;

        setTeamDetailsForm([...teamValues]);

        if (event.target.value && teamErrors[teamIdx].participantTeamName !== AppConstants.emptyString) {
            setTeamErrors(prevErrors => {
                return prevErrors.map((teamErr, idx) =>
                    idx === teamIdx ? {
                        ...teamErr,
                        participantTeamName: AppConstants.emptyString
                    } : teamErr
                )
            });
        }
    };

    const showRequiredAsterisk = () => {
        if (!teamDetailsForm[activeTeamIndex]?.learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia) {
            return <span className="redAstrisk">{AppConstants.asterisk}</span>
        }
        return null;
    };

    const isAddTeamButtonDisabled = () => {
        return teamDetailsForm.filter(team => team.teamSavedFlag === AppConstants.falseText).length > 0 ? AppConstants.trueText : AppConstants.falseText;
    }

    const addTeams = () => {
        if (isAddTeamButtonDisabled()) {
            return;
        }
        let teams = JSON.parse(JSON.stringify(teamDetailsForm));
        let errors = JSON.parse(JSON.stringify(teamErrors));
        let newObj = { ...teamDetailsObj, learnersForTeamEnrolments: new Array(allowedTeamParticipants).fill({ ...participantDetailsObj }) }
        teams.push({ ...newObj });
        errors.push({ ...newObj });
        setTeamDetailsForm([...teams]);
        setTeamErrors([...errors]);
        setActiveTeamIndex(teams.length - 1);
        setActiveParticipantIndex(0);
    };

    const removeTeams = (teamIdx) => {
        if (teamIdx === 0) {
            setActiveTeamIndex(0);
        } else {
            setActiveTeamIndex(teamIdx - 1);
        }
        setActiveParticipantIndex(0);
        const updatedTeams = [...teamDetailsForm];
        const updatedErrors = [...teamErrors];
        updatedTeams.splice(teamIdx, 1);
        updatedErrors.splice(teamIdx, 1);
        setTeamDetailsForm(updatedTeams);
        setTeamErrors(updatedErrors);
    };

    const updateLearnerQrenciaId = async (participantIdx, teamIdx) => {
        const allParticipantQrenciaIds = teamDetailsForm.flatMap(team =>
            team.learnersForTeamEnrolments.filter(participant => participant.isQrenciaIdvalid === AppConstants.trueText).map(participant => participant.participantQrenciaId)
        );

        const qrenciaId = teamDetailsForm[teamIdx].learnersForTeamEnrolments[participantIdx].participantQrenciaId;
        if (!allParticipantQrenciaIds.includes(qrenciaId)) {
            const params = {
                userQrenciaId: qrenciaId
            }
            const url = StringUtils.createURLWithParams(AppConstants.getLearnerDetailForQrenciaIdAPI, params);
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response) {
                updateTeamErrors(teamIdx, participantIdx, "participantQrenciaId", AppConstants.emptyString);
                setTeamDetailsForm(prevTeams => {
                    return prevTeams.map((team, idx) => {
                        if (idx === teamIdx) {
                            return {
                                ...team,
                                learnersForTeamEnrolments: team.learnersForTeamEnrolments.map((learner, learnerIndex) => learnerIndex === participantIdx ? {
                                    ...learner,
                                    participantFirstName: response.firstName,
                                    participantLastName: response.lastName,
                                    participantGender: response.gender,
                                    participantDob: response.dob,
                                    participantTeamName: AppConstants.emptyString,
                                    isPartOfQrencia: AppConstants.trueText,
                                    participantQrenciaId: qrenciaId,
                                    isQrenciaIdvalid: AppConstants.trueText
                                } : learner)
                            };
                        } else {
                            return team;
                        }
                    });
                });
            } else {
                updateTeamErrors(teamIdx, participantIdx, "participantQrenciaId", StringUtils.getParsedMessage(ErrorMessages.tamEnrolmentLearnerQrenciaIdError, { qrenciaId }))
            }
        } else {
            updateTeamErrors(teamIdx, participantIdx, "participantQrenciaId", ErrorMessages.participantQrenciaIdAddedInCompetitionError)
        }
    };

    const handleParticipantQrenciaIdChange = (checkedParameter, teamIdx, participantIdx) => {
        const updatedparticipant = { ...participantDetailsObj };
        const updatedparticipantErrors = { ...participantDetailsObj };
        updatedparticipant.isPartOfQrencia = checkedParameter;

        setTeamDetailsForm(prevTeams => {
            return prevTeams.map((team, idx) => {
                if (idx === teamIdx) {
                    return {
                        ...team,
                        learnersForTeamEnrolments: team.learnersForTeamEnrolments.map((learner, learnerIndex) => learnerIndex === participantIdx ? updatedparticipant : learner)
                    }
                } else {
                    return team;
                }
            });
        });
        setTeamErrors(prevErrors => {
            return prevErrors.map((errors, idx) => {
                if (idx === teamIdx) {
                    return {
                        ...errors,
                        learnersForTeamEnrolments: errors.learnersForTeamEnrolments.map((learner, learnerIndex) => learnerIndex === participantIdx ? updatedparticipantErrors : learner)
                    }
                } else {
                    return errors;
                }
            });
        });
    };

    const saveTeamDetails = (teamIdx) => {
        const isValid = validateForm(teamIdx);
        if (isValid) {
            setTeamDetailsForm(prevTeams => prevTeams.map((team, i) => (
                i === teamIdx ? { ...team, teamSavedFlag: AppConstants.trueText } : team
            )));
        }
    }

    const updateFirstErrorIndex = (value, errorIndex) => {
        return value === -1 ? errorIndex : value;
    };

    const validateForm = (specificTeamIndex = -1) => {
        const nameRegex = /^[a-zA-Z ]*$/;
        const teamNameRegex = /^[a-zA-Z0-9 .,'()&-]*$/;
        const currentDate = new Date();
        let firstTeamErrorIndex = -1;
        let firstParticipantErrorIndex = -1;
        currentDate.setHours(0, 0, 0, 0);


        let isFormValid = AppConstants.trueText;
        let fromErrors = [];
        let teamNamesSet = new Set();
        existingTeams.forEach(team => teamNamesSet.add(team));
        let unsavedTeams = teamDetailsForm.filter(team => !team.teamSavedFlag);


        for (let i = 0; i < teamDetailsForm.length; i++) {
            let errors = { ...teamDetailsObj, learnersForTeamEnrolments: [] };
            let teamName = teamDetailsForm[i]?.participantTeamName.trim();

            if (specificTeamIndex > -1) {
                let participantErrors = [...teamErrors[i].learnersForTeamEnrolments];
                if (i !== specificTeamIndex) {
                    errors.learnersForTeamEnrolments = participantErrors;
                    fromErrors.push(errors);
                    continue;
                }
            }

            if (!teamName.trim() || !teamNameRegex.test(teamName)) {
                errors.participantTeamName = ErrorMessages.pleaseEnterTeamNameError;
                isFormValid = AppConstants.falseText;
                firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, 0);
            } else if (teamNamesSet.has(teamName)) {
                errors.participantTeamName = ErrorMessages.teamNameTakenError;
                isFormValid = AppConstants.falseText;
                firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, 0);
            } else {
                teamNamesSet.add(teamName);
            }

            for (let j = 0; j < teamDetailsForm[i]?.learnersForTeamEnrolments?.length; j++) {
                let participantDetails = teamDetailsForm[i].learnersForTeamEnrolments[j];
                let participantErrors = { ...participantDetailsObj };

                if (participantDetails.isPartOfQrencia && !participantDetails.isQrenciaIdvalid) {
                    participantErrors.participantQrenciaId = "Please add a valid Qrencia Id";
                    isFormValid = AppConstants.falseText;
                    firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                    firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                } else {
                    if (!participantDetails.participantFirstName.trim()) {
                        participantErrors.participantFirstName = ErrorMessages.invalidUserFirstName;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }

                    if (!nameRegex.test(participantDetails.participantFirstName)) {
                        participantErrors.participantFirstName = ErrorMessages.invalidUserFirstNameWithRegex;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }

                    if (!participantDetails.participantLastName.trim()) {
                        participantErrors.participantLastName = ErrorMessages.invalidUserLastName;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }
                    if (!nameRegex.test(participantDetails.participantLastName)) {
                        participantErrors.participantLastName = ErrorMessages.invalidUserLastNameWithRegex;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }
                    if (!participantDetails.participantDob) {
                        participantErrors.participantDob = ErrorMessages.enterDateOfBirthError;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }

                    if (participantDetails.participantDob) {
                        const dob = new Date(participantDetails.participantDob);
                        dob.setHours(0, 0, 0, 0);

                        if (new Date(participantDetails.participantDob) >= currentDate) {
                            participantErrors.participantDob = ErrorMessages.invalidParticipantDobError;
                            isFormValid = AppConstants.falseText;
                            firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                            firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                        } else if (DateTime.fromJSDate(new Date(currentDate)).diff(DateTime.fromJSDate(new Date(dob)), 'years').years < minimumAgeLimit) {
                            participantErrors.participantDob = `Age should be atleast ${minimumAgeLimit} ${minimumAgeLimit > 1 ? "years" : "year"} to participate in the competition`
                            isFormValid = AppConstants.falseText;
                            firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                            firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                        }
                    }

                    if (!participantDetails.participantGender) {
                        participantErrors.participantGender = ErrorMessages.pleaseSelectGenderError;
                        isFormValid = AppConstants.falseText;
                        firstTeamErrorIndex = updateFirstErrorIndex(firstTeamErrorIndex, i);
                        firstParticipantErrorIndex = updateFirstErrorIndex(firstParticipantErrorIndex, j);
                    }
                }
                errors.learnersForTeamEnrolments?.push(participantErrors);
            }
            fromErrors.push(errors);
        }

        if (!isFormValid && firstTeamErrorIndex !== -1) {
            setActiveTeamIndex(firstTeamErrorIndex);
        }

        if (!isFormValid && firstParticipantErrorIndex !== -1) {
            setActiveParticipantIndex(firstParticipantErrorIndex);
        }

        if (unsavedTeams.length > 0 && specificTeamIndex === -1) {
            isFormValid = AppConstants.falseText;
            setUnsavedTeamError(ErrorMessages.saveTeamDetailsError);
        }
        setTeamErrors(fromErrors);
        return isFormValid;
    }

    const buildTeamLearnerPayload = () => {
        const result = teamDetailsForm.filter(team => team.teamSavedFlag === AppConstants.trueText).flatMap(team => {
            return team.learnersForTeamEnrolments.map(participant => {
                const { participantFirstName, participantLastName, participantDob, participantGender, participantQrenciaId, isQrenciaIdvalid } = participant;

                return {
                    participantFirstName: isQrenciaIdvalid ? AppConstants.nullText : participantFirstName.trim(),
                    participantLastName: isQrenciaIdvalid ? AppConstants.nullText : participantLastName.trim(),
                    participantDob: isQrenciaIdvalid ? AppConstants.nullText : participantDob,
                    participantGender: isQrenciaIdvalid ? AppConstants.nullText : participantGender,
                    participantTeamName: team.participantTeamName,
                    participantQrenciaId: isQrenciaIdvalid ? participantQrenciaId : AppConstants.nullText
                };
            });
        });

        return result;
    };

    const setResponses = (response) => {
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowEnrolmentModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        setShowTeamDetailsForm(AppConstants.falseText);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const enrolInCompetition = async (status) => {
        const payload = buildTeamLearnerPayload();
        const cost = PaymentsUtil.calculateCost({ cost: enrolmentDetails.cost, discount: 0 });
        let response = await enrolInTeamCompetitions({ ...enrolmentDetails, cost }, payload, status);
        setResponses(response);
        setEnrolSpinner(AppConstants.falseText);
        return;
    };

    const onClickRetry = async () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        const payload = buildTeamLearnerPayload();
        let response = await PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails, payload);
        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
            enrolInCompetition(AppConstants.paymentStatus.verified);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowEnrolmentModal(AppConstants.falseText);
        const payload = buildTeamLearnerPayload();
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShouldWarn(AppConstants.trueText);
                setShowEnrolmentModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    let verifyResponse = await PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails, payload);
                    if (verifyResponse.verificationStatus === AppConstants.paymentStatus.verified) {
                        enrolInCompetition(AppConstants.paymentStatus.verified);
                    } else {
                        setResponses({
                            modalTypeError: AppConstants.trueText,
                            message: ErrorMessages.paymentDeductedEnrolmentError,
                            paymentStatus: AppConstants.emptyString,
                            showModal: AppConstants.trueText,
                            showRazorpayPopup: AppConstants.falseText,
                            shouldWarn: AppConstants.falseText,
                            networkError: AppConstants.falseText
                        });
                    }
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            const payload = buildTeamLearnerPayload();
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails, payload);
        });

        rzp1.open();
    };

    const handleModalShow = () => {
        setShowEnrolmentModal(AppConstants.trueText);
    };

    const handleModalClose = () => {
        setShowEnrolmentModal(AppConstants.falseText);
        setTransactionId(AppConstants.emptyString);
        setOfflinePaymentRequestedFlag(AppConstants.falseText);
        setOfflinePaymentRequestError(AppConstants.falseText);

        if (transactionId) {
            window.location.reload();
        } else if (message === SuccessMessage.studentEnrollmentSuccess || message === SuccessMessage.teamEnrollmentSuccess || offlinePaymentRequestGranted) {
            window.location.reload();
        } else {
            setShowTeamDetailsForm(AppConstants.trueText);
        }
    };


    const initiateOfflinePayment = async () => {
        try {
            const data = {
                uniqueId: enrolmentDetails.uniqueId,
                learningActivityType: AppConstants.learningActivitiesTypes.competition,
                learnersForTeamEnrolments: buildTeamLearnerPayload(),
                locale: DateTimeUtil.getPreferredTimeZoneForUser(),
                offlineEnrolmentRequestFlag: AppConstants.yText,
                teamLimits: enrolmentDetails.teamsLimit
            }

            const url = AppConstants.raiseOfflineEnrolmentRequestForTeamAPI;
            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (!response) throw new Error(ErrorMessages.codRequestSendError);

            if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                setOfflinePaymentRequestGranted(AppConstants.falseText);
                setOfflinePaymentRequestError(ErrorMessages.sessionExpiredEnrolmentError);
                setShowTeamDetailsForm(AppConstants.falseText);
                return;
            }

            const body = await response.json();

            if (body?.statusCode === 1) {
                setOfflinePaymentRequestGranted(AppConstants.trueText);
                setShowTeamDetailsForm(AppConstants.falseText);
            } else {
                setOfflinePaymentRequestGranted(AppConstants.falseText);
                setOfflinePaymentRequestError(EnrolInCompetition.getEnrolmentStatusForTeamCompetitions(body));
                setShowTeamDetailsForm(AppConstants.falseText);
            }
        } catch {
            setOfflinePaymentRequestGranted(AppConstants.falseText);
            setOfflinePaymentRequestError(ErrorMessages.codRequestSendError);
            setShowTeamDetailsForm(AppConstants.falseText);
        }
    }

    const submitTeamDetailsForm = (action) => {
        const isFormValid = validateForm();
        if (isFormValid) {
            if (action === AppConstants.teamEnrolmentFormButtons.enrol) {
                setEnrolSpinner(AppConstants.trueText);
                enrolInCompetition();
            } else if (action === AppConstants.teamEnrolmentFormButtons.payOffline) {
                setShowTeamDetailsForm(AppConstants.falseText);
                setOfflinePaymentRequestedFlag(AppConstants.trueText);
                initiateOfflinePayment();
            }
        }
    };

    // const viewTeamDetails = () => {
    //     setShowTeamDetailsForm(AppConstants.falseText);
    //     setShowTeamDetailsModal(AppConstants.trueText)
    // }

    // const closeTeamDetailsModal = () => {
    //     setShowTeamDetailsForm(AppConstants.trueText);
    //     setShowTeamDetailsModal(AppConstants.falseText);
    // };

    const getTotalAmount = () => {
        let teamLength = teamDetailsForm.filter(team => team.teamSavedFlag).length;
        return `INR ${MathUtil.formatNumberToLocaleString(teamLength * allowedTeamParticipants * enrolmentDetails.cost)}`
    }

    const getTeamParticipantCount = (teamIdx) => {
        let count = 0;
        teamDetailsForm[teamIdx]?.learnersForTeamEnrolments.forEach(participant => {
            if (participant.participantFirstName?.trim() && participant.participantLastName?.trim() && participant.participantDob && participant.participantGender) {
                count++;
            }
        })

        if (allowedTeamParticipants) {
            return `${count} out of ${allowedTeamParticipants}`
        } else {
            return `${count}`
        }
    };

    const changeActiveTeam = (i) => {
        setActiveTeamIndex(i);
        setActiveParticipantIndex(0);
    };

    return (
        <>
            {showTeamDetailsForm && <Modal className={classes.teamEnrolmentModal} show={true} size="xl" backdrop="static" onHide={hideTeamEnrolmentForm} keyboard={false} centered>
                <Modal.Header closeButton>
                    <div>
                        <Modal.Title className={classes.modalHeading}>{props.userSignupDone ? `Hi ${Auth.getLoggedInUserDetails().firstName}, you have successfully signed up as a team!` : `Hi ${Auth.getLoggedInUserDetails().firstName}, welcome to the competition!`}</Modal.Title>
                        <Modal.Title className={classes.modalSubtitle}>Please provide your team details to complete the enrolment process</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body className={`${modalClass.modalBody} ${classes.modalBody}`}>
                    <div className={classes.container}>
                        <div className={classes.teamDetailsForm}>
                            <div className="row">
                                <Row className={classes.teamNameInputBox}>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Form.Group>
                                            <Form.Label className={formClass.formLabel}>Team Name<span className="redAstrisk">{AppConstants.asterisk}</span></Form.Label>
                                            <Form.Control className={formClass.formInput}
                                                type="text"
                                                placeholder="Enter your team name"
                                                name="participantTeamName"
                                                value={teamDetailsForm[activeTeamIndex]?.participantTeamName}
                                                onChange={event => handleTeamnameChange(activeTeamIndex, event)}
                                                required
                                                min={1} max={60}
                                                minLength={1} maxLength={60}
                                                isInvalid={teamErrors[activeTeamIndex]?.participantTeamName}
                                            />
                                            <Form.Control.Feedback className="errorText" type="invalid">{teamErrors[activeTeamIndex]?.participantTeamName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div className={classes.participantCount}>
                                            <div className={classes.participantCountHeading}><MdOutlinePeopleAlt /> Teammates<span className={classes.participantCountMoblie}>{`: ${getTeamParticipantCount(activeTeamIndex)}`}</span>   </div>
                                            <span className={classes.participantCountDesktop}>{getTeamParticipantCount(activeTeamIndex)}</span>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <div className={classes.participantNavTabs}>
                                        {teamDetailsForm[activeTeamIndex]?.learnersForTeamEnrolments.map((participant, participantIdx) => (
                                            <>
                                                <h6 onClick={() => setActiveParticipantIndex(participantIdx)} className={`${classes.participantNavTabText} ${participantIdx === activeParticipantIndex && classes.activeParticipantNavTabText}`}>{participant.participantFirstName || `Participant ${participantIdx + 1}`}</h6>
                                            </>
                                        ))}
                                    </div>
                                </Row>
                                <Row>
                                    <div className={classes.participantDetailsSection}>
                                        <Row className={`mb-2`}>
                                            <div className={classes.qrenciaIdFormInputContainer}>
                                                <div className={classes.qrenciaIdFormTextSection}>
                                                    <div className={classes.switchText}><input type="checkbox" checked={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia} onChange={(e) => handleParticipantQrenciaIdChange(e.target.checked, activeTeamIndex, activeParticipantIndex)} />Does the participant have a Qrencia Id?</div>
                                                </div>
                                                <div className={classes.qrenciaIdFormInputSection}>
                                                    <Form.Group>
                                                        <Form.Control className={classes.qrenciaIdFormInput}
                                                            type={AppConstants.inputFieldTypes.number}
                                                            onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                                            value={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantQrenciaId}
                                                            id={`${activeTeamIndex}-${activeParticipantIndex}-qrenciaId`}
                                                            name="participantQrenciaId"
                                                            placeholder="Enter Qrencia Id"
                                                            readOnly={!teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia || teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isQrenciaIdvalid}
                                                            disabled={!teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia || teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isQrenciaIdvalid}
                                                            onChange={event => handleParticipantDetailsChange(activeTeamIndex, activeParticipantIndex, event)}
                                                            isInvalid={teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantQrenciaId}
                                                        />
                                                    </Form.Group>
                                                    {!teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isQrenciaIdvalid && <Button disabled={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex].participantQrenciaId?.trim()?.length === 0} onClick={() => updateLearnerQrenciaId(activeParticipantIndex, activeTeamIndex)} className={classes.addQrenciaIdButton}>Add</Button>}
                                                    {teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isQrenciaIdvalid && <Button onClick={(e) => handleParticipantQrenciaIdChange(AppConstants.trueText, activeTeamIndex, activeParticipantIndex)} className={classes.addQrenciaIdButton}>Edit</Button>}
                                                </div>
                                            </div>
                                            <div>{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantQrenciaId && <p className={`errorText ${classes.qrenciaIdErrorText}`}>{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantQrenciaId}</p>}</div>
                                        </Row>
                                        <div className={classes.orTextSection}>OR</div>
                                        <Row>
                                            <div className={`${classes.switchText} ${classes.formHeading}`}>Enter details of the participant manually</div>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Form.Group>
                                                    <Form.Label className={formClass.formLabel}>First Name {showRequiredAsterisk(activeParticipantIndex)}</Form.Label>
                                                    <Form.Control className={formClass.formInput}
                                                        id={`${activeTeamIndex}-${activeParticipantIndex}-firstName`}
                                                        type="text"
                                                        placeholder="First name"
                                                        name="participantFirstName"
                                                        maxLength={40}
                                                        value={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantFirstName}
                                                        disabled={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        readOnly={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        onChange={event => handleParticipantDetailsChange(activeTeamIndex, activeParticipantIndex, event)}
                                                        isInvalid={teamErrors[activeTeamIndex]?.learnersForTeamEnrolments[activeParticipantIndex]?.participantFirstName}
                                                    />
                                                    <Form.Control.Feedback className="errorText" type="invalid">{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantFirstName}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Form.Group>
                                                    <Form.Label className={formClass.formLabel}>Last Name {showRequiredAsterisk(activeTeamIndex, activeParticipantIndex)}</Form.Label>
                                                    <Form.Control className={formClass.formInput}
                                                        id={`${activeTeamIndex}-${activeParticipantIndex}-lastName`}
                                                        type="text"
                                                        placeholder="Last name"
                                                        name="participantLastName"
                                                        maxLength={40}
                                                        value={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantLastName}
                                                        disabled={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        readOnly={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        onChange={event => handleParticipantDetailsChange(activeTeamIndex, activeParticipantIndex, event)}
                                                        isInvalid={teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantLastName}
                                                    />
                                                    <Form.Control.Feedback className="errorText" type="invalid">{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantLastName}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Form.Group className={formClass.dateTimeInput}>
                                                    <Form.Label className={formClass.formLabel}>DOB {showRequiredAsterisk()}</Form.Label>
                                                    <Form.Control className={`${formClass.formInput} ${classes.dobInput}`}
                                                        type="date"
                                                        id={`${activeTeamIndex}-${activeParticipantIndex}-participantDob`}
                                                        name="participantDob"
                                                        value={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantDob}
                                                        disabled={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        readOnly={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        onChange={event => handleParticipantDetailsChange(activeTeamIndex, activeParticipantIndex, event)}
                                                        isInvalid={teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantDob}
                                                    />
                                                    <Form.Control.Feedback className="errorText" type="invalid">{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantDob}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Form.Group>
                                                    <Form.Label className={formClass.formLabel}>Gender {showRequiredAsterisk()}</Form.Label>
                                                    <Form.Select className={formClass.selectInput}
                                                        id={`${activeTeamIndex}-${activeParticipantIndex}-participantGender`}
                                                        name="participantGender"
                                                        value={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantGender}
                                                        disabled={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        readOnly={teamDetailsForm[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.isPartOfQrencia}
                                                        isInvalid={teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantGender}
                                                        onChange={event => handleParticipantDetailsChange(activeTeamIndex, activeParticipantIndex, event)} >
                                                        <option value=""> Select a gender</option>
                                                        {AppConstants.gender?.map(option => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback className="errorText" type="invalid">{teamErrors[activeTeamIndex].learnersForTeamEnrolments[activeParticipantIndex]?.participantGender}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>
                                </Row>
                                <div className={classes.qrenciaIdRow}>
                                    {!teamDetailsForm[activeTeamIndex].teamSavedFlag && <Button onClick={() => saveTeamDetails(activeTeamIndex)} className={classes.modalButton}>Save Team</Button>}
                                </div>
                            </div>
                        </div >
                        <div className={classes.teamOverviewSectionDesktop}>
                            <h5 className={classes.overviewHeading}>Overview</h5>
                            <div className={classes.teamNameSection}>
                                <Row>
                                    {teamDetailsForm.map((team, i) => (
                                        <>
                                            <div className={`${classes.teamNameBox} ${i === activeTeamIndex && classes.activeTeam}`} onClick={() => changeActiveTeam(i)}>
                                                {team.participantTeamName || `Team ${i + 1}`}&nbsp;
                                                {team.teamSavedFlag && <FaCheckCircle className={classes.checkCircle} />}
                                                {teamDetailsForm.length > 1 && <RiDeleteBin6Line onClick={(e) => { e.stopPropagation(); removeTeams(i) }} className={classes.removeTeamIcon} />}
                                                <ul className={classes.teamParticipants}>
                                                    {team?.learnersForTeamEnrolments
                                                        ?.filter(learner => learner.participantFirstName)
                                                        .map((learner, i) => (
                                                            <li key={i}>{learner.participantFirstName}</li>
                                                        ))}
                                                </ul>
                                            </div>

                                        </>
                                    ))}
                                    {(teamDetailsForm.length < enrolmentDetails.teamsLimit || enrolmentDetails.teamsLimit == 0 || enrolmentDetails.teamsLimit == null) && <div className={`${classes.addTeamButton} ${isAddTeamButtonDisabled() && classes.addTeamButtonDisabled}`} onClick={addTeams}> + Add Team</div>}
                                </Row>
                            </div>
                            <div className={classes.totalAmountSection}>
                                <h6>Total Amount </h6>
                                <h6>{enrolmentDetails.cost ? getTotalAmount() : "INR 0"}</h6>
                            </div>
                        </div>
                    </div >
                    <div className={classes.modalFooter}>
                        <div className={classes.errorMessageContainer}>
                            {unsavedTeamError && <ErrorSuccessAlertMessage message={unsavedTeamError} varient={AppConstants.alertVarient[1]} />}
                        </div>
                        <div className={classes.buttonRow}>
                            {/* {existingTeams.length > 0 && <Button data-tooltip-id="team-details" disabled={enrolSpinner} onClick={viewTeamDetails} className={classes.viewTeamButton} ><CiViewList /></Button>} */}
                            {/* <Button className={`${classes.modalButton} ${classes.closeButton}`} disabled={enrolSpinner} onClick={hideTeamEnrolmentForm}>Cancel</Button> */}
                            {enrolmentDetails.payOfflineFlag === AppConstants.yText && <Button onClick={() => submitTeamDetailsForm(AppConstants.teamEnrolmentFormButtons.payOffline)} className={classes.modalButton}>Pay Offline </Button>}
                            <Button disabled={enrolSpinner} onClick={() => submitTeamDetailsForm(AppConstants.teamEnrolmentFormButtons.enrol)} className={classes.modalButton} >{enrolmentDetails.cost ? "Pay Now" : "Enrol Now"} {enrolSpinner && <BiLoaderAlt className="spinner" />}</Button>
                            {/* <Tooltip className={classes.tooltipMessage} id="team-details"><span className={classes.tooltipMessage}>View my existing enroled teams</span></Tooltip> */}
                        </div>

                    </div>
                </Modal.Body >
            </Modal >}

            {
                offlinePaymentRequestedFlag && <Modal show={true} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {offlinePaymentRequestError && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        {offlinePaymentRequestGranted && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {(offlinePaymentRequestGranted || offlinePaymentRequestError) && <p><b>{enrolmentDetails.id} | {enrolmentDetails.title}</b></p>}
                            {!offlinePaymentRequestError && !offlinePaymentRequestGranted && <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /></div>}
                            {!offlinePaymentRequestError && !offlinePaymentRequestGranted && <p>Please wait while we send your enrolment request to the {enrolmentDetails.creatorRole?.toLowerCase()}.</p>}
                            {offlinePaymentRequestGranted && <p>{StringUtils.getParsedMessage(SuccessMessage.offlinePaymentRequestSentSuccess, { creatorName: enrolmentDetails.creatorName, creatorRole: enrolmentDetails.creatorRole?.toLowerCase() })}</p>}
                            {offlinePaymentRequestError && <p>{offlinePaymentRequestError}</p>}
                        </div>
                    </Modal.Body>
                    {(offlinePaymentRequestGranted || offlinePaymentRequestError) && <Modal.Footer className={modalClass.modalFooter}>
                        {<Button className={classes.closeOfflinePaymentButton} onClick={handleModalClose}>Close</Button>}
                    </Modal.Footer>}
                </Modal >
            }
            {/* {showTeamDetailsModal && <TeamEnrolmentDetailsModal learningActivityDetails={{ uniqueId: enrolmentDetails.uniqueId }} closeTeamDetailsModal={closeTeamDetailsModal} />} */}

            {(message !== AppConstants.emptyString) && (showEnrolmentModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} enrolmentDetails={props.enrolmentDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
        </>
    );
}