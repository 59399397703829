import Moment from "react-moment";
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import classes from "./ReviewCardsComponent.module.css";

export const ReviewCardsComponent = ({ review, individualSkillsFlag }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    return <div className={classes.reviewContainer}>
        <div className={classes.profileImageSection}>
            {review.reviewerProfileImagePath ? <div className={classes.reviewInitials}><img src={review.reviewerProfileImagePath} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.reviewInitials}>{review.reviewerNameInitials}</div>}
            <div>
                <p className={classes.reviewerName}>{review.reviewedByName}</p>
                {review.rating && <div className={classes.ratingStarContainer}>
                    {individualSkillsFlag && <p className={classes.dateText}>Review for {review.reviewedUserName} | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{review.reviewDate.replace(' ', 'T') + 'Z'}</Moment>  {timeZone === AppConstants.utcTimezone && `(${review.preferredTimeZone})`}</p>}

                    {!individualSkillsFlag && <p className={classes.dateText}> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{review.reviewDate.replace(' ', 'T') + 'Z'}</Moment>  {timeZone === AppConstants.utcTimezone && `(${review.preferredTimeZone})`}</p>}                </div>}
            </div>
        </div>
        <p className={classes.reviewComment}>{review.review}</p>
    </div>
}