import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { AppConstants } from "../../../constants/AppConstants";
import "./SuggestedSearch.css";

export const SuggestedSearchComponent = (props) => {
    const [competitionsList, setCompetitionsList] = useState([]);

    useEffect(() => {
        async function getAllActiveCompetitionsForExpert() {
            const url = AppConstants.fetchAllActiveCompetitionsOfExpertAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setCompetitionsList(response.map(resp => {
                    let data = {
                        key: `${resp.competitionId} | ${resp.title}`,
                        title: resp.title,
                        uniqueId: resp.uniqueId,
                        competitionId: resp.competitionId
                    }
                    return data;
                }))
            } else {
                setCompetitionsList([]);
            }
        }
        getAllActiveCompetitionsForExpert();
    }, []);

    const handleChange = (value) => {
        if (value[0]?.competitionId) {
            props.changeSelectedCompetition(value[0]);
        }
    };

    return (
        <div className="suggestedSearchContainer">
            <div className="suggestedSearchContainerInput">
                <label className="suggestedSearchLabel">Competition</label>
                <Typeahead
                    className="SuggestedSearchInputBox"
                    placeholder="Search Competition"
                    name="suggestedSearch"
                    id="suggestedSearch"
                    labelKey="key"
                    filterBy={['title', 'uniqueId']}
                    onChange={handleChange}
                    options={competitionsList}
                />
            </div>
            <Button className="comppossButton" onClick={() => props.showComposeMailSection()}>Compose</Button>
        </div>
    )
}