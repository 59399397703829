import { AppConstants } from "../../constants/AppConstants";
import { BulkUploadSheetComponent } from '../../component/bulkUploadSheetComponent/BulkUploadSheetComponent';
import { SuccessMessage } from "../../constants/SuccessMessage";

export const AdminBulkEnrolPage = () => {

    return (
        <div className="adminContentSection">
            <BulkUploadSheetComponent heading="Bulk Enrol Learners" uploadSheetAPI={AppConstants.studentBulkSignUpAndEnrolAPI} downloadedSheetName={AppConstants.adminBulkEnrolStudentList} downloadTemplateAPI={AppConstants.downloadStudentSignUpAndEnrolmentTemplateAPI} multipartFileName="studentSignUpAndEnrolSheet" uploadFileLabel="Participant Sheet" successMessage={SuccessMessage.adminBulkEnrolSheetUploadSuccess} />
        </div>
    )
}