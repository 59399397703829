import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import classes from '../../common/ErrorPage.module.css';
import parse from 'html-react-parser';

export const ErrorPage = () => {
  const [footerData, setFooterData] = useState();
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const sectionBackgroundImageUrl = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.errorPageDesktopImageS3Path}`;
  const [bgImage, setBgImage] = useState(sectionBackgroundImageUrl);

  useEffect(() => {
    fetchFooterData();
    async function fetchFooterData() {
      const url = AppConstants.landingPageDataAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setFooterData(response);
    }
  }, []);

  return (
    <>
      <LandingPageTopNavigation />
      <img src={bgImage} className={classes.backgroundImage}></img>
      <div className={classes.contentSection}>
        <h1 className={classes.heading}>{AppConstants.errorPageHeading}</h1>
        <h3 className={classes.subHeading}>{parse(AppConstants.errorPageSubHeading)}</h3>
        <Link to={AppConstants.forwardSlash}><Button className={classes.homeButton}>Home</Button></Link>
      </div>
      {footerData && <LandingPageFooter landingPageData={footerData} />}
    </>
  )

}