import React, { useState, useEffect } from "react";
import classes from "./AdvancedCompetitionSettings.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { AiOutlineExclamationCircle, AiFillDelete } from 'react-icons/ai';
import { Tooltip } from "react-tooltip";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { Form, Button, Row, Col } from "react-bootstrap";
import formClass from "../../../common/FormStyling.module.css";

export const AdvancedCompetitionSettings = (props) => {
    const { competitionDetails, cost, stage, isGymnasticsSelected, saveCurrentStep } = props;
    const teamEnrolmentFormLabels = AppConstants.teamEnrolmentsFormLables;
    const [feeWaiver, setFeeWaiver] = useState(AppConstants.falseText);
    const [publicEnrolments, setPublicEnrolments] = useState(AppConstants.falseText);
    const [performanceReport, setPerformanceReport] = useState(AppConstants.falseText);
    const [onlyForClubQrencia, setOnlyForCLubQrencia] = useState(AppConstants.falseText);
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(AppConstants.falseText);
    const [enableTeamEnrolments, setEnableTeamEnrolments] = useState(AppConstants.falseText);
    const [mappedClasses, setMappedClasses] = useState([]);
    const [markingType, setMarkingType] = useState(AppConstants.emptyString);
    const [markingParameter, setMarkingParameter] = useState(AppConstants.emptyString);
    const [isOfflinePaymentDisabled, setIsOfflinePaymentDisabled] = useState(AppConstants.falseText);
    const [selectedClasses, setSelectedClasses] = useState([{
        schoolClass: AppConstants.emptyString,
        allSections: []
    }]);
    const [teamParticipantsLimit, setTeamParticipantsLimit] = useState(AppConstants.emptyString);
    const [teamsLimit, setTeamsLimit] = useState(AppConstants.emptyString);

    const stages = isGymnasticsSelected ? AppConstants.createGymnasticsCompetitionActions : AppConstants.createCompetitionActions;

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({
                feeWaiver,
                publicEnrolments,
                performanceReport,
                onlyForClubQrencia,
                enableOfflinePayment,
                selectedClasses,
                enableTeamEnrolments,
                markingParameter,
                markingType,
                teamParticipantsLimit,
                teamsLimit
            });
        }

        handleChange();
    }, [feeWaiver, publicEnrolments, performanceReport, onlyForClubQrencia, selectedClasses, enableOfflinePayment, markingType, markingParameter, enableTeamEnrolments, teamParticipantsLimit, teamsLimit]);

    useEffect(() => {
        if (competitionDetails.id) {
            setPerformanceReport(competitionDetails.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
        } else {
            if (!props.rubricsConfigured) {
                setPerformanceReport(AppConstants.trueText);
                setMarkingType(AppConstants.emptyString);
                setMarkingParameter(AppConstants.emptyString);
            } else {
                setPerformanceReport(AppConstants.falseText);
            }
        }
    }, [props.rubricsConfigured, competitionDetails]);

    useEffect(() => {
        let netAmount = cost ? parseInt(cost) : 0;
        if (netAmount <= 0) {
            setIsOfflinePaymentDisabled(AppConstants.trueText);
            setEnableOfflinePayment(AppConstants.falseText);
        } else {
            setIsOfflinePaymentDisabled(AppConstants.falseText);
        }
    }, [cost]);

    useEffect(() => {
        if (competitionDetails.id) {
            setEnableOfflinePayment(competitionDetails.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setFeeWaiver(competitionDetails.competitionFeeWaiverFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText)
            setPublicEnrolments(competitionDetails.disablePublicEnrolmentFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setPerformanceReport(competitionDetails.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setOnlyForCLubQrencia(competitionDetails.clubQrenciaFlg === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setEnableTeamEnrolments(competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setMarkingParameter(competitionDetails.markingParameter || AppConstants.emptyString);
            setMarkingType(competitionDetails.markingType || AppConstants.emptyString);
            setTeamParticipantsLimit(competitionDetails.teamParticipantsLimit || AppConstants.emptyString);
            setTeamsLimit(competitionDetails.teamsLimit || AppConstants.emptyString);

            // set classes if club qrencia flag is "Y"
            if (competitionDetails.clubQrenciaFlg === AppConstants.yText) {
                let resultArray = [];
                competitionDetails.selectedClasses.split(',').forEach(pair => {
                    const [className, section] = pair.trim().split(AppConstants.hyphen);
                    const matchingClass = mappedClasses?.find(c => c.className === className);
                    if (matchingClass) {
                        const allSections = matchingClass.sectionsArray.map(sec => ({
                            section: sec,
                            isActive: sec === section
                        }));
                        resultArray.push({
                            allSections: allSections,
                            schoolClass: className
                        });
                    }
                });
                setSelectedClasses(resultArray);
            }
        } else {
            setFeeWaiver(AppConstants.falseText);
            setPublicEnrolments(AppConstants.falseText);
            setPerformanceReport(AppConstants.falseText);
            setOnlyForCLubQrencia(AppConstants.falseText);
            setEnableOfflinePayment(AppConstants.falseText);
            setEnableTeamEnrolments(AppConstants.falseText);
            setMarkingParameter(AppConstants.emptyString);
            setMarkingType(AppConstants.emptyString);
            setTeamParticipantsLimit(AppConstants.emptyString);
            setTeamsLimit(AppConstants.emptyString);
        }
    }, [competitionDetails]);

    useEffect(() => {
        const fetchMappedClassesForSchool = async () => {
            const url = `${AppConstants.getClassAndSectionsForSchoolIdAPI}${props.schoolId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setMappedClasses(response);
            } else {
                setMappedClasses([]);
            }
        };
        if (props.schoolId) {
            fetchMappedClassesForSchool();
        }
    }, [props.schoolId]);

    const chagneClubQrenciaFlag = () => {
        // toggle the flag value
        setOnlyForCLubQrencia(!onlyForClubQrencia);

        if (onlyForClubQrencia) {
            // if current flag value is true
            setSelectedClasses([{
                schoolClass: AppConstants.emptyString,
                allSections: []
            }]);
        }
    };

    const handleClassChange = (event, idx) => {
        let c = [...selectedClasses];
        if (event.target.value) {
            c[idx].schoolClass = event.target.value;
            c[idx].allSections = mappedClasses.filter(cl => cl.className === event.target.value)[0].sectionsArray.map(section => {
                return {
                    section,
                    isActive: AppConstants.trueText
                }
            });
        } else {
            // remove the class at index
            c[idx].schoolClass = AppConstants.emptyString;
            c[idx].allSections = [];
        }
        setSelectedClasses(c);
    };

    const getNumberOfActiveSections = (sectionsArray) => {
        let count = 0;
        sectionsArray.forEach(section => count = section.isActive ? count + 1 : count);
        return count;
    }

    const handleSectionChange = (event, classIndex, sectionIndex) => {
        let c = [...selectedClasses];
        if (event.target.checked) {
            c[classIndex].allSections[sectionIndex].isActive = AppConstants.trueText;
        } else {
            c[classIndex].allSections[sectionIndex].isActive = AppConstants.falseText;
            if (getNumberOfActiveSections(c[classIndex].allSections) === 0) {
                c[classIndex].schoolClass = AppConstants.emptyString;
                c[classIndex].allSections = [];
            }
        };

        setSelectedClasses(c);
    };

    const addNewClass = () => {
        let newClass = {
            schoolClass: AppConstants.emptyString,
            allSections: []
        }
        setSelectedClasses([...selectedClasses, newClass]);
    };

    const isClassOptionDisabled = (className) => {
        let arr = selectedClasses.filter(cls => cls.schoolClass === className);
        if (arr.length > 0) return true;
        else return false;
    };

    const deleteClass = (index) => {
        setSelectedClasses(prevClasses => {
            const updatedClasses = [...prevClasses];
            updatedClasses.splice(index, 1);
            return updatedClasses;
        });
    };

    const handleClickNext = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const changeTeamEnrolments = (e) => {
        if (e.target.checked) {
            setPerformanceReport(AppConstants.trueText);
        } else {
            setPerformanceReport(AppConstants.falseText);
        }
        setEnableTeamEnrolments(!enableTeamEnrolments);
        setMarkingParameter(AppConstants.emptyString);
        setMarkingType(AppConstants.emptyString);
        setTeamParticipantsLimit(AppConstants.emptyString);
        setTeamsLimit(AppConstants.emptyString);
        setOnlyForCLubQrencia(AppConstants.falseText);
        setSelectedClasses([{
            schoolClass: AppConstants.emptyString,
            allSections: []
        }]);
    };

    const changeMarkingType = (event) => {
        setMarkingType(event.target.value);
        setMarkingParameter(AppConstants.emptyString);
    }

    const checkValidNumber = (event) => {
        let excludedKeywords = [".", "-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };

    const changeParticipationLimit = (event) => {
        setTeamParticipantsLimit(event.target.value);
    }

    const changeTeamLimit = (event) => {
        setTeamsLimit(event.target.value);
    }


    return (
        <>
            <div className={classes.mainContainer}>
                {!props.rubricsConfigured && <div className={classes.warningInfoContainer}>
                    <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />Since judging parameters are not configured, the result will not be shared with the participants</p></div>
                </div>
                }
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm"><input type="checkbox" checked={feeWaiver} onChange={() => setFeeWaiver(!feeWaiver)} disabled={onlyForClubQrencia} /></span>Request Fee Waiver <RiInformationFill className={classes.infoButton} data-tooltip-id="feeWaiverInfo" /></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm"><input type="checkbox" checked={publicEnrolments} onChange={() => setPublicEnrolments(!publicEnrolments)} disabled={onlyForClubQrencia} /></span>Disable Public Enrolments<RiInformationFill className={classes.infoButton} data-tooltip-id="publicEnrolmentsInfo" /></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm"><input disabled={!props.rubricsConfigured || enableTeamEnrolments} readOnly={props.rubricsConfigured || enableTeamEnrolments} type="checkbox" checked={(!props.rubricsConfigured || enableTeamEnrolments) ? true : performanceReport} onChange={() => setPerformanceReport(!performanceReport)} />
                    </span>Disable Performance Analysis<RiInformationFill className={classes.infoButton} data-tooltip-id="performanceReportInfo" /></div>
                </div>
                {<div className={classes.switchContainer}>
                    <div className={classes.switchText}><span className="checkboxInputForm"><input className={classes.switchInput} disabled={isOfflinePaymentDisabled || onlyForClubQrencia || feeWaiver} type="checkbox" checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span>Enable Offline Payment<RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /></div>
                    <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
                </div>}
                <Tooltip className={classes.tooltipMessage} id="feeWaiverInfo"><span className={classes.tooltipMessage}>{AppConstants.feeWaiverInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="publicEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePublicEnrolmentInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="performanceReportInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePerformanceAnalysisInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="clubQrenciaInfo"><span className={classes.tooltipMessage}>By selecting this, the competition will be created exclusively for your school and for the below selected classes.</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="teamEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.enableTeamEnrolmentsInfo}</span></Tooltip>
            </div>

            {<div className={classes.switchContainer}>
                <div className={classes.switchText}><span className="checkboxInputForm"><input type="checkbox" checked={enableTeamEnrolments} disabled={onlyForClubQrencia} onChange={(e) => changeTeamEnrolments(e)} />
                </span>Enable Team Enrolments<RiInformationFill className={classes.infoButton} data-tooltip-id="teamEnrolmentsInfo" /></div>
            </div>}
            {enableTeamEnrolments && <Row className="mb-4" xs={1} sm={2} md={2} lg={2} xl={3}>
                <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.teamParticipantsLimit.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        placeholder="Team Size"
                        name={teamEnrolmentFormLabels.teamParticipantsLimit.name}
                        value={teamParticipantsLimit}
                        onKeyDown={checkValidNumber}
                        onChange={changeParticipationLimit}
                    />
                </Form.Group>
                <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.teamsLimit.label}</Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        placeholder="Number of Teams Allowed"
                        name={teamEnrolmentFormLabels.teamsLimit.name}
                        value={teamsLimit}
                        onKeyDown={checkValidNumber}
                        onChange={changeTeamLimit}
                    />
                </Form.Group>
                {props.rubricsConfigured && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.markingType.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name={teamEnrolmentFormLabels.markingType.name}
                        value={markingType}
                        disabled={!props.rubricsConfigured}
                        readOnly={!props.rubricsConfigured}
                        onChange={changeMarkingType} >
                        <option value={AppConstants.emptyString} defaultValue>Select Marking Type</option>
                        {Object.values(AppConstants.markingTypes)?.map(option => (
                            <option key={option?.value} value={option?.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>}
                {markingType === AppConstants.markingTypes.individual.value && props.rubricsConfigured && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>{teamEnrolmentFormLabels.markingParameter.label}<span className="redAstrisk">*</span></Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name={teamEnrolmentFormLabels.markingParameter.name}
                        value={markingParameter}
                        disabled={!props.rubricsConfigured}
                        readOnly={!props.rubricsConfigured}
                        onChange={(e) => setMarkingParameter(e.target.value)} >
                        <option value={AppConstants.emptyString} defaultValue>Select Scoring Parameter</option>
                        {Object.values(AppConstants.markingParameters)?.map(option => (
                            <option key={option.value} value={option.key}>
                                {option.value}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>}
            </Row>}

            {props.isExpertAssociatedWithClubQrencia === AppConstants.yText && <div className={classes.switchContainer}>
                <div className={classes.switchText}><span className="checkboxInputForm">
                    <input readOnly={props.rubricsConfigured} type="checkbox" checked={onlyForClubQrencia} disabled={enableTeamEnrolments} onChange={() => { setPublicEnrolments(AppConstants.falseText); setFeeWaiver(AppConstants.falseText); chagneClubQrenciaFlag(); setEnableTeamEnrolments(AppConstants.falseText) }} />
                </span>Only for Club Qrencia<RiInformationFill className={classes.infoButton} data-tooltip-id="clubQrenciaInfo" /></div>
            </div>}
            {onlyForClubQrencia && !enableTeamEnrolments && <div className="row mb-4"> {
                selectedClasses.map((sc, classIndex) => <div key={classIndex}>
                    <div className={classes.mainClassSection}>
                        <div className={classes.clubQrenciaClassDropdown}>
                            <Form.Label className={formClass.formLabel}>Class</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={`classDropdown-${classIndex}`}
                                value={sc.schoolClass}
                                onChange={e => handleClassChange(e, classIndex)}
                                required
                            ><option value={AppConstants.emptyString} defaultValue>Select a Class</option>
                                {mappedClasses?.map(option => (
                                    <option disabled={isClassOptionDisabled(option.className)} key={option.className} value={option.className}>
                                        {option.className}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className={classes.removeIcon}>
                            {classIndex !== 0 && <AiFillDelete onClick={() => deleteClass(classIndex)} />}
                        </div>
                    </div>
                    {sc.schoolClass !== AppConstants.emptyString && <div className={classes.sectionsContainer}>
                        <h5 className={classes.sectionsHeading}>Sections</h5>
                        {
                            sc.allSections.map((section, sectionIndex) => {
                                return <div key={section.section} className={classes.switchContainer}>
                                    <div className={classes.switchText}>{section.section} <span><input type="checkbox" checked={section.isActive} onChange={(e) => handleSectionChange(e, classIndex, sectionIndex)} />
                                    </span></div>
                                </div>

                            })
                        }
                    </div>}
                    {classIndex === selectedClasses.length - 1 && mappedClasses.length != selectedClasses.length && <Button className={classes.newButton} onClick={addNewClass}>+ Add Class</Button>}
                </div>)
            }
            </div>

            }
            {isGymnasticsSelected && <div className={classes.nextBtn}>
                <Button type="submit" onClick={handleClickNext}>{AppConstants.nextText}</Button>
            </div>}
        </>
    )
}