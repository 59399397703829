import moment from "moment";
import { AppConstants } from "../../../constants/AppConstants";
import { MathUtil } from "../../../utils/MathUtil";
import classes from "../../offlinePaymentRequestComponents/pendingEnrolmentRequestDetails/PendingEnrolmentRequestDetails.module.css";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AgGridReact } from "ag-grid-react";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

export const AdminOfflinePaymentDetailsStats = () => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [pendingEnrolmentDetails, setPendingEnrolmentDetails] = useState([]);

    useEffect(() => {
        const fetchPendingEnrolmentRequestDetails = async () => {
            const url = AppConstants.adminGetOfflinePaymentRequestDetailsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setPendingEnrolmentDetails(response);
            } else {
                setPendingEnrolmentDetails([]);
            }
        }

        fetchPendingEnrolmentRequestDetails();
    }, []);

    const getLearningActivityTextClass = ({ data }) => {
        if (data.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
            return `${classes.competitionTextColor}`;
        } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.course) {
            return `${classes.courseTextColor}`;
        } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
            return `${classes.workshopTextColor}`;
        } else {
            return AppConstants.emptyString;
        }
    };

    const getPaymentStatusTextClass = ({ data }) => {
        if (data.paymentStatus === AppConstants.pendingCODStatus.pending) {
            return `${classes.pendingCodText}`;
        } else if (data.paymentStatus === AppConstants.pendingCODStatus.cancel) {
            return `${classes.cancelledCodText}`;
        } else if (data.paymentStatus === AppConstants.pendingCODStatus.approve) {
            return `${classes.approvedCodText}`;
        } else {
            return AppConstants.emptyString;
        }
    };

    const columnDefs = [
        {
            headerName: "Qrencia Id",
            field: "learnerQrenciaId",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerQrenciaId,
            valueGetter: ({ data }) => {
                return data.learnerQrenciaId
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "learnerName",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerName,
            valueGetter: ({ data }) => {
                return data.learnerName
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Phone",
            field: "learnerMobileNumber",
            tooltipField: "learnerMobileNumber",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.learnerMobileNumber,
            valueGetter: ({ data }) => {
                return data.learnerMobileNumber;
            },
        },
        {
            headerName: "Learning Activity Id",
            field: "learningActivityId",
            tooltipField: "learningActivityId",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.learningActivityId,
            valueGetter: ({ data }) => {
                return data.learningActivityId;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Learning Activity Name",
            field: "learningActivityTitle",
            tooltipField: "learningActivityTitle",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.learningActivityTitle,
            valueGetter: ({ data }) => {
                return data.learningActivityTitle;
            },
            cellClass: getLearningActivityTextClass,
            headerClass: "multi-line-header",
            minWidth: 120,
        },
        {
            headerName: "Creator Name",
            field: "organizedBy",
            tooltipField: "organizedBy",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.organizedBy,
            valueGetter: ({ data }) => {
                return data.organizedBy;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Status",
            field: "paymentStatus",
            tooltipField: "paymentStatus",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.paymentStatus,
            valueGetter: ({ data }) => {
                return data.paymentStatus;
            },
            cellClass: getPaymentStatusTextClass
        },
        {
            headerName: "Requested On",
            field: "offlinepaymentRequestedOn",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.offlinepaymentRequestedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.offlinepaymentRequestedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Updated On",
            field: "paymentStatusUpdatedOn",
            tooltipValueGetter: ({ data }) => data.paymentStatusUpdatedOn ? `${moment.utc(data.paymentStatusUpdatedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return data.paymentStatusUpdatedOn ? `${moment.utc(data.paymentStatusUpdatedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText;
            }
        },
        {
            headerName: "Cost",
            field: "learningActivityNetAmount",
            tooltipField: "learningActivityNetAmount",
            filter: 'agTextColumnFilter',
            maxWidth: 80,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.learningActivityNetAmount),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.learningActivityNetAmount);
            },
        },
        {
            headerName: "Classes",
            field: "learningActivityNumberOfClasses",
            filter: 'agTextColumnFilter',
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => (data.learningActivityNumberOfClasses && data.learningActivityNumberOfClasses > 0) ? data.learningActivityNumberOfClasses : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityNumberOfClasses && data.learningActivityNumberOfClasses > 0) ? data.learningActivityNumberOfClasses : AppConstants.notAvailableText;
            },
        },
        {
            headerName: "Duration (Days)",
            field: "learningActivityPackageDuration",
            filter: 'agTextColumnFilter',
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => (data.learningActivityPackageDuration && data.learningActivityPackageDuration > 0) ? data.learningActivityPackageDuration : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityPackageDuration && data.learningActivityPackageDuration > 0) ? data.learningActivityPackageDuration : AppConstants.notAvailableText;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Class Validity (Days)",
            field: "learningActivityValidity",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => (data.learningActivityValidity && data.learningActivityValidity > 0) ? data.learningActivityValidity : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityValidity && data.learningActivityValidity > 0) ? data.learningActivityValidity : AppConstants.notAvailableText;
            },
            headerClass: "multi-line-header",
            minWidth: 120,
        },
    ];

    return (
        <Row className="mt-2 mb-2">
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={pendingEnrolmentDetails}
                    pagination={true}
                    paginationPageSize={10}
                    enableBrowserTooltips={true}
                    domLayout='autoHeight'
                    suppressRowHoverHighlight={true}
                    defaultColDef={{
                        filter: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                        suppressMenu: true,
                        suppressHorizontalScroll: true,
                        floatingFilterComponentParams: { suppressFilterButton: true },
                        floatingFilter: true,
                    }}
                    enableFilter={true}
                    enableSorting={true}
                    enableSearching={true}
                    headerClass={classes.obrGridHeader}
                    rowClass={classes.obrGridTable}
                />
            </div>
        </Row>
    )
}