import { Nav, Navbar, Badge, } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { HiOutlineBookOpen } from "react-icons/hi";
import { FaRegBell, FaRegUserCircle } from "react-icons/fa";
import { AppConstants } from "../../constants/AppConstants";
import Auth from "../../utils/Auth";
import '../../common/TemplateStyle.css';

export const TeamLeftNav = (props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Navbar expanded={expanded} expand="lg" className="sideNavigation">
                <Navbar.Toggle className="sidebarToggleButton" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="sideNavigationContent">
                    <Nav className="sideNavigationListSection">
                        <div className="profileSection">
                            <div className="profileImageDiv">
                                {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div>
                                    <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className="profileImage" alt="Profile" />
                                </div>}
                                {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                                    <div className="userInitials">{Auth.getLoggedInUserDetails().nameInitials.toUpperCase()}</div>
                                </div>}
                            </div>
                            <div className="userNameDiv">
                                <p className="userName">{Auth.getLoggedInName()}</p>
                                <p className="qrenciaIdText">QID: {Auth.getLoggedInUserDetails().userId}</p>
                            </div>
                        </div>
                        <div className="referButtonSection">
                            <div className="notificationSidebarSection">
                                <FaRegBell className="notificationSidebarImage" />
                                <Badge bg="danger" className="notificationSidebarBadge">0</Badge>
                            </div>
                        </div>


                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.teamHomePath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaRegUserCircle className="linkIcons" />
                                <p className="navigationLinks">Home</p>
                            </NavLink>
                        </Nav.Item>
                        <div className="sideNavigationItemMobileView">
                            <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                <NavLink
                                    to={AppConstants.teamMyLearningPath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                ><HiOutlineBookOpen className="linkIcons" />
                                    <p className="navigationLinks">Past Enrolments</p>
                                </NavLink>
                            </Nav.Item>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}