import { useEffect, useState } from 'react';
import classes from "./ExpertBulkEnrolPage.module.css";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AppConstants } from '../../constants/AppConstants';
import { Button } from 'react-bootstrap';
import { SuggestiveCompetitionSearch } from '../../component/suggestiveCompetitionSearch/SuggestiveCompetitonSearch';
import { ExpertBulkEnrolmentSheet } from '../../component/expertBulkEnrolment/ExpertBulkEnrolmentSheet';
import { ErrorSuccessAlertMessage } from '../../component/errorSuccessMessage/ErrorSuccessAlertMessage';

export const ExpertBulkEnrolPage = () => {
    const [competitionsList, setCompetitionsList] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState(AppConstants.emptyString);
    const [showSheetSection, setShowSheetSection] = useState(AppConstants.falseText);
    const [requiredMessage, setRequiredMessage] = useState(AppConstants.emptyString);

    useEffect(() => {
        const fetchExpertCompetitions = async () => {
            let url = AppConstants.getDisabledPublicEnromentCompetitionsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setCompetitionsList(response.map(resp => {
                    let data = {
                        key: `${resp.competitionId} | ${resp.title}`,
                        title: resp.title,
                        uniqueId: resp.uniqueId,
                        competitionId: resp.competitionId
                    }
                    return data;
                }));
            } else {
                setCompetitionsList([]);
            }
        }

        fetchExpertCompetitions();
    }, []);

    const handleCompetitionChange = (value) => {
        setSelectedCompetition(value);
    };

    const handleShowTemplateScreen = () => {
        if (selectedCompetition.uniqueId) {
            setShowSheetSection(AppConstants.trueText);
            setRequiredMessage(AppConstants.emptyString);
        } else {
            setRequiredMessage("Please select a competition");
            let timer = setTimeout(() => {
                setRequiredMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    return (
        <>
            <div className="expertContentSection">
                <h3 className={classes.heading}>Bulk Enrol</h3>
                <div className={classes.suggestiveCompetitionSearchComponent}>
                    <div className={classes.suggestiveSearchInputContainer}>
                        <SuggestiveCompetitionSearch competitionsList={competitionsList} handleCompetitionChange={handleCompetitionChange} />
                        <Button className={classes.selectButton} onClick={handleShowTemplateScreen}>Select</Button>
                    </div>
                    {requiredMessage && <div className={classes.errorMessageDiv}>
                        <ErrorSuccessAlertMessage message={requiredMessage} varient={AppConstants.alertVarient[1]} />
                    </div>}
                </div>
                {showSheetSection && selectedCompetition && <ExpertBulkEnrolmentSheet selectedCompetition={selectedCompetition} />}
            </div>
        </>
    )
}
