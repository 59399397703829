import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { RxCross1 } from "react-icons/rx";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../../utils/DateTimeUtil";
import { AppConstants } from "../../../../constants/AppConstants";
import formClass from "../../../../common/FormStyling.module.css";
import { ErrorSuccessAlertMessage } from "../../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { SuccessMessage } from "../../../../constants/SuccessMessage";
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import { ErrorMessages } from "../../../../constants/ErrorMessages";
import modalClass from "../../../cards/CompetitionsCards.module.css"
import classes from "./AttendanceBasedTrackingScreen.module.css";
import Moment from "react-moment";
import { ViewSelectedWeekDays } from "../../../calendar/ViewSelectedWeekDays";
import { MathUtil } from "../../../../utils/MathUtil";

export const AttendanceBasedTrackingScreen = ({ selectedCourse, courseUniqueId }) => {
    const attendanceStatus = AppConstants.attendanceStatus;
    const subscriptionStatus = AppConstants.subscriptionStatus;
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [selectedDate, setSelectedDate] = useState(AppConstants.emptyString);
    const [learnerDetails, setLearnerDetails] = useState([]);
    const [statusExpiredLearnersDetails, setStatusExpiredLearnersDetails] = useState([]);
    const [dateError, setDateError] = useState(AppConstants.emptyString);
    const [showAttendanceButton, setShowAttendanceButton] = useState(AppConstants.falseText);
    const [subscriptionReminderSpinner, setSubscriptionReminderSpinner] = useState([]);
    const [subscriptionReminderEmailMessage, setSubscriptionReminderEmailMessage] = useState(AppConstants.emptyString);
    const [subscriptionAlertVarient, setSubscriptionAlertVarient] = useState(AppConstants.emptyString);
    const [saveAttendanceSpinner, setSaveAttendanceSpinner] = useState(AppConstants.falseText);
    const [saveAttendanceMessage, setSaveAttendanceMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [refreshFlag, setRefreshFlag] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [showWarningMessage, setShowWarningMessage] = useState(AppConstants.falseText);
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;

    const commonColumnDefs = [{
        headerName: "Learner Id",
        field: "learnerId",
        maxWidth: 100,
        tooltipValueGetter: ({ data }) => data.learnerId,
        valueGetter: ({ data }) => {
            return data.learnerId
        },
        filter: 'agTextColumnFilter'
    },
    {
        headerName: "Name",
        field: "name",
        width: 300,
        tooltipValueGetter: ({ data }) => data.learnerName,
        valueGetter: ({ data }) => {
            return data.learnerName
        },
        filter: 'agTextColumnFilter'
    },
    {
        headerName: "Phone",
        field: "mobileNumber",
        tooltipField: "mobileNumber",
        filter: 'agTextColumnFilter',
        tooltipValueGetter: ({ data }) => data.mobileNumber,
        valueGetter: ({ data }) => {
            return data.mobileNumber;
        },
    },]

    const learnerColumnDefsWithEndDate = [
        {
            headerName: "Remaining Classes (as of today)",
            field: "previousRemainingClasses",
            tooltipValueGetter: ({ data }) => data.previousRemainingClasses,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: `${selectedCourse.validity > 0 ? "Class Validity" : "Subscription End Date"}`,
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Attendance",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>}
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>}
            </div>,
            minWidth: 200,
        }
    ];

    const learnerColumnDefsWithoutEndDate = [
        {
            headerName: "Remaining Classes (as of today)",
            field: "previousRemainingClasses",
            tooltipValueGetter: ({ data }) => data.previousRemainingClasses,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Attendance",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>}
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>}
            </div>,
            minWidth: 200,
        }
    ];
    const structuredAttendanceColumnDefs = [
        {
            headerName: "Attendance",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>}
                {(params.data.remainingClasses !== 0 || learnerDetails[params.rowIndex].attendance !== AppConstants.nullText || params.data.wasAttendanceMarked) && <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>}
            </div>,
            minWidth: 200,
        }
    ];

    const durationAttendanceActiveColumnDefs = [
        {
            headerName: "Subscription Start Date",
            field: "subscriptionStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Subscription End Date",
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Attendance",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                {<span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>}
                {<span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.active)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>}
            </div>,
            minWidth: 200,
        }
    ];

    const durationAttendanceExpiredColumnDefs = [
        {
            headerName: "Subscription Start Date",
            field: "subscriptionStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Subscription End Date",
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Attendance",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                {<span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>}
                {<span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>}
                {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText && <Button disabled={subscriptionReminderSpinner[params.rowIndex]} onClick={() => sendSubscriptionReminder(params.data.learnerEmail, params.rowIndex)} className={`${classes.reminderButton}`}>Reminder {subscriptionReminderSpinner[params.rowIndex] && <BiLoaderAlt className="spinner" />}</Button>}
            </div>,
            minWidth: 300,
        }
    ];


    const learnerExpiredColumnsDefsWithEndDate = [
        {
            headerName: "Last Subscribed On",
            field: "subscriptionStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Remaining Classes (as of today)",
            field: "previousRemainingClasses",
            tooltipValueGetter: ({ data }) => data.previousRemainingClasses,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Class Validity",
            field: "subscriptionEndDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>
                <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>
                {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText && <Button disabled={subscriptionReminderSpinner[params.rowIndex]} onClick={() => sendSubscriptionReminder(params.data.learnerEmail, params.rowIndex)} className={`${classes.reminderButton}`}>Reminder {subscriptionReminderSpinner[params.rowIndex] && <BiLoaderAlt className="spinner" />}</Button>}
            </div>,
            minWidth: 280,
        }
    ];

    const learnerExpiredColumnsDefsWithoutEndDate = [
        {
            headerName: "Last Subscribed On",
            field: "subscriptionStartDate",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Remaining Classes (as of today)",
            field: "previousRemainingClasses",
            tooltipValueGetter: ({ data }) => data.previousRemainingClasses,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.present, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} ><TiTick />Present</span>
                <span onClick={() => markAttendanceForId(params.data.learnerId, AppConstants.attendanceStatus.absent, AppConstants.subscriptionStatus.expired)} className={`${classes.actionButton} ${params.data.attendance === "Absent" && classes.absentButton}`}><RxCross1 /> Absent</span>
                {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText && <Button disabled={subscriptionReminderSpinner[params.rowIndex]} onClick={() => sendSubscriptionReminder(params.data.learnerEmail, params.rowIndex)} className={`${classes.reminderButton}`}>Reminder {subscriptionReminderSpinner[params.rowIndex] && <BiLoaderAlt className="spinner" />}</Button>}
            </div>,
            minWidth: 280,
        }
    ];

    useEffect(() => {
        if (selectedCourse.courseType === AppConstants.structuredCourseType && DateTime.local().setZone(timeZone) > DateTime.fromISO(`${selectedCourse.utcEndDate}T${selectedCourse.windowEndTime}Z`).setZone(timeZone)) {
            setSelectedDate(DateTime.fromISO(`${selectedCourse.utcEndDate}T${selectedCourse.windowEndTime}Z`).setZone(timeZone).toISODate());
            fetchAttendanceStatusOnDate(DateTime.fromISO(`${selectedCourse.utcEndDate}T${selectedCourse.windowEndTime}Z`).setZone(timeZone).toISODate());
        } else {
            setSelectedDate(new Date().toISOString().split('T')[0]);
            fetchAttendanceStatusOnDate(new Date().toISOString().split('T')[0]);
        }
    }, [selectedCourse]);

    const changeSelectedDate = (e) => {
        setSelectedDate(e.target.value);
        if (!e.target.value) {
            setShowAttendanceButton(AppConstants.falseText);
        }
    }

    const markAttendanceForAll = (status) => {
        if (!selectedDate) {
            return null;
        }
        setLearnerDetails(learnerDetails.map(learner => ({ ...learner, attendance: learner.attendance === AppConstants.nullText && learner.subscriptionStatus === AppConstants.subscriptionStatus.expired ? AppConstants.nullText : status })));
        if (selectedCourse.courseType === AppConstants.flexibleCourseType) {
            setStatusExpiredLearnersDetails(statusExpiredLearnersDetails.map(learner => ({ ...learner, attendance: learner.attendance !== AppConstants.nullText ? status : AppConstants.nullText })));
        }
    };

    const isDateValid = (selectedDate) => {
        if (!selectedDate) {
            setDateError(ErrorMessages.selectMarkAttendanceDateError);
            return false;
        } else {
            const currentDate = new Date();
            if ((new Date(selectedDate) > currentDate)) {
                setDateError(ErrorMessages.futureDateMarkAttendanceError);
                return false
            }

            if (selectedCourse.courseType === AppConstants.structuredCourseType) {
                const startTime = `${selectedCourse.utcStartDate} ${selectedCourse.windowStartTime}`;
                const endTime = `${selectedCourse.utcEndDate} 23:59:59`;
                const startDateTime = DateTime.fromISO(startTime.replace(' ', 'T') + 'Z').setZone(timeZone);
                const endDateTime = DateTime.fromISO(endTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const selectedDateTime = DateTime.fromISO(`${selectedDate}T23:59:59Z`).setZone(timeZone);
                if (selectedDateTime > endDateTime || selectedDateTime < startDateTime) {
                    setDateError(ErrorMessages.structuredCourseDateRangeError);
                    return false
                } else {
                    return true;
                }
            }
            return true;
        }
    };

    const fetchAttendanceStatusOnDate = async (attendanceDate) => {
        setDateError(AppConstants.emptyString);
        if (!isDateValid(attendanceDate)) {
            setShowAttendanceButton(AppConstants.falseText);
            return null;
        } else {
            setDateError(AppConstants.emptyString);
        }
        if (!refreshFlag) {
            setShowAttendanceButton(AppConstants.falseText);
        }

        const convertedDate = DateTimeUtil.createDateTimeObject(attendanceDate, "23:59:59").toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`)
        const url = `${AppConstants.getStudentAttendanceDetailsAPI}${courseUniqueId}&classDate=${encodeURIComponent(convertedDate)}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setShowWarningMessage(AppConstants.trueText);
            const expiredLearnerDetails = response.filter(learner => learner.subscriptionStatus === AppConstants.subscriptionStatus.expired);
            if (selectedCourse.courseType === AppConstants.structuredCourseType) {
                setLearnerDetails(response);
            } else {
                setLearnerDetails(response.filter(learner => learner.subscriptionStatus === AppConstants.subscriptionStatus.active));
            }
            setStatusExpiredLearnersDetails(expiredLearnerDetails);
            setSubscriptionReminderSpinner(new Array(expiredLearnerDetails.length).fill(AppConstants.falseText));
            setShowAttendanceButton(AppConstants.trueText);
            setRefreshFlag(AppConstants.falseText);
        } else {
            setShowWarningMessage(AppConstants.trueText);
            setShowAttendanceButton(AppConstants.falseText);
            setLearnerDetails([]);
            setStatusExpiredLearnersDetails([]);
            setSubscriptionReminderSpinner([]);
        }
    };

    const markAttendanceForId = (learnerId, attendance, status) => {
        if (!selectedDate) {
            return null;
        }

        if (status === AppConstants.subscriptionStatus.active) {
            setLearnerDetails(learnerDetails.map((learner) => {
                if (learner.learnerId === learnerId) {
                    learner.attendance = learner.attendance === attendance ? null : attendance
                }
                return learner;
            }));
        } else if (status === AppConstants.subscriptionStatus.expired) {
            setStatusExpiredLearnersDetails(statusExpiredLearnersDetails.map((learner) => {
                if (learner.learnerId === learnerId) {
                    learner.attendance = learner.attendance === attendance ? null : attendance
                }
                return learner;
            }));
        }
    };

    const sendSubscriptionReminder = async (learnerEmail, index) => {
        try {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            const url = `${AppConstants.sendReminderEmailToRenewFlexiCourseSubscriptionAPI}${selectedCourse.uniqueId}&learnerEmail=${encodeURIComponent(learnerEmail)}`;
            const response = await AuthorizedDataService.post(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setSubscriptionAlertVarient(AppConstants.alertVarient[0]);
                    setSubscriptionReminderEmailMessage(`${SuccessMessage.courseSubscriptionReminderSentSuccessfully} ${learnerEmail}`);
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else {
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                    setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                let timer = setTimeout(() => {
                    setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
            setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
            let timer = setTimeout(() => {
                setSubscriptionReminderEmailMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const saveAttendanceDetails = async () => {
        if (!selectedDate) {
            return null;
        }
        setSaveAttendanceSpinner(AppConstants.trueText);
        const convertedDate = DateTimeUtil.createDateTimeObject(selectedDate, "23:59:59").toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`)
        let url = AppConstants.markAttendanceAPI;
        let attendanceDetails = [...learnerDetails, ...statusExpiredLearnersDetails];

        const data = {
            "courseUniqueId": courseUniqueId,
            "stringClassDate": convertedDate,
            "locale": timeZone,
            "attendanceDetails": attendanceDetails.map(participant => ({
                attendance: participant.attendance,
                learnerEmail: participant.learnerEmail,
                learnerId: participant.learnerId,
                previousRemainingClasses: participant.previousRemainingClasses
            }))
        };
        const response = await AuthorizedDataService.postRequest(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setSaveAttendanceSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
            setSaveAttendanceMessage(SuccessMessage.attendanceMarkedSuccessfullyMessage);
            setRefreshFlag(AppConstants.trueText);
            setModalTypeError(AppConstants.falseText);
            fetchAttendanceStatusOnDate(selectedDate);
        } else {
            setSaveAttendanceSpinner(AppConstants.falseText);
            setSaveAttendanceMessage(ErrorMessages.studentAttendanceUpdateError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
        }
    };

    const onCloseModal = () => {
        setShowModal(AppConstants.falseText);
        setSaveAttendanceMessage(AppConstants.emptyString);
        setModalTypeError(AppConstants.emptyString);
    };

    const getActiveColumnDefs = () => {
        if (selectedCourse.courseType === AppConstants.structuredCourseType) {
            return [...commonColumnDefs, ...structuredAttendanceColumnDefs];
        } else if (selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText) {
            if (selectedCourse.validity > 0) {
                return [...commonColumnDefs, ...learnerColumnDefsWithEndDate];
            } else {
                return [...commonColumnDefs, ...learnerColumnDefsWithoutEndDate];
            }
        } else if (selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText) {
            return [...commonColumnDefs, ...durationAttendanceActiveColumnDefs];
        } else {
            return commonColumnDefs;
        }
    };

    const getExpiredColumnDefs = () => {
        if (selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText) {
            if (selectedCourse.validity > 0) {
                return [...commonColumnDefs, ...learnerExpiredColumnsDefsWithEndDate];
            } else {
                return [...commonColumnDefs, ...learnerExpiredColumnsDefsWithoutEndDate];
            }

        } else if (selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText) {
            return [...commonColumnDefs, ...durationAttendanceExpiredColumnDefs];
        } else {
            return commonColumnDefs;
        }
    }

    return (
        <>
            <div className={`${classes.summarySection} mb-2`}>
                <div className={classes.thumbnailSection}>
                    <div className={classes.thumbnailContainer}>
                        <img className={classes.thumbnail} src={selectedCourse.courseThumbnailImageAbsolutePath !== null ? selectedCourse.courseThumbnailImageAbsolutePath : defaultCourseThumbnail} alt="Course Thumbnail"></img>
                    </div>
                    <div className={classes.thumbnailDetailsSection}>
                        <div className={classes.courseTitle}>{selectedCourse.courseTitle}</div>
                        {selectedCourse.courseType === AppConstants.structuredCourseType && <div className={classes.courseDescription}>
                            {selectedCourse.startTime.split(",")[1]}&nbsp;-&nbsp;{selectedCourse.endTime.split(",")[1]} {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</div>}
                        {((selectedCourse.weekDays?.length !== 0) || (selectedCourse.startTime != null) || (selectedCourse.endTime != null)) && <div className={`${classes.courseDescription}`}>
                            <div className={classes.courseTimings}>{selectedCourse.startTime?.split(",")[0]} - {selectedCourse.endTime?.split(",")[0]} {timeZone === AppConstants.utcTimezone && selectedCourse.courseType === AppConstants.flexibleCourseType && `(${selectedCourse.preferredTimezone})`}</div>
                            {(selectedCourse.weekDays && selectedCourse.weekDays?.length !== 0) && <ViewSelectedWeekDays selectedValues={selectedCourse.weekDays} />}
                        </div>}
                        <div className={classes.courseDescription}>{MathUtil.formatNumberToLocaleString(selectedCourse.participantsDetails.length)} Enrolments</div>
                    </div>
                </div>
                {showAttendanceButton && <div className={classes.attendanceSummary}>
                    <h6 className={classes.attendanceSummaryHeading}>Attendance summary</h6>
                    <li className={classes.summaryDescription}>Total Present: {MathUtil.formatNumberToLocaleString([...learnerDetails, ...statusExpiredLearnersDetails].filter(learnerAttendance => learnerAttendance.attendance === attendanceStatus.present).length)}</li>
                    <li className={classes.summaryDescription}>Total Absent: {MathUtil.formatNumberToLocaleString([...learnerDetails, ...statusExpiredLearnersDetails].filter(learnerAttendance => learnerAttendance.attendance === attendanceStatus.absent).length)}</li>
                    <li className={classes.summaryDescription}>Not Marked: {MathUtil.formatNumberToLocaleString(learnerDetails.filter(learnerAttendance => learnerAttendance.attendance === AppConstants.nullText).length)}</li>
                </div>}
            </div>
            {(selectedCourse.participantsDetails.length > 0) && <Row className={`mb-4`}>
                <div className={classes.dateAttendanceSummarySection}>
                    <div className={classes.dateContainer}>
                        <div className={`${classes.dateField} ${formClass.dateTimeInput}`}>
                            <Form.Label className={formClass.formLabel}>Select Date</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name="courseDate"
                                type="date"
                                max={new Date().toISOString().split('T')[0]}
                                value={selectedDate}
                                onChange={changeSelectedDate}
                            />
                        </div>
                        <div>
                            <Button disabled={!selectedDate} className={classes.selectButton} onClick={() => fetchAttendanceStatusOnDate(selectedDate)}>Select</Button>
                        </div>
                    </div>
                </div>
            </Row>}
            {(learnerDetails.length === 0 && dateError.length === 0 && statusExpiredLearnersDetails.length === 0 && showWarningMessage) && <Row className={`mb-4 ${classes.warningSection}`}>
                <div className={classes.noLearnersWarningMessage}><p><AiOutlineExclamationCircle /> {selectedCourse.participantsDetails.length === 0 ? "There are no enrolled learners in this course." : "There are no enrolments on the selected date. Attendance cannot be marked"}</p></div>
            </Row>}

            {dateError && <ErrorSuccessAlertMessage message={dateError} varient={AppConstants.alertVarient[1]} />}

            {learnerDetails.length > 0 && dateError === AppConstants.emptyString && <Row className="mt-4 mb-2">
                <div className={classes.markButtonRow}>
                    <h1 className={classes.learnerDetailsHeading}>{selectedCourse.courseType === AppConstants.structuredCourseType ? "Enrolled Learners" : "Active Enrolments"}</h1>
                    {showAttendanceButton && <Row>
                        <div className={classes.markAllButtonSection}>
                            <div onClick={() => markAttendanceForAll(attendanceStatus.absent)} className={`${classes.markAllButton} ${classes.absentButton}`}><RxCross1 /> All absent</div>
                            <div onClick={() => markAttendanceForAll(attendanceStatus.present)} className={`${classes.markAllButton} ${classes.presentButton}`}><TiTick /> All present</div>
                            <Button disabled={saveAttendanceSpinner || !selectedDate} onClick={saveAttendanceDetails} className={classes.saveAttendaceButton}>Save {saveAttendanceSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </div>
                    </Row>}
                </div>
            </Row>}

            {learnerDetails.length > 0 && dateError === AppConstants.emptyString && <div className={classes.showGridSection}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={getActiveColumnDefs()}
                                rowData={learnerDetails}
                                pagination={true}
                                paginationPageSize={20}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>}
            {learnerDetails.length > 0 && dateError === AppConstants.emptyString && <div className={classes.showCardsSection}>
                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                    {learnerDetails.map((participant, index) => {
                        return (
                            <Col>
                                <div className={classes.cardContainer}>
                                    <div className={classes.profileImageSection}>
                                        {participant.leanerImagePath ? <div className={classes.nameInitials}><img src={participant.leanerImagePath} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.nameInitials}>{participant.learnerNameInitials}</div>}
                                        <div>
                                            <p className={classes.learnerName}>{participant.learnerName}</p>
                                            <p className={classes.emailText}>QID: {participant.learnerId} | {participant.mobileNumber}</p>
                                        </div>
                                    </div>
                                    {participant.subscriptionStatus === subscriptionStatus.active && selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && <p className={classes.classesLeftText}><span className={`${classes.statusSpan} ${classes.activeSpan}`}>{participant.previousRemainingClasses} Classes Remaining (as of today)</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <p className={classes.dateInfoText}><span className={`${classes.dateSpan} `}><span className={classes.startText}>Start:</span>&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionStartDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionStartDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <p className={classes.dateInfoText}><span className={`${classes.dateSpan}`}><span className={classes.startText}>End:</span>&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && participant.subscriptionEndDate && <p className={classes.dateInfoText}><span className={`${classes.dateSpan}`}><span className={classes.startText}>Valid Till:</span>&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {showAttendanceButton && <div className={classes.cardButtonSection}>
                                        {(participant.subscriptionStatus === subscriptionStatus.active || participant.attendance !== AppConstants.nullText || participant.wasAttendanceMarked || selectedCourse.courseType === AppConstants.structuredCourseType) && <span onClick={() => markAttendanceForId(participant.learnerId, attendanceStatus.present, AppConstants.subscriptionStatus.active)} className={`${classes.cardActionButton} ${participant.attendance === attendanceStatus.present && classes.presentButton}`} ><TiTick />Present</span>}
                                        {(participant.subscriptionStatus === subscriptionStatus.active || participant.attendance !== AppConstants.nullText || participant.wasAttendanceMarked || selectedCourse.courseType === AppConstants.structuredCourseType) && <span onClick={() => markAttendanceForId(participant.learnerId, attendanceStatus.absent, AppConstants.subscriptionStatus.active)} className={`${classes.cardActionButton} ${participant.attendance === attendanceStatus.absent && classes.absentButton}`}><RxCross1 /> Absent</span>}
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>}

            {statusExpiredLearnersDetails.length > 0 && dateError === AppConstants.emptyString && <Row className="mt-4 mb-2">
                <div className={classes.markButtonRow}>
                    <h1 className={classes.learnerDetailsHeading}>Expired Enrolments</h1>
                    {showAttendanceButton && learnerDetails.length === 0 && <Row>
                        <div className={classes.markAllButtonSection}>
                            <div onClick={() => markAttendanceForAll(attendanceStatus.absent)} className={`${classes.markAllButton} ${classes.absentButton} ${statusExpiredLearnersDetails.filter(learner => learner.attendance === AppConstants.nullText).length === statusExpiredLearnersDetails.length && classes.disabledMarkButton}`}><RxCross1 /> All absent</div>
                            <div onClick={() => markAttendanceForAll(attendanceStatus.present)} className={`${classes.markAllButton} ${classes.presentButton} ${statusExpiredLearnersDetails.filter(learner => learner.attendance === AppConstants.nullText).length === statusExpiredLearnersDetails.length && classes.disabledMarkButton}`}><TiTick /> All present</div>
                            <Button disabled={saveAttendanceSpinner || !selectedDate} onClick={saveAttendanceDetails} className={classes.saveAttendaceButton}>Save {saveAttendanceSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </div>
                    </Row>}
                </div>
            </Row>}
            {statusExpiredLearnersDetails.length > 0 && selectedCourse.courseType === AppConstants.flexibleCourseType && dateError === AppConstants.emptyString && <div className={classes.showGridSection}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={getExpiredColumnDefs()}
                                rowData={statusExpiredLearnersDetails}
                                pagination={true}
                                paginationPageSize={20}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </div>}
            {statusExpiredLearnersDetails.length > 0 && selectedCourse.courseType === AppConstants.flexibleCourseType && dateError === AppConstants.emptyString && <div className={classes.showCardsSection}>
                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                    {statusExpiredLearnersDetails.map((participant, index) => {
                        return (
                            <Col>
                                <div className={classes.cardContainer}>
                                    <div className={classes.profileImageSection}>
                                        {participant.leanerImagePath ? <div className={classes.nameInitials}><img src={participant.leanerImagePath} className={classes.profilePicture} alt="user_profile" /></div> : <div className={classes.nameInitials}>{participant.learnerNameInitials}</div>}
                                        <div>
                                            <p className={classes.learnerName}>{participant.learnerName}</p>
                                            <p className={classes.emailText}>QID: {participant.learnerId} | {participant.mobileNumber}</p>
                                        </div>
                                    </div>
                                    {participant.subscriptionStatus === subscriptionStatus.expired && selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && <p className={classes.classesLeftText}><span className={`${classes.statusSpan} ${classes.expiredSpan}`}>{participant.previousRemainingClasses} Classes Remaining (as of today)</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && <p className={classes.lastSubscribedText}><span className={classes.dateSpan}> Last Subscribed On: {moment.utc(participant.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} {timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <p className={classes.lastSubscribedText}><span className={`${classes.dateSpan} `}>Start:&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionStartDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionStartDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <p className={classes.lastSubscribedText}><span className={`${classes.dateSpan}`}>End:&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && participant.subscriptionEndDate && <p className={classes.lastSubscribedText}><span className={`${classes.dateSpan}`}>Valid Till:&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participant.subscriptionEndDate.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span></p>}
                                    {showAttendanceButton && <div className={classes.cardButtonSection}>
                                        <span onClick={() => markAttendanceForId(participant.learnerId, attendanceStatus.present, AppConstants.subscriptionStatus.expired)} className={`${classes.cardActionButton} ${participant.attendance === attendanceStatus.present && classes.presentButton}`} ><TiTick />Present</span>
                                        <span onClick={() => markAttendanceForId(participant.learnerId, attendanceStatus.absent, AppConstants.subscriptionStatus.expired)} className={`${classes.cardActionButton} ${participant.attendance === attendanceStatus.absent && classes.absentButton}`}><RxCross1 /> Absent</span>
                                        {(participant.subscriptionStatus === subscriptionStatus.expired && selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.disableEnrolmentFlag === AppConstants.nText) && <Button disabled={subscriptionReminderSpinner[index]} onClick={() => sendSubscriptionReminder(participant.learnerEmail, index)} className={`${classes.reminderButtonCard}`}>Reminder {subscriptionReminderSpinner[index] && <BiLoaderAlt className="spinner" />}</Button>}
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div >}
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {saveAttendanceMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            {subscriptionReminderEmailMessage && <ErrorSuccessAlertMessage message={subscriptionReminderEmailMessage} varient={subscriptionAlertVarient} />}
        </>
    )
}

