import React from 'react';
import { Navigate } from 'react-router-dom';
import Auth from '../utils/Auth';
import { AppConstants } from '../constants/AppConstants';

const PublicRoutes = ({ restricted, children }) => {

    // restricted = false meaning public route
    // restricted = true meaning restricted route
    if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleExpert) {
        return <Navigate to={AppConstants.expertHomePath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleAdmin) {
        return <Navigate to={AppConstants.adminNumberTrackingPath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleStudent) {
        return <Navigate to={AppConstants.studentHomePath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleAgency) {
        return <Navigate to={AppConstants.agencyHomePath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleSales) {
        return <Navigate to={AppConstants.salesNumberTrackingPath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleMarketing) {
        return <Navigate to={AppConstants.marketingBulkSignupPath} />
    }
    else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
        return <Navigate to={AppConstants.partnerSuccessNumberTracking} />
    } else if (Auth.isLogin() && restricted && Auth.getUserRole() === AppConstants.userRoleTeam) {
        return <Navigate to={AppConstants.teamHomePath} />
    }
    else {
        return children
    }
};

export default PublicRoutes;