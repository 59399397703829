import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import classes from './QuizListingPageCards.module.css';
import { Tooltip } from 'react-tooltip';
import { AppConstants } from "../../../constants/AppConstants";
import { MdAccessTime } from "react-icons/md";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { TbShare3 } from "react-icons/tb";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { BiLoaderAlt } from "react-icons/bi";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";

export const QuizListingPageCards = (props) => {
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.expertsPageCardsCount);
    const cardDisplayedCount = AppConstants.cardCounts.expertsPageCardsCount;
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.quizThumbnailImage}`;
    const handleCardClick = (quiz) => {
        window.open(`${process.env.REACT_APP_FRONT_END_URL}events/quiz/${quiz.slug}`, AppConstants.newPageTargetSelf);
    };
    const [editIndex, setEditIndex] = useState(null);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);

    const getQuizDurationText = (quiz) => {
        let secs = quiz.quizDuartion / 1000;

        let minutes = Math.floor(secs / (60));

        let divisor_for_seconds = secs % (60);
        let seconds = Math.ceil(divisor_for_seconds);

        return seconds ? `${minutes}m ${seconds}s` : `${minutes}m`
    }

    const getQuizType = (quiz) => {
        return quiz.type.toUpperCase() === AppConstants.quizType.mcq.toUpperCase() ? quiz.type.toUpperCase() : "Q&A";
    }

    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}events/quiz/${shareData.slug}`);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + cardDisplayedCount);
    }, [props.quizList]);

    return (
        <>
            <Row xs={1} sm={2} md={3} lg={4} xl={4} className={classes.cardsSection}>
                {props.quizList?.slice(0, cardCount).map((quiz, index) => (
                    <Col key={quiz.slug} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card className={classes.quizCard} onClick={() => handleCardClick(quiz)}>
                            <Card.Img src={quiz.thumbnailImage || defaultThumbnail} className={classes.thumbnailImage} />
                            <TbShare3 className={classes.shareIcon} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(quiz) }} />
                            <Card.Body className={classes.quizCardBody}>
                                <div className="subTitleContainer">
                                    <div className={`cardSubTitle ${classes.typeSubtitle}`}><span className={`onlineIconWorkshop  ${classes.typeSubtitle}`}>{getQuizType(quiz)}</span> {quiz.quizOfTheDayFlag === AppConstants.yText && <> <span className="seperator">{AppConstants.verticalBar}</span> <span className={`onlineIconWorkshop  ${classes.typeSubtitle} ${classes.flashingText}`}>Quiz of the day</span></>}</div>
                                </div>
                                {editIndex === index && showSocialMediaPannel && <div className={classes.shareIconPannelContainer} onClick={(e) => { stopPropagation(e); }}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <h1 className={classes.quizTitle}>
                                    <Link className={`${classes.linkedText}  ${classes.singleLine}`} data-tooltip-id={`${quiz.slug}-title`}>{quiz.title}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${quiz.slug}-title`}><span>{quiz.title}</span></Tooltip>
                                </h1>
                                <div className="cardTimeAndDate">
                                    <div><MdAccessTime className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">&nbsp;{quiz.numberOfQuestions} {quiz.numberOfQuestions > 1 ? "Questions" : "Question"} <span className="durationSeparator">|</span> {getQuizDurationText(quiz)} </span>
                                    </div>
                                </div>
                                <div className={`buttonSection`}>
                                    <div className={`cardTimeAndDate ${classes.entriesSection}`}>
                                        <div><MdOutlinePeopleAlt className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            <span className="competitionDateTimeFormat">&nbsp;{quiz.quizParticipationCount} Entries</span>
                                        </div>
                                    </div>
                                    <Button type="button" className={classes.startButton}>Play</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {loading && cardCount < props.quizList?.length && (
                <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                </Row>
            )}
        </>
    );
}