import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import Moment from "react-moment";
import { DateTime } from "luxon";
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import 'moment-timezone';
import '../CourseCards.css';
import classes from "./StudentWorkshopCards.module.css"
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";

export const StudentWorkshopCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [workshopCards, setWorkshopCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [payloadData, setPayloadData] = useState({});
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const frontendURL = process.env.REACT_APP_FRONT_END_URL;
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(false);
    useWarnOnLeave(shouldWarn)
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.bannerImagesS3Path}${AppConstants.forwardSlash}${AppConstants.workshopDefaultThumbnailImage}`;

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
        setTransactionId(AppConstants.emptyString);
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (paymentStatus !== AppConstants.emptyString) {
            window.location.reload();
        }
    }

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        setWorkshopCards(props.workshopData);
    }, [props.workshopData]);

    // verify method for phonepe payments
    useEffect(() => {
        const verifyPayment = async (data, workshop) => {
            try {
                if (workshop && workshop?.workshopUniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setModalTypeError(AppConstants.falseText);
                            setTransactionId(response.transactionId);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            enrolInWorkshop(workshop.workshopId, workshop.workshopTitle, workshop.workshopUniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            setShowModal(AppConstants.trueText);
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        setShowModal(AppConstants.trueText);
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return AppConstants.nullText;
            }
        };

        if (paymentParam && workshopCards.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== AppConstants.nullText && data.category === props.category) {
                const workshop = workshopCards.filter(card => card.workshopUniqueId === data.uniqueId)[0];
                if (!workshop) {
                    setPayloadData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                setConfirmationPopupActivityDetails({ id: workshop.workshopId, title: workshop.workshopTitle, uniqueId: workshop.workshopUniqueId, startTime: workshop.workshopUtcStartDateString, payOfflineFlag: workshop.payOfflineFlag, creatorName: workshop.organizedBy, creatorRole: workshop.userRole, type: AppConstants.learningActivitiesTypes.workshop });
                data.learningActivityName = workshop.workshopTitle;
                setPayloadData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, workshop);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentParam, workshopCards]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole, cost }) => {
        setTransactionId(AppConstants.emptyString);
        const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
        const currentDateTime = DateTime.local().setZone(timeZone);
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.workshop, payOfflineFlag, creatorName, creatorRole, startTime, cost });

        // check if the learning activity has started.
        if (currentDateTime > startDateTime) {
            // show confirmation modal to the user.
            setShowConfirmationModal(AppConstants.trueText);
        } else {
            if (payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInWorkshop(id, title, uniqueId);
            }
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInWorkshop = async (id, title, uniqueId, status) => {
        const workshop = workshopCards.filter(card => card.workshopUniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzPay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setTransactionId(response.razorpay_payment_id);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInWorkshop(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzPay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzPay.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {workshopCards !== undefined && workshopCards.slice(AppConstants.zeroIndex, cardCount).map((workshop, index) => (
                    <Col key={workshop.workshopUniqueId}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${workshop.skillCategorySlug}${AppConstants.forwardSlash}${workshop.skillSlug}${AppConstants.forwardSlash}${workshop.workshopSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={workshop.workshopThumbnailImagePath ? workshop.workshopThumbnailImagePath : defaultWorkshopThumbnail} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className={`cardSubTitle workshopDiffColor`}>{workshop.workshopModeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={workshop.workshopUniqueId}><a className="workshopLink onlineIconWorkshop" href={workshop.workshopGeoLocation} target={AppConstants.openInNewTab}><CgPin className="geoLocationIcon onlineIconWorkshop" /> {workshop.workshopModeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconWorkshop modeSubtitle">{workshop.workshopModeOfDelivery.toUpperCase()}</span>} </div>
                                    <Tooltip className="tooltipMessage" id={workshop.workshopUniqueId}><span className="tooltipMessage">{workshop.workshopAddress}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${workshop.workshopUniqueId}-title`}>{workshop.workshopTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${workshop.workshopUniqueId}-title`}><span>{workshop.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(workshop.userRole === AppConstants.userRoleExpert ? `${frontendURL}${AppConstants.expert}${AppConstants.forwardSlash}${workshop.userSlug}` : `${frontendURL}${AppConstants.agency}${AppConstants.forwardSlash}${workshop.userSlug}`) }} className="cardTimeAndDate">By : {workshop.organizedBy}</Card.Text>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.workshopUtcStartDateString.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.workshopUtcStartDateString.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{workshop.workshopUtcEndDateString.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshop.workshopUtcEndDateString.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 })}</div>
                                    <Button type="button" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: workshop.workshopId, title: workshop.workshopTitle, uniqueId: workshop.workshopUniqueId, startTime: workshop.workshopUtcStartDateString, payOfflineFlag: workshop.payOfflineFlag, creatorName: workshop.organizedBy, creatorRole: workshop.userRole }) }} className={classes.sessionButton}>Enrol Now</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className={classes.loadMoreDiv}>
                    {cardCount < workshopCards?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString || payloadData.category === props.category) && (showModal || payloadData.category === props.category) && <EnrolmentModal onShow={() => setShowModal(AppConstants.trueText)} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}