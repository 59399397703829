import { useState } from "react";
import classes from "./ExpertBulkEnrolmentSheet.module.css";
import { Button, Modal } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { HiOutlineDownload } from 'react-icons/hi';
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import modalClass from "../cards/CompetitionsCards.module.css";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { FileUploadInput } from "../fileUploadInput/FileUploadInput";

export const ExpertBulkEnrolmentSheet = ({ selectedCompetition }) => {
    const [templateSpinner, setTemplateSpinner] = useState(AppConstants.falseText);
    const [downloadTemplateError, setDownloadTemplateError] = useState(AppConstants.emptyString);
    const [participantSheet, setParticipantSheet] = useState(AppConstants.emptyString);
    const [uploadSheetSpinner, setUploadSheetSpinner] = useState(AppConstants.falseText);
    const [enrolStudentEmails, setEnrolStudentEmails] = useState([]);
    const [totalCostPayble, setTotalCostPayble] = useState(AppConstants.emptyString);
    const [fileError, setFileError] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);

    const onCloseModal = () => {
        setShowModal(false);
    };

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setParticipantSheet(AppConstants.emptyString);
    }

    const downloadTemplate = async () => {
        try {
            setTemplateSpinner(true);
            const url = `${AppConstants.downloadBulkEnrolmentSheetAPI}${selectedCompetition.competitionId}`;
            const response = await FileOperationsUtil.downloadFile(
                url,
                AppConstants.bulkEnrolmentSheetName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok) {
                    setTemplateSpinner(AppConstants.falseText);
                } else {
                    setTemplateSpinner(AppConstants.falseText);
                    setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
                    let timer = setTimeout(() => {
                        setDownloadTemplateError(AppConstants.falseText);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setTemplateSpinner(AppConstants.falseText);
                setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
                let timer = setTimeout(() => {
                    setDownloadTemplateError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setTemplateSpinner(AppConstants.falseText);
            setDownloadTemplateError(ErrorMessages.bulkEnrolSheetDownloadError);
            let timer = setTimeout(() => {
                setDownloadTemplateError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const uploadSheet = async () => {
        try {
            if (AppConstants.excelSheetType !== participantSheet.type) {
                setFileError(ErrorMessages.bulkEnrolSheetFileTypeError);
                let timer = setTimeout(() => {
                    setFileError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else {
                setUploadSheetSpinner(AppConstants.trueText);
                let formData = new FormData();

                formData.append("bulkEnrolmentSheet", participantSheet);
                formData.append("uniqueCompetitionId", selectedCompetition.uniqueId);
                formData.append("competitionName", selectedCompetition.title);
                formData.append("competitionId", selectedCompetition.competitionId);

                let url = AppConstants.uploadParticipantSheetAPI;
                let response = await AuthorizedDataService.postMultipartRequest(url,
                    formData,
                    AppConstants.emptyString,
                    AppConstants.emptyString);

                if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                    const body = await response.json();
                    setEnrolStudentEmails(body.emails);
                    setTotalCostPayble(response.totalCostPayble);
                    enrolStudents(body.emails);
                } else if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                    removeFile("participantsSheetInput");
                    setUploadSheetSpinner(AppConstants.falseText);
                    setModalMessage(SuccessMessage.bulkSheetEnrolStudentsSuccess);
                    setModalTypeError(AppConstants.falseText);
                    setShowModal(true);
                } else {
                    const body = await response.json();
                    removeFile("participantsSheetInput");
                    setUploadSheetSpinner(AppConstants.falseText);
                    setEnrolStudentEmails(null);
                    setModalMessage(body.message)
                    setTotalCostPayble(body.totalCostPayble);
                    setModalTypeError(true);
                    setShowModal(true);
                }
            }
        } catch (error) {
            removeFile("participantsSheetInput");
            setUploadSheetSpinner(AppConstants.falseText);
            setEnrolStudentEmails(null);
            setModalMessage(ErrorMessages.bulkEnrolUploadSheetError);
            setTotalCostPayble(null);
            setModalTypeError(true);
            setShowModal(true);
        }
    }

    const enrolStudents = async (emailList) => {
        try {
            let url = AppConstants.bulkEnrolStudentsAPI;
            let data = {
                emails: emailList,
                uniqueCompetitionId: selectedCompetition.uniqueId,
                competitionName: selectedCompetition.title
            }

            let response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok && response.status === 200) {
                removeFile("participantsSheetInput");
                setUploadSheetSpinner(AppConstants.falseText);
                setModalMessage(SuccessMessage.bulkSheetEnrolStudentsSuccess);
                setModalTypeError(AppConstants.falseText);
                setShowModal(true);
            } else {
                removeFile("participantsSheetInput");
                setUploadSheetSpinner(AppConstants.falseText);
                setModalMessage(ErrorMessages.bulkEnrolParticipantSheetError);
                setModalTypeError(AppConstants.trueText);
                setShowModal(false);
            }
        } catch (error) {
            removeFile("participantsSheetInput");
            setUploadSheetSpinner(AppConstants.falseText);
            setModalMessage(ErrorMessages.bulkEnrolParticipantSheetError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(false);
        }
    }

    return (
        <div className={classes.bulkEnrolmentSheetContainer}>
            <Button className={classes.templateButton} onClick={downloadTemplate}>Template {templateSpinner ? <BiLoaderAlt className="spinner" /> : <HiOutlineDownload />} </Button>
            <div className={classes.uploadFileSection}>
                <div>
                    <FileUploadInput id="participantsSheetInput" name="participantsSheetInput" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Participants sheet" handleFileChange={event => setParticipantSheet(event.target.files[AppConstants.zeroIndex])} inputFile={participantSheet} />
                    {participantSheet && participantSheet.size > 0 && <p onClick={() => { removeFile("participantsSheetInput") }} className='removeFileText'>Remove file</p>}
                </div>
                <Button onClick={uploadSheet} disabled={uploadSheetSpinner || !participantSheet?.size} className={classes.buttons}>Upload {uploadSheetSpinner && <BiLoaderAlt className="spinner" />}</Button>
            </div>


            {fileError !== AppConstants.emptyString && <ErrorSuccessAlertMessage message={fileError} varient={AppConstants.alertVarient[1]} />}
            {downloadTemplateError.length > 0 && <ErrorSuccessAlertMessage message={downloadTemplateError} varient={AppConstants.alertVarient[1]} />}

            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        <p><b>{selectedCompetition.competitionId} | {selectedCompetition.title}</b></p>
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}