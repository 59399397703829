import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AppConstants } from "../../constants/AppConstants";
import '../../common/TemplateStyle.css';
import { DateTime } from 'luxon';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
export const ExpertFooter = (props) => {
    const [footerDetails, setFooterDetails] = useState(props.footerDetails.footer);
    const year = DateTime.now().setZone(DateTimeUtil.getPreferredTimeZoneForUser())?.toFormat("yyyy");
    return (
        <>
            {footerDetails.active === AppConstants.trueText && <div className="footerSection">
                <div className="footerSocialMediaLinkSection">
                    <div className="footerLowerSections">© {year} {footerDetails.copyRightText}</div>
                    <div className="footerTermsPrivacyLowerSections"><NavLink className="footerTermsPrivacyLink" to={AppConstants.termsAndConditions}>{footerDetails.termsAndConditionsText}</NavLink> | <NavLink className="footerTermsPrivacyLink" to={AppConstants.privacyPolicy}>{footerDetails.privacyPolicyText}</NavLink> | <NavLink className="footerTermsPrivacyLink" to={AppConstants.refundPolicyPage}>{footerDetails.refundPolicyText}</NavLink></div>
                    <div className="footerIconsLowerSections">
                        {footerDetails.socialMediaLinks.filter((data) => data.active === AppConstants.trueText).map((socialLink) => (
                            <div key={socialLink.id} className="socialLinkIconSection">
                                <Link to={socialLink.link} target="_blank">
                                    <img
                                        className="socialLinkIcons"
                                        src={socialLink.imagePath}
                                        alt="Passion List Image"
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            }
        </>
    )
}