import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { useNavigate } from "react-router-dom";
import { BsFillCloudArrowDownFill } from 'react-icons/bs';
import { BiLoaderAlt, BiLinkExternal } from 'react-icons/bi';
import Table from 'react-bootstrap/Table';
import markParticipantsStyles from "./ExpertMarkParticipantsScreen.module.css";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { Button } from 'react-bootstrap';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa'
import Moment from 'react-moment';
import 'moment-timezone';
import { MathUtil } from '../../utils/MathUtil';
import { GymnasticsMarkParticipantScreen } from './GymnasticsMarkParticipantScreen';
import { FormValidationUtils } from '../../utils/FormValidationUtils';
import { StringUtils } from '../../utils/StringUtils';

export const ExpertMarkParticipantsScreen = () => {
    const { competitionSlug, judgeUniqueId, participantQrenciaId } = useParams();
    const [pageLoader, setPageLoader] = useState(true);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const [judgingEnabledFlag, setJudgingEnabledFlag] = useState();
    const [participantsDetails, setParticipantsDetails] = useState();
    const [competitionDetails, setCompetitionDetails] = useState();
    const [judgeDetails, setJudgeDetails] = useState();
    const [competitionAspectDetails, setCompetitionAspectDetails] = useState([]);
    const [allotedMarksArray, setAllotedMarksArray] = useState([]);
    const [judgingSheetSpinner, setJudgingSheetSpinner] = useState(false);
    const [judgingPageLoader, setJudgingPageLoader] = useState(true);
    const [judgingSheetError, setJudgingSheetError] = useState(false);
    const [judgingSheetErrorVarient, setJudgingSheetErrorVarient] = useState(AppConstants.emptyString);
    const [judgingSheetErrorMessage, setJudgingSheetErrorMessage] = useState(AppConstants.emptyString);
    const [submitMarksSpinner, setSubmitMarksSpinner] = useState(false);
    const [submitMarksError, setSubmitMarksError] = useState(false);
    const [submitMarksErrorMessage, setSubmitMarksErrorMessage] = useState(AppConstants.emptyString);
    const [submitMarksErrorVarient, setSubmitMarksErrorVarient] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [teamMembers, setTeamMembers] = useState([]);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        async function getCompetitionDetails() {
            setPageLoader(true);
            const url = `${AppConstants.markParticipantsPageGetCompetitionDetailsAPI}${competitionSlug}${AppConstants.markParticipantsPageGetCompetitionDetailsAPIPart2}${timeZone}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setCompetitionDetails(response);
                setPageLoader(false);
            }
            else {
                setCompetitionDetails({});
                setPageLoader(false);
                navigate(errorPageUrl);
            }
        };
        getCompetitionDetails();
    }, []);

    useEffect(() => {

        if (competitionDetails != undefined) {
            async function getJudgingEnabledFlag() {
                setJudgingPageLoader(true);
                const url = `${AppConstants.getJudgingEnabledFlagAPI}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setJudgingEnabledFlag(response);
                    setPageLoader(false);
                    if (response === false) {
                        navigate(errorPageUrl);
                    }
                }
                else {
                    setJudgingEnabledFlag(false);
                    setPageLoader(false);
                    navigate(errorPageUrl);
                }
            };
            getJudgingEnabledFlag();

            async function getParticipantsDetails() {
                setPageLoader(true);
                const params = {
                    competitionId: competitionDetails.competitionId,
                    judgeId: judgeUniqueId,
                    skillName: competitionDetails.skillName,
                    teamEnrolmentEnabledFlag: competitionDetails.teamEnrolmentEnabledFlag,
                    markingType: competitionDetails.markingType
                }
                const url = StringUtils.createURLWithParams(AppConstants.getParticipantsForJudgingScreenAPI, params);
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    if (competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText) {
                        setParticipantsDetails(response.filter((el) => { return el.uniqueId === participantQrenciaId }));
                    } else {
                        setParticipantsDetails(response.filter((el) => { return el.participantQrenciaId === participantQrenciaId }));
                    }
                    setPageLoader(false);
                }
                else {
                    setParticipantsDetails({});
                    setPageLoader(false);
                    navigate(errorPageUrl);
                }
            };


            getParticipantsDetails();

            async function getJudgeInformation() {
                const url = `${AppConstants.getJudgeDetailsAPI}${judgeUniqueId}${AppConstants.getJudgeDetailsAPISecondParam}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setJudgeDetails(response);
                    setPageLoader(false);
                }
                else {
                    setJudgeDetails({});
                    setPageLoader(false);
                    navigate(errorPageUrl);
                }
            };
            getJudgeInformation();

            async function getCompetitionAspect() {
                const params = {
                    competitionId: competitionDetails.competitionId,
                    judgeUniqueId,
                    participantQrenciaId: competitionDetails.teamEnrolmentEnabledFlag !== AppConstants.yText ? participantQrenciaId : AppConstants.nullText,
                    teamParticipantUniqueId: competitionDetails.markingType === AppConstants.markingTypes.individual.value ? participantQrenciaId : AppConstants.nullText,
                    teamUniqueId: competitionDetails.markingType === AppConstants.markingTypes.team.value ? participantQrenciaId : AppConstants.nullText,
                    skillName: competitionDetails.skillName,
                    competitionTeamEnrolmentFlag: competitionDetails.teamEnrolmentEnabledFlag,
                    competitionMarkingType: competitionDetails.markingType
                };

                const url = StringUtils.createURLWithParams(AppConstants.getAspectsForJudgingAPI, params);
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setCompetitionAspectDetails(response);
                    setPageLoader(false);
                    //set previous allotted marks
                    const fields = ['uniqueAspectId', 'marksAllotted']
                    const marksArray = response.map(i => Object.fromEntries(fields.map(f => [f, i[f]])));
                    setAllotedMarksArray(marksArray.map(({ uniqueAspectId: aspectId, ...rest }) => ({ aspectId, ...rest })));
                }
                else {
                    setCompetitionAspectDetails([]);
                    setPageLoader(false);
                    navigate(errorPageUrl);
                }
            };
            getCompetitionAspect();

            async function getParticipantNamesForTeamCompetition() {
                const params = {
                    competitionId: competitionDetails.competitionId,
                    teamUniqueId: participantQrenciaId
                }
                const url = StringUtils.createURLWithParams(AppConstants.getTeamParticipantsInCompetitionAPI, params);
                const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
                if (response) {
                    setTeamMembers(response);
                }
            };

            getParticipantNamesForTeamCompetition();
        }
    }, [competitionDetails]);

    //download judging sheet
    async function downloadJudgingSheet() {
        setJudgingSheetSpinner(true);
        let nonQrenciaUserName = competitionDetails.markingType === AppConstants.markingTypes.team.value ? participantsDetails[0]?.teamName : participantsDetails[0]?.participantName;
        const url = `${AppConstants.markParticipantsDownloadJudgingSheetAPI}${competitionDetails.competitionId}${AppConstants.markParticipantsDownloadJudgingSheetAPIJudgeParam}${judgeUniqueId}${AppConstants.markParticipantsDownloadJudgingSheetAPIStudentParam}${participantQrenciaId}&skillName=${competitionDetails.skillName}&teamEnrolmentEnabledFlag=${competitionDetails.teamEnrolmentEnabledFlag}&participantUniqueId=${participantQrenciaId}`;
        const name = `${competitionDetails.competitionTitle}${AppConstants.hyphen}${participantsDetails[0]?.participantQrenciaId || nonQrenciaUserName}${AppConstants.hyphen}${AppConstants.judgingSheetName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFileUnauthorized(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setJudgingSheetSpinner(false);
            } else {
                setJudgingSheetSpinner(false);
                setJudgingSheetErrorVarient(AppConstants.alertVarient[1]);
                setJudgingSheetError(true);
                setJudgingSheetErrorMessage(ErrorMessages.downloadJudgingSheetError);
                let timer = setTimeout(() => {
                    setJudgingSheetError(false);
                    setJudgingSheetErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setJudgingSheetSpinner(false);
            setJudgingSheetErrorVarient(AppConstants.alertVarient[1]);
            setJudgingSheetError(true);
            setJudgingSheetErrorMessage(ErrorMessages.downloadJudgingSheetError);
            let timer = setTimeout(() => {
                setJudgingSheetError(false);
                setJudgingSheetErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //handle marks input
    const handleInputMarks = (e, uniqueAspectId) => {
        const { value } = e.target;
        const temp = competitionAspectDetails;
        const aspectDetailsArray = temp.map((item, index) => item.uniqueAspectId === uniqueAspectId ? { ...item, marksAllotted: value } : item);
        setCompetitionAspectDetails((item) =>
            item?.map((item, index) =>
                item.uniqueAspectId === uniqueAspectId ? { ...item, marksAllotted: value } : item
            ),
        );
        const fields = ['uniqueAspectId', 'marksAllotted'];
        const marksArray = aspectDetailsArray.map(i => Object.fromEntries(fields.map(f => [f, i[f]])));
        setAllotedMarksArray(marksArray.map(({ uniqueAspectId: aspectId, ...rest }) => ({ aspectId, ...rest })));
    };

    async function submitParticipantsMarks(e) {
        e.preventDefault();
        setSubmitMarksSpinner(true);
        setSubmitMarksError(false);
        const url = AppConstants.markParticipantsPagePostAllotedMarksAPI;
        const data = {
            "competitionId": competitionDetails.competitionId,
            "judgeUniqueId": judgeUniqueId,
            "studentUniqueId": competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText ? AppConstants.nullText : participantQrenciaId,
            competitionTeamEnrolmentFlag: competitionDetails.teamEnrolmentEnabledFlag,
            competitionMarkingType: competitionDetails.markingType,
            teamParticipantUniqueId: competitionDetails.markingType === AppConstants.markingTypes.individual.value ? participantQrenciaId : AppConstants.nullText,
            teamUniqueId: competitionDetails.markingType === AppConstants.markingTypes.team.value ? participantQrenciaId : AppConstants.nullText,
            "marks": allotedMarksArray
        }
        const response = await DataService.post(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined && response.ok) {
            setSubmitMarksSpinner(false);
            setSubmitMarksErrorVarient(AppConstants.alertVarient[0]);
            setSubmitMarksError(true);
            setSubmitMarksErrorMessage(SuccessMessage.submitMarksSuccess);
            let timer = setTimeout(() => {
                setSubmitMarksError(false);
                setSubmitMarksErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        } else {
            setSubmitMarksSpinner(false);
            setSubmitMarksErrorVarient(AppConstants.alertVarient[1]);
            setSubmitMarksError(true);
            setSubmitMarksErrorMessage(ErrorMessages.submitMarksError);
            let timer = setTimeout(() => {
                setSubmitMarksError(false);
                setSubmitMarksErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }
    //Open submissions in new tab
    function openInNewWindow(e, file) {
        var submissionsWindowName = AppConstants.submissionWindowName + competitionSlug + AppConstants.submissionWindowNamePartTwo + participantQrenciaId;
        let startIndex = file.lastIndexOf(".");
        let endIndex = file.indexOf("?");
        let fileExtension = file.substring(startIndex, endIndex);
        if (fileExtension === '.ogg' || fileExtension === '.avi' || fileExtension === '.mp4' || fileExtension === '.mov' || fileExtension === '.webm') {

            var submissionsWindow = window.open("", AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            // Write the video tag to the new window.
            submissionsWindow.document.write('<video controls="controls" width="600" height="600" name="submission"><source src="' + file + '"></video>');

            // Play the video.
            submissionsWindow.document.querySelector('video').play();
        }
        else {
            var submissionsWindow = window.open(file, AppConstants.openInNewTab, "name=submissions,height=600,width=600");
        }
        submissionsWindow.name = submissionsWindowName;
        submissionsWindow.document.title = submissionsWindowName;
    }

    const viewAllParticipantList = () => {
        const url = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.judgeCompetitionsUriPart}${AppConstants.forwardSlash}${competitionSlug}${AppConstants.forwardSlash}judge${AppConstants.forwardSlash}${judgeUniqueId}`;
        window.location.href = url;
    }

    const validateKeyDown = (event) => {
        const regex = /^\d*\.?\d*$/;

        if ((!regex.test(event.key) && !AppConstants.keyboardUtilKeys.includes(event.key))) {
            event.preventDefault();
            return;
        }

        FormValidationUtils.preventNumberWithSpecialCharacters(event, ["-", "+"]);
    };

    return (
        <>
            {(pageLoader) &&
                <div className={markParticipantsStyles.errorContainer}>
                    <p className={markParticipantsStyles.loaderIcon}><BiLoaderAlt className={markParticipantsStyles.spinner} /></p>
                </div>}
            {(!pageLoader && competitionDetails !== null && competitionDetails !== undefined && judgeDetails !== null && judgeDetails !== undefined && participantsDetails !== null && participantsDetails !== undefined) && <div>
                <div className={markParticipantsStyles.thumbnailSection}>
                    <div className={markParticipantsStyles.thumbnailContainer}>
                        <img className={markParticipantsStyles.thumbnail} src={competitionDetails.thumbnailImage !== null ? competitionDetails.thumbnailImage : ""} alt="Competition Thumbnail"></img>
                    </div>
                    <div className={markParticipantsStyles.thumbnailDetailsSection}>
                        <div className={markParticipantsStyles.competitionTitle}>{competitionDetails.competitionTitle}</div>
                        <div className={markParticipantsStyles.competitionDescription}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionStartTime.replace(' ', 'T') + 'Z'}</Moment> <p className={markParticipantsStyles.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> <p className={markParticipantsStyles.hyphenText}>{AppConstants.hyphen}</p> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionEndTime.replace(' ', 'T') + 'Z'}</Moment> <p className={markParticipantsStyles.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetails.preferredTimezone})`} {competitionDetails.venueLocation != null && <span className={markParticipantsStyles.location}> ({competitionDetails.venueLocation})</span>}</div>
                        {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.nText && <div className={markParticipantsStyles.competitionDescription}>{MathUtil.formatNumberToLocaleString(competitionDetails?.enrolledCandidates)} {" "}{competitionDetails?.enrolledCandidates === 1 ? "Enrollement" : "Enrollements"}</div>}
                        {competitionDetails?.teamEnrolmentEnabledFlag === AppConstants.yText && (
                            <div className={markParticipantsStyles.competitionDescription}>
                                {MathUtil.formatNumberToLocaleString(competitionDetails?.teamCount)}{" "}
                                {competitionDetails?.teamCount === 1 ? "Team" : "Teams"}
                                {competitionDetails?.markingType === AppConstants.markingTypes.individual.value && (
                                    <>
                                        {" ("}{competitionDetails?.enrolledCandidates === 1 ? "Total Participant : " : "Total Participants : "}{MathUtil.formatNumberToLocaleString(competitionDetails?.enrolledCandidates)}{")"}
                                    </>
                                )}
                            </div>
                        )}
                        <div className={markParticipantsStyles.competitionDescription}><strong>Judge:</strong> {judgeDetails?.judgeName} ({judgeDetails?.judgeEmail})</div>
                    </div>
                </div>
                <div className={markParticipantsStyles.downloadJudingSheetContainer}>
                    <div className={markParticipantsStyles.participantsDetailsContainer}>
                        {competitionDetails.teamEnrolmentEnabledFlag !== AppConstants.yText && <p><strong>Participant :</strong> {`${participantsDetails[0]?.participantName} | QID: ${participantQrenciaId} ${participantsDetails[0]?.age ? `| ${participantsDetails[0]?.age} years` : ""}`} </p>}
                        {competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText && <p><strong>Team Name :</strong> {participantsDetails[0]?.teamName} </p>}
                        {competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails.markingType !== AppConstants.markingTypes.team.value && <p><strong>Name : </strong>{`${participantsDetails[0]?.participantName} ${participantsDetails[0]?.participantQrenciaId ? `| QID: ${participantsDetails[0]?.participantQrenciaId}` : ""}  ${participantsDetails[0]?.age ? `| ${participantsDetails[0]?.age} years` : ""}`}</p>}
                        {competitionDetails.teamEnrolmentEnabledFlag === AppConstants.yText && competitionDetails.markingType === AppConstants.markingTypes.team.value && <>
                            <p><strong>Team Members:</strong></p>
                            <ol className='mt-2'>
                                {teamMembers?.map(member =>
                                    <li className={markParticipantsStyles.teamMembersList}>{member.participantName} {member?.participantQrenciaId && `| QID:${member?.participantQrenciaId}`}</li>
                                )}
                            </ol>
                        </>}
                    </div>
                    {competitionDetails.skillName?.toLocaleLowerCase() !== AppConstants.gymnasticsSkillName.toLocaleLowerCase() && <div className={markParticipantsStyles.InstructionsContainer}>
                        <p className={markParticipantsStyles.instructionTitle}><strong>{AppConstants.markParticipantsInstructionsTitle}</strong></p>
                        {competitionDetails.markingType !== AppConstants.markingTypes.team.value && <ul className={markParticipantsStyles.instructionList}>
                            {AppConstants.markParticipantsInstructionsArray.map((instruction, index) => (
                                <li key={index}>{instruction}</li>
                            ))}
                        </ul>}
                        {competitionDetails.markingType === AppConstants.markingTypes.team.value && <ul className={markParticipantsStyles.instructionList}>
                            {AppConstants.markTeamInstructionsArray.map((instruction, index) => (
                                <li key={index}>{instruction}</li>
                            ))}
                        </ul>}
                    </div>}
                    <Button className={markParticipantsStyles.downloadJudingSheetButton} disabled={competitionAspectDetails?.length === 0} onClick={downloadJudgingSheet}>{judgingSheetSpinner && <BiLoaderAlt className={markParticipantsStyles.spinner} />}{!judgingSheetSpinner && <BsFillCloudArrowDownFill />} Judging Sheet</Button>
                    {participantsDetails[0]?.solutionSubmissionFlag === AppConstants.trueFlag && <Button className={markParticipantsStyles.downloadJudingSheetButton} onClick={(e) => openInNewWindow(e, participantsDetails[0]?.submissionFilePath)}>View Submission <BiLinkExternal /></Button>}
                </div>
                {judgingSheetError && <ErrorSuccessAlertMessage message={judgingSheetErrorMessage} varient={judgingSheetErrorVarient} />}
                <div className={markParticipantsStyles.allotmarksContainer}>
                    <div className={markParticipantsStyles.backButtonDiv}><FaArrowLeft onClick={viewAllParticipantList} /></div>
                    {competitionAspectDetails?.length === 0 && <div className={markParticipantsStyles.judgingEnabledWarningMessage}><p><AiOutlineExclamationCircle />&nbsp;{ErrorMessages.noJudgingParametersConfiguredWarning}</p></div>}
                    {competitionDetails.skillName?.toLocaleLowerCase() !== AppConstants.gymnasticsSkillName.toLocaleLowerCase() && <form onSubmit={(e) => { submitParticipantsMarks(e) }}>
                        <Table bordered>
                            <thead>
                                <tr className={markParticipantsStyles.tableHeadings}>
                                    <th>Criteria</th>
                                    <th>Aspect</th>
                                    <th>Maximum Marks</th>
                                    <th>Marks Obtained</th>
                                </tr>
                            </thead>
                            <tbody>
                                {competitionAspectDetails.map((aspect) => (
                                    <tr key={aspect.aspectId}>
                                        <td>{aspect.criteriaName}</td>
                                        <td>{aspect.aspectDescription}</td>
                                        <td className={markParticipantsStyles.aspectMarksContainer}>{aspect.aspectMarks}</td>
                                        <td className={markParticipantsStyles.inputBoxContainer}><input className={markParticipantsStyles.inputMarksBox}
                                            value={aspect.marksAllotted} inputMode="numeric"
                                            onKeyDown={validateKeyDown} onChange={(e) => { handleInputMarks(e, aspect.uniqueAspectId) }} type="number" step="1" min={0} max={aspect.aspectMarks} required /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className={markParticipantsStyles.submitButtonContainer}>
                            <Button disabled={competitionAspectDetails?.length === 0} type="submit" className={markParticipantsStyles.submitButton}>Submit{submitMarksSpinner && <BiLoaderAlt className={markParticipantsStyles.spinner} />}</Button>
                        </div>
                        {submitMarksError && <ErrorSuccessAlertMessage message={submitMarksErrorMessage} varient={submitMarksErrorVarient} />}
                    </form>}
                    {competitionDetails.skillName?.toLocaleLowerCase() === AppConstants.gymnasticsSkillName.toLocaleLowerCase() && <GymnasticsMarkParticipantScreen competitionDetails={competitionDetails} competitionAspectDetails={competitionAspectDetails} judgeUniqueId={judgeUniqueId} studentUniqueId={participantQrenciaId} participantsDetails={participantsDetails} />}
                </div>
            </div >}
        </>
    )
}
