import { Link } from "react-router-dom";
import { React, useState } from "react";
import studentCompetitionWidgetCards from './StudentCompetitionWidgetCards.module.css';
import { Row, Col, Card, Button } from "react-bootstrap";
import { FaChalkboardTeacher, FaRegCalendarCheck } from 'react-icons/fa';
import { BiCalendarX } from 'react-icons/bi';
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { TbRefreshAlert } from 'react-icons/tb';
import { BiDownload } from 'react-icons/bi';
import { MdShareLocation } from 'react-icons/md';
import 'moment-timezone';
import { Tooltip } from "react-tooltip";
import { TeamEnrolmentDetailsModal } from "../modal/teamEnrolmentDetailsModal/TeamEnrolmentDetailsModal.js";
import Auth from "../../utils/Auth";
import { CiViewList } from "react-icons/ci";
import { DateTime } from "luxon";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal.js";
import { TeamEnrolmentForm } from "../formControls/teamEnrolmentForm/TeamEnrolmentForm.js";
import { EnrolInCompetition } from "../studentPageComponents/EnrolInCompetition.js";
import { AuthorizedDataService } from "../../services/AuthorizedDataService.js";
import { EnrolmentModal } from "../modal/EnrolmentModal.js";

export const StudentUpcomingCompetitionWidgetCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.rightWidgetCardCounts);
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(AppConstants.falseText);
    const [selectedLearningActivity, setSelectedLearningActivity] = useState({});
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [showTeamEnrolmentForm, setShowTeamEnrolmentForm] = useState(AppConstants.falseText);
    //download competition pre-read
    async function downloadPreRead(competitionData) {
        setDownloadFailed(false);
        const fileUrl = competitionData.guidelinesDocumentPath;

        try {
            FileOperationsUtil.downloadDocument(fileUrl, competitionData.title, AppConstants.guidelinesFile);
        } catch (error) {
            setDownloadFailed(true);
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const showTeamDetails = (e, learningActivity) => {
        e.stopPropagation();
        setSelectedLearningActivity({ uniqueId: learningActivity.uniqueId });
        setShowTeamDetailsModal(AppConstants.trueText);
    };

    const closeTeamDetailsModal = () => {
        setSelectedLearningActivity({});
        setShowTeamDetailsModal(AppConstants.falseText);
    }

    const enrolInCompetition = async () => {
        setShowTeamEnrolmentForm(AppConstants.trueText);
        return;
    };

    const seatsFilledConfirmation = async (uniqueId, teamsLimit) => {
        const url = `${AppConstants.checkTeamEnrolmentEligibilityAPI}?competitionUniqueId=${uniqueId}`;
        const response = await AuthorizedDataService.getRequestWithResponseCode(url, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            if (response && response.status === 500) {
                const data = await response.json();
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.emptyString);
                setMessage(EnrolInCompetition.getEnrolmentStatusForTeamCompetitions({ ...data, message: 0 }, null));
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    }
    const confirmBeforeEnrolment = async ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole, cost, teamParticipantsLimit, teamsLimit }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.competition, payOfflineFlag, creatorName, creatorRole, cost, teamParticipantsLimit, teamsLimit });
        if (Auth.getUserRole() === AppConstants.userRoleTeam) {
            let teamEligibility = await seatsFilledConfirmation(uniqueId, teamsLimit);
            if (!teamEligibility) {
                return;
            }
        }
        const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
        const currentDateTime = DateTime.local().setZone(timeZone);
        if (currentDateTime > startDateTime) {
            setShowConfirmationModal(AppConstants.trueText);
        } else {
            enrolInCompetition();
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        if (status) {
            enrolInCompetition();
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const hideTeamEnrolmentForm = () => {
        setShowTeamEnrolmentForm(AppConstants.falseText);
    };
    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };
    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={studentCompetitionWidgetCards.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((competition) => (
                    <Col className={studentCompetitionWidgetCards.competitionCardSection} key={competition.id}>
                        <Card className={studentCompetitionWidgetCards.competitionCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitions}${AppConstants.forwardSlash}${competition.categorySlug}${AppConstants.forwardSlash}${competition.skillSlug}${AppConstants.forwardSlash}${competition.competionSlug}`)}>
                            <Card.Body className={studentCompetitionWidgetCards.competitionCardBody}>
                                <Card.Title className={studentCompetitionWidgetCards.competitionTitle}><Link>{competition.title}</Link></Card.Title>
                                <div>
                                    {competition.clubQrenciaFlg === AppConstants.yText && <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaChalkboardTeacher className={studentCompetitionWidgetCards.orangeCalendarIcon} /><div className={studentCompetitionWidgetCards.dateTimeText}>School</div></div>}
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaRegCalendarCheck className={studentCompetitionWidgetCards.orangeCalendarIcon} /> <div className={studentCompetitionWidgetCards.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{competition.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competition.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {competition.preferredTimezone === AppConstants.utcTimezone && `(${competition.preferredTimezone})`}</span></div>
                                    </div>
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <BiCalendarX className={`${studentCompetitionWidgetCards.orangeCalendarIcon} ${studentCompetitionWidgetCards.endDateIcon}`} /> <div className={studentCompetitionWidgetCards.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{competition.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competition.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {competition.preferredTimezone === AppConstants.utcTimezone && `(${competition.preferredTimezone})`}</span></div>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${competition.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${competition.userSlug}`) }} className={studentCompetitionWidgetCards.expertNameText}>By : {competition.organizedBy}</div>
                                <div>
                                    {competition.type === AppConstants.competitionTypeOffline && <div onClick={stopPropagation}>
                                        <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={competition.geoLocation} target="_blank"> <span data-tooltip-place="top" data-tooltip-id={`${competition.competionSlug}-upcoming`} className={studentCompetitionWidgetCards.widgitLocationSpan}> <MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Venue Map</span></a> {competition.guidelinesDocumentPath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {competition.guidelinesDocumentPath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(competition); }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { { stopPropagation(e); downloadPreRead(competition) } }} />}</a>}
                                    </div>}

                                    <Tooltip className={studentCompetitionWidgetCards.tooltipMessage} id={`${competition.competionSlug}-upcoming`}><span className={studentCompetitionWidgetCards.tooltipMessage}>{competition?.venueLocation}</span></Tooltip>
                                    {competition.type === AppConstants.competitionTypeOnline && <div>
                                        <p className={studentCompetitionWidgetCards.competitionOnlineModeText}><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Online</p> {competition.guidelinesDocumentPath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {competition.guidelinesDocumentPath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(competition) }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(competition) }} />}</a>}
                                    </div>}
                                    {Auth.getUserRole() === AppConstants.userRoleTeam && <p onClick={(e) => showTeamDetails(e, competition)} className={studentCompetitionWidgetCards.competitionCardLink}><CiViewList /> View Team Details</p>} <br />
                                    {Auth.getUserRole() === AppConstants.userRoleTeam && <Button type="button" className={`cardButton ${studentCompetitionWidgetCards.addTeamButton}`} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: competition.competitionId, title: competition.title, uniqueId: competition.uniqueId, startTime: competition.startDateTimeUTC, payOfflineFlag: competition.payOfflineFlag, creatorName: competition.organizedBy, creatorRole: competition.userRole, cost: competition.cost, teamParticipantsLimit: competition.teamParticipantsLimit, teamsLimit: competition.teamsLimit }) }}>Add New Team</Button>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                {showTeamDetailsModal && <TeamEnrolmentDetailsModal learningActivityDetails={selectedLearningActivity} closeTeamDetailsModal={closeTeamDetailsModal} />}
                {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
                {showTeamEnrolmentForm && <TeamEnrolmentForm hideTeamEnrolmentForm={hideTeamEnrolmentForm} enrolmentDetails={confirmationPopupActivityDetails} />}
                {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={AppConstants.emptyString} enrolmentDetails={confirmationPopupActivityDetails}></EnrolmentModal>}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.rightWidgetCardCounts)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}