import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css'
import '../node_modules/react-modal-video/scss/modal-video.scss';
import './index.css';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>

      <App />

  </React.StrictMode>
);
