import { useState, useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import { StudentFeaturedCourses } from '../../component/studentPageComponents/studentCoursesComponents/studentFeaturedCourses/StudentFeaturedCourses';
import { StudentRecentlyPublishedCourses } from '../../component/studentPageComponents/studentCoursesComponents/studentRecentlyPublishedCourses/StudentRecentlyPublishedCourses';
import classes from "./StudentCoursesPage.module.css";
import { StudentSkillCategoryTabs } from '../../component/lists/StudentSkillCategoryTabs';
import { DataService } from '../../services/DataService';

export const StudentCoursesPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const skillsData = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (skillsData?.length > AppConstants.zeroIndex) {
                var data = new Map();
                for (let obj of skillsData) {
                    data.set(obj.categoryId, obj.category);
                }
                let skillCategoryArray = [...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId);

                setCategoryList([AppConstants.AllText, ...skillCategoryArray]);
            }
        }

        fetchSkills();
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, []);

    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    }

    return (
        <div className="studentContentSection">
            {categoryList.length > AppConstants.zeroIndex && <div className={classes.section}>
                <div className={classes.filterSection}>
                    <StudentSkillCategoryTabs categoryList={categoryList} onSelection={categorySelectionHandler} />
                </div>
                <h3 className={classes.sectionHeading}>Featured Courses</h3>
                <StudentFeaturedCourses minimumReviewCount={minimumReviewCount} selectedCategory={selectedCategory} categoryList={categoryList} />
                <h3 className={classes.sectionHeading}>Recently Published</h3>
                <StudentRecentlyPublishedCourses minimumReviewCount={minimumReviewCount} selectedCategory={selectedCategory} categoryList={categoryList} />
            </div>}
        </div>
    )
}