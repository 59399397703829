import { useState } from 'react';
import parse from 'html-react-parser';
import { IoMdAdd } from "react-icons/io";
import classes from "./FaqSectionCards.module.css";
import { Accordion } from 'react-bootstrap';
import "./FaqSectionCard.css";

export const FaqSectionCards = ({ faqDetails }) => {
    return (
        <>
            <div className='coursePageFaqAccordion'>
                <Accordion className={classes.cardContainer}>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className={classes.questionContainer}>{faqDetails.question}</Accordion.Header>
                        <Accordion.Body className={classes.answerContainer}>
                            <div>
                                {faqDetails.answer && <div className={`${classes.answer} quillListIndent ql-editor`}>{parse(faqDetails.answer)}</div>}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >
            </div>
        </>
    )
}