import { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaRegEdit, FaUsers, FaHome, FaChartLine } from 'react-icons/fa';
import { GrTableAdd } from "react-icons/gr";
import { AppConstants } from "../../constants/AppConstants";
import Auth from '../../utils/Auth';
import '../../common/TemplateStyle.css';

export const MarketingLeftNav = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <Navbar expanded={expanded} expand="lg" className="sideNavigation">
                <Navbar.Toggle className="sidebarToggleButton" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="sideNavigationContent">
                    <Nav className="sideNavigationListSection">
                        <div className="profileSection">
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div className="profileImageDiv">
                                <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className="profileImage" alt="User Profile" />
                            </div>}
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                                <div className="userInitials">{Auth.getLoggedInUserDetails().nameInitials}</div>
                            </div>}
                            <div className="userNameDiv">
                                <p className="userName">{Auth.getLoggedInName()}</p>
                                <p className="qrenciaIdText">QID: {Auth.getLoggedInUserDetails().userId}</p>
                            </div>
                        </div>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.marketingBulkSignupPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaUsers className="linkIcons" />
                                <p className="navigationLinks">Bulk Signup</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.marketingBulkEnrolPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaRegEdit className="linkIcons" />
                                <p className="navigationLinks">Bulk Enrol</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.marketingBulkUploadLeads}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><GrTableAdd className="linkIcons" />
                                <p className="navigationLinks">Upload Leads</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.marketingDashboardPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaHome className="linkIcons" />
                                <p className="navigationLinks">Dashboard</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.marketingNumberTrackingPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaChartLine className="linkIcons" />
                                <p className="navigationLinks">Number Tracking</p>
                            </NavLink>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}