import { Outlet, useLoaderData } from 'react-router-dom';
import React, { useEffect, useState, createContext } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { StudentHeader } from '../../layout/StudentPageLayout/StudentHeader';
import { StudentLeftNav } from '../../layout/StudentPageLayout/StudentLeftNav';
import { StudentAdvertisement } from '../../layout/StudentPageLayout/StudentAdvertisement';
import { DataService } from "../../services/DataService";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from '../../constants/ErrorMessages';
import { StudentFooter } from '../../layout/StudentPageLayout/StudentFooter';
import { AppConstants } from "../../constants/AppConstants";
import { StudentRightWidgetSpace } from '../../layout/StudentPageLayout/StudentRightWidgetSpace';
import { useNavigate } from "react-router-dom";
import Auth from '../../utils/Auth';
import classes from './StudentTemplate.module.css';
//export const StudentTemplateContext = createContext(null);
export const StudentTemplate = () => {
    //const [landingPageData, skillsData] = useLoaderData();
    const userData = Auth.getLoggedInUserDetails();
    const [footerDetails, setFooterDetails] = useState();
    const [advertisementDetails, setAdvertisementDetails] = useState();
    const [associationStatus, setAssociationStatus] = useState({});
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // async function navigateToCompleteProfile() {
        //     if (userData.profileCompletionFlag === "N" || userData.mobileNumberVerificationFlag === "N" || userData.emailVerificationFlag === "N") {
        //         navigate(AppConstants.studentCompleteProfilePath);
        //     }
        // };
        // navigateToCompleteProfile();
        async function fetchFooterData() {
            const url = AppConstants.landingPageDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setFooterDetails(response);
            return null;
        };
        fetchFooterData();

        async function fetchStudentAdvertisementData() {
            const url = AppConstants.studentAdvertisementDataAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setAdvertisementDetails(response);
            return null;
        }
        fetchStudentAdvertisementData();


        async function getStudentAssociationWithSchool() {
            const url = AppConstants.getStudentAssociationWithSchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setAssociationStatus(response)
            } else {
                setAssociationStatus(AppConstants.falseText);
            }
        }

        getStudentAssociationWithSchool();
    }, []);


    const myActivitiesHandler = () => {
        if (open === true) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
    }
    return (
        <>
            <StudentHeader />
            {/*<StudentTemplateContext.Provider value={skillsData}>*/}
            <div>
                <div className={classes.myActivitiesSection}>
                    <Button className={classes.myActivitiesButton} onClick={myActivitiesHandler}>My Activities {!open && <RiArrowDownSLine className={classes.activityIcon} />}
                        {open && <RiArrowUpSLine className={classes.activityIcon} />}</Button>
                </div>
                <Row>
                    <Col lg={2}><StudentLeftNav associationStatus={associationStatus} /></Col>
                    <div className={classes.mobileViewRightWidgetSection}>{open && <StudentRightWidgetSpace />}</div>
                    <Col xs={12} sm={12} md={8} lg={7} className={classes.templateHeight}>
                        <Outlet />
                        {advertisementDetails != null && <StudentAdvertisement advertisementData={advertisementDetails} />}
                    </Col>
                    <Col md={4} lg={3} className="d-none d-md-block d-lg-block" ><StudentRightWidgetSpace /></Col>
                </Row>
            </div>
            {/*</StudentTemplateContext.Provider>*/}

            {footerDetails != null && <StudentFooter footerDetails={footerDetails} />}
        </>
    )
}
