import { useState } from "react";
import classes from './ImpactCreatedByQrenciaCards.module.css';
import { Row, Col } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
export const ImpactCreatedByQrenciaCards = (props) => {
    const [impactCreatedByQrenciaCardsList, setImpactCreatedByQrenciaCardsList] = useState(props.cardDetails);

    return (
        <>
            <Row xs={2} sm={2} md={4} lg={4} xl={4} className={classes.impactCreatedCardSection}>
                {impactCreatedByQrenciaCardsList.filter((data) => data.active === AppConstants.trueText).map((impactCreatedCards) => (
                    <Col key={impactCreatedCards.id} className={classes.impactCreatedCardColSection}>
                        <h1 className={classes.heading}>{impactCreatedCards.title}</h1>
                        <h2 className={classes.subHeading}>{impactCreatedCards.subTitle}</h2>
                    </Col>
                ))}
            </Row>
        </>
    );
}