import classes from "./PendingEnrolmentRequest.module.css";
import { useCallback, useEffect, useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useLoaderData, useNavigate } from "react-router-dom";
import { QuizAuthenticationForm } from "../../component/quizEventComponent/QuizAuthenticationForm";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import Auth from "../../utils/Auth";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import { Logout } from "../../common/Logout";
import { AppConstants } from "../../constants/AppConstants";
import { PendingEnrolmentRequestDetails } from "../../component/offlinePaymentRequestComponents/pendingEnrolmentRequestDetails/PendingEnrolmentRequestDetails";
import { Row } from "react-bootstrap";

export const PendingEnrolmentRequestPage = () => {
    const [pendingEnrolmentDetails, setPendingEnrolmentDetails] = useState([]);
    const [showPopupForLogin, setShowPopupForLogin] = useState(AppConstants.falseText);
    const [pageLoaderSpinner, setPageLoaderSpinner] = useState(AppConstants.trueText);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [logoutMessage, setLogoutMessage] = useState(AppConstants.falseText);
    const [landingPageData] = useLoaderData();
    const navigate = useNavigate();

    const fetchPendingOfflinePaymentRequest = useCallback(async () => {
        const url = AppConstants.getPendingOfflinePaymentRequestsForLearningActivityAPI;
        const response = await AuthorizedDataService.getRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
            setLogoutMessage(AppConstants.trueText);
            setPageLoaderSpinner(AppConstants.falseText);
        } else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
            navigate(AppConstants.asterisk);
        } else {
            setPendingEnrolmentDetails(response);
            setPageLoaderSpinner(AppConstants.falseText);
        }
    }, [navigate]);


    useEffect(() => {

        if (Auth.isLogin()) {
            if (Auth.getUserRole() !== AppConstants.userRoleExpert && Auth.getUserRole() !== AppConstants.userRoleAgency) {
                navigate(AppConstants.asterisk);
                setShowPopupForLogin(AppConstants.falseText);
            } else {
                fetchPendingOfflinePaymentRequest();
            }
        } else {
            setShowPopupForLogin(AppConstants.trueText);
        }
    }, [navigate, timeZone, fetchPendingOfflinePaymentRequest]);

    const closeAuthenticationForm = () => {
        window.location.reload();
        setShowPopupForLogin(AppConstants.trueText);
    };

    const refreshPageData = () => {
        fetchPendingOfflinePaymentRequest();
    }

    return (
        <>
            {
                showPopupForLogin ? <div className={classes.loginSection}>
                    <QuizAuthenticationForm disableSignup={AppConstants.trueText} closeAuthenticationForm={closeAuthenticationForm} signupHeading="Signup" loginHeading="Login" />
                </div> : (pageLoaderSpinner || logoutMessage) ? <>
                    <LandingPageTopNavigation landingPageData={landingPageData} />
                    <div className={classes.loaderContainer}>
                        {pageLoaderSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}
                        {logoutMessage && <p className={classes.sessionExpiredText}>Your session has been expired. Please <span onClick={Logout.logout}>login</span> to continue.</p>}
                    </div>
                </>
                    : <>
                        <LandingPageTopNavigation landingPageData={landingPageData} />
                        <div className={classes.pageContainer}>
                            <Row>
                                <h1 className={classes.pendingEnrolmentHeading}>Offline enrolments requests</h1>
                            </Row>
                            <PendingEnrolmentRequestDetails refreshPageData={refreshPageData} pendingEnrolmentDetails={pendingEnrolmentDetails} />
                        </div>
                        <LandingPageFooter landingPageData={landingPageData} />
                    </>
            }
        </>
    )
}

