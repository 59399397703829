import React,{useState} from 'react';
import classes from './IndividualSkillsPageHeader.module.css';
import { AppConstants } from '../../constants/AppConstants';
export const IndividualSkillsPageHeader = (props) => {
   
    
    const [mobileImage,setMobileImage] = useState(...props.bannerSectionData.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.mobileText)));
    const [tabletImage,setTabletImage] = useState(...props.bannerSectionData.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.tabletText)));
    const [webImage,setWebImage] = useState(...props.bannerSectionData.filter(section => section.imageUrl.toLowerCase().includes(AppConstants.webText)));

    return (
        <>             
            <div className={classes.ispMobileImgContainer}>
                <img src={mobileImage.imageUrl} alt={props.skillName} className={classes.ispBannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{mobileImage.title}</h5>
                    <h3 className={classes.skillName}>{props.skillName}</h3>
                </div>
            </div>   
            <div className={classes.ispTabletImgContainer}>
                <img src={tabletImage.imageUrl} alt={props.skillName} className={classes.ispBannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{tabletImage.title}</h5>
                    <h3 className={classes.skillName}>{props.skillName}</h3>
                </div>
            </div> 
            <div className={classes.ispBannerImgContainer}>
                <img src={webImage.imageUrl} alt={props.skillName} className={classes.ispBannerImage} />
                <div className={classes.ispBannerTextOverLay}>
                    <h5 className={classes.exploreSkillsText}>{webImage.title}</h5>
                    <h3 className={classes.skillName}>{props.skillName}</h3>
                </div>
            </div>  
        </>
    );
}