import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppConstants } from '../../../constants/AppConstants';
import { DataService } from '../../../services/DataService';
import { ExtendedPageBannerImage } from '../ExtendedPageBannerImage';
import { SkillsCards } from '../../cards/SkillsCards';
import { SkillCategoryListSection } from '../../lists/SkillsCategoryListSection';
import { SearchSkillsBar } from '../../formControls/SearchSkillsBar';

export const AllSkills = (props) => {

    const { categorySlug } = useParams();
    const [exploreSkills, setExploreSkills] = useState(props.skillsData);
    const [skillsList, setSkillsList] = useState(props.skillsData);
    const linkToNavigate = `${AppConstants.forwardSlash}${AppConstants.skills}`;
    const [skillsOfCategory, setSkillsOfCategory] = useState(categorySlug != undefined ? exploreSkills.filter((item) => item.categorySlug.toLowerCase().includes(categorySlug.toLowerCase())) : exploreSkills);
    const [selectedCategory, setSelectedCategory] = useState(categorySlug != undefined ? exploreSkills.filter((item) => item.categorySlug.toLowerCase().includes(categorySlug.toLowerCase()))[0].category : AppConstants.AllText);
    const [selectedAge, setSelectedAge] = useState(AppConstants.selectAge);
    const [selectedSkillName, setSelectedSkillName] = useState(AppConstants.emptyString)
    let filteredByAge = [];
    let filteredBySkillName = [];
    let filteredListOnReset = [];


    useEffect(() => {
        scrollToTop()
        async function scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'instant'
            });
        };

    }, []);

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setExploreSkills(response);
        }
    }, []);


    //Handler function for Skill Name search
    function skillNameSearchHandler(skill) {
        setSelectedSkillName(skill);
        if (selectedAge !== AppConstants.eighteenPlusYears || selectedAge !== AppConstants.selectAge) {
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
                filteredBySkillName = skill.toLowerCase() !== AppConstants.emptyString.toLowerCase()
                    ? exploreSkills.filter((item) => (item.skill.toLowerCase().includes(skill.toLowerCase()) && (item.category.toLowerCase().includes(selectedCategory.toLowerCase())) && (parseInt(item.skillMinAge) === parseInt(selectedAge.split(AppConstants.hyphen)[0]))))
                    : exploreSkills.filter((item) => (item.category.toLowerCase().includes(selectedCategory.toLowerCase())) && parseInt(item.skillMinAge) === parseInt(selectedAge.split(AppConstants.hyphen)[0]));
            }
            if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
                filteredBySkillName = skill.toLowerCase() !== AppConstants.emptyString.toLowerCase()
                    ? exploreSkills.filter((item) => (item.skill.toLowerCase().includes(skill.toLowerCase()) && (parseInt(item.skillMinAge) === parseInt(selectedAge.split(AppConstants.hyphen)[0]))))
                    : exploreSkills.filter((item) => parseInt(item.skillMinAge) === parseInt(selectedAge.split(AppConstants.hyphen)[0]));
            }

        }
        if (selectedAge.toLowerCase() === AppConstants.eighteenPlusYears.toLowerCase() || selectedAge.toLowerCase() === AppConstants.selectAge.toLowerCase()) {
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
                filteredBySkillName = skill.toLowerCase() === AppConstants.emptyString.toLowerCase()
                    ? exploreSkills.filter((item) => (item.category.toLowerCase().includes(selectedCategory.toLowerCase())))
                    : exploreSkills.filter((item) => (item.category.toLowerCase().includes(selectedCategory.toLowerCase())) && item.skill.toLowerCase().includes(skill.toLowerCase()))
            }
            if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
                filteredBySkillName = skill.toLowerCase() === AppConstants.emptyString.toLowerCase() ? exploreSkills
                    : exploreSkills.filter((item) => item.skill.toLowerCase().includes(skill.toLowerCase()));
            }
        }

        setSkillsOfCategory(filteredBySkillName);
    }

    // function to select category and filter skills data
    function categorySelectionHandler(option) {
        setSelectedAge(AppConstants.selectAge);
        setSelectedSkillName(AppConstants.emptyString);

        setSelectedCategory(option);
        const filteredSkills = option === AppConstants.AllText ? props.skillsData : props.skillsData.filter(item => item.category.includes(option));
        setSkillsOfCategory(filteredSkills);
    }
    //Handler Function for Age Seach
    function ageSearchHandler(option) {
        setSelectedAge(option);
        if (selectedSkillName.toLowerCase() !== AppConstants.emptyString.toLowerCase()) {
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase() && (option !== AppConstants.eighteenPlusYears || option !== AppConstants.selectAge)) {
                filteredByAge = exploreSkills.filter((data) => data.skill.toLowerCase().includes(selectedSkillName.toLowerCase()) && (parseInt(data.skillMinAge) === parseInt(option.split(AppConstants.hyphen)[0])) && data.category.toLowerCase().includes(selectedCategory.toLowerCase()));

            }
            if (selectedCategory.toLowerCase().includes(AppConstants.AllText.toLowerCase()) && (option !== AppConstants.eighteenPlusYears || option !== AppConstants.selectAge)) {
                filteredByAge = exploreSkills.filter((data) => data.skill.toLowerCase().includes(selectedSkillName.toLowerCase()) && (parseInt(data.skillMinAge) === parseInt(option.split(AppConstants.hyphen)[0])));

            }
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase() && (option.toLowerCase() === AppConstants.eighteenPlusYears.toLowerCase() || option.toLowerCase() === AppConstants.selectAge.toLowerCase())) {
                filteredByAge = exploreSkills.filter(data => data.skill.toLowerCase().includes(selectedSkillName.toLowerCase()) && data.category.toLowerCase().includes(selectedCategory.toLowerCase()));

            }
            if (selectedCategory.toLowerCase().includes(AppConstants.AllText.toLowerCase()) && (option.toLowerCase() === AppConstants.eighteenPlusYears.toLowerCase() || option.toLowerCase() === AppConstants.selectAge.toLowerCase())) {
                filteredByAge = exploreSkills.filter((data) => data.skill.toLowerCase().includes(selectedSkillName.toLowerCase()));

            }
        }
        if (selectedSkillName.toLowerCase() === AppConstants.emptyString.toLowerCase()) {
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase() && (option !== AppConstants.eighteenPlusYears || option !== AppConstants.selectAge)) {
                filteredByAge = exploreSkills.filter((data) => (parseInt(data.skillMinAge) === parseInt(option.split(AppConstants.hyphen)[0])) && data.category.toLowerCase().includes(selectedCategory.toLowerCase()));

            }
            if (selectedCategory.toLowerCase().includes(AppConstants.AllText.toLowerCase()) && (option !== AppConstants.eighteenPlusYears || option !== AppConstants.selectAge)) {
                filteredByAge = exploreSkills.filter((data) => (parseInt(data.skillMinAge) === parseInt(option.split(AppConstants.hyphen)[0])));

            }
            if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase() && (option.toLowerCase() === AppConstants.eighteenPlusYears.toLowerCase() || option.toLowerCase() === AppConstants.selectAge.toLowerCase())) {
                filteredByAge = exploreSkills.filter(data => data.category.toLowerCase().includes(selectedCategory.toLowerCase()));

            }
            if (selectedCategory.toLowerCase().includes(AppConstants.AllText.toLowerCase()) && (option.toLowerCase() === AppConstants.eighteenPlusYears.toLowerCase() || option.toLowerCase() === AppConstants.selectAge.toLowerCase())) {
                filteredByAge = exploreSkills;

            }
        }
        setSkillsOfCategory(filteredByAge);
    }

    //Handler function for Skill Name and select age rest
    function resetSearchAndAgeFilter() {
        setSelectedSkillName(AppConstants.emptyString);
        setSelectedAge(AppConstants.selectAge);

        if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
            filteredListOnReset = exploreSkills.filter((item) => (item.category.toLowerCase().includes(selectedCategory.toLowerCase())))
        }
        if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
            filteredListOnReset = exploreSkills;
        }
        setSkillsOfCategory(filteredListOnReset);
    }
    return (
        <>
            <ExtendedPageBannerImage bannerImage={props.bannerImage} />
            <SkillCategoryListSection categoryList={skillsList} onSelection={categorySelectionHandler} linkToNavigate={linkToNavigate} />
            <SearchSkillsBar onSearch={skillNameSearchHandler} onAgeChange={ageSearchHandler} onResetFilter={resetSearchAndAgeFilter} selectedAge={selectedAge} selectedSkillName={selectedSkillName} />
            <SkillsCards skillsOfCategory={skillsOfCategory} selectedAge={selectedAge} />
        </>
    );
}