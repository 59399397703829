import { Outlet } from 'react-router-dom';
import React from 'react';
import { Row, Col } from "react-bootstrap";
import { SalesHeader } from '../../layout/SalesPageLayout/SalesHeader';
import { SalesLeftNav } from '../../layout/SalesPageLayout/SalesLeftNav';
import { SalesFooter } from '../../layout/SalesPageLayout/SalesFooter';
import { useLoaderData } from 'react-router-dom';

export const SalesTemplate = () => {
    const footerDetails = useLoaderData();
    
    return (
        <>
            <SalesHeader />
            <Row>
                <Col lg={2}><SalesLeftNav /></Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <Outlet />
                </Col>
            </Row>
            {footerDetails != null && <SalesFooter footerDetails={footerDetails} />}
        </>
    )
}