import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import classes from "../student/StudentHomePage.module.css";
import { DataService } from "../../services/DataService";
import { StudentSkillCategoryTabs } from "../../component/lists/StudentSkillCategoryTabs";
import { StudentHomeTodaysActivities } from "../../component/studentPageComponents/StudentHomeTodaysActivities";
import { StudentCompetitions } from "../../component/studentPageComponents/StudentCompetitions/StudentCompetitions";

export const TeamHomePage = () => {
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const [minimumAgeLimit, setMinimumAgeLimit] = useState(AppConstants.emptyString);
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const skillsData = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);

            if (skillsData.length > AppConstants.zeroIndex) {
                var data = new Map();
                for (let obj of skillsData) {
                    data.set(obj.categoryId, obj.category);
                }
                let skillCategoryArray = [...data.values()].sort((a, b) => a.categoryUniqueId - b.categoryUniqueId);

                setCategoryList([AppConstants.AllText, ...skillCategoryArray]);
            }
        }

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
                setMinimumAgeLimit(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue))
            }
        };

        fetchConfigurableKeys();
        fetchSkills();
    }, []);

    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    };

    return (
        <div className="studentContentSection">
            <StudentHomeTodaysActivities minimumReviewCount={minimumReviewCount} />
            {categoryList.length > AppConstants.zeroIndex && <div className={classes.section}>
                <div className={`${classes.filterSection} mt-4`}>
                    <StudentSkillCategoryTabs categoryList={categoryList} onSelection={categorySelectionHandler} />
                </div>
                <h3 className={classes.sectionHeading}>Featured Competitions</h3>
                <StudentCompetitions minimumAgeLimit={minimumAgeLimit} selectedCategory={selectedCategory} categoryList={categoryList} category="featured" apiEndpoint={AppConstants.getFeaturedCompetitionsForStudentAPI} teamEnrolmentEnabledFlag={AppConstants.yText} />
                <h3 className={classes.sectionHeading}>Recently Published Competitions</h3>
                <StudentCompetitions minimumAgeLimit={minimumAgeLimit} selectedCategory={selectedCategory} categoryList={categoryList} category="recently published" apiEndpoint={AppConstants.getRecentlyPublishedCompetitionsForStudentAPI} teamEnrolmentEnabledFlag={AppConstants.yText} />
            </div>}
        </div >
    )
}