import { Tabs, Tab } from "react-bootstrap";
import './SkillCategoryTabs.css';
import classes from './skillCategoryTabs.module.css';

export const SkillCategoryTabs = (props) => {
    //Handler to return selected Skills Value
    function selectCategory(e) {
        props.onSelection(e);
    };

    return (
        <Tabs className={classes.skillCategoryTab}
            defaultActiveKey={props.categoryList[0]}
            transition={false}
            id="skillCategoryFilterTabs"
            onSelect={(e) => selectCategory(e)}
        >
            {props.categoryList.map((category) => {
                return (
                    <Tab eventKey={category} title={category} key={category}></Tab>
                )
            })}
        </Tabs>
    );
}