import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import parse from 'html-react-parser';
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import classes from "./CopyLearningActivityInput.module.css";
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { RiInformationFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { Typeahead } from "react-bootstrap-typeahead";
import "./CopyLearningActivitySearch.css";

export const CopyLearningActivityInput = (props) => {
    const [selectedLearningActivityId, setSelectedLearningActivityId] = useState(AppConstants.emptyString);
    const [isLearningActivitySelected, setIsLearningActivitySelected] = useState(AppConstants.falseText);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(AppConstants.falseText);
    const [confirmationAction, setConfirmationAction] = useState(AppConstants.emptyString);
    const [showInputField, setShowInputField] = useState(AppConstants.falseText);
    const [copiedLearningActivity, setCopiedLearningActivity] = useState(AppConstants.emptyString);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [learningActivityList, setLearningActivityList] = useState([]);
    const actions = AppConstants.copyLearningActivityActions;
    const typeaheadRef = useRef(null);

    useEffect(() => {
        const fetchLearningActivities = async () => {
            const url = `${AppConstants.getLearningActivitiesForTypeAPI}${props.learningActivityType}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setLearningActivityList(response.map(resp => {
                    let data = {
                        key: `${resp.learningActivityId} | ${resp.learningActivityTitle}`,
                        learningActivityTitle: resp.learningActivityTitle,
                        learningActivityUniqueId: resp.learningActivityUniqueId,
                        learningActivityId: resp.learningActivityId
                    }
                    return data;
                }))
            } else {
                setLearningActivityList([]);
            }
        };

        if (props.learningActivityType) {
            fetchLearningActivities();
        }
    }, [props.learningActivityType])

    const getParsedMessage = (message, obj) => {
        let replacedStr = message;
        for (const [key, value] of Object.entries(obj)) {
            replacedStr = replacedStr.replace(`{{${key}}}`, `${value}`);
        };

        return parse(replacedStr);
    }

    const selectLearningActivity = async () => {
        try {
            let url = AppConstants.emptyString;
            if (props.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                url = `${AppConstants.getCompetitionDetailsForCompetitionIdAPI}${selectedLearningActivityId}`;
            } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                url = `${AppConstants.getCourseDetailsForCourseIdAPI}${selectedLearningActivityId}`;
            } else if (props.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
                url = `${AppConstants.getWorkshopDetailsForWorkshopIdAPI}${selectedLearningActivityId}`
            }

            const response = await AuthorizedDataService.getRequestWithResponseCode(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response && response.status === AppConstants.httpResponseCodes.responseCode400) {
                setMessage(getParsedMessage(ErrorMessages.learningActivityIdNotExistError, { learningActivityType: props.learningActivityType, learningActivityId: selectedLearningActivityId }))
                let timer = setTimeout(() => {
                    setMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else if (response && response.status === AppConstants.httpResponseCodes.responseCode500) {
                setMessage(getParsedMessage(ErrorMessages.learningActivityDetailsFetchError, { learningActivityType: props.learningActivityType }));
                let timer = setTimeout(() => {
                    setMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            } else {
                setCopiedLearningActivity(response);
                setShowConfirmationPopup(AppConstants.trueText);
            }
        } catch (error) {
            setMessage(getParsedMessage(ErrorMessages.learningActivityDetailsFetchError, { learningActivityType: props.learningActivityType }));
            let timer = setTimeout(() => {
                setMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const handleChange = (value) => {
        if (value[0]?.learningActivityId) {
            setSelectedLearningActivityId(value[0]?.learningActivityId);
        } else {
            setSelectedLearningActivityId(AppConstants.emptyString);
        }
    };

    const resetLearingActivity = () => {
        setIsLearningActivitySelected(AppConstants.falseText);
        setSelectedLearningActivityId(AppConstants.emptyString);
        props.resetLearningActivityDetails();
        typeaheadRef?.current?.clear();
    };

    const updateConfirmationStatus = (status) => {
        if (status && confirmationAction === actions.select) {
            setIsLearningActivitySelected(AppConstants.trueText);
            props.setSelectedLearningActivityDetails(copiedLearningActivity);
        } else if (status && confirmationAction === actions.reset) {
            resetLearingActivity();
        }
        setConfirmationAction(AppConstants.emptyString);
        setShowConfirmationPopup(AppConstants.falseText);
        setCopiedLearningActivity(AppConstants.emptyString);
    };

    const toggleCheckbox = (e) => {
        setShowInputField(e.target.checked)
    };

    return (
        <>
            <div className={classes.switchContainer}>
                <div className={classes.switchText}>Copy details from previous {`${props.learningActivityType.toLowerCase()}s`} <RiInformationFill className={classes.infoButton} data-tooltip-id="copyLearningActivityDetailsInfo" /><span><input type="checkbox" readOnly={isLearningActivitySelected} disabled={isLearningActivitySelected} checked={showInputField} onChange={toggleCheckbox} /></span></div>
            </div>
            <Tooltip className={classes.tooltipMessage} id="copyLearningActivityDetailsInfo"><span className={classes.tooltipMessage}>You can copy the {props.learningActivityType.toLowerCase()} details from previously created {props.learningActivityType.toLowerCase()}s.</span></Tooltip>
            <div>
                {showInputField && <div className={classes.copyLearningActivityInputContainer}>
                    <div className="copyLearningActivitySearchContainer">
                        <div className="copyLearningActivityContainerInput">
                            <label className="copyLearningActivitySearchLabel">{props.learningActivityType}</label>
                            <Typeahead
                                ref={typeaheadRef}
                                className="copyLearningActivitySearchInputBox"
                                placeholder={`Search Id or Title of ${props.learningActivityType.toLowerCase()}`}
                                name="copyLearningActivitySearch"
                                id="copyLearningActivitySearch"
                                labelKey="key"
                                disabled={isLearningActivitySelected}
                                filterBy={['learningActivityId', 'learningActivityTitle']}
                                onChange={handleChange}
                                options={learningActivityList}
                            />
                        </div>
                        {!isLearningActivitySelected && <Button disabled={selectedLearningActivityId === AppConstants.emptyString} className={classes.copyButton} onClick={() => { selectLearningActivity(); setConfirmationAction(actions.select) }}>Select</Button>}
                        {isLearningActivitySelected && <Button className={classes.copyButton} onClick={() => { setShowConfirmationPopup(AppConstants.trueText); setConfirmationAction(actions.reset) }}>Reset</Button>}
                    </div>

                </div>}
                {message !== AppConstants.emptyString && <ErrorSuccessAlertMessage message={message} varient={AppConstants.alertVarient[1]} />}
            </div>

            <Modal show={showConfirmationPopup} onHide={() => updateConfirmationStatus(AppConstants.falseText)} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {confirmationAction === actions.reset ? ErrorMessages.resetLearningActivityDetailsWarning : ErrorMessages.copyLearningActivityDetailsWarning}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={classes.modalNoButton} onClick={() => updateConfirmationStatus(AppConstants.falseText)}>No</Button>}
                    {<Button className={classes.modalYesButton} onClick={() => updateConfirmationStatus(AppConstants.trueText)}>Yes</Button>}
                </Modal.Footer>
            </Modal>
        </>
    )

}