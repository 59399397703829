import { useState } from 'react';
import { Alert } from "react-bootstrap";
import classes from '../errorSuccessMessage/ErrorSuccessAlertMessage.module.css';

export const ErrorSuccessAlertMessage = (props) => {

    return (
        <>
            <Alert variant={props.varient} className={classes.alertBox}>
                <p className={`${props.varient === "success" ? classes.successText : classes.errorText}`}>{props.message}</p>
            </Alert>
        </>
    );
}