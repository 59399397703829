import React from "react";
import { Signup } from "../../common/signup";
import { useLoaderData } from 'react-router-dom';
export const SignupPage = () => {
    const [signupPageSectionDetails] = useLoaderData();
    return (
        <>
            {/* <SignupPageTopNavigation /> */}
            <Signup signupPageData={signupPageSectionDetails} />
        </>
    )
}