import { Tabs, Tab } from "react-bootstrap";
import './StudentSkillCategoryTabs.css';

export const StudentSkillCategoryTabs = (props) => {
    //Handler to return selected Skills Value
    function selectCategory(e) {
        props.onSelection(e);
    };

    return (
        <Tabs className="studentSkillCategoryTabs"
            defaultActiveKey={props.categoryList[0]}
            transition={false}
            onSelect={(e) => selectCategory(e)}
        >
            {props.categoryList.map((category) => {
                return (
                    <Tab eventKey={category} title={category} key={category}></Tab>
                )
            })}
        </Tabs>
    );
}