import { useEffect, useState } from "react"
import { AppConstants } from "../../constants/AppConstants";
import { AgGridReact } from "ag-grid-react";
import { Col } from "react-bootstrap";

export const GymnasticsViewParticipantMarking = ({ studentMarksDetails }) => {
    const [markingDetails, setMarkingDetails] = useState([]);
    const [uniqueDynamicAspects, setUniqueDynamicAspects] = useState([]);

    useEffect(() => {
        const createRowDataForTable = () => {
            const finalResult = [];
            const nonExcludedAspectDescriptions = studentMarksDetails.filter(item => !AppConstants.gymnasticsStaticAspects.includes(item.aspectDescription))
                .reduce((uniqueDescriptions, item) => {
                    if (!uniqueDescriptions.includes(item.aspectDescription)) {
                        uniqueDescriptions.push(item.aspectDescription);
                    }
                    return uniqueDescriptions;
                }, []);

            studentMarksDetails.forEach(item => {
                let criteria = finalResult.find(c => c.criteriaId === item.criteriaId);

                if (!criteria) {
                    criteria = {
                        criteriaId: item.criteriaId,
                        criteriaName: item.criteriaName,
                        averageDeduction: item.averageDeduction,
                        finalScore: item.finalScore,
                        escore: item.escore
                    };
                    finalResult.push(criteria);
                }

                if (!AppConstants.gymnasticsStaticAspects.includes(item.aspectDescription)) {
                    criteria[item.aspectDescription] = item.marksalloted;
                } else {
                    if (item.aspectDescription === AppConstants.gymnasticsStaticAspects[0] || item.aspectDescription === AppConstants.gymnasticsStaticAspects[1]) {
                        criteria.dScore = item.marksalloted;
                    } else if (item.aspectDescription === AppConstants.gymnasticsStaticAspects[2] || item.aspectDescription === AppConstants.gymnasticsStaticAspects[3]) {
                        criteria.penalty = item.marksalloted;
                    }
                }
            });

            finalResult.forEach(criteria => {
                for (let i = 0; i < nonExcludedAspectDescriptions.length; i++) {
                    const key = nonExcludedAspectDescriptions[i];
                    if (!criteria.hasOwnProperty(key)) {
                        criteria[key] = null;
                    }
                }
            });
            setUniqueDynamicAspects(nonExcludedAspectDescriptions);
            setMarkingDetails(finalResult);
        }

        if (studentMarksDetails?.length > 0) {
            createRowDataForTable();
        }
    }, [studentMarksDetails]);

    const markingColumnDefs = [
        {
            headerName: "Criteria",
            field: "criteriaName",
            tooltipValueGetter: ({ data }) => data.criteriaName,
            valueGetter: ({ data }) => {
                return data.criteriaName;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `D-Score`,
            field: "dScore",
            tooltipValueGetter: ({ data }) => data.dScore || 0,
            valueGetter: ({ data }) => {
                return data.dScore || 0
            },
            maxWidth: 70,
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Avg. Execution",
            field: "averageDeduction",
            tooltipValueGetter: ({ data }) => data.averageDeduction ?? AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.averageDeduction ?? AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter',
            headerClass: "multi-line-header"
        },
        {
            headerName: `Penalty/Other Deductions`,
            field: "penalty",
            tooltipValueGetter: ({ data }) => data.penalty || 0,
            valueGetter: ({ data }) => {
                return data.penalty || 0;
            },
            maxWidth: 100,
            filter: 'agTextColumnFilter',
            headerClass: "multi-line-header"
        },
        {
            headerName: `D + E`,
            field: "escore",
            maxWidth: 70,
            tooltipValueGetter: ({ data }) => data.escore || 0,
            valueGetter: ({ data }) => {
                return data.escore || 0;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Final Score",
            field: "finalScore",
            tooltipValueGetter: ({ data }) => data.finalScore || AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.finalScore || AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter'
        }
    ];

    const getMarkingColumnDefs = () => {
        let uniqueAspectsHeaders = uniqueDynamicAspects.map(item => ({
            headerName: item,
            field: item,
            maxWidth: 60,
            tooltipValueGetter: ({ data }) => data[item] ?? AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data[item] ?? AppConstants.notAvailableText;
            },
            headerClass: "multi-line-header",
            filter: 'agTextColumnFilter'
        }));
        let updatedColumnDefs = [...markingColumnDefs];
        updatedColumnDefs.splice(2, 0, ...uniqueAspectsHeaders);

        return updatedColumnDefs
    }

    return (
        <div>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        className="expertObrDetailsGrid"
                        columnDefs={getMarkingColumnDefs()}
                        rowData={markingDetails}
                        enableBrowserTooltips={true}
                        domLayout='autoHeight'
                        suppressRowHoverHighlight={true}
                        defaultColDef={{
                            filter: true,
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            resizable: true,
                            suppressMenu: true,
                            suppressHorizontalScroll: true,
                            floatingFilterComponentParams: { suppressFilterButton: true },
                            floatingFilter: true,
                        }}
                        enableFilter={true}
                        enableSorting={true}
                        enableSearching={true}
                    />
                </div>
            </Col>
        </div>
    )
}