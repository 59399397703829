import { useState } from "react"
import { AppConstants } from "../../constants/AppConstants"
import ReCAPTCHA from "react-google-recaptcha"
import { DataService } from "../../services/DataService";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const CaptchaComponent = (props) => {
    const [captchaMessage, setCaptchaMessage] = useState(AppConstants.emptyString);

    const validateCaptchaToken = async (token) => {
        try {
            const url = AppConstants.validCaptchaTokenAPI;
            const data = {
                token
            }
            const response = await DataService.post(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                const body = await response.json();
                return body.success;
            } else {
                return AppConstants.falseText;
            }
        } catch (error) {
            return AppConstants.falseText;
        }
    }

    const onCaptchaChange = async (value) => {
        if (value) {
            const captchaValid = await validateCaptchaToken(value);
            if (captchaValid) {
                props.toggleCaptchaScreen(AppConstants.falseText);
                props.fetchPageData();
            } else {
                props.toggleCaptchaScreen(AppConstants.trueText);
                setCaptchaMessage(ErrorMessages.captchaVerificationFailedError);
                let timer = setTimeout(() => {
                    setCaptchaMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        }
    };

    return (
        <>
            <ReCAPTCHA
                sitekey={props.captchaSiteKey}
                onChange={onCaptchaChange}
            />
            {captchaMessage && <p className="errorText">{captchaMessage}</p>}
        </>
    )
}