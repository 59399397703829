import React, { useState, useEffect } from 'react';
import { MicrositeWorkshopCards } from '../cards/expertMicrositeCards/MicrositeWorkshopCards';

export const MicrositeWorkshopSection = (props) => {
    const [workshopsDetailsObj, setWorkshopsDetailsObj] = useState([]);

    useEffect(() => {
        setWorkshopsDetailsObj(props.workshopsData);
    }, [props.workshopsData]);

    return (
        <>
            <div>
                {workshopsDetailsObj.length !== 0 &&
                    <MicrositeWorkshopCards
                        userRoleForMicrosite={props.userRoleForMicrosite}
                        category="Workshop"
                        workshopsData={workshopsDetailsObj}
                    />
                }
            </div>
        </>
    );
}