import { StudentUpcomingCompetitionsWidget } from '../../component/widgets/StudentUpcomingCompetitionsWidget';
import { StudentNudgesWidget } from '../../component/widgets/StudentNudgesWidget';
import { StudentUpcomingWorkshopsWidget } from '../../component/widgets/StudentUpcomingWorkshopsWidget';
import { StudentUpcomingCoursesWidget } from '../../component/widgets/StudentUpcomingCoursesWidget';
import classes from './StudentRightWidgetSpace.module.css';
import { StudentOfflinePaymentRequestsWidget } from '../../component/widgets/StudentOfflinePaymentRequestsWidget';
export const StudentRightWidgetSpace = () => {
    return (
        <>
            <div className={classes.widgetSection}>
                <StudentOfflinePaymentRequestsWidget />
                <StudentNudgesWidget />
                <StudentUpcomingCompetitionsWidget />
                <StudentUpcomingCoursesWidget />
                <StudentUpcomingWorkshopsWidget />
            </div>
        </>
    )
}