import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { BiCalendarCheck, BiLoaderAlt, BiCalendarX, BiWallet } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import 'moment-timezone';
import modalClass from "../CompetitionsCards.module.css";
import classes from "./StudentLearningActivityCourseCards.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import Auth from "../../../utils/Auth";
import { MdOutlineShareLocation } from "react-icons/md";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { StringUtils } from "../../../utils/StringUtils";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import Moment from "react-moment";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { LiaCommentsSolid } from "react-icons/lia";
import { CourseFeedbackModal } from "../../modal/CourseFeedbackModal";

export const StudentLearningActivityCoursesCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.myLearningCardCount);
    const [cardDetails, setCardDetails] = useState(props.cardDetails);
    const [certificateSpinner, setCertificateSpinner] = useState(new Array(props.cardDetails?.length).fill(false));
    const [showModal, setShowModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [courseTitle, setCourseTitle] = useState(AppConstants.emptyString);
    const [courseId, setCourseId] = useState(AppConstants.emptyString);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [enrolmentMessage, setEnrolmentMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showEnrolmentModal, setShowEnrolmentModal] = useState(AppConstants.falseText);
    const [payloadData, setPayloadData] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [showFeedbackModal, setShowFeedbackModal] = useState(AppConstants.falseText);
    const [selectedLearningActivity, setSelectedLearningActivity] = useState({});

    const learnerDetails = [{
        studentEmail: Auth.getLoggedInUserDetails().userEmail,
        studentName: Auth.getLoggedInName()
    }]

    useWarnOnLeave(shouldWarn);
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;

    const onCloseModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        setCardDetails(props.cardDetails);
    }, [props.cardDetails]);

    useEffect(() => {
        const verifyPayment = async (data, course) => {
            try {
                if (course && course?.uniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setModalTypeError(AppConstants.falseText);
                            setTransactionId(response.transactionId);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            enrolInCourse(course.courseId, course.courseTitle, course.uniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setEnrolmentMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setEnrolmentMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        if (paymentParam && cardDetails.length > 0) {
            const data = StringUtils.decodeBase64EncodedString(paymentParam);
            if (data !== null && data.category === props.category) {
                const course = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!course) {
                    setPayloadData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                data.learningActivityName = course.courseTitle;
                setPayloadData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setCourseTitle(course.courseTitle);
                setCourseId(course.courseId);

                const timer = setTimeout(() => {
                    verifyPayment(data, course);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }

    }, [paymentParam, cardDetails]);

    async function downloadCertificate(courseData, index) {
        const userQrenciaId = Auth.getLoggedInUserDetails().userId;
        setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
        const url = `${AppConstants.downloadCourseCertificateForStudentAPI}${courseData.uniqueId}${AppConstants.studentQrenciaIdParam}${userQrenciaId}`;
        const name = `${courseData.courseTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined) {
            if (response.ok && response.status === 200) {
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
            } else if (response.status === 409) {
                setShowModal(true);
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setModalMessage(ErrorMessages.certificateNotAvailableError);
            } else {
                setShowModal(true);
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            }
        } else {
            setShowModal(true);
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
            setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const enrolInCourse = async (courseId, courseTitle, courseUniqueId, status) => {
        const course = cardDetails.filter(card => card.uniqueId === courseUniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });

        const response = await enrolInLearningActivity({
            uniqueId: courseUniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.trackingType
        }, status);

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setEnrolmentMessage(response.message);
        setShowEnrolmentModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowEnrolmentModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowEnrolmentModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });


        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };


    const confirmBeforeEnrolment = ({ id, title, uniqueId, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, payOfflineFlag, creatorName, creatorRole, type: AppConstants.learningActivitiesTypes.course });
        if (payOfflineFlag === AppConstants.yText) {
            setShowOfflinePaymentModal(AppConstants.trueText);
        } else {
            enrolInCourse(id, title, uniqueId);
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const handleModalShow = () => {
        setShowEnrolmentModal(AppConstants.trueText);
    };

    const handleModalClose = () => {
        setShowEnrolmentModal(false);
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (transactionId) {
            window.location.reload();
        }
    };

    const openFeedbackModal = (e, learningActivity) => {
        e.stopPropagation();
        setSelectedLearningActivity({
            learningActivityUniqueId: learningActivity.uniqueId,
            userImagePath: learningActivity.userImagePath,
            organizedBy: learningActivity.organizedBy,
            learningActivityName: learningActivity.courseTitle
        })
        setShowFeedbackModal(AppConstants.trueText);
    }
    const closeFeedbackModal = () => {
        setSelectedLearningActivity({});
        setShowFeedbackModal(AppConstants.falseText);
    }

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={2} className="cardSection">
                {cardDetails?.length && cardDetails?.slice(0, cardCount).map((cardData, index) => (
                    <Col key={index}>
                        <div className={classes.card} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${cardData.skillCategorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.courseSlug}`)}>
                            <div className={classes.imageContainer}>
                                <img variant="top" className={classes.cardImage} src={cardData.courseThumbnailImageAbsolutePath || defaultThumbnail} alt="Course thumbnail" />
                            </div>
                            <div className={classes.cardBody}>
                                <div className={classes.cardTitle}>
                                    <Link className="cardLink" data-tooltip-id={`${cardData.courseSlug}-title`}>{cardData.courseTitle}</Link>
                                    <Tooltip className={classes.titleTooltipMessage} id={`${cardData.courseSlug}-title`}><span></span>{cardData.courseTitle}</Tooltip>
                                </div>

                                <p className={classes.activityTypeCourse}>{cardData.modeOfDelivery}</p>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</div>
                                {cardData.courseType === AppConstants.structuredCourseType && <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        Start:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(cardData.startTime)} {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span></div>
                                </div>}
                                {cardData.courseType === AppConstants.structuredCourseType && <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        End:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(cardData.endTime)} {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span></div>
                                </div>}
                                {cardData.courseType === AppConstants.flexibleCourseType && cardData.subscriptionEndDate !== AppConstants.nullText && <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment> at  <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment>  {cardData.preferredTimezone === AppConstants.utcTimezone && `(${cardData.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                {cardData.courseType === AppConstants.flexibleCourseType && cardData.disableEnrolmentFlag === AppConstants.nText && <div className={classes.cardTimeAndDate}>
                                    <div><BiWallet className="calendarIcon" />&nbsp;</div><div className={`${classes.courseDateTime} dateTimeText`}>
                                        <span className="competitionDateTimeFormat"> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: cardData.discount })} for {cardData.trackingType === AppConstants.classBasedTrackingText ? cardData.numberOfClasses : cardData.packageDuration} {cardData.trackingType === AppConstants.classBasedTrackingText ? "classes" : "Days"}</span></div>
                                </div>}
                                {cardData.courseType === AppConstants.flexibleCourseType && cardData.disableEnrolmentFlag === AppConstants.nText && <div className={classes.buttonSection}>
                                    <Button onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.courseId, title: cardData.courseTitle, uniqueId: cardData.uniqueId, creatorName: cardData.organizedBy, creatorRole: cardData.userRole, payOfflineFlag: cardData.payOfflineFlag }); }}>{AppConstants.renewPlanText}</Button>
                                </div>}
                                <div className={classes.iconSection}>
                                    {cardData.templateId !== 0 && cardData.templateId !== null && <div>
                                        {certificateSpinner[index] ? <BiLoaderAlt className="spinner" /> : <TbCertificate onClick={(e) => { stopPropagation(e); downloadCertificate(cardData, index) }} data-tooltip-place="top" data-tooltip-id="certificate" />}
                                    </div>}
                                    {cardData.feedbackCount > 0 && <LiaCommentsSolid onClick={(e) => { openFeedbackModal(e, cardData) }} data-tooltip-place="top" data-tooltip-id={`${cardData.id}-class-feedback`} />}
                                    {cardData.modeOfDelivery === AppConstants.competitionTypeOffline && cardData.address && <a onClick={stopPropagation} href={cardData.geoLocation} target={AppConstants.openInNewTab} className={classes.performanceReportLink}><MdOutlineShareLocation data-tooltip-place="top" data-tooltip-id={cardData.id} /></a>}
                                    <Tooltip className={classes.tooltipMessage} id="certificate"><span className={classes.tooltipMessage}>Download Certificate</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id="sessionLink"><span className={classes.tooltipMessage}>Session Link</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id={`${cardData.id}-class-feedback`}><span className={classes.tooltipMessage}>View Feedback</span></Tooltip>
                                    <Tooltip className={classes.tooltipMessage} id={cardData.id}><span className={classes.tooltipMessage}>{cardData.address}</span></Tooltip>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.myLearningCardCount)}>Load more</button>}
                </Col>
            </Row>
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            {showFeedbackModal && <CourseFeedbackModal learningActivityDetails={selectedLearningActivity} closeFeedbackModal={closeFeedbackModal} modalHeading="Class feedback" mode={AppConstants.actionModes.view} learnerDetails={learnerDetails} />}

            {(enrolmentMessage !== AppConstants.emptyString || paymentParam) && (showEnrolmentModal || paymentParam) && <EnrolmentModal onShow={handleModalShow} message={enrolmentMessage} modalTypeError={modalTypeError} onClose={handleModalClose} learningActivityId={courseId} learningActivityTitle={courseTitle} learningActivityType={AppConstants.learningActivitiesTypes.course} paymentStatus={paymentStatus} transactionId={transactionId} addClassFlag={AppConstants.trueText} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
}