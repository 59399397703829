import React, { useState, useEffect } from "react";
import classes from './AdminCreateQtalksPage.module.css';
import { Button, Row, Col } from "react-bootstrap";
import ReactQuill from 'react-quill';
import {DateTimeUtil} from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import 'react-quill/dist/quill.snow.css';
import { DataService } from '../../services/DataService';
import { BiLoaderAlt } from 'react-icons/bi';
import { ErrorSuccessAlertMessage } from '../../component/errorSuccessMessage/ErrorSuccessAlertMessage';
import validator from 'validator';
export const AdminCreateQtalksPage = () => {
    const formLabels = AppConstants.createQTalkFormLabels;
    const webBackgroundRef = React.useRef();
    const tabBackgroundRef = React.useRef();
    const mobileBackgroundRef = React.useRef();
    const thumbnailRef = React.useRef();
    const eventRef = React.useRef();
    const speakerProfileRef = React.useRef();
    const seoThumbnailRef = React.useRef();
    const [showSpinner, setShowSpinner] = useState(false);
    const [qrenciaSkillsDetails, setQrenciaSkillsDetails] = useState([]);
    const [selectedSkillCategory, setSelectedSkillCategory] = useState('');
    const [selectedSkill, setSelectedSkill] = useState('');
    const [skillsListForSelectedCategory, setSkillsListForSelectedCategory] = useState([]);

    //form inputs
    const [qtalkTitle, setQtalkTitle] = useState('');
    const [qtalkDate, setQtalkDate] = useState('');
    const [qtalkStartTime, setQtalkStartTime] = useState('');
    const [qtalkEndTime, setQtalkEndTime] = useState('');
    const [qtalkVideoLink, setQtalkVideoLink] = useState('');
    const [qtalkVideoId, setQtalkVideoId] = useState('');
    const [qtalkSkillCategory, setQtalkSkillCategory] = useState('');
    const [qtalkSkill, setQtalkSkill] = useState('');
    const [speakerName, setSpeakerName] = useState('');
    const [speakerDesignation, setSpeakerDesignation] = useState('');
    const [speakerFacebookProfileLink, setSpeakerFacebookProfileLink] = useState('');
    const [speakerTwitterProfileLink, setSpeakerTwitterProfileLink] = useState('');
    const [speakerInstagramProfileLink, setSpeakerInstagramProfileLink] = useState('');
    const [speakerLinkedInProfileLink, setSpeakerLinkedInProfileLink] = useState('');
    const [speakerYoutubeProfileLink, setSpeakerYoutubeProfileLink] = useState('');
    const [speakerWebsiteProfileLink, setSpeakerWebsiteProfileLink] = useState('');
    const [qtalkDescription, setQtalkDescription] = useState('');
    const [webBackgroundImageFile, setWebBackgroundImageFile] = useState('');
    const [tabBackgroundImageFile, setTabBackgroundImageFile] = useState('');
    const [mobileBackgroundImageFile, setMobileBackgroundImageFile] = useState('');
    const [eventImageFile, setEventImageFile] = useState('');
    const [thumbnailImageFile, setThumbnailImageFile] = useState('');
    const [speakerKeyHighlights, setSpeakerKeyHighlights] = useState('');
    const [qtalkKeyHighlights, setQtalkKeyHighlights] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoThumbnailImageFile, setSeoThumbnailImageFile] = useState('');
    const [speakerProfileImageFile, setSpeakerProfileImageFile] = useState('');
    //error messages
    const [errorSuccessAlertVisiblity, setErrorSuccessAlertVisiblity] = useState(false);
    const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState('');
    const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState('');
    const [validFormDetailsFlag, setValidFormDetailsFlag] = useState(true);
    const [validSubmit, setValidSubmit] = useState(false);
    //form elements valid checks
    const [qtalkTitleValid, setQtalkTitleValid] = useState(true);
    const [webBackgroundImageValid, setWebBackgroundImageValid] = useState(true);
    const [tabBackgroundImageValid, setTabBackgroundImageValid] = useState(true);
    const [mobileBackgroundImageValid, setMobileBackgroundImageValid] = useState(true);
    const [eventImageValid, setEventImageValid] = useState(true);
    const [thumbnailImageValid, setThumbnailImageValid] = useState(true);
    const [dateValid, setDateValid] = useState(true);
    const [startTimeValid, setStartTimeValid] = useState(true);
    const [endTimeValid, setEndTimeValid] = useState(true);
    const [youtubeVideoLinkValid, setYoutubeVideoLinkValid] = useState(true);
    const [youtubeVideoIdValid, setYoutubeVideoIdValid] = useState(true);
    const [skillCategoryValid, setSkillCategoryValid] = useState(true);
    const [skillValid, setSkillValid] = useState(true);
    const [descriptionValid, setDescriptionValid] = useState(true);
    const [keyHighlightsValid, setKeyHighlightsValid] = useState(true);
    const [speakerKeyHighlightsValid, setSpeakerKeyHighlightsValid] = useState(true);
    const [speakerNameValid, setSpeakerNameValid] = useState(true);
    const [speakerDesignationValid, setSpeakerDesignationValid] = useState(true);
    const [speakerProfileImageValid, setSpeakerProfileImageValid] = useState(true);
    const [seoTitleValid, setSeoTitleValid] = useState(true);
    const [seoThumbnailImageValid, setSeoThumbnailImageValid] = useState(true);
    const [seoDescriptionValid, setSeoDescriptionValid] = useState(true);
    //file path storage 
    const [webBackgroundImageFilePath, setWebBackgroundImageFilePath] = useState('');
    const [tabBackgroundImageFilePath, setTabBackgroundImageFilePath] = useState('');
    const [mobileBackgroundImageFilePath, setMobileBackgroundImageFilePath] = useState('');
    const [thumbnailImageFilePath, setThumbnailImageFilePath] = useState('');
    const [eventImageFilePath, setEventImageFilePath] = useState('');
    const [seoThumbnailImageFilePath, setSeoThumbnailImageFilePath] = useState('');
    const [speakerProfileImageFilePath, setSpeakerProfileImageFilePath] = useState('');

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setQrenciaSkillsDetails(response);
        }
    }, []);

    var data = new Map();
    for (let obj of qrenciaSkillsDetails) {
        data.set(obj.categoryId, obj);
    }
    var categoryList = [...data.values()];

    const submitSelectedSkillCategory = (event) => {
        setQtalkSkill("");
        setSelectedSkillCategory(event.target.value);
        fetchSkills(event.target.value);
    }

    const fetchSkills = (selectedCategoryId) => {
        setSkillsListForSelectedCategory(qrenciaSkillsDetails.filter((item) => item.categoryId === selectedCategoryId));
    }


    useEffect(() => {
        const quillEmptyCheckRegex = /<(.|\n)*?>/g;
        const uploadHandler = (e) => {
            if (webBackgroundImageFile != "") {
                setWebBackgroundImageValid(true);
            }
            if (tabBackgroundImageFile != "") {
                setTabBackgroundImageValid(true);
            }
            if (mobileBackgroundImageFile != "") {
                setMobileBackgroundImageValid(true);
            }
            if (thumbnailImageFile != "") {
                setThumbnailImageValid(true);
            }
            if (eventImageFile != "") {
                setEventImageValid(true);
            }
            if (qtalkTitle.trim() != null) {
                setQtalkTitleValid(true);
            }
            if (qtalkDate != null) {
                setDateValid(true);
            }
            if (qtalkStartTime != null) {
                setStartTimeValid(true);
            }
            if (qtalkEndTime != null) {
                setEndTimeValid(true);
            }
            if (validator.isURL(qtalkVideoLink)) {
                setYoutubeVideoLinkValid(true);
            }
            if (qtalkVideoId.trim() != null) {
                setYoutubeVideoIdValid(true);
            }
            if (qtalkSkillCategory != "") {
                setSkillCategoryValid(true);
            }
            if (qtalkSkill != "") {
                setSkillValid(true);
            }
            if (qtalkDescription.trim() != null) {
                setDescriptionValid(true);
            }
            if (speakerName.trim() != null) {
                setSpeakerNameValid(true);
            }
            if (speakerDesignation.trim() != null) {
                setSpeakerDesignationValid(true);
            }
            if (speakerProfileImageFile != "") {
                setSpeakerProfileImageValid(true);
            }
            if (qtalkKeyHighlights.replace(quillEmptyCheckRegex, '').trim().length != 0) {
                setKeyHighlightsValid(true);
            }
            if (speakerKeyHighlights.replace(quillEmptyCheckRegex, '').trim().length != 0) {
                setSpeakerKeyHighlightsValid(true);
            }
            if (seoTitle.trim() != null) {
                setSeoTitleValid(true);
            }
            if (seoThumbnailImageFile != "") {
                setSeoThumbnailImageValid(true);
            }
            if (seoDescription.trim() != null) {
                setSeoDescriptionValid(true);
            }
        }
        uploadHandler();
    }, [webBackgroundImageFile, tabBackgroundImageFile, mobileBackgroundImageFile, thumbnailImageFile, eventImageFile, qtalkTitle, qtalkDate, qtalkStartTime, qtalkEndTime, qtalkVideoLink, qtalkVideoId, qtalkSkillCategory, qtalkSkill, qtalkDescription, speakerName, speakerDesignation, qtalkKeyHighlights, speakerKeyHighlights, seoTitle, seoDescription, seoThumbnailImageFile, speakerProfileImageFile]);

    function hideErrorMessage() {
        let timer = setTimeout(() => setErrorSuccessAlertVisiblity(false), 7000);
        return () => {
            clearTimeout(timer)
        }
    }

    const createQtalk = (e) => {
        e.preventDefault();
        setValidFormDetailsFlag(true);
        setValidSubmit(false);
        const quillEmptyCheckRegex = /<(.|\n)*?>/g;
        const checkValidUrlRegex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        setShowSpinner(true);
        setErrorSuccessAlertVisiblity(false);
        if (qtalkTitle.trim() == "") {
            setQtalkTitleValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkTitle);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (webBackgroundImageFile === "") {
            setWebBackgroundImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkWebFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (tabBackgroundImageFile == "") {
            setTabBackgroundImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkTabFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (mobileBackgroundImageFile == "") {
            setMobileBackgroundImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkMobileFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (thumbnailImageFile == "") {
            setThumbnailImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkThumbnailFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (eventImageFile == "") {
            setEventImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkEventFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkDate == "") {
            setDateValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkDate);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkStartTime == "") {
            setStartTimeValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkStartTime);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkEndTime == "") {
            setEndTimeValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkEndTime);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (!validator.isURL(qtalkVideoLink)) {
            setYoutubeVideoLinkValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkVideoLink);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkVideoId.trim() == "") {
            setYoutubeVideoIdValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkVideoId);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkSkillCategory == "") {
            setSkillCategoryValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkSkillCategory);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkSkill.trim() == "" || qtalkSkill === "") {
            setSkillValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkSkill);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (qtalkDescription.trim() == "") {
            setDescriptionValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkDescription);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            return null;
        }
        if (qtalkKeyHighlights.replace(quillEmptyCheckRegex, '').trim().length === 0) {
            setKeyHighlightsValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkKeyHighlight);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (speakerName.trim() == "") {
            setSpeakerNameValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSpeakerName);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (speakerDesignation.trim() == "") {
            setSpeakerDesignationValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSpeakerDesignation);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (speakerProfileImageFile == "") {
            setSpeakerProfileImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSpeakerProfileFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (speakerKeyHighlights.replace(quillEmptyCheckRegex, '').trim().length === 0) {
            setSpeakerKeyHighlightsValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSpeakerHighlight);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            setValidFormDetailsFlag(false);
            hideErrorMessage();
            return null;
        }
        if (seoTitle.trim() == "") {
            setSeoTitleValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSeoTitle);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (seoThumbnailImageFile == "") {
            setSeoThumbnailImageValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSeoThumbnailFile);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            setValidFormDetailsFlag(false);
            return null;
        }
        if (seoDescription.trim() == "") {
            setSeoDescriptionValid(false);
            setShowSpinner(false);
            setErrorSuccessAlertMessage(ErrorMessages.invalidSeoDescription);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            setErrorSuccessAlertVisiblity(true);
            hideErrorMessage();
            return null;
        }

        if (validFormDetailsFlag) {
            try {
                var uploadFiles = async () => {
                    
                    const webBackgroundImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(webBackgroundImageFile,AppConstants.qtlaksBackgroundImageWebS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const tabBackgroundImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(tabBackgroundImageFile,AppConstants.qtlaksBackgroundImageTabS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const mobileBackgroundImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(mobileBackgroundImageFile,AppConstants.qtlaksBackgroundImageMobileS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const thumbnailImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(thumbnailImageFile,AppConstants.qtlaksThumbnailS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const eventImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(eventImageFile,AppConstants.qtlaksEventS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const seoThumbnailImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(seoThumbnailImageFile,AppConstants.qtalksSeoS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    const speakerProfileImageUpload = await FileOperationsUtil.uploadFileThroughPreSignedURL(speakerProfileImageFile,AppConstants.qtalksSpeakerImageS3Path,AppConstants.s3filePermissionPublic,AppConstants.s3FileContentDispositionInline);
                    setWebBackgroundImageFilePath(webBackgroundImageUpload.objectKey);
                    setTabBackgroundImageFilePath(tabBackgroundImageUpload.objectKey);
                    setMobileBackgroundImageFilePath(mobileBackgroundImageUpload.objectKey);
                    setThumbnailImageFilePath(thumbnailImageUpload.objectKey);
                    setEventImageFilePath(eventImageUpload.objectKey);
                    setSeoThumbnailImageFilePath(seoThumbnailImageUpload.objectKey);
                    setSpeakerProfileImageFilePath(speakerProfileImageUpload.objectKey);
                    setValidSubmit(true);
                }
                uploadFiles();
            }
            catch (error) {
                setShowSpinner(false);
                setErrorSuccessAlertMessage(ErrorMessages.fileUploadError);
                setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                setErrorSuccessAlertVisiblity(true);
                setValidSubmit(false);
                hideErrorMessage();
                return null;
            }
        }
    }
    //create qtalk request
    useEffect(() => {
        if (validSubmit) {
            //conversion of date time to utc
            const start_date_time = new Date(qtalkDate + " " + qtalkStartTime);
            const end_date_time = new Date(qtalkDate + " " + qtalkEndTime);
            const start_date_utc = DateTimeUtil.convertToUTC(start_date_time);
            const end_date_utc = DateTimeUtil.convertToUTC(end_date_time);
            const firstQuoteIndex = qtalkTitle.indexOf("'");
            const lastQuoteIndex = qtalkTitle.lastIndexOf("'");
            //adding strong tag before and after single quotes
            const qTalkFormattedTitle = qtalkTitle.substring(0, firstQuoteIndex)
                + "<strong>"
                + qtalkTitle.substring(firstQuoteIndex, lastQuoteIndex + 1)
                + "</strong>"
                + qtalkTitle.substring(lastQuoteIndex + 1);

            const qtalkData = {
                "qtalkTitle": qTalkFormattedTitle,
                "qtalkDescription": qtalkDescription,
                "qtalkWebBackgroungImagePath": webBackgroundImageFilePath,
                "qtalkTabletBackgroungImagePath": tabBackgroundImageFilePath,
                "qtalkMobileBackgroungImagePath": mobileBackgroundImageFilePath,
                "qtalkThumbnailImagePath": thumbnailImageFilePath,
                "qtalkYouTubeVideoLinkId": qtalkVideoId,
                "qtalkYouTubeVideoLink": qtalkVideoLink,
                "skillId": 4,
                "qtalkDate": start_date_utc.split('T')[0],
                "qtalkStartTime": start_date_utc.split('T')[1].split('.000Z')[0],
                "qtalkEndTime": end_date_utc.split('T')[1].split('.000Z')[0],
                "seoTitle": seoTitle,
                "seoDescription": seoDescription,
                "seoThumbnailImagePath": seoThumbnailImageFilePath,
                "eventImagePath": eventImageFilePath,
                "qtalkKeyHighlights": qtalkKeyHighlights,
                "qtalkSpeakersDetails": [{
                    "speakerName": speakerName,
                    "speakerDesignation": speakerDesignation,
                    "aboutSpeaker": speakerKeyHighlights,
                    "speakerImagePath": speakerProfileImageFilePath,
                    "speakerLinkedInLink": speakerLinkedInProfileLink,
                    "speakerFacebookLink": speakerFacebookProfileLink,
                    "speakerInstagramLink": speakerInstagramProfileLink,
                    "speakerTwitterLink": speakerTwitterProfileLink,
                    "speakerYoutubeLink": speakerYoutubeProfileLink,
                    "speakerWebsiteLink": speakerWebsiteProfileLink
                }]
            };
            async function createQtalkRequest() {
                setShowSpinner(true);
                const url = AppConstants.qtalksPostAPI;
                const data = qtalkData;
                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (!response.ok) {
                    if (response.status === 409) {
                        setShowSpinner(false);
                        setErrorSuccessAlertMessage(ErrorMessages.invalidQtalkDateTime);
                        setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                        setErrorSuccessAlertVisiblity(true);
                        hideErrorMessage();
                    }
                    else if (response.status === 500) {
                        setShowSpinner(false);
                        setErrorSuccessAlertMessage(ErrorMessages.qtalkInternalServerError);
                        setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                        setErrorSuccessAlertVisiblity(true);
                        hideErrorMessage();
                    }
                    else {
                        setShowSpinner(false);
                        setErrorSuccessAlertMessage(ErrorMessages.qtalkInternalServerError);
                        setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                        setErrorSuccessAlertVisiblity(true);
                        hideErrorMessage();
                    }
                }
                else if (response.ok && response.status === 201) {
                    setShowSpinner(false);
                    setErrorSuccessAlertMessage(ErrorMessages.qtalkCreatedSuccessfully);
                    setErrorSuccessAlertVarient(AppConstants.alertVarient[0]);
                    setErrorSuccessAlertVisiblity(true);
                    hideErrorMessage();
                    setQtalkTitle('');
                    setQtalkDate('');
                    setQtalkStartTime('');
                    setQtalkEndTime('');
                    setQtalkVideoLink('');
                    setQtalkVideoId('');
                    setQtalkSkillCategory('');
                    setQtalkSkill('');
                    setSpeakerName('');
                    setSpeakerDesignation('');
                    setSpeakerFacebookProfileLink('');
                    setSpeakerTwitterProfileLink('');
                    setSpeakerInstagramProfileLink('');
                    setSpeakerLinkedInProfileLink('');
                    setSpeakerYoutubeProfileLink('');
                    setSpeakerWebsiteProfileLink('');
                    setQtalkDescription('');
                    setWebBackgroundImageFile('');
                    setTabBackgroundImageFile('');
                    setMobileBackgroundImageFile('');
                    setEventImageFile('');
                    setThumbnailImageFile('');
                    setSpeakerKeyHighlights('');
                    setQtalkKeyHighlights('');
                    setSeoTitle('');
                    setSeoDescription('');
                    setSeoThumbnailImageFile('');
                    setSpeakerProfileImageFile('');
                    webBackgroundRef.current.value = "";
                    tabBackgroundRef.current.value = "";
                    mobileBackgroundRef.current.value = "";
                    thumbnailRef.current.value = "";
                    eventRef.current.value = "";
                    speakerProfileRef.current.value = "";
                    seoThumbnailRef.current.value = "";
                    setValidSubmit(false);
                }
                return null;
            };
            createQtalkRequest();
        }
    }, [validSubmit, webBackgroundImageFilePath, tabBackgroundImageFilePath, mobileBackgroundImageFilePath,
        thumbnailImageFilePath, eventImageFilePath, speakerProfileImageFilePath]);

    return (
        <>
            <div className="adminContentSection">
                <div className={classes.contentSection}>
                    <h1 className='templateDivHeading'>Create Qtalk</h1>
                    <div className="formContainer">
                        <form id="createQtalkForm" onSubmit={createQtalk}>
                            <Row className="mb-2" >
                                <h1 className="formHeading">Qtalk Details</h1>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel" >{formLabels.title}<span className="redAstrisk">*</span></label>
                                    <input name="qtalkTitle" value={qtalkTitle} minLength={0} maxLength={60} className={`${['formInputBox mb-2']} ${!qtalkTitleValid && ['invalid']}`} onChange={(e) => setQtalkTitle(e.target.value)} type="text" placeholder="Enter Title"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.webBackgroundImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" accept=".png,.jpeg" className={`${['formInputBox mb-2']} ${!webBackgroundImageValid && ['invalid']}`} onChange={(e) => setWebBackgroundImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.tabBackgroundImage}<span className="redAstrisk">*</span></label>
                                    <input type="file"  className={`${['formInputBox mb-2']} ${!tabBackgroundImageValid && ['invalid']}`} accept=".png,.jpeg" onChange={(e) => setTabBackgroundImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.mobileBackgroundImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" className={`${['formInputBox mb-2']} ${!mobileBackgroundImageValid && ['invalid']}`} accept=".png,.jpeg" onChange={(e) => setMobileBackgroundImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.thumbnailImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" className={`${['formInputBox mb-2']} ${!thumbnailImageValid && ['invalid']}`} accept=".png,.jpeg" onChange={(e) => setThumbnailImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.eventImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" className={`${['formInputBox mb-2']} ${!eventImageValid && ['invalid']}`} accept=".png,.jpeg" onChange={(e) => setEventImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.date}<span className="redAstrisk">*</span></label>
                                    <input type="date" name="qtalkDate" value={qtalkDate} className={`${['formInputBox mb-2']} ${!dateValid && ['invalid']}`} onChange={(e) => setQtalkDate(e.target.value)}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.startTime}<span className="redAstrisk">*</span></label>
                                    <input type="time" name="qtalkStartTime" value={qtalkStartTime} className={`${['formInputBox mb-2']} ${!startTimeValid && ['invalid']}`} onChange={(e) => setQtalkStartTime(e.target.value)}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.endTime}<span className="redAstrisk">*</span></label>
                                    <input type="time" name="qtalkEndTime" value={qtalkEndTime} className={`${['formInputBox mb-2']} ${!endTimeValid && ['invalid']}`} onChange={(e) => setQtalkEndTime(e.target.value)}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.youtubeVideoLink}<span className="redAstrisk">*</span></label>
                                    <input type="text" name="qtalkVideoLink" value={qtalkVideoLink} className={`${['formInputBox mb-2']} ${!youtubeVideoLinkValid && ['invalid']}`} onChange={(e) => setQtalkVideoLink(e.target.value)} placeholder="Enter Youtube Video Link"  ></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.youtubeVideoId}<span className="redAstrisk">*</span></label>
                                    <input type="text" name="qtalkVideoId" value={qtalkVideoId} className={`${['formInputBox mb-2']} ${!youtubeVideoIdValid && ['invalid']}`} onChange={(e) => setQtalkVideoId(e.target.value)} placeholder="Enter Youtube Video Id"  ></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.skillCategory}<span className="redAstrisk">*</span></label>
                                    <select className={`${['formInputBox mb-2']} ${!skillCategoryValid && ['invalid']}`} onChange={e => { setQtalkSkillCategory(e.target.value); submitSelectedSkillCategory(e) }} value={qtalkSkillCategory} name="qtalkSkillCategory"  >
                                        <option value="" defaultValue>Select Skill Category</option>
                                        {categoryList.filter((data) => data.categoryActiveFlg === AppConstants.trueFlag).map((category) => (
                                            <option value={category.categoryId} key={category.categoryId}>{category.category}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.skill}<span className="redAstrisk">*</span></label>
                                    <select className={`${['formInputBox mb-2']} ${!skillValid && ['invalid']}`} value={qtalkSkill} name="qtalkSkill" onChange={(e) => setQtalkSkill(e.target.value)}>
                                        <option value="" defaultValue>Select Skill</option>
                                        {skillsListForSelectedCategory.filter((data) => data.skillActiveFlg === AppConstants.trueFlag).map((skill) => (
                                            <option value={skill.skillId} key={skill.skillId}>{skill.skill}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={8}>
                                    <label className="formLabel">{formLabels.description}<span className="redAstrisk">*</span></label>
                                    <textarea name="qtalkDescription" value={qtalkDescription} rows="3" type="text" minLength="3" maxLength="150" className={`${['formInputBox mb-2']} ${!descriptionValid && ['invalid']}`} onChange={(e) => setQtalkDescription(e.target.value)} placeholder="Enter Description"></textarea>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                    <label className="formLabel">{formLabels.keyHighlights}<span className="redAstrisk">*</span></label>
                                    <ReactQuill className={`${['formTextEditorInput']} ${!keyHighlightsValid && ['invalid']}`} theme="snow" value={qtalkKeyHighlights} placeholder="Enter Key Highlights" onChange={(e) => setQtalkKeyHighlights(e)} />
                                </Col>
                            </Row>
                            <Row className="mb-2 mt-4" >
                                <h1 className="formHeading">Qtalk Speaker Details</h1>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerName}<span className="redAstrisk">*</span></label>
                                    <input name="speakerName" value={speakerName} className={`${['formInputBox mb-2']} ${!speakerNameValid && ['invalid']}`} onChange={(e) => setSpeakerName(e.target.value)} type="text" placeholder="Enter Name"  ></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerDesignation}<span className="redAstrisk">*</span></label>
                                    <input name="speakerDesignation" value={speakerDesignation} className={`${['formInputBox mb-2']} ${!speakerDesignationValid && ['invalid']}`} onChange={(e) => setSpeakerDesignation(e.target.value)} type="text" placeholder="Enter Designation"  ></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerProfileImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" accept=".png,.jpeg" className={`${['formInputBox mb-2']} ${!speakerProfileImageValid && ['invalid']}`} onChange={(e) => setSpeakerProfileImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerFacebookProfileLink}</label>
                                    <input type="url" name="speakerFacebookProfileLink" value={speakerFacebookProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerFacebookProfileLink(e.target.value)} placeholder="Enter Facebook Profile Link"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerTwitterProfileLink}</label>
                                    <input type="url" name="speakerTwitterProfileLink" value={speakerTwitterProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerTwitterProfileLink(e.target.value)} placeholder="Enter Facebook Twitter Link"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerInstagramProfileLink}</label>
                                    <input type="url" name="qtalkVideoLink" value={speakerInstagramProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerInstagramProfileLink(e.target.value)} placeholder="Enter Instagram Profile Link"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerLinkedInProfileLink}</label>
                                    <input type="url" name="speakerLinkedInProfileLink" value={speakerLinkedInProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerLinkedInProfileLink(e.target.value)} placeholder="Enter LinkedIn Profile Link"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerYoutubeProfileLink}</label>
                                    <input type="url" name="speakerYoutubeProfileLink" value={speakerYoutubeProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerYoutubeProfileLink(e.target.value)} placeholder="Enter Youtube Profile Link"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.speakerWebsiteProfileLink}</label>
                                    <input type="url" name="speakerWebsiteProfileLink" value={speakerWebsiteProfileLink} className="formInputBox mb-2" onChange={(e) => setSpeakerWebsiteProfileLink(e.target.value)} placeholder="Enter Website Link"></input>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                    <label className="formLabel">{formLabels.speakerHighlightPoints}<span className="redAstrisk">*</span></label>
                                    <ReactQuill className={`${['formTextEditorInput']} ${!speakerKeyHighlightsValid && ['invalid']}`} theme="snow" value={speakerKeyHighlights} onChange={(e) => setSpeakerKeyHighlights(e)} placeholder="Enter Highlights" />
                                </Col>
                            </Row>
                            <Row className="mb-2 mt-4" >
                                <h1 className="formHeading">Qtalk SEO Details</h1>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.title}<span className="redAstrisk">*</span></label>
                                    <input name="seoTitle" value={seoTitle} minLength={0} maxLength={60} className={`${['formInputBox mb-2']} ${!seoTitleValid && ['invalid']}`} onChange={(e) => setSeoTitle(e.target.value)} type="text" placeholder="Enter Title"></input>
                                </Col>
                                <Col sm={12} md={6} lg={4}>
                                    <label className="formLabel">{formLabels.thumbnailImage}<span className="redAstrisk">*</span></label>
                                    <input type="file" accept=".png,.jpeg" className={`${['formInputBox mb-2']} ${!seoThumbnailImageValid && ['invalid']}`} onChange={(e) => setSeoThumbnailImageFile(e.target.files[0])}></input>
                                </Col>
                                <Col sm={12} md={12} lg={8}>
                                    <label className="formLabel">{formLabels.description}<span className="redAstrisk">*</span></label>
                                    <textarea name="seoDescription" value={seoDescription} rows="3" type="text" minLength="3" maxLength="150" className={`${['formInputBox mb-2']} ${!seoDescriptionValid && ['invalid']}`} onChange={(e) => setSeoDescription(e.target.value)} placeholder="Enter Description"></textarea>
                                </Col>
                            </Row>
                            <Row className={classes.formSubmitButtonSection}>
                                {showSpinner && <Button type="submit" className={classes.formSubmitButton}>{AppConstants.publishText} <BiLoaderAlt className={classes.spinner} /></Button>}
                                {!showSpinner && <Button type="submit" className={classes.formSubmitButton}>{AppConstants.publishText}</Button>}
                            </Row>
                            {errorSuccessAlertVisiblity &&
                                <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}