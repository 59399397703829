import { Typeahead } from "react-bootstrap-typeahead";
import classes from "./LearningActivityListDropdown.module.css";
import { useEffect, useRef, useState } from "react";
import { MdClear } from "react-icons/md";
import { Button } from "react-bootstrap";

export const LearningActivityListDropdown = ({ placeholder, label, labelKey, handleSelect, learningActivityList, filterByKeys, selectedLearningActivity }) => {
    const typeaheadRef = useRef(null);
    const [learningActivity, setLearningActivity] = useState({});

    useEffect(() => {
        if (selectedLearningActivity?.learningActivityUniqueId) {
            setLearningActivity(selectedLearningActivity);
            handleSelect(selectedLearningActivity);
        }
    }, [selectedLearningActivity]);

    const handleChange = (value) => {
        if (value[0]?.learningActivityUniqueId) {
            setLearningActivity(value[0])
        } else {
            setLearningActivity({})
        }
    };

    const clearTypeaheadValue = () => {
        typeaheadRef?.current?.clear();
        handleChange([]);
        handleSelect([]);
    };

    const getSelectedLearningActivity = () => {
        if (learningActivity.learningActivityUniqueId) {
            return [{ ...learningActivity }]
        } else return [];
    };

    return (
        <div className={`SuggestiveCompetitionSearch ${classes.searchContainer}`}>
            <div className="SuggestiveCompetitionSearchInput">
                <label className="suggestedSearchLabel">{label}</label>
                <div className="suggestiveSearchTypeaheadContainer">
                    <Typeahead
                        ref={typeaheadRef}
                        className="SuggestedSearchInputBox"
                        placeholder={placeholder}
                        name="suggestedCompetitionSearch"
                        id="suggestedCompetitionSearch"
                        labelKey={labelKey}
                        filterBy={filterByKeys}
                        onChange={handleChange}
                        options={learningActivityList}
                        selected={getSelectedLearningActivity()}
                    />
                    {<MdClear className="suggestedSearchClearIcon" onClick={clearTypeaheadValue} />}
                </div>
            </div>
            <Button onClick={() => handleSelect(learningActivity)} className={classes.selectButton}>Select</Button>
        </div>
    )
}