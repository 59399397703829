import { useCallback, useEffect, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { AgGridReact } from "ag-grid-react";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { Button, Card, Col, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { MathUtil } from "../../../utils/MathUtil";
import { FaFilter } from "react-icons/fa6";
import { BiLoaderAlt } from "react-icons/bi";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import moment from "moment";
import { DataService } from "../../../services/DataService";

export const LearnersOnQrenciaStats = () => {
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [learnerDetails, setLearnerDetails] = useState([]);
    const [downloadSpinner, setDownloadSpinner] = useState(AppConstants.falseText);
    const [downloadError, setDownloadError] = useState(AppConstants.emptyString);
    const [fromDate, setFromDate] = useState(AppConstants.emptyString);
    const [toDate, setToDate] = useState(AppConstants.emptyString);
    const [ageGroupArray, setAgeGroupArray] = useState([]);
    const [qrenciaLearnersStats, setQrenciaLearnersStats] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const constructURL = (url, fromDateString, toDateString) => {
        if (fromDateString) {
            url = `${url}?${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDateString)}`
        }
        if (toDateString && !fromDateString) {
            url = `${url}?${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        } else if (toDateString && fromDateString) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        }
        if (fromDateString || toDateString) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        return url;
    };

    const fetchQrenciaLearnerDetails = useCallback(async (fromDateString, toDateString) => {
        let url = constructURL(AppConstants.getQrenciaLearnersForAdminAPI, fromDateString, toDateString);

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setLearnerDetails(response);
        } else {
            setLearnerDetails([]);
        }
    }, []);

    const fetchQrenciaLearnersStats = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getQrenciaStatsForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getQrenciaStatsForAdminAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setQrenciaLearnersStats(response);
        } else {
            setQrenciaLearnersStats({});
        }
    }, []);

    const fetchLearnerAgeGroup = useCallback(async () => {
        const url = AppConstants.fetchConfigurableKeysAPI;
        const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response) {
            let ageGroups = response.find(item => item.keyName === AppConstants.adminLearnerAgeGroupDisplay).keyValue
            setAgeGroupArray(ageGroups.split(AppConstants.comma));
        }

    }, []);

    useEffect(() => {
        fetchQrenciaLearnerDetails();
        fetchQrenciaLearnersStats();
        fetchLearnerAgeGroup();
    }, [fetchQrenciaLearnerDetails, fetchQrenciaLearnersStats, fetchLearnerAgeGroup]);


    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        setFromDate(fromDateString);
        setToDate(toDateString);
        fetchQrenciaLearnersStats(fromDateString, toDateString);
        fetchQrenciaLearnerDetails(fromDateString, toDateString);
    };

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    };

    const downloadStudentList = async () => {
        setDownloadSpinner(AppConstants.trueText);
        let url = constructURL(AppConstants.downloadQrenciaActiveLearnersAPI, fromDate, toDate);

        const name = AppConstants.qrenciaActiveLearnersFileName;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadSpinner(AppConstants.falseText);
            } else {
                setDownloadSpinner(AppConstants.falseText);
                setDownloadError(ErrorMessages.adminDownloadActiveLernersDetailsError);
                let timer = setTimeout(() => {
                    setDownloadError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadSpinner(AppConstants.falseText);
            setDownloadError(ErrorMessages.adminDownloadActiveLernersDetailsError);
            let timer = setTimeout(() => {
                setDownloadError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const getAgeGroupHeading = (ageGroup) => {
        let values = ageGroup?.split(AppConstants.hyphen);
        if (values[0] === "min") {
            return `Below ${parseInt(values[1]) + 1} Years`;
        } else if (values[1] === "max") {
            return `Above ${parseInt(values[0]) - 1} Years`;
        } else {
            return `${ageGroup} Years`
        }
    };

    const getAgeGroupValue = (ageGroup) => {
        let values = ageGroup?.split(AppConstants.hyphen);
        if (values[0] === "min") {
            return learnerDetails.filter(learner => (learner.studentAge <= parseInt(values[1]))).length;
        } else if (values[1] === "max") {
            return learnerDetails.filter(learner => (learner.studentAge >= parseInt(values[0]))).length;
        } else {
            return learnerDetails.filter(learner => (learner.studentAge >= parseInt(values[0]) && learner.studentAge <= parseInt(values[1]))).length;
        }
    };

    const getBackgroundColor = (idx) => {
        return `ageGroupCard_${idx}`
    }
    const learningActivityColumnDefs = [
        {
            headerName: `Learner ID`,
            field: "studentQrenciaId",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentQrenciaId}`,
            valueGetter: (params) => {
                return params.data.studentQrenciaId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Name`,
            field: "studentName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentName}`,
            valueGetter: (params) => {
                return `${params.data.studentName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Age`,
            field: "studentAge",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentAge}`,
            valueGetter: (params) => {
                return `${params.data.studentAge}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Email`,
            field: "studentEmail",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentEmail}`,
            valueGetter: (params) => {
                return `${params.data.studentEmail}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Phone`,
            field: "studentPhoneNumber",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentPhoneNumber}`,
            valueGetter: (params) => {
                return `${params.data.studentPhoneNumber}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Registered On`,
            field: "studentRegisteredOn",
            width: 300,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.studentRegisteredOn).tz(timeZone).format('HHD MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.studentRegisteredOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Last Login`,
            field: "lastLoginOn",
            width: 300,
            tooltipValueGetter: ({ data }) => data.lastLoginOn ? `${moment.utc(data.lastLoginOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "Never",
            valueGetter: ({ data }) => {
                return data.lastLoginOn ? `${moment.utc(data.lastLoginOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "Never"
            },
            filter: 'agTextColumnFilter'
        }
    ];

    return (
        <div>
            <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
            {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
            <Row>
                <div className={`${dashboardClass.dashboardCards} mb-2`}>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.feeCollectedCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Total Learners</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaLearnersStats.registeredStudents)} </Card.Text>
                        </Card.Body>
                    </Card>
                    {ageGroupArray.length > 0 && ageGroupArray?.map((group, idx) => {
                        return <>
                            <Card className={`${dashboardClass.countCards} ${dashboardClass.ageGroupCard} ${dashboardClass[getBackgroundColor(idx)]}`}>
                                <Card.Body>
                                    <Card.Title className={dashboardClass.cardTitle}>{getAgeGroupHeading(group)}</Card.Title>
                                    <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(getAgeGroupValue(group))} </Card.Text>
                                </Card.Body>
                            </Card>
                        </>
                    })}
                </div>
            </Row>
            <Row className="mt-4">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            className="expertObrDetailsGrid"
                            columnDefs={learningActivityColumnDefs}
                            pagination={true}
                            paginationPageSize={AppConstants.adminDashboardPaginationSize}
                            rowData={learnerDetails}
                            enableBrowserTooltips={true}
                            domLayout='autoHeight'
                            suppressRowHoverHighlight={true}
                            defaultColDef={{
                                filter: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                resizable: true,
                                suppressMenu: true,
                                suppressHorizontalScroll: true,
                                floatingFilterComponentParams: { suppressFilterButton: true },
                                floatingFilter: true,
                            }}
                            enableFilter={true}
                            enableSorting={true}
                            enableSearching={true}
                            headerClass={classes.obrGridHeader}
                            rowClass={classes.obrGridTable}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <div>
                    {learnerDetails.length > 0 && <Button className={classes.downloadButton} onClick={downloadStudentList}>Download {downloadSpinner && <BiLoaderAlt className="spinner" />} </Button>}
                </div>
                {downloadError && <ErrorSuccessAlertMessage message={downloadError} varient={AppConstants.alertVarient[1]} />}
            </Row>
        </div>
    )
}