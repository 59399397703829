import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import classes from './LandingPageSkillCards.module.css';

export const LandingPageSkillCards = (props) => {
  const [cardCount, setCardCount] = useState(4);
  return (
    <>
      <Row xs={2} sm={2} md={3} lg={4} xl={4} className={classes.skillsCardsSection}>
        {props.skillsOfCategory.slice(0, cardCount).map((skillsCards) => (
          <Col key={skillsCards.skillId}>
            <Card className={classes.skillsCard}>
              <Link to={`${AppConstants.skills}${AppConstants.forwardSlash}${skillsCards.categorySlug}${AppConstants.forwardSlash}${skillsCards.skillSlug}`} state={skillsCards}>
                <Card.Img variant="top" src={skillsCards.skillthumbnailImage} className={classes.skillthumbnailImage} />
                <Card.Body className={classes.cardBody}>
                  {/* <Card.Title className={classes.ageRange}>
                    {skillsCards.skillMaxAge!=null? `${skillsCards.skillMinAge}${AppConstants.hyphen}${skillsCards.skillMaxAge}${AppConstants.years}`:`${AppConstants.eighteenPlusYears}`}
                  </Card.Title> */}
                  <Card.Text className={classes.skillName}>{skillsCards.skill}</Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}