import React, { useRef, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { Button, Form } from "react-bootstrap";
import { DragDropFile } from "../../../utils/DragDropFileUtil";
import { AppConstants } from "../../../constants/AppConstants";
import formClass from "../../../common/FormStyling.module.css";
import classes from "./AddChallenge.module.css";
import { Tooltip } from "react-tooltip";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";

export const AddChallenge = (props) => {
    const { saveCurrentStep, changeProblemStatementData, stage, competitionData } = props;

    const stages = AppConstants.createCompetitionActions;
    const [problemStatement, setProblemStatement] = useState((competitionData.questionPaperDescription !== AppConstants.nullText) ? competitionData.questionPaperDescription : AppConstants.emptyEditorString);
    const [statementFile, setStatementFile] = useState({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [problemStatementSpinner, setProblemStatementSpinner] = useState(AppConstants.falseText);
    const [defaultFile, setDefaultFile] = useState(AppConstants.nullText);
    const reactQuillRef = useRef();
    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.http}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }

    useEffect(() => {
        const changeParentState = () => {
            changeProblemStatementData({ statementFile, problemStatement });
        }

        changeParentState();
    }, [statementFile, problemStatement]);

    useEffect(() => {
        const getUploadedFiles = async () => {
            if (competitionData.questionPaperFilePath !== null && competitionData.questionPaperDescription === AppConstants.nullText) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(competitionData.questionPaperFilePath, `${competitionData.title}-ProblemStatement`);
                getSelectedFile(fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            } else {
                setDefaultFile(AppConstants.nullText);
                getSelectedFile(AppConstants.emptyString);
            }
            setProblemStatement((competitionData.questionPaperDescription !== AppConstants.nullText) ? competitionData.questionPaperDescription : AppConstants.emptyEditorString);
        }
        if (competitionData.id) {
            getUploadedFiles()
        } else {
            getSelectedFile(AppConstants.emptyString);
            setProblemStatement(AppConstants.emptyEditorString);
            setDefaultFile(AppConstants.nullText);
        }
    }, [competitionData]);

    useEffect(() => {
        const getDataTransfer = () => {
            if (competitionData.questionPaperFilePath) {
                const name = `ProblemStatement.${getFileExtension()}`
                const dataTransfer = new DataTransfer()
                const file = new File([''], name, { type: 'text/plain' })
                dataTransfer.items.add(file);
                setDefaultFile(dataTransfer.files[0])
            } else {
                setDefaultFile(AppConstants.nullText)
            }
        };
        if (competitionData.questionPaperFilePath !== null && competitionData.questionPaperDescription === AppConstants.nullText) {
            getDataTransfer();
        } else {
            setDefaultFile(AppConstants.nullText)
        }
    }, [competitionData]);

    const handleSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const getSelectedFile = (file) => {
        setStatementFile(file);
    }

    const handleTextChange = (value) => {
        let regex = /^<p\s+class="[^"]*"><br><\/p>$/;
        let htmlText = value.replace(regex, AppConstants.emptyEditorString);
        setProblemStatement(htmlText);
    };

    const getFileExtension = () => {
        return competitionData.questionPaperFileRelativePath.split(".").pop();
    }

    const downloadFile = async () => {
        const ext = getFileExtension();
        setProblemStatementSpinner(AppConstants.trueText);
        let resp = await FileOperationsUtil.downloadFileWithLoader(competitionData.questionPaperFilePath, `${competitionData.title}-ProblemStatement${AppConstants.dot}${ext}`);
        if (resp) {
            setProblemStatementSpinner(AppConstants.falseText);
        } else {
            setProblemStatementSpinner(AppConstants.falseText);
        }
    };

    return (
        <>
            <div className={`${classes.dragDropContainer} ${problemStatement !== AppConstants.emptyEditorString && classes.dragDropContainerInactive}`}>
                <DragDropFile classes={classes.dropFile}
                    maxFileSizeInBytes={50 * 1024 * 1024}
                    headingText={AppConstants.addChallengeHeading}
                    isDisabled={problemStatement !== AppConstants.emptyEditorString}
                    getSelectedFile={getSelectedFile}
                    defaultFile={defaultFile}
                    selectedCompetitionId={competitionData.id}
                    allowedTypes=".pdf,.txt,.zip" />
            </div>

            {competitionData.questionPaperFilePath && <div className={classes.problemStatementBtnContainer}>
                {<button type="button" data-tooltip-id="downloadProblemStatement" onClick={() => downloadFile()} className={classes.problemStatementBtn}>{problemStatementSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />} Download</button>}
                <Tooltip className={classes.tooltipMessage} id="downloadProblemStatement"><span className={classes.tooltipMessage}>Download File</span></Tooltip>
            </div>}

            {problemStatement === AppConstants.emptyEditorString && !statementFile?.size && <h4 className={classes.separatorLine}>OR</h4>}
            <div className={`${classes.textEditorContainer} ${statementFile?.size && classes.textEditorContainerInactive}`}>
                <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{AppConstants.enterProblemStatementText}</Form.Label>
                <ReactQuill
                    readOnly={statementFile && statementFile.size > 0}
                    className={classes.textEditor} theme="snow"
                    placeholder="Enter Problem statement"
                    value={problemStatement}
                    preserveWhitespace={AppConstants.trueText}
                    ref={reactQuillRef}
                    maxLength={500}
                    formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                    onChange={handleTextChange} />
            </div>
            <div className={classes.nextBtn}>
                <Button disabled={formSubmitting} type="submit" onClick={handleSubmit}>{AppConstants.nextText}</Button>
            </div>
        </>
    );
};

