import React, { useEffect, useState } from 'react';
import styles from "./StudentWeakAspectsSection.module.css";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const StudentWeakAspectsSection = (props) => {
    const [weakAspectsArray, setWeakAspectsArray] = useState(props.weakAspects);
    const [competitionDetais, setCompetitionDetais] = useState(props.competitionDetails);
    const waekAspects = weakAspectsArray?.map(function (e) { return e.aspectName; });
    const marksScored = weakAspectsArray?.map(function (e) { return e.marksScored; });

    useEffect(() => {
        setWeakAspectsArray(props.weakAspects);
        setCompetitionDetais(props.competitionDetails);
    }, [props.weakAspects, props.competitionDetails])

    const options = {
        chart: {
            type: 'bar',
            backgroundColor: 'white',
            style: {
                borderRadius: '12px',
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: waekAspects,
            labels: {
                style: {
                    fontSize: '0.9em',
                    fontWeight: '400',
                    color: '#000000'
                }
            },
            title: {
                text: null
            },
            gridLineWidth: 0,
            lineWidth: 0,
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0.5,
            gridLineColor: '#e77a7b',
            tickInterval: 1,
            tickPositions: [0, 1, 2, 3, 4, 5]
        },
        legend: {
            enabled: false,
        },
        colors: ['#e77a7b'],
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            showInLegend: false,
            name: "Marks Scored",
            data: marksScored,
        }]
    }

    return (
        <>
            <div className={styles.weakSectionContainer}>
                <div className={styles.topHeadingContainer}>
                    <h1 className={styles.headingText}>Jury felt {competitionDetais.studentName} <strong>could do better</strong> in the following aspects</h1>
                </div>
                <div className={styles.chartContainer}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        containerProps={{ style: { height: "100%" } }}
                    />
                </div>
            </div>
        </>
    );
}

