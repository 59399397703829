
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import parse from 'html-react-parser';
import { RiInformationFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../../constants/AppConstants";
import { Button, Col, Form, Row } from "react-bootstrap";
import formClass from "../../../common/FormStyling.module.css";
import { FormValidationUtils } from "../../../utils/FormValidationUtils";
import classes from "../../createCourseComponent/addCourseAttendanceDetails/AddCourseAttendanceDetails.module.css";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const EditCourseAttendanceDetails = ({ changeAttendanceData, courseType, selectedCourseData, saveCurrentStep, stage }) => {
    const stages = AppConstants.createCoursesActions;
    const [isAttendanceEnabled, setIsAttendanceEnabled] = useState(AppConstants.falseText);
    const [countAbsentAsClass, setCountAbsentAsClass] = useState(AppConstants.falseText);
    const [attendanceTracking, setAttendanceTracking] = useState(AppConstants.structuredAttendanceTrackingOptions);

    const initialValues = {
        trackingType: selectedCourseData.trackingType || AppConstants.emptyString,
        packageDuration: selectedCourseData.packageDuration || AppConstants.emptyString,
        classValidity: selectedCourseData.validity || AppConstants.emptyString
    };

    const validationSchema = Yup.object().shape({
        trackingType: Yup.string().required(ErrorMessages.selectTrackingTypeMessage),
        packageDuration: courseType === AppConstants.structuredCourseType ? Yup.number().notRequired() : Yup.number().test("duration_based_test", ErrorMessages.pleaseAddPackageDurationError, function (value) {
            const { trackingType } = this.parent;
            return ((trackingType === attendanceTracking.durationBasedTracking?.value && value > 0) || trackingType === attendanceTracking.classsBasedTracking?.value)
        }),
        classValidity: Yup.number().min(1, ErrorMessages.addClassValidityError).notRequired()
    });

    const { values, touched, errors, setFieldValue, handleBlur, handleChange } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        handleSubmit: () => { }
    });

    useEffect(() => {
        if (selectedCourseData?.id) {
            setIsAttendanceEnabled(selectedCourseData?.isAttendanceEnabledFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setCountAbsentAsClass(selectedCourseData?.isAbsentCountedAsAClassFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setAttendanceTracking(selectedCourseData.courseType === AppConstants.flexibleCourseType ? AppConstants.flexiAttendanceTrackingOptions : AppConstants.structuredAttendanceTrackingOptions);
        }
    }, [courseType, selectedCourseData]);

    useEffect(() => {
        changeAttendanceData({
            attendanceValues: {
                ...values, isAttendanceEnabledFlag: isAttendanceEnabled, isAbsentCountedAsAClassFlag: countAbsentAsClass,
            },
            attendanceErrors: errors
        });
    }, [values, isAttendanceEnabled, countAbsentAsClass, errors]);

    const changeAttendanceEnabledFlag = () => {
        if (selectedCourseData.isAttendanceMarkedForCourse === AppConstants.falseTextString) {
            if (isAttendanceEnabled) {
                setCountAbsentAsClass(AppConstants.falseText);
            }
            setIsAttendanceEnabled(!isAttendanceEnabled);
        }
    };

    const changeCountAbsentAsClassFlag = () => {
        if (selectedCourseData.isAttendanceMarkedForCourse === AppConstants.falseTextString) {
            setCountAbsentAsClass(!countAbsentAsClass)
        }
    };

    const moveToNextSection = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };


    const changeTrackingType = (e) => {
        if (selectedCourseData.participantsDetails?.length === 0) {
            setFieldValue("packageDuration", AppConstants.emptyString);
            setFieldValue("classValidity", AppConstants.emptyString);
            setFieldValue(AppConstants.courseDetailsFormLabels.trackingType.name, e.target.value);
            setIsAttendanceEnabled(AppConstants.falseText);
            setCountAbsentAsClass(AppConstants.falseText);
        }
    };

    return <>
        <div className={classes.mainContainer}>
            <Row xs={1} sm={2} md={1} lg={2} xl={2}>
                <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={selectedCourseData.participantsDetails?.length > 0 ? formClass.formLabelDisabled : formClass.formLabel}>{AppConstants.courseDetailsFormLabels.trackingType.label}{courseType === AppConstants.flexibleCourseType && <span className="redAstrisk">*</span>}</Form.Label>
                    <Form.Select className={formClass.selectInput}
                        name={AppConstants.courseDetailsFormLabels.trackingType.name}
                        value={values.trackingType}
                        isInvalid={touched.trackingType && errors.trackingType}
                        onChange={(e) => changeTrackingType(e)} onBlur={handleBlur}
                        disabled={selectedCourseData.participantsDetails?.length > 0}>
                        {Object.values(attendanceTracking)?.map((type, i) => (
                            <option key={i} value={type.value}>
                                {type.label}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback className="errorText" type="invalid">{errors.trackingType}</Form.Control.Feedback>
                </Form.Group>
                {courseType === AppConstants.flexibleCourseType && values.trackingType === attendanceTracking.durationBasedTracking?.value && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>Package Duration (In Days)<span className="redAstrisk">*</span></Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        min={0}
                        placeholder="Package duration"
                        name="packageDuration"
                        value={values.packageDuration} onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                        isInvalid={touched.packageDuration && errors.packageDuration}
                    />
                    <Form.Control.Feedback className="errorText" type="invalid">{errors.packageDuration}</Form.Control.Feedback>
                </Form.Group>}
                {courseType === AppConstants.flexibleCourseType && values.trackingType === attendanceTracking.classsBasedTracking?.value && <Form.Group className="mb-2" as={Col}>
                    <Form.Label className={formClass.formLabel}>Class Validity (In Days)</Form.Label>
                    <Form.Control className={formClass.formInput}
                        type="number"
                        min={0}
                        placeholder="Class Validity"
                        name="classValidity"
                        value={values.classValidity} onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                        isInvalid={touched.classValidity && errors.classValidity}
                    />
                    <Form.Control.Feedback className="errorText" type="invalid">{errors.classValidity}</Form.Control.Feedback>
                </Form.Group>}
            </Row>
            {courseType === AppConstants.flexibleCourseType && values.trackingType === attendanceTracking.durationBasedTracking?.value && <div className={classes.checkboxContainer}>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Enable Attendance&nbsp;<RiInformationFill className={classes.infoButton} data-tooltip-id="enableAttendanceDurationInfo" /> <span> <input type="checkbox" checked={isAttendanceEnabled} disabled={selectedCourseData.isAttendanceMarkedForCourse === AppConstants.trueTextString} onChange={changeAttendanceEnabledFlag} /></span></div>
                </div>
                <Tooltip className={classes.tooltipMessage} id="enableAttendanceDurationInfo"><span className={classes.tooltipMessage}>{AppConstants.enableAttendanceForDurationTooltip}</span></Tooltip>
            </div>}
            {courseType === AppConstants.flexibleCourseType && values.trackingType === attendanceTracking.classsBasedTracking?.value && <div className={classes.checkboxContainer}>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Enable Attendance&nbsp;<RiInformationFill className={classes.infoButton} data-tooltip-id="enableAttendanceClassesInfo" /> <span> <input type="checkbox" checked={isAttendanceEnabled} disabled={selectedCourseData.isAttendanceMarkedForCourse === AppConstants.trueTextString} onChange={changeAttendanceEnabledFlag} /></span></div>
                </div>
                {isAttendanceEnabled && <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Reduce a class if marked as absent&nbsp;<RiInformationFill className={classes.infoButton} data-tooltip-id="countAbsentAsClassInfo" /><span>  <input type="checkbox" checked={countAbsentAsClass} disabled={selectedCourseData.isAttendanceMarkedForCourse === AppConstants.trueTextString} onChange={changeCountAbsentAsClassFlag} /></span></div>
                </div>}
                <Tooltip className={classes.tooltipMessage} id="enableAttendanceClassesInfo"><span className={classes.tooltipMessage}>{AppConstants.enableAttendanceForClassesTooltip}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="countAbsentAsClassInfo"><span className={classes.tooltipMessage}>{AppConstants.countAbsentAsClassTooltip}</span></Tooltip>
            </div>}

            <div className={classes.generalInfoContainer}>
                <h3 className={classes.generalInfoHeading}>{AppConstants.generalInformationText}</h3>
                {Object.values(attendanceTracking)?.map((type, i) => <p className={classes.generalInfoText}>
                    {parse(type.description)} </p>
                )}
            </div>
            <div className={classes.nextBtn}>
                <Button onClick={moveToNextSection}>Next</Button>
            </div>
        </div>
    </>
}

