import { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import classes from "./ExpertPublishUnpublishCourse.module.css";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { AppConstants } from "../../constants/AppConstants";
import { PublishedUnpublishedCourseCards } from "../../component/cards/publishedUnpublishedCourseCards/PublishedUnpublishedCourseCards";
import { EditCourseComponent } from "../../component/editCourseComponent/EditCourseComponent";

export const ExpertPublishUnpublishCourse = () => {
    const [publishedCourses, setPublishedCourses] = useState([]);
    const [unpublishedCourses, setUnpublishedCourses] = useState([]);
    const [publishedCourseSearchText, setPublishedCourseSearchText] = useState(AppConstants.emptyString);
    const [unpublishedCourseSearchText, setUnpublishedCourseSearchText] = useState(AppConstants.emptyString);
    const [filteredPublishedCourses, setFilteredPublishedCourses] = useState([]);
    const [filteredUnpublishedCourses, setFilteredUnpublishedCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [editCourseOpen, setEditCourseOpen] = useState(false);
    const [refreshCoursesFlag, setRefreshCoursesFlag] = useState(false);

    useEffect(() => {
        const fetchPublishedCourseList = async () => {
            const url = AppConstants.fetchCoursesByStatusAPI + AppConstants.coursePublishedStatus.published;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setPublishedCourses(response);
                setFilteredPublishedCourses(response);
            } else {
                setPublishedCourses([]);
                setFilteredPublishedCourses([]);
            }
        };

        const fetchUnpublishedCourseList = async () => {
            const url = AppConstants.fetchCoursesByStatusAPI + encodeURIComponent(AppConstants.coursePublishedStatus.unpublished);
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setUnpublishedCourses(response);
                setFilteredUnpublishedCourses(response);
            } else {
                setUnpublishedCourses([]);
                setFilteredUnpublishedCourses([]);
            }
        };

        fetchPublishedCourseList();
        fetchUnpublishedCourseList();
    }, [refreshCoursesFlag]);

    const refreshCoursesData = () => {
        setRefreshCoursesFlag(!refreshCoursesFlag);
        setPublishedCourseSearchText(AppConstants.emptyString);
        setFilteredPublishedCourses(publishedCourses);
        setUnpublishedCourseSearchText(AppConstants.emptyString);
        setFilteredUnpublishedCourses(unpublishedCourses);
    }

    const searchPublishedCourses = (e) => {
        setPublishedCourseSearchText(e.target.value);
        let filteredCourses = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? publishedCourses.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : publishedCourses;
        setFilteredPublishedCourses(filteredCourses);
    };

    const searchUnpublishedCourses = (e) => {
        setUnpublishedCourseSearchText(e.target.value);
        let filteredCourses = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? unpublishedCourses.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : unpublishedCourses;
        setFilteredUnpublishedCourses(filteredCourses);
    };

    const resetPublishedCourseFilter = () => {
        setPublishedCourseSearchText(AppConstants.emptyString);
        setFilteredPublishedCourses(publishedCourses);
    };

    const resetUnpublishedCourseFilter = () => {
        setUnpublishedCourseSearchText(AppConstants.emptyString);
        setFilteredUnpublishedCourses(unpublishedCourses);
    };

    const onClickEdit = (course) => {
        if (course.eventStatus === AppConstants.courseStatus.past) return;

        setEditCourseOpen(true);
        setSelectedCourse(course);
    }

    const onHideEdit = () => {
        setSelectedCourse({});
        setEditCourseOpen(false);
        refreshCoursesData();
    }

    return (
        <>
            {!editCourseOpen && <div>
                <h2 className={classes.heading}>Unpublished Courses</h2>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={unpublishedCourseSearchText} onChange={(e) => { searchUnpublishedCourses(e) }} placeholder="Search courses" />
                    {unpublishedCourseSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetUnpublishedCourseFilter() }} />}
                </div>
                {filteredUnpublishedCourses.length !== 0 && <div>
                    <PublishedUnpublishedCourseCards
                        courseData={filteredUnpublishedCourses.length === 0 ? unpublishedCourses : filteredUnpublishedCourses}
                        courseType={AppConstants.unpublishedCourseText}
                        refreshCoursesData={refreshCoursesData}
                        onClickEdit={onClickEdit}
                    />
                </div>}
                {filteredUnpublishedCourses.length === 0 && <div>
                    <p className="noDataAvailableError">{ErrorMessages.noUnpublishedCoursesFallbackMessage}</p>
                </div>}
            </div>}
            {!editCourseOpen && <div className={classes.publishedCardsSection}>
                <h2 className={classes.heading}>Published Courses</h2>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={publishedCourseSearchText} onChange={(e) => { searchPublishedCourses(e) }} placeholder="Search courses" />
                    {publishedCourseSearchText && <MdClear className={classes.resetSearchIcon} onClick={() => { resetPublishedCourseFilter() }} />}
                </div>
                {filteredPublishedCourses.length !== 0 && <div>
                    <PublishedUnpublishedCourseCards
                        courseData={filteredPublishedCourses.length === 0 ? publishedCourses : filteredPublishedCourses}
                        courseType={AppConstants.publishedCourseText}
                        refreshCoursesData={refreshCoursesData}
                        onClickEdit={onClickEdit}
                    />
                </div>}
                {filteredPublishedCourses.length === 0 && <div>
                    <p className="noDataAvailableError">{ErrorMessages.noPublishedCoursesFallbackMessage}</p>
                </div>}
            </div>}
            {editCourseOpen && selectedCourse.uniqueId && <div className={classes.showEditSection}>
                <EditCourseComponent selectedCourse={selectedCourse.uniqueId} onHideEdit={onHideEdit} />
            </div>}
        </>
    )
}