import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import classes from "../../component/expertListingPage/ExpertListingPage.module.css";
import { Button } from "react-bootstrap";
import { DataService } from "../../services/DataService";
import { QuizListingPageCards } from "../../component/cards/quizListingPageCards/QuizListingPageCards";
import { ErrorMessages } from "../../constants/ErrorMessages";
import sharePanelClasses from "../../component/individualCoursePage/IndividualCoursePageLayout.module.css";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";
import { QuizListingPageBanner } from "../../component/quizListingPageBanner/QuizListingPageBanner";

export const QuizListingPage = () => {
    const [landingPageData, bannerSectionData] = useLoaderData();
    const [quizData, setQuizData] = useState([]);
    const [filterText, setFilterText] = useState(AppConstants.emptyString);
    const [filteredList, setFilteredList] = useState([]);
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.quizzesText}`;

    // scroll function
    const scrollToSection = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    };
    scrollToSection();

    const handleResetFields = () => {
        setFilterText(AppConstants.emptyString);
        setFilteredList(quizData);
    }

    const handleSearchInputChange = (e) => {
        setFilterText(e.target.value);

        let filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? quizData?.filter((item) => ((item.title?.toLowerCase())?.includes(e.target.value.toLowerCase())))
            : quizData;

        setFilteredList(filteredArray);
    };

    useEffect(() => {
        //fetch quizzes
        async function getQuizData() {
            const url = AppConstants.getQuizDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response && response?.length) {
                setQuizData(response);
                setFilteredList(response);
            } else {
                setQuizData([]);
                setFilteredList([]);
            }
        }
        getQuizData();
    }, []);

    return (
        <div>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={sharePanelClasses} linkToShare={linkToShare} />
            </div>
            <QuizListingPageBanner bannerImage={bannerSectionData} />
            <div className={classes.quizSearchContainer}>
                <div className={classes.containerSearchItem}><input
                    type="text"
                    value={filterText}
                    onChange={handleSearchInputChange}
                    placeholder="Search Quizzes by Name"
                />
                </div>
                <Button onClick={handleResetFields} className={classes.resetButtonQuiz}>Clear</Button>
            </div>
            {filteredList?.length !== 0 ?
                <QuizListingPageCards quizList={filteredList} />
                : <div className={classes.noDataError}><p className={classes.errorText}>{filterText.length > 0 ? ErrorMessages.noQuizesFound : ErrorMessages.noActiveQuizesFoundMessage}</p></div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </div>

    );
}