import React, { useState } from 'react';
import classes from './StudentProfilePageTabs.module.scss';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

return (
  <div className="tabs">
    <div className={classes.tabContainer}>
      {children.map((child, index) => (
        <div
          key={index} 
          className={`${classes.tabPill} ${index === activeTab ? classes.activeTab : ''} ${index !== 0 ? classes.tabOverlap : ''}`}
          onClick={() => handleTabClick(index)}
        >
          {child.props.label}
        </div>
      ))}
    </div>
    <div>
      {children[activeTab]}
    </div>
  </div>
);
};

export default Tabs;
