import React from 'react';
import './signup.css'
import { SignupPageCarousel } from '../component/signupPageComponents/SignupPageCarousel'
import { useLoaderData } from 'react-router-dom';
import { SignupAndEnrolInCompetition } from '../component/formControls/SignupAndEnrolInCompetition';
import { AppConstants } from '../constants/AppConstants';

export const SignupAndEnrolPage = () => {
    const [signupPageSectionDetails] = useLoaderData();
    return <>
        {/* <SignupPageTopNavigation /> */}
        <div className='row main-container-signup'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 carousel-container'>
                <SignupPageCarousel signupPageData={signupPageSectionDetails} page={AppConstants.enrolAndSignupPageText} />
            </div>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 signup-form-container'>
                <SignupAndEnrolInCompetition />
            </div>
        </div>
    </>
}

